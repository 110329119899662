import {ListItemIcon, styled} from "@mui/material";

const StyledListItemIcon = styled(ListItemIcon)(() => ({
  "&.MuiListItemIcon-root": {
    width: "auto",
    minWidth: "auto !important",
  },
}))
export default StyledListItemIcon


import {useTranslation} from "react-i18next";
import {RJSFSchema, UiSchema} from "@rjsf/utils";
import RecordView from "@/components/views/RecordView";
import urls from "@/urls";
import {VendorEndPoint} from "@/services/vendor/endpoints";


const DiscountRecordView = () => {
  const {t} = useTranslation("vendor");
  const {t: menu} = useTranslation("menu");
  const formSchema: RJSFSchema = {
    "type": "object",
    "properties": {
      discount_ratio: {
        type: "number",
        title: t("discount.ratio"),
        default: 0.0
      },
      effective_status: {
        type: "boolean",
        title: t("discount.effective"),
        default: false
      }
    },
    required: ["discount_ratio"]
  }

  const uiSchema: UiSchema = {
    'ui:defaultXsWidth': 4,
    "ui:submitButtonOptions": {"norender": true},
    effective_status: {
      "ui:widget": "switch"
    }
  }

  return (
    <RecordView
      breadcrumbs={[
        {title: menu("menu.subscription"), href: urls.subscription.root},
        {title: menu("menu.subscription.discount"), href: urls.subscription.discount},
      ]}
      formSchema={formSchema}
      uiSchema={uiSchema}
      uri={VendorEndPoint.DISCOUNT_URI}
    />
  )
}
export default DiscountRecordView

import Grid from "@mui/material/Grid2";

import { styled, Typography } from "@mui/material";
import { DataGrid, GridColDef, GridColumnVisibilityModel, useGridApiRef } from "@mui/x-data-grid";
import React, { useEffect, useRef, useState } from "react";
import GenericService from "@/services/core/GenericService";
import SearchPanel from "@/pages/Report/ReportLibrary/components/ReportView/components/SearchPanel";
import HeaderToolBar from "@/pages/Report/ReportLibrary/components/ReportView/components/HeaderToolBar";
import { ReportViewContext } from "./components/ReportViewProvider";
import exportHooks from "@/hooks/exportHooks";

const Breadcrumb = styled(Grid)(() => ({
  height: "48px",
  display: "flex",
  alignItems: "center",

  margin: "0 8px",
}));

const ReportView = (props: Record<string, any>) => {
  const searchPanelRef = useRef<any>();
  const dataGridRef = useGridApiRef();
  const {RecordPanel} = props
  const [selectedRow, setSelectedRow] = useState<Record<string, any>>()
  const [loading, setLoading] = useState<boolean>(false);
  const [gridData, setGridData] = useState<any>({ data: [], total: 0 });
  const filterType = props.filterType ? props.filterType : "day";
  const departmentReport = props.departmentReport ? props.departmentReport : false;
  const [paginationModel, setPaginationModel] = useState<{
    page: number;
    pageSize: number;
  }>({ page: 0, pageSize: 10 });

  const [filterModel, setFilterModel] = useState<Record<string, any>>({
    category: null,
    selected: [],
    startDate: null,
    endDate: null,
    emp_code: null,
  });
  const updateFilterModel = (params: Record<string, any>) => {
    setFilterModel({ ...filterModel, ...params });
    if (props.onUpdateFilterModel) {
      props.onUpdateFilterModel({ ...filterModel, ...params });
    }
  };
  const refreshGridData = async () => {
    setLoading(true);
    GenericService.action(props.uri, "view", {
      page: paginationModel.page + 1,
      page_size: paginationModel.pageSize,
      start_date: filterModel.startDate?.format("YYYY-MM-DD"),
      end_date: filterModel.endDate?.format("YYYY-MM-DD"),
      [`${filterModel.category}`]: filterModel.selected,
      emp_code: filterModel.emp_code,
    })
      .then((response) => {
        setGridData(response.data);
        setSelectedRow({});
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (filterModel.category) {
      refreshGridData().then();
      if (props.refreshColumns) {
        props
          .refreshColumns({ ...paginationModel, ...filterModel, setLoading })
          .then();
      }
    }
  }, [paginationModel, filterModel]);

  const wiseFields = ['Default', 'employee_code', 'department_code', 'att_date']

  const defaultHideColumns: GridColumnVisibilityModel = {}
  if (props.hideColumns) {
    props.hideColumns.forEach((each: string) => {
      defaultHideColumns[each] = false
    })
  }
  const [columnVisibilityModel, setColumnVisibilityModel] =
    useState<GridColumnVisibilityModel>(defaultHideColumns);

  return (
    <ReportViewContext.Provider
      value={{
        title: props.title,
        uri: props.uri,
        paginationModel,
        refreshGridData,
        filterModel,
        updateFilterModel,
        dataGridRef,
        filterType,
        departmentReport,
        wiseFields: props.wiseFields === undefined ? wiseFields : props.wiseFields,
      }}
    >
      <Grid container spacing={1} sx={{ width: "calc(100% -224px)" }}>
        <Breadcrumb size={12}>
          <Typography variant={"h3"}>{props.title}</Typography>
        </Breadcrumb>
        <Grid
          size={12}
          sx={{
            display: "flex",
          }}
        >
          <SearchPanel ref={searchPanelRef} />
          <Grid
            sx={{
              flex: 1,
              margin: "0 8px",
            }}
          >
            <HeaderToolBar />
            <Grid
              sx={{
                mt: 1,
              }}
            >
              <DataGrid
                loading={loading}
                apiRef={dataGridRef}
                sx={{
                  height: "calc(100vh - 64px - 64px - 64px)",
                  backgroundColor: "#fff",
                  ".MuiDataGrid-cell:focus": { outline: "none" },
                  ".MuiDataGrid-cell:focus-within": { outline: "none" },
                  ".MuiDataGrid-columnHeader:focus": { outline: "none" },
                }}
                columns={props.columns.map((column: GridColDef) => ({
                  sortable: false,
                  ...column,
                }))}
                columnVisibilityModel={columnVisibilityModel}
                onColumnVisibilityModelChange={(newModel) =>
                  setColumnVisibilityModel(newModel)
                }
                rows={gridData.data}
                rowCount={gridData.total}
                onRowClick={(params, event, details) => {
                  setSelectedRow(params.row)
                }}
                getRowId={props.getRowId}
                paginationMode={"server"}
                paginationModel={paginationModel}
                onPaginationModelChange={(model: any, details: any) => {
                  setPaginationModel(model);
                }}
              />
            </Grid>
          </Grid>
          {RecordPanel && <RecordPanel record={selectedRow}/>}
        </Grid>
      </Grid>
    </ReportViewContext.Provider>
  );
};
export default ReportView;

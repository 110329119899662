import ListView from "@/components/views/ListView";
import {GridColDef} from "@mui/x-data-grid";
import AppUrls from "@/urls";
import urls from "@/urls";
import {TeamEP} from "@/services/team/endpoints";
import {useTranslation} from "react-i18next";


const NodeListView = () => {
  const {t} = useTranslation("per")
  const displayName = (value: any) => value?.display_name
  const columns: GridColDef[] = [
    {field: "workflow_engine_name", headerName: t("workflowNode.workflow.name"), flex: 1},
    {field: "node_code", headerName: t("workflowNode.code"), flex: 1},
    {field: "node_name", headerName: t("workflowNode.name"), flex: 1},
    {
      field: "approver", valueFormatter: (value: any) => {
        return value.map((each: any) => each.display_name)
      }, headerName: t("workflowNode.approver"), flex: 1
    },
    {
      field: "notifier", valueFormatter: (value: any) => {
        return value.map((each: any) => each.display_name)
      }, headerName: t("workflowNode.notifier"), flex: 1
    },
    {
      field: "approve_scope", valueFormatter: (value: any) => {
        return value.map((each: any) => each.display_name)
      }, headerName: t("workflowNode.approverScope"), flex: 1
    },
    {
      field: "notify_scope", valueFormatter: (value: any) => {
        return value.map((each: any) => each.display_name)
      }, headerName: t("workflowNode.notifierScope"), flex: 1
    },
  ]

  const filters = [
    {key: 'workflow_engine_name', label: t("workflowNode.workflow.name"), type: 'string'},
    {key: 'node_code', label: t("workflowNode.code"), type: 'string'},
    {key: 'node_name', label: t("workflowNode.name"), type: 'string'},
  ]

  return (
    <ListView
      title={t("menu.workflowNode", {ns: "menu"})}
      breadcrumbs={[
        {title: t("menu.team"), href: urls.team.root},
      ]}
      columns={columns}
      uri={TeamEP.WORKFLOW_NODE_URI}
      url={AppUrls.team.workflowNode}
      filters={filters}
      disableAdd
      disableDelete
    />
  )
}
export default NodeListView

import {useTranslation} from "react-i18next";
import {GridColDef} from "@mui/x-data-grid";
import ListView from "@/components/views/ListView";
import {PersonalEndPoint} from "@/services/personal/endpoints";
import AppUrls from "@/urls";
import FilterPanel from "@/pages/Personal/request/components/FilterPanel";


const HolidayListView = () => {
  const {t} = useTranslation("complement");
  const {t: menu} = useTranslation("menu");
  const columns: GridColDef[] = [
    {
      field: "alias",
      headerName: t("holiday.name"),
      flex: 1
    },
    {
      field: "start_date",
      headerName: t("holiday.startDate"),
      flex: 1,
    },
    {
      field: "duration_day",
      headerName: t("holiday.durationDay"),
      flex: 1
    },
    {
      field: "work_type",
      headerName: t("holiday.workType"),
      flex: 1,
      type: "singleSelect",
      valueOptions: [
        {value: 0, label: t("workType.ignore")},
        {value: 1, label: t("workType.normalWorkTime")},
        {value: 2, label: t("workType.weekdayOvertime")},
        {value: 3, label: t("workType.weekOffOvertime")},
        {value: 4, label: t("workType.holidayOvertime")},
      ]
    }
  ]
  return (
    <ListView
      title={menu("menu.holiday")}
      columns={columns}
      uri={PersonalEndPoint.holidays}
      url={AppUrls.personal.myCalendar.holiday}
      disableHistory={true}
      disableAdd={true}
      disableEdit={true}
      disableDelete={true}
      disableExport
      FilterPanel={FilterPanel}
    />
  )
}
export default HolidayListView

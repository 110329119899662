import ListView from "@/components/views/ListView";
import {GridColDef} from "@mui/x-data-grid";
import AppUrls from "@/urls";
import {DeviceEndPoint} from "@/services/device/endpoints";
import {useTranslation} from "react-i18next";

import ClearAllIcon from "@mui/icons-material/ClearAll";
import SnackbarUtil from "@/utils/SnackbarUtil";
import CommandService from "@/services/device/CommandService";

const DeviceCommandListView = () => {
  const {t} = useTranslation("device");
  const columns: GridColDef[] = [
    {
      field: "sn",
      headerName: t("device.sn"),
      flex: 1,
    },
    {
      field: "alias",
      headerName: t("device.alias"),
      flex: 1,
    },
    {field: "content", headerName: t("deviceCommand.content"), flex: 1},
    {
      field: "commit_time",
      headerName: t("deviceCommand.commitTime"),
      flex: 1,
    },
    {
      field: "transfer_time",
      headerName: t("deviceCommand.transferTime"),
      flex: 1,
    },
    {
      field: "return_time",
      headerName: t("deviceCommand.returnTime"),
      flex: 1,
    },
    {
      field: "return_value",
      headerName: t("deviceCommand.returnValue"),
      flex: 1,
      type: "singleSelect",
      valueOptions: [
        {value: 0, label: t('deviceCommand.retrunValue.successful')},
        {value: -1, label: t('deviceCommand.retrunValue.parameterIncorrect')},
        {value: -2, label: t('deviceCommand.retrunValue.userPhotoTransferError')},
        {value: -3, label: t('deviceCommand.retrunValue.readOrWriteError')},
        {value: -9, label: t('deviceCommand.retrunValue.bioTemplateTransferError')},
        {value: -10, label: t('deviceCommand.retrunValue.userNotExist')},
        {value: -11, label: t('deviceCommand.retrunValue.illegalFPTemplateFormat')},
        {value: -12, label: t('deviceCommand.retrunValue.illegalFPTemplate')},
        {value: -1001, label: t('deviceCommand.retrunValue.capacityLimitation')},
        {value: -1002, label: t('deviceCommand.retrunValue.deviceNotSupport')},
        {value: -1003, label: t('deviceCommand.retrunValue.timeout')},
        {value: -1004, label: t('deviceCommand.retrunValue.dataException')},
        {value: -1005, label: t('deviceCommand.retrunValue.deviceBusy')},
        {value: -1006, label: t('deviceCommand.retrunValue.dataTooLong')},
        {value: -1007, label: t('deviceCommand.retrunValue.memoryError')},
        {value: -1008, label: t('deviceCommand.retrunValue.gettingServerDataFailed')},
      ]
    },
  ];



  const filters = [
    {
      key: "terminal__sn",
      label: t("device.sn"),
      type: "string",
    },
    {
      key: "terminal__alias",
      label: t("device.alias"),
      type: "string",
    },
    {
      key: "content",
      label: t("deviceCommand.content"),
      type: "string",
    },
  ];

  const onBulkDelete = async () => {
    const response = await CommandService.clear({});
    if (response.isSuccess) {
      SnackbarUtil.success(t("common.message.successful", {ns: "common"}));
      return true;
    }
    SnackbarUtil.success(response.code);
    return false;
  };

  const modelActions = [
    {
      id: "devCommBulkDelete",
      title: t("deviceCommand.bulkDelete"),
      icon: <ClearAllIcon/>,
      display: "dialog",
      callback: onBulkDelete,
      confirmation: t("deviceCommand.bulkDeleteAction.confirm"),
    },
  ];

  return (
    <ListView
      disableAdd
      disableEdit
      title={t("menu.device.command", {ns: "menu"})}
      columns={columns}
      uri={DeviceEndPoint.COMMAND_URI}
      url={AppUrls.device.command}
      filters={filters}
      excludeActions={["delete"]}
      modelActions={modelActions}
    />
  );
};
export default DeviceCommandListView;

import {useTranslation} from "react-i18next";
import {GridColDef} from "@mui/x-data-grid";
import urls from "@/urls";
import ReportView from "../ReportView";
import {PersonalEndPoint} from "@/services/personal/endpoints";
import { useReportEmployee } from "@/hooks/employee";

const IncidentDetailReport = () => {
  const {t} = useTranslation("report")
  const dangerouslySetInnerHTML = (params: any) => {
    return <div dangerouslySetInnerHTML={{ __html: params.value }} />
  }
  const employeeFields = useReportEmployee()

  const columns: GridColDef[] = [
    ...employeeFields,
    { field: "allotment_type", headerName: t("report.allotmentType"), flex: 1},
    { field: "effective_date", headerName: t("report.effectiveDate"), flex: 1},
    { field: "increment_date", headerName: t("report.incrementDate"), flex: 1},
    { field: "days", headerName: t("report.days"), flex: 1},
    { field: "max_leave_per_month", headerName: t("report.maxLeavePerMonth"), flex: 1},
    { field: "carry_forward_limit", headerName: t("report.carryForwardLimit"), flex: 1},
    { field: "leave_availed", headerName: t("report.leaveAvailed"), flex: 1},
    { field: "leave_balance", headerName: t("report.leaveBalance"), flex: 1},
  ]
  return (
    <ReportView
      title={t("report.incidentDetailReport")}
      breadcrumbs={[]}
      columns={columns}
      uri={PersonalEndPoint.INCIDENT_DETAIL_URI}
      url={urls.personal.report.incidentDetail}
      hideColumns={['first_name', 'last_name', 'department_code', 'position_code', 'position_name']}
    />
  )
}
export default IncidentDetailReport

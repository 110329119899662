import AppUrls from "@/urls";
import {CustomValidator, RJSFSchema, UiSchema} from "@rjsf/utils";
import {useTranslation} from "react-i18next";
import RecordView from "@/components/views/RecordView";
import {ComplementEndpoint} from "@/services/complement/endpoints";
import RegExps from "@/utils/RegExps";

const WorkCodeRecordView = () => {
  const {t} = useTranslation("complement");
  const {t: common} = useTranslation("common");
  const formSchema: RJSFSchema = {
    type: "object",
    required: ["code", "alias"],
    properties: {
      code: {
        type: "string",
        title: t("workCode.code"),
        maxLength: 8
      },
      alias: {
        type: "string",
        title: t("workCode.alias"),
        maxLength: 24
      },
    },
  };

  const customValidate: CustomValidator = (formData, errors, uiSchema) => {
    if (formData.code && !RegExps.digitalCode.test(formData.code)) {
      errors.code?.addError(common("form.validateError.digitalOnly"))
    }
    if (formData.alias && RegExps.xssClean.test(formData.alias)) {
      errors.alias?.addError(common("form.validateError.illegalInput"))
    }

    return errors
  }


  const uiSchema: UiSchema = {
    "ui:defaultXsWidth": 4,
  };
  return (
    <RecordView
      formSchema={formSchema}
      uiSchema={uiSchema}
      uri={ComplementEndpoint.WORK_CODE_URI}
      url={AppUrls.complement.workCode}
      customValidate={customValidate}
    />
  );
};
export default WorkCodeRecordView;

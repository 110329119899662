import {Route, Routes} from "react-router-dom";
import LeaveListView from "./ListView";
import LeaveRecordView from "./RecordView";

const LeaveRouter = () => {
  return (
    <Routes>
      <Route index element={<LeaveListView/>}/>
      <Route path={"add/"} element={<LeaveRecordView/>}/>
      <Route path={"edit/"} element={<LeaveRecordView/>}/>
    </Routes>
  );
};
export default LeaveRouter;

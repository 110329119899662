import Grid from "@mui/material/Grid2";
import {useTranslation} from "react-i18next";
import {Chip, FormLabel} from "@mui/material";

const RecordReadOnlyView = (props: Record<string, any>) => {
  const {t} = useTranslation()
  const {recordId, record} = props

  const items = [
    {label: t("vendor.company.name"), value: record.name},
    {label: t("vendor.company.subscriptionStartDate"), value: record.subscription_start_date},
    {label: t("vendor.company.subscriptionEndDate"), value: record.subscription_end_date},
    {label: t("vendor.company.employeeUsage"), value: record.employee_usage},
    {label: t("vendor.company.deviceUsage"), value: record.device_usage},
    {label: t("vendor.company.transactionUsage"), value: record.transaction_usage},
    {label: t("vendor.company.mobileUsage"), value: record.mobile_usage},
    {label: t("vendor.company.whatsappUsage"), value: record.whatsapp_usage},
    {label: t("vendor.company.feature"), value: record.feature}
  ]

  return (
    <Grid
      container
      spacing={1}
      sx={{
        minWidth: "384px",
        minHeight: "128px",
        marginTop: "-16px",
        marginBottom: "16px"
      }}
    >
      {items.map((item) => (
        <>
          <Grid size={6}>
            <FormLabel>{item.label}</FormLabel>
          </Grid>
          <Grid size={6}>{item.value}</Grid>
        </>
      ))}
    </Grid>
  )
}
export default RecordReadOnlyView

import {Navigate, useRoutes} from "react-router-dom";
import CompanyRouter from "@//pages/vendor/tenement/Company/router";
import UserRouter from "@/pages/vendor/tenement/User/router";
import DeviceRouter from "@/pages/vendor/tenement/Device/router";
import EmployeeRouter from "@/pages/vendor/tenement/Employee/router";
import MobileAppRouter from "@/pages/vendor/tenement/MobileApp/router";


const TenementRouter = () => {
  const routes = useRoutes([
    {index: true, element: <Navigate to={"company"} replace/>},
    {path: "company/*", element: <CompanyRouter/>},
    {path: "user", element: <UserRouter/>},
    {path: "device", element: <DeviceRouter/>},
    {path: "employee", element: <EmployeeRouter/>},
    {path: "mobile-app", element: <MobileAppRouter/>},
  ])
  return routes
}
export default TenementRouter

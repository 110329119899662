import {Box, Card, CardContent, CardHeader} from "@mui/material";
import ReactEcharts from "echarts-for-react";
import React, {useEffect, useState} from "react";
import GenericService from "@/services/core/GenericService";
import {VendorEndPoint} from "@/services/vendor/endpoints";
import {useTranslation} from "react-i18next";


const CompanyChart = () => {
  const {t} = useTranslation("vendor");
  const [expenseCount, setExpenseCount] = useState([0,0,0,0]);
  const [salesCount, setSalesCount] = useState([0,0,0,0]);
  function getLastFourYears() {
    const currentYear = new Date().getFullYear();
    const years = [];
    for (let i = 3; i >= 0; i--) {
      years.push(currentYear - i);
    }
    return years;
  }
  const startYear = getLastFourYears()[0];
  const endYear = getLastFourYears()[getLastFourYears().length - 1];
  useEffect(() => {
    GenericService.list(VendorEndPoint.DASHBOARD_SALES_DATA, {
    }).then((response) => {
      setExpenseCount(response.data.expense_count)
      setSalesCount(response.data.sales_count)
    })
  }, [])
  const option = {
    title: {
      text: `Sales data for the last four years from ${startYear} to ${endYear}`,
      left: 'left',
      textStyle: {
        fontSize: 14,
        fontWeight: 'light',
        color: '#333',
      }
    },
    tooltip: {
      trigger: 'axis'
    },
    legend: {
      data: ['Expense', 'Sales'],
      bottom: 'bottom'
    },
    toolbox: {
      show: true,
      feature: {
        mark: {show: true},
        // dataView: {show: true, readOnly: false},
        // restore: {show: true},
        // saveAsImage: {show: true}
      }
    },
    xAxis: {
      type: 'category',
      data: getLastFourYears()
    },
    yAxis: {
      type: 'value'
    },
    series: [
      {
        name: 'Expense',
        type: 'line',
        data: expenseCount,
        itemStyle: {
          color: '#FF6347'
        }
      },
      {
        name: 'Sales',
        type: 'line',
        data: salesCount,
        itemStyle: {
          color: '#4076e3'
        }
      }
    ]
  }
  return (
    <Card>
      <CardHeader
        title={t("dashboard.companyReport")}
      />
      <CardContent>
        <ReactEcharts option={option}/>
      </CardContent>
    </Card>
  )
}
export default CompanyChart

import {useRoutes} from "react-router-dom";
import GroupListView from "./ListView";
import GroupRecordView from "@/pages/AccessControl/group/RecordView";


const GroupRouter = () => {
  return useRoutes([
    {index: true, element: <GroupListView />},
    {path: "add", element: <GroupRecordView />},
    {path: "edit", element: <GroupRecordView />},
  ])
};
export default GroupRouter

import qs from 'qs';
import request from "@/utils/Request";
import {AxiosRequestConfig} from "axios";
import { PsnlEP } from './endpoints';

const transfer = async (key: string, data: Record<string, any>, config?: AxiosRequestConfig<Record<string, any>>) => {
  const response = await request.authorizedAxios().post(`${PsnlEP.DEPARTMENT_URI}${key}/transfer/`, data, config);
  return response.data;
}


const DepartmentService = {
  transfer
}
export default DepartmentService

import {useLocation} from "react-router-dom";
import React, {ForwardedRef, useEffect, useImperativeHandle, useRef, useState} from "react";
import LayoutForm from "@/components/rjsf/Form";
import validator from "@rjsf/validator-ajv8";
import {useTranslation} from "react-i18next";
import SnackbarUtil from "@/utils/SnackbarUtil";
import {createBrowserHistory} from "history";
import GenericService from "@/services/core/GenericService";
import ModelActionLayout from "@/components/views/components/ModelActionLayout";

// type RecordViewProps = Record<string, any>
type RecordViewProps = any

const RecordView = (props: RecordViewProps, ref: ForwardedRef<any>) => {
  const {t} = useTranslation("common")
  const {t:api} = useTranslation("api")
  const params = useLocation()
  const history = createBrowserHistory()
  const recordId = params.state?.recordId
  const mode = params.state?.mode || "ADD"
  const title = mode === "EDIT" ? t("common.action.edit") : t("common.action.add")
  const formRef = useRef<any>()
  const [formData, setFormData] = useState<Record<string, any>>({})
  const fetchRecord = async () => {
    const response = await GenericService.retrieve(props.uri, recordId)
    return response.data
  }

  const onSave = async () => {
    const isValid = formRef.current?.validateForm()
    if (isValid) {
      const data = formRef.current?.state.formData
      let response
      if (mode === "EDIT") {
        response = await GenericService.patch(props.uri, recordId, data)
      } else {
        response = await GenericService.create(props.uri, data)
      }
      if (response.isSuccess) {
        SnackbarUtil.success(t("common.message.saveSuccessfully"))
        history.back()
      }
    } else {
      SnackbarUtil.error("Invalid input")
    }
  }

  const onDiscard = async () => {
    history.back()
  }

  const defaultRecordActions: any[] = [
    {title: t("common.button.save"), callback: onSave},
    {title: t("common.button.discard"), variant: "outlined", callback: onDiscard}
  ]

  useImperativeHandle(ref, () => ({
    formData: formRef.current?.state.formData
  }))


  useEffect(() => {
    if (recordId) {
      fetchRecord().then(data => {
        setFormData(data)
      })
    }
  }, [])

  const onChange = (e: any) => {
    if (props.onChange) {
      props.onChange(e)
    } else {
      setFormData(e.formData)
    }
  }
  useEffect(() => {
    setFormData(props.formData)
  }, [props.formData])

  return (
    <ModelActionLayout
      title={props.title || title}
      breadcrumbs={props.breadcrumbs}
      actions={defaultRecordActions}
    >
      <LayoutForm
        ref={formRef}
        schema={props.formSchema}
        uiSchema={props.uiSchema}
        fields={props.fields}
        widgets={props.widgets}
        templates={props.templates}
        onChange={onChange}
        customValidate={props.customValidate}
        validator={validator}
        formData={formData}
      />
    </ModelActionLayout>
  )
}
export default React.forwardRef(RecordView)

import {Box, Card, CardContent, CardHeader, Typography} from "@mui/material";
import {Stack} from "@mui/system";


const SummaryCard = (props: Record<string, any>) => {
  return (
    <Stack
      direction="row"
      sx={{
        backgroundColor: "#fff",
        borderRadius: "4px",
        p: "2 0",
        minHeight: "80px",
        alignItems: "center",
      }}
    >
      <Box sx={{p: 1}}>{props.icon}</Box>
      <Stack spacing={1}>
        <Typography color={"primary"}>{props.title}</Typography>
        <Typography variant={"h3"}>{props.summary}</Typography>
      </Stack>
    </Stack>
  )
}
export default SummaryCard

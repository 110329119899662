import {Card, CardContent, CardHeader} from "@mui/material";
import React, {useEffect} from "react";
import {useTranslation} from "react-i18next";
import ReactEcharts from "echarts-for-react";
import GenericService from "@/services/core/GenericService";
import {VendorEndPoint} from "@/services/vendor/endpoints";
import {DatePicker} from "@mui/x-date-pickers";


const EmployeeTendencyChart = () => {
  const {t} = useTranslation("vendor");
  const [data, setData] = React.useState([]);
  const [date, setDate] = React.useState<any>(new Date());
  useEffect(() => {
    GenericService.action(VendorEndPoint.DASHBOARD, "employee_tendency", {year: date.getFullYear()}).then((response) => {
      setData(response.data)
    })
  }, [date])
  const option = {
    title: {
      show: false
    },
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow'
      }
    },
    grid: {
      top: '3%',
      left: '3%',
      right: '4%',
      bottom: '3%',
      containLabel: true
    },
    xAxis: {
      type: 'category',
      data: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
      axisLabel: {
        interval: 0,
        show: true
      },
      boundaryGap: true
    },
    yAxis: {
      type: 'value'
    },
    series: [
      {
        name: t("dashboard.employee"),
        type: 'bar',
        data: data,
        itemStyle: {
          color: '#4076e3'
        }
      }
    ]
  };
  return (
    <Card>
      <CardHeader
        title={t("dashboard.employeeReport")}
        action={
          <DatePicker
            value={date}
            views={["year"]}
            openTo={"year"}
            onChange={(value) => {
              setDate(value)
            }}
          />
        }
      />
      <CardContent>
        <ReactEcharts option={option}/>
      </CardContent>
    </Card>
  )
}
export default EmployeeTendencyChart

import { RJSFSchema, UiSchema } from "@rjsf/utils";
import ImportView from "@/components/views/ImportView";
import { useTranslation } from "react-i18next";
import AppUrls from "@/urls";
import { PsnlEP } from "@/services/psnl/endpoints";
import urls from "@/urls";


const ResignImportView = () => {
  const { t } = useTranslation("menu");

  const formSchema: RJSFSchema = {
    "type": "object",
    "required": [
      "import_file",
      "overwrite",
    ],
    "properties": {
      "import_file": {
        "type": "string",
        "title": t("common.button.upload", {ns: "common"}),
      },

      "overwrite": {
        "title": t("import.overwrite", {ns: "common"}),
        "type": "integer",
        "default": 0,
        "oneOf": [
          {const: 0, title: t("boolean.no", {ns: "common"}),},
          {const: 1, title: t("boolean.yes", {ns: "common"})},
        ]
      }
    }
  };

  const uiSchema: UiSchema = {
    'ui:defaultXsWidth': 4,
    "import_file": {
      'ui:widget': 'file',
    },
    "overwrite": {
      "ui:widget": "select",
    },
  }

  const templateDemoHeaders = [
    {field: "employee", title: t("employee.code", {ns: "per"}), required: true},
    {field: "resign_date", title: t("resign.date", {ns: "per"}), required: true},
    {field: "report_generation_end_date", title: t("resign.endDateOfReportGeneration", {ns: "per"}), required: true},
    {field: "resign_type", title: t("resign.type", {ns: "per"}), required: true},
    {field: "disableatt", title: t("employee.attendance", {ns: "per"})},
    {field: "reason", title: t("resign.reason", {ns: "per"}), required: true},
  ]
  const templateDemoRows = [
    ['NO.1', '2024-01-01', '2024-01-01', '1', '', 'true'],
    ['NO.2', '2024-01-01', '2024-01-01', '1', '', 'true'],
  ]

  return (
    <ImportView
      modelTitle={t("menu.team.resign")}
      title={t("common.action.import", {ns: "common"})}
      breadcrumbs={[
        {title: t("menu.company"), href: urls.team.root},
        { title: t("menu.team.resign"), href: AppUrls.team.resign }
      ]}
      formSchema={formSchema}
      uiSchema={uiSchema}
      uri={PsnlEP.RESIGN_URI}
      templateDemoHeaders={templateDemoHeaders}
      templateDemoRows={templateDemoRows}
      templateUrl={'/personnel/resign/import_template/'}
    />
  )
}
export default ResignImportView

import { GridColDef } from "@mui/x-data-grid";
import { useTranslation } from "react-i18next";
import AppUrls from "@/urls";
import ListView from "@/components/views/ListView";
import { PersonalEndPoint } from "@/services/personal/endpoints";
import ViewListIcon from "@mui/icons-material/ViewList";
import WorkflowView from "../components/WorkflowView";
import { Approval } from "@mui/icons-material";
import ApprovalView from "../components/ApprovalView";
import FilterPanel from "@/pages/Personal/request/components/FilterPanel";
import {useEffect, useState} from "react";
import GenericService from "@/services/core/GenericService";
import {AttEndPoint} from "@/services/att/endpoints";

const LeaveApprovalListView = () => {
  const { t } = useTranslation("att");
  const { t: menu } = useTranslation("menu");
  const { t: per } = useTranslation("per");
  const { t: common } = useTranslation("common");
  const columns: GridColDef[] = [
    { field: "emp_code", headerName: per("employee.code"), width: 120 },
    { field: "first_name", headerName: per("employee.firstName"), width: 100 },
    { field: "last_name", headerName: per("employee.lastName"), width: 100 },
    { field: "apply_time", headerName: common("approval.applyTime"), width: 160 },
    {
      field: "apply_reason",
      headerName: common("approval.applyReason"),
      width: 160,
    },
    { field: "leave_type", headerName: t("leave.category"), width: 140 },
    { field: "start_time", headerName: t("leave.startTime"), width: 160 },
    { field: "end_time", headerName: t("leave.endTime"), width: 160 },
    {
      field: "audit_status",
      headerName: common("approval.status"),
      type: "singleSelect",
      valueOptions: [
        {value: 1, label: common("approval.status.pending")},
        {value: 2, label: common("approval.status.approved")},
        {value: 3, label: common("approval.status.rejected")},
        {value: 6, label: common("approval.status.revoked")},
        {value: 7, label: common("approval.status.withdraw")},
      ],
      width: 140,
    },
    {
      field: "last_approver",
      headerName: common("approval.lastApprover"),
      width: 140,
    },
    {
      field: "audit_reason",
      headerName: common("approval.remark"),
      flex: 1,
    },
  ];
  const recordActions = [
    {
      id: "approval",
      title: common("approval.workflowView"),
      icon: <Approval />,
      display: "dialog",
      Form: ApprovalView,
      readOnly: true,
      state: {
        uri: PersonalEndPoint.LEAVE_URI,
        event: menu("menu.personal.leave"),
      },
    },
  ];

  const [leaveCategories, setLeaveCategories] = useState<GridColDef[]>([])
  useEffect(() => {
    GenericService.list(AttEndPoint.LEAVE_TYPE_URI, {
      page: 1,
      page_size: 50,
    })
      .then((response) => {
        const leaves: any = []
        response.data.data.forEach((each: any) => {
          leaves.push({value: `leave_${each.id}`, label: `${each.display_name}`})
        })
        setLeaveCategories(leaves)
      })
  }, [])

  return (
    <ListView
      recordActions={recordActions}
      title={menu("menu.personal.leave")}
      columns={columns}
      uri={PersonalEndPoint.LEAVE_URI}
      resource={"my_approval/"}
      url={AppUrls.personal.approval.leave}
      disableAdd={true}
      disableHistory={true}
      disableEdit={true}
      disableDelete={true}
      disableExport
      FilterPanel={FilterPanel}
      options={leaveCategories}
      categoryLabel={t("leave.category")}
      approvalFilter={true}
      auditStatus={true}
    />
  );
};
export default LeaveApprovalListView;

import { WidgetProps } from "@rjsf/utils";
import { InputAdornment, TextField } from "@mui/material";
import { useRef } from "react";
import { parseTime } from "@/utils/datetime";

const TimeWidget = (props: WidgetProps) => {
  const inputRef = useRef<any>();
  const { required, label, name, value, onChange, ...widgetProps } = props;
  return (
    <TextField
      inputRef={inputRef}
      required={required}
      label={label}
      name={name}
      value={value}
      onChange={async (event) => {
        onChange(event.target?.value);
      }}
      onBlur={async (event) => {
        const value = inputRef.current?.value;
        const timeStamp = parseTime(value);
        onChange(timeStamp);
      }}
      onKeyUp={async (event) => {
        if (event.code === "Enter") {
          const value = inputRef.current?.value;
          const timeStamp = parseTime(value);
          onChange(timeStamp);
        }
      }}
      InputProps={{
        endAdornment: <InputAdornment position={"end"}>HH:mm</InputAdornment>,
      }}
    />
  );
};
export default TimeWidget;

import {useTranslation} from "react-i18next";
import {GridColDef} from "@mui/x-data-grid";
import ListView from "@/components/views/ListView";
import AppUrls from "@/urls";
import {AccessControlEndPoint} from "@/services/acc/endpoints";
import SearchPanel from "@/pages/AccessControl/components/SearchPanel";
import ApprovalIcon from "@mui/icons-material/Approval";
import HolidayCloneAction from "@/pages/AccessControl/holiday/components/CloneAction";


const HolidayListView = () => {
  const {t} = useTranslation("acc")

  const columns: GridColDef[] = [
    {field: "holiday_name", headerName: t("holiday.name"), flex: 1},
    {field: "start_date", headerName: t("holiday.startDate"), flex: 1},
    {field: "duration_days", headerName: t("holiday.durationDays"), flex: 1},
    {field: "time_period", headerName: t("holiday.timePeriod"), flex: 1},
  ]

  const filters = [
    {key: 'holiday_name', label: t("holiday.name"), type: 'string'},
  ]

  const modelActions = [
    {
      id: "holidayClone",
      title: t("data.clone"),
      icon: <ApprovalIcon />,
      validateSelected: true,
      display: "dialog",
      Form: HolidayCloneAction,
    },
  ];
  return (
    <ListView
      title={t("menu.acc.holiday", {ns: "menu"})}
      breadcrumbs={[
        {title: t("menu.accessControl", {ns: "menu"}), href: AppUrls.acc.root},
      ]}
      columns={columns}
      uri={AccessControlEndPoint.HOLIDAY_URI}
      url={AppUrls.acc.holiday}
      filters={filters}
      SearchPanel={SearchPanel}
      modelActions={modelActions}
    />
  )
}
export default HolidayListView

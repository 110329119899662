import Grid from "@mui/material/Grid2";
import React, {ForwardedRef, useImperativeHandle, useState} from "react";
import {AdvancedMarker, APIProvider, Map, Marker} from "@vis.gl/react-google-maps";
import axios from "axios";
import dayjs from "dayjs";
import CustomAutoComplete from "@/provider/MapProvider/CustomAutoComplete";
const MapKey = "AIzaSyCcnm8KK8zUoUdMsaIxTQyUQXwPM7uOOqw"

const MapView = (props: Record<string, any>, ref: ForwardedRef<any>) => {
  const [mapInfo, setMapInfo] = useState<any>()
  const onPlaceSelect = async (place: Record<string, any>) => {
    const position = place?.geometry.location
    const response = await axios.get("https://maps.googleapis.com/maps/api/timezone/json", {
      headers: {
        'Content-type': 'application/json',
      },
      params: {
        key: MapKey,
        location: `${position.lat()},${position.lng()}`,
        timestamp: dayjs().unix()
      }
    })
    console.log(response)
    setMapInfo({
      place,
      timezone: response.data.timeZoneId
    })
  }

  useImperativeHandle(ref, () => ({
    onConfirm: () => {
      return mapInfo
    }
  }))
  const position = mapInfo?.place?.geometry.location || props.position

  return (
    <APIProvider
      apiKey={MapKey}
      libraries={['marker']}
    >
      <Grid
        sx={{
          width: "640px",
          minHeight: "352px"
        }}
      >
        <Grid>
          <CustomAutoComplete onPlaceSelect={onPlaceSelect}/>
        </Grid>
        <Grid
          sx={{
            width: "100%",
            height: "352px",
            marginTop: "8px"
          }}
        >
          <Map
            mapId={"companyLocation"}
            defaultCenter={props.position}
            defaultZoom={11}
            center={position}
            streetViewControl={false}
          >
            <AdvancedMarker
              position={position}
              title={mapInfo?.place?.formatted_address}
            />
          </Map>
        </Grid>
      </Grid>
    </APIProvider>
  )
}
export default React.forwardRef(MapView)

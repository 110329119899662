import {useTranslation} from "react-i18next";
import React, {useRef, useState} from "react";
import {CustomValidator, RJSFSchema, UiSchema} from "@rjsf/utils";
import PasswordWidget from "@/pages/Oauth/components/PasswordWidget";
import PhoneNumberWidget from "@/pages/Oauth/components/PhoneNumberWidget";
import {Box, Button, Checkbox, FormControlLabel, Typography} from "@mui/material";
import LayoutForm from "@/components/rjsf/Form";
import {useNavigate} from "react-router-dom";
import urls from "@/urls";
import Layout from "@/pages/Oauth/Layout";
import OauthService from "@/services/oauth/OauthService";
import SnackbarUtil from "@/utils/SnackbarUtil";
import EmailWidget from "@/pages/Oauth/components/EmailWidget";
import SubmitButton from "@/pages/Oauth/components/SubmitButton";
import {Stack} from "@mui/system";
import RegExps from "@/utils/RegExps";


const SignUpForm = () => {
  const {t} = useTranslation("oauth")
  const {t: api} = useTranslation("api")
  const navigate = useNavigate()
  const [loading, setLoading] = useState<boolean>(false)
  const [acceptTerms, setAcceptTerms] = useState<boolean>(false)
  const formRef = useRef<any>()
  const formSchema: RJSFSchema = {
    type: "object",
    properties: {
      email: {
        type: "string",
        title: t("oauth.email"),
      },
      phone_number: {
        type: "string",
        title: t("oauth.phoneNumber"),
      },
      password: {
        type: "string",
        title: t("oauth.password"),
      },
      confirm_password: {
        type: "string",
        title: t("oauth.confirmPassword")
      }
    },
    required: ["email", "phone_number", "password", "confirm_password"]
  }
  const customValidate: CustomValidator = (formData, errors, uiSchema) => {
    if(formData.email && !RegExps.email.test(formData.email)){
      errors.email?.addError(t("oauth.validateError.invalidEmail"))
    }
    if(formData.phone_number && !RegExps.phoneNumber.test(formData.phone_number)){
      errors.phone_number?.addError(t("oauth.validateError.invalidPhoneNumber"))
    }
    if(formData.password && !RegExps.password.test(formData.password)){
      errors.password?.addError(t("oauth.validateError.unsafePassword"))
    }
    if(formData.password && formData.confirm_password && formData.password !== formData.confirm_password){
      errors.confirm_password?.addError(t("oauth.validateError.mismatchPassword"))
    }
    return errors
  }

  const uiSchema: UiSchema = {
    email: {
      "ui:widget": "email",
      "ui:placeholder": t("oauth.email"),
    },
    phone_number: {
      "ui:widget": "phone_number",
      "ui:placeholder": t("oauth.phoneNumber"),
    },
    password: {
      "ui:widget": "password",
      "ui:placeholder": t("oauth.password"),
    },
    confirm_password: {
      "ui:widget": "password",
      "ui:placeholder": t("oauth.confirmPassword"),
    },
  }
  const widgets = {
    email: EmailWidget,
    phone_number: PhoneNumberWidget,
    password: PasswordWidget,
  }

  const onSignIn = async () => {
    navigate(urls.companyVerification)
  }

  const onSignUp = async () => {
    setLoading(true)
    const isValid = formRef.current?.validateForm()
    if (isValid) {
      const data = formRef.current?.state.formData
      const response = await OauthService.signUp(data).catch(() => setLoading(false))
      if (response.isSuccess) {
        setLoading(false)
        navigate(urls.emailActivation, {state: {email: data.email, action: "ACTIVATION"}})
      } else {
        console.log(response)
      }
    }
    setLoading(false)
  }

  const onTermOfService = async () => {
    console.log(111111)
  }

  const [formData, setFormData] = useState<Record<string, any>>({username: undefined, company: undefined})

  return (
    <Layout title={t("oauth.signUp.title")}>
      <LayoutForm
        ref={formRef}
        schema={formSchema}
        uiSchema={uiSchema}
        widgets={widgets}
        formData={formData}
        onChange={(e) => setFormData(e.formData)}
        customValidate={customValidate}
        formContext={{
          onSubmit: onSignUp
        }}
      />
      <FormControlLabel
        control={<Checkbox onChange={(event) => {
          setAcceptTerms(event.target.checked)
        }}/>}
        label={
          <Stack direction={"row"} sx={{alignItems: "center", flexWrap: "wrap"}}>
            <Typography variant={"body2"} sx={{whiteSpace: "nowrap"}}>{t("oauth.agreement")}</Typography>
            <Button variant={"text"} sx={{whiteSpace: "nowrap"}}>
              {t("oauth.termOfService")}
            </Button>
            <Typography variant={"body2"}>&</Typography>
            <Button variant={"text"} sx={{whiteSpace: "nowrap"}}>
              {t("oauth.privacyPolicy")}
            </Button>
          </Stack>
        }
        sx={{width: "100%"}}
      />
      <SubmitButton
        loading={loading}
        onClick={onSignUp}
        disabled={!acceptTerms}
      >
        {t("oauth.signUp")}
      </SubmitButton>
      <Box
        sx={{
          display: "flex",
          flexWrap: "nowrap",
          alignItems: "center",
          justifyContent: "center",
          margin: "16px 0"
        }}
      >
        <Typography>{t("oauth.signIn.ask")}</Typography>
        <Button onClick={onSignIn}>{t("oauth.signIn")}</Button>
      </Box>
    </Layout>
  )
}
export default SignUpForm

import {RJSFSchema, UiSchema} from "@rjsf/utils";
import {useTranslation} from "react-i18next";
import validator from "@rjsf/validator-ajv8";
import LayoutForm from "@/components/rjsf/Form";
import React, {ForwardedRef, useImperativeHandle, useRef, useState} from "react";
import SnackbarUtil from "@/utils/SnackbarUtil";
import {datetime2string} from "@/utils/datetime";
import DeviceService from "@/services/device/DeviceService";


const UpgradeFirmwareAction = (props: Record<string, any>, ref: ForwardedRef<any>) => {
  const {t} = useTranslation()
  const formRef = useRef<any>()
  const [formData, setFormData] = useState<Record<string, any>>({

  });

  const formSchema: RJSFSchema = {
    "type": "object",
    "required": [
      "upgrade_file",
    ],
    "properties": {
      "upgrade_file": {
        "type": "string",
        "title": t("upgrade.firmware.file", {ns: "device"}),
      },
    }
  }

  const uiSchema: UiSchema = {
    'ui:defaultXsWidth': 12,
    "ui:submitButtonOptions": {
      "norender": true,
    },
    "upgrade_file": {
      'ui:widget': 'file',
      "ui:wrap": true
    },
  };

  const onConfirm = async () => {
    const isValid = formRef.current?.validateForm()
    if (isValid) {
      const selectedIds = props.selectedIds
      const data = formRef.current?.state.formData
      const response = await DeviceService.upgradeFirmware({ids: selectedIds, ...data})
      if (response.isSuccess) {
        SnackbarUtil.success(t("common.successful", {ns: "common"}))
        return true
      } else {
        console.log(response)
      }
    }
  }

  useImperativeHandle(ref, () => ({
    onConfirm
  }))

  return (
    <LayoutForm
      ref={formRef}
      schema={formSchema}
      uiSchema={uiSchema}
      validator={validator}
      formData={formData}
    />
  )
}
export default React.forwardRef(UpgradeFirmwareAction)

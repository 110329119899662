import {GridColDef} from "@mui/x-data-grid";
import {useTranslation} from "react-i18next";
import AppUrls from "@/urls";
import ListView from "@/components/views/ListView";
import {PersonalEndPoint} from "@/services/personal/endpoints";
import WorkflowView from "@/pages/Personal/request/components/WorkflowView";
import {VisibilityOutlined} from "@mui/icons-material";
import FilterPanel from "@/pages/Personal/request/components/FilterPanel";

const ManualLogListView = () => {
  const {t} = useTranslation("att");
  const {t: menu} = useTranslation("menu");
  const {t: common} = useTranslation("common");
  const columns: GridColDef[] = [
    {
      field: "punch_date",
      headerName: t("manualLog.punchDate"),
      flex: 1
    },
    {
      field: "punch_time",
      headerName: t("manualLog.punchTime"),
      flex: 1
    },
    {
      field: "punch_state",
      headerName: t("manualLog.punchState"),
      flex: 1,
      type: "singleSelect",
      valueOptions: [
        {value: 0, label: common("punchState.checkIn")},
        {value: 1, label: common("punchState.checkOut")},
        {value: 2, label: common("punchState.breakOut")},
        {value: 3, label: common("punchState.breakIn")},
        {value: 4, label: common("punchState.overtimeIn")},
        {value: 5, label: common("punchState.overtimeOut")},
      ]
    },
    {
      field: "work_code",
      headerName: t("manualLog.workCode"),
      flex: 1
    },
    {
      field: "apply_reason",
      headerName: common("approval.applyReason"),
      flex: 1
    },
    {
      field: "audit_status",
      headerName: common("approval.status"),
      flex: 1,
      type: "singleSelect",
      valueOptions: [
        {value: 1, label: common("approval.status.pending")},
        {value: 2, label: common("approval.status.approved")},
        {value: 3, label: common("approval.status.rejected")},
        {value: 6, label: common("approval.status.revoked")},
        {value: 7, label: common("approval.status.withdraw")},
      ]
    },
    {
      field: "audit_reason",
      headerName: common("approval.remark"),
      flex: 1,
    },
  ]

  const recordActions = [
    {
      id: "workflowView",
      title: common("approval.workflowView"),
      icon: <VisibilityOutlined/>,
      display: "dialog",
      Form: WorkflowView,
      readOnly: true,
      state: {
        uri: PersonalEndPoint.MANUAL_LOG_URI,
        event: menu("menu.att.manualLog")
      }
    },
  ]

  const options = [
      {value: 0, label: common("punchState.checkIn")},
      {value: 1, label: common("punchState.checkOut")},
      {value: 2, label: common("punchState.breakOut")},
      {value: 3, label: common("punchState.breakIn")},
      {value: 4, label: common("punchState.overtimeIn")},
      {value: 5, label: common("punchState.overtimeOut")},
  ]

  return (
    <ListView
      title={menu("menu.att.manualLog")}
      columns={columns}
      recordActions={recordActions}
      uri={PersonalEndPoint.MANUAL_LOG_URI}
      resource={"my_request/"}
      url={AppUrls.personal.request.manualLog}
      disableHistory={true}
      disableExport={true}
      FilterPanel={FilterPanel}
      options={options}
      categoryLabel={t("manualLog.punchState")}
      auditStatus={true}
    />
  )
}
export default ManualLogListView

import React, {useEffect, useState} from "react";
import {Box, Typography} from "@mui/material";
import PhoneInTalkIcon from '@mui/icons-material/PhoneInTalk';
import {useTranslation} from "react-i18next";
import GenericService from "@/services/core/GenericService";
import {VendorEndPoint} from "@/services/vendor/endpoints";
import Grid from "@mui/material/Grid2";

const ContactUs = (props: Record<string, any>) => {
  const {t} = useTranslation("sub")
  const [vendor, setVendor] = useState<Record<string, any>>({})
  useEffect(() => {
    GenericService.action(VendorEndPoint.SUBSCRIPTION_URI, 'contact_us').then((response) => {
      setVendor(response.data)
    })
  }, [])

  return (
    <Grid container sx={{width: "448px", minHeight: "256px"}}>
      <Grid
        size={6}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column"
        }}
      >
        <Box>
          <Typography variant={"body1"}>{t('onlineSubscription.sales')}</Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
          }}
        >
          <PhoneInTalkIcon color={"primary"} fontSize={"large"}/>
          <Typography variant={"h4"} sx={{p: 1}}>{vendor.sales_contact}</Typography>
        </Box>
      </Grid>
      <Grid
        size={6}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column"
        }}
      >
        <Box>
          <Typography variant={"body1"}>{t('onlineSubscription.technicalSupport')}</Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
          }}
        >
          <PhoneInTalkIcon color={"primary"} fontSize={"large"}/>
          <Typography variant={"h4"} sx={{p: 1}}>{vendor.ts_contact}</Typography>
        </Box>
      </Grid>
      <Grid
        size={12}
        sx={{
          border: '1px solid grey',
          borderRadius: '5px',
        }}
      >
        <Typography variant="body1" sx={{textAlign: "left", borderSpacing: "5px", padding: "10px"}}>
          <b>{t('onlineSubscription.companyName')} :</b>{vendor.vendor}<br/>
          <b>{t('onlineSubscription.email')} :</b>{vendor.email}<br/>
          <b>P.O. Box:</b>{vendor.POBox}<br/>
          <b>{t('onlineSubscription.country')} :</b>{vendor.country}<br/>
          <b>{t('onlineSubscription.address')} :</b>{vendor.address}
        </Typography>
      </Grid>
    </Grid>
  )
}
export default ContactUs

import {Box, Chip, Tooltip, Typography, useTheme} from "@mui/material";
import {AdvancedMarker, APIProvider, Map} from "@vis.gl/react-google-maps";
import Const from "@/utils/Const";
import React, {useEffect, useMemo, useRef, useState} from "react";
import GenericService from "@/services/core/GenericService";
import {GPSEndPoint} from "@/services/gps/endpoints";
import {Stack} from "@mui/system";
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator
} from "@mui/lab";
import {AlarmOn, Business} from "@mui/icons-material";
import {useTranslation} from "react-i18next";
import dayjs from "dayjs";
import {AttEndPoint} from "@/services/att/endpoints";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import ModelActionLayout from "@/components/views/components/ModelActionLayout";


const CalendarPanel = (props: Record<string, any>) => {
  const calendarRef = useRef<any>()
  const { t } = useTranslation("common")
  const theme = useTheme()
  const getEvents = async (params: Record<string, any>) => {
    const response = await GenericService.action(AttEndPoint.EMPLOYEE_SCHEDULE_URI, "my_calendar", {
      start_date: dayjs(params.start).format("YYYY-MM-DD"),
      end_date: dayjs(params.end).format("YYYY-MM-DD"),
      employee_id: props.record?.id
    })
    return response.data.events
  }

  return (
    <ModelActionLayout
      title={t("common.employee")+":"+props?.record?.employee_name}
    >
      <FullCalendar
        ref={calendarRef}
        plugins={[dayGridPlugin, interactionPlugin]}
        initialView={"dayGridMonth"}
        headerToolbar={{
          start: "title",
          center: "",
          end: "prev,next"
        }}
        height={"100%"}
        selectable={true}
        eventColor={"#fff"}
        eventContent={(params) => {
          const event: any = params.event
          let backgroundColor = theme.palette.primary.main
          switch (event.extendedProps?.day_off) {
            case 2:
              backgroundColor = "green"
              break
            case 3:
              backgroundColor = "#a93ac4"
              break
            default:
              backgroundColor = theme.palette.primary.main
              break
          }
          return (
            <Tooltip title={event.title}>
              <Chip
                label={event.title}
                sx={{
                  color: "#fff",
                  width: "100%",
                  backgroundColor
                }}
              />
            </Tooltip>
          )
        }}
        events={getEvents}
      />
    </ModelActionLayout>
  )
}
export default CalendarPanel

import {WidgetProps} from "@rjsf/utils";
import GenericService from "@/services/core/GenericService";
import {AuthEP} from "@/services/auth/endpoints";
import {useEffect, useState} from "react";
import {Box, Tab, Tabs} from "@mui/material";
import {useTranslation} from "react-i18next";
import CompanyPermissionView from "@/pages/SystemSetting/Group/components/CompanyPermissionView";
import {TabContext, TabList} from "@mui/lab";
import EmployeePermissionView from "./EmployeePermissionView";
import DevicePermissionView from "./DevicePermissionView";
import AttendancePermissionView from "./AttendancePermissionView";
import AccessPermissionView from "./AccessPermissionView";
import ReportsPermissionView from "./ReportsPermissionView";
import SystemPermissionView from "./SystemPermissionView";


const PermissionWidget = (props: WidgetProps) => {
  const {t} = useTranslation('menu')
  const {label, name, value} = props
  const [tabValue, setTabValue] = useState('company');
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setTabValue(newValue);
  };

  const [itemKey, setItemKey] = useState<Record<string, any>>({});
  const [selecteds, setSelecteds] = useState<string[]>(value.map((each: number) => each.toString()));
  const [initSelecteds, setInitSelecteds] = useState<string[]>();

  const menus: Record<string, any>[] = [
    {key: "company", title: t("menu.company")},
    {key: "psnl", title: t("menu.employee")},
    {key: "device", title: t("menu.device")},
    {key: "att", title: t("menu.attendance")},
    {key: "acc", title: t("menu.accessControl")},
    {key: "report", title: t("menu.report")},
    {key: "sys", title: t("menu.systemSetting")},
  ]

  useEffect(() => {
    GenericService.list(AuthEP.PERMISSION_URI + 'group_permissions/', {
      page: 1,
      page_size: 1000,
    }).then((response) => {
      setItemKey(response.data)
    })
  }, [])

  useEffect(() => {
    setSelecteds(value.map((each: number) => each.toString()))
  }, [value])

  const handelStatusChange = (
    selected: string[],
    unSelected: string[],
  ) => {

    setSelecteds((old: any) => {
      const newSelecteds: Set<string> = new Set(old);
      selected.forEach(id => {
        newSelecteds.add(id)
      })
      unSelected.forEach(id => {
        newSelecteds.delete(id)
      })

      props.onChange(Array.from(newSelecteds))
      return Array.from(newSelecteds);
    })

  }

  return (
    <TabContext value={tabValue}>
      <TabList onChange={handleChange}>
        {menus.map(menu => {
          return <Tab key={menu.key} label={menu.title} value={menu.key}/>
        })}
      </TabList>
      <CompanyPermissionView itemKey={itemKey} onStatusChange={handelStatusChange} initSelecteds={selecteds} />
      <EmployeePermissionView itemKey={itemKey} onStatusChange={handelStatusChange} initSelecteds={selecteds} />
      <DevicePermissionView itemKey={itemKey} onStatusChange={handelStatusChange} initSelecteds={selecteds} />
      <AttendancePermissionView itemKey={itemKey} onStatusChange={handelStatusChange} initSelecteds={selecteds} />
      <AccessPermissionView itemKey={itemKey} onStatusChange={handelStatusChange} initSelecteds={selecteds} />
      <ReportsPermissionView itemKey={itemKey} onStatusChange={handelStatusChange} initSelecteds={selecteds} />
      <SystemPermissionView itemKey={itemKey} onStatusChange={handelStatusChange} initSelecteds={selecteds} />
    </TabContext>
  )
}
export default PermissionWidget

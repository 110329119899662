import GenericService from "@/services/core/GenericService";
import {AttEndPoint} from "@/services/att/endpoints";


const getGlobalRule = async () => {
  const response = await GenericService.action(AttEndPoint.ATT_RULE_URI, "global_rule", {})
  return response.data
}

const postGlobalRule = async (data: Record<string, any>) => {
  return await GenericService.action(AttEndPoint.ATT_RULE_URI, "global_rule", data)
}

const AttRuleService = {
  getGlobalRule,
  postGlobalRule,
}
export default AttRuleService

import {useTranslation} from "react-i18next";
import {GridColDef} from "@mui/x-data-grid";
import ListView from "@/components/views/ListView";
import {VendorEndPoint} from "@/services/vendor/endpoints";
import AppUrls from "@/urls";

const DeviceListView = () => {
  const {t} = useTranslation("vendor")
  const {t: menu} = useTranslation("menu")

  const columns: GridColDef[] = [
    {field: "sn", headerName: t("device.sn"), flex: 1},
    {field: "alias", headerName: t("device.alias"), flex: 1},
    {field: "ip_address", headerName: t("device.deviceIp"), flex: 1},
    {field: "real_status", headerName: t("device.state"), flex: 1},
    {field: "last_activity", headerName: t("device.lastActivity"), flex: 1},
    {field: "address", headerName: t("device.deviceAddress"), flex: 1},
    {field: "company", headerName: t("device.company"), flex: 1}
  ]

  const filters = [
    {key: 'alias', label: t("device.alias"), type: 'string'},
  ]


  return (
    <ListView
      title={menu("menu.tenement.device")}
      breadcrumbs={[
        {title: menu("menu.tenement"), href: AppUrls.tenement.root},
      ]}
      columns={columns}
      uri={VendorEndPoint.DEVICE_URI}
      url={AppUrls.vendor.device}
      filters={filters}
      disableDelete={true}
      disableEdit={true}
      disableAdd={true}
    />
  )
}
export default DeviceListView

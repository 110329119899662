import ListView from "@/components/views/ListView";
import {GridColDef} from "@mui/x-data-grid";
import AppUrls from "@/urls";
import {PsnlEP} from "@/services/psnl/endpoints";
import {useTranslation} from "react-i18next";
import SyncAltIcon from '@mui/icons-material/SyncAlt';
import TransferAction from "./components/TransferAction";
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';


const DepartmentListView = () => {
  const {t} = useTranslation("company")
  const {t: menu} = useTranslation("menu")
  const {t: common} = useTranslation("common")
  const displayName = (value: any) => value?.display_name
  const columns: GridColDef[] = [
    {field: "dept_code", headerName: t("department.code"), flex: 1},
    {field: "dept_name", headerName: t("department.name"), flex: 1},
    {field: "parent_dept", valueFormatter: displayName, headerName: t("department.superior"), flex: 1},
    {field: "employee_count", headerName: t("department.employeeCount"), flex: 1},
    {field: "resigned_count", headerName: t("department.resignCount"), flex: 1},
  ]

  const filters = [
    {key: 'dept_code', label: t("department.code"), type: 'string'},
    {key: 'dept_name', label: t("department.name"), type: 'string'},
  ]

  const actions = [
    {
      key: "transfer",
      title: t("department.personnelTransfer"),
      validateSelected: true,
      uniqueObjectRequired: true,
      icon: <SyncAltIcon/>,
      display: "dialog",
      Form: TransferAction
    },
    {
      id: "import", title: common("common.action.import"), icon: <FileUploadOutlinedIcon/>,
      display: "page", url: `${AppUrls.company.department}/import`,
    },
  ]

  return (
    <ListView
      title={menu("menu.company.department")}
      columns={columns}
      uri={PsnlEP.DEPARTMENT_URI}
      url={AppUrls.company.department}
      filters={filters}
      modelActions={actions}
    />
  )
}
export default DepartmentListView

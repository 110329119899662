import {WidgetProps} from "@rjsf/utils";
import {DataGrid, GridColDef, GridRenderCellParams, useGridApiRef} from "@mui/x-data-grid";
import {Box, Button, Drawer, Grid, IconButton} from "@mui/material";
import {Stack} from "@mui/system";
import {Add, DeleteOutline, EditOutlined} from "@mui/icons-material";
import {useState} from "react";
import {useTranslation} from "react-i18next";
import DocumentRecordView from "./DocumentRecordView";
import ArticleIcon from '@mui/icons-material/Article';
import { downloadFile } from "@/utils";

const DocumentWidget = (props: WidgetProps) => {
  const {t} = useTranslation("per");
  const gridRef = useGridApiRef()
  const rows = props.value
  const [drawer, setDrawer] = useState<any>({open: false, children: []})
  const renderRowActions = (params: GridRenderCellParams) => {
    return (
      <Stack spacing={1} direction={"row"}>
        {params.row.file?.startsWith('document') &&
        <IconButton color={"info"} onClick={async () => downloadFile(`/files/${params.row.file}`)}>
          <ArticleIcon/>
        </IconButton>}

        <IconButton color={"primary"} onClick={async () => await updateData(params)}>
          <EditOutlined/>
        </IconButton>
        <IconButton color={"error"} onClick={async () => await removeData(params)}>
          <DeleteOutline/>
        </IconButton>
      </Stack>
    )
  }
  const columns: GridColDef[] = [
    {field: "certification", type: "string", headerName: t("employee.document"), flex: 1},
    {field: "expire_on", type: "string", headerName: t("employee.document.expireOn"), flex: 1},
    {field: "email_alert", type: "string", headerName: t("employee.document.emailAlert"), flex: 1},
    {field: "before", type: "string", headerName: t("employee.document.alertBefore"), flex: 1},
    // {field: "file", type: "string", headerName: t("document.document"), flex: 1},
    {field: "", type: "actions", headerName: "", flex: 1, renderCell: renderRowActions}
  ]
  const onDiscard = () => {
    setDrawer({
      open: false,
      children: []
    })
  }

  const onAdd = (newData: Record<string, any>) => {
    // 新增同编辑，当certification为同一个时，就编辑
    const originalRows = [...props.value]
    // 检查originalRows中是否存在具有相同grant字段的对象
    const existingItem = originalRows.find(row => row.certification === newData.certification);
    if (existingItem) {
      // 如果存在，更新该对象
      // 然后使用...newData来更新或添加newData的属性
      const index = originalRows.indexOf(existingItem);
      originalRows[index] = {...existingItem, ...newData};
    } else {
      // 如果不存在，添加newData到originalRows
      originalRows.push(newData);
    }
    props.onChange(originalRows)
    onDiscard()
  }

  const addNode = async () => {
    setDrawer({
      open: true,
      children: [
        <DocumentRecordView onConfirm={onAdd} onDiscard={onDiscard}/>
      ]
    })
  }


  const onUpdate = async (newData: Record<string, any>) => {
    const originalRows = [...rows]
    const newRows = originalRows.map(row => {
      if (row.certification === newData.certification) {
        // 如果id相等，返回新对象(更新)
        return newData;
      } else {
        // 如果id不相等，返回原对象
        return row;
      }
    });
    props.onChange(newRows)
    onDiscard()
  }

  const updateData = async (params: GridRenderCellParams) => {
    setDrawer({
      open: true,
      children: [
        <DocumentRecordView onConfirm={onUpdate} onDiscard={onDiscard} formData={params.row}/>
      ]
    })
  }

  const removeData = async (params: GridRenderCellParams) => {
    const originalRows = [...rows]
    // 使用filter方法删除所有certification字段与params.row中相等的元素
    const updatedRows = originalRows.filter(row => row.certification !== params.row.certification);
    props.onChange(updatedRows)
  }

  return (
    <Grid>
      <Box sx={{padding: "8px 0"}}>
        <Stack spacing={1} direction={"row"}>
          <Button variant={"contained"} onClick={addNode} startIcon={<Add/>}>
            {t("employee.document.addDocument")}
          </Button>
        </Stack>
      </Box>
      <DataGrid
        apiRef={gridRef}
        columns={columns}
        rows={rows}
        autoHeight={true}
        hideFooter={true}
        getRowId={row => `${row.certification} ${row.expire_on}`}
      />
      <Drawer open={drawer.open} anchor={"right"}>
        {drawer.children}
      </Drawer>
    </Grid>
  )
}
export default DocumentWidget

import { Navigate, useRoutes } from "react-router-dom";
import TransactionReport from "./components/TransactionReport";
import ScheduledLogReport from "@/pages/Personal/report/components/ScheduledLogReport";
import TimeCardReport from "@/pages/Personal/report/components/TimeCardReport";
import StaffSummaryReport from "@/pages/Personal/report/components/StaffSummaryReport";
import TotalTimecardReport from "@/pages/Personal/report/components/TotalTimecardReport";
import IncidentDetailReport from "@/pages/Personal/report/components/IncidentDetailReport";
import BreakTimeReport from "@/pages/Personal/report/components/BreakTimeReport";
import MultipleTransactionReport from "@/pages/Personal/report/components/MultipleTransactionReport";

const ReportRouter = () => {
  const routes = useRoutes([
    { index: true, element: <Navigate to={"transaction"} replace /> },
    { path: "transaction", element: <TransactionReport /> },
    { path: "scheduled-log", element: <ScheduledLogReport /> },
    { path: "total-time-card", element: <TotalTimecardReport /> },
    { path: "staff-summary", element: <StaffSummaryReport /> },
    { path: "time-card", element: <TimeCardReport /> },
    { path: "incident-detail", element: <IncidentDetailReport /> },
    { path: "break-time", element: <BreakTimeReport /> },
    { path: "multiple-transaction", element: <MultipleTransactionReport /> },
  ]);
  return routes;
};
export default ReportRouter;

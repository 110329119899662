import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { Button, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { useEffect, useState } from "react";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import SnackbarUtil from "@/utils/SnackbarUtil";
import { useTranslation } from "react-i18next";
import { useReportViewContext } from "./ReportViewProvider";
import SearchIcon from "@mui/icons-material/Search";

const FilterPanel = () => {
  const { t } = useTranslation("report");
  const { updateFilterModel, filterType } = useReportViewContext();

  let initStartDate = dayjs().startOf("week");
  let initEndDate = dayjs().endOf("week");
  if (filterType === "month") {
    initStartDate = dayjs().startOf("month");
    initEndDate = dayjs().endOf("month");
    if (initEndDate > dayjs()) {
      initEndDate = dayjs();
    }
  }
  if (filterType === "year") {
    initStartDate = dayjs().startOf("year");
    initEndDate = dayjs().endOf("year");
  }

  const [dateRange, setDateRange] = useState({
    startDate: initStartDate,
    endDate: initEndDate,
  });
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Stack
        direction={"row"}
        spacing={2}
        sx={{
          alignItems: "center",
        }}
      >
        {filterType === "day" && (
          <>
            <DatePicker
              label={t("report.startDate")}
              value={dateRange.startDate}
              onChange={(value) => {
                if (value?.isAfter(dateRange.endDate)) {
                  SnackbarUtil.warning(
                    t("report.error.startDateBeforeEndDATE")
                  );
                } else if (value) {
                  setDateRange({ ...dateRange, startDate: value });
                }
              }}
              slotProps={{
                textField: {
                  size: "small",
                },
              }}
            />
            <Typography variant={"body1"}>-</Typography>
            <DatePicker
              label={t("report.endDate")}
              value={dateRange.endDate}
              onChange={(value) => {
                if (value?.isBefore(dateRange.startDate)) {
                  SnackbarUtil.warning(t("report.error.endDateAfterEndDATE"));
                } else if (value) {
                  setDateRange({ ...dateRange, endDate: value });
                }
              }}
              slotProps={{
                textField: {
                  size: "small",
                },
              }}
            />
          </>
        )}
        {filterType === "month" && (
          <>
            <DatePicker
              label={t("report.startDate")}
              value={dateRange.startDate}
              openTo="month"
              views={["year", "month"]}
              maxDate={dayjs()}
              onChange={(value) => {
                if (value) {
                  let startDate = value.startOf("month");
                  let endDate = value.endOf("month");
                  if (endDate > dayjs()) {
                    startDate = dayjs().startOf("month");
                    endDate = dayjs();
                  }
                  setDateRange({ startDate, endDate });
                }
              }}
              slotProps={{
                textField: {
                  size: "small",
                },
              }}
            />
          </>
        )}
        {filterType === "year" && (
          <>
            <DatePicker
              label={t("report.startDate")}
              value={dateRange.startDate}
              openTo="year"
              views={["year"]}
              maxDate={dayjs()}
              onChange={(value) => {
                if (value) {
                  let startDate = value.startOf("year");
                  let endDate = value.endOf("year");
                  if (endDate > dayjs()) {
                    startDate = dayjs().startOf("month");
                    endDate = dayjs();
                  }
                  setDateRange({ startDate, endDate });
                }
              }}
              slotProps={{
                textField: {
                  size: "small",
                },
              }}
            />
          </>
        )}
        <>
          <Button
            onClick={() => {
              if (
                dateRange.startDate &&
                dateRange.endDate &&
                !dateRange.startDate.isAfter(dateRange.endDate)
              ) {
                updateFilterModel({
                  category: true,
                  startDate: dateRange.startDate,
                  endDate: dateRange.endDate,
                });
              }
            }}
          >
            <SearchIcon />
          </Button>
        </>
      </Stack>
    </LocalizationProvider>
  );
};
export default FilterPanel;

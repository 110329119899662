import ListView from "@/components/views/ListView";
import {GridColDef} from "@mui/x-data-grid";
import AppUrls from "@/urls";
import {useTranslation} from "react-i18next";
import {ComplementEndpoint} from "@/services/complement/endpoints";


const DocumentListView = () => {
  const {t} = useTranslation("complement")
  const columns: GridColDef[] = [
    {field: "cert_code", headerName: t("document.code"), flex: 1},
    {field: "cert_name", headerName: t("document.name"), flex: 1},
  ]

  const filters = [
    {key: 'cert_code', label: t("document.code"), type: 'string'},
    {key: 'cert_name', label: t("document.name"), type: 'string'},
  ]

  return (
    <ListView
      title={t("menu.employeeDocument", {ns: "menu"})}
      columns={columns}
      uri={ComplementEndpoint.DOCUMENT_URI}
      url={AppUrls.complement.documentType}
      filters={filters}
    />
  )
}
export default DocumentListView

import { RJSFSchema, UiSchema } from "@rjsf/utils";
import { useTranslation } from "react-i18next";
import RecordView from "@/components/views/RecordView";
import urls from "@/urls";
import { AttEndPoint } from "@/services/att/endpoints";
import { ComplementEndpoint } from "@/services/complement/endpoints";
import { PsnlEP } from "@/services/psnl/endpoints";
import React from "react";
import {useLocation} from "react-router-dom";
import RecordViewLayout from "@/pages/Attendance/Training/components/RecordViewLayout";

const TrainingRecordView = () => {
  const { t: t } = useTranslation("att");
  const { t: tMenu } = useTranslation("menu");
  const params = useLocation()
  const mode = params.state?.mode || "ADD"
  const formSchema: RJSFSchema = {
    type: "object",
    required: [
      "employee",
      "start_date",
      "start_time",
      "end_date",
      "end_time",
      "category"
    ],
    properties: {
      employee: {
        type: "string",
        title: t("training.employee"),
        readOnly: mode === "EDIT"
      },
      category: {
        type: "number",
        title: t("training.trainingType"),
      },
      start_date: {
        type: "string",
        format: "date",
        title: t("report.startDate", {ns: 'report'}),
      },
      start_time: {
        type: "string",
        title: t("training.startTime"),
        default: ""
      },
      end_date: {
        type: "string",
        format: "date",
        title: t("report.endDate", {ns: 'report'}),
      },
      end_time: {
        type: "string",
        title: t("training.endTime"),
        default: ""
      },

      apply_reason: {
        type: ["string", 'null'],
        title: t("approval.applyReason", {ns: "common"}),
        maxLength: 200,
      },
    },
  };

  const uiSchema: UiSchema = {
    "ui:ObjectFieldTemplate": RecordViewLayout,
    "ui:defaultXsWidth": 6,
    "ui:defaultRowWrap": false,
    employee: {
      "ui:widget": "m2o",
      "ui:options": {
        uri: PsnlEP.EMPLOYEE_URI,
      },
    },
    category: {
      "ui:widget": "m2o",
      "ui:options": {
        uri: ComplementEndpoint.TRAINING_TYPE_URI,
      },
    },
    start_time: {
      'ui:widget': 'time',
      "ui:wrap": true
    },
    end_time: {
      'ui:widget': 'time',
      "ui:wrap": true
    },
    apply_reason: {
      "ui:widget": "textarea",
      "ui:emptyValue": ""
    },
  };

  return (
    <RecordView
      breadcrumbs={[
        { title: tMenu("menu.attendance"), href: urls.att.root },
        { title: tMenu("menu.att.training"), href: urls.att.training },
      ]}
      formSchema={formSchema}
      uiSchema={uiSchema}
      uri={AttEndPoint.TRAINING_URI}
      // onChange={onChange}
      // formData={formData}
    />
  );
};
export default TrainingRecordView;

import {useRoutes} from "react-router-dom";
import PrivateRoute from "@/router/PrivateRoute";
import FeatureListView from "@/pages/vendor/subscription/Feature/ListView";
import FeatureRecordView from "@/pages/vendor/subscription/Feature/RecordView";


const FeatureRouter = () => {
  const routes = useRoutes([
    {index: true, element: <PrivateRoute requireRoles={["agent", "vendor"]}><FeatureListView /></PrivateRoute>},
    {path: "add", element: <PrivateRoute requireRoles={["agent", "vendor"]}><FeatureRecordView /></PrivateRoute>},
    {path: "edit", element: <PrivateRoute requireRoles={["agent", "vendor"]}><FeatureRecordView /></PrivateRoute>},
  ])
  return routes
}
export default FeatureRouter

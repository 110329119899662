import {RegistryFieldsType, RJSFSchema, UiSchema} from "@rjsf/utils";
import {useTranslation} from "react-i18next";
import RecordView from "@/components/views/RecordView";
import {AttEndPoint} from "@/services/att/endpoints";
import LayoutTemplate from "./components/FromTemplate";
import React from "react";
import PatternDaysField from "./components/PatternDaysField";


const ShiftRecordView = () => {
  const WEEK = 1
  const DAY = 0
  const {t} = useTranslation("att");
  const formSchema: RJSFSchema = {
    "type": "object",
    "required": [
      'alias',
    ],
    "properties": {
      "alias": {
        "type": "string",
        "title": t("shift.alias"),
        "maxLength":50
      },
      "auto_shift": {
        "type": "number",
        "title": t("shift.autoShift"),
        "default": 0,
        "oneOf": [
          {const: 0, title: t("boolean.no", {ns: "common"}),},
          {const: 1, title: t("boolean.yes", {ns: "common"})},
        ],
      },
      "work_weekend": {
        "type": "boolean",
        "title": t("shift.workWeekend"),
      },
      pattern: {
        type: "object",
        properties: {
          id: {
            type: ["string", "null"],
          },
          time_period_type: {
            type: "number",
            title: t("shift.timePeriodType"),
            default: WEEK,
            oneOf: [
              {const: DAY, title: t("timePeriodType.day")},
              {const: WEEK, title: t("timePeriodType.week")}
            ],
          },
          time_period_cnt: {
            type: "number",
            default: 1,
            title: t("patternTemplate.field.timePeriodCount"),
          },
          pattern_days: {
            type: "array",
            items: {
              type: "object",
              properties: {
                day: {
                  type: "number", title: t("patternTemplate.field.day")
                },
                shift_template: {
                  type: "object",
                  properties: {
                    id: {
                      type: "number"
                    }
                  }
                }
              }
            }
          }
        },
        required: ["time_period_type", "time_period_cnt", "pattern_days"]
      }
    }
  };

  const uiSchema: UiSchema = {
    "ui:submitButtonOptions": {"norender": true},
    pattern: {
      "ui:field": "patternDays",
      "ui:xsWidth": 12
    },
  };
  const templates = {
    ObjectFieldTemplate: LayoutTemplate
  }
  const fields: RegistryFieldsType = {
    patternDays: PatternDaysField
  }

  const [formData, setFormData] = React.useState<Record<string, any>>({})
  const onChange = (e: any) => {
    setFormData({
      ...e.formData
    })
  }

  return (
    <RecordView
      formSchema={formSchema}
      uiSchema={uiSchema}
      uri={AttEndPoint.ATT_SHIFT_URI}
      templates={templates}
      onChange={onChange}
      formData={formData}
      fields={fields}
    />
  )
}
export default ShiftRecordView

import {useTranslation} from "react-i18next";
import ModelActionPage from "@/components/views/ListView/components/model-action/ModelActionPage";
import urls from "@/urls";
import {RJSFSchema} from "@rjsf/utils";
import {useEffect, useState} from "react";
import SystemSettingService from "@/services/base/SystemSettingService";
import SnackbarUtil from "@/utils/SnackbarUtil";


const EmailSetting = () => {
  const {t} = useTranslation("sys")
  const [formData, setFormData] = useState<Record<string, any>>({})
  const formSchema: RJSFSchema = {
    type: "object",
    properties: {
      smtp_server: {
        type: "string",
        title: t("emailSetting.smtpServer")
      },
      smtp_server_port: {
        type: "integer",
        title: t("emailSetting.smtpServerPort")
      },
      enable_ssl: {
        type: "boolean",
        title: t("emailSetting.SSL"),
        default: false
      },
      enable_tls: {
        type: "boolean",
        title: t("emailSetting.TSL"),
        default: false
      },
      email_account: {
        type: "string",
        title: t("emailSetting.emailAccount")
      },
      email_password: {
        type: "string",
        title: t("emailSetting.emailPassword")
      },
      email_address: {
        type: "string",
        title: t("emailSetting.emailAddress")
      }
    },
    required: ["smtp_server", "smtp_server_port", "email_account", "email_password", "email_address"]
  }
  const uiSchema = {
    "ui:defaultXsWidth": 4,
    enable_ssl: {
      "ui:widget": "switch"
    },
    enable_tls: {
      "ui:widget": "switch"
    },
    email_password: {
      "ui:widget": "password"
    }
  }
  const templates = {}
  const getEmailSetting = async () => {
    const response = await SystemSettingService.getEmailSetting()
    if(response.isSuccess){
      setFormData(response.data)
    }
  }

  const onSave = async (data: Record<string, any>) => {
    const response = await SystemSettingService.updateEmailSetting(data)
    if (response.isSuccess) {
      SnackbarUtil.success("Success")
    } else {
      SnackbarUtil.error(response?.code)
    }
  }

  useEffect(() => {
    getEmailSetting().then()
  }, [])

  return (
    <ModelActionPage
      title={t("menu.systemSetting.emailSetting", {ns: "menu"})}
      breadcrumbs={[
        {title: t("menu.systemSetting", {ns: "menu"}), href: urls.system.root},
      ]}
      formSchema={formSchema}
      uiSchema={uiSchema}
      templates={templates}
      formData={formData}
      disableDiscard={true}
      onSave={onSave}
    />
  )
}
export default EmailSetting

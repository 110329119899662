import {Box, Typography} from "@mui/material";
import {Stack} from "@mui/system";
import React from "react";


const SummaryCard = (props: Record<string, any>) => {
  return (
    <Stack
      direction={"row"}
      sx={{
        backgroundColor: "#fff",
        alignItems: "center",
        borderRadius: "4px",
      }}
    >
      <Box sx={{p: 2}}>
        <props.icon color={"primary"} sx={{width: "48px", height: "48px"}}/>
      </Box>
      <Stack spacing={0.5}>
        <Typography color={"primary"}>{props.title}</Typography>
        <Typography variant={"h3"} color={"textPrimary"}>{props.summary}</Typography>
      </Stack>
    </Stack>
  )
}
export default SummaryCard

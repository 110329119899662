import {useTranslation} from "react-i18next";
import {GridColDef, GridRenderCellParams} from "@mui/x-data-grid";
import urls from "@/urls";
import {ReportEndPoint} from "@/services/report/endpoints";
import ReportView from "@/pages/Report/ReportLibrary/components/ReportView";
import {useEffect, useState} from "react";
import GenericService from "@/services/core/GenericService";
import {AttEndPoint} from "@/services/att/endpoints";


const DepartmentSummaryReport = () => {
  const {t} = useTranslation("report")
  const fixedColumns: GridColDef[] = [
    {
      field: "dept_name",
      headerName: t("report.column.department"),
      minWidth: 120
    },
    {
      field: "total_emp",
      headerName: t("report.column.totalEmployee"),
    },

    {
      field: "total_late",
      headerName: t("report.column.late"),
    },
    {
      field: "total_early_leave",
      headerName: t("report.column.earlyLeave"),
    },
    {
      field: "total_absent_times",
      headerName: t("report.column.absent"),
    },
    {
      field: "late_times",
      headerName: t("report.column.lateTimes"),
    },
    {
      field: "total_work",
      headerName: t("report.column.actualWork"),
    },
    {
      field: "total_not",
      headerName: t("report.column.normalOT"),
    },
    {
      field: "total_wot",
      headerName: t("report.column.weekOffOT"),
    },
    {
      field: "total_hot",
      headerName: t("report.column.holidayOT"),
    },
  ]

  const [leaveColumns, setLeaveColumns] = useState<GridColDef[]>([])
  const [columns, setColumns] = useState<GridColDef[]>(fixedColumns)

  useEffect(() => {
    GenericService.list(AttEndPoint.LEAVE_TYPE_URI, {
      page: 1,
      page_size: 50,
    })
      .then((response) => {
        const leaves: any = []
        response.data.data.forEach((each: any) => {
          leaves.push({field: `leave_${each.id}`, headerName: `${each.display_name}`})
        })
        setLeaveColumns(leaves)
      })
  }, [])

    useEffect(() => {
    setColumns((old) => {
      return [
        ...fixedColumns,
        ...leaveColumns
      ]
    })
  }, [leaveColumns])


  return (
    <ReportView
      title={t("reportLibrary.departmentSummary")}
      breadcrumbs={[]}
      columns={columns}
      uri={ReportEndPoint.DEPARTMENT_SUMMARY_URI}
      url={urls.report.departmentSummary}
      getRowId={(row: any) => row.dept_code}
      wiseFields={[]}
      departmentReport={true}
    />
  )
}
export default DepartmentSummaryReport

import i18next from "i18next";
import I18NextHttpBackend from "i18next-http-backend";
import I18nextBrowserLanguageDetector from "i18next-browser-languagedetector";
import {initReactI18next} from "react-i18next";

i18next
  .use(I18NextHttpBackend)
  .use(I18nextBrowserLanguageDetector)
  .use(initReactI18next)
  .init({
    debug: false,
    detection: {
      lookupLocalStorage: "i18nextLng"
    },
    fallbackLng: "en",
    backend: {
      loadPath: "/locales/{{lng}}/{{ns}}.json",
    },

    ns: ["default", "common", "menu", "per", "activity", "device", "att", "acc", "setting", "report", "tenant", "sub",
      "vendor","ralvie", "gps", "complement", "sys", "api"],
    defaultNS: "default",
    keySeparator: ".",
  })
export default i18next

import GenericService from "@/services/core/GenericService";
import {RalvieEndPoint} from "@/services/ralvie/endpoints";

const getAppCategories = async (params: Record<string, any>) => {
  const response = await GenericService.post(RalvieEndPoint.DASHBOARD_APP_CATEGORY_URI, params)
  return response
}

const getProjectHours = async (params: Record<string, any>) => {
  const response = await GenericService.post(RalvieEndPoint.DASHBOARD_PROJECT_HOURS_URI, params)
  return response
}

const getWeeklyHours = async (params: Record<string, any>) => {
  const response = await GenericService.post(RalvieEndPoint.DASHBOARD_WEEKLY_HOURS_URI, params)
  return response
}

const getMostUsedApps = async (params: Record<string, any>) => {
  const response = await GenericService.post(RalvieEndPoint.DASHBOARD_MOST_USED_APP_URI, params)
  return response
}


const DashboardService = {
  getAppCategories,
  getProjectHours,
  getWeeklyHours,
  getMostUsedApps
}
export default DashboardService

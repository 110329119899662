import {MainIconButton} from "@/components/styled"
import HistoryIcon from '@mui/icons-material/History';
import {Tooltip} from "@mui/material";
import {useNavigate} from "react-router-dom";

const HistoryAction = (props: Record<any, any>) => {
  const navigate = useNavigate()

  return <Tooltip title={"History"}>
    <MainIconButton onClick={() => {
      navigate('history', {
        state: {
          uri: props.uri,
          breadcrumbs: props.breadcrumbs,
        }
      })
    }}>
      <HistoryIcon/>
    </MainIconButton>
  </Tooltip>
}

export default HistoryAction

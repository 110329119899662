import React, {ForwardedRef, useImperativeHandle, useRef} from "react";
import {useTranslation} from "react-i18next";
import {RJSFSchema, UiSchema} from "@rjsf/utils";
import LayoutForm from "@/components/rjsf/Form";
import SnackbarUtil from "@/utils/SnackbarUtil";
import DeviceService from "@/services/device/DeviceService";


const SyncData2Device = (props: Record<any, any>, ref: ForwardedRef<any>) => {
  const {t} = useTranslation("device");
  const {t: common} = useTranslation("common");
  const formRef = useRef<any>()
  const formSchema: RJSFSchema = {
    type: "object",
    properties: {
      employee: {
        type: "boolean",
        title: t("device.syncData2Device.employee"),
        default: false
      },
      photo: {
        type: "boolean",
        title: t("device.syncData2Device.photo"),
        default: false
      },
      fingerprint: {
        type: "boolean",
        title: t("device.syncData2Device.fingerprint"),
        default: false
      },
      face: {
        type: "boolean",
        title: t("device.syncData2Device.face"),
        default: false
      },
      palm: {
        type: "boolean",
        title: t("device.syncData2Device.palm"),
        default: false
      },
      finger_vein: {
        type: "boolean",
        title: t("device.syncData2Device.fingerVein"),
        default: false
      }
    },
  }

  const uiSchema: UiSchema = {
    "ui:defaultXsWidth": 6,
    "ui:defaultRowWrap": false,
  }

  const onConfirm = async () => {
    const isValid = formRef.current?.validateForm()
    if (isValid) {
      const data = formRef.current?.state.formData
      const response = await DeviceService.bulkSyncData({...data, devices: props.selectedIds})
      if (response.isSuccess) {
        SnackbarUtil.success(common("common.successful"))
        return true
      } else {
        console.log(response)
        return false
      }
    }
  }

  useImperativeHandle(ref, () => ({
    onConfirm
  }))

  return (
    <LayoutForm
      ref={formRef}
      schema={formSchema}
      uiSchema={uiSchema}
    />
  )
}
export default React.forwardRef(SyncData2Device)

import {useTranslation} from "react-i18next";
import {GridColDef} from "@mui/x-data-grid";
import ListView from "@/components/views/ListView";
import AppUrls from "@/urls";
import {DeviceEndPoint} from "@/services/device/endpoints";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";

import SnackbarUtil from "@/utils/SnackbarUtil";
import PrivateMessageService from "@/services/device/PrivateMessageService";

const PrivateMassageListView = () => {
  const {t} = useTranslation("device");
  const {t: per} = useTranslation("per");
  const columns: GridColDef[] = [
    {field: "emp_code", headerName: per("employee.code"), flex: 1},
    {field: "first_name", headerName: per("employee.firstName"), flex: 1},
    {field: "last_name", headerName: per("employee.lastName"), flex: 1},
    {
      field: "start_time",
      headerName: t("privateMessage.startTime"),
      flex: 1,
    },
    {field: "duration", headerName: t("privateMessage.duration"), flex: 1},
    {field: "content", headerName: t("privateMessage.content"), flex: 1},
    {field: "last_send", headerName: t("privateMessage.lastSend"), flex: 1},
  ];

  const filters = [
    {key: "employee", label: t("privateMessage.employee"), type: "string"},
  ];

  const onSendPrivateMessage = async (selectedIds: string) => {
    const response = await PrivateMessageService.sendMessage({
      ids: selectedIds,
    });
    if (response.isSuccess) {
      SnackbarUtil.success(t("common.message.successful", {ns: "common"}));
      return true;
    } else {
      console.log(response);
      return false;
    }
  };

  const modelActions = [
    {
      id: "privateMessageSendMessage",
      title: t("privateMessage.sendMessage"),
      icon: <ExitToAppIcon/>,
      display: "dialog",
      callback: onSendPrivateMessage,
      confirmation: t("privateMessage.sendMessageAction.confirm"),
      validateSelected: true,
    },
  ];

  return (
    <ListView
      title={t("menu.device.privateMessage", {ns:"menu"})}
      breadcrumbs={[
        {title: t("menu.device.message", {ns:"menu"}), href: AppUrls.device.root},
      ]}
      columns={columns}
      uri={DeviceEndPoint.PRIVATE_MESSAGE_URI}
      url={AppUrls.device.privateMessage}
      filters={filters}
      excludeActions={["add", "delete"]}
      modelActions={modelActions}
    />
  );
};
export default PrivateMassageListView;

import {useLocation} from "react-router-dom";
import {DataGrid, GridColDef, GridRenderCellParams, useGridApiRef} from "@mui/x-data-grid";
import {Box} from "@mui/material";
import React, {useEffect, useState} from "react";
import ViewBreadcrumbs from "@/components/views/components/ViewBreadcrumbs";
import {Stack} from "@mui/system";
import GenericService from "@/services/core/GenericService";
import RefreshAction from "@/components/views/components/RefreshAction";
import FiltersAction from "@/components/views/components/FiltersAction";
import Grid from "@mui/material/Grid2";
import {useTranslation} from "react-i18next";
import {CancelOutlined, CheckCircleOutlined} from "@mui/icons-material";


const HistoryView = (props: Record<string, any>) => {
  const {t} = useTranslation("sys")
  const params = useLocation()
  const breadcrumbs = params.state?.breadcrumbs
  const uri = params.state?.uri
  const [loading, setLoading] = useState<boolean>(false);
  const [gridData, setGridData] = useState<any>({data: [], total: 0})
  const [paginationModel, setPaginationModel] = useState<{ page: number, pageSize: number }>({page: 0, pageSize: 10})
  const gridApiRef = useGridApiRef()

  const renderUserName = (params: GridRenderCellParams) => {
    return params.value || params.row?.user_email
  }

  const renderStatus = (params: GridRenderCellParams) => {
    switch (params.value) {
      case 0:
        return <CheckCircleOutlined color={"primary"}/>
      case 1:
        return <CancelOutlined color={"error"}/>
      default:
        return params.value
    }
  }

  const columns: GridColDef[] = [
    {field: "user_name", headerName: t("actionLog.user"), flex: 1, renderCell: renderUserName},
    {field: "op_time", headerName: t("actionLog.actionTime"), flex: 1},
    {field: "action", headerName: t("actionLog.action"), flex: 1},
    {field: "targets_repr", headerName: t("actionLog.object"), flex: 1},
    {field: "description", headerName: t("actionLog.description"), flex: 1},
    {field: "ip_address", headerName: t("actionLog.ipAddress"), flex: 1},
    {field: "action_status", headerName: t("actionLog.status"), flex: 1, renderCell: renderStatus},
  ]
  const [filterParams, setFilterParams] = useState(props.defaultParams)
  const refreshData = async () => {
    setLoading(true)
    const response = await GenericService.get( `${uri}history/`, {
      page: paginationModel.page + 1,
      page_size: paginationModel.pageSize,
      ...filterParams,
    })
    setGridData({
      data: response.data.data,
      total: response.data.total
    })
    setLoading(false)
  }

  const filters = [
    {key: 'user__first_name', label: t("actionLog.user"), type: 'string'},
    {key: 'op_time', label: t("actionLog.actionTime"), type: 'date'},
    {key: 'action', label: t("actionLog.action"), type: 'string'},
    {key: 'targets_repr', label: t("actionLog.object"), type: 'string'},
    {key: 'ip_address', label: t("actionLog.ipAddress"), type: 'string'},
  ]

  useEffect(() => {
    refreshData().then()
  }, [paginationModel, filterParams]);

  return (
    <Grid container spacing={1} sx={{height: "100%", overflowY: "hidden"}}>
      <Grid size={12} sx={{
        height: "48px",
        display: "flex",
        alignItems: "center"
      }}>
        <ViewBreadcrumbs
          title={"History"}
          breadcrumbs={breadcrumbs}
        />
      </Grid>
      <Grid size={12} sx={{display: "flex"}}>
        <Grid sx={{flex: 1}}>
          <Grid>
            <></>
            <Stack direction={"row"} gap={1}>
              {<FiltersAction
                filters={filters}
                filterModel={filterParams}
                onFilterModelChange={(model: any) => {
                  setFilterParams(model)
                }}
              />}
              <Box sx={{flexGrow: 1}}/>
              <RefreshAction onRefresh={refreshData}/>
            </Stack>
          </Grid>
          <Grid sx={{mt: 1}}>
            <DataGrid
              loading={loading}
              apiRef={gridApiRef}
              sx={{
                height: "calc(100vh - 64px - 48px - 64px)",
                backgroundColor: "#fff",
                '.MuiDataGrid-cell:focus': {outline: 'none'},
                '.MuiDataGrid-cell:focus-within': {outline: 'none'},
                '.MuiDataGrid-columnHeader:focus': {outline: 'none'},
              }}
              columns={columns}
              pagination={true}
              paginationMode={"server"}
              pageSizeOptions={[5, 10, 20, 50]}
              disableRowSelectionOnClick
              disableColumnSorting
              disableColumnMenu
              paginationModel={paginationModel}
              onPaginationModelChange={(model: any, details: any) => {
                setPaginationModel(model)
              }}
              rows={gridData.data}
              rowCount={gridData.total}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}
export default HistoryView

import {ObjectFieldTemplateProps} from "@rjsf/utils";
import {useTranslation} from "react-i18next";
import {ScrollTab, ScrollTabs, useScrollTabsHook} from "@/components/ScrollTabs";
import Grid from "@mui/material/Grid2";
import {Stack} from "@mui/material";


const SettingLayout = (props: ObjectFieldTemplateProps) => {
  const {t} = useTranslation("sys")
  const fields: Record<string, any> = {}
  props.properties?.forEach((element: any) => {
    fields[element.name] = element.content
  })
  const [tabs, getTabProps] = useScrollTabsHook([
    {key: 'attExceptionAlert', title: t("alertSetting.attExceptionAlert")},
    {key: 'exceptionEmailAlert', title: t("alertSetting.exceptionEmailAlert")},
    {key: 'pwdChangeAlert', title: t("alertSetting.pwdChangeAlert")},
    {key: 'deviceOfflineAlert', title: t("alertSetting.deviceOfflineAlert")},
    {key: 'approveAlert', title: t("alertSetting.approveAlert")},
    {key: 'autoCalculation', title: t("alertSetting.autoCalculation")},
  ])
  return (
    <ScrollTabs tabs={tabs}>
      <Stack spacing={2}>
        <ScrollTab {...getTabProps('attExceptionAlert')} >
          <Grid container spacing={2}>
            <Grid size={4}>{fields.late_exceed}</Grid>
            <Grid size={4}>{fields.early_leave_exceed}</Grid>
            <Grid size={4}>{fields.absent_exceed}</Grid>
          </Grid>
        </ScrollTab>
        <ScrollTab {...getTabProps('emailAlert')} >
          <Grid container spacing={2}>
            <Grid size={4}>{fields.email_frequency}</Grid>
            <Grid size={4}>{fields.sending_day}</Grid>
            <Grid size={4}/>
            <Grid size={4}>{fields.email_day}</Grid>
            <Grid size={4}>{fields.email_time}</Grid>
          </Grid>
        </ScrollTab>
        <ScrollTab {...getTabProps('pwdChangeAlert')} >
          <Grid container spacing={2}>
            <Grid size={4}>{fields.adminpasschange_alert}</Grid>
          </Grid>
        </ScrollTab>
        <ScrollTab {...getTabProps('deviceOfflineAlert')} >
          <Grid container spacing={2}>
            <Grid size={4}>{fields.deviceoffline_alert}</Grid>
          </Grid>
        </ScrollTab>
        <ScrollTab {...getTabProps('approveAlert')} >
          <Grid container spacing={2}>
            <Grid size={4}>{fields.email_alert}</Grid>
            <Grid size={4}>{fields.whatsapp_alert}</Grid>
            <Grid size={4}>{fields.popup_alert}</Grid>
          </Grid>
        </ScrollTab>
        <ScrollTab {...getTabProps('autoCalculation')} >
          <Grid container spacing={2}>
            <Grid size={4}>{fields.autocalculation_alert}</Grid>
            <Grid size={4}>{fields.autocalculation_alert_time}</Grid>
          </Grid>
        </ScrollTab>
      </Stack>
    </ScrollTabs>
  )
}
export default SettingLayout

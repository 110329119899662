import {RJSFSchema, UiSchema} from "@rjsf/utils";
import {useTranslation} from "react-i18next";
import validator from "@rjsf/validator-ajv8";
import LayoutForm from "@/components/rjsf/Form";
import React, {ForwardedRef, useImperativeHandle, useRef, useState} from "react";
import SnackbarUtil from "@/utils/SnackbarUtil";
import {PsnlEP} from "@/services/psnl/endpoints";
import DataCloneService from "@/services/acc/DataCloneService";
import {Box} from "@mui/material";


const GroupCloneAction = (props: Record<string, any>, ref: ForwardedRef<any>) => {
  const {t} = useTranslation('acc')
  const formRef = useRef<any>()
  const [formData, setFormData] = useState<Record<string, any>>({});

  const formSchema: RJSFSchema = {
    "type": "object",
    "required": [
      "areas",
    ],
    "properties": {
      "areas": {
        "type": "array",
        "items": {
          "type": "number"
        },
        "title": t("timePeriod.area"),
      },
      "remark": {
        "type": "string",
        "title": t("data.clone.remark")
      }
    }
  }

  const uiSchema: UiSchema = {
    'ui:defaultXsWidth': 12,
    "ui:submitButtonOptions": {
      "norender": true,
    },
    areas: {
      "ui:widget": "m2m",
      "ui:options": {
        uri: PsnlEP.ACCESS_AREA_URI,
      },
    },
    remark: {
      "ui:widget": "textarea",
    },
  };

  const onConfirm = async () => {
    const isValid = formRef.current?.validateForm()
    if (isValid) {
      const selectedIds = props.selectedIds
      const data = formRef.current?.state.formData
      const response = await DataCloneService.cloneGroup({ids: selectedIds, ...data})
      if (response.isSuccess) {
        SnackbarUtil.success(t("common.successful"))
        return true
      } else {
        console.log(response)
      }
    }
  }

  useImperativeHandle(ref, () => ({
    onConfirm
  }))

  return (
    <Box sx={{width: '400px'}}>
      <LayoutForm
        ref={formRef}
        schema={formSchema}
        uiSchema={uiSchema}
        validator={validator}
        formData={formData}
      />
    </Box>
  )
}
export default React.forwardRef(GroupCloneAction)

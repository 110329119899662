import { WidgetProps } from "@rjsf/utils";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

const DateTimeNoTZWidget = (props: WidgetProps) => {
  const format = "YYYY-MM-DD HH:mm:ss";

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DateTimePicker
        label={props.label}
        name={props.name}
        format={format}
        value={props.value ? dayjs(props.value) : null}
        onChange={(value) => {
          if (value) {
            props.onChange(value.format(format))
          }
        }}
        slotProps={{
          textField: {
            required: props.required,
            disabled: props.disabled,
          },
        }}
      />
    </LocalizationProvider>
  );
};
export default DateTimeNoTZWidget;

import {GridColDef} from "@mui/x-data-grid";
import {useTranslation} from "react-i18next";
import AppUrls from "@/urls";
import ListView from "@/components/views/ListView";
import {PersonalEndPoint} from "@/services/personal/endpoints";
import {Approval, VisibilityOutlined} from "@mui/icons-material";
import ApprovalView from "@/pages/Personal/my-approval/components/ApprovalView";
import {useEffect, useState} from "react";
import GenericService from "@/services/core/GenericService";
import {ComplementEndpoint} from "@/services/complement/endpoints";
import FilterPanel from "@/pages/Personal/request/components/FilterPanel";

const TrainingApprovalListView = () => {
  const {t} = useTranslation("att");
  const {t: menu} = useTranslation("menu");
  const {t: per} = useTranslation("per");
  const {t: common} = useTranslation("common");
  const columns: GridColDef[] = [
    {field: "emp_code", headerName: per("employee.code"), width: 120},
    {field: "first_name", headerName: per("employee.firstName"), width: 100},
    {field: "last_name", headerName: per("employee.lastName"), width: 100},
    {field: "apply_time", headerName: common("approval.applyTime"), width: 160},
    {field: "training_type", headerName: t("training.trainingType"), width: 140},
    {field: "start_time", headerName: t("training.startTime"), width: 160},
    {field: "end_time", headerName: t("training.endTime"), width: 160},
    {
      field: "audit_status",
      headerName: common("approval.status"),
      type: "singleSelect",
      valueOptions: [
        {value: 1, label: common("approval.status.pending")},
        {value: 2, label: common("approval.status.approved")},
        {value: 3, label: common("approval.status.rejected")},
        {value: 6, label: common("approval.status.revoked")},
        {value: 7, label: common("approval.status.withdraw")},
      ],
      width: 140
    },
    {field: "last_approver", headerName: common("approval.lastApprover"), width: 140},
    {
      field: "audit_reason",
      headerName: common("approval.remark"),
      flex: 1
    },
  ]

  const recordActions = [
    {
      id: "approval",
      title: common("approval.workflowView"),
      icon: <Approval />,
      display: "dialog",
      Form: ApprovalView,
      readOnly: true,
      state: {
        uri: PersonalEndPoint.TRAINING_URI,
        event: menu("menu.personal.training")
      }
    },
  ]

  const [trainingCategories, setTrainingCategories] = useState<GridColDef[]>([])
  useEffect(() => {
    GenericService.list(ComplementEndpoint.TRAINING_TYPE_URI, {
      page: 1,
      page_size: 50,
    })
      .then((response) => {
        const leaves: any = []
        response.data.data.forEach((each: any) => {
          leaves.push({value: `leave_${each.id}`, label: `${each.display_name}`})
        })
        setTrainingCategories(leaves)
      })
  }, [])

  return (
    <ListView
      title={menu("menu.personal.training")}
      columns={columns}
      uri={PersonalEndPoint.TRAINING_URI}
      resource={"my_approval/"}
      url={AppUrls.personal.approval.training}
      recordActions={recordActions}
      disableAdd={true}
      disableHistory={true}
      disableEdit={true}
      disableDelete={true}
      disableExport
      FilterPanel={FilterPanel}
      options={trainingCategories}
      categoryLabel={t("training.trainingType")}
      approvalFilter={true}
      auditStatus={true}
    />
  )
}
export default TrainingApprovalListView

import {GridColDef} from "@mui/x-data-grid";
import {useTranslation} from "react-i18next";
import AppUrls from "@/urls";
import ListView from "@/components/views/ListView";
import {PersonalEndPoint} from "@/services/personal/endpoints";
import {VisibilityOutlined} from "@mui/icons-material";
import WorkflowView from "@/pages/Personal/request/components/WorkflowView";
import FilterPanel from "@/pages/Personal/request/components/FilterPanel";

const OvertimeListView = () => {
  const {t} = useTranslation("att");
  const {t: menu} = useTranslation("menu");
  const {t: common} = useTranslation("common");
  const columns: GridColDef[] = [
    { field: "overtime_type", headerName: t("overtime.overtimeType"), flex: 1,
      type: "singleSelect",
      valueOptions: [
        { value: 1, label: t("overtime.option.normalOT") },
        { value: 2, label: t("overtime.option.weekendOT") },
        { value: 3, label: t("overtime.option.holidayOT") },
      ]
    },
    {
      field: "start_time",
      headerName: t("overtime.startTime"),
      flex: 1
    },
    {
      field: "end_time",
      headerName: t("overtime.endTime"),
      flex: 1,
    },
    {
      field: "apply_reason",
      headerName: common("approval.applyReason"),
      flex: 1,
    },
    {
      field: "audit_status",
      headerName: common("approval.status"),
      flex: 1,
      type: "singleSelect",
      valueOptions: [
        {value: 1, label: common("approval.status.pending")},
        {value: 2, label: common("approval.status.approved")},
        {value: 3, label: common("approval.status.rejected")},
        {value: 6, label: common("approval.status.revoked")},
        {value: 7, label: common("approval.status.withdraw")},
      ]
    },
    {
      field: "audit_reason",
      headerName: common("approval.remark"),
      flex: 1,
    },
  ]

  const recordActions = [
    {
      id: "workflowView",
      title: common("approval.workflowView"),
      icon: <VisibilityOutlined/>,
      display: "dialog",
      Form: WorkflowView,
      readOnly: true,
      state: {
        uri: PersonalEndPoint.OVERTIME_URI,
        event: menu("menu.att.overtime")
      }
    },
  ]

  const options = [
      { value: 1, label: t("overtime.option.normalOT") },
      { value: 2, label: t("overtime.option.weekendOT") },
      { value: 3, label: t("overtime.option.holidayOT") },
  ]

  return (
    <ListView
      title={menu("menu.att.overtime")}
      columns={columns}
      recordActions={recordActions}
      uri={PersonalEndPoint.OVERTIME_URI}
      resource={"my_request/"}
      url={AppUrls.personal.request.overtime}
      disableHistory={true}
      FilterPanel={FilterPanel}
      options={options}
      categoryLabel={t("overtime.overtimeType")}
      auditStatus={true}
    />
  )
}
export default OvertimeListView

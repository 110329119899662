import React, {useEffect, useState} from "react";
import {ForwardedRef} from "react";
import Grid from "@mui/material/Grid2";
import GenericService from "@/services/core/GenericService";
import {PsnlEP} from "@/services/psnl/endpoints";
import {Box, CircularProgress, Divider, ToggleButton, ToggleButtonGroup, Tooltip} from "@mui/material";
import {RichTreeView} from "@mui/x-tree-view/RichTreeView";
import {AssignmentIndOutlined, Lan, LanOutlined, LocationOnOutlined} from "@mui/icons-material";
import {useReportViewContext} from "@/pages/Report/ReportLibrary/components/ReportView/components/ReportViewProvider";
import {useTranslation} from "react-i18next";


const SearchPanel =   (props: Record<string, any>, ref: ForwardedRef<any>) => {
  const {t} = useTranslation("report")
  const context = useReportViewContext()
  const [loading, setLoading] = useState<boolean>(false)
  const categories = [{
    category: "department",
    title: t("report.department"),
    icon: <LanOutlined/>,
    uri: PsnlEP.DEPARTMENT_URI,
  }, {
    category: "area",
    title: t("report.area"),
    icon: <LocationOnOutlined/>,
    uri: PsnlEP.AREA_URI,
  }, {
    category: "position",
    title: t("report.position"),
    icon: <AssignmentIndOutlined/>,
    uri: PsnlEP.POSITION_URI,
  }]
  const [filter, setFilter] = useState<Record<string, any>>({
    category: categories[0],
    ready: false,
    selected: []
  })
  const [treeData, setTreeData] = useState<any>([])
  const flatten2Tree = (flatData: any[], parentId = null): any[] => {
    return flatData
      .filter(item => item.parent === parentId)
      .map((item: any) => ({
        ...item,
        id: item.id.toString(),
        label: item.name,
        children: flatten2Tree(flatData, item.id.toString())
      }))
  }

  const getDataList = async () => {
    GenericService.action(filter.category.uri, "tree").then((response) => {
      if (response.isSuccess) {
        const tree = flatten2Tree(response.data)
        setTreeData(tree)
        // setFilterParams({...filterParams, area: defaultSelection})
        // setSearchPanelReady(true)
      }
    }).finally(() => {
      setFilter({...filter, ready: true})
    })
  }

  useEffect(() => {
    if(filter.ready){
      context.updateFilterModel({
        category: filter.category.category,
        selected: filter.selected,
      })
    }
  }, [filter])

  useEffect(() => {
    getDataList().then()
  }, [filter.category]);

  return (
    <Grid
      sx={{
        minWidth: "256px",
        maxWidth: "256px",
        backgroundColor: "#fff",
        display: "flex",
        flexDirection: "column",
        padding: "8px",
      }}
    >
      <ToggleButtonGroup
        fullWidth
        color={"primary"}
        value={filter.category.category}
      >
        {categories.map((c: any) => (
          <Tooltip title={c.title}>
            <ToggleButton
              key={c.category}
              value={c.category}
              onClick={() => setFilter({category: c, selected: []})}
            >
              {c.icon}
            </ToggleButton>
          </Tooltip>
        ))}
      </ToggleButtonGroup>
      <Divider sx={{margin: "8px -8px"}}/>
      {!filter.ready
        ? <Box
          sx={{
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}><CircularProgress/></Box>
        : <RichTreeView
          sx={{
            flex: 1,
            overflowY: "auto"
          }}
          items={treeData}
          onItemSelectionToggle={(event, itemId, isSelected) => {
            if(isSelected){
              setFilter({...filter, selected: [...filter.selected, itemId]})
            }else{
              setFilter({...filter, selected: [...filter.selected.filter((item: string) => item !== itemId)]})
            }
          }}
          checkboxSelection={true}
          multiSelect={true}
          selectedItems={filter.selected}
        />
      }
    </Grid>
  )
}
export default React.forwardRef(SearchPanel)

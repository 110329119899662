
import qs from 'qs';
import request from "@/utils/Request";
import {AxiosRequestConfig} from "axios";

/**
 * Obtain Object list
 * @param endpoint API Uri
 * @param params {current: Page number, pageSize: page Size, ...}
 */
const listProxy = async (endpoint: string, params: Record<string, any>) => {
  const response = await request.authorizedAxios().get(endpoint,
    {params, paramsSerializer: params => qs.stringify(params, {indices: false})});
  return response.data;
}

/**
 * Create Object
 * @param endpoint API Uri
 * @param data Object Model
 * @param config AxiosRequestConfig
 */
const createProxy = async (endpoint: string, data: Record<string, any>, config?: any) => {
  const response = await request.authorizedAxios().post(endpoint, data, config);
  return response.data;
}

/**
 * Create Object
 * @param endpoint API Uri
 * @param data Object Model
 * @param config AxiosRequestConfig
 */
const formCreateProxy = async (endpoint: string, data: FormData, config?: AxiosRequestConfig<FormData>) => {
  const response = await request.authorizedAxios().post(endpoint, data, {
    ...config,
    headers: {
      'Content-Type': 'multipart/form-data',
      ...config?.headers || {}
    },
  });
  return response.data;
}

/**
 * Update Object
 * @param endpoint API Uri
 * @param key Object ID
 * @param data Object Model
 * @param config AxiosRequestConfig
 */
const updateProxy = async (endpoint: string, key: string, data: Record<string, any>, config?: AxiosRequestConfig<Record<string, any>>) => {
  const response = await request.authorizedAxios().put(`${endpoint}${key}/`, data, config);
  return response.data;
}

/**
 * Update Object
 * @param endpoint API Uri
 * @param key Object ID
 * @param data Object Model
 * @param config AxiosRequestConfig
 */
const patchUpdateProxy = async (endpoint: string, key: string, data: Record<string, any>, config?: AxiosRequestConfig<Record<string, any>>) => {
  const response = await request.authorizedAxios().patch(`${endpoint}${key}/`, data, config);
  return response.data;
}

/**
 * Update Object
 * @param endpoint API Uri
 * @param key Object ID
 * @param data Object Model
 * @param config AxiosRequestConfig
 */
const formUpdateProxy = async (endpoint: string, key: string, data: FormData, config?: AxiosRequestConfig<FormData>) => {
  const response = await request.authorizedAxios().put(`${endpoint}${key}/`, data, {
    ...config,
    headers: {
      'Content-Type': 'multipart/form-data',
      ...config?.headers || {}
    },
  });
  return response.data;
}
/**
 * Update Object
 * @param endpoint API Uri
 * @param key Object ID
 * @param data Object Model
 * @param config AxiosRequestConfig
 */
const patchProxy = async (endpoint: string, key: string, data: Record<string, any>, config?: AxiosRequestConfig<Record<string, any>>) => {
  const response = await request.authorizedAxios().patch(`${endpoint}${key}/`, data, config);
  return response.data;
}

/**
 * Update Object
 * @param endpoint API Uri
 * @param key Object ID
 * @param data Object Model
 * @param config AxiosRequestConfig
 */
const formPatchProxy = async (endpoint: string, key: string, data: FormData, config?: AxiosRequestConfig<FormData>) => {
  const response = await request.authorizedAxios().patch(`${endpoint}${key}/`, data, {
    ...config,
    headers: {
      'Content-Type': 'multipart/form-data',
      ...config?.headers || {}
    },
  });
  return response.data;
}
/**
 * Obtain Object
 * @param endpoint API Uri
 * @param key Object ID
 */
const retrieveProxy = async (endpoint: string, key: string) => {
  const response = await request.authorizedAxios().get(`${endpoint}${key}/`);
  return response.data;
}

/**
 * Delete Object
 * @param endpoint API Uri
 * @param key Object ID
 */
const destroyProxy = async (endpoint: string, key: string) => {
  const response = await request.authorizedAxios().delete(`${endpoint}${key}/`);
  return response.data;
}

const multiDestroyProxy = async (endpoint: string, keys: any[]) => {
  const response = await request.authorizedAxios().delete(`${endpoint}multi-destroy/`, {data: keys});
  return response.data;
}

const actionProxy = async (endpoint: string, action: string, data?: Record<string, any>, target?: string) => {
  let uri = `${endpoint}${action}/`
  if(target){
    uri = `${endpoint}${target}/${action}/`
  }
  const response = await request.authorizedAxios().post(uri, data || {});
  return response.data;
}

const queryProxy = async (endpoint: string, action: string, params?: Record<string, any>, target?: string) => {
  let uri = `${endpoint}${action}/`
  if(target){
    uri = `${endpoint}${target}/${action}/`
  }
  const response = await request.authorizedAxios().get(
    uri,
    {params, paramsSerializer: params => qs.stringify(params, {indices: false})}
  );
  return response.data;
}


const postProxy = async (url: string, data: Record<string, any>, config?: AxiosRequestConfig<Record<string, any>>) => {
  const response = await request.authorizedAxios().post(url, data, config);
  return response.data;
}


const getProxy = async (url: string, params?: Record<string, any>) => {
  const response = await request.authorizedAxios().get(url,
    {params, paramsSerializer: params => qs.stringify(params, {indices: false})});
  return response.data;
}


/**
 * Service Entrance
 */
const GenericService = {
  list: listProxy,
  create: createProxy,
  formCreate: formCreateProxy,
  retrieve: retrieveProxy,
  update: updateProxy,
  patchUpdate: patchUpdateProxy,
  formUpdate: formUpdateProxy,

  patch: patchProxy,
  formPatch: formPatchProxy,
  destroy: destroyProxy,
  multiDestroy: multiDestroyProxy,
  action: actionProxy,
  query: queryProxy,

  post: postProxy,
  get: getProxy,

}
export default GenericService

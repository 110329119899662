import {Grid2, styled, useTheme} from "@mui/material";
import {Outlet} from "react-router-dom";
import React from "react";
import DialogProvider from "@/provider/DialogProvider";
import Grid from "@mui/material/Grid2";

const OnboardLayoutContainer = styled(Grid)(({theme}) => ({
  height: "100%",
  alignItems: "center",
  justifyContent: "center"
}))

const OnboardContainer = styled(Grid)(({theme}) => ({
  display: "flex",
  boxSizing: "border-box",
  minHeight: "400px",
  border: "2px solid #E8E8E8",
  borderRadius: "25px",
  alignItems: "center",
  justifyContent: "center",
  background: `linear-gradient(${theme.direction === "rtl" ? ".75turn" : ".25turn"}, #0E5B9D0A 50%, #FFF 50%)`,
  backgroundColor: "#0E5B9D0A",
}))

const OnboardBanner = styled(Grid2)(({theme}) => ({
  display: "flex",
  justifyContent: "center",
  paddingLeft: "16px",
  paddingRight: "16px"
}))

const OnboardBannerImg = styled("img")(() => ({
  height: "auto",
  width: "240px"
}))


const Onboard = (props: Record<string, any>) => {
  return (
    <DialogProvider>
      <OnboardLayoutContainer container>
        <Grid size={2}/>
        <OnboardContainer
          size={8}
          container
        >
          <Grid size={6}>
            <OnboardBanner>
              <OnboardBannerImg src={"/images/signin_image.svg"}/>
            </OnboardBanner>
          </Grid>
          <Grid size={6}>
            <Outlet/>
          </Grid>
        </OnboardContainer>
        <Grid size={2}/>
      </OnboardLayoutContainer>
    </DialogProvider>
  )
}
export default Onboard

import {useRoutes} from "react-router-dom";
import PrivateRoute from "@/router/PrivateRoute";
import DeviceListView from "@/pages/vendor/tenement/Device/ListView";


const DeviceRouter = () => {
  const routes = useRoutes([
    {index: true, element: <PrivateRoute requireRoles={["agent", "vendor"]}><DeviceListView /></PrivateRoute>},
  ])
  return routes
}
export default DeviceRouter

import {useTranslation} from "react-i18next";
import {ObjectFieldTemplateProps} from "@rjsf/utils";
import Grid from "@mui/material/Grid2";
import {ScrollTab, ScrollTabs, useScrollTabsHook} from "@/components/ScrollTabs";
import {Stack} from "@mui/system";


const DeviceRecordViewLayout = (props: ObjectFieldTemplateProps) => {
  const {t} = useTranslation("acc")
  const fields: Record<string, any> = {}
  props.properties?.forEach((element: any) => {
    fields[element.name] = element.content
  })

  const [tabs, getTabProps] = useScrollTabsHook([
    {key: 'options', title: t("device.accessControl.options")},
    {key: 'duressOptions', title: t("device.accessControl.duressOptions")},
    {key: 'antiPassBackSetup', title: t("device.accessControl.antiPassBackSetup")},
  ])

  return (
    <ScrollTabs tabs={tabs}>
      <Stack spacing={2}>
        <ScrollTab {...getTabProps('options')} >
          <Grid container spacing={2}>
            <Grid size={4}>{fields.door_lock_delay}</Grid>
            <Grid size={4}>{fields.verify_mode_485}</Grid>
            <Grid size={4}/>
            <Grid size={4}>{fields.door_sensor_delay}</Grid>
            <Grid size={4}>{fields.door_sensor_type}</Grid>
            <Grid size={4}/>
            <Grid size={4}>{fields.retry_times}</Grid>
            <Grid size={4}>{fields.door_alarm_delay}</Grid>
            <Grid size={4}/>
            <Grid size={4}>{fields.no_time_period}</Grid>
            <Grid size={4}>{fields.nc_time_period}</Grid>
            <Grid size={4}/>
            <Grid size={4}>{fields.valid_holiday}</Grid>
            <Grid size={4}>{fields.speaker_alarm}</Grid>
          </Grid>
        </ScrollTab>
        <ScrollTab {...getTabProps('duressOptions')} >
          <Grid container spacing={2}>
            <Grid size={4}>{fields.duress_fun_on}</Grid>
            <Grid size={4}>{fields.alarm_password}</Grid>
            <Grid size={4}/>
            <Grid size={4}>{fields.alarm_1_1}</Grid>
            <Grid size={4}>{fields.alarm_1_n}</Grid>
            <Grid size={4}/>
            <Grid size={4}>{fields.duress_alarm_delay}</Grid>
          </Grid>
        </ScrollTab>
        <ScrollTab {...getTabProps('antiPassBackSetup')} >
          <Grid container spacing={2}>
            <Grid size={4}>{fields.anti_passback_mode}</Grid>
            <Grid size={4}>{fields.anti_door_direction}</Grid>
          </Grid>
        </ScrollTab>
      </Stack>
    </ScrollTabs>
  )
}
export default DeviceRecordViewLayout

import React, { ForwardedRef, useImperativeHandle, useRef } from "react";
import { useTranslation } from "react-i18next";
import { RJSFSchema, UiSchema } from "@rjsf/utils";
import LayoutForm from "@/components/rjsf/Form";
import SnackbarUtil from "@/utils/SnackbarUtil";
import GenericService from "@/services/core/GenericService";
import { PsnlEP } from "@/services/psnl/endpoints";

const AddArea = (props: Record<any, any>, ref: ForwardedRef<any>) => {
  const { t: t } = useTranslation("company");
  const formRef = useRef<any>();
  const formSchema: RJSFSchema = {
    type: "object",

    properties: {
      area_code: {
        type: "string",
        title: t("area.code"),
      },
      area_name: {
        type: "string",
        title: t("area.name"),
      },
      parent_area: {
        type: ["number", "null"],
        title: t("area.superior"),
      },
    },
    required: ["area_code", "area_name"],
  };

  const uiSchema: UiSchema = {
    "ui:defaultXsWidth": 12,
    "ui:submitButtonOptions": { norender: true },
    parent_area: {
      "ui:widget": "m2o",
      "ui:options": {
        uri: PsnlEP.AREA_URI,
      },
    },
  };

  const onAddArea = async () => {
    const isValid = formRef.current?.validateForm();
    if (isValid) {
      const data = formRef.current?.state.formData;
      const response = await GenericService.create(PsnlEP.AREA_URI, data);
      if (response.isSuccess) {
        SnackbarUtil.success(t("common.message.saveSuccessfully"));
        return true;
      } else {
        console.log(response);
        return false;
      }
    }
  };

  useImperativeHandle(ref, () => ({
    onConfirm: onAddArea,
  }));

  return <LayoutForm ref={formRef} uiSchema={uiSchema} schema={formSchema} />;
};
export default React.forwardRef(AddArea);

import {Navigate, Route, Routes} from "react-router-dom";
import ResignRouter from "./Resign/router"
import EmployeeRouter from "./Employee/router";
import RoleRouter from "@/pages/Team/Role/router";
import FlowRouter from "@/pages/Team/Flow/router";
import NodeRouter from "@/pages/Team/Node/router";


const TeamRouter = () => {
  return (
    <Routes>
      <Route index element={<Navigate to={"employee"} replace/>}/>
      <Route path={"employee/*"} element={<EmployeeRouter/>}/>
      <Route path={"resign/*"} element={<ResignRouter/>}/>
      <Route path={"workflow-role/*"} element={<RoleRouter/>}/>
      <Route path={"workflow/*"} element={<FlowRouter/>}/>
      <Route path={"workflow-node/*"} element={<NodeRouter/>}/>
    </Routes>
  )
};
export default TeamRouter

import {useRoutes} from "react-router-dom";
import MyScheduleListView from "@/pages/Personal/outdoor-mangement/my-schedule/ListView";

const MyScheduleRouter = () => {
  const routes = useRoutes([
    {index: true, element: <MyScheduleListView />},
  ])
  return routes
}
export default MyScheduleRouter;

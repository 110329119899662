import {RJSFSchema, UiSchema} from "@rjsf/utils";
import {useTranslation} from "react-i18next";
import RecordView from "@/components/views/RecordView";
import {PersonalEndPoint} from "@/services/personal/endpoints";
import {ComplementEndpoint} from "@/services/complement/endpoints";
import React from "react";


const AddLeaveRecordView = () => {
  const {t} = useTranslation("att");
  const formSchema: RJSFSchema = {
    type: "object",
    required: [
      "start_time",
      "end_time",
      "leave_payment_type",
      "day_type",
      "category"
    ],
    properties: {
      category: {
        type: "number",
        title: t("leave.category"),
      },
      start_time: {
        type: "string",
        format: "date",
        title: t("report.startDate", {ns: 'report'}),
      },
      end_time: {
        type: "string",
        format: "date",
        title: t("report.endDate", {ns: 'report'}),
      },
      day_type: {
        type: "number",
        title: t("leave.dayType"),
        oneOf: [
          {const: 1, title: t("dayType.option.fullDay")},
          {const: 2, title: t("dayType.option.firstHalf")},
          {const: 3, title: t("dayType.option.secondHalf")}
        ],
      },
      leave_payment_type: {
        type: "number",
        title: t("leave.paymentType"),
        default: 1,
        oneOf: [
          {const: 0, title: t("paymentType.paid")},
          {const: 1, title: t("paymentType.unpaid")},
        ],
      },
      apply_reason: {
        type: "string",
        title: t("approval.applyReason", {ns: "common"}),
        maxLength: 200
      },
    },
  };

  const uiSchema: UiSchema = {
    "ui:defaultXsWidth": 6,
    "ui:submitButtonOptions": {norender: true},
    category: {
      "ui:widget": "m2o",
      "ui:options": {
        uri: ComplementEndpoint.INCIDENT_TYPE_URI,
      },
    },
    apply_reason: {
      "ui:widget": "textarea",
    },
  };

  const [formData, setFormData] = React.useState<Record<string, any>>({});
  const onChange = (e: any) => {
    const cleanedData = Object.keys(e.formData).reduce(
      (acc: { [key: string]: any }, key: string) => {
        if (
          typeof e.formData[key] !== "string" &&
          e.schema.required.indexOf(key) === -1
        ) {
          acc[key] = "";
        } else {
          acc[key] = e.formData[key];
        }
        return acc;
      },
      {} as { [key: string]: any }
    );
    setFormData({
      ...cleanedData,
    });
  };

  return (
    <RecordView
      formSchema={formSchema}
      uiSchema={uiSchema}
      onChange={onChange}
      formData={formData}
      uri={PersonalEndPoint.LEAVE_URI}
    />
  )
};
export default AddLeaveRecordView;

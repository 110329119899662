import ListView from "@/components/views/ListView";
import { GridColDef } from "@mui/x-data-grid";
import AppUrls from "@/urls";
import { useTranslation } from "react-i18next";
import { AttEndPoint } from "@/services/att/endpoints";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import RecordPanel from "@/pages/Attendance/DepartmentSchedule/components/RecordPanel";

const EmployeeScheduleListView = () => {
  const { t } = useTranslation("att");
  const { t: company } = useTranslation("company");
  const displayName = (value: any) => value?.display_name;
  const columns: GridColDef[] = [
    {
      field: "department",
      valueFormatter: displayName,
      headerName: t("departmentWorkRule.department"),
      flex: 1,
    },
    { field: "start_date", headerName: t("schedule.startDate"), flex: 1 },
    { field: "end_date", headerName: t("schedule.endDate"), flex: 1 },
    {
      field: "shift",
      valueFormatter: displayName,
      headerName: t("schedule.shift"),
      flex: 1,
    },
  ];

  const filters = [
    {
      key: "department__dept_code",
      label: company("department.code"),
      type: "string",
    },
    {
      key: "department__dept_name",
      label: company("department.name"),
      type: "string",
    },
  ];

  const actions = [
    {
      id: "import",
      title: t("common.action.import", { ns: "common" }),
      icon: <FileUploadOutlinedIcon />,
      display: "page",
      url: `${AppUrls.att.departmentSchedule}/import`,
    },
  ];

  return (
    <ListView
      title={t("menu.att.departmentSchedule", { ns: "menu" })}
      columns={columns}
      uri={AttEndPoint.DEPT_SCHEDULE_URI}
      url={AppUrls.att.departmentSchedule}
      filters={filters}
      disableEdit={true}
      modelActions={actions}
      RecordPanel={RecordPanel}
    />
  );
};
export default EmployeeScheduleListView;

import {RJSFSchema, UiSchema} from "@rjsf/utils";
import {useTranslation} from "react-i18next";
import RecordView from "@/components/views/RecordView";
import {AttEndPoint} from "@/services/att/endpoints";
import {PsnlEP} from "@/services/psnl/endpoints";
import React from "react";
import {useLocation} from "react-router-dom";

const ManualLogRecordView = () => {
  const {t: t} = useTranslation("att");
  const {t: common} = useTranslation("common");
  const params = useLocation()
  const mode = params.state?.mode || "ADD"
  const formSchema: RJSFSchema = {
    type: "object",
    required: ["employee", "punch_date", "punch_time", "punch_state"],
    properties: {
      employee: {
        type: "string",
        title: t("manualLog.employee"),
        readOnly: mode === "EDIT"
      },
      punch_state: {
        type: "number",
        title: t("manualLog.punchState"),
        oneOf: [
          {const: 0, title: common("punchState.checkIn")},
          {const: 1, title: common("punchState.checkOut")},
          {const: 2, title: common("punchState.breakOut")},
          {const: 3, title: common("punchState.breakIn")},
          {const: 4, title: common("punchState.overtimeIn")},
          {const: 5, title: common("punchState.overtimeOut")},
        ],
      },
      punch_date: {
        type: "string",
        format: "date",
        title: t("manualLog.punchDate"),
      },
      punch_time: {
        type: "string",
        title: t("manualLog.punchTime"),
        default: ""
      },

      work_code: {
        type: ["string", 'null'],
        title: t("manualLog.workCode"),
        readOnly: mode === "EDIT",
        maxLength: 20

      },
      apply_reason: {
        type: ["string", 'null'],
        title: common("approval.applyReason"),
        maxLength: 200
      },
    },
  };

  const uiSchema: UiSchema = {
    "ui:defaultXsWidth": 4,
    "ui:defaultRowWrap": true,
    employee: {
      "ui:widget": "m2o",
      "ui:options": {
        uri: PsnlEP.EMPLOYEE_URI,
      },
    },
    punch_time: {
      'ui:widget': 'time',
      "ui:wrap": true
    },
    apply_reason: {
      "ui:widget": "textarea",
      "ui:emptyValue": ""
    },
    work_code: {
      "ui:emptyValue": ""
    }
    //TODO: 后端暂时不调整work_code为外键
    // work_code: {
    //   "ui:widget": "m2o",
    //   "ui:options": {
    //     uri: ComplementEndpoint.WORK_CODE_URI,
    //   },
    // },
  };

  return (
    <RecordView
      formSchema={formSchema}
      uiSchema={uiSchema}
      uri={AttEndPoint.MANUAL_LOG_URI}
    />
  );
};
export default ManualLogRecordView;

import {useTranslation} from "react-i18next";
import {GridColDef, GridRenderCellParams} from "@mui/x-data-grid";
import urls from "@/urls";
import {ReportEndPoint} from "@/services/report/endpoints";
import ReportView from "@/pages/Report/ReportLibrary/components/ReportView";
import {useEffect, useState} from "react";
import GenericService from "@/services/core/GenericService";
import {AttEndPoint} from "@/services/att/endpoints";
import { useReportEmployee } from "@/hooks/employee";


const YearlySummaryReport = () => {
  const {t} = useTranslation("report")
  const employeeFields = useReportEmployee()
  const fixedColumns: GridColDef[] = [
    ...employeeFields,
    {
      field: "month",
      headerName: t("report.column.month"),
    },
    {
      field: "first_name",
      headerName: t("employee.firstName", {ns: "per"}),
    },
    {
      field: "dept_name",
      headerName: t("report.column.department"),
    },
    {
      field: "total_present_times",
      headerName: t("report.column.presentLabel"),
    },
    {
      field: "total_absent_times",
      headerName: t("report.column.absentLabel"),
    },
    {
      field: "total_holiday_times",
      headerName: t("report.column.holidayLeaveLabel"),
    },
    {
      field: "total_holiday_present_times",
      headerName: t("report.column.holidayPresentLabel"),
    },
    {
      field: "total_week_off_times",
      headerName: t("report.column.weekOffLabel"),
    },
    {
      field: "total_week_off_present_times",
      headerName: t("report.column.weekOffPresentLabel"),
    },
    {
      field: "total_late_times",
      headerName: t("report.column.lateTimesLabel"),
    },
    {
      field: "total_early_leave_times",
      headerName: t("report.column.earlyLeaveTimesLabel"),
    },
    {
      field: "total_leave_times",
      headerName: t("report.column.totalLeaveTimes"),
    },
  ]

  const [leaveColumns, setLeaveColumns] = useState<GridColDef[]>([])
  const [columns, setColumns] = useState<GridColDef[]>(fixedColumns)

  useEffect(() => {
    GenericService.list(AttEndPoint.LEAVE_TYPE_URI, {
      page: 1,
      page_size: 50,
    })
      .then((response) => {
        const leaves: any = []
        response.data.data.forEach((each: any) => {
          leaves.push({field: `leave_${each.id}`, headerName: `${each.report_symbol}`})
        })
        setLeaveColumns(leaves)
      })
  }, [])

  useEffect(() => {
    setColumns((old) => {
      return [
        ...fixedColumns,
        ...leaveColumns
      ]
    })
  }, [leaveColumns])


  return (
    <ReportView
      title={t("reportLibrary.yearlySummary")}
      breadcrumbs={[]}
      columns={columns}
      uri={ReportEndPoint.YEARLY_SUMMARY_URI}
      url={urls.report.yearlySummary}
      getRowId={(row: any) => row.employee_code+row.month}
      filterType={'year'}
      wiseFields={[]}
      hideColumns={['first_name', 'last_name', 'department_code', 'position_code', 'position_name']}
    />
  )
}
export default YearlySummaryReport

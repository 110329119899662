import request from "@/utils/Request";
import {DeviceEndPoint} from "@/services/device/endpoints";
import GenericService from "../core/GenericService";


const SendWorkCodeToDevice = async (data: Record<string, any>) => {
  const response = await GenericService.action(DeviceEndPoint.WORK_CODE_URI, 'send_work_code', data)
  return response;
}

const RemoveWorkCodeToDevice = async (data: Record<string, any>) => {
  const response = await GenericService.action(DeviceEndPoint.WORK_CODE_URI, 'remove_work_code', data)
  return response;
}

const WorkCodeService = {
  SendWorkCodeToDevice,
  RemoveWorkCodeToDevice,
}
export default WorkCodeService

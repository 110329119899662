import React, {ForwardedRef} from "react"
import {WidgetProps} from "@rjsf/utils";
import {DatePicker} from "@mui/x-date-pickers";
import dayjs from "dayjs";


const DateWidget = (props: WidgetProps) => {
  const format = "YYYY-MM-DD"
  return (
    <DatePicker
      label={props.label}
      name={props.name}
      format={format}
      value={(props.value? dayjs(props.value): null)}
      onChange={async (value) => {
        props.onChange(value?.format(format))
      }}
      slotProps={{
        textField:{
          required: props.required,
          disabled: props.disabled,
        }
      }}
    />
  )
}
export default DateWidget

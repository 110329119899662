import ModelActionPage from "@/components/views/ListView/components/model-action/ModelActionPage";
import React, {useEffect, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import {CustomValidator, RJSFSchema} from "@rjsf/utils";
import CompanySettingLayout from "@/pages/Company/CompanySettings/components/SettingLayout";
import LocationWidget from "@/pages/Company/CompanySettings/components/LocationWidget";
import SystemSettingService from "@/services/base/SystemSettingService";
import SnackbarUtil from "@/utils/SnackbarUtil";
import {useAppContext} from "@/provider/AppProvider";
import LogoWidget from "@/pages/Company/CompanySettings/components/LogoWidget";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import Const from "@/utils/Const";
import {useMapContext} from "@/provider/MapProvider";
import RegExps from "@/utils/RegExps";

dayjs.extend(utc)
dayjs.extend(timezone)

const CompanySettings = () => {
  const {t} = useTranslation("company")
  const {currentCompany} = useAppContext()
  const {mapInfo, setLocation} = useMapContext()
  const actionRef = useRef<any>()
  const [formData, setFormData] = useState<Record<string, any>>({})
  const displayPosition = [
    {const: 0, title: t("displayPosition.none")},
    {const: 1, title: t("displayPosition.left")},
    {const: 2, title: t("displayPosition.center")},
    {const: 3, title: t("displayPosition.right")},
  ]
  const formSchema: RJSFSchema = {
    type: "object",
    properties: {
      logo_img: {
        type: "string",
        title: t("company.logo"),
      },
      company_name: {
        type: "string",
        title: t("company.name"),
        default: currentCompany?.name ?? "",
      },
      company_phone: {
        type: "string",
        title: t("company.phone")
      },
      company_name_display: {
        type: "integer",
        title: t("company.nameDisplay"),
        oneOf: displayPosition,
        default: 0
      },
      logo_display: {
        type: "integer",
        title: t("company.logoDisplay"),
        oneOf: displayPosition,
        default: 0
      },
      company_address: {
        type: "string",
        title: t("company.location"),
        readOnly: true,
        default: ""
      },
      company_country: {
        type: "string",
        title: t("company.country"),
        default: ""
      },
      company_province: {
        type: "string",
        title: t("company.province"),
        default: ""
      },
      company_city: {
        type: "string",
        title: t("company.city"),
        default: ""
      },
      company_latitude: {
        type: "number",
        title: t("company.latitude"),
        readOnly: true,
      },
      company_longitude: {
        type: "number",
        title: t("company.longitude"),
        readOnly: true,
      },
      company_timezone: {
        type: "string",
        title: t("company.timezone"),
        enum: Const.timezones,
      },
      email_signature: {
        type: "string",
        title: t("company.emailSignature"),
      },
      email_signature1: {
        type: "string",
        title: t("company.emailSignature1")
      },
      email_signature2: {
        type: "string",
        title: t("company.emailSignature2")
      },
    },
    required: ["company_name"]
  }
  const uiSchema = {
    logo_img: {
      "ui:widget": "logo",
      "ui:placeholder": t("company.logoUpload")
    },
    company_address: {
      "ui:widget": "location"
    },
    company_timezone: {
      'ui:enumNames': Const.timezones,
    }
  }
  const widgets = {
    logo: LogoWidget,
    location: LocationWidget,
  }
  const templates = {
    ObjectFieldTemplate: CompanySettingLayout
  }

  const customValidate: CustomValidator = (formData, errors, uiSchema) => {
    if (formData.company_phone && !RegExps.company.phoneNumber.test(formData.company_phone)) {
      errors.company_phone?.addError(t("company.validateError.invalidPhoneNumber"))
    }
    return errors
  }
  const onSubmit = async (data: Record<string, any>) => {
    const response = await SystemSettingService.updateCompanySetting(data)
    if (response.isSuccess) {
      SnackbarUtil.success(t("common.message.successful", {ns: "common"}))
      await getCompanySetting()
    }
  }

  const getCompanySetting = async () => {
    const response = await SystemSettingService.getCompanySetting()
    if (response.isSuccess) {
      setFormData(response.data)
    }
  }
  useEffect(() => {
    if (!mapInfo) {
      getCompanySetting().then()
    } else {
      setFormData({
        ...actionRef.current?.formData,
        company_address: mapInfo.place.formatted_address,
        company_country: mapInfo.place.address?.country,
        company_timezone: mapInfo.timezone,
        company_province: mapInfo.place.address?.state,
        company_city: mapInfo.place.address?.city,
        company_latitude: mapInfo.place.geometry?.location?.lat(),
        company_longitude: mapInfo.place.geometry?.location?.lng()
      })
    }
  }, [mapInfo])


  return (
    <ModelActionPage
      ref={actionRef}
      title={t("menu.company.settings", {ns: "menu"})}
      breadcrumbs={[]}
      formSchema={formSchema}
      uiSchema={uiSchema}
      widgets={widgets}
      templates={templates}
      customValidate={customValidate}
      formData={formData}
      disableDiscard={true}
      onSave={onSubmit}
    />
  )
}
export default CompanySettings

import { GridColDef } from "@mui/x-data-grid";
import { useTranslation } from "react-i18next";
import AppUrls from "@/urls";
import ListView from "@/components/views/ListView";
import { PersonalEndPoint } from "@/services/personal/endpoints";
import { Approval } from "@mui/icons-material";
import ApprovalView from "../components/ApprovalView";
import FilterPanel from "@/pages/Personal/request/components/FilterPanel";

const ManualLogApprovalListView = () => {
  const { t } = useTranslation("att");
  const { t: menu } = useTranslation("menu");
  const { t: per } = useTranslation("per");
  const { t: common } = useTranslation("common");
  const columns: GridColDef[] = [
    { field: "emp_code", headerName: per("employee.code"), width: 120 },
    { field: "first_name", headerName: per("employee.firstName"), width: 100 },
    { field: "last_name", headerName: per("employee.lastName"), width: 100 },
    { field: "apply_time", headerName: common("approval.applyTime"), width: 160 },
    { field: "punch_date", headerName: t("manualLog.punchDate") },
    { field: "punch_time", headerName: t("manualLog.punchTime") },
    {
      field: "punch_state",
      headerName: t("manualLog.punchState"),
      type: "singleSelect",
      valueOptions: [
        {value: 0, label: common("punchState.checkIn")},
        {value: 1, label: common("punchState.checkOut")},
        {value: 2, label: common("punchState.breakOut")},
        {value: 3, label: common("punchState.breakIn")},
        {value: 4, label: common("punchState.overtimeIn")},
        {value: 5, label: common("punchState.overtimeOut")},
        {value: 255, label: common("punchState.none")},
      ],
    },
    {
      field: "work_code",
      headerName: t("manualLog.workCode"),
    },
    {
      field: "audit_status",
      headerName: common("approval.status"),
      type: "singleSelect",
      valueOptions: [
        {value: 1, label: common("approval.status.pending")},
        {value: 2, label: common("approval.status.approved")},
        {value: 3, label: common("approval.status.rejected")},
        {value: 6, label: common("approval.status.revoked")},
        {value: 7, label: common("approval.status.withdraw")},
      ],
      width: 140,
    },
    {
      field: "last_approver",
      headerName: common("approval.lastApprover"),
      width: 140,
    },
    {
      field: "audit_reason",
      headerName: common("approval.remark"),
      flex: 1,
    },
  ];
  const recordActions = [
    {
      id: "approval",
      title: common("approval.workflowView"),
      icon: <Approval />,
      display: "dialog",
      Form: ApprovalView,
      readOnly: true,
      state: {
        uri: PersonalEndPoint.MANUAL_LOG_URI,
        event: menu("menu.att.manualLog"),
      },
    },
  ];

  const options = [
      {value: 0, label: common("punchState.checkIn")},
      {value: 1, label: common("punchState.checkOut")},
      {value: 2, label: common("punchState.breakOut")},
      {value: 3, label: common("punchState.breakIn")},
      {value: 4, label: common("punchState.overtimeIn")},
      {value: 5, label: common("punchState.overtimeOut")},
  ]

  return (
    <ListView
      title={menu("menu.personal.manualLog")}
      recordActions={recordActions}
      columns={columns}
      uri={PersonalEndPoint.MANUAL_LOG_URI}
      resource={"my_approval/"}
      url={AppUrls.personal.approval.manualLog}
      disableAdd={true}
      disableHistory={true}
      disableEdit={true}
      disableDelete={true}
      disableExport
      FilterPanel={FilterPanel}
      options={options}
      categoryLabel={t("manualLog.punchState")}
      approvalFilter={true}
      auditStatus={true}
    />
  );
};
export default ManualLogApprovalListView;

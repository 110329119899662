import request from "@/utils/Request";
import {GPSEndPoint} from "./endpoints";
import GenericService from "@/services/core/GenericService";



const pushNotification = async (data: Record<string, any>) => {
  return await GenericService.action(GPSEndPoint.ACCOUNTS_URI, "push_notification", data)
}

const forceOffline = async (data: Record<string, any>) => {
  return await GenericService.action(GPSEndPoint.ACCOUNTS_URI, "force_offline", data)
}

const Disable = async (data: Record<string, any>) => {
  return await GenericService.action(GPSEndPoint.ACCOUNTS_URI, "disable", data)
}

const Enable = async (data: Record<string, any>) => {
  return await GenericService.action(GPSEndPoint.ACCOUNTS_URI, "enable", data)
}

const pushPublicNotice = async (data: Record<string, any>) => {
  return await GenericService.action(GPSEndPoint.ANNOUNCEMENT_URI, "push_public_notice", data)
}

const pushPrivateNotice = async (data: Record<string, any>) => {
  return await GenericService.action(GPSEndPoint.ANNOUNCEMENT_URI, "push_private_notice", data)
}

const GPSService = {
  pushNotification,
  forceOffline,
  Disable,
  Enable,
  pushPublicNotice,
  pushPrivateNotice

}
export default GPSService

import ListView from "@/components/views/ListView";
import {GridColDef} from "@mui/x-data-grid";
import AppUrls from "@/urls";
import {DeviceEndPoint} from "@/services/device/endpoints";
import {useTranslation} from "react-i18next";

const ErrorLogListView = () => {
  const { t } = useTranslation("device");
  const columns: GridColDef[] = [
    {field: "sn", headerName: t("device.sn"), flex: 1},
    {field: "alias", headerName: t("device.alias"), flex: 1},
    { field: "error_code", headerName: t("errorLog.errorCode"), flex: 1 },
    { field: "error_msg", headerName: t("errorLog.errorMsg"), flex: 1 },
    { field: "data_origin", headerName: t("errorLog.dataOrigin"), flex: 1 },
    { field: "cmd", headerName: t("errorLog.cmd"), flex: 1 },
    { field: "additional", headerName: t("errorLog.additional"), flex: 1 },
    {
      field: "terminal_upload_time",
      headerName: t("errorLog.terminalUploadTime"),
      flex: 1,
    },
  ];

  const filters = [
    {
      key: "terminal__sn",
      label: t("device.sn"),
      type: "string",
    },
    {
      key: "terminal__alias",
      label: t("device.alias"),
      type: "string",
    },
    {
      key: "error_code",
      label: t("errorLog.errorCode"),
      type: "string",
    },
  ];

  return (
    <ListView
      disableAdd
      disableEdit
      title={t("menu.device.errorLog", {ns: "menu"})}
      columns={columns}
      uri={DeviceEndPoint.ERROR_LOG_URI}
      url={AppUrls.device.errorLog}
      filters={filters}
      excludeActions={["delete"]}
    />
  );
};
export default ErrorLogListView;

import {Route, Routes, useRoutes} from "react-router-dom";
import DeviceListView from "./ListView";
import DeviceRecordView from "@/pages/AccessControl/Device/RecordView";
import BulkSetup from "@/pages/AccessControl/Device/components/BulkSetup";


const DeviceRouter = () => {
  return useRoutes([
    {index: true, element: <DeviceListView />},
    {path: "edit", element: <DeviceRecordView />},
    {path: "bulk-setup", element: <BulkSetup />},
  ])
};
export default DeviceRouter

import {useNavigate, useSearchParams} from "react-router-dom";
import {useEffect, useState} from "react";
import OauthService from "@/services/oauth/OauthService";
import urls from "@/urls";
import SnackbarUtil from "@/utils/SnackbarUtil";
import {Box, CircularProgress} from "@mui/material";
import {useTranslation} from "react-i18next";


const AccountInvited = () => {
  const {t} = useTranslation("oauth")
  const {t: api} = useTranslation("api")
  const navigate = useNavigate()
  const [search, setSearch] = useSearchParams()
  const verifyCode = search.get("code")
  const [loading, setLoading] = useState<boolean>(false)
  const onInvited = async () => {
    OauthService.accountInvited({code: verifyCode}).then((response) => {
      if (response.isSuccess) {
        if (response.data?.email) {
          navigate(urls.invitedSignUp, {state: {verifyCode, ...response.data}})
        } else {
          SnackbarUtil.success(t("oauth.message.joinCompanySuccessful"))
          navigate(urls.companyVerification, {state: {verifyCode}})
        }
      }else {
        console.log(response)
        navigate(urls.companyVerification)
      }
    }).catch(() => {
      SnackbarUtil.error(t("ZBOA0001"))
    }).finally(() => {
      setLoading(false)
    })
  }
  useEffect(() => {
    if (!verifyCode) {
      SnackbarUtil.error(t("ZBOA0106"))
      navigate(urls.companyVerification)
    } else {
      onInvited().then()
    }
  }, [])
  return (
    <Box sx={{width: "100%", display: "flex", justifyContent: "center"}}>
      {loading && <CircularProgress/>}
    </Box>
  )
}
export default AccountInvited

import {Breadcrumbs, Link, Typography} from "@mui/material";


const ViewBreadcrumbs = (props: Record<any, any>) => {
  const {title, breadcrumbs} = props
  return (
    <Breadcrumbs>
      {false && breadcrumbs?.map((breadcrumb: any, index: number) => {
        return (
          breadcrumb.href
            ? <Link
              key={`link${index}`}
              underline={"hover"}
              color={"primary"}
              href={breadcrumb.href}
            >
              <Typography>
                {breadcrumb.title}
              </Typography>
            </Link>
            : <Typography
              key={`typography${index}`}
              color="text.primary"
            >
              {breadcrumb.title}
            </Typography>
        )
      })}
      <Typography variant={"h3"}>{title}</Typography>
    </Breadcrumbs>
  )
}
export default ViewBreadcrumbs


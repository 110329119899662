import React, {createContext, useContext, useEffect, useState} from "react";
// import AuthService from "@/services/auth/UserService";
// import CompanyService from "@/services/psnl/CompanyService";
import OAuthService from "@/services/oauth/OauthService";
import jwtDecode from "jwt-decode";
import {useLocation, useNavigate, Navigate} from "react-router-dom";
import urls from "@/urls";


type ListViewContextProps = {
  // refreshData?: () => void;
  // dataGridRef?: any;
} & Record<string, any>


export const ListViewContext = createContext<ListViewContextProps>({
})

export const useListViewContext = () => {
  const context = useContext(ListViewContext)
  if (!context) {
    throw new Error('The app provider not found!')
  }
  return context
}

export default {}

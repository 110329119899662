import { MainIconButton } from "@/components/styled"
import { useListViewContext } from "@/provider/ListViewProvider";
import RefreshIcon from '@mui/icons-material/Refresh';
import { Tooltip } from "@mui/material";
import { useNavigate } from "react-router-dom";

const RefreshAction = (props: Record<any, any>) => {
  return <Tooltip title={"Refresh"}>
    <MainIconButton onClick={() => {
      props.onRefresh()
    }}>
      <RefreshIcon />
    </MainIconButton>
  </Tooltip>
}

export default RefreshAction

import {useRoutes} from "react-router-dom";
import ManualLogApprovalListView from "@/pages/Personal/my-approval/manual_log/ListView";

const ManualApprovalRouter = () => {
  const routes = useRoutes([
    {index: true, element: <ManualLogApprovalListView />},
  ])
  return routes
}
export default ManualApprovalRouter;

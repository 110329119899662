import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";


export const useVerifyModesForm = (): any => {
  const { t } = useTranslation("acc");
  return [
    {const: -1, title: t("verifyMode.groupMode")},
    {const: 0, title: t("verifyMode.any")},
    {const: 1, title: t("verifyMode.fingerprintOnly")},
    {const: 2, title: t("verifyMode.userId")},
    {const: 3, title: t("verifyMode.password")},
    {const: 4, title: t("verifyMode.badgeOnly")},
    {const: 5, title: t("verifyMode.fpOrPwd")},
    {const: 6, title: t("verifyMode.fingerprintOrBadge")},
    {const: 7, title: t("verifyMode.pwdOrBadge")},
    {const: 8, title: t("verifyMode.userIdAndFP")},
    {const: 9, title: t("verifyMode.fingerprintAndPassword")},
    {const: 10, title: t("verifyMode.fingerprintAndBadge")},
    {const: 11, title: t("verifyMode.fingerprintAndPasswordAndBadge")},
    {const: 12, title: t("verifyMode.fpAndPwdAndBadge")},
    {const: 13, title: t("verifyMode.userIdAndFPOrPwd")},
    {const: 14, title: t("verifyMode.fpAndBadgeOrUserId")},
    {const: 15, title: t("verifyMode.face")},
    {const: 16, title: t("verifyMode.faceAndFP")},
    {const: 17, title: t("verifyMode.faceAndPwd")},
    {const: 18, title: t("verifyMode.faceAndCard")},
    {const: 19, title: t("verifyMode.faceAndFPAndCard")},
    {const: 20, title: t("verifyMode.faceAndFPAndPwd")},
    {const: 21, title: t("verifyMode.fv")},
    {const: 22, title: t("verifyMode.fvAndPwd")},
    {const: 23, title: t("verifyMode.fvAndCard")},
    {const: 24, title: t("verifyMode.fvAndPwdAndCard")},
    {const: 25, title: t("verifyMode.palm")},
    {const: 26, title: t("verifyMode.palmAndCard")},
    {const: 27, title: t("verifyMode.palmAndFace")},
    {const: 28, title: t("verifyMode.palmAndFP")},
    {const: 29, title: t("verifyMode.palmAndFPAndFace")},
  ];
}

export const useVerifyModesList = (): any => {
  return useVerifyModesForm().map((each: any) => {
    return {value: each.const, label: each.title}
  })
}




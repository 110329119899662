import {WidgetProps} from "@rjsf/utils";
import {FormControlLabel, Switch} from "@mui/material";
import React from "react";


const SwitchWidget = (props: WidgetProps) => {
  const {name, label, value, options, uiSchema, readonly} = props
  const checked = (value === undefined? false: value)
  return (
    <FormControlLabel
      label={label}
      labelPlacement={"end"}
      control={<Switch
        disabled={readonly}
        name={name}
        checked={checked}
        onChange={async (event, checked) => {
          props.onChange(checked)
        }}
      />}
    />
  )
}
export default SwitchWidget

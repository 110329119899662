import {Route, Routes} from "react-router-dom";
import RecordView from "./RecordView";
import ListView from "./ListView";
import ImportView from "./ImportView";

const SubRouter = () => {
  return (
    <Routes>
      <Route index element={<ListView />}/>
      <Route path={"add/"} element={<RecordView />}/>
      <Route path={"import"} element={<ImportView />}/>
    </Routes>
  )
};
export default SubRouter

import {DeviceEndPoint} from "@/services/device/endpoints";
import GenericService from "@/services/core/GenericService";

const sendMessage = async (data: Record<string, any>) => {
  const response = await GenericService.action(DeviceEndPoint.PUBLIC_MESSAGE_URI, "send_message", data)
  return response;
}

const PublicMessageService = {
  sendMessage
}
export default PublicMessageService

import request from "@/utils/Request";
import {DeviceEndPoint} from "@/services/device/endpoints";
import GenericService from "@/services/core/GenericService";

const ReloadUser = async (data: Record<string, any>) => {
  const response = await request.authorizedAxios().post(DeviceEndPoint.TERMINAL_RELOAD_USER_URI, data)
  return response.data;
}

const bulkUploadUserData = async (data: Record<string, any>) => {
  return await GenericService.action(DeviceEndPoint.TERMINAL_URI, "bulk_upload_user_data", data)
}

const bulkUploadTransaction = async (data: Record<string, any>) => {
  return await GenericService.action(DeviceEndPoint.TERMINAL_URI, "bulk_upload_transaction", data)
}

const bulkSyncData = async (data: Record<string, any>) => {
  return await GenericService.action(DeviceEndPoint.TERMINAL_URI, "bulk_sync_data", data)
}

const Reboot = async (data: Record<string, any>) => {
  return await GenericService.action(DeviceEndPoint.TERMINAL_URI, "reboot", data)
}

const Info = async (data: Record<string, any>) => {
  return await GenericService.action(DeviceEndPoint.TERMINAL_URI, "info", data)
}

const enrollRemotely = async (data: Record<string, any>) => {
  return await GenericService.action(DeviceEndPoint.TERMINAL_URI, "enrollRemotely", data)
}

const duplicatePunchPeriod = async (data: Record<string, any>) => {
  return await GenericService.action(DeviceEndPoint.TERMINAL_URI, "duplicatePunchPeriod", data)
}

const captureSetting = async (data: Record<string, any>) => {
  return await GenericService.action(DeviceEndPoint.TERMINAL_URI, "captureSetting", data)
}

const daylightSavingTime = async (data: Record<string, any>) => {
  return await GenericService.action(DeviceEndPoint.TERMINAL_URI, "daylightSavingTime", data)
}

const upgradeFirmware = async (data: Record<string, any>) => {
  return await GenericService.action(DeviceEndPoint.TERMINAL_URI, "upgradeFirmware", data)
}

const bulkClearCommand = async (data: Record<string, any>) => {
  return await GenericService.action(DeviceEndPoint.TERMINAL_URI, "bulk_clear_command", data)
}

const bulkClearData = async (data: Record<string, any>) => {
  return await GenericService.action(DeviceEndPoint.TERMINAL_URI, "bulk_clear_data", data)
}

const bulkClearPunches = async (data: Record<string, any>) => {
  return await GenericService.action(DeviceEndPoint.TERMINAL_URI, "bulk_clear_punches", data)
}

const bulkClearCapture = async (data: Record<string, any>) => {
  return await GenericService.action(DeviceEndPoint.TERMINAL_URI, "bulk_clear_capture", data)
}

const DeviceService = {
  bulkUploadUserData,
  bulkUploadTransaction,
  bulkSyncData,
  ReloadUser,
  Reboot,
  Info,
  enrollRemotely,
  duplicatePunchPeriod,
  captureSetting,
  daylightSavingTime,
  upgradeFirmware,
  bulkClearCommand,
  bulkClearData,
  bulkClearPunches,
  bulkClearCapture
}
export default DeviceService

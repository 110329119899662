import {RJSFSchema, UiSchema} from "@rjsf/utils";
import {useTranslation} from "react-i18next";
import RecordView from "@/components/views/RecordView";
import {PsnlEP} from "@/services/psnl/endpoints";
import {AttEndPoint} from "@/services/att/endpoints";


const EmployeeScheduleRecordView = () => {
  const {t} = useTranslation("att");
  const formSchema: RJSFSchema = {
    "type": "object",
    "required": [
      "employee",
      "start_date",
      "end_date",
      "shift",
    ],
    "properties": {
      "employee": {
        "title": t("schedule.employee"),
        "type": "array",
        "minItems": 1,
        "items": {
          "type": "string",
        },
      },
      "start_date": {
        "type": "string",
        "format": "date",
        "title": t("schedule.startDate"),
      },
      "end_date": {
        "type": "string",
        "format": "date",
        "title": t("schedule.endDate"),
      },
      "shift": {
        "type": ["number"],
        "title": t("schedule.shift"),
      },
      "schedule_cover": {
        "type": ["boolean"],
        "title": t("schedule.overwrite"),
      },
    }
  };

  const uiSchema: UiSchema = {
    'ui:defaultXsWidth': 6,
    "shift": {
      "ui:widget": "m2o",
      "ui:options": {
        uri: AttEndPoint.ATT_SHIFT_URI
      }
    },
    "employee": {
      "ui:widget": "m2m",
      "ui:options": {
        uri: PsnlEP.EMPLOYEE_URI
      }
    },
  };
  return (
    <RecordView
      formSchema={formSchema}
      uiSchema={uiSchema}
      uri={AttEndPoint.SCHEDULE_URI}
    />
  )
}
export default EmployeeScheduleRecordView

import {ObjectFieldTemplateProps} from "@rjsf/utils";
import {Stack} from "@mui/material";
import {useTranslation} from "react-i18next";
import Grid from '@mui/material/Grid2';
import {ScrollTab, ScrollTabs, useScrollTabsHook} from "@/components/ScrollTabs";


const LayoutTemplate = (props: ObjectFieldTemplateProps) => {
  const {t} = useTranslation("att")
  const fields: Record<string, any> = {}
  props.properties?.forEach((element: any) => {
    fields[element.name] = element.content
  })

  const [tabs, getTabProps] = useScrollTabsHook([
    {key: 'basicSettings', title: t("timetable.basicSettings")},
    {key: 'checkIn', title: t("timetable.checkIn")},
    {key: 'checkOut', title: t("timetable.checkOut")},
    {key: 'workDay', title: t("timetable.workDay")},
    {key: 'breakTimeSettings', title: t("timetable.breakTimeSettings")},
    {key: 'unscheduleTimeSettings', title: t("timetable.unscheduleTimeSettings")},
    {key: 'otLevelSettings', title: t("timetable.otLevelSettings")},
    {key: 'ruleSettings', title: t("timetable.ruleSettings")},
    {key: 'halfDaySettings', title: t("timetable.halfDaySettings")},
  ])

  return (
    <ScrollTabs tabs={tabs}>
      <Stack spacing={3} sx={{width: '80%'}}>
        <ScrollTab {...getTabProps('basicSettings')} >
          <Grid container spacing={2}>
            <Grid size={6}>{fields.alias}</Grid>
          </Grid>
        </ScrollTab>

        <ScrollTab {...getTabProps("checkIn")}>
          <Grid container spacing={2}>
            <Grid size={6}>{fields.in_time}</Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid size={6}>{fields.begin_in}</Grid>
            <Grid size={6}>{fields.begin_in_cross_days}</Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid size={6}>{fields.end_in}</Grid>
            <Grid size={6}>{fields.end_in_cross_days}</Grid>
          </Grid>
        </ScrollTab>

        <ScrollTab {...getTabProps("checkOut")}>
          <Grid container spacing={2}>
            <Grid size={6}>{fields.out_time}</Grid>
            <Grid size={6}>{fields.out_time_cross_days}</Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid size={6}>{fields.begin_out}</Grid>
            <Grid size={6}>{fields.begin_out_cross_days}</Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid size={6}>{fields.end_out}</Grid>
            <Grid size={6}>{fields.end_out_cross_days}</Grid>
          </Grid>
        </ScrollTab>

        <ScrollTab {...getTabProps("workDay")}>
          <Grid container spacing={2}>
            <Grid size={6}>{fields.work_day}</Grid>
          </Grid>
        </ScrollTab>

        <ScrollTab {...getTabProps("breakTimeSettings")}>
          <Grid container spacing={2}>
            <Grid size={6}>{fields.break_time}</Grid>
          </Grid>
        </ScrollTab>

        <ScrollTab {...getTabProps("unscheduleTimeSettings")}>
          <Grid container spacing={2}>
            <Grid size={6}>{fields.early_in}</Grid>
            <Grid size={6}>{fields.min_early_in}</Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid size={6}>{fields.late_out}</Grid>
            <Grid size={6}>{fields.min_late_out}</Grid>
          </Grid>
        </ScrollTab>

        <ScrollTab {...getTabProps("otLevelSettings")}>
          <Grid container spacing={2}>
            <Grid size={6}>{fields.overtime_lv}</Grid>
            <Grid size={6}>{fields.overtime_lv1}</Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid size={6}>{fields.overtime_lv2}</Grid>
            <Grid size={6}>{fields.overtime_lv3}</Grid>
          </Grid>
        </ScrollTab>

        <ScrollTab {...getTabProps("ruleSettings")}>
          <Grid container spacing={2}>
            <Grid size={6}>{fields.in_required}</Grid>
            <Grid size={6}>{fields.out_required}</Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid size={6}>{fields.allow_late}</Grid>
            <Grid size={6}>{fields.allow_leave_early}</Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid size={6}>{fields.available_interval_type}</Grid>
            <Grid size={6}>{fields.available_interval}</Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid size={6}>{fields.func_key}</Grid>
          </Grid>
        </ScrollTab>

        <ScrollTab {...getTabProps('halfDaySettings')}>
          <Grid container spacing={2}>
            <Grid size={6}>{fields.first_half_in_time}</Grid>
            <Grid size={6}>{fields.second_half_in_time}</Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid size={6}>{fields.first_half_out_time}</Grid>
            <Grid size={6}>{fields.second_half_out_time}</Grid>
          </Grid>
        </ScrollTab>
      </Stack>
    </ScrollTabs>
  )
}
export default LayoutTemplate

import {Box, Button, Divider, ListItemIcon, ListItemText, MenuItem, Typography} from "@mui/material";
import Tab from "@mui/material/Tab";
import React from "react";
import {bindMenu, bindTrigger, usePopupState} from "material-ui-popup-state/hooks";
import StyledPopupMenu from "@/components/styled/StyledPopupMenu";
import {GroupAdd} from "@mui/icons-material";
import {useAppContext} from "@/provider/AppProvider";
import {useTranslation} from "react-i18next";


const Plugins = () => {
  const {t} = useTranslation("menu")
  const popupState = usePopupState({variant: "popover", popupId: "plugins"})
  const {viewApp, switchViewApp} = useAppContext()
  const plugins = ['ralvie']
  return (
    <>
      <Box
        className={plugins.includes(viewApp) ? "Mui-selected" : ""}
        sx={{
          "&.Mui-selected": {
            borderBottom: "3px solid #2E85CF",
            color: "#2E85CF"
          },
          "&.Mui-selected .MuiTypography-root": {
            color: "#2E85CF"
          }
        }}
      >
        <Button
          sx={{
            padding: "8px",
            height: "61px",
            borderRadius: 0,
          }}
          {...bindTrigger(popupState)}
        >
          <Typography variant={"h4"} sx={{textTransform: "none"}}>Apps</Typography>
        </Button>
      </Box>
      <StyledPopupMenu
        {...bindMenu(popupState)}
        sx={{
          minWidth: "120px"
        }}
      >
        <MenuItem onClick={() => {
          popupState.close()
          switchViewApp("ralvie")
        }}>
          <ListItemIcon>
            <img src={"/images/plugins/ralvie.png"} style={{width: "24px", height: "24px"}}/>
          </ListItemIcon>
          <ListItemText>{t("menu.plugins.ralvie")}</ListItemText>
        </MenuItem>
      </StyledPopupMenu>
    </>

  )
}
export default Plugins

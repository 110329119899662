import GenericService from "@/services/core/GenericService";
import {BaseEP} from "@/services/base/endpoints";

const getCompanySetting = async () => {
  return  await GenericService.action(BaseEP.SYSTEM_SETTING_URI, "get_company_setting")
}

const updateCompanySetting = async (data: Record<string, any>) => {
  return  await GenericService.action(BaseEP.SYSTEM_SETTING_URI, "update_company_setting", data)
}

const getPDFReportSetting = async () => {
  return  await GenericService.action(BaseEP.SYSTEM_SETTING_URI, "get_pdf_report_setting")
}

const updatePDFReportSetting = async (data: Record<string, any>) => {
  return  await GenericService.action(BaseEP.SYSTEM_SETTING_URI, "update_pdf_report_setting", data)
}


const getEmailSetting = async () => {
  return  await GenericService.action(BaseEP.SYSTEM_SETTING_URI, "get_email_setting")
}

const updateEmailSetting = async (data: Record<string, any>) => {
  return  await GenericService.action(BaseEP.SYSTEM_SETTING_URI, "update_email_setting", data)
}

const getWhatsAppSetting = async () => {
  return  await GenericService.action(BaseEP.SYSTEM_SETTING_URI, "get_whatsapp_setting")
}

const updateWhatsAppSetting = async (data: Record<string, any>) => {
  return  await GenericService.action(BaseEP.SYSTEM_SETTING_URI, "update_whatsapp_setting", data)
}

const getAlertSetting = async () => {
  return  await GenericService.action(BaseEP.SYSTEM_SETTING_URI, "get_alert_setting")
}

const updateAlertSetting = async (data: Record<string, any>) => {
  return  await GenericService.action(BaseEP.SYSTEM_SETTING_URI, "update_alert_setting", data)
}


const SystemSettingService = {
  getCompanySetting,
  updateCompanySetting,
  getPDFReportSetting,
  updatePDFReportSetting,
  getEmailSetting,
  updateEmailSetting,
  getWhatsAppSetting,
  updateWhatsAppSetting,
  getAlertSetting,
  updateAlertSetting
}
export default SystemSettingService

import ListView from "@/components/views/ListView";
import {GridColDef} from "@mui/x-data-grid";
import AppUrls from "@/urls";
import urls from "@/urls";
import {useTranslation} from "react-i18next";
import {ComplementEndpoint} from "@/services/complement/endpoints";

const TrainingTypeListView = () => {
  const {t} = useTranslation("complement");
  const {t: common} = useTranslation("common");
  const columns: GridColDef[] = [
    {
      field: "category_name",
      headerName: t("trainingType.categoryName"),
      flex: 1,
    },
    {
      field: "minimum_unit",
      headerName: t("trainingType.minimumUnit"),
      flex: 1,
    },
    {
      field: "unit",
      headerName: t("trainingType.unit"),
      flex: 1,
      type: "singleSelect",
      valueOptions: [
        {value: 1, label: common("duration.hour")},
        {value: 2, label: common("duration.minute")},
        {value: 3, label: common("duration.workday")},
        {value: 4, label: common("duration.HHmm")},
      ],
    },
    {
      field: "round_off",
      headerName: t("trainingType.roundOff",),
      flex: 1,
      type: "singleSelect",
      valueOptions: [
        {value: 0, label: common("boolean.no")},
        {value: 1, label: common("boolean.yes")},
      ],
    },
    {
      field: "report_symbol",
      headerName: t("trainingType.reportSymbol"),
      flex: 1,
    },
  ];

  const filters = [
    {
      key: "category_name",
      label: t("trainingType.categoryName"),
      type: "string",
    },
    {
      key: "report_symbol",
      label: t("trainingType.reportSymbol"),
      type: "string",
    },
  ];

  return (
    <ListView
      title={t("menu.trainingType", {ns: "menu"})}
      breadcrumbs={[
        {title: t("menu.complement"), href: urls.complement.root},
      ]}
      columns={columns}
      uri={ComplementEndpoint.TRAINING_TYPE_URI}
      url={AppUrls.complement.trainingType}
      filters={filters}
    />
  );
};
export default TrainingTypeListView;

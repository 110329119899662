import { RichTreeView } from '@mui/x-tree-view/RichTreeView';
import React, { useEffect } from "react";
import { TreeViewBaseItem } from "@mui/x-tree-view/models";
import { useTreeViewApiRef } from '@mui/x-tree-view/hooks';


function getItemDescendantsIds(item: TreeViewBaseItem) {
  const ids: string[] = [];
  item.children?.forEach((child) => {
    ids.push(child.id);
    ids.push(...getItemDescendantsIds(child));
  });

  return ids;
}

function getNewTreeNode(item: any, selectedSet: any) {
  let selectedCount = 0
  let total = 0
  const clone: any = {
    id: item.id,
    label: item.label,
    count: 0,
    total: 0,
  }
  if (item.children?.length) {
    clone.children = []
    item.children?.map((child: any) => {
      const newItem = getNewTreeNode(child, selectedSet)
      selectedCount = selectedCount + newItem.count
      total = total + newItem.total
      clone.children.push(newItem)
    })
  } else {
    total = total + 1
    if (selectedSet.has(item.id)) {
      selectedCount = selectedCount + 1
    }
  }
  clone.count = selectedCount
  clone.total = total

  if (item.name) {
    clone.name = item.name
  } else {
    clone.name = clone.label
  }

  if (item.children?.length) {
    clone.label = `${clone.name} (${clone.count}/${clone.total})`
  }

  if (clone.count) {
    if (!selectedSet.has(item.id)) {
      selectedSet.add(item.id)
    }
  } else {
    if (selectedSet.has(item.id)) {
      selectedSet.delete(item.id)
    }
  }
  return clone;
}

export type RenderTree = {
  id: string;
  label: string;
  count: number;
  selectCount: number;
  children?: RenderTree[];
}

type MultiSelectTreeViewProps = {
  nodes: RenderTree[]
  selected: string[]
  onSelectedChange?: (values: any) => void
}
const MultiSelectTreeView = (props: MultiSelectTreeViewProps) => {

  console.log('rrrrrrrr')

  const {nodes, selected, onSelectedChange} = props
  const [items, setItems] = React.useState<any>([]);
  const [selectedItems, setSelectedItems] = React.useState<string[]>(selected);
  const toggledItemRef = React.useRef<{ [itemId: string]: boolean }>({});
  const apiRef = useTreeViewApiRef();

  useEffect(() => {
    setSelectedItems(selected)
  }, [selected])

  const handleItemSelectionToggle = (
    event: React.SyntheticEvent,
    itemId: string,
    isSelected: boolean,
  ) => {
    toggledItemRef.current[itemId] = isSelected;
  };

  const handleSelectedItemsChange = (
    event: React.SyntheticEvent,
    newSelectedItems: string[],
  ) => {
    const apiRefCurrent: any = apiRef.current
    setSelectedItems(newSelectedItems);
    const itemsToSelect: string[] = [];
    const itemsToUnSelect: { [itemId: string]: boolean } = {};
    Object.entries(toggledItemRef.current).forEach(([itemId, isSelected]) => {
      const item = apiRefCurrent.getItem(itemId);
      if (isSelected) {
        itemsToSelect.push(...getItemDescendantsIds(item));
      } else {
        getItemDescendantsIds(item).forEach((descendantId) => {
          itemsToUnSelect[descendantId] = true;
        });
      }
    });
    const newSelectedItemsWithChildren = Array.from(
      new Set(
        [...newSelectedItems, ...itemsToSelect].filter(
          (itemId) => !itemsToUnSelect[itemId],
        ),
      ),
    );
    setSelectedItems(newSelectedItemsWithChildren);
    const selectedIds = newSelectedItemsWithChildren.filter(each => Number.isInteger(Number(each)))
    onSelectedChange && onSelectedChange(selectedIds)

    toggledItemRef.current = {};
  };

  useEffect(() => {
    const newItems: any = []
    const selectedSet = new Set(selectedItems);
    const setCount = selectedSet.size
    nodes.forEach((element: any) => {
      newItems.push(getNewTreeNode(element, selectedSet))
    })
    setItems(newItems)
    if (setCount !== selectedSet.size) {
      setSelectedItems([...selectedSet])
    }
  }, [selectedItems, nodes])

  return (
    <RichTreeView
      multiSelect
      checkboxSelection
      apiRef={apiRef}
      selectedItems={selectedItems}
      onSelectedItemsChange={handleSelectedItemsChange}
      onItemSelectionToggle={handleItemSelectionToggle}
      items={items}
    >
    </RichTreeView >
  )
}
export default MultiSelectTreeView

import {AuthEP} from "@/services/auth/endpoints";
import GenericService from "../core/GenericService";

const manualExport = async (data: Record<string, any>) => {
    const response = await GenericService.action(AuthEP.AUTO_EXPORT_URI, "manual_export", data)
    return response;
}

export const AutoExportService = {
    manualExport,
}

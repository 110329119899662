import {useRoutes} from "react-router-dom";
import PrivilegeListView from "./ListView";
import PrivilegeRecordView from "@/pages/AccessControl/privilege/RecordView";


const PrivilegeRouter = () => {
  return useRoutes([
    {index: true, element: <PrivilegeListView />},
    {path: "add", element: <PrivilegeRecordView />},
    {path: "edit", element: <PrivilegeRecordView />},
  ])
};
export default PrivilegeRouter

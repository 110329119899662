import {useRoutes} from "react-router-dom";
import PrivateRoute from "@/router/PrivateRoute";
import UserListView from "@/pages/vendor/tenement/User/ListView";


const UserRouter = () => {
  const routes = useRoutes([
    {index: true, element: <PrivateRoute requireRoles={["agent", "vendor"]}><UserListView /></PrivateRoute>},
  ])
  return routes
}
export default UserRouter

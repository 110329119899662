import request from "@/utils/Request";
import {OauthEP} from "@/services/oauth/endpoints";

const login = async (credentials: Oauth.Credentials) => {
  const response = await request.unauthorizedAxios().post(OauthEP.USER_LOGIN, credentials)
  return response.data;
}
const selectCompany = async (company_id: string) => {
  const response = await request.authorizedAxios().post(OauthEP.COMPANY_SELECTION, {company_id})
  return response.data;
}

const getCurrentUser = async () => {
  const response = await request.authorizedAxios().get(OauthEP.CURRENT_USER, {})
  return response.data;
}

const getCurrentCompany = async () => {
  const response = await request.authorizedAxios().get(OauthEP.CURRENT_COMPANY, {})
  return response.data;
}

const regionIdentify = async (data: Record<string, any>) => {
  const response = await request.unauthorizedAxios().post(OauthEP.REGION_IDENTIFY, data)
  return response.data;
}

const signUp = async (data: Record<string, any>) => {
  const response = await request.unauthorizedAxios().post(OauthEP.SIGN_UP, data)
  return response.data;
}

const verifyCompany = async (data: Record<string, any>) => {
  const response = await request.unauthorizedAxios().post(OauthEP.COMPANY_VERIFICATION, data)
  return response.data;
}

const createCompany = async (data: Record<string, any>) => {
  const response = await request.unauthorizedAxios().post(OauthEP.COMPANY_CREATION, data)
  return response.data;
}

const accountActivation = async (data: Record<string, any>) => {
  const response = await request.unauthorizedAxios().post(OauthEP.ACCOUNT_ACTIVATION, data)
  return response.data;
}

const accountInvited = async (data: Record<string, any>) => {
  const response = await request.unauthorizedAxios().post(OauthEP.ACCOUNT_INVITED, data)
  return response.data;
}

const invitedSignUp = async (data: Record<string, any>) => {
  const response = await request.unauthorizedAxios().post(OauthEP.INVITED_SIGN_UP, data)
  return response.data;
}

const emailActivation = async (data: Record<string, any>) => {
  const response = await request.unauthorizedAxios().post(OauthEP.EMAIL_ACTIVATION, data)
  return response.data;
}

const signIn = async (data: Record<string, any>) => {
  const response = await request.unauthorizedAxios().post(OauthEP.SIGN_IN, data)
  return response.data;
}

const forgotPassword = async (data: Record<string, any>) => {
  const response = await request.unauthorizedAxios().post(OauthEP.FORGOT_PASSWORD, data)
  return response.data;
}

const resetPasswordCheck = async (data: Record<string, any>) => {
  const response = await request.unauthorizedAxios().post(OauthEP.RESET_PASSWORD_CHECK, data)
  return response.data;
}

const resetPassword = async (data: Record<string, any>) => {
  const response = await request.unauthorizedAxios().post(OauthEP.RESET_PASSWORD, data)
  return response.data;
}

const joinApproval = async (data: Record<string, any>) => {
  const response = await request.unauthorizedAxios().post(OauthEP.JOIN_APPROVAL, data)
  return response.data;
}

const OauthService = {
  login,
  selectCompany,
  getCurrentUser,
  getCurrentCompany,
  regionIdentify,
  signUp,
  accountActivation,
  accountInvited,
  invitedSignUp,
  emailActivation,
  verifyCompany,
  createCompany,
  signIn,
  forgotPassword,
  resetPasswordCheck,
  resetPassword,
  joinApproval
}
export default OauthService

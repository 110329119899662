import {Box, Typography} from "@mui/material";
import {AdvancedMarker, APIProvider, Map} from "@vis.gl/react-google-maps";
import Const from "@/utils/Const";
import React, {useEffect, useMemo, useState} from "react";
import GenericService from "@/services/core/GenericService";
import {GPSEndPoint} from "@/services/gps/endpoints";
import {Stack} from "@mui/system";
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator
} from "@mui/lab";
import {AlarmOn, Business} from "@mui/icons-material";
import {useTranslation} from "react-i18next";
import dayjs from "dayjs";
import {PersonalEndPoint} from "@/services/personal/endpoints";


const RecordPanel = (props: Record<string, any>) => {
  const {t: common} = useTranslation("common")
  const {record} = props
  const [schedulePlan, setSchedulePlan] = useState<any>()
  useEffect(() => {
    if (!props.record) return
    GenericService.action(
      PersonalEndPoint.OUTDOOR_SCHEDULE_URI,
      "schedule_plan_view",
      {},
      props.record.id).then((response) => {
      setSchedulePlan(response.data)
    })
  }, [props.record])

  const schedules = useMemo(() => {
    if(!props.record?.start_date){
      return schedulePlan?.schedules
    }else{
      const items: any[] = []
      const startDate = dayjs(props.record?.start_date)
      const endDate = dayjs(props.record?.end_date)
      schedulePlan?.schedules?.map((item: any) => {
        if(startDate <= dayjs(item.dates) &&  dayjs(item.dates) <= endDate){
          items.push(item)
        }
      })
      return items
    }
  }, [props.record, schedulePlan])

  return (
    <APIProvider
      apiKey={Const.mapKey}
      libraries={['marker']}
    >
      <Stack
        spacing={2}
        sx={{
          minWidth: "512px",
          backgroundColor: "#fff",
          p: 1,
        }}>
        <Typography variant={"h4"}>{record?.schedule_name || common("common.message.noRecordSelected")}</Typography>
        <Box sx={{height: "256px"}}>
          <Map
            mapId={"schedulePlann"}
            defaultCenter={{
              lat: 10.99835602,
              lng: 77.01502627
            }}
            defaultZoom={2}
            streetViewControl={false}
          >
            {schedules?.map((item: any) => {
              return (
                <AdvancedMarker
                  position={{
                    lat: item.client?.latitude,
                    lng: item.client?.longitude,
                  }}
                  title={item?.client?.company_name}
                />
              )
            })}
          </Map>
        </Box>
        <Timeline>
          {schedules?.map((item: any, index: number) => {
            return (
              <TimelineItem>
                <TimelineOppositeContent>{item.dates}</TimelineOppositeContent>
                <TimelineSeparator>
                  <TimelineDot />
                  <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent>
                  <Stack spacing={1}>
                    <Stack direction={"row"} spacing={1}>
                      <Business />
                      <Typography>{item.client?.company_name}</Typography>
                    </Stack>
                    <Stack direction={"row"} spacing={1}>
                      <AlarmOn />
                      <Typography>{`${item.start_time} - ${item.end_time}`}</Typography>
                    </Stack>
                  </Stack>
                </TimelineContent>
              </TimelineItem>
            )
          })}
        </Timeline>
      </Stack>
    </APIProvider>
  )
}
export default RecordPanel

import GenericService from "@/services/core/GenericService";
import {AccessControlEndPoint} from "@/services/acc/endpoints";

const clear = async (data: Record<string, any>) => {
  const response = await GenericService.action(AccessControlEndPoint.COMMAND_URI, "clear", data)
  return response
}

const CommandService = {
  clear,
}
export default CommandService

import {Route, Routes} from "react-router-dom";
import RecordView from "./RecordView";
import ListView from "./ListView";


const SubRouter = () => {
  return (
    <Routes>
      <Route index element={<ListView />}/>
      <Route path={"add/"} element={<RecordView />}/>
      <Route path={"edit/"} element={<RecordView />}/>
    </Routes>
  )
};
export default SubRouter

import { useTranslation } from "react-i18next";
import { RJSFSchema, UiSchema } from "@rjsf/utils";
import { Box, Button, Grid, Typography } from "@mui/material";
import LayoutForm from "@/components/rjsf/Form";
import validator from "@rjsf/validator-ajv8";
import { Stack } from "@mui/system";
import { useRef } from "react";
import { ComplementEndpoint } from "@/services/complement/endpoints";

const DocumentRecordView = (props: any) => {
  const { onDiscard, formData } = props
  const { t } = useTranslation("per");
  const formRef = useRef<any>()
  const formSchema: RJSFSchema = {
    "type": "object",
    "required": ["certification", "expire_on"],
    "properties": {
      "certification": {
        "type": "integer",
        "title": t("employee.document")
      },
      "expire_on": {
        "type": "string",
        "title": t("employee.document.expireOn"),
        "format": "date",
      },
      "email_alert": {
        "type": ['boolean', 'null'],
        "title": t("employee.document.emailAlert"),
        default: false,
        "oneOf": [
          { const: false, title: t("boolean.no", {ns: "common"}) },
          { const: true, title: t("boolean.yes", {ns: "common"}) },
        ],
      },
      "before": {
        "type": "integer",
        "title": t("employee.document.alertBefore"),
        default: 0,
        minimum: 0,
      },
      "file": {
        "type": "string",
        "title": t("employee.document"),
      },
    }
  }

  const uiSchema: UiSchema = {
    "file": {
      'ui:widget': 'file',
    },
    "email_alert": {
      "ui:widget": "select",
    },
    "certification": {
      "ui:widget": "m2o",
      "ui:options": {
        uri: ComplementEndpoint.DOCUMENT_URI
      }
    },
  }

  const onConfirm = async () => {
    const isValid = formRef.current?.validateForm()
    if (isValid) {
      const formData = formRef.current?.state.formData
      props.onConfirm(formData)
    }
  }

  return (
    <Grid container sx={{ width: "352px", p: 2, height: "100%" }}>
      <Grid item xs={12} sx={{ height: "calc(100% - 38px)", overflowY: "auto" }}>
        <Box sx={{ pb: 2 }}>
          <Typography>
            {t("employee.document")}
          </Typography>
        </Box>
        <LayoutForm
          ref={formRef}
          schema={formSchema}
          uiSchema={uiSchema}
          widgets={{}}
          validator={validator}
          formData={formData}
          formContext={{}}
        />
      </Grid>
      <Grid item xs={12}>
        <Stack direction={"row"} spacing={1}>
          <Button title={t("common.button.confirm", {ns: "common"})} variant={"contained"} onClick={onConfirm}>
            {t("common.button.confirm", {ns: "common"})}
          </Button>
          <Button title={t("common.button.discard", {ns: "common"})} onClick={onDiscard}>
            {t("common.button.discard", {ns: "common"})}
          </Button>
        </Stack>
      </Grid>
    </Grid>
  )
}
export default DocumentRecordView

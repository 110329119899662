import ListView from "@/components/views/ListView";
import {GridColDef} from "@mui/x-data-grid";
import AppUrls from "@/urls";
import {useTranslation} from "react-i18next";
import {AttEndPoint} from "@/services/att/endpoints";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import RecordPanel from "@/pages/Attendance/EmployeeSchedule/components/RecordPanel";

const EmployeeScheduleListView = () => {
  const {t} = useTranslation("att");
  const {t: per} = useTranslation("per");
  const displayName = (value: any) => value?.display_name;
  const columns: GridColDef[] = [
    {field: "emp_code", headerName: per("employee.code"), flex: 1},
    {field: "first_name", headerName: per("employee.firstName"), flex: 1},
    {field: "last_name", headerName: per("employee.lastName"), flex: 1},
    {field: "start_date", headerName: t("schedule.startDate"), flex: 1},
    {field: "end_date", headerName: t("schedule.endDate"), flex: 1},
    {
      field: "shift",
      valueFormatter: (value: any, row: any) => row.shift_alias,
      headerName: t("schedule.shift"),
      flex: 1,
    },
  ];

  const filters = [
    {key: "employee__emp_code", label: per("employee.code"), type: "string"},
    {
      key: "employee__first_name",
      label: per("employee.firstName"),
      type: "string",
    },
  ];

  const actions = [
    {
      id: "import",
      title: t("common.action.import", {ns: "common"}),
      icon: <FileUploadOutlinedIcon/>,
      display: "page",
      url: `${AppUrls.att.employeeSchedule}/import`,
    },
  ];

  return (
    <ListView
      title={t("menu.att.employeeSchedule", {ns: "menu"})}
      columns={columns}
      uri={AttEndPoint.SCHEDULE_URI}
      url={AppUrls.att.employeeSchedule}
      filters={filters}
      disableEdit={true}
      modelActions={actions}
      RecordPanel={RecordPanel}
    />
  );
};
export default EmployeeScheduleListView;

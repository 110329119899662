import {Navigate, Route, Routes} from "react-router-dom";
import DeviceRouter from "@/pages/AccessControl/Device/router";
import TimePeriodRouter from "@/pages/AccessControl/time_period/router";
import HolidayRouter from "@/pages/AccessControl/holiday/router";
import CommandRouter from "@/pages/AccessControl/device_command/router";
import GroupRouter from "@/pages/AccessControl/group/router";
import CombinationRouter from "@/pages/AccessControl/combination/router";
import PrivilegeRouter from "@/pages/AccessControl/privilege/router";

const AccessControlRouter = () => {
  return (
    <Routes>
      <Route index element={<Navigate to={"terminal"} replace/>}/>
      <Route path={"terminal/*"} element={<DeviceRouter/>}/>
      <Route path={"command/*"} element={<CommandRouter/>}/>
      <Route path={"time-period/*"} element={<TimePeriodRouter/>}/>
      <Route path={"holiday/*"} element={<HolidayRouter/>}/>
      <Route path={"group/*"} element={<GroupRouter/>}/>
      <Route path={"combination/*"} element={<CombinationRouter/>}/>
      <Route path={"privilege/*"} element={<PrivilegeRouter/>}/>
    </Routes>
  )
}
export default AccessControlRouter

import {useTranslation} from "react-i18next";
import {GridColDef} from "@mui/x-data-grid";
import ListView from "@/components/views/ListView";
import {VendorEndPoint} from "@/services/vendor/endpoints";
import AppUrls from "@/urls";


const MobileAppListView = () => {
  const {t} = useTranslation("vendor")
  const {t: menu} = useTranslation("menu")

  const columns: GridColDef[] = [
    {field: "username", headerName: t("mobileApp.username"), flex: 1},
    {field: "enable", headerName: t("mobileApp.enable"), flex: 1},
    {field: "active", headerName: t("mobileApp.active"), flex: 1},
    {field: "login_time", headerName: t("mobileApp.loginTime"), flex: 1},
    {field: "last_login", headerName: t("mobileApp.lastLogin"), flex: 1},
    {field: "company", headerName: t("mobileApp.belongsCompany"), flex: 1},

  ]

  const filters = [
    {key: 'username', label: t("user.username"), type: 'string'},
  ]

  return (
    <ListView
      title={menu("menu.tenement.mobileApp")}
      breadcrumbs={[
        {title: menu("menu.tenement"), href: AppUrls.tenement.root},
      ]}
      columns={columns}
      uri={VendorEndPoint.MOBILE_APP_URI}
      url={AppUrls.vendor.mobileApp}
      filters={filters}
      disableAdd={true}
      disableDelete={true}
      disableEdit={true}
    />
  )
}
export default MobileAppListView

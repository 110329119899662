import {useTranslation} from "react-i18next";
import {RJSFSchema, UiSchema} from "@rjsf/utils";
import RecordView from "@/components/views/RecordView";
import urls from "@/urls";
import {VendorEndPoint} from "@/services/vendor/endpoints";
import RecordViewLayout from "@/pages/vendor/subscription/PriceStrategy/components/RecordViewLayout";


const PriceStrategyRecordView = () => {
  const {t} = useTranslation("vendor");
  const {t: menu} = useTranslation("menu");
  const formSchema: RJSFSchema = {
    "type": "object",
    "properties": {
      "type": {
        "type": "integer",
        "title": t("priceStrategy.type"),
        oneOf: [
          {const: 1, title: t("priceStrategyType.basic")},
          {const: 2, title: t("priceStrategyType.standard")},
          {const: 3, title: t("priceStrategyType.premium")},
          {const: 4, title: t("priceStrategyType.enterprise")},
          {const: 5, title: t("priceStrategyType.customization")},
          {const: 6, title: t("priceStrategyType.lite")},
          {const: 7, title: t("priceStrategyType.professional")}
        ],
        default: 1
      },
      "effective_status": {
        "type": "boolean",
        "title": t("priceStrategy.effective"),
        default: true
      },
      "subscription_duration_unit_price_pre_month": {
        "type": "number",
        "title": t("priceStrategy.monthlyFee"),
      },
      currency: {
        type: "string",
        title: t("priceStrategy.currency"),
        oneOf: [
          {const: "USD", title: t("currency.USD")},
          {const: "NGN", title: t("currency.NGN")}
        ]
      },
      "employee_threshold": {
        "type": "integer",
        "title": t("priceStrategy.employeeThreshold"),
      },
      "employee_unit_price": {
        "type": "number",
        "title": t("priceStrategy.employeeUnitPrice"),
      },
      "device_threshold": {
        "type": "integer",
        "title": t("priceStrategy.deviceThreshold"),
      },
      "device_unit_price": {
        "type": "number",
        "title": t("priceStrategy.deviceUnitPrice"),
      },
      "mobile_app_counts_limit_status": {
        "type": "boolean",
        "title": t("priceStrategy.mobileAppLimited"),
        default: false
      },
      "mobile_applications_threshold": {
        "type": "integer",
        "title": t("priceStrategy.mobileAppThreshold"),
      },
      "mobile_applications_unit_price": {
        "type": "number",
        "title": t("priceStrategy.mobileAppUnitPrice"),
      },
      "whatsapp_push_counts_limit_status": {
        "type": "boolean",
        "title": t("priceStrategy.whatsappLimited"),
        default: false
      },
      "whatsapp_push_threshold": {
        "type": "integer",
        "title": t("priceStrategy.whatsappThreshold"),
      },
      "whatsapp_push_unit_price": {
        "type": "number",
        "title": t("priceStrategy.whatsappUnitPrice"),
      },
      "transaction_counts_limit_status": {
        "type": "boolean",
        "title": t("priceStrategy.transactionLimited"),
        default: false
      },
      "transaction_threshold": {
        "type": "integer",
        "title": t("priceStrategy.transactionThreshold"),
      },
      "transaction_unit_price": {
        "type": "number",
        "title": t("priceStrategy.transactionUnitPrice"),
      },
      country: {
        type: "string",
        title: t("priceStrategy.country"),
        oneOf: [
          {const: "AE", title: t("country.UAE")},
          {const: "US", title: t("country.US")},
          {const: "NG", title: t("country.NG")},
          {const: "IN", title: t("country.IN")},
        ]
      },
      vendor: {
        type: ["string", "null"],
        title: t("priceStrategy.vendor"),
      },
      discount: {
        type: "integer",
        title: t("priceStrategy.discount")
      },
      feature: {
        type: "array",
        title: t("priceStrategy.feature"),
        items: {
          type: "integer"
        }
      }
    },
    required: [
      "type", "effective_status",
      "subscription_duration_unit_price_pre_month", "currency",
      "employee_threshold", "employee_unit_price",
      "device_threshold", "device_unit_price",
      "whatsapp_push_counts_limit_status", "whatsapp_push_threshold", "whatsapp_push_unit_price",
      "mobile_app_counts_limit_status", "mobile_applications_threshold", "mobile_applications_unit_price",
      "transaction_counts_limit_status", "transaction_threshold", "transaction_unit_price",
      "country", "discount", "feature"
    ]
  }

  const uiSchema: UiSchema = {
    'ui:defaultXsWidth': 4,
    "ui:submitButtonOptions": {"norender": true},
    effective_status: {
      "ui:widget": "switch2"
    },
    whatsapp_push_counts_limit_status: {
      "ui:widget": "switch2"
    },
    mobile_app_counts_limit_status: {
      "ui:widget": "switch2"
    },
    transaction_counts_limit_status: {
      "ui:widget": "switch2"
    },
    discount: {
      "ui:widget": "m2o",
      "ui:options": {
        uri: VendorEndPoint.DISCOUNT_URI
      }
    },
    vendor: {
      "ui:widget": "m2o",
      "ui:options": {
        uri: VendorEndPoint.COMPANY_URI
      }
    },
    feature: {
      "ui:widget": "m2m",
      "ui:options": {
        uri: VendorEndPoint.FEATURE_URI
      }
    }
  }

  const templates = {
    ObjectFieldTemplate: RecordViewLayout
  }

  return (
    <RecordView
      breadcrumbs={[
        {title: menu("menu.subscription"), href: urls.subscription.root},
        {title: menu("menu.subscription.priceStrategy"), href: urls.subscription.priceStrategy},
      ]}
      formSchema={formSchema}
      uiSchema={uiSchema}
      templates={templates}
      uri={VendorEndPoint.PRICE_STRATEGY_URI}
    />
  )
}
export default PriceStrategyRecordView

import {useRoutes} from "react-router-dom";
import OvertimeListView from "@/pages/Personal/request/overtime/ListView";
import OvertimeRecordView from "@/pages/Personal/request/overtime/RecordView";

const ManualLogRouter = () => {
  const routes = useRoutes([
    {index: true, element: <OvertimeListView />},
    {path: "add", element: <OvertimeRecordView />},
    {path: "edit", element: <OvertimeRecordView />}
  ])
  return routes
}
export default ManualLogRouter;

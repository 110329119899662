import {useTranslation} from "react-i18next";
import {GridColDef} from "@mui/x-data-grid";
import ListView from "@/components/views/ListView";
import AppUrls from "@/urls";
import {AccessControlEndPoint} from "@/services/acc/endpoints";
import SearchPanel from "@/pages/AccessControl/components/SearchPanel";
import ApprovalIcon from "@mui/icons-material/Approval";
import CombinationCloneAction from "@/pages/AccessControl/combination/components/CloneAction";


const CombinationListView = () => {
  const {t} = useTranslation("acc")

  const columns: GridColDef[] = [
    {field: "combination_no", headerName: t("combination.code"), width: 140},
    {field: "combination_name", headerName: t("combination.name"), flex: 1},
    {field: "group1", headerName: t("combination.group1"), width: 80},
    {field: "group2", headerName: t("combination.group2"), width: 80},
    {field: "group3", headerName: t("combination.group3"), width: 80},
    {field: "group4", headerName: t("combination.group4"), width: 80},
    {field: "group5", headerName: t("combination.group5"), width: 80},
  ]

  const filters = [
    {key: 'combination_name', label: t("combination.name"), type: 'string'},
  ]

  const modelActions = [
    {
      id: "combinationClone",
      title: t("data.clone"),
      icon: <ApprovalIcon />,
      validateSelected: true,
      display: "dialog",
      Form: CombinationCloneAction,
    },
  ];

  return (
    <ListView
      title={t("menu.acc.combination", {ns: "menu"})}
      breadcrumbs={[
        {title: t("menu.accessControl", {ns: "menu"}), href: AppUrls.acc.root},
      ]}
      columns={columns}
      uri={AccessControlEndPoint.COMBINATION_URI}
      url={AppUrls.acc.combination}
      filters={filters}
      SearchPanel={SearchPanel}
      disableAdd={true}
      disableDelete={true}
      modelActions={modelActions}
    />
  )
}
export default CombinationListView

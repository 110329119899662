import {useTranslation} from "react-i18next";
import React, {useState} from "react";
import Grid from "@mui/material/Grid2";
import {Stack} from "@mui/system";
import Typography from "@mui/material/Typography";
import Switch from "@mui/material/Switch";
import {Box} from "@mui/material";
import SubscriptionListView from "@/pages/OnlineSubscription/MESubscription/Billing";
import MESubscriptionPricing from "@/pages/OnlineSubscription/MESubscription/Pricing";


const MESubscription = () => {
  const {t} = useTranslation('sub')
  const [billing, setBilling] = useState<boolean>(false)
  return (
    <Grid container spacing={2}>
      <Grid size={12} sx={{display: "flex", p: 2, height: "48px"}}>
        <Stack
          direction={"row"}
          spacing={1}
          sx={{
            flex: 1,
            alignItems: "center"
          }}
        >
          <Typography>{t('onlineSubscription.pricing')}</Typography>
          <Switch
            checked={billing}
            onChange={async (event) => {
              console.log(event.target.checked)
              setBilling(event.target.checked)
            }}
          />
          <Typography>{t('onlineSubscription.billing')}</Typography>
        </Stack>
        <Box>
          {
            billing
              ? <Typography variant={"h3"}>{t('onlineSubscription.allOrders')}</Typography>
              : <Typography variant={"h3"}>{t('onlineSubscription.subscriptionPlan')}</Typography>
          }
        </Box>
      </Grid>
      <Grid size={12}>
        {
          billing
            ? <SubscriptionListView/>
            : <MESubscriptionPricing/>
        }
      </Grid>
    </Grid>
  )
}
export default MESubscription

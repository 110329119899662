import GenericService from "@/services/core/GenericService";
import {AccessControlEndPoint} from "@/services/acc/endpoints";

const cloneTimePeriod = async (data: Record<string, any>) => {
  const response = await GenericService.action(AccessControlEndPoint.TIME_PERIOD_URI, "clone", data)
  return response
}

const cloneHoliday = async (data: Record<string, any>) => {
  const response = await GenericService.action(AccessControlEndPoint.HOLIDAY_URI, "clone", data)
  return response
}

const cloneGroup = async (data: Record<string, any>) => {
  const response = await GenericService.action(AccessControlEndPoint.GROUP_URI, "clone", data)
  return response
}

const cloneCombination = async (data: Record<string, any>) => {
  const response = await GenericService.action(AccessControlEndPoint.COMBINATION_URI, "clone", data)
  return response
}

const groupTransferEmployee = async (data: Record<string, any>) => {
  const response = await GenericService.action(AccessControlEndPoint.GROUP_URI, "transfer", data)
  return response
}

const adjust = async (data: Record<string, any>) => {
  const response = await GenericService.action(AccessControlEndPoint.PRIVILEGE_URI, "adjust", data)
  return response
}

const DataCloneService = {
  cloneTimePeriod,
  cloneHoliday,
  cloneGroup,
  cloneCombination,
  groupTransferEmployee,
  adjust
}
export default DataCloneService

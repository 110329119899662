import { CustomValidator, RJSFSchema, UiSchema } from "@rjsf/utils";
import { useTranslation } from "react-i18next";
import RecordView from "@/components/views/RecordView";
import urls from "@/urls";
import { AttEndPoint } from "@/services/att/endpoints";
import React from "react";
import FlexibleFromTemplate from "./components/FlexibleFromTemplate";

const TimeTableRecordView = () => {
  const { t } = useTranslation("att");
  const formSchema: RJSFSchema = {
    type: "object",
    required: [
      "alias",
      "in_time",
      "out_time",
      "out_time_cross_days",
      "work_time_duration",
      "work_day",
      "work_type",
      "late_out",
      "min_late_out",
      "overtime_lv",
      "overtime_lv1",
      "overtime_lv2",
      "overtime_lv3",
      "in_required",
      "out_required",
      "available_interval_type",
      "available_interval",
      "func_key",
      "multiple_punch",
      "day_change",
      "first_half_out_time",
      "second_half_in_time",
      "half_day_work_time_duration",
    ],
    properties: {
      alias: {
        type: "string",
        title: t("timetable.alias"),
      },
      use_mode: {
        type: "number",
        title: "",
        default: 1,
      },
      in_time: {
        type: "string",
        default: "09:00",
        title: t("timetable.inTime"),
      },
      out_time: {
        type: "string",
        default: "18:00",
        title: t("timetable.outTime"),
      },
      out_time_cross_days: {
        type: "number",
        title: t("timetable.outTimeCrossDays"),
        default: 0,
        oneOf: [
          { const: 0, title: "0" },
          { const: 1, title: "1" },
          { const: 2, title: "2" },
          { const: 3, title: "3" },
        ],
      },
      work_time_duration: {
        type: "integer",
        title: t("timetable.workTimeDuration"),
        default: 480,
        minimum: 0,
      },
      work_day: {
        type: "number",
        // "format": "decimal",
        title: t("timetable.workDay"),
        default: 1,
        minimum: 0,
      },
      work_type: {
        type: "integer",
        title: t("timetable.workType"),
        default: 0,
        oneOf: [
          { const: 0, title: t("timetable.normalWork") },
          { const: 1, title: t("timetable.dayOff") },
          { const: 2, title: t("timetable.weekend") },
        ],
      },

      late_out: {
        type: "number",
        title: t("timetable.lateOut"),
        default: 0,
        oneOf: [
          { const: 0, title: t("timetable.unscheduledIgnore") },
          { const: 1, title: t("timetable.unscheduledMove2Normal.work") },
          { const: 2, title: t("timetable.unscheduledMove2Normal.overtime") },
          { const: 3, title: t("timetable.unscheduledMove2Weekend.overtime") },
          { const: 4, title: t("timetable.unscheduledMove2Holiday.overtime") },
        ],
      },
      min_late_out: {
        type: "number",
        title: t("timetable.minLateOut"),
        default: 60,
        minimum: 0,
      },

      overtime_lv: {
        type: "number",
        title: t("timetable.overtimeLv"),
        default: 0,
        oneOf: [
          { const: 0, title: t("timetable.overtimeLevelIgnore") },
          { const: 1, title: t("timetable.overtimeLevelDepends.on.worktime") },
          { const: 2, title: t("timetable.overtimeLevelDepends.on.overtime") },
        ],
      },
      overtime_lv1: {
        type: "number",
        title: t("timetable.overtimeLv1"),
        default: 0,
        minimum: 0,
      },
      overtime_lv2: {
        type: "number",
        title: t("timetable.overtimeLv2"),
        default: 0,
        minimum: 0,
      },
      overtime_lv3: {
        type: "number",
        title: t("timetable.overtimeLv3"),
        default: 0,
        minimum: 0,
      },

      in_required: {
        type: "number",
        title: t("timetable.inRequired"),
        default: 1,
        oneOf: [
          { const: 0, title: t("boolean.no", { ns: "common" }) },
          { const: 1, title: t("boolean.yes", { ns: "common" }) },
        ],
      },
      out_required: {
        type: "number",
        title: t("timetable.outRequired"),
        default: 1,
        oneOf: [
          { const: 0, title: t("boolean.no", { ns: "common" }) },
          { const: 1, title: t("boolean.yes", { ns: "common" }) },
        ],
      },
      available_interval_type: {
        type: "number",
        title: t("timetable.availableIntervalType"),
        default: 0,
        oneOf: [
          { const: 0, title: t("timetable.typeOption.basedOnRule") },
          { const: 1, title: t("timetable.typeOption.userDefined") },
        ],
      },
      available_interval: {
        type: "number",
        title: t("timetable.availableInterval"),
        default: 1,
        minimum: 0,
      },
      func_key: {
        type: "number",
        title: t("timetable.funcKey"),
        default: 0,
        oneOf: [
          { const: 0, title: t("boolean.no", { ns: "common" }) },
          { const: 1, title: t("boolean.yes", { ns: "common" }) },
        ],
      },
      multiple_punch: {
        type: "number",
        title: t("timetable.multiplePunch"),
        default: 0,
        oneOf: [
          { const: 0, title: t("boolean.no", { ns: "common" }) },
          { const: 1, title: t("boolean.yes", { ns: "common" }) },
        ],
      },
      day_change: {
        type: "string",
        default: "00:00",
        title: t("timetable.dayChange"),
      },

      first_half_in_time: {
        type: "string",
        default: "09:00",
        title: t("timetable.firstHalfInTime"),
      },
      second_half_in_time: {
        type: "string",
        default: "14:00",
        title: t("timetable.secondHalfInTime"),
      },
      first_half_out_time: {
        type: "string",
        default: "13:00",
        title: t("timetable.firstHalfOutTime"),
      },
      second_half_out_time: {
        type: "string",
        default: "18:00",
        title: t("timetable.secondHalfOutTime"),
      },
      half_day_work_time_duration: {
        type: "integer",
        title: t("timetable.halfDayWorkTimeDuration"),
        default: 240,
        minimum: 0,
      },
    },
  };

  const uiSchema: UiSchema = {
    // 'ui:defaultXsWidth': 4,
    "ui:submitButtonOptions": { norender: true },
    first_half_in_time: {
      "ui:readonly": true,
    },
    second_half_out_time: {
      "ui:readonly": true,
    },
    in_time: {
      "ui:widget": "time",
    },
    out_time: {
      "ui:widget": "time",
    },
    day_change: {
      "ui:widget": "time",
    },
  };
  const templates = {
    ObjectFieldTemplate: FlexibleFromTemplate,
  };

  const [formData, setFormData] = React.useState<Record<string, any>>({});
  const onChange = (e: any) => {
    setFormData({
      ...e.formData,
      first_half_in_time: e.formData.in_time,
      second_half_out_time: e.formData.out_time,
    });
  };

  const customValidate: CustomValidator = (formData, errors, uiSchema) => {
    // TODO: 目前不清楚为何in_time, out_time无法自动验证必填
    //        只能暂时手动配置验证必填
    if (!formData.in_time) {
      errors.in_time?.addError("This filed is required");
    }
    if (!formData.out_time) {
      errors.out_time?.addError("This filed is required");
    }
    return errors;
  };

  return (
    <RecordView
      formSchema={formSchema}
      uiSchema={uiSchema}
      uri={AttEndPoint.TIMETABLE_URI}
      customValidate={customValidate}
      templates={templates}
      onChange={onChange}
      formData={formData}
    />
  );
};
export default TimeTableRecordView;

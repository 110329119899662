const API_VER = 'v2'
const RESOURCE_TAG = 'api'
export const VendorEndPoint = {
  COMPANY_URI: `/vendor/${RESOURCE_TAG}/${API_VER}/companies/`,
  FEATURE_URI: `/vendor/${RESOURCE_TAG}/${API_VER}/features/`,
  DISCOUNT_URI: `/vendor/${RESOURCE_TAG}/${API_VER}/discounts/`,
  PRICE_STRATEGY_URI: `/vendor/${RESOURCE_TAG}/${API_VER}/price_strategies/`,
  ORDER_URI: `/vendor/${RESOURCE_TAG}/${API_VER}/orders/`,
  SUBSCRIPTION_ORDER_URI: `/vendor/${RESOURCE_TAG}/${API_VER}/subscription_orders/`,
  DASHBOARD: `/vendor/${RESOURCE_TAG}/${API_VER}/dashboard/`,
  DASHBOARD_TOTAL_DATA: `/vendor/${RESOURCE_TAG}/${API_VER}/dashboard/total_basic_data/`,
  DASHBOARD_USER_EMP_DATA: `/vendor/${RESOURCE_TAG}/${API_VER}/dashboard/get_emp_and_user_data/`,
  DASHBOARD_LICENSE_DATA: `/vendor/${RESOURCE_TAG}/${API_VER}/dashboard/get_license_status/`,
  DASHBOARD_SALES_DATA: `/vendor/${RESOURCE_TAG}/${API_VER}/dashboard/get_sales_data/`,
  DEVICE_URI: `/vendor/${RESOURCE_TAG}/${API_VER}/devices/`,
  USER_URI: `/vendor/${RESOURCE_TAG}/${API_VER}/users/`,
  EMPLOYEE_URI: `/vendor/${RESOURCE_TAG}/${API_VER}/employees/`,
  MOBILE_APP_URI: `/vendor/${RESOURCE_TAG}/${API_VER}/mobile_app/`,
  SUBSCRIPTION_URI: `/vendor/${RESOURCE_TAG}/${API_VER}/subscriptions/`,
}

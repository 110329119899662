const APP = 'staff'
const API_VER = 'v2'
const RESOURCE_TAG = 'api'
export const PersonalEndPoint = {
  SCHEDULE_URI: `/${APP}/${RESOURCE_TAG}/${API_VER}/schedules/`,
  holidays: `/${APP}/${RESOURCE_TAG}/${API_VER}/holidays/`,
  MANUAL_LOG_URI: `/${APP}/${RESOURCE_TAG}/${API_VER}/manual_logs/`,
  OVERTIME_URI: `/${APP}/${RESOURCE_TAG}/${API_VER}/overtimes/`,
  TRAINING_URI: `/${APP}/${RESOURCE_TAG}/${API_VER}/trainings/`,
  LEAVE_URI: `/${APP}/${RESOURCE_TAG}/${API_VER}/leaves/`,
  SWAP_SCHEDULE_URI: `/${APP}/${RESOURCE_TAG}/${API_VER}/swap_schedules/`,
  OUTDOOR_SCHEDULE_URI: `/${APP}/${RESOURCE_TAG}/${API_VER}/outdoor_schedules/`,
  TRANSACTION_URI: `/${APP}/${RESOURCE_TAG}/${API_VER}/transactions/`,
  SCHEDULED_LOG_URI: `/${APP}/${RESOURCE_TAG}/${API_VER}/scheduled_log/`,
  TOTAL_TIME_CARD_URI: `/${APP}/${RESOURCE_TAG}/${API_VER}/total_time_card/`,
  STAFF_SUMMARY_URI: `/${APP}/${RESOURCE_TAG}/${API_VER}/staff_summary/`,
  TIME_CARD_URI: `/${APP}/${RESOURCE_TAG}/${API_VER}/time_card/`,
  INCIDENT_DETAIL_URI: `/${APP}/${RESOURCE_TAG}/${API_VER}/incident_detail/`,
  BREAK_TIME_URI: `/${APP}/${RESOURCE_TAG}/${API_VER}/break_time/`,
  MULTIPLE_TRANSACTION_URI: `/${APP}/${RESOURCE_TAG}/${API_VER}/multiple_transaction/`,
  SCHEDULE_TRACK_URI: `/${APP}/${RESOURCE_TAG}/${API_VER}/schedule_track/`,
  UNSCHEDULE_TRACK_URI: `/${APP}/${RESOURCE_TAG}/${API_VER}/unschedule_track/`,
}

import { RJSFSchema, UiSchema } from "@rjsf/utils";
import { useTranslation } from "react-i18next";
import validator from "@rjsf/validator-ajv8";
import LayoutForm from "@/components/rjsf/Form";
import React, {
  ForwardedRef,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import SnackbarUtil from "@/utils/SnackbarUtil";
import { datetime2string } from "@/utils/datetime";
import EmployeeService from "@/services/psnl/EmployeeService";

const DisabelAppAction = (props: Record<string, any>, ref: ForwardedRef<any>) => {
  const { t } = useTranslation("per");
  const formRef = useRef<any>();

  const formSchema: RJSFSchema = {
    type: "object",
    properties: {
      app_status: {
        type: "boolean",
        title: t("employee.account.appLogin"),
      },
      app_punch_status: {
        type: "boolean",
        title: t("employee.mobilePunch"),
      },
      enable_outdoor_management: {
        type: "boolean",
        title: t("employee.mobileOutdoorStatus"),
      },
    },
  };

  const uiSchema: UiSchema = {
    "ui:defaultXsWidth": 12,
    "ui:submitButtonOptions": {
      norender: true,
    },
    app_status: {
      "ui:widget": "switch",
    },
    app_punch_status: {
      "ui:widget": "switch",
    },
    enable_outdoor_management: {
      "ui:widget": "switch",
    },
  };

  const onConfirm = async () => {
    const isValid = formRef.current?.validateForm();
    if (isValid) {
      const selectedIds = props.selectedIds;
      const data = formRef.current?.state.formData;
      const response = await EmployeeService.DisableApp({
        ids: selectedIds,
        ...data,
      });
      if (response.isSuccess) {
        SnackbarUtil.success(t("common.successful", {ns: "common"}));
        return true;
      } else {
        console.log(response);
      }
    }
  };

  useImperativeHandle(ref, () => ({
    onConfirm,
  }));

  return (
    <LayoutForm
      ref={formRef}
      schema={formSchema}
      uiSchema={uiSchema}
      validator={validator}
    />
  );
};
export default React.forwardRef(DisabelAppAction);

import { Navigate, useRoutes } from "react-router-dom";
import ManualApprovalRouter from "@/pages/Personal/my-approval/manual_log/router";
import OvertimeApprovalRouter from "@/pages/Personal/my-approval/overtime/router";
import TrainingApprovalRouter from "@/pages/Personal/my-approval/training/router";
import SwapScheduleApprovalRouter from "@/pages/Personal/my-approval/swap_schedule/router";
import LeaveApprovalRouter from "@/pages/Personal/my-approval/leave/router";

const MyApprovalRouter = () => {
  const routes = useRoutes([
    { index: true, element: <Navigate to={"manual-log"} replace /> },
    { path: "manual-log/*", element: <ManualApprovalRouter /> },
    { path: "overtime/*", element: <OvertimeApprovalRouter /> },
    { path: "training/*", element: <TrainingApprovalRouter /> },
    { path: "leave/*", element: <LeaveApprovalRouter /> },
    { path: "swap-schedule/*", element: <SwapScheduleApprovalRouter /> },
  ]);
  return routes;
};

export default MyApprovalRouter;

import ListView from "@/components/views/ListView";
import {GridColDef} from "@mui/x-data-grid";
import AppUrls from "@/urls";
import urls from "@/urls";
import {DeviceEndPoint} from "@/services/device/endpoints";
import {useTranslation} from "react-i18next";

import FileUploadIcon from "@mui/icons-material/FileUpload";
import TransactionUSBUpload from "./components/TransactionUSBUpload";
import RecordPanel from "@/pages/Device/Transaction/components/RecordPanel";

const TransactionListView = () => {
  const {t} = useTranslation("device");
  const {t: per} = useTranslation("per");
  const {t: common} = useTranslation("common");
  const {t: report} = useTranslation("report");
  const columns: GridColDef[] = [
    {field: "emp_code", headerName: per("employee.code"), flex: 1},
    {field: "first_name", headerName: per("employee.firstName"), flex: 1},
    {field: "last_name", headerName: per("employee.lastName"), flex: 1},
    {
      field: "dept_name",
      headerName: report("report.column.department"),
      flex: 1,
    },
    {
      field: "punch_date",
      headerName: report("report.column.punchDate"),
      flex: 1,
    },
    {
      field: "punch_time",
      headerName: report("report.column.punchTime"),
      flex: 1,
    },
    {
      field: "punch_state",
      headerName: report("report.column.punchState"),
      flex: 1,
      type: "singleSelect",
      valueOptions: [
        {value: "0", label: common("punchState.checkIn")},
        {value: "1", label: common("punchState.checkOut")},
        {value: "2", label: common("punchState.breakOut")},
        {value: "3", label: common("punchState.breakIn")},
        {value: "4", label: common("punchState.overtimeIn")},
        {value: "5", label: common("punchState.overtimeOut")},
      ]
    },
    {field: "area_alias", headerName: report("report.area"), flex: 1},
    {field: "terminal_sn", headerName: report("report.sn"), flex: 1},
    {
      field: "terminal_alias",
      headerName: report("report.column.alias"),
      flex: 1,
    },
    {field: "upload_time", headerName: report("report.column.uploadTime"), flex: 1},
  ];

  const filters = [
    {
      key: "terminal_sn",
      label: t("device.sn"),
      type: "string",
    },
    {
      key: "terminal_alias",
      label: t("device.alias"),
      type: "string",
    },
  ];
  const modelActions = [
    {
      id: "transactionUSBUpload",
      title: report("report.column.USBUpload"),
      icon: <FileUploadIcon/>,
      display: "dialog",
      Form: TransactionUSBUpload,
    },
  ];
  return (
    <ListView
      disableAdd
      disableEdit
      disableDelete
      title={t("menu.device.transaction", {ns: "menu"})}
      breadcrumbs={[{title: t("menu.device"), href: urls.device.root}]}
      columns={columns}
      uri={DeviceEndPoint.TRANSACTION_URI}
      url={AppUrls.device.transaction}
      filters={filters}
      modelActions={modelActions}
      RecordPanel={RecordPanel}
    />
  );
};
export default TransactionListView;

import {Route, Routes} from "react-router-dom";
import GroupListView from "@/pages/SystemSetting/Group/ListView";
import GroupRecordView from "@/pages/SystemSetting/Group/RecordView";


const GroupRouter = () => {
  return (
    <Routes>
      <Route index element={<GroupListView />}/>
      <Route path={"add/"} element={<GroupRecordView />}/>
      <Route path={"edit/"} element={<GroupRecordView />}/>
    </Routes>
  )
};
export default GroupRouter

import {ObjectFieldTemplateProps} from "@rjsf/utils";
import {Stack} from "@mui/material";
import {useTranslation} from "react-i18next";
import Grid from '@mui/material/Grid2';
import {ScrollTab, ScrollTabs, useScrollTabsHook} from "@/components/ScrollTabs";


const LayoutTemplate = (props: ObjectFieldTemplateProps) => {
  const {t} = useTranslation("att")
  const fields: Record<string, any> = {}
  props.properties?.forEach((element: any) => {
    fields[element.name] = element.content
  })

  const [tabs, getTabProps] = useScrollTabsHook([
    {key: 'basicSettings', title: t("workRule.basicSettings")},
    {key: 'overtimeSettings', title: t("workRule.overtimeSettings")},
    {key: 'lateRule', title: t("workRule.lateRule")},
    {key: 'earlyLeaveRule', title: t("workRule.earlyLeaveRule")},
    {key: 'halfDayWorkRule', title: t("workRule.halfDayWorkRule")},
    {key: 'missedInRule', title: t("workRule.missedInRule")},
    {key: 'missedOutRule', title: t("workRule.missedOutRule")},
  ])

  return (
    <ScrollTabs tabs={tabs}>
      <Stack spacing={3} sx={{width: '70%'}}>
        <ScrollTab {...getTabProps('basicSettings')} >
          <Grid container spacing={2}>
            <Grid size={6}>{fields.alias}</Grid>
            <Grid size={6}>{fields.department}</Grid>
          </Grid>
        </ScrollTab>

        <ScrollTab {...getTabProps("overtimeSettings")}>
          <Grid container spacing={2}>
            <Grid size={6}>{fields.use_ot}</Grid>
          </Grid>
        </ScrollTab>

        <ScrollTab {...getTabProps("lateRule")}>
          <Grid container spacing={2}>
            <Grid size={6}>{fields.mins_late_absents}</Grid>
            <Grid size={6}>{fields.mins_late_absent}</Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid size={6}>{fields.timecheckinmin}</Grid>
            <Grid size={6}>{fields.timecheckinminchoices}</Grid>
          </Grid>
        </ScrollTab>

        <ScrollTab {...getTabProps("earlyLeaveRule")}>
          <Grid container spacing={2}>
            <Grid size={6}>{fields.mins_early_absents}</Grid>
            <Grid size={6}>{fields.mins_early_absent}</Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid size={6}>{fields.timecheckoutmin}</Grid>
            <Grid size={6}>{fields.timecheckoutminchoices}</Grid>
          </Grid>
        </ScrollTab>

        <ScrollTab {...getTabProps("halfDayWorkRule")}>
          <Grid container spacing={2}>
            <Grid size={6}>{fields.half_day_work}</Grid>
          </Grid>
        </ScrollTab>

        <ScrollTab {...getTabProps("missedInRule")}>
          <Grid container spacing={2}>
            <Grid size={6}>{fields.miss_in}</Grid>
          </Grid>
        </ScrollTab>

        <ScrollTab {...getTabProps("missedOutRule")}>
          <Grid container spacing={2}>
            <Grid size={6}>{fields.miss_out}</Grid>
          </Grid>
        </ScrollTab>

      </Stack>
    </ScrollTabs>
  )
}
export default LayoutTemplate

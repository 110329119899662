import React, {useMemo, useState} from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Chip,
  Divider,
  Grid,
  List,
  ListItem, ListItemIcon,
  ListItemText,
  Typography
} from "@mui/material";
import GenericService from "@/services/core/GenericService";
import {useTranslation} from "react-i18next";
import {VendorEndPoint} from "@/services/vendor/endpoints";
import {LoadingButton} from "@mui/lab";
import {useDialogContext} from "@/provider/DialogProvider";
import PaymentView from "@/pages/OnlineSubscription/MXSubscription/Pricing/components/PaymentView";
import SnackbarUtil from "@/utils/SnackbarUtil";
import {CheckBoxOutlined, ExpandMore, ShoppingCart} from "@mui/icons-material";
import {Stack} from "@mui/system";

const OrderView = (props: Record<string, any>) => {
  const {t} = useTranslation('sub')
  const {t: api} = useTranslation('api')
  const dialog = useDialogContext();
  const [loading, setLoading] = useState<boolean>(false)
  const {plan, planType} = props
  const infos = [
    {
      field: "employeeCapacity",
      label: t('onlineSubscription.employeeCounts'),
      value: plan?.employee_capacity
    }, {
      field: "deviceCapacity",
      label: t('onlineSubscription.deviceCounts'),
      value: plan?.device_capacity
    }, {
      field: "mobileCapacity",
      label: t('onlineSubscription.mobileAppCounts'),
      value: plan?.mobile_capacity
    }, {
      field: "lengthOfService",
      label: t('onlineSubscription.lengthOfService'),
      value: `12 ${t("onlineSubscription.months")}`
    }
  ]

  const onPurchase = async () => {
    setLoading(true)
    GenericService.action(VendorEndPoint.SUBSCRIPTION_URI, "purchase", {
      price_strategy: plan.id,
    }).then((response) => {
      if (response.isSuccess) {
        dialog.showDialog({
          title: t("onlineSubscription.payment"),
          content: <PaymentView order={response.data}/>,
          disableAction: true
        })
      } else {
        console.log(response)
      }
    }).finally(() => {
      setLoading(false)
    })
  }

  console.log(plan)

  return (
    <Box sx={{width: "384px", minHeight: "192px"}}>
      <List>
        {infos.map((item: any) => {
          return (
            <ListItem key={item.field} disablePadding sx={{width: "100%", justifyContent: "center"}}>
              <ListItemText>
                <Grid container sx={{justifyContent: "space-between"}}>
                  <Grid item xs={6}>{item.label}:</Grid>
                  <Grid item xs={6} style={{textAlign: 'left'}}>
                    <Typography variant={"h4"}>{`${item.value}`}</Typography>
                  </Grid>
                </Grid>
              </ListItemText>
            </ListItem>
          )
        })}
      </List>
      <Accordion sx={{width: "352px"}}>
        <AccordionSummary
          sx={{p: 0}}
          expandIcon={<ExpandMore/>}
        >
          <Typography>{t("onlineSubscription.features")}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <List sx={{height: "128px", overflowY: "auto"}}>
            {
              plan.feature?.map((feature: any, index: number) =>
                <ListItem key={`feature${index}`} disablePadding>
                  <ListItemIcon><CheckBoxOutlined color={"primary"}/></ListItemIcon>
                  <ListItemText>{feature.name}</ListItemText>
                </ListItem>
              )
            }
          </List>
        </AccordionDetails>
      </Accordion>
      <Divider/>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          p: 2
        }}>
        <LoadingButton
          loading={loading}
          variant={"contained"}
          startIcon={<ShoppingCart/>}
          sx={{
            minWidth: "192px",
            height: "48px"
          }}
          onClick={onPurchase}
        >
          {t("onlineSubscription.purchase")}
        </LoadingButton>
      </Box>
    </Box>
  )
}
export default OrderView

import {useRoutes} from "react-router-dom";
import PrivateRoute from "@/router/PrivateRoute";
import DiscountListView from "@/pages/vendor/subscription/Discount/ListView";
import DiscountRecordView from "@/pages/vendor/subscription/Discount/RecordView";


const DiscountRouter = () => {
  const routes = useRoutes([
    {index: true, element: <PrivateRoute requireRoles={["agent", "vendor"]}><DiscountListView /></PrivateRoute>},
    {path: "add", element: <PrivateRoute requireRoles={["agent", "vendor"]}><DiscountRecordView /></PrivateRoute>},
    {path: "edit", element: <PrivateRoute requireRoles={["agent", "vendor"]}><DiscountRecordView /></PrivateRoute>},
  ])
  return routes
}
export default DiscountRouter

import {InputAdornment, TextField} from "@mui/material";
import {WidgetProps} from "@rjsf/utils";
import {Email, PersonOutlined} from "@mui/icons-material";


const EmailWidget = (props: WidgetProps) => {
  const {id, name, label, onChange, formContext, value, readonly, disabled, placeholder} = props
  return (
    <TextField
      fullWidth
      id={id}
      name={name}
      // label={label}
      value={value}
      InputProps={{
        readOnly: readonly,
        disabled: disabled,
        placeholder,
        startAdornment: <InputAdornment position={"start"}><Email/></InputAdornment>
      }}
      onChange={async (event) => {
        onChange(event.target.value)
      }}
      onKeyUp={(event) => {
        if (event.code === "Enter") {
          formContext.onSubmit?.()
        }
      }}
    />
  )
}
export default EmailWidget

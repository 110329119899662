import {Avatar, Box, Button, FormLabel, Input, Tooltip, Typography} from "@mui/material";
import {CameraAltOutlined, CloudUpload, Person} from "@mui/icons-material";
import {WidgetProps} from "@rjsf/utils";
import {Stack} from "@mui/system";
import SnackbarUtil from "@/utils/SnackbarUtil";


const PhotoWidget = (props: WidgetProps) => {
  const {label, value, placeholder} = props
  const onAvatarChange = async (event: any) => {
    const files = event.target.files
    if (files.length > 0) {
      const avatar = files[0]
      if (avatar.type !== 'image/jpeg' && avatar.type !== 'image/png' && avatar.type !== 'image/bmp') {
        SnackbarUtil.warning('Photo only support .jpg, .png, .jpeg and .bmp')
        return;
      }
      const reader = new FileReader()
      reader.readAsDataURL(avatar)
      reader.onload = (event) => {
        props.onChange(event.target?.result)
      }
    }
  }

  return (
    <Stack spacing={2} sx={{display: "flex", justifyContent: "center", alignItems: "center"}}>
      <Button
        component={"label"}
        sx={{
          display: "flex",
          justifyContent: "center",
          border: value ? "1px solid #aaa" : "2px dashed #aaa",
          background: "#fafafa",
          width: "192px",
          height: "192px",
          borderRadius: "50%"
        }}
      >
        <input
          accept={".jpg, .png, .jpeg, .bmp"}
          hidden
          type={"file"}
          onChange={onAvatarChange}
        />
        {
          value
            ? <Avatar sx={{width: "100%", height: "100%"}} src={value}/>
            : <CameraAltOutlined sx={{fontSize: 60}}/>
        }
      </Button>
      <FormLabel>{label}</FormLabel>
    </Stack>
  )
}
export default PhotoWidget

import { Navigate, useRoutes } from "react-router-dom";
import ManualLogListView from "@/pages/Personal/request/manual_log/ListView";
import ManualLogRecordView from "@/pages/Personal/request/manual_log/RecordView";
import ManualLogRouter from "@/pages/Personal/request/manual_log/router";
import OvertimeRouter from "@/pages/Personal/request/overtime/router";
import TrainingRouter from "@/pages/Personal/request/training/router";
import LeaveRouter from "@/pages/Personal/request/leave/router";

const MyRequestRouter = () => {
  const routes = useRoutes([
    {index: true, element: <Navigate to={"manual-log"} replace/>},
    {path: "manual-log/*", element: <ManualLogRouter />},
    {path: "overtime/*", element: <OvertimeRouter />},
    { path: "training/*", element: <TrainingRouter /> },
    {path: "manual-log/*", element: <ManualLogRouter />},
    {path: "leave/*", element: <LeaveRouter />}
  ])
  return routes
}
export default MyRequestRouter;

import {WidgetProps} from "@rjsf/utils";
import React from "react";
import AutocompleteTreeWidget from "@/components/rjsf/widgets/AutocompleteTreeWidget";


const Many2ManyTreeWidget = (props: WidgetProps) => {
  console.log("Many2ManyTreeWidget", props)
  return (
    <AutocompleteTreeWidget
      {...props}
      multiple={true}
    />
  )
};
export default Many2ManyTreeWidget

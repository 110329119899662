const RegExps = {
  email: new RegExp(/\w[-\w.+]*@([A-Za-z0-9][-A-Za-z0-9]+\.)+[A-Za-z]{2,14}/),
  password: new RegExp(/^.*(?=.{8,})(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%&]).*$/),
  phoneNumber: new RegExp(/^(((\+\d{2}-)?0\d{2,3}-\d{7,8})|((\+\d{2}-)?(\d{2,3}-)?([1][3,4,5,7,8][0-9]\d{8})))$/),
  code: new RegExp(/^[0-9A-Za-z]+$/),
  alpInput: new RegExp(/^[a-zA-Z0-9_ ]*$/),
  employeeCode: new RegExp(/^[0-9A-Za-z_]+$/),
  company: {
    phoneNumber: new RegExp(/^\d{7,11}$/)
  },
  alphanumeric: new RegExp(/^[0-9A-Za-z]+$/),
  alphanumericCode: new RegExp(/^[0-9A-Za-z ]+$/),
  alphanumericName: new RegExp(/^[a-zA-Z0-9_ ]*$/),
  digitalCode: new RegExp(/^[0-9]+$/),
  xssClean: new RegExp(/[>=<]/),
}
export default RegExps

import {useTranslation} from "react-i18next";
import {GridColDef} from "@mui/x-data-grid";
import ListView from "@/components/views/ListView";
import AppUrls from "@/urls";
import {GPSEndPoint} from "@/services/gps/endpoints";


const GPSOfEmployeeListView = () => {
  const { t: t } = useTranslation("gps");
  const { t: tMenu } = useTranslation("menu");


  const columns: GridColDef[] = [
    {field: "employee_code", headerName: t("GPSOfEmployee.employeeId"), flex: 1,},
    {field: "employee_name", headerName: t("GPSOfEmployee.employeeName"), flex: 1},
    {field: "location", headerName: t("GPSOfEmployee.location"), flex: 1},
    {field: "longitude", headerName: t("GPSOfEmployee.longitude"), flex: 1},
    {field: "latitude", headerName: t("GPSOfEmployee.latitude"), flex: 1},
    {field: "distance", headerName: t("GPSOfEmployee.distance"), flex: 1},
    {field: "start_date", headerName: t("GPSOfEmployee.startDate"), flex: 1},
    {field: "end_date", headerName: t("GPSOfEmployee.endDate"), flex: 1},
  ]

  const filters = [
    {key: 'emp_code', label: t("GPSOfEmployee.employeeId"), type: 'string'},
    {key: 'first_name', label: t("GPSOfEmployee.employeeName"), type: 'string'}
  ]


  return (
    <ListView
      title={tMenu("menu.gps.geofenceOfEmployee")}
      breadcrumbs={[
        {title: tMenu("menu.gps"), href: AppUrls.gps.root},
      ]}
      columns={columns}
      uri={GPSEndPoint.EMPLOYEE_GPS_URI}
      url={AppUrls.gps.gpsOfEmployee}
      filters={filters}
    />
  )
}
export default GPSOfEmployeeListView

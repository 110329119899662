import ListView from "@/components/views/ListView";
import { GridColDef } from "@mui/x-data-grid";
import AppUrls from "@/urls";
import { DeviceEndPoint } from "@/services/device/endpoints";
import { useTranslation } from "react-i18next";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";

import SnackbarUtil from "@/utils/SnackbarUtil";
import PublicMessageService from "@/services/device/PublicMessagesService";

const PublicMessageListView = () => {
  const { t } = useTranslation("device");
  const columns: GridColDef[] = [
    {field: "sn", headerName: t("device.sn"), flex: 1},
    {field: "alias", headerName: t("device.alias"), flex: 1},
    { field: "start_time", headerName: t("publicMessage.startTime"), flex: 1 },
    { field: "duration", headerName: t("publicMessage.duration"), flex: 1 },
    { field: "content", headerName: t("publicMessage.content"), flex: 1 },
    { field: "last_send", headerName: t("publicMessage.lastSend"), flex: 1 },
  ];

  const filters = [
    {
      key: "terminal__sn",
      label: t("device.sn"),
      type: "string",
    },
    {
      key: "terminal__alias",
      label: t("device.alias"),
      type: "string",
    },
    {
      key: "content",
      label: t("publicMessage.content"),
      type: "string",
    },
  ];

  const onSendPublicMessage = async (selectedIds: string) => {
    const response = await PublicMessageService.sendMessage({
      ids: selectedIds,
    });
    if (response.isSuccess) {
      SnackbarUtil.success(t("common.message.successful", {ns: "common"}));
      return true;
    } else {
      console.log(response);
      return false;
    }
  };

  const modelActions = [
    {
      id: "publicMessageSendMessage",
      title: t("publicMessage.sendMessage"),
      icon: <ExitToAppIcon />,
      display: "dialog",
      callback: onSendPublicMessage,
      confirmation: t("publicMessage.sendMessageAction.confirm"),
      validateSelected: true,
    },
  ];

  return (
    <ListView
      title={t("menu.device.publicMessage", {ns:"menu"})}
      breadcrumbs={[
        { title: t("menu.device.message", {ns:"menu"}), href: AppUrls.device.root },
      ]}
      columns={columns}
      uri={DeviceEndPoint.PUBLIC_MESSAGE_URI}
      url={AppUrls.device.publicMessage}
      filters={filters}
      excludeActions={["add", "delete"]}
      modelActions={modelActions}
    />
  );
};
export default PublicMessageListView;

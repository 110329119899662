import request from "@/utils/Request";
import {AuthEP} from "@/services/auth/endpoints";
import GenericService from "@/services/core/GenericService";

const getCurrentUser = async () => {
  const response = await request.authorizedAxios().get(AuthEP.CURRENT_USER, {})
  return response.data;
}

const getCurrentMembership = async () => {
  return await GenericService.action(AuthEP.ACCOUNT_URI, "current_membership")
}

const getJoinedCompany = async (currentUser: string) => {
  return await GenericService.action(AuthEP.ACCOUNT_URI, "joined_company", {}, currentUser)
}

const joinCompany = async (currentUser: string, data: Record<string, any>) => {
  return await GenericService.action(AuthEP.ACCOUNT_URI, "join_company", data, currentUser)
}

const addAsVendor = async (currentUser: string, data: Record<string, any>) => {
  return await GenericService.action(AuthEP.ACCOUNT_URI, "add_as_vendor", data, currentUser)
}

const createCompany = async (currentUser: string, data: Record<string, any>) => {
  return await GenericService.action(AuthEP.ACCOUNT_URI, "create_company", data, currentUser)
}

const switchCompany = async (currentUser: string, data: Record<string, any>) => {
  return await GenericService.action(AuthEP.ACCOUNT_URI, "switch_company", data, currentUser)
}

const inviteUser = async (currentUser: string, data: Record<string, any>) => {
  return await GenericService.action(AuthEP.ACCOUNT_URI, "invite_user", data, currentUser)
}

const changePassword = async (user: string, data: Record<string, any>) => {
  return await GenericService.action(AuthEP.ACCOUNT_URI, "change_password", data, user)
}

const AuthService = {
  getCurrentUser,
  getCurrentMembership,
  getJoinedCompany,
  joinCompany,
  addAsVendor,
  createCompany,
  switchCompany,
  inviteUser,
  changePassword
}
export default AuthService

import {
  Card,
  CardContent,
  CardHeader,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem, Radio,
  RadioGroup,
  Select
} from "@mui/material";
import React, {useEffect} from "react";
import {useTranslation} from "react-i18next";
import GenericService from "@/services/core/GenericService";
import {VendorEndPoint} from "@/services/vendor/endpoints";
import {AdvancedMarker, APIProvider, Map, Pin} from "@vis.gl/react-google-maps";
import Const from "@/utils/Const";


const DistributionMap = () => {
  const {t} = useTranslation("vendor");
  const [data, setData] = React.useState([]);
  const [category, setCategory] = React.useState("DEVICE");
  const center = {
    lat: 10.99835602,
    lng: 77.01502627
  }
  useEffect(() => {
    GenericService.action(VendorEndPoint.DASHBOARD, "location_distribution", {category}).then((response) => {
      setData(response.data)
    })
  }, [category])
  return (
    <APIProvider
      apiKey={Const.mapKey}
      libraries={['marker']}
    >
      <Card>
        <CardHeader
          title={t("dashboard.distributionMap")}
          action={
            <RadioGroup
              row
              value={category}
              onChange={(event, value) => setCategory(value)}
            >
              <FormControlLabel control={<Radio size={"small"}/>} label={"Device"} value={"DEVICE"}/>
              <FormControlLabel control={<Radio size={"small"}/>} label={"Company"} value={"COMPANY"}/>
            </RadioGroup>
          }
        />
        <CardContent sx={{height: "300px"}}>
          <Map
            mapId={"device-distribution"}
            defaultCenter={center}
            defaultZoom={1}
            streetViewControl={false}
            mapTypeControl={false}
          >
            {data.map((device: Record<string, any>) => {
                const color = device.status === 0 ? "red" : "green"
                return (
                  <AdvancedMarker position={{
                    lat: device.latitude,
                    lng: device.longitude
                  }}>
                    <Pin
                      scale={0.5}
                      background={color}
                      borderColor={color}
                      glyphColor={color}
                    />
                  </AdvancedMarker>
                )
              }
            )}
          </Map>
        </CardContent>
      </Card>
    </APIProvider>
  )
}
export default DistributionMap

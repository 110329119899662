import {Box} from "@mui/material";
import React from "react";
import {useRegionContext} from "@/provider/RegionProvider";


const CompanyLogo = () => {
  const {region} = useRegionContext()
  return (
    <Box sx={{
      width: "200px",
      height: "64px",
      padding: "0 12px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    }}>
      <img
        alt="BioTime Cloud"
        src={`/images/${region.identify_code}/logo.png`}
        style={{width: "120px", height: "40px"}}
      />
    </Box>
  )
}
export default CompanyLogo

import {Route, Routes} from "react-router-dom";
import RecordView from "./RecordView";
import ListView from "./ListView";
import ResignImportView from "@/pages/Team/Resign/ImportView";
import EditResignRecordView from "@/pages/Team/Resign/EditRecordView";


const ResignRouter = () => {
  return (
    <Routes>
      <Route index element={<ListView />}/>
      <Route path={"add/"} element={<RecordView />}/>
      <Route path={"edit/"} element={<RecordView />}/>
      {/*<Route path={"edit/"} element={<EditResignRecordView />}/>*/}
      <Route path={"import"} element={<ResignImportView />}/>
    </Routes>
  )
};
export default ResignRouter

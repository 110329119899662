import ListView from "@/components/views/ListView";
import {GridColDef} from "@mui/x-data-grid";
import AppUrls from "@/urls";
import {useTranslation} from "react-i18next";
import {AttEndPoint} from "@/services/att/endpoints";


const ShiftListView = () => {
  const {t} = useTranslation("att")
  const columns: GridColDef[] = [
    {field: "alias", headerName: t("shift.alias"), flex: 1},
    {field: "cycle_unit", headerName: t("shift.cycleUnit"), flex: 1,
      type: "singleSelect",
      valueOptions: [
        { value: 0, label: t("cycleUnit.day") },
        { value: 1, label: t("cycleUnit.week") },
      ],
    },
    {field: "shift_cycle", headerName: t("shift.shiftCycle"), flex: 1},
    {field: "auto_shift", headerName: t("shift.autoShift"), flex: 1,
      type: "singleSelect",
      valueOptions: [
        { value: 0, label: t("boolean.no", {ns: "common"}) },
        { value: 1, label: t("boolean.yes", {ns: "common"}) },
      ],
    },
  ]

  const filters = [
    {key: 'alias', label: t("shift.alias"), type: 'string'},
  ]

  return (
    <ListView
      title={t("menu.att.shift", {ns: "menu"})}
      columns={columns}
      uri={AttEndPoint.ATT_SHIFT_URI}
      url={AppUrls.att.shift}
      filters={filters}
    />
  )
}
export default ShiftListView

import React, {ForwardedRef, useImperativeHandle, useRef} from "react";
import {useTranslation} from "react-i18next";
import {RJSFSchema, UiSchema} from "@rjsf/utils";
import LayoutForm from "@/components/rjsf/Form";
import SnackbarUtil from "@/utils/SnackbarUtil";
import validator from "@rjsf/validator-ajv8";
import RequestService from "@/services/personal/RequestService";


const LeaveWithdraw = (props: Record<any, any>, ref: ForwardedRef<any>) => {
  const {t} = useTranslation("personal");
  const {t: tCommon} = useTranslation("common");
  const formRef = useRef<any>();
  const formSchema: RJSFSchema = {
    type: "object",
    properties: {
      apply_reason: {
        type: "string",
        title: t("leave.revokeRemark"),
      },
    },
  };

  const uiSchema: UiSchema = {
    "ui:defaultXsWidth": 12,
    "ui:submitButtonOptions": {norender: true},
    apply_reason: {
      "ui:widget": "textarea",
    },
  };

  const onConfirm = async () => {
    const isValid = formRef.current?.validateForm();
    if (isValid) {
      const selectedId = props.recordId;
      const data = formRef.current?.state.formData;
      const response = await RequestService.leaveWithdraw({
        id: selectedId,
        ...data,
      });
      if (response.isSuccess) {
        SnackbarUtil.success(tCommon("common.successful"));
        return true;
      } else {
        console.log(response);
      }
    }
  };
  useImperativeHandle(ref, () => ({
    onConfirm,
  }));

  return (
    <LayoutForm
      ref={formRef}
      schema={formSchema}
      uiSchema={uiSchema}
      validator={validator}
    />
  );
};
export default React.forwardRef(LeaveWithdraw)

import {CustomValidator, RJSFSchema, UiSchema} from "@rjsf/utils";
import {useTranslation} from "react-i18next";
import RecordView from "@/components/views/RecordView";
import {ComplementEndpoint} from "@/services/complement/endpoints";
import RegExps from "@/utils/RegExps";

const TrainingTypeRecordView = () => {
  const {t} = useTranslation("complement");
  const {t: common} = useTranslation("common");
  const formSchema: RJSFSchema = {
    type: "object",
    required: [
      "category_name",
      "minimum_unit",
      "unit",
      "round_off",
      "report_symbol",
    ],
    properties: {
      category_name: {
        type: "string",
        title: t("trainingType.categoryName"),
        maxLength: 50
      },
      minimum_unit: {
        type: "number",
        title: t("trainingType.minimumUnit"),
      },
      unit: {
        type: "number",
        title: t("trainingType.unit"),
        oneOf: [
          {const: 1, title: common("duration.hour")},
          {const: 2, title: common("duration.minute")},
          {const: 3, title: common("duration.workday")},
          {const: 4, title: common("duration.HHmm")},
        ],
      },
      round_off: {
        type: "number",
        title: t("trainingType.roundOff"),
        oneOf: [
          {const: 0, title: common("boolean.no")},
          {const: 1, title: common("boolean.yes")},
        ],
      },
      report_symbol: {
        type: "string",
        title: t("trainingType.reportSymbol"),
        maxLength: 5
      },
    },
  };

  const uiSchema: UiSchema = {
    "ui:defaultXsWidth": 4,
    "ui:submitButtonOptions": {norender: true},
  };

  const customValidate: CustomValidator = (formData, errors, uiSchema) => {
    if (formData.category_name && !RegExps.alpInput.test(formData.category_name)) {
      errors.category_name?.addError(common("form.validateError.alpInputOnly"))
    }
    if (formData.report_symbol && !RegExps.alpInput.test(formData.report_symbol)) {
      errors.report_symbol?.addError(common("form.validateError.alpInputOnly"))
    }

    return errors
  }

  return (
    <RecordView
      formSchema={formSchema}
      uiSchema={uiSchema}
      uri={ComplementEndpoint.TRAINING_TYPE_URI}
      // customValidate={customValidate}
    />
  );
};
export default TrainingTypeRecordView;

import {useRoutes} from "react-router-dom";
import TimePeriodListView from "./ListView";
import TimePeriodRecordView from "@/pages/AccessControl/time_period/RecordView";


const TimePeriodRouter = () => {
  return useRoutes([
    {index: true, element: <TimePeriodListView />},
    {path: "add", element: <TimePeriodRecordView />},
    {path: "edit", element: <TimePeriodRecordView />},
  ])
};
export default TimePeriodRouter

import React, {useState} from "react";
import {Box, Divider, Grid, List, ListItem, ListItemText} from "@mui/material";
import {ShoppingCart} from "@mui/icons-material";
import GenericService from "@/services/core/GenericService";
import {useTranslation} from "react-i18next";
import {VendorEndPoint} from "@/services/vendor/endpoints";
import {LoadingButton} from "@mui/lab";
import PaymentView from "@/pages/OnlineSubscription/MESubscription/Pricing/components/PaymentView";
import SnackbarUtil from "@/utils/SnackbarUtil";
import {useDialogContext} from "@/provider/DialogProvider";

const OrderView = (props: Record<string, any>) => {
  const {t} = useTranslation('sub')
  const {t: api} = useTranslation('api')
  const dialog = useDialogContext();
  const [loading, setLoading] = useState<boolean>(false)
  const {plan, planType, subType, amount} = props
  const getLengthOfService = (subType: string) => {
    switch (subType) {
      case "QUARTER":
        return t('onlineSubscription.quarter')
      case "ANNUAL":
        return t('onlineSubscription.annual')
      default:
        return t('onlineSubscription.month')
    }
  }

  const infos = [
    {
      field: "employeeCapacity",
      label: t('onlineSubscription.employeeCounts'),
      value: plan?.employee_capacity
    }, {
      field: "deviceCapacity",
      label: t('onlineSubscription.deviceCounts'),
      value: plan?.device_capacity
    }, {
      field: "mobileCapacity",
      label: t('onlineSubscription.mobileAppCounts'),
      value: plan?.mobile_capacity
    }, {
      field: "lengthOfService",
      label: t('onlineSubscription.lengthOfService'),
      value: `${getLengthOfService(subType)}`
    }, {
      field: "paymentAmount",
      label: t('onlineSubscription.paymentAmount'),
      value: `$ ${amount}`
    }
  ]

  const onPurchase = async () => {
    setLoading(true)
    GenericService.action(VendorEndPoint.SUBSCRIPTION_URI, "purchase", {
      price_strategy: plan.id,
      sub_type: subType
    }).then((response) => {
      if (response.isSuccess) {
        dialog.showDialog({
          title: t("onlineSubscription.payment"),
          content: <PaymentView order={response.data}/>,
          disableAction: true
        })
      } else {
        console.log(response)
      }
    }).finally(() => {
      setLoading(false)
    })
  }

  return (
    <Box sx={{width: "500px", minHeight: "320px"}}>
      <List>
        {infos.map((item: any) => {
          return (
            <ListItem key={item.field} sx={{width: "100%", justifyContent: "center"}}>
              <ListItemText>
                <Grid container sx={{justifyContent: "space-between"}}>
                  <Grid item xs={6}>{item.label}:</Grid>
                  <Grid item xs={6} style={{textAlign: 'left'}}>{`${item.value}`}</Grid>
                </Grid>
              </ListItemText>
            </ListItem>
          )
        })}
      </List>
      <Divider/>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          p: 2
        }}>
        <LoadingButton
          loading={loading}
          startIcon={<ShoppingCart/>}
          variant={"contained"}
          sx={{
            minWidth: "160px"
          }}
          onClick={onPurchase}
        >
          {t("onlineSubscription.purchase")}
        </LoadingButton>
      </Box>
    </Box>
  )
}
export default OrderView

import {RJSFSchema, UiSchema} from "@rjsf/utils";
import {useTranslation} from "react-i18next";
import RecordView from "@/components/views/RecordView";
import {AttEndPoint} from "@/services/att/endpoints";
import LayoutTemplate from "./components/FromTemplate";
import React from "react";


const BreakTimeRecordView = () => {
  const {t} = useTranslation("att");
  const [formSchema, setFormSchema] = React.useState<RJSFSchema>({
    "type": "object",
    "required": [
      'alias', 'start_time', 'end_time', 'duration', 'calc_type',
      'available_interval_type', 'available_interval', 'func_key',
    ],
    "properties": {
      "alias": {
        "type": "string",
        "title": t("breakTime.alias"),
        "maxLength": 50
      },
      "calc_type": {
        "type": "number",
        "title": t("breakTime.calcType"),
        "default": 0,
        "oneOf": [
          {const: 0, title: t("breakTime.autoDeduct")},
          {const: 1, title: t("breakTime.requiredPunch")},
        ]
      },
      "start_time": {
        "type": "string",
        "default": "12:00",
        "title": t("breakTime.startTime"),
      },
      "end_time": {
        "type": "string",
        "default": "13:00",
        "title": t("breakTime.endTime"),
      },
      "duration": {
        "type": "number",
        "default": 60,
        "title": t("breakTime.duration"),
      },
      "available_interval_type": {
        "type": "number",
        "title": t("breakTime.availableIntervalType"),
        "default": 0,
        "oneOf": [
          {const: 0, title: t("availableIntervalType.ruleBased"),},
          {const: 1, title: t("availableIntervalType.userDefined")},
        ],
      },
      "available_interval": {
        "type": "number",
        "title": t("breakTime.availableInterval"),
        "default": 1,
      },
      "func_key": {
        "type": "number",
        "title": t("breakTime.funcKey"),
        "default": 0,
        "oneOf": [
          {const: 0, title: t("boolean.no", {ns: "common"}),},
          {const: 1, title: t("boolean.yes", {ns: "common"})},
        ],
      },
      "multiple_punch": {
        "type": "number",
        "title": t("breakTime.multiplePunch"),
        "default": 1,
        "oneOf": [
          {const: 0, title: t("boolean.no", {ns: "common"}),},
          {const: 1, title: t("boolean.yes", {ns: "common"})},
        ],
      },
      "minimum_duration": {
        "type": "number",
        "title": t("breakTime.minimumDuration"),
        "default": 1,
      },
      "early_in": {
        "type": "number",
        "title": t("breakTime.earlyIn"),
        "default": 0,
        "oneOf": [
          {const: 0, title: t("unscheduled.ignore"),},
          {const: 1, title: t("unscheduled.move2normalWork")},
          {const: 2, title: t("unscheduled.move2normalOvertime")},
          {const: 3, title: t("unscheduled.move2weekendOvertime")},
          {const: 4, title: t("unscheduled.move2holidayOvertime")},
        ],
      },
      "min_early_in": {
        "type": "number",
        "title": t("breakTime.minEarlyIn"),
        "default": 0,
      },
      "late_in": {
        "type": "number",
        "title": t("breakTime.lateIn"),
        "default": 0,
        "oneOf": [
          {const: 0, title: t("breakTime.lateInMode.ignore"),},
          {const: 1, title: t("breakTime.lateInMode.move2Late")},
          {const: 2, title: t("breakTime.lateInMode.move2EarlyOut")},
          {const: 3, title: t("breakTime.lateInMode.move2Absence")},
        ],
      },
      "min_late_in": {
        "type": "number",
        "title": t("breakTime.minLateIn"),
        "default": 0,
      },
    }
  })

  const [uiSchema, setUiSchema] = React.useState<UiSchema>({
    "ui:submitButtonOptions": {"norender": true},
    "start_time": {
      "ui:widget": "time",
    },
    "end_time": {
      "ui:widget": "time",
    },
    "available_interval": {
      "ui:readonly": true
    }
  })

  const templates = {
    ObjectFieldTemplate: LayoutTemplate
  }

  const [formData, setFormData] = React.useState<Record<string, any>>({})
  const onChange = (e: any) => {
    if (e.formData.available_interval_type !== formData.available_interval_type) {
      const availableIntervalReadonly = e.formData.available_interval_type === 1 ? false : true
      setUiSchema((old) => {
        return {
          ...old,
          "available_interval": {
            "ui:readonly": availableIntervalReadonly
          }
        }
      })
    }

    if (e.formData.calc_type !== formData.calc_type) {
      let requireds = [
        'alias', 'start_time', 'end_time', 'duration', 'calc_type',
        'available_interval_type', 'available_interval', 'func_key',
      ]
      if (e.formData.calc_type === 1) {
        requireds = requireds.concat(['multiple_punch', 'minimum_duration', 'early_in',
          'min_early_in', 'late_in', 'min_late_in'])
      }
      setFormSchema((old) => {
        return {...old, "required": requireds}
      })
    }

    setFormData({
      ...e.formData,
    })

  }

  function calculateTimeDiffInSeconds(start: any, end: any) {
    const start_time = new Date(`1970-01-01T${start}`);
    const end_time = new Date(`1970-01-01T${end}`);
    if (end_time <= start_time) {
      end_time.setDate(end_time.getDate() + 1);
    }
    const startOfSeconds = start_time.getTime() / 1000;
    const endOfSeconds = end_time.getTime() / 1000;
    return endOfSeconds - startOfSeconds
  }

  function durationValidate(formData: any, errors: any, uiSchema: any) {
    const time_duration = formData.duration
    const start_time = formData.start_time
    const end_time = formData.end_time
    const durationInSeconds = calculateTimeDiffInSeconds(start_time, end_time)
    if (time_duration*60 > durationInSeconds) {
      errors.duration.addError(t("break.duration.invalid"));
    }
    return errors;
  }

  return (
    <RecordView
      formSchema={formSchema}
      uiSchema={uiSchema}
      uri={AttEndPoint.BREAK_TIME_URI}
      templates={templates}
      onChange={onChange}
      formData={formData}
      customValidate={durationValidate}
    />
  )
}
export default BreakTimeRecordView

import {Route, Routes} from "react-router-dom";
import  BioTemplateListView from "./ListView";


const BioTemplateRouter = () => {
  return (
    <Routes>
      <Route index element={<BioTemplateListView />}/>
    </Routes>
  )
};
export default BioTemplateRouter

import {DataGrid, GridColDef} from "@mui/x-data-grid";
import {useTranslation} from "react-i18next";
import {Box} from "@mui/material";
import GenericService from "@/services/core/GenericService";
import {TeamEP} from "@/services/team/endpoints";
import {PersonalEndPoint} from "@/services/personal/endpoints";
import {PsnlEP} from "@/services/psnl/endpoints";
import {useEffect, useState} from "react";


const AreaEmployeeView = (props: Record<string, any>) => {
  console.log("Props:", props)
  const {t} = useTranslation("per")
  const [loading, setLoading] = useState<boolean>(false)
  const [gridData, setGridData] = useState({
    rows: [],
    total: 0
  })
  const [paginationModel, setPaginationModel] = useState<{
    page: number;
    pageSize: number;
  }>({page: 0, pageSize: 10});
  const columns: GridColDef[] = [
    {field: "emp_code", headerName: t("employee.code"), flex: 1},
    {field: "first_name", headerName: t("employee.firstName"), flex: 1},
    {field: "last_name", headerName: t("employee.lastName"), flex: 1},
    {field: "fingerprint", headerName: t("employee.fingerprint"), flex: 1},
    {field: "face", headerName: t("employee.face"), flex: 1},
    {field: "palm", headerName: t("employee.palm"), flex: 1},
    {field: "vl_face", headerName: t("employee.VLFace"), flex: 1},
    {field: "enroll_sn", headerName: t("employee.enrollDevice"), flex: 1},
    {field: "update_time", headerName: t("common.column.updateTime", {ns: "common"}), flex: 1},
  ]
  const getGridData = async () => {
    setLoading(true)
    GenericService.query(
      PsnlEP.AREA_URI,
      "employees",
      {
        page: paginationModel.page + 1,
        page_size: paginationModel.pageSize,
      },
      props.area.id
    ).then((response) => {
      setGridData({
        rows: response.data.data,
        total: response.data.total
      })
    }).finally(() => {
      setLoading(false)
    })
  }
  useEffect(() => {
    getGridData().then()
  }, [paginationModel])
  return (
    <Box sx={{minHeight: "384px", width: "50vw"}}>
      <DataGrid
        loading={loading}
        columns={columns}
        rows={gridData.rows}
        rowCount={gridData.total}
        sx={{
          height: "384px",
        }}
        pagination={true}
        paginationMode={"server"}
        pageSizeOptions={[5, 10, 20, 50]}
        paginationModel={paginationModel}
        onPaginationModelChange={(model: any, details: any) => {
          setPaginationModel(model);
        }}
      />
    </Box>
  )
}
export default AreaEmployeeView

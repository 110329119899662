import {useTranslation} from "react-i18next";
import {GridColDef} from "@mui/x-data-grid";
import urls from "@/urls";
import {ReportEndPoint} from "@/services/report/endpoints";
import ReportView from "@/pages/Report/ReportLibrary/components/ReportView";
import {useWeekdayHook} from "@/hooks/optionsHooks";
import { useReportEmployee } from "@/hooks/employee";

const FirstInLastOutReport = () => {
  const {t} = useTranslation("report");
  const {t: per} = useTranslation("per");
  const weekdayOptions = useWeekdayHook()
  const employeeFields = useReportEmployee()
  const columns: GridColDef[] = [
    ...employeeFields,
    {
      field: "att_date",
      headerName: t("report.column.scheduleDate"),
      flex: 1,
    },
    {
      field: "weekday",
      headerName: t("report.column.weekday"),
      flex: 1,
      type: "singleSelect",
      valueOptions: weekdayOptions,
    },
    {
      field: "check_in",
      headerName: t("report.column.checkIn"),
      flex: 1,
    },
    {
      field: "check_out",
      headerName: t("report.column.checkOut"),
      flex: 1,
    },
    {
      field: "total_time",
      headerName: t("report.column.totalTime"),
      flex: 1,
    },
  ];

  return (
    <ReportView
      title={t("reportLibrary.firstInLastOut")}
      breadcrumbs={[]}
      columns={columns}
      uri={ReportEndPoint.FIRSTINLASTOUT_URI}
      url={urls.report.firstInLastOut}
      hideColumns={['first_name', 'last_name', 'department_code', 'position_code', 'position_name']}
    />
  );
};
export default FirstInLastOutReport;

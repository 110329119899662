import {Route, Routes} from "react-router-dom";
import GPSOfDepartmentListView from "./ListView";
import GPSOfDepartmentRecordView from "@/pages/GPS/GPSOfDepartment/RecordView";
import MapProvider from "@/provider/MapProvider";

const GpsOfDepartmentRouter = () => {
  return (
    <Routes>
      <Route index element={<GPSOfDepartmentListView />}/>
      <Route path={"add/"} element={<MapProvider><GPSOfDepartmentRecordView  /></MapProvider>}/>
      <Route path={"edit/"} element={<MapProvider><GPSOfDepartmentRecordView  /></MapProvider>}/>
    </Routes>
  )
};
export default GpsOfDepartmentRouter

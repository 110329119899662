import React, {useEffect, useState} from "react";
import {Business, Groups, LocationCitySharp, Person, PhoneAndroid, ScreenLockPortrait} from "@mui/icons-material";
import {useTranslation} from "react-i18next";
import CompanyLineChart from "@/pages/vendor/Dashboard/components/CompanyLineChart";
import AnalysisChart from "@/pages/vendor/Dashboard/components/SubscriptionAnalysisPie";
import GenericService from "@/services/core/GenericService";
import {VendorEndPoint} from "@/services/vendor/endpoints";
import Grid from "@mui/material/Grid2";
import SummaryCard from "@/pages/vendor/Dashboard/components/SummaryCard";
import DistributionMap from "@/pages/vendor/Dashboard/components/DistributionMap";
import UserTendencyChart from "@/pages/vendor/Dashboard/components/UserTendencyChart";
import EmployeeTendencyChart from "@/pages/vendor/Dashboard/components/EmployeeTendencyChart";
import DistributionChart from "@/pages/vendor/Dashboard/components/DistributionChart";


const Dashboard = () => {
  const {t} = useTranslation("vendor")
  const currentYear = new Date().getFullYear();
  const [summary, setSummary] = useState({
    companies: 0,
    paid_companies: 0,
    devices: 0,
    users: 0,
    employees: 0,
    apps: 0,
  });

  useEffect(() => {

    GenericService.action(VendorEndPoint.DASHBOARD, "summary").then((response) => {
      setSummary(response.data);
    })
  }, [])

  return (
    <Grid container spacing={2}>
      <Grid size={12} container columnSpacing={2}>
        <Grid size={2}>
          <SummaryCard
            title={t("dashboard.companies")}
            summary={summary.companies}
            icon={<LocationCitySharp color={"primary"} fontSize={"large"}/>}
          />
        </Grid>
        <Grid size={2}>
          <SummaryCard
            title={t("dashboard.paidCompanies")}
            summary={summary.paid_companies}
            icon={<Business color={"primary"} fontSize={"large"}/>}
          />
        </Grid>
        <Grid size={2}>
          <SummaryCard
            title={t("dashboard.devices")}
            summary={summary.devices}
            icon={<ScreenLockPortrait color={"primary"} fontSize={"large"}/>}
          />
        </Grid>
        <Grid size={2}>
          <SummaryCard
            title={t("dashboard.users")}
            summary={summary.users}
            icon={<Groups color={"primary"} fontSize={"large"}/>}
          />
        </Grid>
        <Grid size={2}>
          <SummaryCard
            title={t("dashboard.apps")}
            summary={summary.apps}
            icon={<PhoneAndroid color={"primary"} fontSize={"large"}/>}
          />
        </Grid>
        <Grid size={2}>
          <SummaryCard
            title={t("dashboard.employees")}
            summary={summary.employees}
            icon={<Person color={"primary"} fontSize={"large"}/>}
          />
        </Grid>
        <Grid size={4}>
          <AnalysisChart/>
        </Grid>
        <Grid size={8}>
          <CompanyLineChart />
        </Grid>
        <Grid size={6}>
          <DistributionMap />
        </Grid>
        <Grid size={6}>
          <DistributionChart />
        </Grid>
        <Grid size={6}>
          <UserTendencyChart />
        </Grid>
        <Grid size={6}>
          <EmployeeTendencyChart />
        </Grid>
      </Grid>
    </Grid>
  )
}
export default Dashboard

import GenericService from "@/services/core/GenericService";
import {RalvieEndPoint} from "@/services/ralvie/endpoints";

const getTimesheet = async (params: Record<string, any>) => {
  const response = await GenericService.post(RalvieEndPoint.REPORT_TIMESHEET_URI, params)
  return response
}

const ReportService = {
  getTimesheet
}
export default ReportService

const API_VER = 'v2'
const RESOURCE_TAG = 'api'
export const TeamEP = {
  EMPLOYEE_URI: `/personnel/${RESOURCE_TAG}/${API_VER}/employees/`,
  RESIGN_URI: `/personnel/${RESOURCE_TAG}/${API_VER}/resigns/`,
  WORKFLOW_ROLE_URI: `/workflow/${RESOURCE_TAG}/${API_VER}/roles/`,
  WORKFLOW_FLOW_URI: `/workflow/${RESOURCE_TAG}/${API_VER}/flows/`,
  WORKFLOW_NODE_URI: `/workflow/${RESOURCE_TAG}/${API_VER}/nodes/`,
  CONTENT_TYPE_URI: `/workflow/${RESOURCE_TAG}/${API_VER}/contentTypes/`,
}

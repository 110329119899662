import {Route, Routes} from "react-router-dom";
import React from "react";
import NodeRecordView from "./RecordView";
import NodeListView from "./ListView";


const NodeRouter = () => {
  return (
    <Routes>
      <Route index element={<NodeListView/>}/>
      <Route path={"add/"} element={<NodeRecordView/>}/>
      <Route path={"edit/"} element={<NodeRecordView/>}/>
    </Routes>
  )
};
export default NodeRouter

import {useTranslation} from "react-i18next";
import {ObjectFieldTemplateProps} from "@rjsf/utils";
import Grid from "@mui/material/Grid2";
import {Typography} from "@mui/material";


const TimePeriodRecordViewLayout = (props: ObjectFieldTemplateProps) => {
  const {t} = useTranslation("acc")
  const fields: Record<string, any> = {}
  props.properties?.forEach((element: any) => {
    fields[element.name] = element.content
  })

  return (
    <Grid container spacing={2}>
      <Grid size={4}>{fields.area}</Grid>
      <Grid size={8} />
      <Grid size={4}>{fields.timezone_no}</Grid>
      <Grid size={4}>{fields.timezone_name}</Grid>
      <Grid size={4} />
      <Grid size={12}>
        <Typography>Period Setup</Typography>
      </Grid>
      <Grid size={2}>
        {fields.sun_on}</Grid>
      <Grid size={3}>{fields.sun_start}</Grid>
      <Grid size={3}>{fields.sun_end}</Grid>
      <Grid size={4}/>
      <Grid size={2}>
        {fields.mon_on}</Grid>
      <Grid size={3}>{fields.mon_start}</Grid>
      <Grid size={3}>{fields.mon_end}</Grid>
      <Grid size={4}/>
      <Grid size={2}>
        {fields.tue_on}</Grid>
      <Grid size={3}>{fields.tue_start}</Grid>
      <Grid size={3}>{fields.tue_end}</Grid>
      <Grid size={4}/>
      <Grid size={2}>
        {fields.wed_on}</Grid>
      <Grid size={3}>{fields.wed_start}</Grid>
      <Grid size={3}>{fields.wed_end}</Grid>
      <Grid size={4}/>
      <Grid size={2}>
        {fields.thu_on}</Grid>
      <Grid size={3}>{fields.thu_start}</Grid>
      <Grid size={3}>{fields.thu_end}</Grid>
      <Grid size={4}/>
      <Grid size={2}>
        {fields.fri_on}</Grid>
      <Grid size={3}>{fields.fri_start}</Grid>
      <Grid size={3}>{fields.fri_end}</Grid>
      <Grid size={4}/>
      <Grid size={2}>
        {fields.sat_on}</Grid>
      <Grid size={3}>{fields.sat_start}</Grid>
      <Grid size={3}>{fields.sat_end}</Grid>
      <Grid size={4}/>
    </Grid>
  )
}
export default TimePeriodRecordViewLayout

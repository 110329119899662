import React, {useContext, useEffect, useMemo, useState} from "react";
import {PaletteMode, ThemeProvider} from "@mui/material";
import theme, {customTheme} from "@/theme";
import createCache from "@emotion/cache";
import {prefixer} from "stylis";
import stylisRTLPlugin from "stylis-plugin-rtl";
import {CacheProvider} from "@emotion/react";
import {useRegionContext} from "@/provider/RegionProvider";
import i18n from "@/i18n";

type ProviderProps = {
  children?: React.ReactNode
} & Record<string, any>

type MuiThemeContextProps = {
  direction: string;
  setDirection?: any;
  isRTL: boolean;
} & Record<string, any>

const MuiThemeContext = React.createContext<MuiThemeContextProps>({
  direction: "ltr",
  isRTL: false,
});

export const useMuiThemeContext = () => {
  const context = useContext(MuiThemeContext)
  if (!context) {
    throw new Error('The snackbar provider not found!')
  }
  return context
}

const MuiThemeProvider: React.FC<ProviderProps> = (props) => {
  const {region} = useRegionContext()
  const currentLanguage = localStorage.getItem("i18nextLng");
  const defaultDirection = currentLanguage === "ar"? "rtl": "ltr"
  const [direction, setDirection] = useState<string>(defaultDirection)
  const [themeMode, setThemeMode] = useState<PaletteMode>("light")

  const changeLanguage = async (lng: string) => {
    localStorage.setItem("i18nextLng", lng);
    await i18n.changeLanguage(lng)
    setDirection(lng === "ar" ? "rtl" : "ltr")
  }

  const cacheRtl = useMemo(() => createCache({
    key: `mui-${direction}`,
    stylisPlugins: direction === "rtl" ? [prefixer, stylisRTLPlugin] : []
  }), [direction])
  const isRTL = direction === "rtl"

  useEffect(() => {
    document.dir = direction
  }, [direction])

  return (
    <MuiThemeContext.Provider value={{
      direction,
      setDirection,
      isRTL,
      themeMode,
      setThemeMode,
      changeLanguage
    }}>
      <CacheProvider value={cacheRtl}>
        <ThemeProvider theme={{
          ...customTheme(themeMode, region),
          direction
        }}>
          {props.children}
        </ThemeProvider>
      </CacheProvider>
    </MuiThemeContext.Provider>
  )
}
export default MuiThemeProvider

import React, {ForwardedRef, useImperativeHandle, useRef} from "react";
import {useTranslation} from "react-i18next";
import { RJSFSchema, UiSchema } from "@rjsf/utils";
import LayoutForm from "@/components/rjsf/Form";
import SnackbarUtil from "@/utils/SnackbarUtil";
import GenericService from "@/services/core/GenericService";
import GPSService from "@/services/gps/GPSService";


const PushPublicNotice = (props: Record<any, any>, ref: ForwardedRef<any>) => {
  const { t } = useTranslation("gps");
  const formRef = useRef<any>()
  const formSchema: RJSFSchema = {
    type: "object",

    properties: {
      subject: {
        type: "string",
        format: "text",
        title: t("announcement.subject"),
        maxLength: 100
      },
      content: {
        type: "string",
        format: "text",
        title: t("announcement.content"),
      },

    },
    required: ["subject", "content"]
  }

  const uiSchema: UiSchema = {
    "ui:defaultXsWidth": 12,
    subject: {
      "ui:widget": "textarea",
      "ui:options": {
        rows: 4
      }
    },
    content: {
      "ui:widget": "textarea",
      "ui:options": {
        rows: 6
      }
    }
  }

  const onPushPublicNotice = async () => {
   const isValid = formRef.current?.validateForm()
    if (isValid) {
      const data = formRef.current?.state.formData
      const response = await GPSService.pushPublicNotice({...data})
      if (response.isSuccess) {
        SnackbarUtil.success(t("common.successful"))
        return true
      } else {
        console.log(response)
      }
    }
  }

  useImperativeHandle(ref, () => ({
    onConfirm: onPushPublicNotice
  }))

  return (
    <LayoutForm
      ref={formRef}
      schema={formSchema}
      uiSchema={uiSchema}
    />
  )
}
export default React.forwardRef(PushPublicNotice)

import { RJSFSchema, UiSchema } from "@rjsf/utils";
import { useTranslation } from "react-i18next";
import validator from "@rjsf/validator-ajv8";
import LayoutForm from "@/components/rjsf/Form";
import React, {
  ForwardedRef,
  useImperativeHandle,
  useRef,
} from "react";
import SnackbarUtil from "@/utils/SnackbarUtil";
import EmployeeService from "@/services/psnl/EmployeeService";

const ReloadFromDevice = (props: Record<string, any>, ref: ForwardedRef<any>) => {
  const { t } = useTranslation("per");
  const formRef = useRef<any>();
  const formSchema: RJSFSchema = {
    type: "object",
    required: [
      "device_all",
    ],
    properties: {
      device_all: {
        type: "number",
        title: t("employee.reload.device"),
        oneOf: [
          { const: 1, title: t("employee.reload.all") },
          { const: 0, title: t("employee.reload.specific") },
        ],
        default: 1
      },
      device_sn: {
        type: "string",
        title: t("employee.reload.deviceSN"),
      },
    },
  };

  const uiSchema: UiSchema = {
    "ui:defaultXsWidth": 12,
    "ui:submitButtonOptions": {
      norender: true,
    },
    device_sn: {
      "ui:widget": "textarea",
    },
  };

  const onConfirm = async () => {
    const isValid = formRef.current?.validateForm();
    if (isValid) {
      const selectedIds = props.selectedIds;
      const data = formRef.current?.state.formData;
      const response = await EmployeeService.ReloadFromDevice({
        ids: selectedIds,
        ...data,
      });
      if (response.isSuccess) {
        SnackbarUtil.success(t("common.successful", {ns: "common"}));
        return true;
      } else {
        console.log(response);
      }
    }
  };

  useImperativeHandle(ref, () => ({
    onConfirm,
  }));

  return (
    <LayoutForm
      ref={formRef}
      schema={formSchema}
      uiSchema={uiSchema}
      validator={validator}
    />
  );
};
export default React.forwardRef(ReloadFromDevice);

import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent, DialogContentText,
  DialogProps,
  DialogTitle,
  Stack,
  ThemeProvider
} from "@mui/material";
import theme, {customTheme} from "@/theme";
import {I18nextProvider, useTranslation} from "react-i18next";
import i18n from "@/i18n";
import RegionProvider from "@/provider/RegionProvider";
import MuiThemeProvider from "@/provider/MuiThemeProvider";

export type ConfirmDialogProps = {
  icon?: React.ReactNode;
  dialog: DialogProps;
  title?: React.ReactNode;
  description?: React.ReactNode;
  content?: React.ReactNode;
  onConfirm: () => Promise<boolean>;
  onCancel: () => void;
} & Record<string, any>

const ConfirmDialog: React.FC<ConfirmDialogProps> = (props) => {
  const {t} = useTranslation("common")
  const {dialog, title, description, content, onConfirm, onCancel} = props
  return (
    <RegionProvider>
      <MuiThemeProvider direction={props.direction} themeMode={props.themeMode}>
        <I18nextProvider i18n={i18n}>
          <Dialog sx={{minWidth: 192, minHeight: 128}} {...dialog}>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
              {description && <DialogContentText>{description}</DialogContentText>}
              {content}
            </DialogContent>
            <DialogActions>
              <Stack direction={"row"} spacing={1}>
                <Button variant={"contained"} onClick={async () => {
                  const isFinished = await onConfirm()
                  if (isFinished) {
                    onCancel()
                  }
                }}>{t('common.button.confirm')}</Button>
                <Button onClick={onCancel}>{t('common.button.cancel')}</Button>
              </Stack>
            </DialogActions>
          </Dialog>
        </I18nextProvider>
      </MuiThemeProvider>
    </RegionProvider>
  )
}
ConfirmDialog.defaultProps = {
  title: " "
}
export default ConfirmDialog

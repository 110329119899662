import {Button} from "@mui/material";
import {Stack} from "@mui/system";
import React from "react";
import {useNavigate} from "react-router-dom";


const RecordActions = (props: Record<any, any>) => {
  const navigate = useNavigate()
  const {actions}  = props
  return (
    <Stack direction={"row"} spacing={1}>
      {actions?.map((action: any, index: number) => {
        return (<Button key={`action${index}`} variant={action.variant || "contained"} onClick={() => {
          if (action.callback) {
            action.callback()
          } else {
            navigate(action.url)
          }
        }}>
          {action.title}
        </Button>)
      })}
    </Stack>
  )
}
export default RecordActions

import {RJSFSchema, UiSchema} from "@rjsf/utils";
import {useTranslation} from "react-i18next";
import validator from "@rjsf/validator-ajv8";
import LayoutForm from "@/components/rjsf/Form";
import React, {ForwardedRef, useImperativeHandle, useRef, useState,} from "react";
import SnackbarUtil from "@/utils/SnackbarUtil";
import {datetime2string} from "@/utils/datetime";
import CalculationdService from "@/services/att/CalculationService";

const CalculateAction = (props: Record<string, any>, ref: ForwardedRef<any>) => {
  const {t} = useTranslation("report");
  const formRef = useRef<any>();
  const [formData, setFormData] = useState<Record<string, any>>({
    start_date: datetime2string(new Date(), "YYYY-MM-DD"),
    end_date: datetime2string(new Date(), "YYYY-MM-DD"),
  });

  const formSchema: RJSFSchema = {
    type: "object",
    required: [
      "start_date",
      "end_date",
    ],
    properties: {
      "start_date": {
        "type": "string",
        "format": "date",
        "title": t("report.startDate"),
      },
      "end_date": {
        "type": "string",
        "format": "date",
        "title": t("report.endDate"),
      },
    },
  };

  const uiSchema: UiSchema = {
    "ui:defaultXsWidth": 12,
  };

  const onConfirm = async () => {
    const isValid = formRef.current?.validateForm();
    if (isValid) {
      const selectedIds = props.selectedIds;
      const data = formRef.current?.state.formData;
      const response = await CalculationdService.calculate({
        employees: selectedIds,
        ...data,
      });
      if (response.isSuccess) {
        SnackbarUtil.success(t("common.message.successful", {ns: "common"}));
        return true;
      } else {
        console.log(response);
      }
    }
  };

  useImperativeHandle(ref, () => ({
    onConfirm,
  }));

  return (
    <LayoutForm
      ref={formRef}
      schema={formSchema}
      uiSchema={uiSchema}
      validator={validator}
      formData={formData}
    />
  );
};
export default React.forwardRef(CalculateAction);

import React from "react";
import {AppBar, Box, Stack, Toolbar} from "@mui/material";
import AccountProfile from "@/layout/Header/components/AccountProfile";
import Apps from "@/layout/Header/components/Apps";
import CompanyProfile from "@/layout/Header/components/CompanyProfile";
import Subscription from "@/layout/Header/components/Subscription";
import {useAppContext} from "@/provider/AppProvider";


const Header: React.FC = () => {
  const {currentRole} = useAppContext()
  return (
    <AppBar color="mainColor" position={"static"} style={{height: 64, backgroundColor: "#fff"}}>
      <Toolbar disableGutters={true}>
        <Apps />
        <Stack
          direction={"row"}
          sx={{
            alignItems: "center",
            margin: "0 8px",
            height: "100%",
          }}
        >
          {currentRole === "tenement" && <Subscription />}
          <CompanyProfile />
          <AccountProfile/>
        </Stack>
      </Toolbar>
    </AppBar>
  )
}
export default Header

import {useTranslation} from "react-i18next";
import {RJSFSchema, UiSchema} from "@rjsf/utils";
import RecordView from "@/components/views/RecordView";
import urls from "@/urls";
import {VendorEndPoint} from "@/services/vendor/endpoints";


const FeatureRecordView = () => {
  const {t} = useTranslation("vendor");
  const {t: menu} = useTranslation("menu");
  const formSchema: RJSFSchema = {
    "type": "object",
    "properties": {
      "name": {
        "type": "string",
        "title": t("feature.name"),
      },
      "type": {
        "type": "integer",
        "title": t("feature.type"),
        oneOf: [
          {const: 0, title: t("featureType.server")},
          {const: 1, title: t("featureType.customization")},
          {const: 2, title: t("featureType.prototype")},
        ],
        default: 0
      },
      display_status: {
        type: "boolean",
        title: t("feature.display"),
        default: true
      },
      effective_status: {
        type: "boolean",
        title: t("feature.effective"),
        default: true
      },
      country: {
        type: "string",
        title: t("feature.country"),
        oneOf: [
          {const: "AE", title: t("country.UAE")},
          {const: "US", title: t("country.US")},
          {const: "NG", title: t("country.NG")},
          {const: "IN", title: t("country.IN")},
        ],
        default: "AE"
      },
      "price": {
        "type": "number",
        "title": t("feature.price"),
      },
      description: {
        "type": ["string", "null"],
        "title": t("feature.description"),
      }
    },
    required: ["name", "type", "country", "price"]
  }

  const uiSchema: UiSchema = {
    'ui:defaultXsWidth': 4,
    "ui:submitButtonOptions": {"norender": true},
    "display_status": {
      "ui:widget": "switch"
    },
    "effective_status": {
      "ui:widget": "switch"
    },
    "description": {
      "ui:widget": "textarea",
      "ui:emptyValue": ""
    }
  };
  return (
    <RecordView
      breadcrumbs={[
        {title: menu("menu.subscription"), href: urls.subscription.root},
        {title: menu("menu.subscription.feature"), href: urls.subscription.feature},
      ]}
      formSchema={formSchema}
      uiSchema={uiSchema}
      uri={VendorEndPoint.FEATURE_URI}
    />
  )
}
export default FeatureRecordView

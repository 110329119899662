import { TabPanel } from "@mui/lab";
import MultiSelectTreeView from "./MultiSelectTreeView";
import { useEffect, useState } from "react";


const PermissionView = (props: any) => {
  const { itemKey, onStatusChange, initSelecteds } = props

  const nodes: any[] = [
    {
      "id": "att.report_permission",
      "label": "report_permission",
      "children": [
        {
          "codename": "view_daily_attendance_summary_report",
          "label": "view_daily_attendance_summary_report"
        },
        {
          "codename": "view_daily_basic_report",
          "label": "view_daily_basic_report"
        },
        {
          "codename": "view_early_leave_report",
          "label": "view_early_leave_report"
        },
        {
          "codename": "view_dept_wise_report",
          "label": "view_dept_wise_report"
        },
        {
          "codename": "view_daily_status_report",
          "label": "view_daily_status_report"
        },
        {
          "codename": "view_monthly_basic_status_report",
          "label": "view_monthly_basic_status_report"
        },
        {
          "codename": "view_monthly_status_summary_report",
          "label": "view_monthly_status_summary_report"
        },
        {
          "codename": "view_time_card_report",
          "label": "view_time_card_report"
        },
        {
          "codename": "view_mobiletransaction_report",
          "label": "view_mobiletransaction_report"
        },
        {
          "codename": "view_monthly_ot_summary_report",
          "label": "view_monthly_ot_summary_report"
        },
        {
          "codename": "view_monthly_duration_summary_report",
          "label": "view_monthly_duration_summary_report"
        },
        {
          "codename": "view_monthly_detailed_summary_report",
          "label": "view_monthly_detailed_summary_report"
        },
        {
          "codename": "view_monthly_att_sheet_summary_report",
          "label": "view_monthly_att_sheet_summary_report"
        },
        {
          "codename": "view_yearly_summary_report",
          "label": "view_yearly_summary_report"
        },
        {
          "codename": "view_abnormal_report",
          "label": "view_abnormal_report"
        },
        {
          "codename": "view_training_report",
          "label": "view_training_report"
        },
        {
          "codename": "view_daily_attendance_status_report",
          "label": "view_daily_attendance_status_report"
        },
        {
          "codename": "view_all_reports",
          "label": "view_all_reports"
        },
        {
          "codename": "view_calculation_view",
          "label": "view_calculation_view"
        },
        {
          "codename": "view_overtime_report",
          "label": "view_overtime_report"
        },
        {
          "codename": "view_body_temperature_report",
          "label": "view_body_temperature_report"
        },
        {
          "codename": "view_verification_photo_report",
          "label": "view_verification_photo_report"
        },
        {
          "codename": "view_face_mask_report",
          "label": "view_face_mask_report"
        },
        {
          "codename": "view_absent_report",
          "label": "view_absent_report"
        },
        {
          "codename": "view_mul_transaction_report",
          "label": "view_mul_transaction_report"
        },
        {
          "codename": "view_mul_break_time_report",
          "label": "view_mul_break_time_report"
        },
        {
          "codename": "view_emp_summary_report",
          "label": "view_emp_summary_report"
        },
        {
          "codename": "view_emp_birthday_report",
          "label": "view_emp_birthday_report"
        },
        {
          "codename": "view_leave_summary_report",
          "label": "view_leave_summary_report"
        },
        {
          "codename": "view_late_report",
          "label": "view_late_report"
        },
        {
          "codename": "view_exception_report",
          "label": "view_exception_report"
        },
        {
          "codename": "view_total_time_card_report",
          "label": "view_total_time_card_report"
        },
        {
          "codename": "view_scheduled_log_report",
          "label": "view_scheduled_log_report"
        },
        {
          "codename": "view_first_in_last_out_report",
          "label": "view_first_in_last_out_report"
        },
        {
          "codename": "view_first_last_report",
          "label": "view_first_last_report"
        },
        {
          "codename": "view_staff_EmployeeCalendar",
          "label": "view_staff_EmployeeCalendar"
        },
        {
          "codename": "view_staff_breaktime",
          "label": "view_staff_breaktime"
        },
        {
          "codename": "view_staff_multipletransaction",
          "label": "view_staff_multipletransaction"
        },
        {
          "codename": "view_staff_summary",
          "label": "view_staff_summary"
        },
        {
          "codename": "view_staff_attdetail",
          "label": "view_staff_attdetail"
        },
        {
          "codename": "view_staff_scheduledlog",
          "label": "view_staff_scheduledlog"
        },
        {
          "codename": "view_dept_summary_report",
          "label": "view_dept_summary_report"
        },
        {
          "codename": "view_scheduleTrack_report",
          "label": "view_scheduleTrack_report"
        },
        {
          "codename": "view_unscheduleTrack_report",
          "label": "view_unscheduleTrack_report"
        },
        {
          "codename": "view_total_temp_detection_report",
          "label": "view_total_temp_detection_report"
        },
        {
          "codename": "view_payroll_report",
          "label": "view_payroll_report"
        },
        {
          "codename": "view_employeecalendar_report",
          "label": "view_employeecalendar_report"
        },
        {
          "codename": "view_unregistered_user_report",
          "label": "view_unregistered_user_report"
        },
        {
          "codename": "view_half_day_time_report",
          "label": "view_half_day_time_report"
        },
        {
          "codename": "view_adminleavedetails_report",
          "label": "view_adminleavedetails_report"
        },
        {
          "codename": "view_daily_attendance_report",
          "label": "view_daily_attendance_report"
        },
        {
          "codename": "view_employeeschedule_report",
          "label": "view_employeeschedule_report"
        },
        {
          "codename": "view_transaction_report",
          "label": "view_transaction_report"
        },
        {
          "codename": "view_daily_details_report",
          "label": "view_daily_details_report"
        },
        {
          "codename": "view_daily_summary_report",
          "label": "view_daily_summary_report"
        }
      ]
    }
  ]

  const [treeNodes, setTreeNodes] = useState<any[]>([]);
  const [allIds, setaAllIds] = useState<any[]>([]);
  useEffect(() => {
    const newNodes: any = []
    const newAllIds: any = []
    if ( Object.keys(itemKey).length > 0) {
      nodes.forEach((node) => {
        const newChildren: any = []
        if (itemKey[node.id]) {
          node.children.forEach((child: any) => {
            if (itemKey[node.id][child.codename]) {
              newChildren.push({ ...child, id: itemKey[node.id][child.codename].toString() })
              newAllIds.push(itemKey[node.id][child.codename].toString())
            } else {
              console.log('error key', node.id, child.codename)
            }
          })
          node.children = newChildren
        } else {
          console.log('error key:', node.id)
        }
        newNodes.push(node)
      })
    }
    setTreeNodes(newNodes)
    setaAllIds(newAllIds)
  }, [itemKey])

  return (
    <TabPanel value={"report"}>
      <MultiSelectTreeView nodes={treeNodes}
      selected={initSelecteds}
      onSelectedChange={ (ids: any) => {
        const unSelected = allIds.filter(each => ids.indexOf(each) === -1)
        onStatusChange && onStatusChange(ids, unSelected)
      }}
      />
    </TabPanel>
  )
}
export default PermissionView

import React, {ForwardedRef, useEffect, useImperativeHandle, useState} from "react";
import {
  Autocomplete,
  Badge,
  Button,
  Input,
  InputAdornment,
  ListItem,
  ListItemIcon,
  ListItemText,
  Popper,
  styled
} from "@mui/material";
import {Close, Done, Search} from "@mui/icons-material";
import GenericService from "@/services/core/GenericService";
import {AttEndPoint} from "@/services/att/endpoints";
import {useTranslation} from "react-i18next";

const StyledInput = styled(Input)(({theme}) => ({
  padding: 10,
  width: '100%',
  borderBottom: `1px solid ${
    theme.palette?.mode === 'light' ? '#eaecef' : '#30363d'
  }`
}))

const StyledPopper = styled(Popper)(({theme}) => ({
  border: `1px solid ${theme.palette.mode === 'light' ? '#e1e4e8' : '#30363d'}`,
  boxShadow: `0 8px 24px ${
    theme.palette.mode === 'light' ? 'rgba(149, 157, 165, 0.2)' : 'rgb(1, 4, 9)'
  }`,
  borderRadius: 6,
  width: 300,
  zIndex: theme.zIndex.modal,
  fontSize: 13,
  color: theme.palette.mode === 'light' ? '#24292e' : '#c9d1d9',
  backgroundColor: theme.palette.mode === 'light' ? '#fff' : '#1c2128',
}));

const TimetableSelection = (props: Record<string, any>, ref: ForwardedRef<any>) => {
  const {t} = useTranslation("att")
  const [anchorEl, setAnchorEl] = useState<any>()
  const [options, setOptions] = useState<any[]>([])
  const [selectedOptions, setSelectedOptions] = useState<Record<string, any>[]>([])

  const getTimetables = async () => {
    const response = await GenericService.list(
      AttEndPoint.TIMETABLE_URI,
      {page: 1, page_size: 100})
    return response.data.data
  }

  useImperativeHandle(ref, () => ({
    selectedOptions
  }))

  useEffect(() => {
    getTimetables().then(data => {
      setOptions(data)
    })
  }, [])

  return (
    <>
      <Badge badgeContent={selectedOptions.length} color={"primary"}>
        <Button
          variant={"contained"}
          onClick={(event) => {
            if (anchorEl) {
              setAnchorEl(null)
            } else {
              setAnchorEl(event.currentTarget)
            }
          }}
        >{t("temporarySchedule.selectTimetable")}</Button>
      </Badge>
      <StyledPopper
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        placement="bottom-start"
      >
        <Autocomplete
          open={Boolean(anchorEl)}
          multiple
          value={selectedOptions}
          options={options}
          getOptionKey={(option) => option.id}
          getOptionLabel={(option) => option.name}
          isOptionEqualToValue={(option, value) => {
            return option.id === value.id
          }}
          onBlur={() => {
            setAnchorEl(null)
          }}
          renderTags={() => null}
          renderInput={(params) => {
            const {value, ...otherInputProps} = params.inputProps
            return (
              <StyledInput
                ref={params.InputProps.ref}
                inputProps={otherInputProps}
                autoFocus
                startAdornment={<InputAdornment position={"start"}><Search/></InputAdornment>}
              />
            )
          }}
          renderOption={(optionProps, option, {selected}) => {
            return (
              <ListItem
                {...optionProps}
                key={option.id}
              >
                <ListItemIcon sx={{minWidth: "32px"}}>
                  {selected && <Done/>}
                </ListItemIcon>
                <ListItemText>{option.display_name}</ListItemText>
                <ListItemIcon
                  sx={{minWidth: "32px"}}
                  onClick={(event) => {
                    event.stopPropagation()
                    setSelectedOptions(selectedOptions.filter((selectedOption: any) => selectedOption.id !== option.id))
                    setAnchorEl(null)
                  }}
                >
                  {selected && <Close/>}
                </ListItemIcon>
              </ListItem>
            )
          }}
          onChange={(event, newValue, reason, details) => {
            if (reason === "selectOption") {
              setSelectedOptions(newValue)
            }
            setAnchorEl(null)
          }}
        />
      </StyledPopper>
    </>
  )
}
export default React.forwardRef(TimetableSelection)

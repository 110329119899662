import {TreeView} from "@mui/lab";
import {ArrowDropDown, ArrowRight} from "@mui/icons-material";
import React from "react";
import CustomTreeItem from "@/components/rjsf/widgets/AutocompleteTreeWidget/components/CustomTreeItem";

type CustomTreeViewProps = Record<string, any>
const CustomTreeView = (props: CustomTreeViewProps) => {
  const {sx, selections, searchValue, onSelected, multiple, disabled} = props
  const selected = props.selected?.map((s: any) => s.id.toString())
  const treeData: Record<string, any> = {}
  selections?.forEach((s: any) => {
    treeData[s.id.toString()] = {...s, children: []}
  })
  const treeRoot: string[] = []
  selections?.forEach((s: any) => {
    const nodeId = s.id.toString()
    const parent = s.parent?.toString()
    if (treeData[parent]) {
      treeData[parent].children.push(nodeId)
    } else {
      treeRoot.push(nodeId)
    }
  })
  return (
    <TreeView
      sx={sx}
      multiSelect={multiple}
      defaultCollapseIcon={<ArrowDropDown/>}
      defaultExpandIcon={<ArrowRight/>}
      onNodeSelect={async (event: any, value: string) => {
        console.log("onNodeSelect >>>", value)
        if (multiple) {
          return
        }
        await onSelected([value])
      }}
      selected={selected}
    >
      {
        treeRoot?.map((nodeId: any) => {
          return (
            <CustomTreeItem
              key={`node${nodeId}`}
              nodeId={nodeId}
              treeData={treeData}
              multiple={multiple}
              disabled={disabled}
              selected={selected}
              onSelected={onSelected}
              searchValue={searchValue}
            />
          )
        })
      }
    </TreeView>
  )
}
export default CustomTreeView

import { useTranslation } from "react-i18next";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import urls from "@/urls";
import { ReportEndPoint } from "@/services/report/endpoints";
import ReportView from "@/pages/Report/ReportLibrary/components/ReportView";
import { useReportEmployee } from "@/hooks/employee";

const UnScheduleTrackReport = () => {
  const { t } = useTranslation("gps");
  const displayName = (value: any) => value?.display_name
  const employeeFields = useReportEmployee()
  const columns: GridColDef[] = [
    ...employeeFields,
    { field: "date", headerName: t("schedulePlanner.date")},
    { field: "schedule", valueFormatter: displayName, headerName: t("report.schedule.name")},
    { field: "client", valueFormatter: displayName, headerName: t("report.client.name")},
    { field: "checkin_latitude", headerName: t("report.checkin.latitude")},
    { field: "checkin_longitude", headerName: t("report.checkin.longitude")},
    { field: "checkout_latitude", headerName: t("report.checkout.latitude")},
    { field: "checkout_longitude", headerName: t("report.checkout.longitude")},
    { field: "checkin_address", headerName: t("report.checkin.address")},
    { field: "checkout_address", headerName: t("report.checkout.address")},
    { field: "summary", headerName: t("report.summary")},
  ];

  return (
    <ReportView
      title={t("menu.gps.unscheduleReport", {ns: "menu"})}
      breadcrumbs={[]}
      columns={columns}
      uri={ReportEndPoint.UNSCHEDULE_TRACK_URI}
      url={urls.gps.unscheduleReport}
      hideColumns={['first_name', 'last_name', 'department_code', 'position_code', 'position_name']}
    />
  );
};
export default UnScheduleTrackReport;

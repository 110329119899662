import Grid from "@mui/material/Grid2";
import FilterPanel from "@/pages/Report/ReportLibrary/components/ReportView/components/FilterPanel";
import ViewPanel from "@/pages/Report/ReportLibrary/components/ReportView/components/ViewPanel";
import RefreshAction from "@/components/views/components/RefreshAction";
import { useReportViewContext } from "./ReportViewProvider";
import { Stack } from "@mui/material";


const HeaderToolBar = () => {
  const { refreshGridData } = useReportViewContext();

  return (
    <Stack direction={"row"} gap={1}>
      <FilterPanel />
      <Grid flex={1} />
      <ViewPanel />
      <RefreshAction onRefresh={refreshGridData} />
    </Stack>
  )
}
export default HeaderToolBar

import ListView from "@/components/views/ListView";
import {GridColDef} from "@mui/x-data-grid";
import AppUrls from "@/urls";
import {useTranslation} from "react-i18next";
import urls from "@/urls";
import TransferWithinAStationIcon from '@mui/icons-material/TransferWithinAStation';
import DisableIcon from '@mui/icons-material/DisabledByDefault'
import { PsnlEP } from "@/services/psnl/endpoints";
import ResignService from "@/services/psnl/ResignService.ts";
import SnackbarUtil from "@/utils/SnackbarUtil";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";


const ResignListView = () => {
  const {t} = useTranslation("per")
  const displayName = (value: any) => value.display_name
  const displayDisabAttendance = (value: any) => {
    if(value){
      return t("employee.disable")
    }
    else{
      return t("employee.enable")
    }
  }

  const columns: GridColDef[] = [
    {field: "emp_code", headerName: t("employee.code"), flex: 1},
    {field: "first_name", headerName: t("employee.firstName"), flex: 1},
    {field: "department", valueFormatter: displayName, headerName: t("employee.department"), flex: 1},
    {field: "position", valueFormatter: displayName, headerName: t("employee.position"), flex: 1},
    {field: "resign_date", headerName: t("resign.date"), flex: 1},
    {field: "resign_type", headerName: t("resign.type"), flex: 1,
      type: "singleSelect",
      valueOptions: [
        {value: 1, label: t("resignType.option.absence")},
        {value: 2, label: t("resignType.option.delay")},
        {value: 3, label: t("resignType.option.badConduct")},
        {value: 4, label: t("resignType.option.voluntary")},
        {value: 5, label: t("resignType.option.permanentResign")},
        {value: 6, label: t("resignType.option.suspension")},
      ],
    },
    {field: "report_generation_end_date", headerName: t("resign.endDateOfReportGeneration"), flex: 1},
    {field: "disableatt", valueFormatter: displayDisabAttendance, headerName: t("employee.attendance"), flex: 1,},
    {field: "reason", headerName: t("resign.reason"), flex: 1},
  ]

  const filters = [
    {key: 'employee__emp_code', label: t("employee.code"), type: 'string'},
    {key: 'employee__first_name', label: t("employee.firstName"), type: 'string'},
  ]

  const onRehire = async (selectedIds: string) => {
    const response = await ResignService.Rehire({objs: selectedIds})
    if(response.isSuccess){
      SnackbarUtil.success(t("common.message.successful", {ns: "common"}))
      return true
    }else {
      console.log(response)
    }
  }

  const onDisable = async (selectedIds: string) => {
    const response = await ResignService.DisableAttendance({employees: selectedIds})
    if(response.isSuccess){
      SnackbarUtil.success(t("common.message.successful", {ns: "common"}))
      return true
    }else {
      console.log(response)
    }
  }

  const actions = [
    {
      key: "rehire",
      title: t("resign.rehire"),
      icon: <TransferWithinAStationIcon/>,
      validateSelected: true,
      confirmation: t("resign.rehire.confirmation"),
      callback: onRehire,
      display: "dialog"
    },
    {
      id: "import", title: t("common.action.import", {ns: "common"}), icon: <FileUploadOutlinedIcon/>,
      display: "page", url: `${AppUrls.team.resign}/import`,
    },
    {
      key: "disableAttendance",
      title: t("resign.disableAttendance"),
      icon: <DisableIcon/>,
      validateSelected: true,
      confirmation: t("resign.disableAttendance.confirmation"),
      callback: onDisable,
      display: "dialog"
    },
  ]

  return (
    <ListView
      title={t("menu.team.resign", {ns: "menu"})}
      columns={columns}
      uri={PsnlEP.RESIGN_URI}
      url={AppUrls.team.resign}
      filters={filters}
      modelActions={actions}
    />
  )
}
export default ResignListView

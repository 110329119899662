import {Card, CardContent, CardHeader, FormControlLabel, Radio, RadioGroup} from "@mui/material";
import React, {useEffect} from "react";
import {useTranslation} from "react-i18next";
import ReactEcharts from "echarts-for-react";
import GenericService from "@/services/core/GenericService";
import {VendorEndPoint} from "@/services/vendor/endpoints";


const DistributionChart = () => {
  const {t} = useTranslation("vendor");
  const [data, setData] = React.useState([]);
  const [category, setCategory] = React.useState("COMPANY");
  const option = {
    title: {
      text: ''
    },
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow'
      }
    },
    grid: {
      top: '3%',
      left: '3%',
      right: '4%',
      bottom: '3%',
      containLabel: true
    },
    xAxis: {
      type: 'category',
      data: data.map((item: any) => item.country),
      axisLabel: {
        interval: 0,
        show: true
      },
      boundaryGap: true
    },
    yAxis: {
      type: 'value',
    },
    series: [
      {
        type: 'bar',
        data: data.map((item: any) => item.count),
        itemStyle: {
          color: '#4076e3'
        }
      }
    ]
  };
  useEffect(() => {
    GenericService.action(VendorEndPoint.DASHBOARD, "country_distribution", {category}).then((response) => {
      setData(response.data)
    })
  }, [category])
  return (
    <Card>
      <CardHeader
        title={t("dashboard.distributionChart")}
        action={
          <RadioGroup
            row
            value={category}
            onChange={(event, value) => setCategory(value)}
          >
            <FormControlLabel control={<Radio size={"small"}/>} label={"Device"} value={"DEVICE"}/>
            <FormControlLabel control={<Radio size={"small"}/>} label={"Company"} value={"COMPANY"}/>
          </RadioGroup>
        }
      />
      <CardContent>
        <ReactEcharts option={option}/>
      </CardContent>
    </Card>
  )
}
export default DistributionChart

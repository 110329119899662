import { RJSFSchema, UiSchema } from "@rjsf/utils";
import { useTranslation } from "react-i18next";
import validator from "@rjsf/validator-ajv8";
import LayoutForm from "@/components/rjsf/Form";
import React, {
  ForwardedRef,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import SnackbarUtil from "@/utils/SnackbarUtil";
import { datetime2string } from "@/utils/datetime";
import EmployeeService from "@/services/psnl/EmployeeService";

const ResignAction = (props: Record<string, any>, ref: ForwardedRef<any>) => {
  const { t } = useTranslation("per");
  const formRef = useRef<any>();
  const [formData, setFormData] = useState<Record<string, any>>({
    resign_date: datetime2string(new Date(), "YYYY-MM-DD"),
    resign_type: 1,
    report_generation_end_date: datetime2string(new Date(), "YYYY-MM-DD"),
    disableatt: true,
  });

  const formSchema: RJSFSchema = {
    type: "object",
    required: [
      "resign_date",
      "resign_type",
      "report_generation_end_date",
      "disableatt",
    ],
    properties: {
      resign_date: {
        type: "string",
        format: "date",
        title: t("employee.resignation.date"),
      },
      resign_type: {
        type: "number",
        title: t("employee.resign.type"),
        oneOf: [
          { const: 1, title: t("employee.resign.quit") },
          { const: 2, title: t("employee.resign.terminated") },
          { const: 3, title: t("employee.resign.resigned") },
          { const: 4, title: t("employee.resign.transfer") },
          { const: 5, title: t("employee.resign.retain") },
        ],
      },
      report_generation_end_date: {
        type: "string",
        format: "date",
        title: t("employee.report.generation.end.date"),
      },
      disableatt: {
        type: "boolean",
        title: t("employee.attendance"),
        oneOf: [
          { const: true, title: t("employee.disable") },
          { const: false, title: t("employee.enable") },
        ],
      },
      reason: {
        type: "string",
        title: t("employee.reason"),
      },
    },
  };

  const uiSchema: UiSchema = {
    "ui:defaultXsWidth": 12,
    "ui:submitButtonOptions": {
      norender: true,
    },
    disableatt: {
      "ui:widget": "select",
    },
    reason: {
      "ui:widget": "textarea",
    },
  };

  const onConfirm = async () => {
    const isValid = formRef.current?.validateForm();
    if (isValid) {
      const selectedIds = props.selectedIds;
      const data = formRef.current?.state.formData;
      const response = await EmployeeService.Resign({
        ids: selectedIds,
        ...data,
      });
      if (response.isSuccess) {
        SnackbarUtil.success(t("common.successful", {ns: "common"}));
        return true;
      } else {
        console.log(response);
      }
    }
  };

  useImperativeHandle(ref, () => ({
    onConfirm,
  }));

  return (
    <LayoutForm
      ref={formRef}
      schema={formSchema}
      uiSchema={uiSchema}
      validator={validator}
      formData={formData}
    />
  );
};
export default React.forwardRef(ResignAction);

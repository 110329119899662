import {useTranslation} from "react-i18next";
import {GridColDef} from "@mui/x-data-grid";
import ListView from "@/components/views/ListView";
import AppUrls from "@/urls";
import {GPSEndPoint} from "@/services/gps/endpoints";


const OperationLogListView = () => {
  const { t: t } = useTranslation("gps");
  const { t: tMenu } = useTranslation("menu");


  const columns: GridColDef[] = [
    {field: "user", headerName: t("operationLog.user"), flex: 1},
    {field: "client", headerName: t("operationLog.client"), flex: 1},
    {field: "action", headerName: t("operationLog.action"), flex: 1},
    {field: "action_time", headerName: t("operationLog.action_time"), flex: 1},
    {field: "request_status", headerName: t("operationLog.status"), flex: 1},
    {field: "describe", headerName: t("operationLog.describe"), flex: 1},
  ]

  const filters = [
    {key: 'user', label: t("operationLog.user"), type: 'string'},
    {key: 'client', label: t("operationLog.client"), type: 'string'},
    {key: 'action', label: t("operationLog.action"), type: 'string'},
    {key: 'action_time', label: t("operationLog.action_time"), type: 'string'},
    {key: 'request_status', label: t("operationLog.status"), type: 'string'},
    {key: 'describe', label: t("operationLog.describe"), type: 'string'},
  ]


  return (
    <ListView
      title={tMenu("menu.gps.operationLog")}
      breadcrumbs={[
        {title: tMenu("menu.gps"), href: AppUrls.gps.root},
      ]}
      columns={columns}
      uri={GPSEndPoint.OPERATION_LOG_URI}
      url={AppUrls.gps.operationLog}
      filters={filters}
      disableAdd={true}
      disableEdit={true}
    />
  )
}
export default OperationLogListView

import { TabPanel } from "@mui/lab";
import MultiSelectTreeView from "./MultiSelectTreeView";
import { useEffect, useState } from "react";


const PermissionView = (props: any) => {
  const { itemKey, onStatusChange, initSelecteds } = props

  const nodes: any[] = [
    {
      "id": "att.att_setting_permission",
      "label": "att_setting_permission",
      "children": [
        {
          "codename": "view_report_setting_page",
          "label": "view_report_setting_page"
        },
        {
          "codename": "view_att_rule_page",
          "label": "view_att_rule_page"
        }
      ]
    },
    {
      "id": "att.deptattrule",
      "label": "Department Rule",
      "children": [
        {
          "codename": "change_deptattrule",
          "label": "change_deptattrule"
        },
        {
          "codename": "view_deptattrule",
          "label": "view_deptattrule"
        },
        {
          "codename": "batch_add_department_rule_deptattrule",
          "label": "batch_add_department_rule_deptattrule"
        },
        {
          "codename": "delete_deptattrule",
          "label": "delete_deptattrule"
        }
      ]
    },
    {
      "id": "att.timeinterval",
      "label": "Timetable",
      "children": [
        {
          "codename": "delete_timeinterval",
          "label": "delete_timeinterval"
        },
        {
          "codename": "view_timeinterval",
          "label": "view_timeinterval"
        },
        {
          "codename": "add_flexible_timetable_timeinterval",
          "label": "add_flexible_timetable_timeinterval"
        },
        {
          "codename": "add_normal_timetable_timeinterval",
          "label": "add_normal_timetable_timeinterval"
        },
        {
          "codename": "change_timeinterval",
          "label": "change_timeinterval"
        }
      ]
    },
    {
      "id": "att.attshift",
      "label": "Shift",
      "children": [
        {
          "codename": "view_attshift",
          "label": "view_attshift"
        },
        {
          "codename": "delete_attshift",
          "label": "delete_attshift"
        },
        {
          "codename": "change_attshift",
          "label": "change_attshift"
        },
        {
          "codename": "add_attshift",
          "label": "add_attshift"
        }
      ]
    },
    {
      "id": "att.manuallog",
      "label": "Manual Log",
      "children": [
        {
          "codename": "manual_log_approve_manuallog",
          "label": "manual_log_approve_manuallog"
        },
        {
          "codename": "change_manuallog",
          "label": "change_manuallog"
        },
        {
          "codename": "delete_manuallog",
          "label": "delete_manuallog"
        },
        {
          "codename": "view_manuallog",
          "label": "view_manuallog"
        },
        {
          "codename": "add_manual_log_manuallog",
          "label": "add_manual_log_manuallog"
        },
        {
          "codename": "manual_log_revoke_manuallog",
          "label": "manual_log_revoke_manuallog"
        }
      ]
    },
    {
      "id": "att.leave",
      "label": "Leave",
      "children": [
        {
          "codename": "leave_revoke_leave",
          "label": "leave_revoke_leave"
        },
        {
          "codename": "view_leave",
          "label": "view_leave"
        },
        {
          "codename": "add_leave_leave",
          "label": "add_leave_leave"
        },
        {
          "codename": "change_leave",
          "label": "change_leave"
        },
        {
          "codename": "delete_leave",
          "label": "delete_leave"
        },
        {
          "codename": "leave_approve_leave",
          "label": "leave_approve_leave"
        }
      ]
    },
    {
      "id": "att.overtime",
      "label": "Overtime",
      "children": [
        {
          "codename": "view_overtime",
          "label": "view_overtime"
        },
        {
          "codename": "delete_overtime",
          "label": "delete_overtime"
        },
        {
          "codename": "change_overtime",
          "label": "change_overtime"
        },
        {
          "codename": "add_overtime_overtime",
          "label": "add_overtime_overtime"
        },
        {
          "codename": "overtime_approve_overtime",
          "label": "overtime_approve_overtime"
        },
        {
          "codename": "overtime_revoke_overtime",
          "label": "overtime_revoke_overtime"
        }
      ]
    },
    {
      "id": "att.leaveschedule",
      "label": "Incident Schedule",
      "children": [
        {
          "codename": "add_leave_schedule_leaveschedule",
          "label": "add_leave_schedule_leaveschedule"
        },
        {
          "codename": "change_leaveschedule",
          "label": "change_leaveschedule"
        },
        {
          "codename": "view_leaveschedule",
          "label": "view_leaveschedule"
        },
        {
          "codename": "delete_leaveschedule",
          "label": "delete_leaveschedule"
        }
      ]
    },
    {
      "id": "att.leavesettings",
      "label": "Incident Settings",
      "children": [
        {
          "codename": "view_leavesettings",
          "label": "view_leavesettings"
        },
        {
          "codename": "add_leave_settings_leavesettings",
          "label": "add_leave_settings_leavesettings"
        },
        {
          "codename": "delete_leavesettings",
          "label": "delete_leavesettings"
        },
        {
          "codename": "change_leavesettings",
          "label": "change_leavesettings"
        },
        {
          "codename": "add_leavesettings",
          "label": "add_leavesettings"
        }
      ]
    },
    {
      "id": "att.training",
      "label": "Training",
      "children": [
        {
          "codename": "delete_training",
          "label": "delete_training"
        },
        {
          "codename": "training_approve_training",
          "label": "training_approve_training"
        },
        {
          "codename": "training_revoke_training",
          "label": "training_revoke_training"
        },
        {
          "codename": "view_training",
          "label": "view_training"
        },
        {
          "codename": "add_training_training",
          "label": "add_training_training"
        },
        {
          "codename": "change_training",
          "label": "change_training"
        },
        {
          "codename": "add_training",
          "label": "add_training"
        }
      ]
    },
    {
      "id": "att.breaktime",
      "label": "Break Time",
      "children": [
        {
          "codename": "change_breaktime",
          "label": "change_breaktime"
        },
        {
          "codename": "add_breaktime",
          "label": "add_breaktime"
        },
        {
          "codename": "view_breaktime",
          "label": "view_breaktime"
        },
        {
          "codename": "delete_breaktime",
          "label": "delete_breaktime"
        }
      ]
    },
    {
      "id": "att.departmentschedule",
      "label": "Department Schedule",
      "children": [
        {
          "codename": "change_departmentschedule",
          "label": "change_departmentschedule"
        },
        {
          "codename": "import_departmentschedule",
          "label": "import_departmentschedule"
        },
        {
          "codename": "add_department_schedule_departmentschedule",
          "label": "add_department_schedule_departmentschedule"
        },
        {
          "codename": "delete_departmentschedule",
          "label": "delete_departmentschedule"
        },
        {
          "codename": "view_departmentschedule",
          "label": "view_departmentschedule"
        }
      ]
    },
    {
      "id": "att.attschedule",
      "label": "Employee Schedule",
      "children": [
        {
          "codename": "add_att_schedule_attschedule",
          "label": "add_att_schedule_attschedule"
        },
        {
          "codename": "change_attschedule",
          "label": "change_attschedule"
        },
        {
          "codename": "view_attschedule",
          "label": "view_attschedule"
        },
        {
          "codename": "delete_attschedule",
          "label": "delete_attschedule"
        },
        {
          "codename": "import_attschedule",
          "label": "import_attschedule"
        }
      ]
    },
    {
      "id": "att.tempschedule",
      "label": "Temporary Schedule",
      "children": [
        {
          "codename": "delete_tempschedule",
          "label": "delete_tempschedule"
        },
        {
          "codename": "view_tempschedule",
          "label": "view_tempschedule"
        },
        {
          "codename": "change_tempschedule",
          "label": "change_tempschedule"
        },
        {
          "codename": "add_temp_schedule_tempschedule",
          "label": "add_temp_schedule_tempschedule"
        }
      ]
    },
    {
      "id": "att.changeschedule",
      "label": "Schedule Adjustment",
      "children": [
        {
          "codename": "delete_changeschedule",
          "label": "delete_changeschedule"
        },
        {
          "codename": "add_changeschedule",
          "label": "add_changeschedule"
        },
        {
          "codename": "change_schedule_revoke_changeschedule",
          "label": "change_schedule_revoke_changeschedule"
        },
        {
          "codename": "change_changeschedule",
          "label": "change_changeschedule"
        },
        {
          "codename": "view_changeschedule",
          "label": "view_changeschedule"
        },
        {
          "codename": "change_schedule_approve_changeschedule",
          "label": "change_schedule_approve_changeschedule"
        }
      ]
    }
  ]

  const [treeNodes, setTreeNodes] = useState<any[]>([]);
  const [allIds, setaAllIds] = useState<any[]>([]);
  useEffect(() => {
    const newNodes: any = []
    const newAllIds: any = []
    if (Object.keys(itemKey).length > 0) {
      nodes.forEach((node) => {
        const newChildren: any = []
        if (itemKey[node.id]) {
          node.children.forEach((child: any) => {
            if (itemKey[node.id][child.codename]) {
              newChildren.push({ ...child, id: itemKey[node.id][child.codename].toString() })
              newAllIds.push(itemKey[node.id][child.codename].toString())
            } else {
              console.log('error key', node.id, child.codename)
            }
          })
          node.children = newChildren
        } else {
          console.log('error key:', node.id)
        }
        newNodes.push(node)
      })
    }
    setTreeNodes(newNodes)
    setaAllIds(newAllIds)
  }, [itemKey])

  return (
    <TabPanel value={"att"}>
      <MultiSelectTreeView nodes={treeNodes}
        selected={initSelecteds}
        onSelectedChange={(ids: any) => {
          const unSelected = allIds.filter(each => ids.indexOf(each) === -1)
          onStatusChange && onStatusChange(ids, unSelected)
        }}
      />
    </TabPanel>
  )
}
export default PermissionView

import { Route, Routes } from "react-router-dom";
import ListView from "./ListView";
import RecordView from "./RecordView";

const PunchStateSettingRouter = () => {
  return (
    <Routes>
      <Route index element={<ListView />} />
      <Route path={"edit/"} element={<RecordView />} />
    </Routes>
  );
};
export default PunchStateSettingRouter;

import {Route, useRoutes} from "react-router-dom";
import PrivateRoute from "@/router/PrivateRoute";
import CompanyListView from "@/pages/vendor/tenement/Company/ListView";
import CompanyRecordView from "@/pages/vendor/tenement/Company/RecordView";
import OrderListView from "@/pages/vendor/subscription/Order/ListView";
import OrderRecordView from "@/pages/vendor/subscription/Order/RecordView";


const CompanyRouter = () => {
  const routes = useRoutes([
    {index: true, element: <PrivateRoute requireRoles={["agent", "vendor"]}><CompanyListView /></PrivateRoute>},
    {path: "edit", element: <PrivateRoute requireRoles={["agent", "vendor"]}><CompanyRecordView /></PrivateRoute>},
  ])
  return routes
}
export default CompanyRouter




import React from "react";
import {useTranslation, UseTranslationResponse, Namespace, KeyPrefix} from "react-i18next";


let useI18nRef: UseTranslationResponse<Namespace, KeyPrefix<Namespace>>;
export const I18nRef: React.FC = () => {
  useI18nRef = useTranslation()
  return null
}

const api = (msg: string, context?: any) => {
  const {t} = useI18nRef;
  if (context) {
    return t(msg, {ns: 'api', ...context})
  } else {
    return t(msg, {ns: 'api'})
  }

}

export default {
  api
}

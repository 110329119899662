import {useTranslation} from "react-i18next";
import {RJSFSchema, UiSchema} from "@rjsf/utils";
import React, {useEffect, useState} from "react";
import LayoutTemplate from "@/pages/Attendance/GlobalRule/LayoutTemplate";
import TimesWidget from "@/pages/Attendance/GlobalRule/components/TimesWidget";
import EnableWidget from "@/pages/Attendance/GlobalRule/components/EnableWidget";
import AttRuleService from "@/services/att/AttRuleService";
import WeekOffWidget from "@/pages/Attendance/GlobalRule/components/WeekOffWidget";
import SnackbarUtil from "@/utils/SnackbarUtil";
import ModelActionPage from "@/components/views/ListView/components/model-action/ModelActionPage";


const GlobalRule = () => {
  const {t} = useTranslation("att");
  const [formData, setFormData] = useState<Record<string, any>>()
  const formSchema: RJSFSchema = {
    type: "object",
    properties: {
      punch_period: {
        type: "integer",
        title: t("workRule.punchPeriod"),
        default: 1,
        minimum: 0
      },
      weekend: {
        type: "array",
        items: {
          type: "object",
          properties: {
            lv1: {
              type: "number"
            },
            lv2: {
              type: "number"
            },
            lv3: {
              type: "number"
            }
          }
        }
      },
      use_ot: {
        type: "integer",
        title: t("workRule.overtimeRule"),
        oneOf: [
          {const: 0, title: t("workRule.disable.overtime")},
          {const: 1, title: t("workRule.calculateOT")},
          {const: 2, title: t("workRule.approvedOT")},
          {const: 3, title: t("workRule.approvedOTPriority")},
        ]
      },
      mins_late_absent: {
        type: "integer",
        title: t("workRule.exception.considerAs"),
        oneOf: [
          {const: 0, title: t("workRule.exception.absent")},
          {const: 1, title: t("workRule.exception.late")},
          {const: 2, title: t("workRule.exception.halfDay")},
        ]
      },
      mins_late_absents: {
        type: "integer",
        title: t("workRule.exception.lateDuration"),
        default: 60,
        minimum: 0,
        maximum: 24 * 60 * 60
      },
      timecheckin: {
        type: "boolean",
        title: t("workRule.exception.enableLateTime"),
        default: false
      },
      timecheckinmin: {
        type: "integer",
        title: t("workRule.exception.lateTime"),
        default: 0,
        minimum: 0
      },
      timecheckinminchoices: {
        type: "integer",
        title: t("workRule.exception.considerAs"),
        oneOf: [
          {const: 0, title: t("workRule.exception.absent")},
          {const: 1, title: t("workRule.exception.late")},
          {const: 2, title: t("workRule.exception.halfDay")},
        ]
      },
      mins_early_absents: {
        type: "integer",
        title: t("workRule.exception.earlyLeaveDuration"),
        default: 60,
        minimum: 0,
        maximum: 24 * 60 * 60
      },
      mins_early_absent: {
        type: "integer",
        title: t("workRule.exception.considerAs"),
        oneOf: [
          {const: 0, title: t("workRule.exception.absent")},
          {const: 1, title: t("workRule.exception.earlyLeave")},
          {const: 2, title: t("workRule.exception.halfDay")},
        ]
      },
      timecheckout: {
        type: "boolean",
        title: t("workRule.exception.enableEarlyLeaveTime"),
        default: false
      },
      timecheckoutmin: {
        type: "integer",
        title: t("workRule.exception.earlyLeaveTime"),
        default: 0,
        minimum: 0
      },
      timecheckoutminchoices: {
        type: "integer",
        title: t("workRule.exception.considerAs"),
        oneOf: [
          {const: 0, title: t("workRule.exception.absent")},
          {const: 1, title: t("workRule.exception.earlyLeave")},
          {const: 2, title: t("workRule.exception.halfDay")},
        ]
      },
      half_day_work: {
        type: "integer",
        title: t("workRule.exception.halfDayWork"),
        default: 270,
        minimum: 1,
        maximum: 24 * 60 * 60
      },
      miss_in: {
        type: "integer",
        title: t("workRule.exception.missedIn"),
        oneOf: [
          {const: 1, title: t("workRule.exception.present")},
          {const: 2, title: t("workRule.exception.absent")},
          {const: 0, title: t("workRule.exception.incomplete")},
          {const: 5, title: t("workRule.exception.halfDay")},
        ]
      },
      miss_out: {
        type: "integer",
        title: t("workRule.exception.missedOut"),
        oneOf: [
          {const: 1, title: t("workRule.exception.present")},
          {const: 2, title: t("workRule.exception.absent")},
          {const: 0, title: t("workRule.exception.incomplete")},
          {const: 5, title: t("workRule.exception.halfDay")},
        ]
      },
      check_in: {
        type: "string",
        title: t("workRule.punchState.checkIn"),
        default: "0"
      },
      check_out: {
        type: "string",
        title: t("workRule.punchState.checkOut"),
        default: "1"
      },
      break_out: {
        type: "string",
        title: t("workRule.punchState.breakOut"),
        default: "2"
      },
      break_in: {
        type: "string",
        title: t("workRule.punchState.breakIn"),
        default: "3"
      },
      overtime_in: {
        type: "string",
        title: t("workRule.punchState.overtimeIn"),
        default: "4"
      },
      overtime_out: {
        type: "string",
        title: t("workRule.punchState.overtimeOut"),
        default: "5"
      },
      leavemng_condition: {
        type: "boolean",
        title: t("workRule.leaveRule.condition"),
        default: false
      },
      leave_calculation_start_day: {
        type: "integer",
        title: t("workRule.leaveRule.startDay"),
      },
      leave_calculation_end_day: {
        type: "integer",
        title: t("workRule.leaveRule.endDay"),
      },
      full_month: {
        type: "boolean",
        title: t("workRule.leaveRule.fullMonth"),
        default: false
      },
      enable_capture: {
        type: "boolean",
        title: t("workRule.appSettings.enableCapture"),
        default: false
      },
      enable_workcode: {
        type: "boolean",
        title: t("workRule.appSettings.enableWorkCode"),
        default: false
      },
      enable_funckey: {
        type: "boolean",
        title: t("workRule.appSettings.enablePunchState"),
        default: false
      },
    },
    required: [
      "punch_period", "use_ot",
      "mins_late_absents", "mins_late_absent", "timecheckinmin", "timecheckinminchoices",
      "mins_early_absents", "mins_early_absent", "timecheckoutmin", "timecheckoutminchoices",
      "half_day_work", "miss_in", "miss_out",
      "check_in", "check_out", "break_out", "break_in", "overtime_in", "overtime_out",
      "enable_capture", "enable_workcode", "enable_funckey"
    ]
  }
  const uiSchema: UiSchema = {
    punch_period: {
      "ui:widget": "minute"
    },
    weekend: {
      "ui:widget": "weekOff"
    },
    mins_late_absents: {
      "ui:widget": "minute"
    },
    timecheckinmin: {
      "ui:widget": "times"
    },
    mins_early_absents: {
      "ui:widget": "minute"
    },
    timecheckoutmin: {
      "ui:widget": "times"
    },
    half_day_work: {
      "ui:widget": "minute"
    },
    leavemng_condition: {
      "ui:widget": "enable"
    },
    full_month: {
      "ui:widget": "enable"
    },
    enable_capture: {
      "ui:widget": "enable"
    },
    enable_workcode: {
      "ui:widget": "enable"
    },
    enable_funckey: {
      "ui:widget": "enable"
    },
    timecheckin: {
      "ui:widget": "enable"
    },
    timecheckout: {
      "ui:widget": "enable"
    }
  }

  const widgets = {
    weekOff: WeekOffWidget,
    times: TimesWidget,
    enable: EnableWidget
  }

  const templates = {
    ObjectFieldTemplate: LayoutTemplate
  }

  const fetchGlobalRule = async () => {
    const data = await AttRuleService.getGlobalRule()
    setFormData(data)
  }

  const onSaveGlobalRule = async (data: Record<string, any>) => {
    const response = await AttRuleService.postGlobalRule(data)
    if (response.isSuccess) {
      SnackbarUtil.success(t("common.message.saveSuccessfully", {ns: "common"}))
    } else {
      console.log(response)
    }
  }

  useEffect(() => {
    fetchGlobalRule().then()
  }, [])

  return (
    <ModelActionPage
      title={t("menu.att.globalRule", {ns: "menu"})}
      formSchema={formSchema}
      uiSchema={uiSchema}
      widgets={widgets}
      templates={templates}
      formData={formData}
      disableDiscard={true}
      onSave={onSaveGlobalRule}
    />
  )
}
export default GlobalRule

import { useTranslation } from "react-i18next";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import urls from "@/urls";
import { ReportEndPoint } from "@/services/report/endpoints";
import ReportView from "@/pages/Report/ReportLibrary/components/ReportView";
import { useReportEmployee } from "@/hooks/employee";


const TrainingReport = () => {
  const { t } = useTranslation("report");
  const employeeFields = useReportEmployee()
  const columns: GridColDef[] = [
    ...employeeFields,
    {
      field: "att_date",
      headerName: t("report.column.scheduleDate"),
      flex: 1,
    },
    {
      field: "start_time",
      headerName: t("report.column.clockIn"),
      flex: 1,
    },
    {
      field: "end_time",
      headerName: t("report.column.clockOut"),
      flex: 1,
    },
    {
      field: "total_worked",
      headerName: t("report.column.totalWorkTime"),
      flex: 1,
    },
    {
      field: "report_symbol",
      headerName: t("report.column.trainingSymbol"),
      flex: 1,
    },
  ];

  return (
    <ReportView
      title={t("reportLibrary.training")}
      breadcrumbs={[]}
      columns={columns}
      uri={ReportEndPoint.TRAINING_URI}
      url={urls.report.training}
      hideColumns={['first_name', 'last_name', 'department_code', 'position_code', 'position_name']}
    />
  );
};
export default TrainingReport;

import {Avatar, Box, Divider, Drawer, FormLabel, Typography} from "@mui/material";
import {Stack} from "@mui/system";
import {useTranslation} from "react-i18next";


const RecordPanel = (props: Record<string, any>) => {
  const {t} = useTranslation("device")
  const {t: per} = useTranslation("per")
  const {record} = props
  const info = [
    {label: per("employee.code"), value: record?.emp_code},
    {label: per("employee.name"), value: `${record?.first_name} ${record?.last_name || ""}`},
    {label: per("employee.department"), value: record?.department},
    {label: per("employee.position"), value: record?.position},
    {label: t("transaction.punchDate"), value: record?.punch_date},
    {label: t("transaction.punchTime"), value: record?.punch_time},
    {label: t("transaction.area"), value: record?.area_alias || "-"},
    {label: t("device.sn"), value: record?.terminal_sn || "-"},
    {label: t("transaction.uploadTime"), value: record?.upload_time},
  ]
  return (
    <Box sx={{
      minWidth: "256px",
      backgroundColor: "#fff"
    }}>
      <Box sx={{
        display: "flex",
        justifyContent: "center",
        p: 2
      }}>
        <Avatar
          key={record?.id}
          alt={record?.first_name}
          variant={"square"}
          src={record?.capture_url && `/web/${record?.capture_url}`}
          sx={{width: "128px", height: "128px", img: {objectFit: "contain"}}}
        />
      </Box>
      <Divider/>
      {record && <Stack sx={{p: 2}} spacing={1}>
        {info.map((field, index) => {
          return (
            <Stack key={`field${index}`} spacing={0.1}>
              <FormLabel>{field.label}</FormLabel>
              <Typography fontSize={14}>{field.value}</Typography>
            </Stack>
          )
        })}
      </Stack>}
    </Box>
  )
}
export default RecordPanel

import ListView from "@/components/views/ListView";
import {GridColDef} from "@mui/x-data-grid";
import AppUrls from "@/urls";
import urls from "@/urls";
import {useTranslation} from "react-i18next";
import {AttEndPoint} from "@/services/att/endpoints";
import ApprovalIcon from "@mui/icons-material/Approval";
import ApprovalAction from "@/pages/Attendance/components/ApprovalAction";

const ManualLogListView = () => {
  const {t: t} = useTranslation("att");
  const {t: per} = useTranslation("per");
  const {t: common} = useTranslation("common");
  const {t: tMenu} = useTranslation("menu");
  const columns: GridColDef[] = [
    {
      field: "emp_code",
      headerName: per("employee.code"),
      flex: 1,
    },
    {
      field: "first_name",
      valueFormatter: (value: any, row: any) => row.employee.first_name,
      headerName: per("employee.firstName"),
      flex: 1,
    },
    {
      field: "last_name",
      valueFormatter: (value: any, row: any) => row.employee.last_name,
      headerName: per("employee.lastName"),
      flex: 1,
    },
    {field: "dept_name", headerName: per("employee.department"), flex: 1},
    {field: "position_name", headerName: per("employee.position"), flex: 1},
    {field: "punch_time", headerName: t("manualLog.punchTime"), flex: 1},
    {
      field: "punch_state", headerName: t("manualLog.punchState"), flex: 1,
      type: "singleSelect",
      valueOptions: [
        {value: 0, label: common("punchState.checkIn")},
        {value: 1, label: common("punchState.checkOut")},
        {value: 2, label: common("punchState.breakOut")},
        {value: 3, label: common("punchState.breakIn")},
        {value: 4, label: common("punchState.overtimeIn")},
        {value: 5, label: common("punchState.overtimeOut")},
        {value: 255, label: common("punchState.none")},
      ],
    },
    {field: "work_code", headerName: t("manualLog.workCode"), flex: 1},
    {field: "apply_reason", headerName: common("approval.applyReason"), flex: 1},
    {field: "apply_time", headerName: common("approval.applyTime"), flex: 1},
    {
      field: "audit_status",
      headerName: common("approval.status"),
      flex: 1,
      type: "singleSelect",
      valueOptions: [
        {value: 1, label: common("approval.status.pending")},
        {value: 2, label: common("approval.status.approved")},
        {value: 3, label: common("approval.status.rejected")},
        {value: 6, label: common("approval.status.revoked")},
        {value: 7, label: common("approval.status.withdraw")},
      ],
    },
    {
      field: "audit_reason",
      headerName: common("approval.remark"),
      flex: 1,
    },
    {
      field: "audit_time",
      headerName: common("approval.time"),
      flex: 1,
    },
    {field: "last_approver", headerName: common("approval.approver"), flex: 1},
  ];

  const modelActions = [
    {
      id: "manualLogApprovals",
      title: t("manualLog.transfer"),
      icon: <ApprovalIcon/>,
      subs: [
        {
          id: "manualLogApprove",
          title: common("approval.action.approve"),
          validateSelected: true,
          display: "dialog",
          Form: ApprovalAction,
          state: {
            uri: AttEndPoint.MANUAL_LOG_URI,
            action: "approve"
          }
        },{
          id: "manualLogReject",
          title: common("approval.action.reject"),
          validateSelected: true,
          display: "dialog",
          Form: ApprovalAction,
          state: {
            uri: AttEndPoint.MANUAL_LOG_URI,
            action: "reject"
          }
        }, {
          id: "manualLogRevoke",
          title: common("approval.action.revoke"),
          validateSelected: true,
          display: "dialog",
          Form: ApprovalAction,
          state: {
            uri: AttEndPoint.MANUAL_LOG_URI,
            action: "revoke"
          }
        },
      ],
    },
  ];
  const filters = [
    {
      key: "employee__emp_code",
      label: per("employee.code"),
      type: "string",
    },
    {
      key: "employee__first_name",
      label: per("employee.firstName"),
      type: "string",
    },
    {
      key: "employee__last_name",
      label: per("employee.lastName"),
      type: "string",
    },
  ];

  return (
    <ListView
      title={tMenu("menu.att.manualLog")}
      breadcrumbs={[{title: tMenu("menu.attendance"), href: urls.att.root}]}
      columns={columns}
      uri={AttEndPoint.MANUAL_LOG_URI}
      url={AppUrls.att.manualLog}
      filters={filters}
      modelActions={modelActions}
    />
  );
};
export default ManualLogListView;

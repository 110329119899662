import {useRoutes} from "react-router-dom";
import PrivateRoute from "@/router/PrivateRoute";
import PriceStrategyListView from "@/pages/vendor/subscription/PriceStrategy/ListView";
import PriceStrategyRecordView from "@/pages/vendor/subscription/PriceStrategy/RecordView";


const PriceStrategyRouter = () => {
  const routes = useRoutes([
    {index: true, element: <PrivateRoute requireRoles={["agent", "vendor"]}><PriceStrategyListView /></PrivateRoute>},
    {path: "add", element: <PrivateRoute requireRoles={["agent", "vendor"]}><PriceStrategyRecordView /></PrivateRoute>},
    {path: "edit", element: <PrivateRoute requireRoles={["agent", "vendor"]}><PriceStrategyRecordView /></PrivateRoute>},
  ])
  return routes
}
export default PriceStrategyRouter

import {Box, IconButton} from "@mui/material";
import {bindMenu, bindTrigger, usePopupState} from "material-ui-popup-state/hooks";
import ActionMenu from "@/components/views/ListView/components/ActionMenu";
import ListViewRecordAction from "@/components/views/ListView/components/record-action/RecordAction";


const RecordActionMenu = (props: Record<string, any>) => {
  const popupState = usePopupState({variant: "popover", popupId: "rowAction"})
  return (
    <Box key={props.id}>
      <IconButton
        title={props.title}
        sx={{
          width: "32px",
          height: "32px"
        }}
        {...bindTrigger(popupState)}
      >
        {props.icon}
      </IconButton>
      <ActionMenu {...bindMenu(popupState)}>
        {props.subs?.map((action: Record<string, any>) => {
          console.log(props, action)
          return (<ListViewRecordAction key={action.id} menuItem={true} record={props.record} {...action}/>)
        })}
      </ActionMenu>
    </Box>
  )
}
export default RecordActionMenu

import GenericService from "@/services/core/GenericService";
import {ReportEndPoint} from "@/services/report/endpoints";
import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";

export const usePunchStateHook = (): any => {
  const [punchStateOptions, setPunchStateOptions] = useState<any>([])

  useEffect(() => {
    GenericService.list(ReportEndPoint.PUNCH_STATE_SETTING_URI, {page: 1, page_size: 100})
      .then((response) => {
        setPunchStateOptions(response.data.data.map((each: any) => {
          return {value: each.value, label: each.name}
        }))
      })
  }, [])

  return punchStateOptions;
}

export const useWeekdayHook = (): any => {
  const {t} = useTranslation("common");
  return [
    {value: 0, label: t("weekday.monday")},
    {value: 1, label: t("weekday.tuesday")},
    {value: 2, label: t("weekday.wednesday")},
    {value: 3, label: t("weekday.thursday")},
    {value: 4, label: t("weekday.friday")},
    {value: 5, label: t("weekday.saturday")},
    {value: 6, label: t("weekday.sunday")},
  ];
}





import {useEffect, useState} from "react";
import GenericService from "@/services/core/GenericService";
import {VendorEndPoint} from "@/services/vendor/endpoints";
import {Box, CircularProgress} from "@mui/material";
import {Stack} from "@mui/system";
import PlanCard from "@/pages/OnlineSubscription/MXSubscription/Pricing/components/PlanCard";


const MXSubscriptionPlan = () => {
  const [plans, setPlans] = useState<any>([])
  useEffect(() => {
    GenericService.action(VendorEndPoint.SUBSCRIPTION_URI, 'plans').then((response) => {
      setPlans(response.data)
    })
  }, [])
  return (
    <Box sx={{
      height: "100%",
      display: "flex",
      justifyContent: "center"
    }}>
      {plans.length > 0
        ? <Stack direction={"row"} spacing={2}>{
          plans.map((plan: any) => {
            return (
              <PlanCard plan={plan}/>
            )
          })
        }</Stack>
        : <Box sx={{height: "100%"}}><CircularProgress/></Box>
      }
    </Box>
  )
}
export default MXSubscriptionPlan

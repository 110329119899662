import {RJSFSchema, UiSchema} from "@rjsf/utils";
import {useTranslation} from "react-i18next";
import RecordView from "@/components/views/RecordView";
import {PsnlEP} from "@/services/psnl/endpoints";
import {DeviceEndPoint} from "@/services/device/endpoints";

const PrivateMessageRecordView = () => {
  const {t} = useTranslation("device");
  const {t: common} = useTranslation("common");
  const formSchema: RJSFSchema = {
    type: "object",
    required: ["employee", "start_time", "duration", "content"],
    properties: {
      employee: {
        type: "string",
        title: t("privateMessage.employee"),
      },
      start_time: {
        type: "string",
        format: "datetime",
        title: t("privateMessage.startTime"),
      },
      duration: {
        type: "number",
        title: t("privateMessage.duration"),
        minimum: 0,
      },
      content: {
        type: "string",
        title: t("privateMessage.content"),
        maxLength: 320
      },
    },
  };

  const uiSchema: UiSchema = {
    "ui:defaultXsWidth": 6,
    employee: {
      "ui:widget": "m2o",
      "ui:options": {
        uri: PsnlEP.EMPLOYEE_URI,
      },
    },
    content: {
      "ui:widget": "textarea",
    },
    duration: {
      "ui:widget": "quantifier",
      "ui:options": {
        unit: common("common.unit.minute")
      }
    },
  };
  return (
    <RecordView
      formSchema={formSchema}
      uiSchema={uiSchema}
      uri={DeviceEndPoint.PRIVATE_MESSAGE_URI}
    />
  );
};
export default PrivateMessageRecordView;

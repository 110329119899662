import {Route, Routes} from "react-router-dom";
import ClientDetailsListView from "./ListView";
import ClientDetailsRecordView from "./RecordView";
import MapProvider from "@/provider/MapProvider";

const ClientDetailsRouter = () => {
  return (
    <Routes>
      <Route index element={<ClientDetailsListView />}/>
      <Route path={"add/"} element={<MapProvider><ClientDetailsRecordView /></MapProvider>}/>
      <Route path={"edit/"} element={<MapProvider><ClientDetailsRecordView /></MapProvider>}/>
    </Routes>
  )
};
export default ClientDetailsRouter

import ListView from "@/components/views/ListView";
import {GridColDef, GridRenderCellParams} from "@mui/x-data-grid";
import AppUrls from "@/urls";
import {useTranslation} from "react-i18next";
import {AttEndPoint} from "@/services/att/endpoints";
import dayjs from "dayjs";
import {Badge, useTheme} from "@mui/material";


const BreakTimeListView = () => {
  const {t} = useTranslation("att")
  const theme = useTheme()
  const renderEndTime = (params: GridRenderCellParams) => {
    const startTime = dayjs(`1900-01-01 ${params.row.start_time}`)
    const endTime = dayjs(`1900-01-01 ${params.row.end_time}`)
    if(startTime.isAfter(endTime)){
      return <Badge
        badgeContent={"+1"}
        sx={{
          "& .MuiBadge-badge": {
            top: 16,
            right: -8,
            color: theme.palette.primary.main
          },
        }}
      >
        {params.value}
      </Badge>
    }
    return params.value
  }
  const columns: GridColDef[] = [
    {field: "alias", headerName: t("breakTime.alias"), flex: 1},
    {field: "start_time", headerName: t("breakTime.startTime"), flex: 1},
    {field: "end_time", headerName: t("breakTime.endTime"), flex: 1, renderCell: renderEndTime},
    {field: "duration", headerName: t("breakTime.duration"), flex: 1},
    {field: "calc_type", headerName: t("breakTime.calcType"), flex: 1,
      type: "singleSelect",
      valueOptions: [
          { value: 0, label: t("breakTime.autoDeduct") },
          { value: 1, label: t("breakTime.requiredPunch") },
        ]
    },
  ]

  const filters = [
    {key: 'alias', label: t("breakTime.alias"), type: 'string'},
  ]

  return (
    <ListView
      title={t("menu.att.breakTime", {ns: "menu"})}
      columns={columns}
      uri={AttEndPoint.BREAK_TIME_URI}
      url={AppUrls.att.breakTime}
      filters={filters}
    />
  )
}
export default BreakTimeListView

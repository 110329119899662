import React, {createContext, useContext, useRef, useState} from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Stack
} from "@mui/material";
import {useTranslation} from "react-i18next";
import Utils from "@/utils";
import {string} from "zod";
import {Close} from "@mui/icons-material";
import {LoadingButton} from "@mui/lab";

type DialogContextProps = Record<string, any>

const DialogContext = createContext<DialogContextProps>({
  showDialog: () => ({key: string, hide: Promise<void>}),
  hideDialog: Promise<void>
})

export const useDialogContext = () => {
  const context = useContext(DialogContext)
  if (!context) {
    throw new Error('The dialog provider not found!')
  }
  return context
}

type CustomDialogProps = Record<string, any>
const CustomDialog = (props: CustomDialogProps) => {
  const {t} = useTranslation("common")
  const formRef = useRef<any>()
  const [loadings, setLoadings] = useState<any>({})
  const onFormConfirm = async () => {
    return await formRef.current?.onConfirm()
  }
  const _onConfirm = props.Form? onFormConfirm: props.onConfirm
  const onConfirm = async () => {
    const isFinished = await _onConfirm()
    if (isFinished) {
      await props.onDiscard()
      await props.afterConfirm?.(isFinished)
    }
  }
  const defaultActions = [
    {key: "confirm", variant: "contained", name: t('common.button.confirm', {ns: "common"}), callback: onConfirm},
    {key: "discard", name: t('common.button.discard', {ns: "common"}), callback: props.onDiscard},

  ]
  const actions = [...defaultActions, ...props.actions || []]
  const actionWrapper = (action: any) => {
    return async () => {
      setLoadings({...loadings, [action.key]: true})
      await action.callback?.().finally(() => {
        setLoadings({...loadings, [action.key]: false})
      })
    }
  }
  return (
    <Dialog
      open={props.open || false}
      sx={{
        minWidth: "320px",
        minHeight: "128px",
        "& .MuiPaper-root": {
          maxWidth: "calc(100% - 64px)"
        }
      }}
    >
      <DialogTitle>{props.title}</DialogTitle>
      {props.disableAction && <IconButton
        sx={{
          position: "absolute",
          top: "12px",
          right: "6px"
        }}
        onClick={props.onDiscard}
      >
        <Close />
      </IconButton>}
      <DialogContent>
        {props.description && <DialogContentText>{props.description}</DialogContentText>}
        {props.confirmation && <DialogContentText>{props.confirmation}</DialogContentText>}
        {props.content}
        {props.Form && <Box sx={{marginTop: "16px"}}>
          <props.Form ref={formRef} {...props.formProps}/>
        </Box>}
      </DialogContent>
      {!props.disableAction && <DialogActions>
        <Stack direction={"row"} spacing={1}>
          {actions.map((action) =>
            <LoadingButton
              key={action.key}
              loading={loadings[action.key]}
              variant={action.variant || "outlined"}
              onClick={actionWrapper(action)}
            >
              {action.name}
            </LoadingButton>
          )}
        </Stack>
      </DialogActions>}
    </Dialog>
  )
}

type DialogProviderProps = Record<string, any>
const DialogProvider = (props: DialogProviderProps) => {
  const [dialogConfig, setDialogConfig] = useState<Record<string, any>>({open: false})

  const hide = (key: string) => {
    return async () => {
      setDialogConfig({
        open: false,
        id: key
      })
    }
  }

  const show = async (options: Record<string, any>) => {
    const key = Utils.uid(8)
    const onDiscard = async () => {
      await hide(key)()
    }

    setDialogConfig({
      ...options,
      onDiscard,
      open: true,
      id: key
    })
    return {
      key,
      hide: hide(key)
    }
  }


  return (
    <DialogContext.Provider
      value={{
        showDialog: show,
        hideDialog: hide,
      }}
    >
      {props.children}
      {dialogConfig?.open && <CustomDialog {...dialogConfig}/>}
    </DialogContext.Provider>
  )
}
export default DialogProvider

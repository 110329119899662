const API_VER = 'v2'
const RESOURCE_TAG = 'api'
export const AccessControlEndPoint = {
  TERMINAL_URI: `/acc/${RESOURCE_TAG}/${API_VER}/terminals/`,
  COMMAND_URI: `/acc/${RESOURCE_TAG}/${API_VER}/commands/`,
  TIME_PERIOD_URI: `/acc/${RESOURCE_TAG}/${API_VER}/time_periods/`,
  HOLIDAY_URI: `/acc/${RESOURCE_TAG}/${API_VER}/holidays/`,
  GROUP_URI: `/acc/${RESOURCE_TAG}/${API_VER}/groups/`,
  COMBINATION_URI: `/acc/${RESOURCE_TAG}/${API_VER}/combinations/`,
  PRIVILEGE_URI: `/acc/${RESOURCE_TAG}/${API_VER}/privileges/`,
}

import TransactionReport from "@/pages/Report/ReportLibrary/components/TransactionReport";
import ReportLibrary from "@/pages/Report/ReportLibrary/index";
import LateReport from "@/pages/Report/ReportLibrary/components/LateReport";

import AbsentReport from "@/pages/Report/ReportLibrary/components/AbsentReport";
import EarlyLeaveReport from "@/pages/Report/ReportLibrary/components/EarlyLeaveReport";
import TotalTimecardReport from "@/pages/Report/ReportLibrary/components/TotalTimecardReport";
import FirstLastReport from "@/pages/Report/ReportLibrary/components/FirstLastReport";
import FirstInLastOutReport from "@/pages/Report/ReportLibrary/components/FirstInLastOutReport";
import { useRoutes } from "react-router-dom";
import OvertimeReport from "@/pages/Report/ReportLibrary/components/OvertimeReport";
import DailyAttendanceReport from "./components/DailyAttendanceReport";
import MobileTransactionReport from "@/pages/Report/ReportLibrary/components/MobileTransactionReport";
import TotalPunchesReport from "@/pages/Report/ReportLibrary/components/TotalPunchesReport";
import ScheduledLogReport from "@/pages/Report/ReportLibrary/components/ScheduledLogReport";
import DailyDetailsReport from "./components/DailyDetails";
import MissedInOutReport from "@/pages/Report/ReportLibrary/components/MissedInOutReport";
import EmployeeBirthdayReport from "@/pages/Report/ReportLibrary/components/EmployeeBirthdayReport";
import HalfDayReport from "@/pages/Report/ReportLibrary/components/HalfDayReport";
import BreakTimeReport from "@/pages/Report/ReportLibrary/components/BreakTimeReport";
import DailySummaryReport from "./components/DailySummartReport";
import DailyStatusReport from "./components/DailyStatus";
import TrainingReport from "@/pages/Report/ReportLibrary/components/TrainingReport";
import MultipleTransactionReport from "@/pages/Report/ReportLibrary/components/MultipleTransactionReport";
import AbnormalReport from "@/pages/Report/ReportLibrary/components/AbnormalReport";
import EmployeeSummaryReport from "@/pages/Report/ReportLibrary/components/EmployeeSummaryReport";
import MonthlyBasicStatus from "./components/MonthlyBasicStatus";
import DepartmentSummaryReport from "@/pages/Report/ReportLibrary/components/DepartmentSummaryReport";
import LeaveSummaryReport from "@/pages/Report/ReportLibrary/components/LeaveSummaryReport";
import MonthlyStatusSummaryReport from "./components/MonthlyStatusSummaryReport";
import PayrollSummaryReport from "@/pages/Report/ReportLibrary/components/PayrollSummaryReport";
import YearlySummaryReport from "@/pages/Report/ReportLibrary/components/YearlySummaryReport";
import MonthlyDetailedSummary from "./components/MonthlyDetailedSummaryReport";
import OverTimeSummaryReport from "@/pages/Report/ReportLibrary/components/OverTimeSummaryReport";
import DurationSummaryReport from "@/pages/Report/ReportLibrary/components/MonthlyDurationSummaryReport";
import AttSheetSummaryReport from "@/pages/Report/ReportLibrary/components/MonthlyAttSheetSummaryReport";

const ReportLibraryRouter = () => {
  const routes = useRoutes([
    { index: true, element: <ReportLibrary /> },
    { path: "transaction", element: <TransactionReport /> },
    { path: "late", element: <LateReport /> },
    { path: "absent", element: <AbsentReport /> },
    { path: "total-timecard", element: <TotalTimecardReport /> },
    { path: "daily-attendance", element: <DailyAttendanceReport /> },
    { path: "daily-details", element: <DailyDetailsReport /> },
    { path: "daily-summary", element: <DailySummaryReport /> },
    { path: "daily-status", element: <DailyStatusReport /> },
    { path: "early-leave", element: <EarlyLeaveReport /> },
    { path: "first-last", element: <FirstLastReport /> },
    { path: "first-in-last-out", element: <FirstInLastOutReport /> },
    { path: "overtime", element: <OvertimeReport /> },
    { path: "mobile-transaction", element: <MobileTransactionReport /> },
    { path: "total-punches", element: <TotalPunchesReport /> },
    { path: "scheduled-log", element: <ScheduledLogReport /> },
    { path: "missed-in-out", element: <MissedInOutReport /> },
    { path: "employee-birthday", element: <EmployeeBirthdayReport /> },
    { path: "half-day", element: <HalfDayReport /> },
    { path: "break-time", element: <BreakTimeReport /> },
    { path: "training", element: <TrainingReport /> },
    { path: "employee-summary", element: <EmployeeSummaryReport /> },
    { path: "multiple-transaction", element: <MultipleTransactionReport /> },
    { path: "abnormal", element: <AbnormalReport /> },
    { path: "monthly-basic-status", element: <MonthlyBasicStatus /> },
    { path: "monthly_status_summary", element: <MonthlyStatusSummaryReport /> },
    { path: "monthly_detailed_summary", element: <MonthlyDetailedSummary /> },
    { path: "department-summary", element: <DepartmentSummaryReport /> },
    { path: "leave-summary", element: <LeaveSummaryReport /> },
    { path: "payroll-summary", element: <PayrollSummaryReport /> },
    { path: "yearly-summary", element: <YearlySummaryReport /> },
    { path: "overtime-summary", element: <OverTimeSummaryReport /> },
    { path: "monthly-duration-summary", element: <DurationSummaryReport /> },
    { path: "monthly-attsheet-summary", element: <AttSheetSummaryReport /> },
  ]);

  return routes;
};

export default ReportLibraryRouter;

import { useTranslation } from "react-i18next";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import urls from "@/urls";
import { ReportEndPoint } from "@/services/report/endpoints";
import ReportView from "@/pages/Report/ReportLibrary/components/ReportView";
import { useReportEmployee } from "@/hooks/employee";

const EmployeeBirthdayReport = () => {
  const { t } = useTranslation("report");
  const employeeFields = useReportEmployee()
  const columns: GridColDef[] = [
    ...employeeFields,
    {
      field: "birthday",
      headerName: t("report.column.birthday"),
      flex: 1,
    },
  ];

  return (
    <ReportView
      title={t("reportLibrary.birthday")}
      breadcrumbs={[]}
      columns={columns}
      uri={ReportEndPoint.EMPLOYEE_BIRTHDAY_URI}
      url={urls.report.employeeBirthday}
      hideColumns={['first_name', 'last_name', 'department_code', 'position_code', 'position_name']}
    />
  );
};
export default EmployeeBirthdayReport;

import {ObjectFieldTemplateProps} from "@rjsf/utils";
import Grid from "@mui/material/Grid2";


const RecordViewLayout = (props: ObjectFieldTemplateProps) => {
  const fields: Record<string, any> = {}
  props.properties?.forEach((element: any) => {
    fields[element.name] = element.content
  })
  return (
    <Grid container>
      <Grid size={8} container spacing={2}>
        <Grid size={6}>
          {fields.alias}
        </Grid>
        <Grid size={6}>
          {fields.sn}
        </Grid>
        <Grid size={6}>
          {fields.area}
        </Grid>
        <Grid size={6}>
          {fields.terminal_tz}
        </Grid>
        <Grid size={6}>
          {fields.ip_address}
        </Grid>
        <Grid size={6}>
          {fields.transfer_mode}
        </Grid>
        <Grid size={6}>
          {fields.is_registration}
        </Grid>
        <Grid size={6}>
          {fields.heartbeat}
        </Grid>
        <Grid size={6}>
          {fields.is_attendance}
        </Grid>
        <Grid size={6}>
          {fields.is_access}
        </Grid>
        <Grid size={6}>
          {fields.transfer_interval}
        </Grid>
        <Grid size={6}>
          {fields.transfer_time}
        </Grid>
      </Grid>
    </Grid>
  )
}
export default RecordViewLayout

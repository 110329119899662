import {bindMenu, bindTrigger, usePopupState} from "material-ui-popup-state/hooks";
import {Tooltip} from "@mui/material";
import {MainIconButton} from "@/components/styled";
import ActionMenu from "@/components/views/ListView/components/ActionMenu";
import ListViewModelAction from "@/components/views/ListView/components/model-action/ModelAction";

type ListViewModelActionMenuProps = Record<string, any>

const ListViewModelActionMenu = (props: ListViewModelActionMenuProps) => {
  const popupState = usePopupState({variant: "popover", popupId: "modelAction"})
  return (
    <>
      <MainIconButton {...bindTrigger(popupState)}>
        {props.icon}
      </MainIconButton>
      <ActionMenu{...bindMenu(popupState)}>
        {props.subs?.map((action: Record<string, any>) => {
          const beforeAction = async () => {
            popupState.close()
            return await action.beforeAction?.()
          }
          return (<ListViewModelAction key={action.id} menuItem={true} {...action} beforeAction={beforeAction}/>)
        })}
      </ActionMenu>
    </>
  )
}
export default ListViewModelActionMenu

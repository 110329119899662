import {Box, IconButton, Stack, Typography} from "@mui/material";
import {HomeOutlined, TranslateOutlined} from "@mui/icons-material";
import React, {useRef} from "react";
import {useRegionContext} from "@/provider/RegionProvider";
import ChangeLanguage from "@/pages/Oauth/ChangeLanguage";
import {useMuiThemeContext} from "@/provider/MuiThemeProvider";
import {useTranslation} from "react-i18next";
import {useDialogContext} from "@/provider/DialogProvider";


const OnboardPanel = (props: Record<string, any>) => {
  const {t} = useTranslation("oauth")
  const {region} = useRegionContext()
  const {changeLanguage} = useMuiThemeContext()
  const languageRef = useRef<any>()
  const dialog = useDialogContext()
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexWrap: "wrap",
        textAlign: "center"
      }}
    >
      <Box
        sx={{width: "100%"}}
      >
        <Stack direction={"row"} spacing={1} sx={{p: 2}}>
          <Box flexGrow={1}/>
          <IconButton title={t("oauth.changeLanguage")} onClick={() => {
            const defaultLanguage = localStorage.getItem("i18nextLng") || "en"
            dialog.showDialog?.({
              title: t("oauth.changeLanguage"),
              content: <ChangeLanguage defaultLanguage={defaultLanguage} ref={languageRef}/>,
              onConfirm: async () => {
                await changeLanguage(languageRef.current.selected)
                return true
              }
            })
          }}>
            <TranslateOutlined/>
          </IconButton>
          <IconButton title={t("oauth.visitWebsite")} onClick={() => {
            window.open(`${region.website}`)
          }}>
            <HomeOutlined/>
          </IconButton>
        </Stack>
      </Box>
      <Box>
        <img style={{height: "64px", width: "200px"}} src={`/images/${region.identify_code}/logo.png`}/>
      </Box>
      <Box
        sx={{
          width: "100%",
          padding: "16px 32px 32px 32px",
          textAlign: "start",
        }}
      >
        <Typography variant={"h5"}>{props.title}</Typography>
      </Box>
      <Box
        sx={{
          padding: "0 32px",
          width: "100%",
          minHeight: "300px",
          alignItems: "center",
          justifyContent: "center"
        }}
      >
        {props.children}
      </Box>
      <Box sx={{p: 1, mt: 2}}>
        <Typography>{region.copyright}</Typography>
      </Box>
    </Box>
  )
}
export default OnboardPanel

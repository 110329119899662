import {useTranslation} from "react-i18next";
import {GridColDef, GridRenderCellParams} from "@mui/x-data-grid";
import urls from "@/urls";
import {ReportEndPoint} from "@/services/report/endpoints";
import ReportView from "@/pages/Report/ReportLibrary/components/ReportView";
import { useReportEmployee } from "@/hooks/employee";


const TotalPunchesReport = () => {
  const {t} = useTranslation("report")
  const {t: per} = useTranslation("per")
  const employeeFields = useReportEmployee()
  const columns: GridColDef[] = [
    ...employeeFields,
    {
      field: "att_date",
      headerName: t("report.column.punchDate"),
      flex: 1
    },
    {
      field: "punch_times",
      headerName: t("report.column.punchTimes"),
      flex: 1
    },
    {
      field: "punch_set",
      headerName: t("report.column.punchTime"),
      flex: 1
    },
  ]

  return (
    <ReportView
      title={t("reportLibrary.totalPunches")}
      breadcrumbs={[]}
      columns={columns}
      uri={ReportEndPoint.TOTAL_PUNCHES_URI}
      url={urls.report.total_punches}
      getRowId={(row: any) => Math.random().toString(16).slice(2)}
      hideColumns={['first_name', 'last_name', 'department_code', 'position_code', 'position_name']}
    />
  )
}
export default TotalPunchesReport

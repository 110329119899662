import {RJSFSchema, UiSchema} from "@rjsf/utils";
import {useTranslation} from "react-i18next";
import validator from "@rjsf/validator-ajv8";
import LayoutForm from "@/components/rjsf/Form";
import React, {ForwardedRef, useImperativeHandle, useRef, useState,} from "react";
import SnackbarUtil from "@/utils/SnackbarUtil";
import TransactionServer from "@/services/device/TransactionService";
import usbUploadTransactionWidget from "./usbUploadTransactionWidget";

const TransactionUSBUpload = (
  props: Record<string, any>,
  ref: ForwardedRef<any>
) => {
  const {t} = useTranslation("device");
  const formRef = useRef<any>();
  const [formData, setFormData] = useState<Record<string, any>>({});

  const formSchema: RJSFSchema = {
    type: "object",
    required: ["upload_file"],
    properties: {
      upload_file: {
        type: "array",
        title: "upload file",
        description: t("transaction.usbUploadDescription"),
        items: {
          type: "string",
        },
      },
    },
  };

  const uiSchema: UiSchema = {
    "ui:defaultXsWidth": 12,
    "ui:submitButtonOptions": {
      norender: true,
    },
    upload_file: {
      "ui:widget": "usb-upload",
    },
  };

  const widgets = {
    "usb-upload": usbUploadTransactionWidget,
  };

  const onConfirm = async () => {
    const isValid = formRef.current?.validateForm();
    if (isValid) {
      const data = formRef.current?.state.formData;
      const response = await TransactionServer.usbUpload({
        ...data,
      });
      if (response.isSuccess) {
        SnackbarUtil.success(t("common.message.successful", {ns: "common"}));
        return true;
      } else {
        console.log(response);
      }
    }
  };

  useImperativeHandle(ref, () => ({
    onConfirm,
  }));

  return (
    <LayoutForm
      ref={formRef}
      schema={formSchema}
      uiSchema={uiSchema}
      validator={validator}
      formData={formData}
      widgets={widgets}
    />
  );
};
export default React.forwardRef(TransactionUSBUpload);

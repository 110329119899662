import { useTranslation } from "react-i18next";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import urls from "@/urls";
import { ReportEndPoint } from "@/services/report/endpoints";
import ReportView from "@/pages/Report/ReportLibrary/components/ReportView";
import { useWeekdayHook } from "@/hooks/optionsHooks";
import { useReportEmployee } from "@/hooks/employee";

const LateReport = () => {
  const weekdayOptions = useWeekdayHook()
  const { t } = useTranslation("report");
  const employeeFields = useReportEmployee()
  const columns: GridColDef[] = [
    ...employeeFields,
    {
      field: "att_date",
      headerName: t("report.column.scheduleDate"),
      flex: 1,
    },
    {
      field: "weekday",
      headerName: t("report.column.weekday"),
      flex: 1,
      type: "singleSelect",
      valueOptions: weekdayOptions,
    },
    {
      field: "timetable",
      headerName: t("report.column.timetable"),
      flex: 1,
    },
    {
      field: "check_in",
      headerName: t("report.column.checkIn"),
      flex: 1,
    },
    {
      field: "check_out",
      headerName: t("report.column.checkOut"),
      flex: 1,
    },
    {
      field: "clock_in",
      headerName: t("report.column.clockIn"),
      flex: 1,
    },
    {
      field: "clock_out",
      headerName: t("report.column.clockOut"),
      flex: 1,
    },
    {
      field: "total_time",
      headerName: t("report.column.totalTime"),
      flex: 1,
    },
    {
      field: "late",
      headerName: t("report.column.late"),
      flex: 1,
    },
  ];

  return (
    <ReportView
      title={t("reportLibrary.late")}
      breadcrumbs={[]}
      columns={columns}
      uri={ReportEndPoint.LATE_URI}
      url={urls.report.late}
      hideColumns={['first_name', 'last_name', 'department_code', 'position_code', 'position_name']}
    />
  );
};
export default LateReport;

import { MainIconButton } from "@/components/styled";
import {
  Badge,
  Box,
  Grid,
  ListItemText,
  Menu,
  MenuItem,
  Popover,
  Tooltip,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import {
  bindPopover,
  bindTrigger,
  usePopupState,
} from "material-ui-popup-state/hooks";
import {
  FormContextType,
  RJSFSchema,
  StrictRJSFSchema,
  TitleFieldProps,
  UiSchema,
} from "@rjsf/utils";
import { useRef, useState } from "react";
import LayoutForm from "@/components/rjsf/Form";
import BaseInputTemplate from "@/components/rjsf/templates/BaseInputTemplate";

function getFormSchema(config: any) {
  if (config.type === "string") {
    return {
      type: "object",
      title: config.label,
      properties: {
        field: {
          type: "string",
          title: "Field",
          oneOf: [{ const: config.key, title: config.label }],
        },
        operator: {
          type: "string",
          title: "Operator",
          oneOf: [
            { const: "contains", title: "Contains" },
            { const: "exact", title: "Exact" },
            { const: "startswith", title: "Startswith" },
            { const: "endswith", title: "Endswith" },
          ],
        },
        value: {
          type: "string",
          title: "Value",
        },
      },
    };
  } else if (config.type === "date") {
    return {
      type: "object",
      title: config.label,
      properties: {
        field: {
          type: "string",
          title: "Field",
          oneOf: [{ const: config.key, title: config.label }],
        },
        start: {
          type: "string",
          format: "date",
          title: "Start",
        },
        end: {
          type: "string",
          format: "date",
          title: "End",
        },
      },
    };
  } else if (config.type === "select") {
    return {
      type: "object",
      title: config.label,
      properties: {
        field: {
          type: "string",
          title: "Field",
          oneOf: [{ const: config.key, title: config.label }],
        },
        value: {
          type: [config.select_type, "null"],
          title: "Value",
          default: null,
          oneOf: [{const: null, title: '---------'}, ...config.select_options],
        },
      },
    };
  }
}

function getUiSchema(config: any) {
  if (config.type === "string") {
    return {
      "ui:layoutTemplate": FieldLayoutTemplate,
      field: {
        "ui:readonly": true,
      },
    };
  } else if (config.type === "date") {
    return {
      "ui:layoutTemplate": DateFieldTemplate,
      field: {
        "ui:readonly": true,
      },
    };
  } else if (config.type === "select") {
    return {
      "ui:layoutTemplate": SelectFieldTemplate,
      field: {
        "ui:readonly": true,
      },
    };
  }
}

function getInitData(config: any) {
  if (config.type === "string") {
    return { field: config.key, operator: "contains" }
  } else if (config.type === "date") {
    return { field: config.key };
  } else if (config.type === "select") {
    return { field: config.key, value: null }
  }
}



function TitleField<
  T = any,
  S extends StrictRJSFSchema = RJSFSchema,
  F extends FormContextType = any
>({ id, title }: TitleFieldProps<T, S, F>) {
  return (
    // <Box id={id} mb={1} mt={1}>
    //   <Typography variant='h5'>{title}</Typography>
    //   <Divider />
    // </Box>
    <></>
  );
}

const FieldLayoutTemplate = (props: Record<any, any>) => {
  const forms: Record<string, any> = {};
  props.elements.forEach((element: any) => {
    forms[element.name] = element.content;
  });
  return (
    <>
      <Grid item={true} xs={4}>
        {forms.field}
      </Grid>
      <Grid item={true} xs={4}>
        {forms.operator}
      </Grid>
      <Grid item={true} xs={4}>
        {forms.value}
      </Grid>
    </>
  );
};

const DateFieldTemplate = (props: Record<any, any>) => {
  const forms: Record<string, any> = {};
  props.elements.forEach((element: any) => {
    forms[element.name] = element.content;
  });
  return (
    <>
      <Grid item={true} xs={4}>
        {forms.field}
      </Grid>
      <Grid item={true} xs={4}>
        {forms.start}
      </Grid>
      <Grid item={true} xs={4}>
        {forms.end}
      </Grid>
    </>
  );
};

const SelectFieldTemplate = (props: Record<any, any>) => {
  const forms: Record<string, any> = {};
  props.elements.forEach((element: any) => {
    forms[element.name] = element.content;
  });
  return (
    <>
      <Grid item={true} xs={4}>
        {forms.field}
      </Grid>
      <Grid item={true} xs={8}>
        {forms.value}
      </Grid>
    </>
  );
};

const FiltersAction = (props: Record<any, any>) => {
  // const navigate = useNavigate()
  const { filters, filterModel, onFilterModelChange } = props;
  const popupState = usePopupState({
    variant: "popover",
    popupId: "FilterAction",
  });
  const formRef = useRef<any>();
  const onFormChange = async (data: any, id?: string) => {
    setFormData(data.formData);
  };

  const formSchema: any = {
    type: "object",
    properties: {},
  };
  const uiSchema: any = {
    "ui:submitButtonOptions": { norender: false },
  };
  const initData: any = {};

  const getFilterType = (key: string) => {
    const filter = filters.find((each: any) => each.key === key)
    if (filter) {
      return filter.type
    }
  }

  filters.forEach((element: any) => {
    formSchema.properties[element.key] = getFormSchema(element);
    uiSchema[element.key] = getUiSchema(element);
    initData[element.key] = getInitData(element);
  });
  const [formData, setFormData] = useState<Record<string, any>>(initData);
  const [badgeNumber, setBadgeNumber] = useState(0);

  return (
    <>
      <Tooltip title={"Filters"}>
        <MainIconButton {...bindTrigger(popupState)}>
          <Badge badgeContent={badgeNumber} color="primary">
            <FilterAltOutlinedIcon />
          </Badge>
        </MainIconButton>
      </Tooltip>
      <Popover
        {...bindPopover(popupState)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Box
          sx={{
            padding: "20px",
            width: "600px",
          }}
        >
          <LayoutForm
            ref={formRef}
            schema={formSchema}
            uiSchema={uiSchema}
            templates={{
              TitleFieldTemplate: TitleField,
              // BaseInputTemplate: BaseInputTemplate,
            }}
            formData={formData}
            onChange={onFormChange}
            onSubmit={(data) => {
              let newBadgeNumber = 0;
              const tempFilter: any = {};
              for (const i in data.formData) {
                const element = data.formData[i];
                getFilterType(element.field)
                const filter = filters.find((each: any) => each.key === element.field)
                if (filter) {
                  if (filter.type === 'string') {
                    if (element.value) {
                      let key = `${element.field}__${element.operator}`;
                      if (element.operator === 'exact') {
                        key = `${element.field}`;
                      }
                      tempFilter[key] = element.value;
                      newBadgeNumber = newBadgeNumber + 1;
                    }
                  } else if (filter.type === 'date') {
                    if (element.start || element.end) {
                      if (element.start) {
                        const key = `${element.field}__gte`;
                        tempFilter[key] = element.start;
                      }
                      if (element.end) {
                        const key = `${element.field}__lte`;
                        tempFilter[key] = element.end;
                      }
                      newBadgeNumber = newBadgeNumber + 1;
                    }
                  } else if (filter.type === 'select') {
                    if (element.value) {
                      const key = `${element.field}`;
                      tempFilter[key] = element.value;
                      newBadgeNumber = newBadgeNumber + 1;
                    }
                  }
                }
              }
              onFilterModelChange(tempFilter);
              setBadgeNumber(newBadgeNumber);
              popupState.close();
            }}
          />
        </Box>
      </Popover>
    </>
  );
};

export default FiltersAction;

import GenericService from "@/services/core/GenericService";
import {DeviceEndPoint} from "@/services/device/endpoints";


const getConfiguration = async () => {
  const response = await GenericService.get(DeviceEndPoint.CONFIG_URI)
  return response.data
}

const updateConfiguration = async (data: Record<string, any>, key: string) => {
  return await GenericService.patch(DeviceEndPoint.CONFIG_URI, key, data)
}

const ConfigurationService = {
  getConfiguration,
  updateConfiguration,
}
export default ConfigurationService

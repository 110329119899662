import { useRoutes } from "react-router-dom";
import OvertimeApprovalListView from "@/pages/Personal/my-approval/overtime/ListView";

const OvertimeApprovalRouter = () => {
  const routes = useRoutes([
    { index: true, element: <OvertimeApprovalListView /> },
  ]);
  return routes;
};
export default OvertimeApprovalRouter;

import { CustomValidator, RJSFSchema, UiSchema } from "@rjsf/utils";
import urls from "@/urls";
import { DeviceEndPoint } from "@/services/device/endpoints";
import ImportView from "@/components/views/ImportView";
import { useTranslation } from "react-i18next";


const BioPhotoImportView = () => {
  const { t } = useTranslation("common");

  const formSchema: RJSFSchema = {
    "type": "object",
    "required": [
      "import_file",
      "overwrite",
      "ignore_error",
    ],
    "properties": {
      "import_file": {
        "type": "array",
        "title": t("common.button.upload"),
        "items": {
          "type": "string",
        },
      },

      "overwrite": {
        "title": t("import.overwrite"),
        "type": "boolean",
        "default": false,
        "oneOf": [
          {const: 0, title: t("boolean.no")},
          {const: 1, title: t("boolean.yes")},
        ]
      },
      "ignore_error": {
        "title": t("common.import.ignore.error"),
        "type": "boolean",
        "default": false,
        "oneOf": [
          {const: 0, title: t("boolean.no"),},
          {const: 1, title: t("boolean.yes")},
        ]
      }
    }
  };


  const uiSchema: UiSchema = {
    "import_file": {
      'ui:widget': 'multi-photo',
      'ui:xsWidth': 6,
    },
    "overwrite": {
      "ui:widget": "select",
      'ui:xsWidth': 4,
    },
    "ignore_error": {
      "ui:widget": "select",
      'ui:xsWidth': 4,
    },
  }

  const notice = [
    '1. The name of photo must be Employee ID.',
    '2.The employee photo must be .jpg, .jpeg, .png and .bmp format.',
  ]

  return (
    <ImportView
      breadcrumbs={[
        { title: t("menu.device.biophoto"), href: urls.device.bioPhoto }
      ]}
      formSchema={formSchema}
      uiSchema={uiSchema}
      uri={DeviceEndPoint.BIO_PHOTO_URI}
      notice={notice}
      hideExtraInfo={true}
    />
  )
}
export default BioPhotoImportView

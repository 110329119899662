import {Input, InputAdornment, TextField} from "@mui/material";
import {WidgetProps} from "@rjsf/utils";


const QuantifierWidget = (props: WidgetProps) => {
  const {
    id,
    name,
    value,
    label,
    placeholder,
    onChange,
    formContext,
    readonly,
    disabled,
    required,
    uiSchema,
    options
  } = props
  return (
    <TextField
      fullWidth
      required={required}
      id={id}
      name={name}
      value={value}
      label={label}
      slotProps={{
        input: {
          readOnly: readonly,
          disabled,
          placeholder,
          endAdornment: <InputAdornment position={"end"}>{`${options.unit}`}</InputAdornment>
        }
      }}
      onChange={async (event) => {
        onChange(event.target.value)
      }}
      onKeyUp={(event) => {
        if (event.code === "Enter") {
          formContext.onSubmit?.()
        }
      }}
    />
  )
}
export default QuantifierWidget

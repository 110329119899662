import {RJSFSchema, UiSchema} from "@rjsf/utils";
import {useTranslation} from "react-i18next";
import validator from "@rjsf/validator-ajv8";
import LayoutForm from "@/components/rjsf/Form";
import React, {ForwardedRef, useImperativeHandle, useRef} from "react";

import SnackbarUtil from "@/utils/SnackbarUtil";
import GenericService from "@/services/core/GenericService";

const RevokeAction = (props: Record<string, any>, ref: ForwardedRef<any>) => {
  const {t} = useTranslation("common");
  const {t: api} = useTranslation("api");
  const formRef = useRef<any>();
  const formSchema: RJSFSchema = {
    type: "object",
    properties: {
      remarks: {
        type: "string",
        title: t("approval.remark"),
        maxLength: 200
      },
    },
  };

  const uiSchema: UiSchema = {
    "ui:defaultXsWidth": 12,
    remarks: {
      "ui:widget": "textarea",
    },
  };

  const onConfirm = async () => {
    const isValid = formRef.current?.validateForm();
    if (isValid) {
      const selectedIds = props.selectedIds;
      const data = formRef.current?.state.formData;
      const response = await GenericService.action(props.uri, props.action, {
        [props.objectKey || "objects"]: selectedIds,
        ...data,
      });
      if (response.isSuccess) {
        SnackbarUtil.success(t("common.message.successful"));
        return true;
      }
    }
  };
  useImperativeHandle(ref, () => ({
    onConfirm,
  }));

  return (
    <LayoutForm
      ref={formRef}
      schema={formSchema}
      uiSchema={uiSchema}
      validator={validator}
    />
  );
};

export default React.forwardRef(RevokeAction);

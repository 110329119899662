import {useTranslation} from "react-i18next";
import {RJSFSchema, UiSchema} from "@rjsf/utils";
import {PsnlEP} from "@/services/psnl/endpoints";
import RecordView from "@/components/views/RecordView";
import urls from "@/urls";
import {AttEndPoint} from "@/services/att/endpoints";
import {ComplementEndpoint} from "@/services/complement/endpoints";
import {AccessControlEndPoint} from "@/services/acc/endpoints";


const CommandRecordView = () => {
  const {t} = useTranslation("acc");
  const formSchema: RJSFSchema = {
    type: "object",
    properties: {
      area: {
        type: "integer",
        title: t("area"),
      },
      holiday: {
        type: "integer",
        title: t("holiday"),
      },
      timezone: {
        type: "integer",
        title: t("holiday.timePeriod"),
      },
    },
    required: ["area", "holiday", "timezone"]
  };

  const uiSchema: UiSchema = {
    'ui:defaultXsWidth': 4,
    area: {
      "ui:widget": "m2o",
      "ui:options": {
        uri: PsnlEP.AREA_URI
      }
    },
    holiday: {
      "ui:widget": "m2o",
      "ui:options": {
        uri: ComplementEndpoint.HOLIDAY_URI
      }
    },
    timezone: {
      "ui:widget": "m2o",
      "ui:options": {
        uri: AccessControlEndPoint.TIME_PERIOD_URI
      }
    }
  };
  return (
    <RecordView
      breadcrumbs={[
        {title: t("menu.accessControl"), href: urls.acc.root},
        {title: t("menu.acc.holiday"), href: urls.acc.holiday},
      ]}
      formSchema={formSchema}
      uiSchema={uiSchema}
      uri={AccessControlEndPoint.HOLIDAY_URI}
    />
  )
}
export default CommandRecordView

import ListView from "@/components/views/ListView";
import { GridColDef } from "@mui/x-data-grid";
import AppUrls from "@/urls";
import { useTranslation } from "react-i18next";
import { AttEndPoint } from "@/services/att/endpoints";

const DepartmentRuleListView = () => {
  const { t } = useTranslation("att");
  const displayName = (value: any) => value?.display_name;
  const columns: GridColDef[] = [
    {
      field: "department",
      valueFormatter: (value: any, row: any) => row.department.dept_name,
      headerName: t("departmentWorkRule.department"),
      flex: 1,
    },
    { field: "alias", headerName: t("departmentWorkRule.alias"), flex: 1 },
  ];

  const filters = [
    {
      key: "department__dept_name",
      label: t("departmentWorkRule.department"),
      type: "string",
    },
    { key: "alias", label: t("departmentWorkRule.alias"), type: "string" },
  ];

  return (
    <ListView
      title={t("menu.att.departmentRule", { ns: "menu" })}
      columns={columns}
      uri={AttEndPoint.DEPT_ATT_RULE_URI}
      url={AppUrls.att.departmentRule}
      filters={filters}
    />
  );
};
export default DepartmentRuleListView;

import { WidgetProps } from "@rjsf/utils";
import { Avatar, Badge, Box, Button, Grid, IconButton, Paper, Stack, TableContainer, Typography, styled } from "@mui/material";
import React, { ChangeEvent, useCallback, useRef } from "react";
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';


function addNameToDataURL(dataURL: string, name: string) {
  if (dataURL === null) {
    return null;
  }
  return dataURL.replace(';base64', `;name=${encodeURIComponent(name)};base64`);
}

type FileInfoType = {
  dataURL?: string | null;
  name: string;
  size: number;
  type: string;
};

function processFile(file: File): Promise<FileInfoType> {
  const { name, size, type } = file;
  return new Promise((resolve, reject) => {
    const reader = new window.FileReader();
    reader.onerror = reject;
    reader.onload = (event) => {
      if (typeof event.target?.result === 'string') {
        resolve({
          dataURL: addNameToDataURL(event.target.result, name),
          name,
          size,
          type,
        });
      } else {
        resolve({
          dataURL: null,
          name,
          size,
          type,
        });
      }
    };
    reader.readAsDataURL(file);
  });
}

function processFiles(files: FileList) {
  return Promise.all(Array.from(files).map(processFile));
}

const AvatarWidget = (props: WidgetProps) => {
  const { name, label, onChange, options, value } = props
  const [filesInfos, setFilesInfos] = React.useState<FileInfoType[]>([]);
  const inputRef = useRef<any>()
  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (!event.target.files) {
        return;
      }
      processFiles(event.target.files).then((filesInfoEvent) => {
        setFilesInfos(filesInfoEvent);
        const newValue = filesInfoEvent.map((fileInfo) => fileInfo.dataURL);
        console.log('>>>>>>>>', newValue)
        onChange(newValue);
      });
    },
    [onChange]
  );

  return (
    <Box>
      <Stack direction={"row"} spacing={2}>
        <Button variant={"contained"} component={"label"}>
          {label}
          <input
            multiple
            hidden={true}
            type={"file"}
            accept={(options?.accept?.toString() || "image/png,image/jpeg")}
            name={name}
            onChange={handleChange}
            ref={inputRef}
          />
        </Button>
        <Button variant={"outlined"} onClick={async () => {
          onChange(undefined)
          setFilesInfos([]);
        }}>Reset</Button>
      </Stack>

      <TableContainer sx={{
        backgroundColor: "transparent", marginTop: "10px",
        height: "300px", border: '1px solid #e6e6e6'
      }} component={Box}>
        <Box sx={{ margin: "10px" }} display="grid" gridTemplateColumns="repeat(12, 1fr)" gap={1}>
          {filesInfos.map((item: any, index: any) => (
            <Box key={index} gridColumn="span 1">
              <Badge sx={{ '.MuiBadge-badge': { top: "12px", right: "10px" } }} badgeContent={
                <IconButton onClick={async () => {
                  onChange(value.filter((i: any) => i !== item.dataURL));
                  setFilesInfos(filesInfos.filter((i: any) => i !== item));
                }} aria-label="delete"><DeleteForeverIcon sx={{ color: "#f63c79", }} /></IconButton>
              }>
                <Avatar
                  variant={"square"}
                  sx={{ width: "96px", height: "96px" }}
                  src={item.dataURL}
                />
              </Badge>

            </Box>
          ))}
        </Box>
      </TableContainer>

    </Box>
  )
}
export default AvatarWidget

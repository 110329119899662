import { Route, Routes } from "react-router-dom";
import ListView from "./ListView";
import ImportView from "./ImportView";

const Router = () => {
  return (
    <Routes>
      <Route index element={<ListView />} />
      <Route path={"import"} element={<ImportView />}/>
    </Routes>
  );
};
export default Router;

import {RJSFSchema, UiSchema} from "@rjsf/utils";
import {useTranslation} from "react-i18next";
import RecordView from "@/components/views/RecordView";
import urls from "@/urls";
import {PsnlEP} from "@/services/psnl/endpoints";
import {AttEndPoint} from "@/services/att/endpoints";
import React, {useEffect} from "react";
import GenericService from "@/services/core/GenericService";


const LeaveScheduleRecordView = () => {
  const {t} = useTranslation("att");
  const [formSchema, setFormSchema] = React.useState<RJSFSchema>({
    "type": "object",
    "required": [
      'employee',
    ],
    "properties": {
      "employee": {
        "title": t("leaveSchedule.employee"),
        "type": "string",
        readOnly: true,
      },
    }
  })

  const uiSchema: UiSchema = {
    'ui:defaultXsWidth': 6,
    "employee": {
      "ui:widget": "m2o",
      "ui:options": {
        uri: PsnlEP.EMPLOYEE_URI
      }
    },
  };

  useEffect(() => {
    GenericService.list(AttEndPoint.LEAVE_TYPE_URI, {
      page: 1,
      page_size: 50,
    })
      .then((response) => {
        const properties: any = {}
        properties['employee'] = {
          "title": t("leaveSchedule.employee"),
          "type": "string",
          readOnly: true,
        }
        response.data.data.forEach((each: any) => {
          properties[`${each.category_name}_year`] = {
            "type": "integer",
            "title": each.category_name + ' ' + t("leave.yearly"),
            minimum: 0,
          }
          properties[`${each.category_name}_month`] = {
            "type": "integer",
            "title": each.category_name + ' ' + t("leave.monthly"),
            minimum: 0,
          }
        })
        setFormSchema((old) => {
          return {
            ...old,
            properties
          }
        })
      })

  }, [])


  return (
    <RecordView
      formSchema={formSchema}
      uiSchema={uiSchema}
      uri={AttEndPoint.LEAVE_SCHEDULE_URI}
    />
  )
}
export default LeaveScheduleRecordView

import { Button, IconButton } from '@mui/material';
import { alpha, styled } from '@mui/material/styles';


export const MainIconButton = styled(IconButton)({
  borderRadius: '6px',
  backgroundColor: "#fff",
});

export default {}

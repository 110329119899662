import { ChangeEvent, FocusEvent } from 'react';
import MenuItem from '@mui/material/MenuItem';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import {
  ariaDescribedByIds,
  labelValue,
  FormContextType,
  RJSFSchema,
  StrictRJSFSchema,
  WidgetProps,
} from '@rjsf/utils';

import {InputAdornment} from "@mui/material";
import {Business} from "@mui/icons-material";


/** The `CompanyDomainWidget` is a widget for rendering dropdowns.
 *  It is typically used with string properties constrained with enum options.
 *
 * @param props - The `WidgetProps` for this component
 */
export default function CompanyDomainWidget<
  T = any,
  S extends StrictRJSFSchema = RJSFSchema,
  F extends FormContextType = any
>({
  schema,
  id,
  name, // remove this from textFieldProps
  options,
  label,
  hideLabel,
  required,
  disabled,
  placeholder,
  readonly,
  value,
  multiple,
  autofocus,
  onChange,
  onBlur,
  onFocus,
  rawErrors = [],
  registry,
  uiSchema,
  hideError,
  formContext,
  InputLabelProps,
  ...textFieldProps
}: WidgetProps<T, S, F>) {

  multiple = typeof multiple === 'undefined' ? false : !!multiple;

  const emptyValue = multiple ? [] : '';

  const _onChange = ({ target: { value } }: ChangeEvent<HTMLInputElement>) =>
    onChange(value === '' ? options.emptyValue : value);
  const _onBlur = ({ target: { value } }: FocusEvent<HTMLInputElement>) => onBlur(id, value);
  const _onFocus = ({ target: { value } }: FocusEvent<HTMLInputElement>) => onFocus(id, value);

  let host = ''
  if (uiSchema) {
    host = uiSchema['ui:host']
  }

  return (
    <TextField
      id={id}
      name={id}
      label={labelValue(label || undefined, hideLabel, false)}
      value={value || value === 0 ? value : ''}
      required={required}
      disabled={disabled || readonly}
      autoFocus={autofocus}
      placeholder={placeholder}
      error={rawErrors.length > 0}
      InputProps={{
        ...InputLabelProps,
        startAdornment: <InputAdornment position={"start"}><Business/></InputAdornment>,
        endAdornment:<InputAdornment position={"end"}>.{host}</InputAdornment>
      }}

      onChange={_onChange}
      onBlur={_onBlur}
      onFocus={_onFocus}
      {...(textFieldProps as TextFieldProps)}
      aria-describedby={ariaDescribedByIds<T>(id)}
      {...uiSchema?.weightProps}
    >
    </TextField>
  );
}

import React from "react";
import {useRegionContext} from "@/provider/RegionProvider";
import MXSubscription from "@/pages/OnlineSubscription/MXSubscription";
import MESubscription from "@/pages/OnlineSubscription/MESubscription";


const OnlineSubscription = () => {
  const {region} = useRegionContext()
  return (
    <>
      {region?.identify_code === "me" && <MESubscription />}
      {region?.identify_code === "mx" && <MXSubscription />}
    </>
  )
}
export default OnlineSubscription

import {useTranslation} from "react-i18next";
import {GridColDef, GridRenderCellParams} from "@mui/x-data-grid";
import ListView from "@/components/views/ListView";
import AppUrls from "@/urls";
import urls from "@/urls";
import {AccessControlEndPoint} from "@/services/acc/endpoints";
import {
  AlarmOffOutlined,
  CancelOutlined,
  CheckCircleOutlined,
  DoNotDisturbAltOutlined,
  InfoOutlined,
  MeetingRoomOutlined,
  RoomPreferencesOutlined,
  SyncOutlined,
  UploadOutlined
} from "@mui/icons-material";
import {Switch} from "@mui/material";
import SnackbarUtil from "@/utils/SnackbarUtil";
import DeviceService from "@/services/acc/DeviceService";
import SyncSetup from "@/pages/AccessControl/Device/components/SyncSetup";


const DeviceListView = () => {
  const {t} = useTranslation("acc")
  const renderStatus = (params: GridRenderCellParams) => {
    switch (params.value) {
      case 0:
        return <DoNotDisturbAltOutlined color={"error"}/>
      case 1:
        return <CheckCircleOutlined sx={{color: "green", height: "48px"}}/>
      case 2:
        return <SyncOutlined sx={{color: "green", height: "48px"}}/>
      case 3:
        return <CancelOutlined color={"error"}/>
      case 4:
        return <UploadOutlined sx={{color: "green", height: "48px"}}/>
      default:
        return <InfoOutlined color={"warning"}/>
    }
  }

  const renderValidHoliday = (params: GridRenderCellParams) => {
    return <Switch checked={params.value}/>
  }

  const columns: GridColDef[] = [
    {field: "sn", headerName: t("device.sn"), flex: 1},
    {field: "alias", headerName: t("device.alias"), flex: 1},
    {field: "status", headerName: t("device.state"), flex: 1, renderCell: renderStatus},
    {field: "door_lock_delay", headerName: t("device.doorLockDelay"), flex: 1},
    {field: "door_sensor_delay", headerName: t("device.doorSensorDelay"), flex: 1},
    {field: "door_sensor_type", headerName: t("device.doorSensorType"), flex: 1,
      type: "singleSelect",
      valueOptions: [
        {value: 0, label: t("doorSensorType.normalOpen")},
        {value: 1, label: t("doorSensorType.normalClose")},
      ],
    },
    {field: "retry_times", headerName: t("device.retryTimes"), flex: 1},
    {field: "valid_holiday", headerName: t("device.validHoliday"), flex: 1, renderCell: renderValidHoliday},
  ]

  const filters = [
    {key: 'sn', label: t("device.sn"), type: 'string'},
    {key: 'alias', label: t("device.alias"), type: 'string'},
  ]

  const onOpenDoor = async (selectedIds: string) => {
    const response = await DeviceService.openDoor({
      objects: selectedIds,
    });
    if (response.isSuccess) {
      SnackbarUtil.success("Success");
      return true;
    } else {
      console.log(response);
      return false;
    }
  }

  const onCancelAlarm = async (selectedIds: string) => {
    const response = await DeviceService.cancelAlarm({
      devices: selectedIds,
    });
    if (response.isSuccess) {
      SnackbarUtil.success("Success");
      return true;
    } else {
      console.log(response);
      return false;
    }
  }

  const modelActions = [
    {
      id: "openDoor",
      title: t("device.openDoor"),
      icon: <MeetingRoomOutlined/>,
      display: "dialog",
      confirmation: t("device.openDoor.confirmation"),
      validateSelected: true,
      callback: onOpenDoor
    },
    {
      id: "cancelAlarm",
      title: t("device.cancelAlarm"),
      icon: <AlarmOffOutlined/>,
      display: "dialog",
      confirmation: t("device.cancelAlarm.confirmation"),
      validateSelected: true,
      callback: onCancelAlarm
    },
    {
      id: "bulkSetup",
      title: t("device.bulkSetup"),
      icon: <RoomPreferencesOutlined/>,
      display: "page",
      url: `${urls.acc.terminal}/bulk-setup`,
      validateSelected: true,
    },
    {
      id: "syncSetup",
      title: t("device.syncSetup"),
      icon: <SyncOutlined/>,
      display: "dialog",
      Form: SyncSetup,
      validateSelected: true,
    },
  ]

  return (
    <ListView
      title={t("menu.device", {ns: "menu"})}
      breadcrumbs={[
        {title: t("menu.accessControl", {ns: "menu"}), href: AppUrls.acc.root},
      ]}
      columns={columns}
      uri={AccessControlEndPoint.TERMINAL_URI}
      url={AppUrls.acc.terminal}
      filters={filters}
      disableAdd={true}
      disableDelete={true}
      modelActions={modelActions}
    />
  )
}
export default DeviceListView

import React from "react";
import {Button, Grid, List, ListItem, ListItemButton} from "@mui/material";
import {
  AdminPanelSettings,
  CalendarMonth,
  CalendarToday,
  DesignServices,
  Devices,
  EditCalendar,
  EventAvailable,
  EventBusy,
  FileCopy,
  FormatIndentDecrease,
  FormatIndentIncrease,
  Gavel,
  GroupsOutlined,
  History,
  IntegrationInstructions,
  LanOutlined,
  Message,
  People,
  SettingsOutlined,
  Storage,
  SettingsSuggest,
  BarChart,
  Business,
  RequestQuote,
  Approval,
  ShareLocation, ModeOfTravel
} from "@mui/icons-material";

import {useNavigate} from "react-router-dom";
import SettingsIcon from "@mui/icons-material/Settings";
import urls from "@/urls";

import {useAppContext} from "@/provider/AppProvider";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import ApprovalIcon from "@mui/icons-material/Approval";

import {useTranslation} from "react-i18next";
import CustomShrinkListItem from "@/layout/SideBar/components/CustomShrinkListItem";
import CustomListItem from "@/layout/SideBar/components/CustomListItem";
import StyledListItemIcon from "@/layout/SideBar/components/StyledListItemIcon";

const SideBar: React.FC = () => {
  const {t} = useTranslation("menu");
  const navigate = useNavigate();
  const [menuExpand, setMenuExpand] = React.useState<boolean>(false);

  const {viewApp} = useAppContext();
  const appMenus: Record<any, Record<string, any>[]> = {
    company: [
      {
        key: "setting",
        title: t("menu.company.information"),
        icon: <SettingsOutlined/>,
        subMenus: [
          {
            key: "setting",
            title: t("menu.company.settings"),
            url: urls.company.setting,
            icon: <SettingsOutlined/>,
          },
        ],
      },
      {
        key: "organization",
        title: t("menu.company.organization"),
        icon: <GroupsOutlined/>,
        subMenus: [
          {
            key: "department",
            title: t("menu.company.department"),
            url: urls.company.department,
          },
          {
            key: "position",
            title: t("menu.company.position"),
            url: urls.company.position,
          },
          {
            key: "area",
            title: t("menu.company.area"),
            url: urls.company.area,
          },
          {
            key: "location",
            title: t("menu.company.location"),
            url: urls.company.location,
          },
        ],
      },
      {
        key: "organizationChart",
        title: t("menu.company.organizationChart"),
        icon: <LanOutlined/>,
        subMenus: [
          {
            key: "orgChart",
            title: t("menu.company.organizationChart"),
            url: urls.company.organizationChart,
          },
        ]
      },
    ],
    complement: [
      {
        key: "profile",
        title: t("menu.profile"),
        icon: <SettingsOutlined/>,
        subMenus: [
          {
            key: "holiday",
            title: t("menu.holiday"),
            url: urls.complement.holiday,
          },
          {
            key: "employeeDoc",
            title: t("menu.employeeDocument"),
            url: urls.complement.documentType,
          },
          {
            key: "leaveType",
            title: t("menu.incidentType"),
            url: urls.complement.incidentType,
          },
          {
            key: "trainingType",
            title: t("menu.trainingType"),
            url: urls.complement.trainingType,
          },
          {
            key: "workCode",
            title: t("menu.workCode"),
            url: urls.complement.workCode,
          },
        ],
      },
    ],
    personal: [
      {
        key: "myCalendar",
        title: t("menu.personal.myCalendar"),
        icon: <CalendarMonth/>,
        subMenus: [
          {
            key: "calendar",
            title: t("menu.personal.myCalendar"),
            url: urls.personal.myCalendar.calendar,
          },
          {
            key: "holiday",
            title: t("menu.personal.holiday"),
            url: urls.personal.myCalendar.holiday,
          },
        ],
      },
      {
        key: "request",
        title: t("menu.personal.request"),
        icon: <EditCalendar/>,
        subMenus: [
          {
            key: "requestManualLog",
            title: t("menu.personal.manualLog"),
            url: urls.personal.request.manualLog,
          },
          {
            key: "requestLeave",
            title: t("menu.personal.leave"),
            url: urls.personal.request.leave,
          },
          {
            key: "requestOvertime",
            title: t("menu.personal.overtime"),
            url: urls.personal.request.overtime,
          },
          {
            key: "requestTraining",
            title: t("menu.personal.training"),
            url: urls.personal.request.training,
          },
        ],
      },
      {
        key: "approval",
        title: t("menu.personal.approval"),
        icon: <Approval/>,
        subMenus: [
          {
            key: "approvalManualLog",
            title: t("menu.personal.manualLog"),
            url: urls.personal.approval.manualLog,
          },
          {
            key: "approvalLeave",
            title: t("menu.personal.leave"),
            url: urls.personal.approval.leave,
          },
          {
            key: "approvalOvertime",
            title: t("menu.personal.overtime"),
            url: urls.personal.approval.overtime,
          },
          {
            key: "approvalTraining",
            title: t("menu.personal.training"),
            url: urls.personal.approval.training,
          },
          {
            key: "approvalSwapSchedule",
            title: t("menu.personal.swapSchedule"),
            url: urls.personal.approval.swapSchedule,
          },
        ],
      },
      {
        key: "outdoorManagement",
        title: t("menu.personal.outdoorManagement"),
        icon: <ModeOfTravel/>,
        subMenus: [
          {
            key: "mySchedule",
            title: t("menu.personal.mySchedule"),
            url: urls.personal.outdoorManagement.mySchedule,
          },
          {
            key: "scheduleReport",
            title: t("menu.personal.scheduleReport"),
            url: urls.personal.outdoorManagement.scheduleReport,
          },
          {
            key: "unscheduleReport",
            title: t("menu.personal.unscheduleReport"),
            url: urls.personal.outdoorManagement.unscheduleReport,
          },
        ]
      },
      {
        key: "report",
        title: t("menu.personal.report"),
        icon: <FileCopy/>,
        subMenus: [
          {
            key: "incidentDetailReport",
            title: t("report.incidentDetailReport", {ns: "report"}),
            url: urls.personal.report.incidentDetail,
          },
          {
            key: "transactionReport",
            title: t("menu.personal.transactionReport"),
            url: urls.personal.report.transaction,
          },
          {
            key: "staffScheduledLog",
            title: t("reportLibrary.scheduledLog", {ns: "report"}),
            url: urls.personal.report.scheduledLog,
          },
          {
            key: "staffTotalTimeCard",
            title: t("reportLibrary.totalTimecard", {ns: "report"}),
            url: urls.personal.report.totalTimeCard,
          },
          {
            key: "staffSummary",
            title: t("reportLibrary.employeeSummary", {ns: "report"}),
            url: urls.personal.report.staffSummary,
          },
          {
            key: "staffMultipleTransaction",
            title: t("reportLibrary.multiplePairPunch", {ns: "report"}),
            url: urls.personal.report.multipleTransaction,
          },
          {
            key: "staffBreakTime",
            title: t("report.breakTime", {ns: "report"}),
            url: urls.personal.report.breakTime,
          },
          {
            key: "staffTimeCard",
            title: t("report.timeCard", {ns: "report"}),
            url: urls.personal.report.timeCard,
          },
        ],
      },
    ],
    team: [
      {
        key: "employee",
        title: t("menu.team.employee"),
        icon: <People/>,
        subMenus: [
          {
            key: "employees",
            title: t("menu.team.employee"),
            url: urls.team.employee,
          },
          {
            key: "resign",
            title: t("menu.team.resign"),
            url: urls.team.resign,
          },
        ],
      },
      {
        key: "approvalWorkflow",
        title: t("menu.approvalWorkflow"),
        icon: <DesignServices/>,
        subMenus: [
          {
            key: "workflowRole",
            title: t("menu.workflowRole"),
            url: urls.team.workflowRole,
          },
          {
            key: "workflow",
            title: t("menu.workflow"),
            url: urls.team.workflow,
          },
          {
            key: "workflowNode",
            title: t("menu.workflowNode"),
            url: urls.team.workflowNode,
          },
        ],
      },
    ],
    device: [
      {
        key: "deviceManagement",
        title: t("menu.deviceManagement"),

        icon: <Devices/>,
        subMenus: [
          {key: "device", title: t("menu.device"), url: urls.device.terminal},
          {
            key: "deviceCommand",
            title: t("menu.device.command"),
            url: urls.device.command,
          },
        ],
      },
      {
        key: "deviceMessage",
        title: t("menu.device.message"),

        icon: <Message/>,
        subMenus: [
          {
            key: "devicePublicMessage",
            title: t("menu.device.publicMessage"),
            url: urls.device.publicMessage,
          },
          {
            key: "devicePrivateMessage",
            title: t("menu.device.privateMessage"),
            url: urls.device.privateMessage,
          },
        ],
      },
      {
        key: "deviceData",
        title: t("menu.device.data"),
        url: urls.device.transaction,

        icon: <Storage/>,
        subMenus: [
          {
            key: "biomimeticTemplate",
            title: t("menu.device.biomimeticTemplate"),
            url: urls.device.bioTemplate,
          },
          {
            key: "biomimeticPhoto",
            title: t("menu.device.biomimeticPhoto"),
            url: urls.device.bioPhoto,
          },
          {
            key: "deviceTransaction",
            title: t("menu.device.transaction"),
            url: urls.device.transaction,
          },
          {
            key: "unregisteredTransaction",
            title: t("menu.device.unregisteredTransaction"),
            url: urls.device.unregisteredTransaction,
          },
        ],
      },
      {
        key: "deviceLog",
        title: t("menu.device.log"),

        icon: <AccessTimeIcon/>,
        subMenus: [
          {
            key: "operationLog",
            title: t("menu.device.operationLog"),
            url: urls.device.operationLog,
          },
          {
            key: "errorLog",
            title: t("menu.device.errorLog"),
            url: urls.device.errorLog,
          },
          {
            key: "uploadLog",
            title: t("menu.device.uploadLog"),
            url: urls.device.uploadLog,
          },
        ],
      },
      {
        key: "deviceConfiguration",
        title: t("menu.configuration"),

        icon: <SettingsIcon/>,
        subMenus: [
          {
            key: "deviceConfigurations",
            title: t("menu.configuration"),
            url: urls.device.configuration,
          },
        ],
      },
    ],
    gps: [
      {
        key: "appSettings",
        title: t("menu.appSettings"),

        icon: <SettingsSuggest/>,
        subMenus: [
          {
            key: "accounts",
            title: t("menu.gps.accounts"),
            url: urls.gps.accounts,
          },
          {
            key: "announcement",
            title: t("menu.gps.announcement"),
            url: urls.gps.announcement,
          },
          {key: "notice", title: t("menu.gps.notice"), url: urls.gps.notice},
          {
            key: "operationLog",
            title: t("menu.gps.operationLog"),
            url: urls.gps.operationLog,
          },
        ],
      },
      {
        key: "geofence",
        title: t("menu.gps.geofence"),

        icon: <Message/>,
        subMenus: [
          {
            key: "geofenceOfEmployee",
            title: t("menu.gps.geofenceOfEmployee"),
            url: urls.gps.gpsOfEmployee,
          },
          {
            key: "geofenceOfDepartment",
            title: t("menu.gps.geofenceOfDepartment"),
            url: urls.gps.gpsOfDepartment,
          },
        ],
      },
      {
        key: "gpsTracking",
        title: t("menu.gps.gpsTracking"),

        icon: <ShareLocation/>,
        subMenus: [
          {
            key: "clientDetails",
            title: t("menu.gps.clientDetails"),
            url: urls.gps.clientDetails,
          },
          {
            key: "schedulePlanner",
            title: t("menu.gps.schedulePlanner"),
            url: urls.gps.schedulePlanner,
          },
          {
            key: "outdoorSchedule",
            title: t("menu.gps.outdoorSchedule"),
            url: urls.gps.outdoorSchedule,
          },
          {
            key: "scheduleReport",
            title: t("menu.gps.scheduleReport"),
            url: urls.gps.scheduleReport,
          },
          {
            key: "unscheduleReport",
            title: t("menu.gps.unscheduleReport"),
            url: urls.gps.unscheduleReport,
          },
        ],
      },
    ],
    att: [
      {
        key: "attRule",
        title: t("menu.att.rule"),

        icon: <Gavel/>,
        subMenus: [
          {
            key: "attGlobalRule",
            title: t("menu.att.globalRule"),
            url: urls.att.globalRule,
          },
          {
            key: "attDepartmentRule",
            title: t("menu.att.departmentRule"),
            url: urls.att.departmentRule,
          },
        ],
      },
      {
        key: "shiftManagement",
        title: t("menu.att.shift"),

        icon: <CalendarToday/>,
        subMenus: [
          {
            key: "attBreakTime",
            title: t("menu.att.breakTime"),
            url: urls.att.breakTime,
          },
          {
            key: "attTimetable",
            title: t("menu.att.timetable"),
            url: urls.att.timetable,
          },

          {key: "attShift", title: t("menu.att.shift"), url: urls.att.shift},
        ],
      },
      {
        key: "attSchedule",
        title: t("menu.att.schedule"),

        icon: <CalendarMonth/>,
        subMenus: [
          {
            key: "departmentSchedule",
            title: t("menu.att.departmentSchedule"),
            url: urls.att.departmentSchedule,
          },
          {
            key: "employeeSchedule",
            title: t("menu.att.employeeSchedule"),
            url: urls.att.employeeSchedule,
          },
          {
            key: "temporarySchedule",
            title: t("menu.att.temporarySchedule"),
            url: urls.att.temporarySchedule,
          },
          {
            key: "scheduleView",
            title: t("menu.att.scheduleView"),
            url: urls.att.scheduleView,
          },
          {
            key: "employeeCalendar",
            title: t("menu.att.employeeCalendar"),
            url: urls.att.employeeCalendar,
          },
        ],
      },
      {
        key: "approval",
        title: t("menu.att.approval"),

        icon: <ApprovalIcon/>,
        subMenus: [
          {
            key: "manualLog",
            title: t("menu.att.manualLog"),
            url: urls.att.manualLog,
          },

          {key: "leave", title: t("menu.att.leave"), url: urls.att.leave},
          {
            key: "overtime",
            title: t("menu.att.overtime"),
            url: urls.att.overtime,
          },
          {
            key: "training",
            title: t("menu.att.training"),
            url: urls.att.training,
          },
          {
            key: "scheduleAdjustment",
            title: t("menu.att.scheduleAdjustment"),
            url: urls.att.scheduleAdjustment,
          },
        ],
      },
      {
        key: "leaveManagement",
        title: t("menu.att.leaveManagement"),

        icon: <EventBusy/>,
        subMenus: [
          {
            key: "leaveSetting",
            title: t("menu.att.leaveSetting"),
            url: urls.att.leaveSetting,
          },
          {
            key: "leaveSchedule",
            title: t("menu.att.leaveSchedule"),
            url: urls.att.leaveSchedule,
          },
          {
            key: "leaveDetailReport",
            title: t("menu.att.leaveDetailReport"),
            url: urls.att.leaveDetailReport,
          },
        ],
      },
    ],
    report: [
      {
        key: "report",
        title: t("menu.report"),

        icon: <FileCopy/>,
        subMenus: [
          {
            key: "reportLibrary",
            title: t("menu.report.library"),
            url: urls.report.reportLibrary,
          },
          {
            key: "reportCalculate",
            title: t("menu.report.calculation"),
            url: urls.report.calculation,
          },
        ],
      },
      {
        key: "reportConfiguration",
        title: t("menu.configuration"),
        url: urls.device.configuration,

        icon: <SettingsIcon/>,
        subMenus: [
          {
            key: "reportSetting",
            title: t("menu.report.reportSetting"),
            url: urls.report.reportSetting,
          },
          {
            key: "punchStateSetting",
            title: t("menu.report.punchStateSetting"),
            url: urls.report.punchStateSetting,
          },
          {
            key: "reportItemsSetting",
            title: t("menu.report.reportItemsSetting"),
            url: urls.report.reportItemsSetting,
          },
        ],
      },
    ],
    acc: [
      {
        key: "accDeviceManagement",
        title: t("menu.deviceManagement"),

        icon: <Devices/>,
        subMenus: [
          {key: "accDevice", title: t("menu.device"), url: urls.acc.terminal},
          {
            key: "accDeviceCommand",
            title: t("menu.device.command"),
            url: urls.acc.command,
          },
        ],
      },
      {
        key: "accessControlManagement",
        title: t("menu.accessControlManagement"),

        icon: <SettingsIcon/>,
        subMenus: [
          {
            key: "accessControlTimePeriod",
            title: t("menu.acc.timePeriod"),
            url: urls.acc.timePeriod,
          },
          {
            key: "accessControlHoliday",
            title: t("menu.acc.holiday"),
            url: urls.acc.holiday,
          },
          {
            key: "accessControlGroup",
            title: t("menu.acc.group"),
            url: urls.acc.group,
          },
          {
            key: "accessControlCombination",
            title: t("menu.acc.combination"),
            url: urls.acc.combination,
          },
          {
            key: "accessControlPrivilege",
            title: t("menu.acc.privilege"),
            url: urls.acc.privilege,
          },
        ],
      },
    ],
    system: [
      {
        key: "authentication",
        title: t("menu.systemSetting.authentication"),

        icon: <AdminPanelSettings/>,
        subMenus: [
          {
            key: "group",
            title: t("menu.systemSetting.group"),
            url: urls.system.group,
          },
          {
            key: "user",
            title: t("menu.systemSetting.user"),
            url: urls.system.user,
          },
        ],
      },
      {
        key: "integration",
        title: t("menu.systemSetting.integration"),

        icon: <IntegrationInstructions/>,
        subMenus: [
          {
            key: "autoExport",
            title: t("menu.systemSetting.autoExport"),
            url: urls.system.autoExport,

            icon: <People/>,
          },
        ],
      },
      {
        key: "systemConfiguration",
        title: t("menu.configuration"),

        icon: <SettingsIcon/>,
        subMenus: [
          {
            key: "alertSetting",
            title: t("menu.systemSetting.alertSetting"),
            url: urls.system.alertSetting,
          },
          {
            key: "pdfSetting",
            title: t("menu.systemSetting.pdfSetting"),
            url: urls.system.pdfSetting,
          },
          {
            key: "emailSetting",
            title: t("menu.systemSetting.emailSetting"),
            url: urls.system.emailSetting,
          },
          {
            key: "whatsAppSetting",
            title: t("menu.systemSetting.whatsAppSetting"),
            url: urls.system.whatsAppSetting,
          },
        ],
      },
      {
        key: "operationLog",
        title: t("menu.systemSetting.operationLog"),

        icon: <History/>,
        subMenus: [
          {
            key: "operationLog",
            title: t("menu.systemSetting.operationLog"),
            url: urls.system.operationLog,
          },
        ],
      },
    ],
    vendor: [
      {
        key: "dashboard",
        title: t("menu.vendor.dashboard"),
        icon: <BarChart/>,
        subMenus: [
          {
            key: "vendorDashboard",
            title: t("menu.vendor.dashboard"),
            url: urls.vendor.dashboard,
          },
        ],
      },
      {
        key: "tenement",
        title: t("menu.vendor.tenement"),
        icon: <Business/>,
        subMenus: [
          {
            key: "company",
            title: t("menu.tenement.company"),
            url: urls.vendor.company,
          },
          {
            key: "device",
            title: t("menu.tenement.device"),
            url: urls.vendor.device,
          },
          {
            key: "user",
            title: t("menu.tenement.user"),
            url: urls.vendor.user,
          },
          {
            key: "employee",
            title: t("menu.tenement.employee"),
            url: urls.vendor.employee,
          },
          {
            key: "mobileApp",
            title: t("menu.tenement.mobileApp"),
            url: urls.vendor.mobileApp,
          },
        ],
      },
      {
        key: "subscription",
        title: t("menu.vendor.subscription"),
        icon: <RequestQuote/>,
        subMenus: [
          {
            key: "order",
            title: t("menu.subscription.order"),
            url: urls.vendor.order,
          },
          {
            key: "priceStrategy",
            title: t("menu.subscription.priceStrategy"),
            url: urls.vendor.priceStrategy,
          },
          {
            key: "discount",
            title: t("menu.subscription.discount"),
            url: urls.vendor.discount,
          },
          {
            key: "feature",
            title: t("menu.subscription.feature"),
            url: urls.vendor.feature,
          },
        ],
      },
    ],
    ralvie: [
      {
        key: "ralvieAI",
        title: t("menu.plugins.ralvie"),
        icon: (
          <img
            src={"/images/plugins/ralvie.png"}
            style={{width: "24px", height: "24px"}}
          />
        ),
        subMenus: [
          {
            key: "dashboard",
            title: t("menu.ralvie.dashboard"),
            url: urls.ralvie.dashboard,
          },
          {
            key: "productivitySummary",
            title: t("menu.ralvie.productivitySummary"),
            url: urls.ralvie.productivitySummary,
          },
          {
            key: "configuration",
            title: t("menu.ralvie.configuration"),
            url: urls.ralvie.configuration,
          },
        ],
      },
    ],
  };
  const MenuItemView = menuExpand ? CustomShrinkListItem : CustomListItem;
  const onMenuItemClick = async (menu: Record<string, any>) => {
    if (menu.url) {
      navigate(menu.url);
    }
  };
  return (
    <>
      {appMenus[viewApp] && (
        <Grid
          sx={{
            display: "flex",
            backgroundColor: "#fff",
            margin: "8px",
            borderRadius: "4px",
            minWidth: menuExpand ? "auto" : "208px",
            overflowY: "auto",
            padding: "8px 0",
          }}
        >
          <List component={"nav"} sx={{width: "100%"}}>
            <ListItemButton
              sx={{pl: 1, pr: 1}}
              onClick={async () => {
                setMenuExpand(!menuExpand);
              }}
            >
              <StyledListItemIcon>
                {menuExpand ? (
                  <FormatIndentIncrease/>
                ) : (
                  <FormatIndentDecrease/>
                )}
              </StyledListItemIcon>
            </ListItemButton>
            {appMenus[viewApp]?.map((appMenu) => {
              return (
                <MenuItemView
                  key={appMenu.key}
                  menu={appMenu}
                  onMenuItemClick={onMenuItemClick}
                />
              );
            })}
          </List>
        </Grid>
      )}
    </>
  );
};
export default SideBar;

import {RJSFSchema, UiSchema} from "@rjsf/utils";
import {useTranslation} from "react-i18next";
import RecordView from "@/components/views/RecordView";
import {PsnlEP} from "@/services/psnl/endpoints";
import {AttEndPoint} from "@/services/att/endpoints";


const DepartmentScheduleRecordView = () => {
  const {t} = useTranslation("att");
  const {t: common  } = useTranslation("common");
  const formSchema: RJSFSchema = {
    "type": "object",
    "required": [
      "department",
      "start_date",
      "end_date",
      "shift",
    ],
    "properties": {
      "department": {
        "title": t("schedule.department"),
        "type": "array",
        "minItems": 1,
        "items": {
          "type": "number",
        },
      },
      "start_date": {
        "type": "string",
        "format": "date",
        "title": t("schedule.startDate"),
      },
      "end_date": {
        "type": "string",
        "format": "date",
        "title": t("schedule.endDate"),
      },
      "shift": {
        "type": ["number"],
        "title": t("schedule.shift"),
      },
      "schedule_cover": {
        "type": ["boolean"],
        "title": t("schedule.overwrite"),
      },
    }
  };

  function dateValidate(formData: any, errors: any, uiSchema: any) {
    if (formData.start_date > formData.end_date) {
      errors.end_date.addError(common("common.message.startDateInvalid"));
    }
    return errors;
  }

  const uiSchema: UiSchema = {
    'ui:defaultXsWidth': 6,
    "shift": {
      "ui:widget": "m2o",
      "ui:options": {
        uri: AttEndPoint.ATT_SHIFT_URI
      }
    },
    "department": {
      "ui:widget": "m2m",
      "ui:options": {
        uri: PsnlEP.DEPARTMENT_URI
      }
    },
  };
  return (
    <RecordView
      formSchema={formSchema}
      uiSchema={uiSchema}
      uri={AttEndPoint.DEPT_SCHEDULE_URI}
      customValidate={dateValidate}
    />
  )
}
export default DepartmentScheduleRecordView

import { useTranslation } from "react-i18next";
import { GridColDef } from "@mui/x-data-grid";
import { ReportEndPoint } from "@/services/report/endpoints";
import urls from "@/urls";
import ReportView from "@/pages/Report/ReportLibrary/components/ReportView";
import { useReportEmployee } from "@/hooks/employee";


const DailyAttendanceReport = () => {
  const { t } = useTranslation("report")
  const dangerouslySetInnerHTML = (params: any) => {
    return <div dangerouslySetInnerHTML={{ __html: params.value }} />
  }
  const employeeFields = useReportEmployee()
  const columns: GridColDef[] = [
    ...employeeFields,
    { field: "att_date", headerName: t("report.column.scheduleDate") },
    { field: "timetable_name", headerName: t("report.column.timetable") },
    { field: "duration", headerName: t("report.column.duration") },
    { field: "clock_in", headerName: t("report.column.clockIn") },
    { field: "clock_out", headerName: t("report.column.clockOut") },
    { field: "actual_wt", headerName: t("report.column.actualWorkTime") },
    { field: "total_ot", headerName: t("report.column.totalOvertime") },
    { field: "normal_wt", headerName: t("report.column.normalWorkTime") },
    { field: "weekend_ot", headerName: t("report.column.weekOffOT") },
    { field: "holiday_ot", headerName: t("report.column.holidayOT") },
    { field: "total_worked", headerName: t("report.column.totalWorkTime") },
    { field: "attendance_status", headerName: t("report.column.attendanceStatus"), renderCell: dangerouslySetInnerHTML },
  ]
  return (
    <ReportView
      title={t("reportLibrary.dailyAttendance")}
      breadcrumbs={[]}
      columns={columns}
      uri={ReportEndPoint.DAILY_ATTENDANCE_URI}
      url={urls.report.dailyAttendance}
      getRowId={(row: any) => row.uuid}
      hideColumns={['first_name', 'last_name', 'department_code', 'position_code', 'position_name']}
    />
  )
}
export default DailyAttendanceReport

import {useTranslation} from "react-i18next";
import React, {useEffect, useMemo, useState} from "react";
import {RJSFSchema, UiSchema} from "@rjsf/utils";
import UsernameWidget from "@/pages/Oauth/components/UsernameWidget";
import CompanyWidget from "@/pages/Oauth/components/CompanyWidget";
import {Box, Button, Checkbox, FormControlLabel} from "@mui/material";
import LayoutForm from "@/components/rjsf/Form";
import PasswordWidget from "@/pages/Oauth/components/PasswordWidget";
import {useLocation, useNavigate} from "react-router-dom";
import urls from "@/urls";
import Layout from "@/pages/Oauth/Layout";
import OauthService from "@/services/oauth/OauthService";
import SnackbarUtil from "@/utils/SnackbarUtil";
import SubmitButton from "@/pages/Oauth/components/SubmitButton";
import Utils from "@/utils";
import i18n from "@/i18n";
import {useMuiThemeContext} from "@/provider/MuiThemeProvider";
import dayjs from "dayjs";


const SignInForm = () => {
  const {t} = useTranslation("oauth");
  const {setDirection} = useMuiThemeContext()
  const navigate = useNavigate()
  const params = useLocation()
  const [loading, setLoading] = useState<boolean>(false)
  const [rememberPassword, setRememberPassword] = useState<boolean>(false)
  const searchParams = new URLSearchParams(params.search);
  const payload = useMemo(() => {
    try {
      const code = searchParams.get("code")
      const decryptCode = Utils.aesDecrypt(code || "")
      return JSON.parse(decryptCode)
    } catch (e) {
      return {}
    }
  }, [searchParams])
  const [formData, setFormData] = useState<Record<string, any>>({username: payload.username, company: payload.company})
  const formRef = React.useRef<any>()
  const formSchema: RJSFSchema = {
    type: "object",
    properties: {
      username: {
        type: "string",
        title: t("oauth.username"),
      },
      company: {
        type: "string",
        title: t("oauth.companyName"),
      },
      password: {
        type: "string",
        title: t("oauth.password")
      }
    },
    required: ["username", "company", "password"]
  }
  const uiSchema: UiSchema = {
    username: {
      "ui:widget": "username",
      "ui:disabled": true,
      "ui:placeholder": t("oauth.username"),
    },
    company: {
      "ui:widget": "company",
      "ui:placeholder": t("oauth.company"),
      "ui:disabled": true,
      'ui:host': payload.company ? location.host.replace(payload.company + '.', '') : ""
    },
    password: {
      "ui:widget": "password",
      "ui:placeholder": t("oauth.password"),
    }
  }
  const widgets = {
    username: UsernameWidget,
    company: CompanyWidget,
    password: PasswordWidget,
  }
  const onSignIn = async () => {
    setLoading(true)
    const isValid = formRef.current?.validateForm()
    if (isValid) {
      const data = formRef.current?.state.formData
      OauthService.signIn(data).then((response) => {
        if (response.isSuccess) {
          localStorage.setItem('accessToken', response.data.token)
          localStorage.setItem('company_code', data["company"])
          if (rememberPassword) {
            const storage = {
              token: Utils.aesEncrypt(data["password"]),
              expiredDate: dayjs().add(7, "day")
            }
            localStorage.setItem(data["username"], JSON.stringify(storage))
          } else {
            localStorage.removeItem(data["username"])
          }
          navigate("/")
        }
      }).finally(() => {
        setLoading(false)
      })
    }else{
      setLoading(false)
    }
  }

  const onForgotPassword = async () => {
    const response = await OauthService.forgotPassword({username: payload.username, company: payload.company})
    if (response.isSuccess) {
      SnackbarUtil.success(t("oauth.sendEmail.successful"))
      navigate(urls.forgotPassword, {
        state: {
          email: response.data?.email,
          company: payload.company,
          action: "FORGOT_PASSWORD"
        }
      })
    }
  }

  useEffect(() => {
    if (!payload.username || !payload.company) {
      navigate(urls.companyVerification)
    }
    if (payload.lng) {
      localStorage.setItem("i18nextLng", payload.lng);
      i18n.changeLanguage(payload.lng).then(() => {
        setDirection(payload.lng === "ar" ? "rtl" : "ltr")
      })
    }
    if (payload.username) {
      const storage = localStorage.getItem(payload.username)
      if (storage) {
        const data = JSON.parse(storage)
        const expiredDate = dayjs(data["expiredDate"])
        const token = Utils.aesDecrypt(data["token"])
        if (token && expiredDate.isAfter(dayjs())) {
          setFormData({...formData, password: token})
          setRememberPassword(true)
        } else {
          setRememberPassword(false)
        }
      }
    }
  }, [])

  return (
    <Layout title={t("oauth.signIn.title")}>
      <LayoutForm
        ref={formRef}
        schema={formSchema}
        uiSchema={uiSchema}
        widgets={widgets}
        formData={formData}
        formContext={{
          onSubmit: onSignIn
        }}
      />
      <Box
        sx={{
          display: "flex",
          width: "100%"
        }}
      >
        <FormControlLabel
          control={<Checkbox
            checked={rememberPassword}
            onChange={(event) => setRememberPassword(event.target.checked)}
          />}
          label={t("oauth.rememberPassword")}
          sx={{
            flexGrow: 1
          }}
        />
        <Button sx={{textTransform: "uppercase"}} onClick={onForgotPassword}>
          {t("oauth.forgotPassword")}
        </Button>
      </Box>
      <SubmitButton
        loading={loading}
        onClick={onSignIn}
      >
        {t("oauth.signIn")}
      </SubmitButton>
    </Layout>
  )
}
export default SignInForm

import GenericService from "@/services/core/GenericService";
import {DeviceEndPoint} from "@/services/device/endpoints";


const usbUpload = async (data: Record<string, any>) => {
  return await GenericService.action(DeviceEndPoint.TRANSACTION_URI, 'upload_usb_transaction', data)
}

const TransactionService = {
  usbUpload,
}
export default TransactionService

import {useRef} from "react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import {Chip, Tooltip, useTheme} from "@mui/material";
import FullCalendar from "@fullcalendar/react";
import ModelActionLayout from "@/components/views/components/ModelActionLayout";
import GenericService from "@/services/core/GenericService";
import {PersonalEndPoint} from "@/services/personal/endpoints";
import dayjs from "dayjs";


const CalendarView = () => {
  const theme = useTheme()
  const calendarRef = useRef<any>()
  const getEvents = async (params: Record<string, any>) => {
    const response = await GenericService.action(PersonalEndPoint.SCHEDULE_URI, "my_calendar", {
      start_date: dayjs(params.start).format("YYYY-MM-DD"),
      end_date: dayjs(params.end).format("YYYY-MM-DD"),
    })
    return response.data.events
  }
  return (
    <ModelActionLayout
      title={"My Calendar"}
    >
      <FullCalendar
        ref={calendarRef}
        plugins={[dayGridPlugin, interactionPlugin]}
        initialView={"dayGridMonth"}
        headerToolbar={{
          start: "title",
          center: "",
          end: "prev,next"
        }}
        height={"100%"}
        selectable={true}
        eventColor={"#fff"}
        eventContent={(params) => {
          const event: any = params.event
          let backgroundColor = theme.palette.primary.main
          switch (event.extendedProps?.day_off) {
            case 2:
              backgroundColor = "green"
              break
            case 3:
              backgroundColor = "#a93ac4"
              break
            default:
              backgroundColor = theme.palette.primary.main
              break
          }
          return (
            <Tooltip title={event.title}>
              <Chip
                label={event.title}
                sx={{
                  color: "#fff",
                  width: "100%",
                  backgroundColor
                }}
              />
            </Tooltip>
          )
        }}
        events={getEvents}
      />
    </ModelActionLayout>
  )
}
export default CalendarView

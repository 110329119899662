import {RJSFSchema, UiSchema} from "@rjsf/utils";
import {useTranslation} from "react-i18next";
import validator from "@rjsf/validator-ajv8";
import LayoutForm from "@/components/rjsf/Form";
import React, {ForwardedRef, useImperativeHandle, useRef} from "react";
import SnackbarUtil from "@/utils/SnackbarUtil";
import DeviceService from "@/services/device/DeviceService";


const DuplicatePunchPeriodAction = (props: Record<string, any>, ref: ForwardedRef<any>) => {
  const {t} = useTranslation('device')
  const formRef = useRef<any>()

  const formSchema: RJSFSchema = {
    "type": "object",
    "required": [
      "duplicate_punch_period",
    ],
    "properties": {
      "duplicate_punch_period": {
        "type": "number",
        "title": t("duplicate.punch.period"),
        default: 1
      },
    }
  }

  const uiSchema: UiSchema = {
    'ui:defaultXsWidth': 12,
    "ui:submitButtonOptions": {
      "norender": true,
    },
  };

  const onConfirm = async () => {
    const isValid = formRef.current?.validateForm()
    if (isValid) {
      const selectedIds = props.selectedIds
      const data = formRef.current?.state.formData
      const response = await DeviceService.duplicatePunchPeriod({ids: selectedIds, ...data})
      if (response.isSuccess) {
        SnackbarUtil.success(t("common.successful", {ns: "common"}))
        return true
      } else {
        console.log(response)
      }
    }
  }

  useImperativeHandle(ref, () => ({
    onConfirm
  }))

  return (
    <LayoutForm
      ref={formRef}
      schema={formSchema}
      uiSchema={uiSchema}
      validator={validator}
    />
  )
}
export default React.forwardRef(DuplicatePunchPeriodAction)

import { ObjectFieldTemplateProps } from "@rjsf/utils";
import { Box, Stack } from "@mui/material";
import { useTranslation } from "react-i18next";
import Grid from '@mui/material/Grid2';
import { ScrollTab, ScrollTabs, useScrollTabsHook } from "@/components/ScrollTabs";


const LayoutTemplate = (props: ObjectFieldTemplateProps) => {
  const { t } = useTranslation()
  const fields: Record<string, any> = {}
  props.properties?.forEach((element: any) => { fields[element.name] = element.content })

  return (
    <Stack sx={{width: "80%"}} spacing={2}>
      <Grid container spacing={2}>
        <Grid size={6}>{fields.alias}</Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid size={3}>{fields.auto_shift}</Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid size={3}>{fields.work_weekend}</Grid>
      </Grid>
      <Grid container spacing={2} sx={{marginTop: "32px"}}>
        <Grid size={12}>{fields.pattern}</Grid>
      </Grid>
    </Stack>

  )
}
export default LayoutTemplate

import {IconButton, InputAdornment, TextField} from "@mui/material";
import {LockOutlined, Visibility, VisibilityOff} from "@mui/icons-material";
import {WidgetProps} from "@rjsf/utils";
import {useState} from "react";


const PasswordWidget = (props: WidgetProps) => {
  const {id, name, label, value, onChange, formContext, placeholder} = props
  const [visibility, setVisibility] = useState<boolean>(false)
  return (
    <TextField
      fullWidth
      id={id}
      name={name}
      value={value}
      // label={label}
      type={visibility? "text": "password"}
      sx={{

      }}
      InputProps={{
        placeholder,
        startAdornment: <InputAdornment position={"start"}><LockOutlined/></InputAdornment>,
        endAdornment: <InputAdornment position={"end"}>
          <IconButton tabIndex={-1} onClick={async () => {
            setVisibility(!visibility)
          }}>
            {visibility? <Visibility />: <VisibilityOff/>}
          </IconButton>
        </InputAdornment>
      }}
      onChange={async (event) => {
        onChange(event.target.value)
      }}
      onKeyUp={(event) => {
        if (event.code === "Enter") {
          formContext.onSubmit?.()
        }
      }}
    />
  )
}
export default PasswordWidget

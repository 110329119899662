import {WidgetProps} from "@rjsf/utils";
import {IconButton, InputAdornment, TextField, Tooltip} from "@mui/material";
import {LocationOn} from "@mui/icons-material";
import {useMapContext} from "@/provider/MapProvider";


const LocationWidget = (props: WidgetProps) => {
  const {showMapView} = useMapContext()
  const {label, value, required, readonly, onChange} = props
  return (
    <Tooltip title={value}>
      <TextField
        label={label}
        value={value}
        required={required}
        slotProps={{
          inputLabel: {
            shrink: true
          },
          input: {
            readOnly: true,
            endAdornment: <InputAdornment position={"end"}>
              <IconButton color={"primary"} onClick={showMapView}>
                <LocationOn/>
              </IconButton>
            </InputAdornment>
          },
        }}
      />
    </Tooltip>
  )
}
export default LocationWidget

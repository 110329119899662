import { RJSFSchema, UiSchema } from "@rjsf/utils";


export const useExcelFormParams = (wiseFields: Array<string>): any => {
  // const wiseFields = ['Default', 'employee_code', 'department_code', 'att_date']

  const defaultData: Record<string, any> = {
    scope: 'cur',
    page_size: 'Default',
    orientation: 'Default',
    encryption: 'Default',
  }

  const formSchema: any = {
    "type": "object",
    "required": [
      "scope",
      "page_size",
      "orientation",
      "encryption",
    ],
    "properties": {
      "type": {
        "type": "string",
        "title": "Export Type",
        default: 'xls',
        "oneOf": [
          { const: 'xls', title: "Excel" },
        ],
      },
      "scope": {
        "type": "string",
        "title": "Export Scope",
        "oneOf": [
          { const: 'cur', title: "Current Page" },
          { const: 'all', title: "All" },
        ],
      },
      // "encryption": {
      //   "type": "string",
      //   "title": "Export Encryption",
      //   default: 'Default',
      //   "oneOf": [
      //     { const: 'Default', title: "Default" },
      //     { const: 'Password', title: "Password" },
      //   ],
      // },
    },
    dependencies: {
      encryption: {
        oneOf: [
          {
            properties: {
              encryption: {
                enum: ["Password"]
              },
              password: {title: "Password", type: "string"},
            },
            required: ['password']
          },
          {
            properties: {
              encryption: {
                enum: ["Default"]
              },
            },
          },
        ]
      }
    }
  }

  if (wiseFields.length) {

    const baseWiseFields = [
      { const: 'Default', title: "Default" },
      { const: 'employee_code', title: "Employee Wise" },
      { const: 'department_code', title: "Department Wise" },
      { const: 'att_date', title: "Date Wise" },
    ]
    formSchema.properties.export_style = {
      "type": "string",
      "title": "Export Style",
      default: 'Default',
      "oneOf": baseWiseFields.filter((each) => wiseFields.indexOf(each.const) !== -1),
    }
    formSchema.properties.page_wise = {
      "type": "boolean",
      "title": "Page Wise",
      default: false,
    }
  }

  const uiSchema: UiSchema = {
    'ui:defaultXsWidth': 12,
    "ui:submitButtonOptions": {
      "norender": true,
    },
    "type": {
      'ui:disabled': true,
    },
    "scope": {
    },
    "page_wise": {
      'ui:widget': 'select',
    }
  };

  return {
    defaultData,
    formSchema,
    uiSchema,
  }
}


export const usePdfFormParams = (wiseFields: Array<string>): any => {
  const defaultData: Record<string, any> = {
    scope: 'cur',
    page_size: 'Default',
    orientation: 'Default',
    encryption: 'Default',
  }

  const formSchema: any = {
    "type": "object",
    "required": [
      "scope",
      "page_size",
      "orientation",
      "encryption",
    ],
    "properties": {
      "type": {
        "type": "string",
        "title": "Export Type",
        default: 'pdf',
        "oneOf": [
          { const: 'pdf', title: "PDF" },
        ],
      },
      "scope": {
        "type": "string",
        "title": "Export Scope",
        "oneOf": [
          { const: 'cur', title: "Current Page" },
          { const: 'all', title: "All" },
        ],
      },
      "page_size": {
        "type": "string",
        "title": "Page Size",
        "oneOf": [
          { const: "Default", title: "Default" },
          { const: "Auto", title: "Auto" },
          { const: "A0", title: "A0" },
          { const: "A1", title: "A1" },
          { const: "A2", title: "A2" },
          { const: "A3", title: "A3" },
          { const: "A4", title: "A4" },
          { const: "A5", title: "A5" },
          { const: "B0", title: "B0" },
          { const: "B1", title: "B1" },
          { const: "B2", title: "B2" },
          { const: "B3", title: "B3" },
          { const: "B4", title: "B4" },
          { const: "B5", title: "B5" },
        ],
      },
      "orientation": {
        "type": "string",
        "title": "Orientation",
        "oneOf": [
          { const: 'Default', title: "Default" },
          { const: 'Portrait', title: "Portrait" },
          { const: 'Landscape', title: "Landscape" },
        ],
      },
      // "encryption": {
      //   "type": "string",
      //   "title": "Export Encryption",
      //   "oneOf": [
      //     { const: 'Default', title: "Default" },
      //     { const: 'Password', title: "Password" },
      //   ],
      // },
    },
    dependencies: {
      encryption: {
        oneOf: [
          {
            properties: {
              encryption: {
                enum: ["Password"]
              },
              password: {title: "Password", type: "string"},
            },
            required: ['password']
          },
          {
            properties: {
              encryption: {
                enum: ["Default"]
              },
            },
          },
        ]
      }
    }
  }

  if (wiseFields.length) {

    const baseWiseFields = [
      { const: 'Default', title: "Default" },
      { const: 'employee_code', title: "Employee Wise" },
      { const: 'department_code', title: "Department Wise" },
      { const: 'att_date', title: "Date Wise" },
    ]
    formSchema.properties.export_style = {
      "type": "string",
      "title": "Export Style",
      default: 'Default',
      "oneOf": baseWiseFields.filter((each) => wiseFields.indexOf(each.const) !== -1),
    }
    formSchema.properties.page_wise = {
      "type": "boolean",
      "title": "Page Wise",
      default: false,
    }
  }

  const uiSchema: UiSchema = {
    'ui:defaultXsWidth': 12,
    "ui:submitButtonOptions": {
      "norender": true,
    },
    "type": {
      'ui:disabled': true,
    },
    "scope": {
    },
    "page_wise": {
      'ui:widget': 'select',
    }
  };

  return {
    defaultData,
    formSchema,
    uiSchema,
  }
}


export const useCsvFormParams = (): any => {
  const defaultData: Record<string, any> = {
    scope: 'cur',
  }
  const formSchema: RJSFSchema = {
    "type": "object",
    "required": [
      "scope",
    ],
    "properties": {
      "type": {
        "type": "string",
        "title": "Export Type",
        default: 'csv',
        "oneOf": [
          { const: 'csv', title: "CSV" },
        ],
      },
      "scope": {
        "type": "string",
        "title": "Export Scope",
        "oneOf": [
          { const: 'cur', title: "Current Page" },
          { const: 'all', title: "All" },
        ],
      },
    },
  }

  const uiSchema: UiSchema = {
    'ui:defaultXsWidth': 12,
    "ui:submitButtonOptions": {
      "norender": true,
    },
    "type": {
      'ui:disabled': true,
    },
    "scope": {
    },
  };

  return {
    defaultData,
    formSchema,
    uiSchema,
  }
}


export const useTxtFormParams = (): any => {
  const defaultData: Record<string, any> = {
    scope: 'cur',
  }
  const formSchema: RJSFSchema = {
    "type": "object",
    "required": [
      "scope",
    ],
    "properties": {
      "type": {
        "type": "string",
        "title": "Export Type",
        default: 'txt',
        "oneOf": [
          { const: 'txt', title: "TXT" },
        ],
      },
      "scope": {
        "type": "string",
        "title": "Export Scope",
        "oneOf": [
          { const: 'cur', title: "Current Page" },
          { const: 'all', title: "All" },
        ],
      },
    },
  }

  const uiSchema: UiSchema = {
    'ui:defaultXsWidth': 12,
    "ui:submitButtonOptions": {
      "norender": true,
    },
    "type": {
      'ui:disabled': true,
    },
    "scope": {
    },
  };

  return {
    defaultData,
    formSchema,
    uiSchema,
  }
}


export const useExportFormParams = (exportType: string, wiseFields: Array<string>): any => {
  if (exportType === 'excel') {
    return useExcelFormParams(wiseFields)
  } else if (exportType === 'pdf') {
    return usePdfFormParams(wiseFields)
  } else if (exportType === 'txt') {
    return useTxtFormParams()
  } else if (exportType === 'csv') {
    return useCsvFormParams()
  }
}


export default {
  useExcelFormParams,
  usePdfFormParams,
  useTxtFormParams,
  useCsvFormParams,
  useExportFormParams,
}

import {useTranslation} from "react-i18next";
import {RJSFSchema, UiSchema} from "@rjsf/utils";
import RecordView from "@/components/views/RecordView";
import urls from "@/urls";
import {VendorEndPoint} from "@/services/vendor/endpoints";
import RecordViewLayout from "@/pages/vendor/tenement/Company/components/RecordViewLayout";


const CompanyRecordView = () => {
  const {t} = useTranslation("vendor");
  const {t: menu} = useTranslation("menu");
  const formSchema: RJSFSchema = {
    "type": "object",
    "properties": {
      "subscription_start_date": {
        "type": "string",
        "title": t("company.subscriptionStartDate"),
        "format": "datetime"
      },
      "subscription_end_date": {
        "type": "string",
        "title": t("company.subscriptionEndDate"),
        "format": "datetime"
      },
      "max_employees_limit": {
        "type": "number",
        "title": t("company.employeeLimit"),
        "default": 999999
      },
      "max_devices_limit": {
        "type": "number",
        "title": t("company.deviceLimit"),
        "default": 999999
      },
      "max_transactions_limit": {
        "type": "number",
        "title": t("company.transactionLimit"),
        "default": 999999
      },
      "max_mobile_applications_limit": {
        "type": "number",
        "title": t("company.mobileAppLimit"),
        "default": 999999
      },
      "max_whatsapp_push_limit": {
        "type": "number",
        "title": t("company.whatsappLimit"),
        "default": 999999
      },
      "feature": {
        "type": "array",
        "title": t("company.feature"),
        "items": {
          "type": "number",
        },
      }

    },

  }

  const uiSchema: UiSchema = {
    'ui:defaultXsWidth': 4,
    "feature": {
      "ui:widget": "m2m",
      "ui:options": {
        uri: VendorEndPoint.FEATURE_URI,
        style: {height: "250px"}
      }
    },

  };

  const templates = {
    ObjectFieldTemplate: RecordViewLayout
  }

  return (
    <RecordView
      breadcrumbs={[
        {title: t("menu.tenement"), href: urls.tenement.root},
        {title: t("menu.tenement.company"), href: urls.tenement.company},
      ]}
      formSchema={formSchema}
      uiSchema={uiSchema}
      uri={VendorEndPoint.COMPANY_URI}
      templates={templates}
    />
  )
}
export default CompanyRecordView

import {Box, Button, CircularProgress, IconButton, Typography} from "@mui/material";
import {Stack} from "@mui/system";
import {Close, Domain, DomainAdd} from "@mui/icons-material";
import {useAppContext} from "@/provider/AppProvider";
import UserService from "@/services/auth/UserService";
import JoinCompany from "@/layout/Header/components/JoinCompany";
import CreateCompany from "@/layout/Header/components/CreateCompany";
import JoinAsVendor from "@/layout/Header/components/JoinAsVendor";
import {useTranslation} from "react-i18next";
import confirm from "@/components/dialog/confirm";
import SnackbarUtil from "@/utils/SnackbarUtil";
import utils from "@/utils";
import urls from "@/urls";
import {useDialogContext} from "@/provider/DialogProvider";
import Grid from "@mui/material/Grid2";
import {useEffect, useState} from "react";


const SwitchCompany = (props: Record<string, any>) => {
  const {t} = useTranslation("sys")
  const {currentUser, currentCompany} = useAppContext()
  const [memberships, setMemberships] = useState<Record<string, any>[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const dialog = useDialogContext()
  const onJoinCompany = async () => {
    dialog.showDialog({
      title: t("account.joinCompany"),
      Form: JoinCompany
    })
  }
  const onJoinAsVendor = async () => {
    dialog.showDialog?.({
      title: t("account.joinAsVendor"),
      Form: JoinAsVendor
    })
  }
  const onCreateCompany = async () => {
    dialog.showDialog?.({
      title: t("account.createCompany"),
      Form: CreateCompany
    })
  }

  const onSwitchCompany = async (company: Record<string, any>) => {
    confirm({
      description: utils.formatString(t("account.switchCompany.confirmation"), company.name),
      onConfirm: async () => {
        const response = await UserService.switchCompany(currentUser?.id, {company: company.id})
        if (response.isSuccess) {
          localStorage.setItem("accessToken", response.data.token)
          SnackbarUtil.success(t("common.message.successful", {ns: "common"}))
          window.location.replace(urls.root)
          return true
        } else {
          console.log(response)
          return false
        }
      }
    })
  }

  const companyActions = [
    {id: "joinCompany", title: t("account.joinCompany"), icon: <Domain/>, callback: onJoinCompany},
    // {id: "joinAsVendor", title: t("account.AdminPanelSettings"), icon: <Domain/>, callback: onJoinAsVendor},
    {id: "createCompany", title: t("account.createCompany"), icon: <DomainAdd/>, callback: onCreateCompany},
  ]

  useEffect(() => {
    if (currentUser) {
      setLoading(true)
      UserService.getJoinedCompany(currentUser?.id).then(res => {
        setMemberships(res?.data)
      }).finally(() => {
        setLoading(false)
      })
    }
  }, [])

  return (
    <Box sx={{height: "100%"}}>
      <Stack direction={"row"}>
        <Box sx={{flex: 1, p: 1}}>
          <Typography variant={"h4"}>
            {t("account.switchCompany")}
          </Typography>
        </Box>
        <IconButton onClick={props.onClose}><Close/></IconButton>
      </Stack>
      <Grid container sx={{width: "296px", p: 2, height: "calc(100% - 40px)", flexDirection: "column"}}>
        {loading
          ? <Box
              sx={{
                flex: 1, mb: 4,
                overflowY: "auto",
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
              }}>
            <CircularProgress />
            </Box>
          : <Stack sx={{flex: 1, mb: 4, overflowY: "auto"}} spacing={1}>
            {memberships?.map((membership: any) => {
              return (
                <Button
                  key={membership.id}
                  variant={currentCompany?.id === membership.company.id ? "contained" : "outlined"}
                  fullWidth
                  startIcon={<Domain/>}
                  endIcon={<Typography>{`(${membership.role === 2 ? "V" : "U"})`}</Typography>}
                  sx={{justifyContent: "start"}}
                  onClick={async () => {
                    await onSwitchCompany(membership.company)
                  }}
                >
                  <Typography sx={{textTransform: "uppercase"}}>{membership.company?.name}</Typography>
                </Button>
              )
            })}
          </Stack>
        }
        <Stack sx={{width: "100%"}} spacing={1}>
          {companyActions.map((action) => (
            <Button
              key={action.id}
              variant={"contained"}
              fullWidth
              startIcon={action.icon}
              sx={{justifyContent: "start"}}
              onClick={action.callback}
            >
              {action.title}
            </Button>
          ))}
        </Stack>
      </Grid>
    </Box>
  )
}
export default SwitchCompany

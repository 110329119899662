import {CustomValidator, RJSFSchema, UiSchema} from "@rjsf/utils";
import {useTranslation} from "react-i18next";
import RecordView from "@/components/views/RecordView";
import {ComplementEndpoint} from "@/services/complement/endpoints";
import {PsnlEP} from "@/services/psnl/endpoints";
import RegExps from "@/utils/RegExps";


const HolidayRecordView = () => {
  const {t} = useTranslation("complement");
  const {t: common} = useTranslation("common");
  const formSchema: RJSFSchema = {
    "type": "object",
    properties: {
      alias: {
        type: "string",
        title: t("holiday.name"),
        maxLength: 50
      },
      location: {
        type: "integer",
        title: t("holiday.location"),
      },
      start_date: {
        type: "string",
        title: t("holiday.startDate"),
      },
      duration_day: {
        type: "integer",
        title: t("holiday.durationDay"),
      },
      work_type: {
        type: "integer",
        title: t("holiday.workType"),
        oneOf: [
          {const: 0, title: t("workType.ignore")},
          {const: 1, title: t("workType.normalWorkTime")},
          {const: 2, title: t("workType.weekdayOvertime")},
          {const: 3, title: t("workType.weekOffOvertime")},
          {const: 4, title: t("workType.holidayOvertime")},
        ],
        default: 0
      },
      overtime_lv1: {
        type: "number",
        title: t("holiday.overtimeLevel1"),
        default: 0
      },
      overtime_lv2: {
        type: "number",
        title: t("holiday.overtimeLevel2"),
        default: 0
      },
      overtime_lv3: {
        type: "number",
        title: t("holiday.overtimeLevel3"),
        default: 0
      }
    },
    required: [
      "alias", "location", "start_date", "duration_day", "work_type",
      "overtime_lv1", "overtime_lv2", "overtime_lv3"
    ]
  };

  const uiSchema: UiSchema = {
    'ui:defaultXsWidth': 4,
    "ui:submitButtonOptions": {"norender": true},
    location: {
      "ui:widget": "m2o",
      "ui:options": {
        uri: PsnlEP.LOCATION_URI
      }
    },
    start_date: {
      "ui:widget": "date"
    },
    overtime_lv1: {
      "ui:widget": "hour"
    },
    overtime_lv2: {
      "ui:widget": "hour"
    },
    overtime_lv3: {
      "ui:widget": "hour"
    }
  }

  const customValidate: CustomValidator = (formData, errors, uiSchema) => {
    if (formData.alias && !RegExps.alpInput.test(formData.alias)) {
      errors.alias?.addError(common("form.validateError.alpInputOnly"))
    }

    return errors
  }


  return (
    <RecordView
      formSchema={formSchema}
      uiSchema={uiSchema}
      uri={ComplementEndpoint.HOLIDAY_URI}
      // customValidate={customValidate}
    />
  )
}
export default HolidayRecordView

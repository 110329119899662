import React, {ForwardedRef, useImperativeHandle, useRef} from "react";
import {useTranslation} from "react-i18next";
import {CustomValidator, RJSFSchema, UiSchema} from "@rjsf/utils";
import LayoutForm from "@/components/rjsf/Form";
import SnackbarUtil from "@/utils/SnackbarUtil";
import DeviceService from "@/services/device/DeviceService";
import dayjs from "dayjs";


const ClearPunches = (props: Record<string, any>, ref: ForwardedRef<any>) => {
  const {t: t} = useTranslation("device");
  const {t: common} = useTranslation("common");
  const formRef = useRef<any>()
  const formSchema: RJSFSchema = {
    type: "object",
    properties: {
      start_date: {
        type: "string",
        title: t("device.uploadTransaction.startDate"),
      },
      start_time: {
        type: "string",
        title: t("device.uploadTransaction.startTime"),
        default: "00:00"
      },
      end_date: {
        type: "string",
        title: t("device.uploadTransaction.endDate"),
      },
      end_time: {
        type: "string",
        title: t("device.uploadTransaction.endTime"),
        default: "00:00"
      },
      clear_all: {
        type: "boolean",
        title: t("device.clearPunches.clearAll"),
        default: false
      }
    },
  }

  const uiSchema: UiSchema = {
    'ui:defaultXsWidth': 6,
    "ui:defaultRowWrap": false,
    "ui:defaultWrap": false,
    start_date: {
      "ui:widget": "date",
    },
    start_time: {
      "ui:widget": "time",
    },
    end_date: {
      "ui:widget": "date"
    },
    end_time: {
      "ui:widget": "time"
    }
  }

  const customValidate: CustomValidator = (formData, errors, uiSchema) => {
    const startDtm = dayjs(`${formData.start_date} ${formData.start_time}`)
    const endDtm = dayjs(`${formData.end_date} ${formData.end_time}`)
    if (endDtm <= startDtm) {
      errors.end_date?.addError(t("device.uploadTransaction.periodError"))
      errors.end_time?.addError(t("device.uploadTransaction.periodError"))
    }
    return errors
  }

  const onConfirm = async () => {
    const isValid = formRef.current?.validateForm()
    if (isValid) {
      const data = formRef.current?.state.formData
      const response = await DeviceService.bulkClearPunches({...data, devices: props.selectedIds})
      if (response.isSuccess) {
        SnackbarUtil.success(common("common.successful"))
        return true
      } else {
        console.log(response)
        return false
      }
    }
  }

  useImperativeHandle(ref, () => ({
    onConfirm
  }))

  return (
    <LayoutForm
      ref={formRef}
      schema={formSchema}
      uiSchema={uiSchema}
      customValidate={customValidate}
    />
  )
}
export default React.forwardRef(ClearPunches)

import {TeamEP} from './endpoints';
import GenericService from "@/services/core/GenericService";

const assignEmployee = async (data: Record<string, any>) => {
  const response = await GenericService.action(TeamEP.WORKFLOW_ROLE_URI, "assign_employee", data)
  return response
}


const RoleService = {
  assignEmployee
}
export default RoleService

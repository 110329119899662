import {RJSFSchema, UiSchema} from "@rjsf/utils";
import {useTranslation} from "react-i18next";
import validator from "@rjsf/validator-ajv8";
import LayoutForm from "@/components/rjsf/Form";
import React, {ForwardedRef, useImperativeHandle, useRef} from "react";
import SnackbarUtil from "@/utils/SnackbarUtil";
import DeviceService from "@/services/device/DeviceService";


const EnrollRemotelyAction = (props: Record<string, any>, ref: ForwardedRef<any>) => {
  const {t} = useTranslation("device")
  const formRef = useRef<any>()

  const formSchema: RJSFSchema = {
    "type": "object",
    "required": [
      "bio_type",
      "emp_code",
    ],
    "properties": {
      "bio_type": {
        "type": "number",
        "title": t("enrollRemotely.bioType"),
        "oneOf": [
          {const: 1, title: t("enrollRemotely.bioType.fingerprint")},
          {const: 2, title: t("enrollRemotely.bioType.face")},
          {const: 9, title: t("enrollRemotely.bioType.bioPhoto")},
        ],
        default: 1
      },
      "emp_code": {
        "type": "string",
        "title": t("Employee ID"),
        default: ""
      },
    },
    dependencies: {
      bio_type: {
        oneOf: [
          {
            properties:{
              bio_type: {
                enum: [1]
              },
              "finger": {
                "type": "number",
                "title": t("Finger"),
                "oneOf": [
                  {const: 0, title: t('enrollRemotely.finger.leftHandLittle')},
                  {const: 1, title: t('enrollRemotely.finger.leftHandRing')},
                  {const: 2, title: t('enrollRemotely.finger.leftHandMiddle')},
                  {const: 3, title: t('enrollRemotely.finger.leftHandFore')},
                  {const: 4, title: t('enrollRemotely.finger.leftHandThumb')},
                  {const: 5, title: t('enrollRemotely.finger.rightHandThumb')},
                  {const: 6, title: t('enrollRemotely.finger.rightHandFore')},
                  {const: 7, title: t('enrollRemotely.finger.rightHandMiddle')},
                  {const: 8, title: t('enrollRemotely.finger.rightHandRing')},
                  {const: 9, title: t('enrollRemotely.finger.rightHandLittle')},
                ],
                default: 0
              },
            },
            required: ["finger"]
          }
        ]
      }
    }
  }

  const uiSchema: UiSchema = {
    'ui:defaultXsWidth': 12,
  };

  const onConfirm = async () => {
    const isValid = formRef.current?.validateForm()
    if (isValid) {
      const selectedIds = props.selectedIds
      const data = formRef.current?.state.formData
      const response = await DeviceService.enrollRemotely({ids: selectedIds, ...data})
      if (response.isSuccess) {
        SnackbarUtil.success(t("common.successful", {ns: "common"}))
        return true
      } else {
        console.log(response)
      }
    }
  }

  useImperativeHandle(ref, () => ({
    onConfirm
  }))

  return (
    <LayoutForm
      ref={formRef}
      schema={formSchema}
      uiSchema={uiSchema}
      validator={validator}
    />
  )
}
export default React.forwardRef(EnrollRemotelyAction)

import {PsnlEP} from "@/services/psnl/endpoints";
import {RegistryWidgetsType, RJSFSchema, UiSchema} from "@rjsf/utils";
import {useTranslation} from "react-i18next";
import RecordView from "@/components/views/RecordView";
import urls from "@/urls";
import {TeamEP} from "@/services/team/endpoints";
import NodeWidget from "@/pages/Team/Flow/components/NodeWidget";


const FlowRecordView = () => {
  const {t} = useTranslation("per");
  const formSchema: RJSFSchema = {
    "type": "object",
    "required": [
      "start_date",
      "end_date",
      "workflow_code",
      "workflow_name",
      "content_type"
    ],
    "properties": {
      "start_date": {
        "type": "string",
        "format": "date",
        "title": t("workflow.startDate"),
      },
      "end_date": {
        "type": "string",
        "format": "date",
        "title": t("workflow.endDate"),
      },
      "workflow_code": {
        "type": "string",
        "title": t("workflow.code"),
        "maxLength": 50
      },
      "workflow_name": {
        "type": "string",
        "title": t("workflow.name"),
        "maxLength": 50
      },
      "content_type": {
        "type": "number",
        "title": t("workflow.contentType"),
      },
      "employee": {
        "type": "array",
        "title": t("workflow.requester"),
        "items": {
          "type": "string",
        },
      },
      "applicant_position": {
        "type": ["number", "null"],
        "title": t("workflow.position"),
      },
      "departments": {
        "type": ["number", "null"],
        "title": t("workflow.department"),
      },
      "nodes": {
        "type": "array",
        "required": ["node_code", "node_name"],
        "items": {
          "properties": {
            "node_code": {
              "type": "string",
              "title": t("node.code"),
              "maxLength": 30
            },
            "node_name": {
              "type": "string",
              "title": t("node.name"),
              "maxLength": 30
            },
          }
        }
      }
    }
  };

  const uiSchema: UiSchema = {
    "ui:defaultXsWidth": 6,
    "ui:defaultRowWrap": false,
    "ui:submitButtonOptions": {"norender": true},
    "departments": {
      "ui:widget": "m2o",
      "ui:options": {
        uri: PsnlEP.DEPARTMENT_URI
      }
    },
    "employee": {
      "ui:widget": "m2m",
      "ui:options": {
        uri: PsnlEP.EMPLOYEE_URI
      }
    },
    "applicant_position": {
      "ui:widget": "m2o",
      "ui:options": {
        uri: PsnlEP.POSITION_URI
      }
    },
    "content_type": {
      "ui:widget": "m2o",
      "ui:options": {
        uri: TeamEP.CONTENT_TYPE_URI
      }
    },
    "nodes": {
      "ui:widget": "category",
      "ui:xsWidth": 12
    }
  };

  const widgets: RegistryWidgetsType = {
    category: NodeWidget
  }

  return (
    <RecordView
      breadcrumbs={[
        {title: t("menu.team", {ns: "menu"}), href: urls.team.root},
        {title: t("menu.workflow", {ns: "menu"}), href: urls.team.workflow},
      ]}
      formSchema={formSchema}
      uiSchema={uiSchema}
      widgets={widgets}
      uri={TeamEP.WORKFLOW_FLOW_URI}
    />
  )
}
export default FlowRecordView

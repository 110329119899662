import {useTranslation} from "react-i18next";
import {ObjectFieldTemplateProps} from "@rjsf/utils";
import Grid from "@mui/material/Grid2";
import {Typography} from "@mui/material";


const GroupRecordViewLayout = (props: ObjectFieldTemplateProps) => {
  const {t} = useTranslation("acc")
  const fields: Record<string, any> = {}
  props.properties?.forEach((element: any) => {
    fields[element.name] = element.content
  })

  return (
    <Grid container spacing={2}>
      <Grid size={4}>{fields.area}</Grid>
      <Grid size={8} />
      <Grid size={4}>{fields.group_no}</Grid>
      <Grid size={4}>{fields.group_name}</Grid>
      <Grid size={4} />
      <Grid size={4}>{fields.timezone1}</Grid>
      <Grid size={4}>{fields.timezone2}</Grid>
      <Grid size={4} />
      <Grid size={4}>{fields.timezone3}</Grid>
      <Grid size={8} />
      <Grid size={4}>{fields.verify_mode}</Grid>
      <Grid size={8} />
      <Grid size={4}>{fields.is_include_holiday}</Grid>
    </Grid>
  )
}
export default GroupRecordViewLayout

import {useRoutes} from "react-router-dom";
import SwapScheduleListView from "@/pages/Attendance/swap_schedule/ListView";


const SwapScheduleRouter = () => {
  return useRoutes([
    {index: true, element: <SwapScheduleListView />},
  ])
}
export default SwapScheduleRouter

import {WidgetProps} from "@rjsf/utils";
import {Autocomplete, TextField} from "@mui/material";
import {useTranslation} from "react-i18next";


const EmployeeIDArrayWidget = (props: WidgetProps) => {
  const {t} = useTranslation("device")
  const {label, required} = props
  return (
    <Autocomplete
      fullWidth
      freeSolo
      multiple
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            multiline
            maxRows={4}
            required={required}
            label={label}
            helperText={t("device.uploadUserData.helpTxt")}
          />
        )
      }}
      options={[]}
      onChange={(event, value, reason, details) => {
        console.log("onChange", value)
        props.onChange(value)
      }}
    />
  )
}
export default EmployeeIDArrayWidget

import { useTranslation } from "react-i18next";
import { GridColDef } from "@mui/x-data-grid";
import ListView from "@/components/views/ListView";
import AppUrls from "@/urls";
import { DeviceEndPoint } from "@/services/device/endpoints";
import SyncAltIcon from "@mui/icons-material/SyncAlt";
import SendWorkCodeAction from "./components/SendWorkCodeAction";
import RemoveWorkCodeAction from "./components/RemoveWorkCodeAction";

const WorkCodeListView = () => {
  const { t } = useTranslation();
  const columns: GridColDef[] = [
    { field: "code", headerName: t("workCode.code"), flex: 1 },
    { field: "alias", headerName: t("workCode.alias"), flex: 1 },
    {
      field: "last_activity",
      headerName: t("workCode.lastActivity"),
      flex: 1,
    },
  ];

  const filters = [{ key: "code", label: t("workCode.code"), type: "string" }];

  const actions = [
    {
      key: "workCodeAction",
      title: t("workCode.action"),
      icon: <SyncAltIcon />,
      subs: [
        {
          key: "sendWorkCodeToDevice",
          title: t("workCode.sendWorkCodeToDevice"),
          validateSelected: true,
          display: "dialog",
          Form: SendWorkCodeAction,
        },
        {
          key: "removeWorkCodeToDevice",
          title: t("workCode.removeWorkCodeToDevice"),
          validateSelected: true,
          display: "dialog",
          Form: RemoveWorkCodeAction,
        },
      ],
    },
  ];

  return (
    <ListView
      title={t("menu.device.data")}
      breadcrumbs={[
        { title: t("menu.device.workcode"), href: AppUrls.device.workCode },
      ]}
      columns={columns}
      uri={DeviceEndPoint.WORK_CODE_URI}
      url={AppUrls.device.workCode}
      filters={filters}
      modelActions={actions}
    ></ListView>
  );
};
export default WorkCodeListView;

import {DatePicker, LocalizationProvider} from "@mui/x-date-pickers";
import {Button, MenuItem, Typography, Select, Box, TextField} from "@mui/material";
import {Stack} from "@mui/system";
import {SetStateAction, useEffect, useState} from "react";
import dayjs from "dayjs";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import SnackbarUtil from "@/utils/SnackbarUtil";
import {useTranslation} from "react-i18next";
import SearchIcon from "@mui/icons-material/Search";
import {useListViewContext} from "@/provider/ListViewProvider";

const FilterPanel = () => {
  const {t} = useTranslation("report");
  const {t: common} = useTranslation("common");
  const {updateFilterModel, options, categoryLabel, approvalFilter, auditStatus} = useListViewContext();

  const initStartDate = dayjs().startOf("week");
  const initEndDate = dayjs().endOf("week");

  const [dateRange, setDateRange] = useState({
    startDate: initStartDate,
    endDate: initEndDate,
  });
  const [empCode, setEmpCode] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('');
  const handleCategoryChange = (event: { target: { value: SetStateAction<string>; }; }) => {
    setSelectedCategory(event.target.value);
  };
  const [selectedAuditStatus, setSelectedAuditStatus] = useState('');
  const handleAuditStatusChange = (event: { target: { value: SetStateAction<string>; }; }) => {
    setSelectedAuditStatus(event.target.value);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Stack
        direction={"row"}
        spacing={2}
        sx={{
          alignItems: "center",
        }}
      >
        {approvalFilter &&
        <Box>
          <TextField
            id={"staff_approval_emp_code"}
            size={'small'}
            label={common("employee.code", {ns: "per"})}
            value={empCode}
            onChange={(value) => {
               setEmpCode(value.target.value);
            }}
          />
        </Box>
        }
        <>
          <DatePicker
            sx={{ width: '180px', top:"5" }}
            label={t("report.startDate")}
            value={dateRange.startDate}
            onChange={(value) => {
              if (value?.isAfter(dateRange.endDate)) {
                SnackbarUtil.warning(
                  t("report.error.startDateBeforeEndDATE")
                );
              } else if (value) {
                setDateRange({...dateRange, startDate: value});
              }
            }}
            slotProps={{
              textField: {
                size: "small",
              },
            }}
          />
          <Typography variant={"body1"}>-</Typography>
          <DatePicker
            sx={{ width: '180px', top:"5" }}
            label={t("report.endDate")}
            value={dateRange.endDate}
            onChange={(value) => {
              if (value?.isBefore(dateRange.startDate)) {
                SnackbarUtil.warning(t("report.error.endDateAfterEndDATE"));
              } else if (value) {
                setDateRange({...dateRange, endDate: value});
              }
            }}
            slotProps={{
              textField: {
                size: "small",
              },
            }}
          />
        </>

        {options &&
        <Box>
          <TextField
            id={"staff_request_category"}
            select
            size={'small'}
            label={categoryLabel}
            defaultValue={selectedCategory}
            onChange={handleCategoryChange}
            sx={{ width: '180px'}}
          >
            <MenuItem selected>---------</MenuItem>
              {options && options.map((option:any)=>(
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
          </TextField>
        </Box>
        }
        {auditStatus &&
        <Box>
          <TextField
            id={"staff_request_audit_status"}
            select
            size={'small'}
            label={common("approval.status")}
            defaultValue={selectedAuditStatus}
            onChange={handleAuditStatusChange}
            sx={{ width: '180px' }}
          >
            <MenuItem selected>---------</MenuItem>
            <MenuItem value={1}>{common("approval.status.pending")}</MenuItem>
            <MenuItem value={2}>{common("approval.status.approved")}</MenuItem>
            <MenuItem value={3}>{common("approval.status.rejected")}</MenuItem>
            <MenuItem value={6}>{common("approval.status.revoked")}</MenuItem>
            <MenuItem value={7}>{common("approval.status.withdraw")}</MenuItem>
          </TextField>
        </Box>
        }
        <>
          <Button
            onClick={() => {
              if (
                dateRange.startDate &&
                dateRange.endDate &&
                !dateRange.startDate.isAfter(dateRange.endDate)
              )
              {
                if(!approvalFilter){
                  if(options) {
                    updateFilterModel({
                      start_date: dateRange.startDate.format("YYYY-MM-DD"),
                      end_date: dateRange.endDate.format("YYYY-MM-DD"),
                      audit_status: selectedAuditStatus,
                      category: selectedCategory
                    });
                  }
                  else{
                    updateFilterModel({
                      start_date: dateRange.startDate.format("YYYY-MM-DD"),
                      end_date: dateRange.endDate.format("YYYY-MM-DD"),
                    });
                  }
                }
                else{
                  if(options)
                  {
                    updateFilterModel({
                      emp_code: empCode,
                      start_date: dateRange.startDate.format("YYYY-MM-DD"),
                      end_date: dateRange.endDate.format("YYYY-MM-DD"),
                      audit_status: selectedAuditStatus,
                      category: selectedCategory
                    });
                  }
                  else{
                    updateFilterModel({
                      emp_code: empCode,
                      start_date: dateRange.startDate.format("YYYY-MM-DD"),
                      end_date: dateRange.endDate.format("YYYY-MM-DD"),
                      audit_status: selectedAuditStatus,
                    });
                  }
                }
              }
            }}
          >
            <SearchIcon/>
          </Button>
        </>
      </Stack>
    </LocalizationProvider>
  );
};
export default FilterPanel;

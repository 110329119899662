import React from "react";
import ModelActionLayout from "@/components/views/components/ModelActionLayout";
import {
  ScrollTab,
  ScrollTabs,
  useScrollTabsHook,
} from "@/components/ScrollTabs";
import {useTranslation} from "react-i18next";
import Grid from "@mui/material/Grid2";
import {Stack} from "@mui/system";
import {
  SummarizeOutlined,
  WorkHistoryOutlined, MobileFriendlyOutlined, StorageOutlined, InventoryOutlined, QueryBuilderOutlined, UpdateOutlined,
  ManageHistoryOutlined, RuleOutlined, AssignmentLateOutlined, DirectionsRunOutlined,
  CheckBoxOutlineBlankOutlined, FreeBreakfastOutlined, HourglassBottomOutlined,
  DoneAllOutlined, CakeOutlined, MoreTimeOutlined, DisplaySettingsOutlined, AddTaskTwoTone, AddchartTwoTone,
  DateRangeOutlined, CalendarMonthOutlined, NoteAddOutlined, TimelapseOutlined, AnalyticsOutlined,
  SettingsApplicationsOutlined, InsertChartOutlinedOutlined, Groups2Rounded, ApartmentRounded, CallToActionOutlined,
  PollRounded, CastForEducationOutlined, MonetizationOnOutlined, LightbulbCircleOutlined
} from "@mui/icons-material";
import ReportButton from "@/pages/Report/ReportLibrary/components/ReportButton";
import urls from "@/urls";

const ReportLibrary = () => {
  const {t} = useTranslation("report");
  const reportCategories = [
    {
      key: "transactionReports",
      title: t("reportLibrary.transactionReports"),
      subs: [
        {
          id: "transaction",
          title: t("reportLibrary.transaction"),
          icon: <WorkHistoryOutlined/>,
          url: urls.report.transaction,
        },
        {
          id: "mobileTransaction",
          title: t("reportLibrary.mobileTransaction"),
          icon: <MobileFriendlyOutlined/>,
          url: urls.report.mobile_transaction,
        },
        {
          id: "totalPunches",
          title: t("reportLibrary.totalPunches"),
          icon: <StorageOutlined/>,
          url: urls.report.total_punches,
        },
        {
          id: "firstLast",
          title: t("reportLibrary.firstLast"),
          icon: <QueryBuilderOutlined/>,
          url: urls.report.firstLast,
        },
        {
          id: "firstInLastOut",
          title: t("reportLibrary.firstInLastOut"),
          icon: <UpdateOutlined/>,
          url: urls.report.firstInLastOut,
        },
      ],
    },
    {
      key: "schedulingReports",
      title: t("reportLibrary.schedulingReports"),
      subs: [
        {
          id: "scheduledLog",
          title: t("reportLibrary.scheduledLog"),
          icon: <InventoryOutlined/>,
          url: urls.report.scheduleLog,
        },
        {
          id: "totalTimecard",
          title: t("reportLibrary.totalTimecard"),
          icon: <ManageHistoryOutlined/>,
          url: urls.report.totalTimecard,
        },
        {
          id: "missedInOutPunch",
          title: t("reportLibrary.missedInOutPunch"),
          icon: <RuleOutlined/>,
          url: urls.report.missedInOut,
        },
        {
          id: "late",
          title: t("reportLibrary.late"),
          icon: <AssignmentLateOutlined/>,
          url: urls.report.late,
        },
        {
          id: "early",
          title: t("reportLibrary.earlyLeave"),
          icon: <DirectionsRunOutlined/>,
          url: urls.report.earlyLeave,
        },
        {
          id: "absent",
          title: t("reportLibrary.absent"),
          icon: <CheckBoxOutlineBlankOutlined/>,
          url: urls.report.absent,
        },
        {
          id: "overtime",
          title: t("reportLibrary.overtime"),
          icon: <AddTaskTwoTone/>,
          url: urls.report.overtime,
        },
        {
          id: "breakTime",
          title: t("reportLibrary.breakTime"),
          icon: <FreeBreakfastOutlined/>,
          url: urls.report.breakTime,
        },
        {
          id: "halfDay",
          title: t("reportLibrary.halfDay"),
          icon: <HourglassBottomOutlined/>,
          url: urls.report.halfDay,
        },
        {
          id: "multiplePairPunch",
          title: t("reportLibrary.multiplePairPunch"),
          icon: <DoneAllOutlined/>,
          url: urls.report.multipleTransaction,
        },
        {
          id: "birthday",
          title: t("reportLibrary.birthday"),
          icon: <CakeOutlined/>,
          url: urls.report.employeeBirthday,
        },
      ],
    },
    {
      key: "dailyReports",
      title: t("reportLibrary.dailyReports"),
      subs: [
        {
          id: "dailyAttendance",
          title: t("reportLibrary.dailyAttendance"),
          icon: <MoreTimeOutlined/>,
          url: urls.report.dailyAttendance,
        },
        {
          id: "dailyDetail",
          title: t("reportLibrary.dailyDetails"),
          icon: <DisplaySettingsOutlined/>,
          url: urls.report.dailyDetails,
        },
        {
          id: "dailySummary",
          title: t("reportLibrary.dailySummary"),
          icon: <AddchartTwoTone/>,
          url: urls.report.dailySummary,
        },
        {
          id: "dailyStatus",
          title: t("reportLibrary.dailyStatus"),
          icon: <SummarizeOutlined/>,
          url: urls.report.dailyStatus,
        },
      ],
    },
    {
      key: "monthlyReports",
      title: t("reportLibrary.monthlyReports"),
      subs: [
        {
          id: "basicStatus",
          title: t("reportLibrary.basicStatus"),
          icon: <DateRangeOutlined/>,
          url: urls.report.monthlyBasicStatus,
        },
        {
          id: "statusSummary",
          title: t("reportLibrary.statusSummary"),
          icon: <CalendarMonthOutlined/>,
          url: urls.report.monthlyStatusSummary,
        },
        {
          id: "overtimeSummary",
          title: t("reportLibrary.overtimeSummary"),
          icon: <NoteAddOutlined/>,
          url: urls.report.overtimeSummary,
        },
        {
          id: "workDuration",
          title: t("reportLibrary.workDuration"),
          icon: <TimelapseOutlined/>,
          url: urls.report.monthlyDurationSummary,
        },
        {
          id: "workDetail",
          title: t("reportLibrary.workDetail"),
          icon: <SettingsApplicationsOutlined/>,
          url: urls.report.monthlyDetailedSummary,
        },
        {
          id: "shiftSummary",
          title: t("reportLibrary.shiftSummary"),
          icon: <AnalyticsOutlined/>,
          url: urls.report.monthlyAttSheetSummary,
        },
        {
          id: "attendanceStatus",
          title: t("reportLibrary.attendanceStatus"),
          icon: <SummarizeOutlined/>,
        },
        {
          id: "attendanceSummary",
          title: t("reportLibrary.attendanceSummary"),
          icon: <InsertChartOutlinedOutlined/>,
        },
      ],
    },
    {
      key: "summaryReports",
      title: t("reportLibrary.summaryReports"),
      subs: [
        {
          id: "employeeSummary",
          title: t("reportLibrary.employeeSummary"),
          icon: <Groups2Rounded/>,
          url: urls.report.employeeSummary,
        },
        {
          id: "departmentSummary",
          title: t("reportLibrary.departmentSummary"),
          icon: <ApartmentRounded/>,
          url: urls.report.departmentSummary,
        },
        {
          id: "leaveSummary",
          title: t("reportLibrary.leaveSummary"),
          icon: <CallToActionOutlined/>,
          url: urls.report.leaveSummary
        },
        {
          id: "yearlySummary",
          title: t("reportLibrary.yearlySummary"),
          icon: <PollRounded/>,
          url: urls.report.yearlySummary
        },
      ],
    },
    {
      key: "specialReports",
      title: t("reportLibrary.specialReports"),
      subs: [
        {
          id: "training",
          title: t("reportLibrary.training"),
          icon: <CastForEducationOutlined/>,
          url: urls.report.training,
        },
        {
          id: "payrollReport",
          title: t("reportLibrary.payrollReport"),
          icon: <MonetizationOnOutlined/>,
          url: urls.report.payrollSummary
        },
        {
          id: "continueAbnormal",
          title: t("reportLibrary.continueAbnormal"),
          icon: <LightbulbCircleOutlined/>,
          url: urls.report.abnormal
        },
      ],
    },
  ];
  const [tabs, getTabProps] = useScrollTabsHook(reportCategories);
  return (
    <ModelActionLayout title={t("menu.report.library", {ns: "menu"})}>
      <ScrollTabs tabs={tabs}>
        {reportCategories.map((reportCategory: any) => {
          return (
            <Stack key={reportCategory.key} spacing={2}>
              <ScrollTab {...getTabProps(reportCategory.key)}>
                <Grid container spacing={2}>
                  {reportCategory.subs?.map((report: any) => (
                    <ReportButton {...report} />
                  ))}
                </Grid>
              </ScrollTab>
            </Stack>
          );
        })}
      </ScrollTabs>
    </ModelActionLayout>
  );
};
export default ReportLibrary;

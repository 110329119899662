import {useTranslation} from "react-i18next";
import React, {ForwardedRef, useImperativeHandle, useRef} from "react";
import {CustomValidator, RJSFSchema, UiSchema} from "@rjsf/utils";
import LayoutForm from "@/components/rjsf/Form";
import validator from "@rjsf/validator-ajv8";
import AuthService from "@/services/auth/UserService";
import SnackbarUtil from "@/utils/SnackbarUtil";


const ChangePassword = (props: Record<string, any>, ref: ForwardedRef<any>) => {
  const {t} = useTranslation("sys")
  const formRef = useRef<any>()
  const formSchema: RJSFSchema = {
    "type": "object",
    properties: {
      password: {
        type: "string",
        title: t("account.password"),
      },
      new_password: {
        type: "string",
        title: t("account.newPassword"),
      },
      confirm_password: {
        type: "string",
        title: t("account.confirmPassword"),
      },
    },
    required: ["password", "new_password", "confirm_password"]
  }

  const uiSchema: UiSchema = {
    password: {
      "ui:widget": "password"
    },
    new_password: {
      "ui:widget": "password"
    },
    confirm_password: {
      "ui:widget": "password"
    }
  }

  const customValidate: CustomValidator = (formData, errors, uiSchema) => {
    if(formData.new_password !== formData.confirm_password){
      errors.new_password?.addError(t("account.error.mismatchPassword"))
      errors.confirm_password?.addError(t("account.error.mismatchPassword"))
    }
    return errors
  }

  const onChangePassword = async () => {
    const isValid = formRef.current?.validateForm()
    if (isValid) {
      const data = formRef.current?.state.formData
      const response = await AuthService.changePassword(props.recordId, data)
      if(response.isSuccess){
        SnackbarUtil.success(t("account.changePassword.successful"))
        return true
      }else{
        console.log(response)
      }
    }
  }

  useImperativeHandle(ref, () => ({
    onConfirm: onChangePassword
  }))

  return (
    <LayoutForm
      ref={formRef}
      schema={formSchema}
      uiSchema={uiSchema}
      validator={validator}
      customValidate={customValidate}
    />
  )
}
export default React.forwardRef(ChangePassword)

import { RJSFSchema, UiSchema } from "@rjsf/utils";
import { useTranslation } from "react-i18next";
import RecordView from "@/components/views/RecordView";
import { TeamEP } from "@/services/team/endpoints";
import { PsnlEP } from "@/services/psnl/endpoints";
import { useState } from "react";
import { datetime2string } from "@/utils/datetime";
import { useLocation } from "react-router-dom";

const ResignRecordView = () => {
  const { t } = useTranslation("per");
  const params = useLocation();
  const mode = params.state?.mode || "ADD";
  const [formData, setFormData] = useState<Record<string, any>>({
    resign_date: datetime2string(new Date(), "YYYY-MM-DD"),
    resign_type: 1,
    report_generation_end_date: datetime2string(new Date(), "YYYY-MM-DD"),
    disableatt: true,
  });

  const formSchema: RJSFSchema = {
    type: "object",
    required: [
      "employee",
      "resign_date",
      "resign_type",
      "report_generation_end_date",
      "disableatt",
    ],
    properties: {
      employee: {
        type: "string",
        title: t("resign.employee"),
        readOnly: mode === "EDIT",
      },
      resign_date: {
        type: "string",
        format: "date",
        title: t("resign.date"),
      },
      resign_type: {
        type: "number",
        title: t("resign.type"),
        oneOf: [
          { const: 1, title: t("resignType.option.absence") },
          { const: 2, title: t("resignType.option.delay") },
          { const: 3, title: t("resignType.option.badConduct") },
          { const: 4, title: t("resignType.option.voluntary") },
          { const: 5, title: t("resignType.option.permanentResign") },
          { const: 6, title: t("resignType.option.suspension") },
        ],
      },
      report_generation_end_date: {
        type: "string",
        format: "date",
        title: t("resign.endDateOfReportGeneration"),
      },
      disableatt: {
        type: "boolean",
        title: t("resign.disableAttendance"),
        oneOf: [
          { const: true, title: t("employee.disable") },
          { const: false, title: t("employee.enable") },
        ],
      },
      reason: {
        type: ["string", "null"],
        title: t("resign.reason"),
        maxLength: 200
      },
    },
  };

  const uiSchema: UiSchema = {
    "ui:defaultXsWidth": 6,
    "ui:submitButtonOptions": {
      norender: true,
    },
    employee: {
      "ui:widget": "m2o",
      "ui:options":{
        uri: PsnlEP.EMPLOYEE_URI
      }
    },
    disableatt: {
      "ui:widget": "select",
    },
    reason: {
      "ui:widget": "textarea",
      "ui.emptyValue": "",
    },
  };
  return (
    <RecordView
      formSchema={formSchema}
      uiSchema={uiSchema}
      uri={TeamEP.RESIGN_URI}
      formData={formData}
    />
  );
};
export default ResignRecordView;

import {useTranslation} from "react-i18next";
import {useLocation, useNavigate} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {CustomValidator, RJSFSchema, UiSchema} from "@rjsf/utils";
import EmailWidget from "@/pages/Oauth/components/EmailWidget";
import PasswordWidget from "@/pages/Oauth/components/PasswordWidget";
import urls from "@/urls";
import LayoutForm from "@/components/rjsf/Form";
import {Box} from "@mui/material";
import Layout from "@/pages/Oauth/Layout";
import OauthService from "@/services/oauth/OauthService";
import SnackbarUtil from "@/utils/SnackbarUtil";
import SubmitButton from "@/pages/Oauth/components/SubmitButton";
import RegExps from "@/utils/RegExps";


const InvitedSignUp = () => {
  const {t} = useTranslation("oauth")
  const {t: api} = useTranslation("api")
  const navigate = useNavigate()
  const params = useLocation()
  const verifyCode = params.state?.verifyCode
  const [loading, setLoading] = useState<boolean>(false)
  const formRef = React.useRef<any>()
  const formSchema: RJSFSchema = {
    type: "object",
    properties: {
      email: {
        type: "string",
        title: t("oauth.email"),
        default: params.state?.email
      },
      password: {
        type: "string",
        title: t("oauth.password")
      },
      confirm_password: {
        type: "string",
        title: t("oauth.confirmPassword")
      }
    },
    required: ["email", "password", "confirm_password"]
  }
  const uiSchema: UiSchema = {
    email: {
      "ui:widget": "email",
      "ui:placeholder": t("oauth.email"),
      "ui:disabled": true
    },
    password: {
      "ui:widget": "password",
      "ui:placeholder": t("oauth.password"),
    },
    confirm_password: {
      "ui:widget": "password",
      "ui:placeholder": t("oauth.confirmPassword"),
    }
  }
  const widgets = {
    email: EmailWidget,
    password: PasswordWidget,
  }

  const customValidate: CustomValidator = (formData, errors, uiSchema) => {
    if(formData.email && !RegExps.email.test(formData.email)){
      errors.email?.addError(t("oauth.validateError.invalidEmail"))
    }
    if(formData.password && !RegExps.password.test(formData.password)){
      errors.password?.addError(t("oauth.validateError.unsafePassword"))
    }
    if(formData.password && formData.confirm_password && formData.password !== formData.confirm_password){
      errors.confirm_password?.addError(t("oauth.validateError.mismatchPassword"))
    }
    return errors
  }

  const onInvitedSignUp = async () => {
    setLoading(true)
    const isValid = formRef.current?.validateForm()
    if (isValid) {
      const data = formRef.current?.state.formData
      OauthService.invitedSignUp({...data, code: verifyCode}).then((response) => {
        if (response.isSuccess) {
          navigate(urls.companyVerification)
        } else {
          console.log(response)
        }
      }).finally(() => {
        setLoading(false)
      })
    }else{
      setLoading(false)
    }
  }

  useEffect(() => {
    if (!verifyCode) {
      navigate(urls.companyVerification)
    }
  }, [])

  return (
    <Layout title={t("oauth.signUp.title")}>
      <LayoutForm
        ref={formRef}
        schema={formSchema}
        uiSchema={uiSchema}
        widgets={widgets}
        customValidate={customValidate}
        formContext={{
          onSubmit: onInvitedSignUp
        }}
      />
      <SubmitButton
        loading={loading}
        onClick={onInvitedSignUp}
      >
        {t("oauth.signUp")}
      </SubmitButton>
      <Box
        sx={{
          display: "flex",
          flexWrap: "nowrap",
          alignItems: "center",
          justifyContent: "center",
          margin: "16px 0"
        }}
      >
      </Box>
    </Layout>
  )
}
export default InvitedSignUp

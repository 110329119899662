import {WidgetProps} from "@rjsf/utils";
import React, {useState} from "react";
import AutocompleteTreeWidget from "@/components/rjsf/widgets/AutocompleteTreeWidget";


const Many2OneTreeWidget = (props: WidgetProps) => {
  return (
    <AutocompleteTreeWidget
      {...props}
      multiple={false}
    />
  )
};
export default Many2OneTreeWidget


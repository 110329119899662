import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './declare_module';
import App from './App';
import reportWebVitals from './reportWebVitals';
import MuiThemeProvider from "@/provider/MuiThemeProvider";
import {I18nextProvider} from "react-i18next";
import i18next from "./i18n";
import RegionProvider from "@/provider/RegionProvider";

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <RegionProvider>
      <I18nextProvider i18n={i18next}>
        <MuiThemeProvider>
          <App/>
        </MuiThemeProvider>
      </I18nextProvider>
    </RegionProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

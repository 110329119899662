import {useEffect, useMemo, useRef} from "react";
import {Box, Card, CardContent, CardHeader, Chip} from "@mui/material";
import * as echarts from "echarts";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration"
import ChartCard from "@/pages/Ralvie/Dashboard/components/ChartCard";

dayjs.extend(duration)


const WeekTime = (props: Record<string, any>) => {
  const {data} = props
  const containerRef = useRef<any>()
  const chart = useRef<any>()

  const formatDuration = (value: number) => {
    const minutes = Math.floor(value / 60)
    const hour = Math.floor(minutes / 60)
    const minute = minutes % 60
    return `${hour}h${minute}m`
  }

  const option = {
    grid: {
      top: 24
    },
    xAxis: {
      type: 'category',
      data: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
    },
    yAxis: {
      name: "hrs",
      nameLocation: "middle",
      type: 'time',
      axisLabel: {
        show: false
      }
    },
    series: [
      {
        data: [
          data?.["SUNDAY"],
          data?.["MONDAY"],
          data?.["TUESDAY"],
          data?.["WEDNESDAY"],
          data?.["THURSDAY"],
          data?.["FRIDAY"],
          data?.["SATURDAY"]
        ],
        type: 'bar',
        showBackground: true,
        label: {
          show: true,
          position: 'top',
          formatter: (params: any) => {
            return formatDuration(params.data)
          }
        },
      }
    ]
  }

  const total: Record<string, any> = useMemo(() => {
    if (data) {
      const durations: number[] = [
        Math.floor(data?.["SUNDAY"] || 0),
        Math.floor(data?.["MONDAY"] || 0),
        Math.floor(data?.["TUESDAY"] || 0),
        Math.floor(data?.["WEDNESDAY"] || 0),
        Math.floor(data?.["THURSDAY"] || 0),
        Math.floor(data?.["FRIDAY"] || 0),
        Math.floor(data?.["SATURDAY"] || 0)
      ]
      const totalDuration = durations.reduce((total, duration) => {
        return total + duration
      })
      const minutes = Math.floor(totalDuration / 60)
      const hour = Math.floor(minutes / 60)
      const minute = minutes % 60
      return {hour, minute}
    } else {
      return {hour: 0, minute: 0}
    }
  }, [data])

  useEffect(() => {
    const container = containerRef.current
    if (!chart.current) {
      chart.current = echarts.init(container)
    }
    chart.current.setOption(option)
  }, [data])

  return (
    <ChartCard
      title={props.title}
      total={total}
    >
      <Box
        ref={containerRef}
        component={"div"}
        sx={{
          width: "100%",
          height: "100%"
        }}
      />
    </ChartCard>
  )
}
export default WeekTime

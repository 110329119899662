import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  useTheme
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import {useTranslation} from "react-i18next";
import {CheckBoxOutlined} from "@mui/icons-material";
import {Stack} from "@mui/system";
import OrderView from "@/pages/OnlineSubscription/MXSubscription/Pricing/components/OrderView";
import React from "react";
import {useDialogContext} from "@/provider/DialogProvider";


const PlanCard = (props: Record<string, any>) => {
  const {t} = useTranslation("sub")
  const dialog = useDialogContext();
  const {plan} = props
  const theme = useTheme()
  const getPlanName = (planType: number) => {
    switch (planType) {
      case 1:
        return t('subscriptionPlan.basic')
      case 2:
        return t('subscriptionPlan.standard')
      case 3:
        return t('subscriptionPlan.premium')
      case 4:
        return t('subscriptionPlan.enterprise')
      case 5:
        return t('subscriptionPlan.professional')
      default:
        return t('subscriptionPlan.basic')
    }
  }
  const planType = getPlanName(plan.type)
  const onOrderView = async () => {
    dialog.showDialog({
      title: planType,
      content: <OrderView plan={plan} planType={planType}/>,
      disableAction: true
    })
  }

  return (
    <Card
      sx={{
        borderRadius: "8px"
      }}
    >
      <CardHeader
        title={
          <Typography sx={{color: "#fff"}}>
            {planType}
          </Typography>
        }
        sx={{
          backgroundColor: theme.palette.primary.main,
          textAlign: "center"
        }}
      />
      <CardContent
        sx={{p: 0}}
      >
        <Grid
          sx={{
            backgroundColor: "#2E85CF",
            color: "#fff",
          }}
        >
          <List
            sx={{
              p: 2,
            }}
          >
            <ListItem disablePadding>
              <ListItemText>{`${plan.employee_capacity} ${t("onlineSubscription.employees")}`}</ListItemText>
            </ListItem>
            <ListItem disablePadding>
              <ListItemText>{`${plan.device_capacity} ${t("onlineSubscription.devices")}`}</ListItemText>
            </ListItem>
            <ListItem disablePadding>
              <ListItemText>{`${plan.mobile_capacity} ${t("onlineSubscription.mobileApplications")}`}</ListItemText>
            </ListItem>
          </List>
        </Grid>
        <Grid>
          <List
            sx={{
              p: 2,
              height: "224px",
              overflowY: "auto"
            }}
          >
            {plan.feature?.map((feature: any) => {
              return (
                <ListItem disablePadding>
                  <ListItemIcon><CheckBoxOutlined color={"primary"}/></ListItemIcon>
                  <ListItemText>{feature.name}</ListItemText>
                </ListItem>
              )
            })}
          </List>
          <Stack
            spacing={0.5}
            direction={"row"}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: "8px 0"
            }}>
            <Typography variant={"h3"}>{`$${plan.month_price}`}</Typography>
            <Typography variant={"h5"}>/</Typography>
            <Typography variant={"h5"}>{t("onlineSubscription.month")}</Typography>
          </Stack>
        </Grid>
      </CardContent>
      <CardActions>
        <Button
          fullWidth
          variant={"contained"}
          onClick={onOrderView}
        >{t("onlineSubscription.select")}</Button>
      </CardActions>
    </Card>
  )
}
export default PlanCard

import GenericService from "@/services/core/GenericService";
import {PersonalEndPoint} from "@/services/personal/endpoints";


const leaveWithdraw = async (data: Record<string, any>) => {
  return await GenericService.action(PersonalEndPoint.LEAVE_URI, "revoke", data)
}

const RequestService = {

  leaveWithdraw
}
export default RequestService

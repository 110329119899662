import {RJSFSchema, UiSchema} from "@rjsf/utils";
import {useTranslation} from "react-i18next";
import urls from "@/urls";
import {GPSEndPoint} from "@/services/gps/endpoints";
import RecordView from "@/components/views/RecordView";
import {PersonalEndPoint} from "@/services/personal/endpoints";
import {TeamEP} from "@/services/team/endpoints";


const OutdoorScheduleRecordView = () => {
  const {t} = useTranslation('gps');
  const formSchema: RJSFSchema = {
    type: "object",
    properties: {
      employee: {
        type: "string",
        title: t("outdoorSchedule.employee")
      },
      schedule_planner: {
        type: "integer",
        title: t("outdoorSchedule.schedulePlanner")
      },
      start_date: {
        type: "string",
        title: t("outdoorSchedule.startDate")
      },
      end_date: {
        type: "string",
        title: t("outdoorSchedule.endDate")
      }
    },
    required: ["employee", "schedule_planner", "start_date", "end_date"]
  }
  const uiSchema: UiSchema = {
    "ui:defaultXsWidth": 4,
    employee: {
      "ui:widget": "m2o",
      "ui:options": {
        uri: TeamEP.EMPLOYEE_URI
      }
    },
    schedule_planner: {
      "ui:widget": "m2o",
      "ui:options": {
        uri: GPSEndPoint.SCHEDULE_PLANNER
      }
    },
    start_date: {
      "ui:widget": "date"
    },
    end_date: {
      "ui:widget": "date"
    }
  }
  return (
    <RecordView
      formSchema={formSchema}
      uiSchema={uiSchema}
      uri={GPSEndPoint.OUTDOOR_SCHEDULE}
    />
  )
}
export default OutdoorScheduleRecordView

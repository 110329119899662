import {useTranslation} from "react-i18next";
import {GridColDef, GridRenderCellParams} from "@mui/x-data-grid";
import urls from "@/urls";
import ReportView from "../ReportView";
import {PersonalEndPoint} from "@/services/personal/endpoints";
import { usePunchStateHook, useWeekdayHook } from "@/hooks/optionsHooks";
import { useEffect, useState } from "react";
import { useReportEmployee } from "@/hooks/employee";


const ScheduledLogReport = () => {
  const {t} = useTranslation("report")
  const employeeFields = useReportEmployee()
  const weekdayOptions = useWeekdayHook()
  const [columns, setColumns] = useState<GridColDef[]>([
    ...employeeFields,
    {
      field: "att_date",
      headerName: t("report.column.punchDate"),
      flex: 1
    },
    {
      field: "weekday",
      headerName: t("report.column.weekday"),
      flex: 1,
      type: "singleSelect",
      valueOptions: weekdayOptions,
    },
    {
      field: "work_code",
      headerName: t("report.column.work.code"),
      flex: 1
    },
    {
      field: "punch_time",
      headerName: t("report.column.punchTime"),
      flex: 1
    },
    {
      field: "punch_state",
      headerName: t("report.column.punchState"),
      flex: 1
    },
    {
      field: "correct_state",
      headerName: t("report.column.correctState"),
      flex: 1
    },
  ])

  const punchStateOptions = usePunchStateHook();
  useEffect(() => {
    setColumns((old: any) => {
      return old.map((each: any) => {
        if (each.field === "punch_state" || each.field === "correct_state") {
          return {
            ...each,
            type: "singleSelect",
            valueOptions: punchStateOptions,
          };
        } else {
          return each;
        }
      });
    });
  }, [punchStateOptions]);

  return (
    <ReportView
      title={t("reportLibrary.scheduledLog")}
      breadcrumbs={[]}
      columns={columns}
      uri={PersonalEndPoint.SCHEDULED_LOG_URI}
      url={urls.personal.report.scheduledLog}
      hideColumns={['first_name', 'last_name', 'department_code', 'position_code', 'position_name']}
    />
  )
}
export default ScheduledLogReport

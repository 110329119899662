import {RJSFSchema, UiSchema} from "@rjsf/utils";
import {useTranslation} from "react-i18next";
import RecordView from "@/components/views/RecordView";
import urls from "@/urls";
import {PsnlEP} from "@/services/psnl/endpoints";
import {AttEndPoint} from "@/services/att/endpoints";
import React from "react";


const LeaveSettingsRecordView = () => {
  const {t} = useTranslation("att");
  const formSchema: RJSFSchema = {
    "type": "object",
    "required": [
      'employee', 'allotment_type', 'effective_date', 'days',
      'max_leave_per_month', 'auto_deduct', 'carry_forward_limit',
    ],
    "properties": {
      "employee": {
        "title": t("leaveSettings.employee"),
        "type": "array",
        "minItems": 1,
        "items": {
          "type": "string",
        },
      },
      allotment_type: {
        type: "integer",
        title: t("leaveSettings.allotmentType"),
        default: 0,
        oneOf: [
          {const: 0, title: t("leaveSettings.yearly")},
        ]
      },
      "effective_date": {
        "type": "string",
        "format": "date",
        "title": t("leaveSettings.effectiveDate"),
      },
      "days": {
        "type": ["number"],
        "title": t("leaveSettings.days"),
        default: 1,
      },
      "max_leave_per_month": {
        "type": ["number"],
        "title": t("leaveSettings.maxLeavePerMonth"),
        default: 1,
      },
      auto_deduct: {
        type: "integer",
        title: t("leaveSettings.autoDeduct"),
        default: 0,
        oneOf: [
          {const: 1, title: t("boolean.no", {ns: "common"})},
          {const: 0, title: t("boolean.yes", {ns: "common"})},
        ]
      },
      "carry_forward_limit": {
        "type": ["integer"],
        "title": t("leaveSettings.carryForwardLimit"),
        default: 0,
      },
    }
  };

  const [uiSchema, setUiSchema] = React.useState<UiSchema>({
    'ui:defaultXsWidth': 6,
    "ui:submitButtonOptions": {"norender": true},
    "employee": {
      "ui:widget": "m2m",
      "ui:options": {
        uri: PsnlEP.EMPLOYEE_URI
      }
    },
  })

  const [formData, setFormData] = React.useState<Record<string, any>>({})
  const onChange = (e: any) => {
    if (e.formData.auto_deduct !== formData.auto_deduct) {
      const carryForwardLimitReadonly = e.formData.auto_deduct === 0 ? false : true
      setUiSchema((old) => {
        return {
          ...old,
          "carry_forward_limit": {
            "ui:readonly": carryForwardLimitReadonly
          }
        }
      })
      e.formData.carry_forward_limit = 0
    }
    setFormData({
      ...e.formData,
    })
  }

  return (
    <RecordView
      formSchema={formSchema}
      uiSchema={uiSchema}
      uri={AttEndPoint.LEAVE_SETTINGS_URI}
      onChange={onChange}
      formData={formData}
    />
  )
}
export default LeaveSettingsRecordView

import {RJSFSchema, UiSchema} from "@rjsf/utils";
import {useTranslation} from "react-i18next";
import {DeviceEndPoint} from "@/services/device/endpoints";
import RecordView from "@/components/views/RecordView";

const PublicMessageRecordView = () => {
  const {t} = useTranslation("device");
  const {t: common} = useTranslation("common");
  const formSchema: RJSFSchema = {
    type: "object",
    required: ["terminal", "start_time", "duration", "content"],
    properties: {
      terminal: {
        type: "number",
        title: t("publicMessage.terminal"),
      },
      start_time: {
        type: "string",
        format: "datetime",
        title: t("publicMessage.startTime"),
      },
      duration: {
        type: "number",
        title: t("publicMessage.duration"),
        minimum: 0,
      },
      content: {
        type: "string",
        title: t("publicMessage.content"),
        maxLength: 320
      },
    },
  };

  const uiSchema: UiSchema = {
    "ui:defaultXsWidth": 6,
    "ui:submitButtonOptions": {
      norender: true,
    },
    terminal: {
      "ui:widget": "m2o",
      "ui:options": {
        uri: DeviceEndPoint.TERMINAL_URI,
      },
    },
    content: {
      "ui:widget": "textarea",
    },
    duration: {
      "ui:widget": "quantifier",
      "ui:options": {
        unit: common("common.unit.minute")
      }
    },
  };
  return (
    <RecordView
      formSchema={formSchema}
      uiSchema={uiSchema}
      uri={DeviceEndPoint.PUBLIC_MESSAGE_URI}
    />
  );
};
export default PublicMessageRecordView;

import React, { ChangeEvent, useCallback, useRef, useState } from "react";
import { WidgetProps } from "@rjsf/utils";

const usbUploadTransactionWidget = (props: WidgetProps) => {
  const inputRef = useRef(null);
  const handleFileChange = (event: any) => {
    const files = event.target.files;
    if (files && files.length > 0) {
      const file = files[0];
      const reader = new FileReader();
      reader.readAsText(file);
      reader.onload = (event) => {
        props.onChange([file.name, event.target?.result]);
      };
    }
  };

  return (
    <input
      type="file"
      accept=".dat"
      required
      ref={inputRef}
      onChange={handleFileChange}
    />
  );
};

export default usbUploadTransactionWidget;

import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import StarIcon from '@mui/icons-material/Star';


const RenderHeader = (props: any,) => {
  const {title, required, unique} = props
  return (
    <>
      {required ?
        <span style={{ fontSize: "16px", fontWeight: "bold" }}>{title}<span style={{ color: "red" }}> * </span></span>
        :
        <span>{title}</span>
      }
      {unique && <StarIcon sx={{ fontSize: "16px", color: "red" }} />}
    </>
  )
}


export default function CustomTable(props: Record<string, any>) {
  const { headers, rows } = props
  const columns = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'
  return (
    <TableContainer>
      <Table sx={{ minWidth: 650 }}>
        <TableHead>
          <TableRow
            sx={{
              background: '#f2f2f2'
            }}>
            <TableCell align="left"
              sx={{
                borderRight: '1px solid #e6e6e6',
                borderTop: '1px solid #e6e6e6',
                borderLeft: '1px solid #e6e6e6',
                width: "20px",
              }}
            ></TableCell>
            {headers.map((row: any, index: any) => (
              <TableCell key={index} align="left"
                sx={{
                  borderRight: '1px solid #e6e6e6',
                  borderTop: '1px solid #e6e6e6',
                }}
              >{columns[index]}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow
            sx={{
              borderLeft: '1px solid #e6e6e6',
            }}
          >
            <TableCell align="left"
              sx={{ borderRight: '1px solid #e6e6e6', borderTop: '1px solid #e6e6e6', background: '#f2f2f2' }}
            >1</TableCell>
            {headers.map((cell: any, index: any) => (
              <TableCell key={index} align="left"
                sx={{
                  borderRight: '1px solid #e6e6e6',
                  borderTop: '1px solid #e6e6e6',
                  fontWeight: "bold", fontSize: "16px"
                }}
              >
                <RenderHeader
                  title={cell.title}
                  required={cell.required}
                  unique={cell.unique}
                />
              </TableCell>
            ))}
          </TableRow>

          {rows.map((row: any, index: any) => (
            <TableRow
              key={index}
              sx={{ borderLeft: '1px solid #e6e6e6' }}
            >
              <TableCell align="left"
                sx={{
                  borderRight: '1px solid #e6e6e6',
                  fontWeight: "normal !important", fontSize: "12px !important",
                  background: '#f2f2f2'
                }}
              >{index + 2}</TableCell>
              {row.map((cell: any, index: any) => (
                <TableCell key={index} align="left" sx={{
                  borderRight: '1px solid #e6e6e6'
                }}>{cell}</TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

import { PsnlEP } from "@/services/psnl/endpoints";
import {RJSFSchema, UiSchema} from "@rjsf/utils";
import {useTranslation} from "react-i18next";
import RecordView from "@/components/views/RecordView";
import urls from "@/urls";
import {TeamEP} from "@/services/team/endpoints";


const RoleRecordView = () => {
  const {t} = useTranslation("per");
  const formSchema: RJSFSchema = {
    "type": "object",
    "required": [
      "role_code",
      "role_name",
    ],
    "properties": {
      "role_code": {
        "type": "string",
        "title": t("workflowRole.code"),
        "maxLength": 30
      },
      "role_name": {
        "type": "string",
        "title": t("workflowRole.name"),
        "maxLength": 50
      },
      "description": {
        "type": ["string", "null"],
        "title": t("workflowRole.description"),
        "maxLength": 200
      }
    }
  };

  const uiSchema: UiSchema = {
    'ui:defaultXsWidth': 4,
    "ui:submitButtonOptions": {"norender": true},
  };
  return (
    <RecordView
      breadcrumbs={[
        {title: t("menu.team", {ns: "menu"}), href: urls.team.root},
        {title: t("menu.workflowRole", {ns: "menu"}), href: urls.team.workflowRole},
      ]}
      formSchema={formSchema}
      uiSchema={uiSchema}
      uri={TeamEP.WORKFLOW_ROLE_URI}
    />
  )
}
export default RoleRecordView

import { useTranslation } from "react-i18next";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import urls from "@/urls";
import ReportView from "../ReportView";
import {PersonalEndPoint} from "@/services/personal/endpoints";
import { useReportEmployee } from "@/hooks/employee";

const ScheduleTrackReport = () => {
  const { t } = useTranslation("gps");
  const employeeFields = useReportEmployee()
  const displayName = (value: any) => value?.display_name
  const columns: GridColDef[] = [
    ...employeeFields,
    { field: "date", headerName: t("schedulePlanner.date"), flex: 1},
    { field: "schedule", valueFormatter: displayName, headerName: t("report.schedule.name"), flex: 1},
    { field: "client", valueFormatter: displayName, headerName: t("report.client.name"), flex: 1},
    { field: "checkin", headerName: t("report.checkin"), flex: 1},
    { field: "checkout", headerName: t("report.checkout"), flex: 1},
    { field: "checkin_latitude", headerName: t("report.checkin.latitude"), flex: 1},
    { field: "checkin_longitude", headerName: t("report.checkin.longitude"), flex: 1},
    { field: "checkout_latitude", headerName: t("report.checkout.latitude"), flex: 1},
    { field: "checkout_longitude", headerName: t("report.checkout.longitude"), flex: 1},
    { field: "checkin_address", headerName: t("report.checkin.address"), flex: 1},
    { field: "checkout_address", headerName: t("report.checkout.address"), flex: 1},
    { field: "summary", headerName: t("report.summary"), flex: 1},
  ];

  return (
    <ReportView
      title={t("menu.gps.scheduleReport", {ns: "menu"})}
      breadcrumbs={[]}
      columns={columns}
      uri={PersonalEndPoint.SCHEDULE_TRACK_URI}
      url={urls.personal.outdoorManagement.scheduleReport}
      hideColumns={['first_name', 'last_name', 'department_code', 'position_code', 'position_name']}
    />
  );
};
export default ScheduleTrackReport;

import {Route, Routes} from "react-router-dom";
import  PrivateMessageListView from "./ListView";
import RecordView from "./RecordView";


const PrivateMessageRouter = () => {
  return (
    <Routes>
      <Route index element={<PrivateMessageListView />}/>
      <Route path={"add/"} element={<RecordView />}/>
      <Route path={"edit/"} element={<RecordView />}/>
    </Routes>
  )
};
export default PrivateMessageRouter

import ListView from "@/components/views/ListView";
import {GridColDef} from "@mui/x-data-grid";
import AppUrls from "@/urls";
import urls from "@/urls";
import {TeamEP} from "@/services/team/endpoints";
import {useTranslation} from "react-i18next";
import SyncAltIcon from '@mui/icons-material/SyncAlt';
import AssignEmployeeAction from "@/pages/Team/Role/components/AssignEmployeeAction";


const RoleListView = () => {
  const {t} = useTranslation("per")
  const displayName = (value: any) => value?.display_name
  const columns: GridColDef[] = [
    {field: "role_code", headerName: t("workflowRole.code"), flex: 1},
    {field: "role_name", headerName: t("workflowRole.name"), flex: 1},
    {field: "description", headerName: t("workflowRole.description"), flex: 1},
    {field: "employee_count", headerName: t("workflowRole.employeeCount"), flex: 1},
  ]

  const filters = [
    {key: 'role_code', label: t("workflowRole.code"), type: 'string'},
    {key: 'role_name', label: t("workflowRole.name"), type: 'string'},
  ]

  const modelActions = [
    {
      id: "roleAssignEmployee",
      title: t("department.personnelTransfer", {ns: "company"}),
      icon: <SyncAltIcon />,
      validateSelected: true,
      display: "dialog",
      Form: AssignEmployeeAction,
    },
  ];

  return (
    <ListView
      title={t("menu.workflowRole", {ns: "menu"})}
      breadcrumbs={[
        {title: t("menu.team", {ns: "menu"}), href: urls.team.root},
      ]}
      columns={columns}
      uri={TeamEP.WORKFLOW_ROLE_URI}
      url={AppUrls.team.workflowRole}
      filters={filters}
      modelActions={modelActions}
    />
  )
}
export default RoleListView

import GenericService from "@/services/core/GenericService";
import {VendorEndPoint} from "@/services/vendor/endpoints";
import SnackbarUtil from "@/utils/SnackbarUtil";
import {useTranslation} from "react-i18next";
import React, {useState} from "react";
import {Box, Divider, Grid, List, ListItem, Typography} from "@mui/material";
import {LoadingButton} from "@mui/lab";
import {Payment} from "@mui/icons-material";


const PaymentView = (props: Record<string, any>) => {
  const {t} = useTranslation('sub')
  const {t: api} = useTranslation('api')
  const [loading, setLoading] = useState<boolean>(false)
  const {order} = props

  const onPayment = async () => {
    setLoading(true)
    GenericService.action(
      VendorEndPoint.SUBSCRIPTION_URI,
      "payment",
      {provider: "TELR"},
      order.id
    ).then((response) => {
      if (response.isSuccess) {
        const paymentUrl = response.data?.payment_url
        window.open(paymentUrl, '_blank', 'noopener,noreferrer');
      } else {
        return console.log(response)
      }
    }).finally(() => {
      setLoading(false)
    })
  }
  return (
    <Box sx={{width: "384px", minHeight: "192px"}}>
      <List>
        <ListItem>
          <Grid container sx={{justifyContent: "space-between"}}>
            <Grid item xs={6}>{t("onlineSubscription.orderNumber")}:</Grid>
            <Grid item xs={6} style={{textAlign: 'left'}}>{order.number}</Grid>
          </Grid>
        </ListItem>
        <ListItem>
          <Grid container sx={{justifyContent: "space-between"}}>
            <Grid item xs={6}>{t("onlineSubscription.paymentAmount")}:</Grid>
            <Grid item xs={6} style={{textAlign: 'left'}}>
              <Typography variant={"h3"}>{`$ ${order.payment_price}`}</Typography>
            </Grid>
          </Grid>
        </ListItem>
      </List>
      <Divider/>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          p: 2
        }}>
        <LoadingButton
          loading={loading}
          variant={"contained"}
          startIcon={<Payment/>}
          sx={{
            minWidth: "192px",
            height: "48px"
          }}
          onClick={onPayment}
        >
          {t('onlineSubscription.payNow')}
        </LoadingButton>
      </Box>
    </Box>
  )
}
export default PaymentView

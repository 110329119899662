import {Navigate, useRoutes} from "react-router-dom";
import MyRequestRouter from "@/pages/Personal/request/router";
import HolidayListView from "@/pages/Personal/my-calendar/holiday/ListView";


const HolidayRouter = () => {
  const routes = useRoutes([
    {index: true, element: <HolidayListView />},
  ])
  return routes
};
export default HolidayRouter

import {Route, Routes} from "react-router-dom";
import LeaveListView from "@/pages/Personal/request/leave/ListView";
import AddLeaveRecordView from "@/pages/Personal/request/leave/AddRecordView";

const LeaveRouter = () => {
  return (
    <Routes>
      <Route index element={<LeaveListView/>}/>
      <Route path={"add/"} element={<AddLeaveRecordView/>}/>
    </Routes>
  );
};
export default LeaveRouter;

import {useTranslation} from "react-i18next";
import {GridColDef} from "@mui/x-data-grid";
import urls from "@/urls";
import {ReportEndPoint} from "@/services/report/endpoints";
import ReportView from "@/pages/Report/ReportLibrary/components/ReportView";
import {useEffect, useState} from "react";
import {usePunchStateHook} from "@/hooks/optionsHooks";
import GenericService from "@/services/core/GenericService";
import { useReportEmployee } from "@/hooks/employee";


const TransactionReport = () => {
  const {t} = useTranslation("report")
  const employeeFields = useReportEmployee()
  const [columns, setColumns] = useState<GridColDef[]>([
    ...employeeFields,
    {
      field: "att_date",
      headerName: t("report.column.punchDate"),
      flex: 1
    },
    {
      field: "punch_time",
      headerName: t("report.column.punchTime"),
      flex: 1
    },
    {
      field: "punch_state",
      headerName: t("report.column.punchState"),
      flex: 1,
    },
    {
      field: "source",
      headerName: t("report.column.source"),
      flex: 1,
      type: "singleSelect",
      valueOptions: [
        {value: 1, label: t("dataSource.device")},
        {value: 2, label: t("dataSource.manualLog")},
        {value: 3, label: t("dataSource.mobile")},
        {value: 4, label: t("dataSource.penDriver")},
      ]
    },
  ])

  const punchStateOptions = usePunchStateHook()
  useEffect(() => {
    setColumns((old: any) => {
      return old.map((each: any) => {
        if (each.field === 'punch_state') {
          return {
            ...each,
            type: "singleSelect",
            valueOptions: punchStateOptions
          }
        } else {
          return each
        }
      })
    })
  }, [punchStateOptions])

  return (
    <ReportView
      title={t("reportLibrary.transaction")}
      breadcrumbs={[]}
      columns={columns}
      uri={ReportEndPoint.TRANSACTION_URI}
      url={urls.report.transaction}
      hideColumns={['first_name', 'last_name', 'department_code', 'position_code', 'position_name']}
    />
  )
}
export default TransactionReport

import {createContext, useContext} from "react";

type ReportViewContextProps = Record<string, any>


export const ReportViewContext = createContext<ReportViewContextProps>({

})

export const useReportViewContext = () => {
  const context = useContext(ReportViewContext)
  if (!context) {
    throw new Error('The app provider not found!')
  }
  return context
}


import { TabPanel } from "@mui/lab";
import MultiSelectTreeView from "./MultiSelectTreeView";
import { useEffect, useState } from "react";


const PermissionView = (props: any) => {
  const { itemKey, onStatusChange, initSelecteds } = props

  const nodes: any[] = [
    {
      "id": "accounts.myuser",
      "label": "User",
      "children": [
        {
          "codename": "cloud_user_add_myuser",
          "label": "cloud_user_add_myuser"
        },
        {
          "codename": "user_delete_myuser",
          "label": "user_delete_myuser"
        },
        {
          "codename": "change_password_myuser",
          "label": "change_password_myuser"
        },
        {
          "codename": "view_myuser",
          "label": "view_myuser"
        },
        {
          "codename": "enter_gps_module",
          "label": "enter_gps_module"
        },
        {
          "codename": "enter_reports_module",
          "label": "enter_reports_module"
        },
        {
          "codename": "enter_complements_module",
          "label": "enter_complements_module"
        },
        {
          "codename": "enter_company_module",
          "label": "enter_company_module"
        },
        {
          "codename": "enter_access_module",
          "label": "enter_access_module"
        },
        {
          "codename": "enter_payroll_module",
          "label": "enter_payroll_module"
        },
        {
          "codename": "enter_attendance_module",
          "label": "enter_attendance_module"
        },
        {
          "codename": "enter_terminal_module",
          "label": "enter_terminal_module"
        },
        {
          "codename": "enter_personnel_module",
          "label": "enter_personnel_module"
        },
        {
          "codename": "enter_system_module",
          "label": "enter_system_module"
        },
        {
          "codename": "change_myuser",
          "label": "change_myuser"
        },
        {
          "codename": "add_myuser",
          "label": "add_myuser"
        }
      ]
    },
    {
      "id": "auth.group",
      "label": "group",
      "children": [
        {
          "codename": "change_group",
          "label": "change_group"
        },
        {
          "codename": "view_group",
          "label": "view_group"
        },
        {
          "codename": "add_group",
          "label": "add_group"
        }
      ]
    },
    {
      "id": "base.dbbackuplog",
      "label": "DB Backup Log",
      "children": [
        {
          "codename": "d_b_backup_auto_dbbackuplog",
          "label": "d_b_backup_auto_dbbackuplog"
        },
        {
          "codename": "d_b_backup_manually_dbbackuplog",
          "label": "d_b_backup_manually_dbbackuplog"
        },
        {
          "codename": "d_b_restore_manually_dbbackuplog",
          "label": "d_b_restore_manually_dbbackuplog"
        }
      ]
    },
    {
      "id": "base.autoexporttask",
      "label": "Auto Export",
      "children": [
        {
          "codename": "delete_autoexporttask",
          "label": "delete_autoexporttask"
        },
        {
          "codename": "change_autoexporttask",
          "label": "change_autoexporttask"
        },
        {
          "codename": "add_autoexporttask",
          "label": "add_autoexporttask"
        },
        {
          "codename": "view_autoexporttask",
          "label": "view_autoexporttask"
        },
        {
          "codename": "manual_export_autoexporttask",
          "label": "manual_export_autoexporttask"
        }
      ]
    },
    {
      "id": "base.adminlog",
      "label": "Log",
      "children": [
        {
          "codename": "view_adminlog",
          "label": "view_adminlog"
        }
      ]
    },
    {
      "id": "base.sftpsetting",
      "label": "SFTP Setting",
      "children": [
        {
          "codename": "delete_sftpsetting",
          "label": "delete_sftpsetting"
        },
        {
          "codename": "view_sftpsetting",
          "label": "view_sftpsetting"
        },
        {
          "codename": "add_sftpsetting",
          "label": "add_sftpsetting"
        },
        {
          "codename": "change_sftpsetting",
          "label": "change_sftpsetting"
        }
      ]
    },
    {
      "id": "base.system_setting_permission",
      "label": "system_setting_permission",
      "children": [
        {
          "codename": "view_email_template_setting",
          "label": "view_email_template_setting"
        },
        {
          "codename": "view_temp_mask_setting",
          "label": "view_temp_mask_setting"
        },
        {
          "codename": "view_email_setting",
          "label": "view_email_setting"
        },
        {
          "codename": "view_company_setting",
          "label": "view_company_setting"
        },
        {
          "codename": "view_alert_setting",
          "label": "view_alert_setting"
        },
        {
          "codename": "view_pdf_report_setting",
          "label": "view_pdf_report_setting"
        },
        {
          "codename": "view_whatsapp_setting",
          "label": "view_whatsapp_setting"
        },
        {
          "codename": "view_db_migrate",
          "label": "view_db_migrate"
        },
        {
          "codename": "view_refresh_migrate_status",
          "label": "view_refresh_migrate_status"
        },
        {
          "codename": "view_sms_setting",
          "label": "view_sms_setting"
        },
        {
          "codename": "view_twilio_template_setting",
          "label": "view_twilio_template_setting"
        }
      ]
    }
  ]

  const [treeNodes, setTreeNodes] = useState<any[]>([]);
  const [allIds, setaAllIds] = useState<any[]>([]);
  useEffect(() => {
    const newNodes: any = []
    const newAllIds: any = []
    if (Object.keys(itemKey).length > 0) {
      nodes.forEach((node) => {
        const newChildren: any = []
        if (itemKey[node.id]) {
          node.children.forEach((child: any) => {
            if (itemKey[node.id][child.codename]) {
              newChildren.push({ ...child, id: itemKey[node.id][child.codename].toString() })
              newAllIds.push(itemKey[node.id][child.codename].toString())
            } else {
              console.log('error key', node.id, child.codename)
            }
          })
          node.children = newChildren
        } else {
          console.log('error key:', node.id)
        }
        newNodes.push(node)
      })
    }
    setTreeNodes(newNodes)
    setaAllIds(newAllIds)
  }, [itemKey])

  return (
    <TabPanel value={"sys"}>
      <MultiSelectTreeView nodes={treeNodes}
        selected={initSelecteds}
        onSelectedChange={(ids: any) => {
          const unSelected = allIds.filter(each => ids.indexOf(each) === -1)
          onStatusChange && onStatusChange(ids, unSelected)
        }}
      />
    </TabPanel>
  )
}
export default PermissionView

import {Navigate, useRoutes} from "react-router-dom";
import MyScheduleRouter from "@/pages/Personal/outdoor-mangement/my-schedule/router";
import ScheduleTrackReport from "@/pages/Personal/report/components/ScheduleTrackReport";
import UnScheduleTrackReport from "@/pages/Personal/report/components/UnScheduleTrackReport";

const OutdoorManagementRouter = () => {
  const routes = useRoutes([
    {index: true, element: <Navigate to={"my-schedule"} replace/>},
    {path: "my-schedule/*", element: <MyScheduleRouter />},
    {path: "schedule-report/*", element: <ScheduleTrackReport />},
    {path: "unschedule-report/*", element: <UnScheduleTrackReport />},
  ])
  return routes
}
export default OutdoorManagementRouter;

import { RJSFSchema, UiSchema } from "@rjsf/utils";
import { useTranslation } from "react-i18next";
import validator from "@rjsf/validator-ajv8";
import LayoutForm from "@/components/rjsf/Form";
import React, {
  ForwardedRef,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import SnackbarUtil from "@/utils/SnackbarUtil";
import { Box } from "@mui/material";
import { useProgressContext } from "@/provider/ProgressProvider";
import GenericService from "@/services/core/GenericService";
import { downloadFile } from "@/utils";

const ReportExportAction = (props: Record<string, any>, ref: ForwardedRef<any>) => {
  const { t } = useTranslation();
  const formRef = useRef<any>();

  const {exportType, uri, reqData, reqParams, defaultData, formSchema, uiSchema} = props

  const progress = useProgressContext()
  const onConfirm = async () => {
    const isValid = formRef.current?.validateForm();
    if (isValid) {
      const data = formRef.current?.state.formData;
      const { key } = progress.open()
      progress.set(key, 'Starting...', 1)
      const response = await GenericService.post(`${uri}export/`, {...reqData, ...data} , {params: reqParams})
      if (response.isSuccess) {
        progress.set(key, 'Finish...', 100)
        await downloadFile(response.data.file)
        progress.close(key)
        return true;
      } else {
        progress.set(key, 'Error...', 100)
        console.log(response);
      }
    }
  };

  useImperativeHandle(ref, () => ({
    onConfirm,
  }));

  return (
    <Box sx={{ width: "560px", "height": "580px" }}>
      <LayoutForm
        ref={formRef}
        schema={formSchema}
        uiSchema={uiSchema}
        validator={validator}
        formData={defaultData}
      />
    </Box>
  );
};
export default React.forwardRef(ReportExportAction);

import {CustomValidator, RJSFSchema, UiSchema} from "@rjsf/utils";
import {useTranslation} from "react-i18next";
import RecordView from "@/components/views/RecordView";
import {ComplementEndpoint} from "@/services/complement/endpoints";
import RegExps from "@/utils/RegExps";

const IncidentTypeRecordView = () => {
  const {t} = useTranslation("complement");
  const {t: common} = useTranslation("common");
  const formSchema: RJSFSchema = {
    type: "object",
    required: [
      "category_name",
      "minimum_unit",
      "unit",
      "round_off",
      "report_symbol",
      "report_symbol_color",
    ],
    properties: {
      category_name: {
        type: "string",
        title: t("incidentType.incidentName"),
        maxLength: 50
      },
      minimum_unit: {
        type: "number",
        default: 1,
        title: t("incidentType.minimumUnit"),
      },
      unit: {
        type: "number",
        title: t("incidentType.unit"),
        default: 2,
        oneOf: [
          {const: 1, title: common("common.unit.minute")},
          {const: 2, title: common("common.unit.hour")},
          {const: 3, title: common("common.unit.workday")},
          {const: 4, title: common("common.unit.minuteFormat")},
        ],
      },
      round_off: {
        type: "number",
        default: 1,
        title: t("incidentType.roundOff"),
        oneOf: [
          {const: 0, title: t("boolean.no", {ns: "common"})},
          {const: 1, title: t("boolean.yes", {ns: "common"})},
        ],
      },
      report_symbol: {
        type: "string",
        title: t("incidentType.reportSymbol"),
        maxLength: 5
      },
      report_symbol_color: {
        type: "string",
        default: "#000000",
        title: t("incidentType.reportSymbolColor"),
      },
    },
  };

  // const customValidate: CustomValidator = (formData, errors, uiSchema) => {
  //   if (formData.category_name && !RegExps.alpInput.test(formData.category_name)) {
  //     errors.category_name?.addError(common("form.validateError.alpInputOnly"))
  //   }
  //   if (formData.report_symbol && !RegExps.alpInput.test(formData.report_symbol)) {
  //     errors.report_symbol?.addError(common("form.validateError.alpInputOnly"))
  //   }
  //   return errors
  // }

  const uiSchema: UiSchema = {
    "ui:defaultXsWidth": 4,
    report_symbol_color: {
      "ui:widget": "color",
    },
  };

  return (
    <RecordView
      formSchema={formSchema}
      uiSchema={uiSchema}
      uri={ComplementEndpoint.INCIDENT_TYPE_URI}
      // customValidate={customValidate}
    />
  );
};
export default IncidentTypeRecordView;

import {ObjectFieldTemplateProps} from "@rjsf/utils";
import {Box, Stack} from "@mui/material";
import {useTranslation} from "react-i18next";
import Grid from '@mui/material/Grid2';
import {ScrollTab, ScrollTabs, useScrollTabsHook} from "@/components/ScrollTabs";


const LayoutTemplate = (props: ObjectFieldTemplateProps) => {
  const {t} = useTranslation("per")
  const fields: Record<string, any> = {}
  props.properties?.forEach((element: any) => {
    fields[element.name] = element.content
  })

  const [tabs, getTabProps] = useScrollTabsHook([
    {key: 'profile', title: t("employee.profile")},
    {key: 'accountSetting', title: t("employee.accountSetting")},
    {key: 'personalInformation', title: t("employee.personalInformation")},
    {key: 'deviceSettings', title: t("employee.deviceSettings")},
    {key: 'attendanceSettings', title: t("employee.attendanceSettings")},
    {key: 'document', title: t("employee.documentSetting")},
    {key: 'mobileAppSettings', title: t("employee.mobileAppSettings")},
    {key: 'whatsAppSettings', title: t("employee.whatsAppSettings")},
  ])

  return (
    <ScrollTabs tabs={tabs}>
      <Stack spacing={3}>
        <ScrollTab {...getTabProps('profile')} >
          <Grid container spacing={2}>
            <Grid size={8}>
              <Box>
                <Grid container spacing={2}>
                  <Grid size={6}>{fields.emp_code}</Grid>
                  <Grid size={6}>{fields.email}</Grid>
                  <Grid size={6}>{fields.first_name}</Grid>
                  <Grid size={6}>{fields.last_name}</Grid>
                  <Grid size={6}>{fields.mobile}</Grid>
                  <Grid size={6}>{fields.hire_date}</Grid>
                  <Grid size={6}>{fields.department}</Grid>
                  <Grid size={6}>{fields.area}</Grid>
                  <Grid size={6}>{fields.position}</Grid>
                  <Grid size={6}>{fields.location}</Grid>
                  <Grid size={6}>{fields.enable_outdoor_management}</Grid>
                  <Grid size={6}>{fields.emp_type}</Grid>
                </Grid>
              </Box>
            </Grid>
            <Grid size={"grow"} sx={{alignItems: "center", justifyItems: "center"}}>
              {fields.photo}
            </Grid>
          </Grid>
        </ScrollTab>
        <ScrollTab {...getTabProps("accountSetting")}>
          <Grid container spacing={2}>
            <Grid size={4}>{fields.is_active}</Grid>
            <Grid size={4}>{fields.app_status}</Grid>
          </Grid>
        </ScrollTab>
        <ScrollTab {...getTabProps("personalInformation")}>
          <Grid container spacing={2}>
            <Grid size={4}>{fields.birthday}</Grid>
            <Grid size={4}>{fields.nickname}</Grid>
            <Grid size={4}>{fields.ssn}</Grid>
            <Grid size={4}>{fields.driver_license_motorcycle}</Grid>
            <Grid size={4}>{fields.contact_tel}</Grid>
            <Grid size={4}>{fields.office_tel}</Grid>
            <Grid size={4}>{fields.driver_license_automobile}</Grid>
            <Grid size={4}>{fields.card_no}</Grid>
            <Grid size={4}>{fields.religion}</Grid>
            <Grid size={4}>{fields.city}</Grid>
            <Grid size={4}>{fields.address}</Grid>
            <Grid size={4}>{fields.postcode}</Grid>
            <Grid size={4}>{fields.gender}</Grid>
            <Grid size={4}>{fields.passport}</Grid>
            <Grid size={4}>{fields.national}</Grid>
          </Grid>
        </ScrollTab>

        <ScrollTab {...getTabProps("deviceSettings")}>
          <Grid container spacing={2}>
            <Grid size={8}>
              <Box>
                <Grid container spacing={2}>
                  <Grid size={6}>{fields.verify_mode}</Grid>
                  <Grid size={6}>{fields.dev_privilege}</Grid>
                  <Grid size={6}>{fields.device_password}</Grid>
                  <Grid size={6}>{fields.enroll_sn}</Grid>
                </Grid>
              </Box>
            </Grid>
            <Grid size={"grow"} sx={{alignItems: "center", justifyItems: "center"}}>
              {fields.biophoto}
            </Grid>
          </Grid>
        </ScrollTab>

        <ScrollTab {...getTabProps("attendanceSettings")}>
          <Grid container spacing={2}>
            <Grid size={4}>{fields.enable_att}</Grid>
            <Grid size={4}>{fields.enable_holiday}</Grid>
            <Grid size={"grow"} />
            <Grid size={4}>{fields.flow_role}</Grid>
          </Grid>
        </ScrollTab>

        <ScrollTab {...getTabProps("document")}>
          <Grid container spacing={2}>
            <Grid size={8}>{fields.documents}</Grid>
          </Grid>
        </ScrollTab>

        <ScrollTab {...getTabProps("mobileAppSettings")}>
          <Grid container spacing={2}>
            {/*<Grid size={4}>{fields.app_status}</Grid>*/}
            <Grid size={4}>{fields.app_role}</Grid>
            <Grid size={8}/>
            <Grid size={4}>{fields.app_punch_status}</Grid>
            {/*<Grid size={4}>{fields.self_password}</Grid>*/}
          </Grid>
        </ScrollTab>

        <ScrollTab {...getTabProps("whatsAppSettings")}>
          <Grid container spacing={2}>
            <Grid size={4}>{fields.enable_whatsapp}</Grid>
            <Grid size={8} />
            <Grid size={4}>{fields.whatsapp_exception}</Grid>
            <Grid size={4}>{fields.whatsapp_punch}</Grid>
            <Grid size={4} />
            <Grid size={4}>{fields.supervisor_mobile}</Grid>
          </Grid>
        </ScrollTab>

      </Stack>
    </ScrollTabs>
  )
}
export default LayoutTemplate

import React from "react";
import {Button, Stack} from "@mui/material";
import Typography from '@mui/material/Typography';
import ContactUs from "@/pages/OnlineSubscription/MESubscription/Pricing/components/ContactUs";
import {useTranslation} from "react-i18next";
import SubscriptionPlan from "@/pages/OnlineSubscription/MESubscription/Pricing/components/Plan";
import Grid from "@mui/material/Grid2";
import {useDialogContext} from "@/provider/DialogProvider";


const MESubscriptionPricing = () => {
  const {t} = useTranslation('sub')
  const dialog = useDialogContext()
  const onContactUs = () => {
    dialog.showDialog({
      title: t('onlineSubscription.contactUs'),
      content: <ContactUs/>,
      disableAction: true
    })
  };

  return (
    <Grid container={true} spacing={2}>
      <Grid
        size={12}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          p: 4
        }}
      >
        <SubscriptionPlan/>
      </Grid>
      <Grid size={12}>
        <Stack spacing={2} sx={{alignItems: "center"}}>
          <Typography variant="h5" color={'primary'}>
            {t('onlineSubscription.me.helpText')}
          </Typography>
          <Button variant="contained" onClick={onContactUs} sx={{width: 120}}>
            {t('onlineSubscription.contactUs')}
          </Button>
        </Stack>
      </Grid>
    </Grid>
  );
};

export default MESubscriptionPricing;




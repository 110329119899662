import {useTranslation} from "react-i18next";
import {GridColDef} from "@mui/x-data-grid";
import ListView from "@/components/views/ListView";
import AppUrls from "@/urls";
import {ReportEndPoint} from "@/services/report/endpoints";

const PunchStateSettingListView = () => {
  const { t: t } = useTranslation("report");
  const { t: tMenu } = useTranslation("menu");
  const columns: GridColDef[] = [
    {
      field: "key",
      headerName: t("report.punchStateSetting.key"),
      flex: 1,
    },
    { field: "value", headerName: t("report.punchStateSetting.value"), flex: 1 },
    { field: "name", headerName: t("report.punchStateSetting.name"), flex: 1 },
  ];

  return (
    <ListView
      disableDelete
      disableAdd
      title={tMenu("menu.report.punchStateSetting", {ns: "menu"})}
      breadcrumbs={[{ title: tMenu("menu.report"), href: AppUrls.report.root }]}
      columns={columns}
      uri={ReportEndPoint.PUNCH_STATE_SETTING_URI}
      url={AppUrls.report.punchStateSetting}
    />
  );
};
export default PunchStateSettingListView;

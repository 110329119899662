import {createContext, useCallback, useContext, useEffect, useState} from "react";
import {useDialogContext} from "@/provider/DialogProvider";
import MapView from "@/provider/MapProvider/MapView";

const MapContext = createContext<Record<string, any>>({})

export const useMapContext = () => {
  const context = useContext(MapContext)
  if (!context) {
    throw new Error('The map provider not found!')
  }
  return context
}

const MapProvider = (props: Record<string, any>) => {
  const dialog = useDialogContext()
  const [mapInfo, setMapInfo] = useState<Record<string, any>>()
  const [position, setPosition] = useState<any>({
    lat: 10.99835602,
    lng: 77.01502627
  })
  const updateLocation = async (data: any) => {
    console.log("MapProvider", data)
    setMapInfo({...data})
  }
  const showMapView = useCallback(async () => {
    dialog.showDialog({
      title: "Map Location",
      Form: MapView,
      formProps: {
        position
      },
      afterConfirm: updateLocation
    })
  }, [position])

  useEffect(() => {
    navigator.geolocation.getCurrentPosition((pos) => {
      const coords = {
        lat: pos.coords.latitude,
        lng: pos.coords.longitude,
      };
      setPosition(coords);
    })
  }, []);

  return (
    <MapContext.Provider
      value={{
        mapInfo,
        setMapInfo,
        showMapView,
      }}
    >
      {props.children}
    </MapContext.Provider>
  )
}
export default MapProvider

import React, {useState} from "react";
import {Box, Button, Drawer, Tooltip} from "@mui/material";
import {useAppContext} from "@/provider/AppProvider";
import {Apartment} from "@mui/icons-material";
import SwitchCompany from "@/layout/Header/components/SwitchCompany";
import {useTranslation} from "react-i18next";

const CompanyProfile: React.FC = () => {
  const {t} = useTranslation("sys")
  const {currentRole, currentCompany} = useAppContext()
  const [drawer, setDrawer] = useState<any>({open: false, children: []})
  const onClose = async () => {
    setDrawer({open: false, children: []})
  }
  return (
    <Box sx={{display: "flex", alignItems: "center", height: "100%"}}>
      <Tooltip title={currentRole === "employee"? currentCompany?.name: t("account.switchCompany")}>
        <Button
          startIcon={<Apartment/>}
          onClick={async () => {
            if(currentRole === "employee"){
              return
            }
            setDrawer({open: true, children: [<SwitchCompany onClose={onClose}/>]})
          }}
          color={"primary"}
          sx={{
            fontSize: 16,
            textTransform: "uppercase"
          }}
        >
          {currentCompany?.name}
        </Button>
      </Tooltip>
      <Drawer open={drawer.open} anchor={"right"}>
        {drawer.children}
      </Drawer>
    </Box>
)
}
export default CompanyProfile

import { TabPanel } from "@mui/lab";
import MultiSelectTreeView from "./MultiSelectTreeView";
import { useEffect, useState } from "react";


const PermissionView = (props: any) => {
  const { itemKey, onStatusChange, initSelecteds } = props

  const nodes: any[] = [
    {
      "id": "personnel.department",
      "label": "Department",
      "children": [
        {
          "codename": "change_department",
          "label": "change_department"
        },
        {
          "codename": "add_department",
          "label": "add_department"
        },
        {
          "codename": "delete_department",
          "label": "delete_department"
        },
        {
          "codename": "view_department",
          "label": "view_department"
        },
        {
          "codename": "import_department",
          "label": "import_department"
        },
        {
          "codename": "set_department_department",
          "label": "set_department_department"
        }
      ]
    },
    {
      "id": "personnel.position",
      "label": "Position",
      "children": [
        {
          "codename": "view_position",
          "label": "view_position"
        },
        {
          "codename": "import_position",
          "label": "import_position"
        },
        {
          "codename": "add_position",
          "label": "add_position"
        },
        {
          "codename": "change_position",
          "label": "change_position"
        },
        {
          "codename": "delete_position",
          "label": "delete_position"
        },
        {
          "codename": "set_position_position",
          "label": "set_position_position"
        }
      ]
    },
    {
      "id": "personnel.area",
      "label": "Area",
      "children": [
        {
          "codename": "change_area",
          "label": "change_area"
        },
        {
          "codename": "delete_area",
          "label": "delete_area"
        },
        {
          "codename": "view_area",
          "label": "view_area"
        },
        {
          "codename": "import_area",
          "label": "import_area"
        },
        {
          "codename": "set_area_area",
          "label": "set_area_area"
        },
        {
          "codename": "add_area",
          "label": "add_area"
        }
      ]
    },
    {
      "id": "personnel.location",
      "label": "Location",
      "children": [
        {
          "codename": "change_location",
          "label": "change_location"
        },
        {
          "codename": "set_location_location",
          "label": "set_location_location"
        },
        {
          "codename": "view_location",
          "label": "view_location"
        },
        {
          "codename": "delete_location",
          "label": "delete_location"
        },
        {
          "codename": "add_location",
          "label": "add_location"
        }
      ]
    }
  ]

  const [treeNodes, setTreeNodes] = useState<any[]>([]);
  const [allIds, setaAllIds] = useState<any[]>([]);
  useEffect(() => {
    const newNodes: any = []
    const newAllIds: any = []
    if (Object.keys(itemKey).length > 0) {
      nodes.forEach((node) => {
        const newChildren: any = []
        if (itemKey[node.id]) {
          node.children.forEach((child: any) => {
            if (itemKey[node.id][child.codename]) {
              newChildren.push({ ...child, id: itemKey[node.id][child.codename].toString() })
              newAllIds.push(itemKey[node.id][child.codename].toString())
            } else {
              console.log('error key', node.id, child.codename)
            }
          })
          node.children = newChildren
        } else {
          console.log('error key:', node.id)
        }
        newNodes.push(node)
      })
    }
    setTreeNodes(newNodes)
    setaAllIds(newAllIds)
  }, [itemKey])

  return (
    <TabPanel value={"company"}>
      <MultiSelectTreeView nodes={treeNodes}
        selected={initSelecteds}
        onSelectedChange={(ids: any) => {
          const unSelected = allIds.filter(each => ids.indexOf(each) === -1)
          onStatusChange && onStatusChange(ids, unSelected)
        }}
      />
    </TabPanel>
  )
}
export default PermissionView

import {ObjectFieldTemplateProps} from "@rjsf/utils";
import Grid from "@mui/material/Grid2";


const RecordViewLayout = (props: ObjectFieldTemplateProps) => {
  const fields: Record<string, any> = {}
  props.properties?.forEach((element: any) => { fields[element.name] = element.content })
  return (
    <Grid container spacing={2} sx={{ height: "100%", marginTop: 0 }}>
      <Grid size={4}>{fields.type}</Grid>
      <Grid size={4}>{fields.effective_status}</Grid>
       <Grid size={4} />
      <Grid size={4}>{fields.subscription_duration_unit_price_pre_month}</Grid>
      <Grid size={4}>{fields.currency}</Grid>
      <Grid size={4} />
      <Grid size={4}>{fields.employee_threshold}</Grid>
      <Grid size={4}>{fields.employee_unit_price}</Grid>
      <Grid size={4} />
      <Grid size={4}>{fields.device_threshold}</Grid>
      <Grid size={4}>{fields.device_unit_price}</Grid>
      <Grid size={4} />
      <Grid size={4}>{fields.whatsapp_push_counts_limit_status}</Grid>
      <Grid size={4}>{fields.whatsapp_push_threshold}</Grid>
      <Grid size={4}>{fields.whatsapp_push_unit_price}</Grid>

      <Grid size={4}>{fields.mobile_app_counts_limit_status}</Grid>
      <Grid size={4}>{fields.mobile_applications_threshold}</Grid>
      <Grid size={4}>{fields.mobile_applications_unit_price}</Grid>

      <Grid size={4}>{fields.transaction_counts_limit_status}</Grid>
      <Grid size={4}>{fields.transaction_threshold}</Grid>
      <Grid size={4}>{fields.transaction_unit_price}</Grid>

      <Grid size={4}>{fields.country}</Grid>
      <Grid size={4}>{fields.vendor}</Grid>
      <Grid size={4}>{fields.discount}</Grid>

      <Grid size={8}>{fields.feature}</Grid>
    </Grid>
  )
}
export default RecordViewLayout

import {useTranslation} from "react-i18next";
import {GridColDef} from "@mui/x-data-grid";
import ListView from "@/components/views/ListView";
import {VendorEndPoint} from "@/services/vendor/endpoints";
import AppUrls from "@/urls";
import {Visibility} from "@mui/icons-material";
import RecordReadOnlyView from "@/pages/vendor/tenement/Company/components/RecordReadOnlyView";


const CompanyListView = () => {
  const {t} = useTranslation("vendor")
  const {t: menu} = useTranslation("menu")

  const columns: GridColDef[] = [
    {field: "name", headerName: t("company.name"), flex: 1},
    {field: "country_name", headerName: t("company.country"), flex: 1},
    {field: "province", headerName: t("company.province"), flex: 1},
    {field: "city", headerName: t("company.city"), flex: 1},
    {field: "company_phone", headerName: t("company.companyPhone"), flex: 1},
    {field: "company_address", headerName: t("company.companyAddress"), flex: 1},
    {field: "user_count", headerName: t("company.userCount"), flex: 1},
    {field: "employee_count", headerName: t("company.employeeCount"), flex: 1},
    {field: "device_count", headerName: t("company.deviceCount"), flex: 1},
    {field: "app_count", headerName: t("company.appCount"), flex: 1},
    {field: "vendor_status", headerName: t("company.vendorStatus"), flex: 1},
    {field: "create_date", headerName: t("company.createDate"), flex: 1},
    {field: "expiration_date", headerName: t("company.expirationDate"), flex: 1},
    {field: "admin_email", headerName: t("company.adminEmail"), flex: 1},
    {field: "admin_mobile", headerName: t("company.adminMobile"), flex: 1},
    {field: "paid_company", headerName: t("company.paidCompany"), flex: 1}
  ]

  const filters = [
    {key: 'alias', label: t("device.alias"), type: 'string'},
    {key: 'paid_company', label: t("company.paid"), type: 'string'},
  ]

    const recordActions = [
    {id: "view", icon: <Visibility />, title: "View", display: "dialog", readOnly: true, Form: RecordReadOnlyView}
  ]

  return (
    <ListView
     title={menu("menu.tenement.company")}
      breadcrumbs={[
        {title: menu("menu.tenement"), href: AppUrls.tenement.root},
      ]}
      recordActions={recordActions}
      columns={columns}
      uri={VendorEndPoint.COMPANY_URI}
      url={AppUrls.vendor.company}
      filters={filters}
      disableAdd={true}
      // disableEdit={true}
    />
  )
}
export default CompanyListView

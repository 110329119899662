import {useTranslation} from "react-i18next";
import {GridColDef} from "@mui/x-data-grid";
import ListView from "@/components/views/ListView";
import AppUrls from "@/urls";
import {ComplementEndpoint} from "@/services/complement/endpoints";


const HolidayListView = () => {
  const {t} = useTranslation("complement")
  const columns: GridColDef[] = [
    {field: "alias", headerName: t("holiday.name"), flex: 1},
    {field: "start_date", headerName: t("holiday.startDate"), flex: 1},
    {field: "duration_day", headerName: t("holiday.durationDay"), flex: 1},
    {
      field: "work_type",
      headerName: t("holiday.workType"),
      flex: 1,
      type: "singleSelect",
      valueOptions: [
        {value: 0, label: t("workType.ignore")},
        {value: 1, label: t("workType.normalWorkTime")},
        {value: 2, label: t("workType.weekdayOvertime")},
        {value: 3, label: t("workType.weekOffOvertime")},
        {value: 4, label: t("workType.holidayOvertime")},
      ]
    },
    {field: "location_name", headerName: t("holiday.location"), flex: 1},
  ]

  const filters = [
    {key: 'alias', label: t("holiday.name"), type: 'string'},
  ]

  return (
    <ListView
      title={t("menu.holiday", {ns: "menu"})}
      columns={columns}
      uri={ComplementEndpoint.HOLIDAY_URI}
      url={AppUrls.complement.holiday}
      filters={filters}
    />
  )
}
export default HolidayListView

import {Route, Routes} from "react-router-dom";
import NormalTimeTable from "./NormalTimeTable";
import ListView from "./ListView";
import FlexibleTimeTable from "./FlexibleTimeTable";
import TimeTableRecordView from "./RecordView";


const SubRouter = () => {
  return (
    <Routes>
      <Route index element={<ListView />}/>
      <Route path={"flexible/add"} element={<FlexibleTimeTable />}/>
      <Route path={"normal/add"} element={<NormalTimeTable />}/>
      <Route path={"edit"} element={<TimeTableRecordView />}/>
    </Routes>
  )
};
export default SubRouter

import {Route, Routes} from "react-router-dom";
import AdminLogListView from "@/pages/SystemSetting/AdminLog/ListView";


const AdminLogRouter = () => {
  return (
    <Routes>
      <Route index element={<AdminLogListView />}/>
    </Routes>
  )
};
export default AdminLogRouter

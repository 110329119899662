import {useTranslation} from "react-i18next";
import {GridColDef} from "@mui/x-data-grid";
import {ReportEndPoint} from "@/services/report/endpoints";
import urls from "@/urls";
import ReportView from "@/pages/Report/ReportLibrary/components/ReportView";
import { useReportEmployee } from "@/hooks/employee";


const LeaveDetailReport = () => {
  const {t} = useTranslation("report")
  const dangerouslySetInnerHTML = (params: any) => {
    return <div dangerouslySetInnerHTML={{ __html: params.value }} />
  }
  const employeeFields = useReportEmployee()
  const columns: GridColDef[] = [
    ...employeeFields,
    { field: "allotment_type", headerName: t("report.allotmentType")},
    { field: "effective_date", headerName: t("report.effectiveDate")},
    { field: "increment_date", headerName: t("report.incrementDate")},
    { field: "days", headerName: t("report.days")},
    { field: "max_leave_per_month", headerName: t("report.maxLeavePerMonth")},
    { field: "carry_forward_limit", headerName: t("report.carryForwardLimit")},
    { field: "leave_availed", headerName: t("report.leaveAvailed")},
    { field: "leave_balance", headerName: t("report.leaveBalance")},
  ]
  return (
    <ReportView
      title={t("report.leaveDetails")}
      breadcrumbs={[]}
      columns={columns}
      uri={ReportEndPoint.LEAVE_DETAIL_URI}
      url={urls.att.leaveDetailReport}
      // getRowId={(row: any) => row.uuid}
      hideColumns={['first_name', 'last_name', 'department_code', 'position_code', 'position_name']}
    />
  )
}
export default LeaveDetailReport

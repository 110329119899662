import { Route, Routes } from "react-router-dom";
import AutoExportListView from "@/pages/SystemSetting/AutoExport/ListView";
import AutoExportRecordView from "@/pages/SystemSetting/AutoExport/RecordView";

const AutoExportRouter = () => {
  return (
    <Routes>
      <Route index element={<AutoExportListView />} />
      <Route path={"add/"} element={<AutoExportRecordView />} />
      <Route path={"edit/"} element={<AutoExportRecordView />} />
    </Routes>
  );
};
export default AutoExportRouter;

import ListView from "@/components/views/ListView";
import {GridColDef} from "@mui/x-data-grid";
import AppUrls from "@/urls";
import {DeviceEndPoint} from "@/services/device/endpoints";
import {Avatar, Box} from "@mui/material";
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import ApprovalIcon from '@mui/icons-material/Approval';
import ApproveAction from "./components/ApproveAction";
import {useTranslation} from "react-i18next";

const BioPhotoListView = () => {
  const {t} = useTranslation("device");
  const {t: per} = useTranslation("per");
  const columns: GridColDef[] = [
    {field: "emp_code", headerName: per("employee.code"), flex: 1},
    {field: "first_name", headerName: per("employee.firstName"), flex: 1},
    {field: "last_name", headerName: per("employee.lastName"), flex: 1},
    {field: "email", headerName: per("employee.email"), flex: 1},
    {field: "enroll_sn", headerName: t("device.sn"), flex: 1},
    {
      field: "user_photo", headerName: t("bioPhoto.userPhoto"), flex: 1, renderCell: (params) => {
        return (
          <Box>
            <Avatar
              variant="square"
              src={params.row.user_photo ? `/web/files/${params.row.user_photo}` : ''}
              sx={{width: "96px", height: "96px"}}
            />
          </Box>
        )
      }
    },
    {
      field: "approval_photo", headerName: t("bioPhoto.approvalPhoto"), flex: 1, renderCell: (params) => {
        return (
          <Box>
            <Avatar
              variant="square"
              src={params.row.register_photo_display ? `/web/files/${params.row.register_photo_display}` : ''}
              sx={{width: "96px", height: "96px"}}/>
          </Box>
        )
      }
    },
    {field: "register_time", headerName: t("bioPhoto.registerTime"), flex: 1},
    {field: "remark", headerName: t("bioPhoto.remark"), flex: 1},
    {field: "approval_state_display", headerName: t("bioPhoto.approvalState"), flex: 1},
    {field: "approval_time", headerName: t("bioPhoto.approvalTime"), flex: 1},
  ];

  const filters = [
    {
      key: "employee__emp_code",
      label: per("employee.code"),
      type: "string",
    },
    {
      key: "employee__first_name",
      label: per("employee.firstName"),
      type: "string",
    },
  ];

  const actions = [
    {
      key: "approve",
      title: t("common.action.approve", {ns: "common"}),
      validateSelected: true,
      icon: <ApprovalIcon/>,
      display: "dialog",
      Form: ApproveAction
    },
    {
      id: "import",
      title: t("common.action.import", {ns: "common"}),
      icon: <FileUploadOutlinedIcon/>,
      display: "page",
      url: `${AppUrls.device.bioPhoto}/import`,
    },
  ]

  return (
    <ListView
      disableAdd
      disableEdit
      title={t("menu.device.bioPhoto", {ns: "menu"})}
      columns={columns}
      uri={DeviceEndPoint.BIO_PHOTO_URI}
      url={AppUrls.device.bioPhoto}
      filters={filters}
      modelActions={actions}
      dataGrid={{
        rowHeight: 98,
      }}
    />
  );
};
export default BioPhotoListView;

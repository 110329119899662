import {Navigate, useRoutes} from "react-router-dom";
import OrderRouter from "@/pages/vendor/subscription/Order/router";
import FeatureRouter from "@/pages/vendor/subscription/Feature/router";
import DiscountRouter from "@/pages/vendor/subscription/Discount/router";
import PriceStrategyRouter from "@/pages/vendor/subscription/PriceStrategy/router";


const SubscriptionRouter = () => {
  const routes = useRoutes([
    {index: true, element: <Navigate to={"order"} replace/>},
    {path: "feature/*", element: <FeatureRouter/>},
    {path: "discount/*", element: <DiscountRouter/>},
    {path: "price-strategy/*", element: <PriceStrategyRouter/>},
    {path: "order/*", element: <OrderRouter/>},
  ])
  return routes
};
export default SubscriptionRouter

import {ObjectFieldTemplateProps} from "@rjsf/utils";
import Grid from "@mui/material/Grid2";


const RecordViewLayout = (props: ObjectFieldTemplateProps) => {
  const fields: Record<string, any> = {}
  props.properties?.forEach((element: any) => { fields[element.name] = element.content })
  return (
    <Grid container spacing={2} sx={{ height: "100%", marginTop: 0 }}>
      <Grid size={4}>{fields.company}</Grid>
      <Grid size={8} />
      <Grid size={4}>{fields.price_strategy}</Grid>
      <Grid size={4}>{fields.subscription_duration}</Grid>
      <Grid size={4} />
      <Grid size={4}>{fields.employees_count}</Grid>
      <Grid size={4}>{fields.devices_count}</Grid>
      <Grid size={4} />
      <Grid size={4}>{fields.mobile_applications_count}</Grid>
      <Grid size={8} />
      <Grid size={4}>{fields.whatsapp_push_count}</Grid>
      <Grid size={4}>{fields.transactions_count}</Grid>
      <Grid size={4} />

      <Grid size={8}>{fields.description}</Grid>
    </Grid>
  )
}
export default RecordViewLayout

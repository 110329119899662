import ListView from "@/components/views/ListView";
import {GridColDef} from "@mui/x-data-grid";
import AppUrls from "@/urls";
import {DeviceEndPoint} from "@/services/device/endpoints";
import {useTranslation} from "react-i18next";

const OperationLogListView = () => {
  const {t} = useTranslation("device");
  const columns: GridColDef[] = [
    {field: "sn", headerName: t("device.sn"), flex: 1},
    {field: "alias", headerName: t("device.alias"), flex: 1},
    {field: "terminal_tz", headerName: t("operationLog.terminalTz"), flex: 1},
    {field: "admin", headerName: t("operationLog.admin"), flex: 1},
    {field: "action_name", headerName: t("operationLog.actionName"), flex: 1},
    {field: "object", headerName: t("operationLog.object"), flex: 1},
    {field: "param1", headerName: t("operationLog.param"), flex: 1},
    {
      field: "action_time",
      headerName: t("operationLog.actionTime"),
      flex: 1,
    },
    {
      field: "upload_time",
      headerName: t("operationLog.uploadTime"),
      flex: 1,
    },
  ];

  const filters = [
    {
      key: "terminal__sn",
      label: t("device.sn"),
      type: "string",
    },
    {
      key: "terminal__alias",
      label: t("device.alias"),
      type: "string",
    },
    {
      key: "admin",
      label: t("operationLog.admin"),
      type: "string",
    },
  ];

  return (
    <ListView
      disableAdd
      disableEdit
      title={t("menu.device.operationLog", {ns: "menu"})}
      columns={columns}
      uri={DeviceEndPoint.OPERATION_LOG_URI}
      url={AppUrls.device.operationLog}
      filters={filters}
      excludeActions={["delete"]}
    />
  );
};
export default OperationLogListView;

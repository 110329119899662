import {createTheme, PaletteMode, ThemeOptions} from "@mui/material";

import 'typeface-roboto';
import {useRegionContext} from "@/provider/RegionProvider";


const mainColor = "#ffffff";
// const mainColor = "#474b4f"
const primaryColor = "#7ac142";


const theme = createTheme({
  palette: {
    mode: "light",
    primary: {
      main: primaryColor,
      light: primaryColor,
      contrastText: '#ffffff',
    },
    secondary: {
      main: '#020202FF'
    },
    mainColor: {
      main: mainColor,
      light: mainColor,
    }
  },
  components: {
    MuiIconButton: {
      styleOverrides: {}
    }
  },
  typography: {
    fontFamily: "Roboto"
  }
})

export default theme


export const customTheme = (mode: PaletteMode, region: Record<string, any>) => {
  // const primaryColor = region.identify_code === "me"? "#7ac142": "#2E85CF"
  const primaryColor = "#0E5B9D"
  const lightThemeOptions: ThemeOptions = {
    palette: {
      mode: "light",
      primary: {
        main: primaryColor,
        light: primaryColor,
        contrastText: '#ffffff',
      },
      secondary: {
        main: '#FC4C02'
      },
      mainColor: {
        main: mainColor,
        light: mainColor,
      }
    },
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            textTransform: "none"
          }
        },
      },
    },
    typography: {
      fontFamily: "Poppins, SemiBold",
      fontSize: 14,
      h3: {
        fontSize: 20,
        fontWeight: 600,
        color: "#0F3F62"
      },
      h4: {
        fontSize: 18,
        color: "#0F3F62"
      },
      h5: {
        fontSize: 14,
        color: "#0F3F62"
      }
    },
  }

  const darkThemeOptions: ThemeOptions = {
    palette: {
      mode: "dark",
      primary: {
        main: primaryColor,
        light: primaryColor,
        contrastText: '#ffffff',
      },
      secondary: {
        main: '#ffffff'
      },
      mainColor: {
        main: mainColor,
        light: mainColor,
      }
    },
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            textTransform: "none"
          }
        },
      },
    },
    typography: {
      fontFamily: "Roboto"
    }
  }
  const options = (mode === "light" ? lightThemeOptions : darkThemeOptions)
  return createTheme(options)
}

import { Navigate, Route, Routes, useRoutes } from "react-router-dom";
import UserRouter from "@/pages/SystemSetting/User/router";
import GroupRouter from "@/pages/SystemSetting/Group/router";
import AdminLogRouter from "@/pages/SystemSetting/AdminLog/router";
import PDFReportSetting from "@/pages/SystemSetting/PDFReportSetting";
import EmailSetting from "@/pages/SystemSetting/EmailSetting";
import WhatsAppSetting from "@/pages/SystemSetting/WhatsAppSetting";
import AlertSetting from "@/pages/SystemSetting/AlertSetting";
import AutoExportRouter from "@/pages/SystemSetting/AutoExport/router";

const SystemSettingRouter = () => {
  // const routes = useRoutes([
  //   {index: true, element: <Navigate to={"user"} replace/>},
  //   {path: "group/*", element: <GroupRouter/>},
  //   {path: "user/*", element: <UserRouter/>},
  //   {path: "operation-log/*", element: <AdminLogRouter/>},
  //   {path: "pdf-setting/*", element: <PDFReportSetting/>},
  //   {path: "email-setting/*", element: <EmailSetting/>},
  //   {path: "whatsapp-setting/*", element: <WhatsAppSetting/>},
  //   {path: "alert-setting/*", element: <AlertSetting/>},
  // ])
  // return routes
  return (
    <Routes>
      <Route index element={<Navigate to={"user"} replace />} />
      <Route path={"group/*"} element={<GroupRouter />} />
      <Route path={"user/*"} element={<UserRouter />} />
      <Route path={"operation-log/*"} element={<AdminLogRouter />} />
      <Route path={"pdf-setting"} element={<PDFReportSetting />} />
      <Route path={"email-setting"} element={<EmailSetting />} />
      <Route path={"whatsapp-setting"} element={<WhatsAppSetting />} />
      <Route path={"alert-setting"} element={<AlertSetting />} />
      <Route path={"auto-export/*"} element={<AutoExportRouter />} />
    </Routes>
  );
};
export default SystemSettingRouter;

import {useTranslation} from "react-i18next";
import {GridColDef} from "@mui/x-data-grid";
import ListView from "@/components/views/ListView";
import AppUrls from "@/urls";
import RecordPanel from "@/pages/Personal/outdoor-mangement/my-schedule/components/RecordPanel";
import {PersonalEndPoint} from "@/services/personal/endpoints";
import FilterPanel from "@/pages/Personal/request/components/FilterPanel";

const MyScheduleListView = () => {
  const {t: gps} = useTranslation("gps")
  const {t: menu} = useTranslation("menu")
  const columns: GridColDef[] = [
    {field: "schedule_name", headerName: gps("schedulePlanner.name"), flex: 1},
    {field: "start_date", headerName: gps("outdoorSchedule.startDate"), flex: 1},
    {field: "end_date", headerName: gps("outdoorSchedule.endDate"), flex: 1},
  ]
  return (
    <ListView
      title={menu("menu.personal.mySchedule")}
      columns={columns}
      uri={PersonalEndPoint.OUTDOOR_SCHEDULE_URI}
      url={AppUrls.personal.outdoorManagement.mySchedule}
      RecordPanel={RecordPanel}
      disableHistory={true}
      disableAdd={true}
      disableEdit={true}
      disableDelete={true}
      disableExport
      FilterPanel={FilterPanel}
    />
  )
}
export default MyScheduleListView

import {useTranslation} from "react-i18next";
import {GridColDef} from "@mui/x-data-grid";
import AppUrls from "@/urls";
import {AuthEP} from "@/services/auth/endpoints";
import ListView from "@/components/views/ListView";


const GroupListView = () => {
  const {t} = useTranslation("sys")
  const columns: GridColDef[] = [
    {field: "name", headerName: t("group.name"), flex: 1},
  ]

  const filters = [
    {key: 'name', label: t("group.name"), type: 'string'},
  ]

  return (
    <ListView
      title={t("menu.systemSetting.group", {ns: "menu"})}
      columns={columns}
      uri={AuthEP.GROUP_URI}
      url={AppUrls.system.group}
      filters={filters}
    />
  )
}
export default GroupListView

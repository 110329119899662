import {useRoutes} from "react-router-dom";
import CombinationListView from "./ListView";
import CombinationRecordView from "@/pages/AccessControl/combination/RecordView";


const CombinationRouter = () => {
  return useRoutes([
    {index: true, element: <CombinationListView />},
    {path: "edit", element: <CombinationRecordView />},
  ])
};
export default CombinationRouter

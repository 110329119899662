import {useTranslation} from "react-i18next";
import {useLocation, useNavigate} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {CustomValidator, RJSFSchema, UiSchema} from "@rjsf/utils";
import CompanyWidget from "@/pages/Oauth/components/CompanyWidget";
import LayoutForm from "@/components/rjsf/Form";
import Layout from "@/pages/Oauth/Layout";
import OauthService from "@/services/oauth/OauthService";
import SnackbarUtil from "@/utils/SnackbarUtil";
import urls from "@/urls";
import SubmitButton from "@/pages/Oauth/components/SubmitButton";
import RegExps from "@/utils/RegExps";


const CreateCompanyForm = () => {
  const {t} = useTranslation("oauth");
  const {t: common} = useTranslation("common");
  const {t: api} = useTranslation("api");
  const navigate = useNavigate()
  const params = useLocation()
  const verifyCode = params.state?.verifyCode
  const [loading, setLoading] = useState<boolean>(false)
  const formRef = React.useRef<any>()
  const formSchema: RJSFSchema = {
    type: "object",
    properties: {
      company: {
        type: "string",
        title: t("oauth.companyName"),
        maxLength: 50
      }
    }
  }
  const uiSchema: UiSchema = {
    company: {
      "ui:widget": "company",
      "ui:placeholder": t("oauth.companyName"),
    }
  }
  const widgets = {
    company: CompanyWidget
  }

  const customValidate: CustomValidator = (formData, errors, uiSchema) => {
    if(formData.company && !RegExps.code.test(formData.company)){
      errors.company?.addError(t("oauth.validateError.invalidCompanyName"))
    }
    return errors
  }
  const onCreateCompany = async () => {
    setLoading(true)
    const isValid = formRef.current?.validateForm()
    if (isValid) {
      const data = formRef.current?.state.formData
      OauthService.createCompany({...data, code: verifyCode}).then((response) => {
        if (response.isSuccess) {
          SnackbarUtil.success(common("common.message.successful"))
          navigate(urls.companyVerification)
        } else {
          console.log(response)
        }
      }).finally(() => {
        setLoading(false)
      })
    }else{
      setLoading(false)
    }
  }

  useEffect(() => {
    if (!verifyCode) {
      navigate(urls.companyVerification)
    }
  }, [])

  return (
    <Layout title={t("oauth.createCompany.title")}>
      <LayoutForm
        ref={formRef}
        schema={formSchema}
        uiSchema={uiSchema}
        widgets={widgets}
        customValidate={customValidate}
        formContext={{
          onSubmit: onCreateCompany
        }}
      />
      <SubmitButton
        loading={loading}
        onClick={onCreateCompany}
      >
        {t("oauth.createCompany.continue")}
      </SubmitButton>
    </Layout>
  )
}
export default CreateCompanyForm

import {Navigate, Route, Routes} from "react-router-dom";
import AccountsRouter from "@/pages/GPS/Accounts/router";
import AnnouncementRouter from "@/pages/GPS/Announcement/router";
import NoticeRouter from "@/pages/GPS/Notice/router";
import OperationLogRouter from "@/pages/GPS/OperationLog/router";
import GPSOfEmployeeRouter from "@/pages/GPS/GPSOfEmployee/router";
import GPSOfDepartmentRouter from "@/pages/GPS/GPSOfDepartment/router";
import ClientDetailsRouter from "@/pages/GPS/ClientDetails/router";
import SchedulePlannerRouter from "@/pages/GPS/schedule-planner/router";
import OutdoorScheduleRouter from "@/pages/GPS/outdoor-schedule/router";
import ScheduleTrackReport from "@/pages/Report/ReportLibrary/components/ScheduleTrackReport";
import UnScheduleTrackReport from "@/pages/Report/ReportLibrary/components/UnScheduleTrackReport";

const GPSManagementRouter = () => {
  return (
    <Routes>
      <Route index element={<Navigate to={"accounts"} replace />} />
      <Route path={"accounts/*"} element={<AccountsRouter />} />
      <Route path={"announcement/*"} element={<AnnouncementRouter />} />
      <Route path={"notice/*"} element={<NoticeRouter />} />
      <Route path={"operation-log/*"} element={<OperationLogRouter />} />
      <Route path={"gps-of-employee/*"} element={<GPSOfEmployeeRouter />} />
      <Route path={"gps-of-department/*"} element={<GPSOfDepartmentRouter />} />
      <Route path={"client-details/*"} element={<ClientDetailsRouter />} />
      <Route path={"schedule-planner/*"} element={<SchedulePlannerRouter />} />
      <Route path={"outdoor-schedule/*"} element={<OutdoorScheduleRouter />} />
      <Route path={"schedule-report/*"} element={<ScheduleTrackReport />} />
      <Route path={"unschedule-report/*"} element={<UnScheduleTrackReport />} />
    </Routes>
  );
};
export default GPSManagementRouter;

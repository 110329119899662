import {InputAdornment, TextField} from "@mui/material";
import {WidgetProps} from "@rjsf/utils";


const HourWidget = (props: WidgetProps) => {
  const {id, name, value, label, placeholder, onChange, formContext, readonly, disabled, required, uiSchema} = props
  return (
    <TextField
      fullWidth
      required={required}
      id={id}
      name={name}
      value={value}
      label={label}
      InputProps={{
        readOnly: readonly,
        disabled,
        placeholder,
        endAdornment:<InputAdornment position={"end"}>{"Hours"}</InputAdornment>
      }}
      onChange={async (event) => {
        onChange(event.target.value)
      }}
      onKeyUp={(event) => {
        if (event.code === "Enter") {
          formContext.onSubmit?.()
        }
      }}
    />
  )
}
export default HourWidget

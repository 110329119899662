const API_VER = 'v2'
const RESOURCE_TAG = 'api'
export const PsnlEP = {
  COMPANY_URI: `/personnel/${RESOURCE_TAG}/${API_VER}/companies/`,
  CURRENT_COMPANY_URI: `/personnel/${RESOURCE_TAG}/${API_VER}/companies/user-company/`,
  DEPARTMENT_URI: `/personnel/${RESOURCE_TAG}/${API_VER}/departments/`,
  DEPARTMENT_LAYER_URI: `/personnel/${RESOURCE_TAG}/${API_VER}/departments/layer/`,
  POSITION_URI: `/personnel/${RESOURCE_TAG}/${API_VER}/positions/`,
  POSITION_LAYER_URI: `/personnel/${RESOURCE_TAG}/${API_VER}/positions/layer/`,
  LOCATION_URI: `/personnel/${RESOURCE_TAG}/${API_VER}/locations/`,
  EMPLOYEE_URI: `/personnel/${RESOURCE_TAG}/${API_VER}/employees/`,
  CALCULATE_EMPLOYEE_URI: `/personnel/${RESOURCE_TAG}/${API_VER}/employees/calculate_list/`,
  EMPLOYEE_RESIGN_URI: `/personnel/${RESOURCE_TAG}/${API_VER}/employees/resign/`,
  RESIGN_URI: `/personnel/${RESOURCE_TAG}/${API_VER}/resigns/`,
  AREA_URI: `/personnel/${RESOURCE_TAG}/${API_VER}/areas/`,
  AREA_LAYER_URI: `/personnel/${RESOURCE_TAG}/${API_VER}/areas/layer/`,
  ACCESS_AREA_URI: `/personnel/${RESOURCE_TAG}/${API_VER}/areas/access_areas/`
}

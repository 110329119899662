import React, {useEffect, useState} from "react";
import {useAppContext} from "@/provider/AppProvider";
import RealTimeMonitoring from "@/pages/Dashboard/components/RealTimeMonitoring";
import Grid from "@mui/material/Grid2";
import SummaryCard from "@/pages/Dashboard/components/SummaryCard";
import PresentPieChart from "@/pages/Dashboard/components/PresentPieChart";
import AbsentPieChart from "@/pages/Dashboard/components/AbsentPieChart";
import LeaveLineChart from "@/pages/Dashboard/components/LeaveLineChart";
import WorkHoursLineChart from "@/pages/Dashboard/components/WorkHoursLineChart";
import {useTranslation} from "react-i18next";
import {Devices, Group, GroupAdd, PhoneAndroid} from "@mui/icons-material";
import GenericService from "@/services/core/GenericService";
import {BaseEP} from "@/services/base/endpoints";


const Dashboard: React.FC = () => {
  const {t} = useTranslation("company")
  const context = useAppContext()
  const [data, setData] = useState<any>({
    total_employees: 0,
    newly_join: 0,
    total_devices: 0,
    total_mobiles: 0
  })
  useEffect(() => {
    GenericService.action(BaseEP.DASHBOARD_URI, "summary").then((response) => {
      setData(response.data)
    })
  }, [])

  return (
    <Grid
      container
      spacing={2}
      sx={{
        padding: "16px",
        height: "100%"
      }}
    >
      <Grid size={8} container spacing={2} sx={{height: "100%", overflowY: "auto", scrollbarWidth: "thin"}}>
        <Grid size={3}>
          <SummaryCard icon={Group} title={t("dashboard.totalEmployee")} summary={data.total_employees}/>
        </Grid>
        <Grid size={3}>
          <SummaryCard icon={GroupAdd} title={t("dashboard.newlyJoin")} summary={data.newly_join}/>
        </Grid>
        <Grid size={3}>
          <SummaryCard icon={Devices} title={t("dashboard.totalDevice")} summary={data.total_devices}/>
        </Grid>
        <Grid size={3}>
          <SummaryCard icon={PhoneAndroid} title={t("dashboard.totalMobile")} summary={data.total_mobiles}/>
        </Grid>
        <Grid size={6} sx={{height: "336px"}}>
          <PresentPieChart />
        </Grid>
        <Grid size={6} sx={{height: "336px"}}>
          <AbsentPieChart />
        </Grid>
        <Grid size={12} sx={{height: "336px"}}>
          <WorkHoursLineChart />
        </Grid>
        <Grid size={12} sx={{height: "336px"}}>
          <LeaveLineChart />
        </Grid>
      </Grid>
      <Grid size={4} container spacing={2} sx={{height: "100%"}}>
        <Grid size={12} sx={{height: "100%"}}>
          <RealTimeMonitoring/>
        </Grid>
      </Grid>
    </Grid>
  )
}
export default Dashboard

const API_VER = 'v2'
const RESOURCE_TAG = 'api'
export const DeviceEndPoint = {
  TERMINAL_URI: `/iclock/${RESOURCE_TAG}/${API_VER}/terminals/`,
  TERMINAL_RELOAD_USER_URI: `/iclock/${RESOURCE_TAG}/${API_VER}/terminals/reload_user/`,
  COMMAND_URI: `/iclock/${RESOURCE_TAG}/${API_VER}/commands/`,
  TRANSACTION_URI: `/iclock/${RESOURCE_TAG}/${API_VER}/transactions/`,
  EMPLOYEE_URI: `/iclock/${RESOURCE_TAG}/${API_VER}/employees/`,
  ERROR_LOG_URI: `/iclock/${RESOURCE_TAG}/${API_VER}/errorlogs/`,
  BIO_DATA_URI: `/iclock/${RESOURCE_TAG}/${API_VER}/biodatas/`,
  BIO_PHOTO_URI: `/iclock/${RESOURCE_TAG}/${API_VER}/biophotos/`,
  OPERATION_LOG_URI: `/iclock/${RESOURCE_TAG}/${API_VER}/operationlogs/`,
  UPLOAD_LOG_URI: `/iclock/${RESOURCE_TAG}/${API_VER}/uploadlogs/`,
  TERMINAL_LOG_URI: `/iclock/${RESOURCE_TAG}/${API_VER}/terminallogs/`,
  COMMAND_LOG_URI: `/iclock/${RESOURCE_TAG}/${API_VER}/commandlogs/`,
  CONFIG_URI: `/iclock/${RESOURCE_TAG}/${API_VER}/configs/`,
  PUBLIC_MESSAGE_URI: `/iclock/${RESOURCE_TAG}/${API_VER}/publicmessages/`,
  PRIVATE_MESSAGE_URI:`/iclock/${RESOURCE_TAG}/${API_VER}/privatemessages/`,
  WORK_CODE_URI: `/iclock/${RESOURCE_TAG}/${API_VER}/terminalworkcodes/`,
  UNREGISTERED_TRANSACTION_URI: `/iclock/${RESOURCE_TAG}/${API_VER}/unregistered_transaction/`,
}

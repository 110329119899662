import {Route, Routes} from "react-router-dom";
import DeviceListView from "./ListView";
import DeviceRecordView from "@/pages/Device/Device/RecordView";


const DeviceRouter = () => {
  return (
    <Routes>
      <Route index element={<DeviceListView />}/>
      <Route path={"add/"} element={<DeviceRecordView />}/>
      <Route path={"edit/"} element={<DeviceRecordView />}/>
    </Routes>
  )
};
export default DeviceRouter

import { useTranslation } from "react-i18next";
import { GridColDef } from "@mui/x-data-grid";
import { ReportEndPoint } from "@/services/report/endpoints";
import urls from "@/urls";
import ReportView from "@/pages/Report/ReportLibrary/components/ReportView";
import CalendarPanel from "./components/CalendarPanel";


const EmployeeCalendar = () => {
  const { t } = useTranslation("report")
  const columns: GridColDef[] = [
    { field: "employee_code", headerName: t("employee.code", {ns: "per"}), minWidth: 80},
    { field: "employee_name", headerName: t("employee.firstName", {ns: "per"}), minWidth: 120},
    { field: "department_name", headerName: t("report.department")},
    { field: "area_name", headerName: t("report.area")},
  ]

  return (
    <ReportView
      title={t("reportLibrary.employeeCalendar")}
      breadcrumbs={[]}
      columns={columns}
      uri={ReportEndPoint.EMP_CALENDAR_URI}
      url={urls.att.employeeCalendar}
      getRowId={(row: any) => row.id}
      RecordPanel={CalendarPanel}
      filterType={'none'}
    />
  )
}
export default EmployeeCalendar

import { RJSFSchema, UiSchema } from "@rjsf/utils";
import { useTranslation } from "react-i18next";
import validator from "@rjsf/validator-ajv8";
import LayoutForm from "@/components/rjsf/Form";
import React, {
  ForwardedRef,
  useImperativeHandle,
  useRef,
} from "react";
import SnackbarUtil from "@/utils/SnackbarUtil";
import { PsnlEP } from "@/services/psnl/endpoints";
import { Box } from "@mui/material";
import AreaService from "@/services/psnl/AreaService";

const TransferAction = (props: Record<string, any>, ref: ForwardedRef<any>) => {
  const { t } = useTranslation("common");
  const formRef = useRef<any>();

  const formSchema: RJSFSchema = {
    type: "object",
    required: [
      "employee",
    ],
    properties: {
      "employee": {
        "title": t("common.employee"),
        "type": "array",
        "minItems": 1,
        "items": {
          "type": "string",
        },
      },
    },
  };

  const uiSchema: UiSchema = {
    "ui:defaultXsWidth": 12,
    "ui:submitButtonOptions": {
      norender: true,
    },
    "employee": {
      "ui:widget": "m2m",
      "ui:options": {
        uri: PsnlEP.EMPLOYEE_URI
      }
    },
  };

  const onConfirm = async () => {
    const isValid = formRef.current?.validateForm();
    if (isValid) {
      const selectedIds = props.selectedIds;
      const data = formRef.current?.state.formData;
      const response = await AreaService.transfer(selectedIds[0], {...data});
      if (response.isSuccess) {
        SnackbarUtil.success(t("common.successful"));
        return true;
      } else {
        console.log(response);
      }
    }
  };

  useImperativeHandle(ref, () => ({
    onConfirm,
  }));

  return (
    <Box sx={{width: '400px'}}>
    <LayoutForm
      ref={formRef}
      schema={formSchema}
      uiSchema={uiSchema}
      validator={validator}
    />
    </Box>
  );
};
export default React.forwardRef(TransferAction);

import {useTranslation} from "react-i18next";
import {RJSFSchema, UiSchema} from "@rjsf/utils";
import {PsnlEP} from "@/services/psnl/endpoints";
import RecordView from "@/components/views/RecordView";
import urls from "@/urls";
import {AccessControlEndPoint} from "@/services/acc/endpoints";
import {FormProps} from "@rjsf/core";
import GroupRecordViewLayout from "@/pages/AccessControl/group/components/RecordViewLayout";
import {useLocation} from "react-router-dom";
import { useVerifyModesForm } from "../options";


const GroupRecordView = () => {
  const {t} = useTranslation("acc")
  const params = useLocation()
  const mode = params.state?.mode || "ADD"
  const area = params.state?.record?.area || params.state?.area
  const verifyModes = useVerifyModesForm()
  const formSchema: RJSFSchema = {
    type: "object",
    properties: {
      area: {
        type: "integer",
        title: t("area"),
        // readOnly: mode === "EDIT",
        readOnly: true,
        default: parseInt(area)
      },
      group_no: {
        type: "integer",
        title: t("group.code"),
        readOnly: mode === "EDIT",
        oneOf: Array(99).fill(0).map((v, i) => ({const: i +1, title: (i + 1).toString()}))
      },
      group_name: {
        type: "string",
        title: t("group.name"),
        maxLength: 100
      },
      timezone1: {
        type: ["integer", "null"],
        title: t("group.timePeriod1"),
      },
      timezone2: {
        type: ["integer", "null"],
        title: t("group.timePeriod2"),
      },
      timezone3: {
        type: ["integer", "null"],
        title: t("group.timePeriod3"),
      },
      verify_mode: {
        type: "integer",
        title: t("group.verifyMode"),
        oneOf: verifyModes,
        default: 0
      },
      is_include_holiday: {
        type: "boolean",
        title: t("group.includeHoliday"),
        default: false
      }
    },
    required: ["area", "group_no", "group_name", "timezone1", "verify_mode"]
  };
  const timezoneOptions = {
    uri: AccessControlEndPoint.TIME_PERIOD_URI,
    area: parseInt(area)
  }
  const uiSchema: UiSchema = {
    'ui:defaultXsWidth': 4,
    area: {
      "ui:widget": "m2o",
      "ui:options": {
        uri: PsnlEP.ACCESS_AREA_URI,
      }
    },
    timezone1: {
      "ui:widget": "m2o",
      "ui:options": timezoneOptions
    },
    timezone2: {
      "ui:widget": "m2o",
      "ui:options": timezoneOptions
    },
    timezone3: {
      "ui:widget": "m2o",
      "ui:options": timezoneOptions
    },
    is_include_holiday: {
      "ui:widget": "switch",
    }
  }

  const templates: Partial<FormProps["templates"]> = {
    ObjectFieldTemplate: GroupRecordViewLayout
  }


  return (
    <RecordView
      breadcrumbs={[
        {title: t("menu.accessControl", {ns: "menu"}), href: urls.acc.root},
        {title: t("menu.acc.group", {ns: "menu"}), href: urls.acc.group},
      ]}
      formSchema={formSchema}
      uiSchema={uiSchema}
      templates={templates}
      uri={AccessControlEndPoint.GROUP_URI}
    />
  )
}
export default GroupRecordView

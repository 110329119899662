import { RJSFSchema, UiSchema } from "@rjsf/utils";
import { useTranslation } from "react-i18next";
import validator from "@rjsf/validator-ajv8";
import LayoutForm from "@/components/rjsf/Form";
import React, {
  ForwardedRef,
  useImperativeHandle,
  useRef,
} from "react";
import SnackbarUtil from "@/utils/SnackbarUtil";
import EmployeeService from "@/services/psnl/EmployeeService";

const DeleteBiometric = (props: Record<string, any>, ref: ForwardedRef<any>) => {
  const { t } = useTranslation("per");
  const formRef = useRef<any>();
  const formSchema: RJSFSchema = {
    type: "object",
    required: [
      "fingerprint",
      "face",
      "finger_vein",
      "palm",
      "VLFace",
    ],
    properties: {
      fingerprint: {
        type: "number",
        title: t("employee.biometricDelete.fingerprint"),
        oneOf: [
          { const: 0, title: t("employee.biometricDelete.no") },
          { const: 1, title: t("employee.biometricDelete.yes") },
        ],
        default:0
      },
      face: {
        type: "number",
        title: t("employee.biometricDelete.face"),
        oneOf: [
          { const: 0, title: t("employee.biometricDelete.no") },
          { const: 1, title: t("employee.biometricDelete.yes") },
        ],
        default:0
      },
      finger_vein: {
        type: "number",
        title: t("employee.biometricDelete.fingerVein"),
        oneOf: [
          { const: 0, title: t("employee.biometricDelete.no") },
          { const: 1, title: t("employee.biometricDelete.yes") },
        ],
        default:0
      },
      palm: {
        type: "number",
        title: t("employee.biometricDelete.palm"),
        oneOf: [
          { const: 0, title: t("employee.biometricDelete.no") },
          { const: 1, title: t("employee.biometricDelete.yes") },
        ],
        default:0
      },
      VLFace: {
        type: "number",
        title: t("employee.biometricDelete.vlFace"),
        oneOf: [
          { const: 0, title: t("employee.biometricDelete.no") },
          { const: 1, title: t("employee.biometricDelete.yes") },
        ],
        default:0
      },
    },
  };

  const uiSchema: UiSchema = {
    "ui:defaultXsWidth": 12,
    "ui:submitButtonOptions": {
      norender: true,
    },
  };

  const onConfirm = async () => {
    const isValid = formRef.current?.validateForm();
    if (isValid) {
      const selectedIds = props.selectedIds;
      const data = formRef.current?.state.formData;
      const response = await EmployeeService.DeleteBiometric({
        ids: selectedIds,
        ...data,
      });
      if (response.isSuccess) {
        SnackbarUtil.success(t("common.successful", {ns: "common"}));
        return true;
      } else {
        console.log(response);
      }
    }
  };

  useImperativeHandle(ref, () => ({
    onConfirm,
  }));

  return (
    <LayoutForm
      ref={formRef}
      schema={formSchema}
      uiSchema={uiSchema}
      validator={validator}
    />
  );
};
export default React.forwardRef(DeleteBiometric);

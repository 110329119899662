import {Route, Routes} from "react-router-dom";
import OvertimeListView from "./ListView";
import OvertimeRecordView from "./RecordView";

const OvertimeRouter = () => {
  return (
    <Routes>
      <Route index element={<OvertimeListView/>}/>
      <Route path={"add/"} element={<OvertimeRecordView/>}/>
      <Route path={"edit/"} element={<OvertimeRecordView/>}/>
    </Routes>
  );
};
export default OvertimeRouter;

import { WidgetProps } from "@rjsf/utils";
import React, { useEffect, useMemo, useState } from "react";
import GenericService from "@/services/core/GenericService";
import {
  Autocomplete,
  Checkbox,
  FormControlLabel,
  TextField,
} from "@mui/material";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

const Many2ManyWidget = (props: WidgetProps) => {
  const { name, label, value, options, uiSchema, readonly, formContext } =
    props;
  const [selections, setSelections] = useState<Record<string, any>[]>([]);
  const selected = useMemo(() => {
    return selections.filter((selection) => value?.indexOf(selection.id) > -1);
  }, [selections, value]);

  const { uri, ...filter } = options;
  useEffect(() => {
    if (formContext.preload) {
      const data: Record<string, any>[] = formContext[name];
      setSelections(data || []);
    } else if (options.uri) {
      GenericService.list(options.uri?.toString(), {
        page: 1,
        page_size: 100,
        ...filter,
      }).then((res) => {
        setSelections(res.data.data);
      });
    }
  }, []);
  const [hasInit, setHasInit] = useState(false);
  useEffect(() => {
    if (uiSchema && uiSchema.onValueChange) {
      if (selections.length && value.length) {
        const initValue = selections.filter(
          (selection) => value?.indexOf(selection.id) > -1
        );
        if (hasInit === false) {
          setHasInit(true);
          uiSchema.onValueChange(initValue);
        }
      }
    }
  }, [selections, value]);

  return (
    <Autocomplete
      multiple
      readOnly={readonly}
      options={selections}
      value={selected}
      getOptionLabel={(option: Record<string, any>) =>
        option?.display_name || ""
      }
      isOptionEqualToValue={(option, value) => option.id === value?.id}
      onChange={async (event, value, reason, details) => {
        props.onChange(value?.map((record) => record.id));
        if (uiSchema && uiSchema.onValueChange) {
          uiSchema.onValueChange(value);
        }
      }}
      renderInput={(params: any) => {
        return (
          <TextField
            {...params}
            name={name}
            label={label}
            required={props.required}
          />
        );
      }}
      renderOption={(optionProps, option, state) => {
        return (
          <li {...optionProps} key={`option${option.id}`}>
            <FormControlLabel
              control={
                <Checkbox
                  icon={<CheckBoxOutlineBlankIcon />}
                  checkedIcon={<CheckBoxIcon />}
                  checked={state.selected}
                />
              }
              label={option.display_name}
            />
          </li>
        );
      }}
      disableCloseOnSelect
    />
  );
};
export default Many2ManyWidget;

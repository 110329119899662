import {CustomValidator, RJSFSchema, UiSchema} from "@rjsf/utils";
import {useTranslation} from "react-i18next";
import RecordView from "@/components/views/RecordView";
import {ComplementEndpoint} from "@/services/complement/endpoints";
import RegExps from "@/utils/RegExps";


const DocumentRecordView = () => {
  const {t} = useTranslation("complement");
  const {t: common} = useTranslation("common");
  const formSchema: RJSFSchema = {
    "type": "object",
    "required": [
      "cert_code",
      "cert_name",
    ],
    "properties": {
      "cert_code": {
        "type": "string",
        "title": t("document.code"),
        "maxLength":20
      },
      "cert_name": {
        "type": "string",
        "title": t("document.name"),
        "maxLength":50
      }
    }
  };

  const uiSchema: UiSchema = {
    'ui:defaultXsWidth': 4,
  }

  const customValidate: CustomValidator = (formData, errors, uiSchema) => {
    if (formData.cert_code && !RegExps.alphanumericCode.test(formData.cert_code)) {
      errors.cert_code?.addError(common("form.validateError.alpInputOnly"))
    }
    if (formData.cert_name && RegExps.xssClean.test(formData.cert_name)) {
      errors.cert_name?.addError(common("form.validateError.illegalInput"))
    }

    return errors
  }

  return (
    <RecordView
      formSchema={formSchema}
      uiSchema={uiSchema}
      uri={ComplementEndpoint.DOCUMENT_URI}
      customValidate={customValidate}
    />
  )
}
export default DocumentRecordView

import {RJSFSchema, UiSchema} from "@rjsf/utils";
import {useTranslation} from "react-i18next";
import validator from "@rjsf/validator-ajv8";
import LayoutForm from "@/components/rjsf/Form";
import React, {ForwardedRef, useImperativeHandle, useRef, useState} from "react";
import SnackbarUtil from "@/utils/SnackbarUtil";
import BioPhotoService from "@/services/device/BioPhotoService";


const ApproveAction = (props: Record<string, any>, ref: ForwardedRef<any>) => {
  const {t} = useTranslation("common")
  const formRef = useRef<any>()
  const [formData, setFormData] = useState<Record<string, any>>();

  const formSchema: RJSFSchema = {
    "type": "object",
    "required": [
      "state",
      "overwrite",
    ],
    "properties": {
      "state": {
        "type": "number",
        "title": t("approval.status"),
        "default": 1,
        "oneOf": [
          {const: 1, title: t("approval.status.approved")},
          {const: 2, title: t("approval.status.rejected")},
        ],
      },
      "overwrite": {
        "type": "number",
        "title": t("approval.overtime"),
        "default": 0,
        "oneOf": [
          {const: 0, title: t("boolean.no")},
          {const: 1, title: t("boolean.yes")},
        ],
      },
      "remarks": {title: t("approval.remarks"), type: "string"},
    }
  }

  const uiSchema: UiSchema = {
    'ui:defaultXsWidth': 12,
    "ui:submitButtonOptions": {
      "norender": true,
    },
    "remarks": {
      "ui:widget": "textarea"
    },
  };

  const onConfirm = async () => {
    const isValid = formRef.current?.validateForm()
    if (isValid) {
      const selectedIds = props.selectedIds
      const data = formRef.current?.state.formData
      const response = await BioPhotoService.Approve({ids: selectedIds, ...data})
      if (response.isSuccess) {
        SnackbarUtil.success(t("common.message.successful"))
        return true
      } else {
        console.log(response)
      }
    }
  }

  useImperativeHandle(ref, () => ({
    onConfirm
  }))

  return (
    <LayoutForm
      ref={formRef}
      schema={formSchema}
      uiSchema={uiSchema}
      validator={validator}
      formData={formData}
    />
  )
}
export default React.forwardRef(ApproveAction)

import RecordView from "@/components/views/RecordView";
import {useTranslation} from "react-i18next";
import {RJSFSchema, UiSchema} from "@rjsf/utils";
import {AuthEP} from "@/services/auth/endpoints";
import RecordLayoutTemplate from "@/pages/SystemSetting/User/components/RecordLayoutTemplate";
import AppUrls from "@/urls";
import {PsnlEP} from "@/services/psnl/endpoints";


const UserRecordView = () => {
  const {t} = useTranslation("sys");
  const {t: menu} = useTranslation("menu");
  const formSchema: RJSFSchema = {
    "type": "object",
    properties: {
      username: {
        type: "string",
        title: t("user.username"),
      },
      email: {
        type: "string",
        title: t("user.email")
      },
      first_name: {
        type: "string",
        title: t("user.firstName")
      },
      last_name: {
        type: "string",
        title: t("user.lastName")
      },
      is_staff: {
        type: "boolean",
        title: t("user.isActive"),
        default: false
      },
      is_superuser: {
        type: "boolean",
        title: t("user.isSuperuser"),
        default: false
      },
      auth_departments: {
        type: "array",
        title: t("user.authorizeDepartment"),
        items: {
          type: "integer"
        }
      },
      auth_areas: {
        type: "array",
        title: t("user.authorizeArea"),
        items: {
          type: "integer"
        }
      },
      auth_positions: {
        type: "array",
        title: t("user.authorizePosition"),
        items: {
          type: "integer"
        }
      }
    },
    required: ["username", "email"]
  }
  const uiSchema: UiSchema = {
    "ui:ObjectFieldTemplate": RecordLayoutTemplate,
    "ui:defaultXsWidth": 6,
    username: {
      "ui:disabled": true
    },
    is_staff: {
      "ui:widget": "switch2"
    },
    is_superuser: {
      "ui:widget": "switch2"
    },
    auth_departments: {
      "ui:widget": "m2m",
      "ui:options": {
        uri: PsnlEP.DEPARTMENT_URI
      }
    },
    auth_areas: {
      "ui:widget": "m2m",
      "ui:options": {
        uri: PsnlEP.AREA_URI
      }
    },
    auth_positions: {
      "ui:widget": "m2m",
      "ui:options": {
        uri: PsnlEP.POSITION_URI
      }
    },
  }

  return (
    <RecordView
      breadcrumbs={[
        {title: menu("menu.systemSetting"), href: AppUrls.system.root},
        {title: menu("menu.systemSetting.user"), href: AppUrls.system.user},
      ]}
      formSchema={formSchema}
      uiSchema={uiSchema}
      uri={AuthEP.ACCOUNT_URI}
    />
  )
}
export default UserRecordView

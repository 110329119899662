import {DeviceEndPoint} from "@/services/device/endpoints";
import GenericService from "@/services/core/GenericService";


const sendMessage = async (data: Record<string, any>) => {
  const response = await GenericService.action(DeviceEndPoint.PRIVATE_MESSAGE_URI, 'send_message', data)
  return response;
}

const PrivateMessageService = {
  sendMessage
}
export default PrivateMessageService

import {Route, Routes} from "react-router-dom";
import CalculationListView from "./ListView";


const CalculationRouter = () => {
  return (
    <Routes>
      <Route index element={<CalculationListView />}/>
    </Routes>
  )
};
export default CalculationRouter

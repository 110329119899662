import React, {useRef, useState} from "react";
import {Avatar, Box, Divider, ListItemIcon, ListItemText, MenuItem, Stack, Typography, useTheme} from "@mui/material";
import {useAppContext} from "@/provider/AppProvider";
import {bindMenu, bindTrigger, usePopupState} from "material-ui-popup-state/hooks";
import {useNavigate} from "react-router-dom";
import urls from "@/urls";
import confirm from "@/components/dialog/confirm";
import {useMuiThemeContext} from "@/provider/MuiThemeProvider";
import {InfoOutlined, Key, PowerSettingsNew, TranslateOutlined, HelpOutlineOutlined} from "@mui/icons-material";
import {useTranslation} from "react-i18next";
import ChangeLanguage from "@/pages/Oauth/ChangeLanguage";
import {useDialogContext} from "@/provider/DialogProvider";
import About from "@/layout/Header/components/About";
import ChangePassword from "@/pages/SystemSetting/User/components/ChangePassword";
import StyledPopupMenu from "@/components/styled/StyledPopupMenu";
import {BaseEP} from "@/services/base/endpoints";
import request from "@/utils/Request";


const AccountProfile: React.FC = (props) => {
  const {t} = useTranslation("sys")
  const theme = useTheme()
  const navigate = useNavigate()
  const {currentUser} = useAppContext()
  const popupState = usePopupState({variant: "popover", popupId: "AccountMenu"})
  const languageRef = useRef<any>()
  const dialog = useDialogContext()
  const {changeLanguage} = useMuiThemeContext()

  const onAbout = async () => {
    popupState.close()
    dialog.showDialog?.({
      title: t("account.about"),
      content: <About/>,
      showTitleClose: true,
      disableAction: true
    })
  }

  const onHelp = async () => {
    request.authorizedAxios().get(`${BaseEP.HELP_DOCUMENT}help_document_file/`, {
      method: "GET",
      responseType: 'blob'
    })
      .then(
        (response) => {
          const file = new Blob([response.data], {type: 'application/pdf'});
          const fileURL = URL.createObjectURL(file);
          window.open(fileURL);
        }
      )
      .catch((error) => {
          console.error('Error downloading the PDF:', error);
        }
      )
  };


  const onChangeLanguage = async () => {
    popupState.close()
    const defaultLanguage = localStorage.getItem("i18nextLng") || "en"
    dialog.showDialog?.({
      title: t("account.changeLanguage"),
      content: <ChangeLanguage defaultLanguage={defaultLanguage} ref={languageRef}/>,
      onConfirm: async () => {
        await changeLanguage(languageRef.current.selected)
        return true
      }
    })
  }

  const onChangePassword = async () => {
    popupState.close()
    dialog.showDialog?.({
      title: t("account.changePassword"),
      Form: ChangePassword,
      formProps: {recordId: currentUser?.id},
      afterConfirm: async () => {
        localStorage.removeItem("accessToken")
        navigate(urls.companyVerification)
      }
    })
  }

  const onLogout = () => {
    popupState.close()
    confirm({
      description: t("account.signOut.confirmation"),
      onConfirm: async () => {
        const companyHost = localStorage.getItem('company_code') + '.'
        localStorage.removeItem("accessToken")
        if (window.location.host.startsWith(companyHost)) {
          const newHost = window.location.host.substring(companyHost.length, window.location.host.length)
          const newOrigin = window.location.origin.replace(window.location.host, newHost)
          window.location.href = `${newOrigin}${urls.companyVerification}`
        } else {
          navigate(urls.companyVerification)
        }
        return true
      }
    })
  }
  const actions = [
    {
      id: "about", title: t("account.about"),
      icon: <InfoOutlined/>,
      callback: onAbout,
      display: true
    },
    {
      id: "help", title: t("account.help"),
      icon: <HelpOutlineOutlined/>,
      callback: onHelp,
      display: true
    },
    {
      id: "changeLanguage",
      title: t("account.changeLanguage"),
      icon: <TranslateOutlined/>,
      callback: onChangeLanguage,
      display: true
    },
    {
      id: "changePassword",
      title: t("account.changePassword"),
      icon: <Key/>,
      callback: onChangePassword,
      display: true
    }
  ]
  const avatarSrc = currentUser?.photo ? `/web/files/${currentUser.photo}` : ""
  return (
    <>
      <Box sx={{display: "flex", alignItems: "center", height: "100%", padding: "0 8px"}} {...bindTrigger(popupState)}>
        <Avatar sx={{width: 40, height: 40}} alt={currentUser?.first_name} src={avatarSrc}/>
      </Box>
      <StyledPopupMenu {...bindMenu(popupState)}>
        <MenuItem sx={{minWidth: "204px"}}>
          <ListItemIcon>
            <Avatar sx={{width: 40, height: 40}} alt={currentUser?.first_name} src={avatarSrc}/>
          </ListItemIcon>
          <ListItemText>
            <Stack sx={{p: 1}}>
              <Typography variant={"body2"}>{currentUser?.first_name} {currentUser?.last_name}</Typography>
              <Typography variant={"body2"} color={"rgba(0, 0, 0, 0.6)"}>
                {currentUser?.isSuperuser ? "Super Admin" : "Admin"}
              </Typography>
            </Stack>
          </ListItemText>
        </MenuItem>
        <Divider/>
        {actions.filter((action) => action.display).map((action) => {
          return (
            <MenuItem key={action.id} onClick={action.callback}>
              <ListItemIcon>{action.icon}</ListItemIcon>
              <ListItemText>{action.title}</ListItemText>
            </MenuItem>
          )
        })}
        <Divider/>
        <MenuItem key={"logout"} onClick={onLogout}>
          <ListItemIcon>
            <PowerSettingsNew/>
          </ListItemIcon>
          <ListItemText>{t("account.signOut")}</ListItemText>
        </MenuItem>
      </StyledPopupMenu>
    </>
  )
}
export default AccountProfile

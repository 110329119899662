import {RJSFSchema, UiSchema} from "@rjsf/utils";
import {useTranslation} from "react-i18next";
import React, {useRef} from "react";
import validator from "@rjsf/validator-ajv8";
import LayoutForm from "@/components/rjsf/Form";
import {Stack} from "@mui/system";
import {Box, Button, Typography} from "@mui/material";
import {VendorEndPoint} from "@/services/vendor/endpoints";
import SnackbarUtil from "@/utils/SnackbarUtil";
import GenericService from "@/services/core/GenericService";
import {useDialogContext} from "@/provider/DialogProvider";


const RequestSubscription = () => {
  const {t} = useTranslation("sub");
  const {t: api} = useTranslation("api");
  const dialog = useDialogContext();
  const formRef = useRef<any>()
  const formSchema: RJSFSchema = {
    type: "object",
    properties: {
      country: {
        type: "string",
        title: t("onlineSubscription.country"),
        oneOf: [
          {const: "USD", title: "Latin America"}
        ],
        default: "USD"
      },
      region: {
        type: "integer",
        title: t("onlineSubscription.region"),
      },
      supplier: {
        type: "integer",
        title: t("onlineSubscription.supplier"),
      },
    }
  };

  const widgets = {}
  const uiSchema: UiSchema = {
    "ui:defaultXsWidth": 4,
    "ui:defaultRowWrap": false,
    country: {
      "ui:options": {
        size: "small"
      }
    },
    region: {
      "ui:widget": "m2o",
      "ui:options": {
        uri: `${VendorEndPoint.SUBSCRIPTION_URI}regions/`
      }
    },
    supplier: {
      "ui:widget": "m2o",
      "ui:options": {
        uri: `${VendorEndPoint.SUBSCRIPTION_URI}suppliers/`
      }
    },
  }

  const onRequest = async () => {
    const data = formRef.current?.state.formData;
    if (data.supplier) {
      GenericService.action(VendorEndPoint.SUBSCRIPTION_URI, "apply", data).then((response) => {
        if (response.isSuccess) {
          dialog.showDialog({
            title: t("onlineSubscription.request.successful"),
            content: (<Stack spacing={2} sx={{width: "576px"}}>
              <Typography>{t("onlineSubscription.request.message1")}</Typography>
              <Typography>{t("onlineSubscription.request.message2")}</Typography>
              <Typography>{t("onlineSubscription.request.message3")}</Typography>
              <Typography>{t("onlineSubscription.request.message4")}</Typography>
            </Stack>),
            disableAction: true
          })
        } else {
          return console.log(response)
        }
      })
    } else {
      SnackbarUtil.error(t("onlineSubscription.error.supplierRequired"))
    }
  }

  return (
    <Stack
      direction={"row"}
      spacing={2}
      sx={{
        display: "flex",
        alignItems: "center",
        "& .rjsf": {
          width: "50%"
        }
      }}
    >
      <LayoutForm
        ref={formRef}
        schema={formSchema}
        uiSchema={uiSchema}
        widgets={widgets}
        validator={validator}
      />
      <Button variant={"contained"} onClick={onRequest}>
        {t("onlineSubscription.request")}
      </Button>
    </Stack>
  )
}
export default RequestSubscription

const API_VER = 'v2'
const RESOURCE_TAG = 'api'

export const ReportEndPoint = {

  TRANSACTION_URI: `/report/${RESOURCE_TAG}/${API_VER}/report_transaction/`,
  TOTAL_TIMECARD_URI: `/report/${RESOURCE_TAG}/${API_VER}/report_total_timecard/`,
  DAILY_ATTENDANCE_URI: `/report/${RESOURCE_TAG}/${API_VER}/report_daily_attendance/`,
  DAILY_DETAILS_URI: `/report/${RESOURCE_TAG}/${API_VER}/report_daily_details/`,
  DAILY_SUMMARY_URI: `/report/${RESOURCE_TAG}/${API_VER}/report_daily_summary/`,
  DAILY_STATUS_URI: `/report/${RESOURCE_TAG}/${API_VER}/report_daily_status/`,
  EMP_SCHEDULE_URI: `/report/${RESOURCE_TAG}/${API_VER}/report_emp_schedule/`,
  EMP_CALENDAR_URI: `/report/${RESOURCE_TAG}/${API_VER}/report_employee_calendar/`,
  LATE_URI: `/report/${RESOURCE_TAG}/${API_VER}/report_late/`,
  ABSENT_URI: `/report/${RESOURCE_TAG}/${API_VER}/report_absent/`,
  EARLY_LEAVE_URI: `/report/${RESOURCE_TAG}/${API_VER}/report_early_leave/`,
  FIRSTLAST_URI: `/report/${RESOURCE_TAG}/${API_VER}/report_firstlast/`,
  FIRSTINLASTOUT_URI: `/report/${RESOURCE_TAG}/${API_VER}/report_firstin_lastout/`,
  OVERTIME_URI: `/report/${RESOURCE_TAG}/${API_VER}/report_overtime/`,
  MOBILE_TRANSACTION_URI: `/report/${RESOURCE_TAG}/${API_VER}/report_mobile_transaction/`,
  TOTAL_PUNCHES_URI: `/report/${RESOURCE_TAG}/${API_VER}/report_total_punches/`,
  SCHEDULED_LOG_URI: `/report/${RESOURCE_TAG}/${API_VER}/report_scheduled_log/`,
  MISSED_IN_OUT_URI: `/report/${RESOURCE_TAG}/${API_VER}/report_missed_in_out/`,
  EMPLOYEE_BIRTHDAY_URI: `/report/${RESOURCE_TAG}/${API_VER}/report_employee_birthday/`,
  HALF_DAY_URI: `/report/${RESOURCE_TAG}/${API_VER}/report_half_day/`,
  BREAKTIME_URI: `/report/${RESOURCE_TAG}/${API_VER}/report_break_time/`,
  TRAINING_URI: `/report/${RESOURCE_TAG}/${API_VER}/report_training/`,
  ABNORMAL_URI: `/report/${RESOURCE_TAG}/${API_VER}/report_abnormal/`,
  MULTIPLETRANSACTION_URI: `/report/${RESOURCE_TAG}/${API_VER}/report_multiple_transaction/`,
  REPORT_CONFIGURATION_URI: `/att/${RESOURCE_TAG}/${API_VER}/report_configuration/`,
  PUNCH_STATE_SETTING_URI: `/att/${RESOURCE_TAG}/${API_VER}/punch_state_setting/`,
  REPORT_ITEMS_SETTING_URI: `/att/${RESOURCE_TAG}/${API_VER}/report_items_setting/`,
  EMPLOYEE_SUMMARY_URI: `/report/${RESOURCE_TAG}/${API_VER}/report_employee_summary/`,
  BASIC_STATUS_URI: `/report/${RESOURCE_TAG}/${API_VER}/report_monthly_basic_status/`,
  DEPARTMENT_SUMMARY_URI: `/report/${RESOURCE_TAG}/${API_VER}/report_department_summary/`,
  LEAVE_DETAIL_URI: `/report/${RESOURCE_TAG}/${API_VER}/report_admin_leave_details/`,
  LEAVE_SUMMARY_URI: `/report/${RESOURCE_TAG}/${API_VER}/report_leave_summary/`,
  MONTHLY_STATUS_SUMMARY_URI: `/report/${RESOURCE_TAG}/${API_VER}/report_monthly_status_summary/`,
  MONTHLY_DETAILED_SUMMARY_URI: `/report/${RESOURCE_TAG}/${API_VER}/report_monthly_detailed_summary/`,
  PAYROLL_SUMMARY_URI: `/report/${RESOURCE_TAG}/${API_VER}/report_payroll_summary/`,
  YEARLY_SUMMARY_URI: `/report/${RESOURCE_TAG}/${API_VER}/report_yearly_summary/`,
  OVERTIME_SUMMARY_URI: `/report/${RESOURCE_TAG}/${API_VER}/report_overtime_summary/`,
  DURATION_SUMMARY_URI: `/report/${RESOURCE_TAG}/${API_VER}/report_monthly_duration_summary/`,
  ATTSHEET_SUMMARY_URI: `/report/${RESOURCE_TAG}/${API_VER}/report_monthly_attsheet_summary/`,
  SCHEDULE_TRACK_URI: `/report/${RESOURCE_TAG}/${API_VER}/report_schedule_track/`,
  UNSCHEDULE_TRACK_URI: `/report/${RESOURCE_TAG}/${API_VER}/report_unschedule_track/`,
 }

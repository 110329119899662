import {Route, Routes} from "react-router-dom";
import AccountsListView from "./ListView";

const AccountsRouter = () => {
  return (
    <Routes>
      <Route index element={<AccountsListView />}/>
    </Routes>
  )
};
export default AccountsRouter

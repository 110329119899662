import {Button, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import {RJSFSchema, UiSchema} from "@rjsf/utils";
import {useTranslation} from "react-i18next";
import validator from "@rjsf/validator-ajv8";
import LayoutForm from "@/components/rjsf/Form";
import React, {ForwardedRef, useImperativeHandle, useRef} from "react";
import {useAppContext} from "@/provider/AppProvider";
import UserService from "@/services/auth/UserService";
import SnackbarUtil from "@/utils/SnackbarUtil";


const InviteUser = (props: Record<string, any>, ref: ForwardedRef<any>) => {
  const {t} = useTranslation("sys")
  const {currentUser} = useAppContext()
  const formRef = useRef<any>()
  const formSchema: RJSFSchema = {
    "type": "object",
    properties: {
      email: {
        type: "string",
        title: t("user.email"),
        description: t("user.inviteUser.description")
      },
    },
    required: ["email"]
  }
  const uiSchema: UiSchema = {}
  const onInviteUser = async () => {
    const isValid = formRef.current?.validateForm()
    if (isValid) {
      const data = formRef.current?.state.formData
      const response = await UserService.inviteUser(currentUser?.id, data)
      if (response.isSuccess) {
        SnackbarUtil.success(t("user.inviteUser.successful"))
        return true
      } else {
        console.log(response)
        return false
      }
    }
  }
  useImperativeHandle(ref, () => ({
    onConfirm: onInviteUser
  }))
  return (
    <LayoutForm
      ref={formRef}
      schema={formSchema}
      uiSchema={uiSchema}
      validator={validator}
    />
  )
}
export default React.forwardRef(InviteUser)

import React from "react";
import {bindHover, bindMenu, usePopupState} from "material-ui-popup-state/hooks";
import {List, ListItem, ListItemButton, ListItemText, ListSubheader} from "@mui/material";
import HoverMenu from "material-ui-popup-state/HoverMenu";
import StyledListItemIcon from "@/layout/SideBar/components/StyledListItemIcon";


const CustomShrinkListItem = (props: Record<string, any>) => {
  const {menu, onMenuItemClick} = props
  const popupState = usePopupState({
    variant: "popover",
    popupId: `${menu.key}Menu`,
  });
  return (
    <>
      <ListItemButton sx={{p: 1}} {...bindHover(popupState)}>
        <StyledListItemIcon>{menu.icon}</StyledListItemIcon>
      </ListItemButton>
      <HoverMenu
        autoFocus={false}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        variant={"selectedMenu"}
        {...bindMenu(popupState)}
      >
        <List
          disablePadding
          subheader={
            <ListSubheader component={"div"}>
              <ListItem sx={{pl: 0, pr: 0}}>
                <ListItemText>{menu.title}</ListItemText>
              </ListItem>
            </ListSubheader>
          }
        >
          {menu.subMenus?.map((subMenu: any) => {
            return (
              <ListItemButton
                key={subMenu.key}
                onClick={async () => {
                  await onMenuItemClick(subMenu);
                }}
              >
                <ListItemText primary={subMenu.title}/>
              </ListItemButton>
            );
          })}
        </List>
      </HoverMenu>
    </>
  );
}
export default CustomShrinkListItem

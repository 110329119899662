import { RJSFSchema, UiSchema } from "@rjsf/utils";
import { useTranslation } from "react-i18next";
import validator from "@rjsf/validator-ajv8";
import LayoutForm from "@/components/rjsf/Form";
import React, { ForwardedRef, useImperativeHandle, useRef } from "react";
import SnackbarUtil from "@/utils/SnackbarUtil";
import { DeviceEndPoint } from "@/services/device/endpoints";
import WorkCodeService from "@/services/complement/WorkCodeService";

const SendWorkCodeAction = (
  props: Record<string, any>,
  ref: ForwardedRef<any>
) => {
  const { t } = useTranslation();

  const formRef = useRef<any>();

  const formSchema: RJSFSchema = {
    type: "object",
    required: ["device"],
    properties: {
      device: {
        type: "number",
        title: t("workCode.device"),
      },
    },
  };

  const uiSchema: UiSchema = {
    "ui:defaultXsWidth": 12,
    "ui:submitButtonOptions": { norender: true },
    device: {
      "ui:widget": "m2o",
      "ui:options": {
        uri: DeviceEndPoint.TERMINAL_URI,
      },
    },
  };

  const onConfirm = async () => {
    const isValid = formRef.current?.validateForm();
    if (isValid) {
      const selectedIds = props.selectedIds;
      const data = formRef.current?.state.formData;
      const response = await WorkCodeService.sendWorkCode2Device({
        ids: selectedIds,
        ...data,
      });
      if (response.isSuccess) {
        SnackbarUtil.success(t("common.successful"));
        return true;
      } else {
        console.log(response);
      }
    }
  };
  useImperativeHandle(ref, () => ({
    onConfirm,
  }));

  return (
    <LayoutForm
      ref={formRef}
      schema={formSchema}
      uiSchema={uiSchema}
      validator={validator}
    />
  );
};
export default React.forwardRef(SendWorkCodeAction);

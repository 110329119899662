import React, {useEffect, useState} from "react";
import {Button, Collapse, List, ListItem, ListItemButton, ListItemText, Typography, useTheme} from "@mui/material";
import {useLocation} from "react-router-dom";
import {ExpandLess, ExpandMore} from "@mui/icons-material";
import StyledListItemIcon from "@/layout/SideBar/components/StyledListItemIcon";


const CustomListItem = (props: Record<string, any>) => {
  const {menu, onMenuItemClick} = props
  const theme = useTheme();
  const [menuOpen, setMenuOpen] = React.useState<boolean>(false);
  const location = useLocation();
  useEffect(() => {
    let initOpen = false;
    menu.subMenus &&
    menu.subMenus.map((subMenu: any) => {
      if (subMenu.url && location.pathname === subMenu.url) {
        initOpen = true;
      }
      if (subMenu.url && location.pathname.startsWith(subMenu.url + '/')) {
        initOpen = true;
      }
    });
    if (initOpen) {
      setMenuOpen(initOpen);
    }
  }, [location.pathname]);

  return (
    <>
      <ListItem
        sx={{
          height: "48px",
          padding: "0 4px",
        }}
      >
        <Button
          sx={{
            width: "100%",
            display: "flex",
            color: "#0F3F62"
          }}
          startIcon={menu.icon}
          endIcon={menuOpen ? <ExpandLess/> : <ExpandMore/>}
          onClick={() => setMenuOpen(!menuOpen)}
        >
          <Typography
            sx={{
              flex: 1,
              textAlign: "left"
            }}
          >
            {menu.title}
          </Typography>
        </Button>
      </ListItem>
      {menu.subMenus && (
        <Collapse in={menuOpen}>
          <List component={"div"} disablePadding>
            {menu.subMenus.map((subMenu: any) => {
              let onView = false
              if (subMenu.url && location.pathname === subMenu.url) {
                onView = true;
              }
              if (subMenu.url && location.pathname.startsWith(subMenu.url + '/')) {
                onView = true;
              }
              return (
                <ListItem
                  key={subMenu.key}
                  sx={{
                    height: "40px",
                    background: onView? "#2e85d03b": "none"
                  }}
                >
                  <Button
                    fullWidth
                    sx={{
                      display: "flex",
                      color: "#0F3F62"
                    }}
                    onClick={async () => {
                      await onMenuItemClick(subMenu)
                    }}
                  >
                    <Typography
                      sx={{
                        flex: 1,
                        paddingLeft: "8px",
                        textAlign: "left"
                      }}
                    >
                      {subMenu.title}
                    </Typography>
                  </Button>
                </ListItem>
              )
            })}
          </List>
        </Collapse>
      )}
    </>
  )
}
export default CustomListItem

import {PsnlEP} from "@/services/psnl/endpoints";
import {CustomValidator, RJSFSchema, UiSchema} from "@rjsf/utils";
import {useTranslation} from "react-i18next";
import RecordView from "@/components/views/RecordView";
import {useLocation} from "react-router-dom";
import RegExps from "@/utils/RegExps";


const LocationRecordView = () => {
  const {t} = useTranslation("company");
  const {t: common} = useTranslation("common");
  const params = useLocation();
  const mode = params.state?.mode || "ADD";
  const formSchema: RJSFSchema = {
    "type": "object",
    "required": [
      "location_code",
      "location_name",
    ],
    "properties": {
      "location_code": {
        "type": "string",
        "title": t("location.code"),
        readOnly: mode === "EDIT",
        "maxLength": 30
      },
      "location_name": {
        "type": "string",
        "title": t("location.name"),
        "maxLength": 30
      },
      "parent_location": {
        "type": ["number", "null"],
        "title": t("location.superior"),
      }
    }
  };

  const customValidate: CustomValidator = (formData, errors, uiSchema) => {
    if (formData.location_code && RegExps.xssClean.test(formData.location_code)) {
      errors.location_code?.addError(common("form.validateError.illegalInput"))
    }
    if (formData.location_name && RegExps.xssClean.test(formData.location_name)) {
      errors.location_name?.addError(common("form.validateError.illegalInput"))
    }

    return errors
  }

  const uiSchema: UiSchema = {
    'ui:defaultXsWidth': 4,
    "ui:submitButtonOptions": {"norender": true},
    "parent_location": {
      "ui:widget": "m2o",
      "ui:options": {
        uri: PsnlEP.LOCATION_URI
      }
    }
  };
  return (
    <RecordView
      formSchema={formSchema}
      uiSchema={uiSchema}
      uri={PsnlEP.LOCATION_URI}
      customValidate={customValidate}
    />
  )
}
export default LocationRecordView

import {WidgetProps} from "@rjsf/utils";
import {Avatar, Button, Stack, Typography} from "@mui/material";
import React, {useRef} from "react";


const AvatarWidget = (props: WidgetProps) => {
  const {name, label, value, options, uiSchema, readonly} = props
  const inputRef = useRef<any>()
  let displaySrc = ''
  if (value) {
    if (value.startsWith('data:image/')) {
      displaySrc = value
    } else {
      displaySrc = `/web/auth_files/${value}`
    }
  }

  return (
    <Stack direction={"row"} spacing={2} sx={{alignItems: "center"}}>
      <Avatar
        variant={"square"}
        sx={{width: "128px", height: "128px"}}
        src={displaySrc}
      />
      <Stack spacing={2}>
        <Stack direction={"row"} spacing={2}>
          <Button variant={"contained"} component={"label"} disabled={readonly}>
            {label}
            <input
              hidden={true}
              type={"file"}
              accept={(options?.accept?.toString() || "image/png,image/jpeg")}
              name={name}
              onChange={async (event: any) => {
                const curAvatar = event.target.files[0]
                const reader = new FileReader()
                reader.readAsDataURL(curAvatar)
                reader.onload = (event) => {
                  props.onChange(event.target?.result)
                }
              }}
              ref={inputRef}
            />
          </Button>
          <Button variant={"outlined"} disabled={readonly} onClick={async () => {
            inputRef.current.value = null
            props.onChange(undefined)
          }}>Reset</Button>
        </Stack>
        <Typography>{options?.customHelp?.toString()}</Typography>
      </Stack>
    </Stack>
  )
}
export default AvatarWidget

import { MainIconButton } from "@/components/styled"
import { ListItemText, Menu, MenuItem, Tooltip } from "@mui/material";
import { bindMenu, bindTrigger, usePopupState } from "material-ui-popup-state/hooks";
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import ReportExportAction from './ReportExportForm'
import { useDialogContext } from "@/provider/DialogProvider";
import { useReportViewContext } from "../ReportViewProvider";
import dayjs from "dayjs";
import { useAppContext } from "@/provider/AppProvider";
import { useExportFormParams } from "@/hooks/exportHooks";
import { gridColumnVisibilityModelSelector } from "@mui/x-data-grid";


const ExportAction = (props: Record<any, any>) => {
  const {currentCompany} = useAppContext()
  const { title, uri, paginationModel, listParams, filterModel, dataGridRef, wiseFields } = useReportViewContext()
  const popupState = usePopupState({ variant: "popover", popupId: "ExportAction" })
  const dialog = useDialogContext()
  const handleExport = (exportType: any, dialogTitle: any) => {
    const columns = dataGridRef.current.getAllColumns()
    const visibility = gridColumnVisibilityModelSelector(dataGridRef)
    const fieldNames: any = {}
    const fields: any = []
    const fieldOptions: any = {}
    columns.forEach((each: any) => {
      if (visibility[each.field] !== false) {
        if (['__check__', 'actions'].indexOf(each.field) === -1) {
          if (each.type === 'singleSelect') {
            fieldOptions[each.field] = each.valueOptions
          }
          fieldNames[each.field] = each.headerName
          fields.push(each.field)
        }
      }
    })
    const reqParams = {}
    const reqData = {
      page: paginationModel.page + 1,
      page_size: paginationModel.pageSize,
      start_date: filterModel.startDate?.format("YYYY-MM-DD"),
      end_date: filterModel.endDate?.format("YYYY-MM-DD"),
      [`${filterModel.category}`]: filterModel.selected,
      title,
      fields,
      i18n: {
        date_string: `From  ${filterModel.startDate?.format("YYYY-MM-DD")}  To  ${filterModel.endDate?.format("YYYY-MM-DD")}`,
        create_on_string: `Generated On: ${dayjs().format("YYYY-MM-DD")}`,
        company_string: `Company: ${currentCompany.name}`,
        field_options: fieldOptions,
        ...fieldNames
      }
    }
    const exportFormParams = useExportFormParams(exportType, wiseFields)
    dialog.showDialog({
      title: dialogTitle,
      Form: ReportExportAction,
      formProps: {exportType, title, uri, reqData, reqParams, ...exportFormParams}
    })
  }

  return <>
    <Tooltip title={"Export"}>
      <MainIconButton {...bindTrigger(popupState)} >
        <FileDownloadOutlinedIcon />
      </MainIconButton>
    </Tooltip>
    <Menu
      autoFocus={false}
      variant={'selectedMenu'}
      {...bindMenu(popupState)}
    >
      <MenuItem onClick={() => {
        handleExport('csv', 'CSV Export')
      }}>
        <ListItemText>CSV Export</ListItemText>
      </MenuItem>
      <MenuItem onClick={() => {
        handleExport('excel', 'Excel Export')
      }}>
        <ListItemText>Excel Export</ListItemText>
      </MenuItem>
      <MenuItem onClick={() => {
        handleExport('pdf', 'PDF Export')
      }}>
        <ListItemText>PDF Export</ListItemText>
      </MenuItem>
      <MenuItem onClick={() => {
        handleExport('txt', 'TXT Export')
      }}>
        <ListItemText>TXT Export</ListItemText>
      </MenuItem>
    </Menu>
  </>
}

export default ExportAction

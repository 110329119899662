import {CircularProgress, Stack} from "@mui/material";
import Box from "@mui/material/Box";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import GenericService from "@/services/core/GenericService";
import {VendorEndPoint} from "@/services/vendor/endpoints";
import PlanCard from "@/pages/OnlineSubscription/MESubscription/Pricing/components/PlanCard";


const SubscriptionPlan = (props: Record<string, any>) => {
  const {t} = useTranslation('sub')
  const [plans, setPlans] = useState<any[]>([])
  useEffect(() => {
    GenericService.action(VendorEndPoint.SUBSCRIPTION_URI, 'plans').then((response) => {
      setPlans(response.data)
    })
  }, [])

  return (
    <Box>
      {
        plans?.length > 0
          ? <Stack direction={"row"} spacing={2}>
            {plans.map((plan: any) => (
              <PlanCard plan={plan}/>
            ))}
          </Stack>
          : <Box sx={{height: "100%"}}><CircularProgress/></Box>
      }
    </Box>
  )
}
export default SubscriptionPlan

import {Route, Routes} from "react-router-dom";
import GPSOfEmployeeListView from "./ListView";
import GPSOfEmployeeRecordView from "@/pages/GPS/GPSOfEmployee/RecordView";
import MapProvider from "@/provider/MapProvider";

const GPSOfEmployeeRouter = () => {
  return (
    <Routes>
      <Route index element={<GPSOfEmployeeListView />}/>
      <Route path={"add/"} element={<MapProvider><GPSOfEmployeeRecordView /></MapProvider>}/>
      <Route path={"edit/"} element={<MapProvider><GPSOfEmployeeRecordView /></MapProvider>}/>
    </Routes>
  )
};
export default GPSOfEmployeeRouter

import {useTranslation} from "react-i18next";


export const useReportEmployee = (): any => {
  const {t: per} = useTranslation("per")
  const {t: company} = useTranslation("company");
  return [
    {
      field: "employee_code",
      headerName: per("employee.code"),
      flex: 1,
      minWidth: 120,
    },
    {
      field: "employee_name",
      headerName: per("employee.employeeName"),
      flex: 1,
      minWidth: 120,
    },
    {
      field: "first_name",
      headerName: per("employee.firstName"),
      flex: 1,
      minWidth: 120,
    },
    {
      field: "last_name",
      headerName: per("employee.lastName"),
      flex: 1,
      minWidth: 120,
    },
    {
      field: "department_code",
      headerName: company("department.code"),
      flex: 1,
      minWidth: 140,
    },
    {
      field: "department_name",
      headerName: company("department.name"),
      flex: 1,
      minWidth: 140,
    },
    {
      field: "position_code",
      headerName: company("position.code"),
      flex: 1,
      minWidth: 120,
    },
    {
      field: "position_name",
      headerName: company("position.name"),
      flex: 1,
      minWidth: 120,
    },
  ];
}





import {Button, Card, CardContent, CardHeader, Stack} from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import utils from "@/utils";
import React from "react";
import {useTranslation} from "react-i18next";
import {useDialogContext} from "@/provider/DialogProvider";
import OrderView from "@/pages/OnlineSubscription/MESubscription/Pricing/components/OrderView";


const PlanCard = (props: Record<string, any>) => {
  const {plan} = props
  const {t} = useTranslation('sub')
  const dialog = useDialogContext()
  const getPlanName = (planType: number) => {
    switch (planType) {
      case 1:
        return t('subscriptionPlan.basic')
      case 2:
        return t('subscriptionPlan.standard')
      case 3:
        return t('subscriptionPlan.premium')
      case 4:
        return t('subscriptionPlan.enterprise')
      case 5:
        return t('subscriptionPlan.professional')
      default:
        return t('subscriptionPlan.basic')
    }
  }

  const onPurchase = (plan: any, planType: string, subType: string, amount: number) => {
    dialog.showDialog({
      title: planType,
      content: <OrderView plan={plan} planType={planType} subType={subType} amount={amount}/>,
      disableAction: true
    })
  }

  return (
    <Card sx={{height: "100%"}}>
      <CardHeader
        title={getPlanName(plan.type)}
        titleTypographyProps={{align: 'center', color: '#fff'}}
        style={{
          backgroundColor: '#0E5B9D',
          textAlign: 'center',
        }}
      />
      <CardContent>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            minHeight: "200px",
          }}
        >
          <Typography variant="body1">
            <h5 style={{textAlign: 'center'}}>{t('onlineSubscription.includes')}</h5>
            <h5>
              <span>{utils.formatString(t('onlineSubscription.upTo'), plan.employee_capacity)}</span>
            </h5>
            <h5> {plan.mobile_capacity} {t('onlineSubscription.mobileApplications')}</h5>
          </Typography>
          <Typography>
            <Stack direction="column" spacing={2}>
              <Button variant="outlined"
                      onClick={async () => onPurchase(plan, getPlanName(plan.type), "MONTH", plan.month_price)}>
                <Typography variant={"h5"}>
                  {`$${plan.month_price} / ${t('onlineSubscription.month')}`}
                </Typography>
              </Button>
              <Button variant="outlined"
                      onClick={async () => onPurchase(plan, getPlanName(plan.type), "QUARTER", plan.quarter_price)}>
                <Typography variant={"h5"}>
                  {`$${plan.quarter_price} / ${t('onlineSubscription.quarter')}`}
                </Typography>
              </Button>
              <Button variant="outlined">
                <Typography variant={"h5"}
                            onClick={async () => onPurchase(plan, getPlanName(plan.type), "ANNUAL", plan.annual_price)}>
                  {`$${plan.annual_price} / ${t('onlineSubscription.annual')}`}<br/>
                  {`3% ${t('onlineSubscription.discountForAnnualPlan')}`}
                </Typography>
              </Button>
            </Stack>
          </Typography>
        </Box>
      </CardContent>
    </Card>
  )
}
export default PlanCard

import {Route, Routes} from "react-router-dom";
import SchedulePlannerListView from "./ListView";
import SchedulePlannerRecordView from "@/pages/GPS/schedule-planner/RecordView";

const SchedulePlannerRouter = () => {
  return (
    <Routes>
      <Route index element={<SchedulePlannerListView />}/>
      <Route path={"add/"} element={<SchedulePlannerRecordView />}/>
      <Route path={"edit/"} element={<SchedulePlannerRecordView />}/>
    </Routes>
  )
};
export default SchedulePlannerRouter

import React, {ForwardedRef, useImperativeHandle, useRef} from "react";
import {useTranslation} from "react-i18next";
import {RJSFSchema, UiSchema} from "@rjsf/utils";
import LayoutForm from "@/components/rjsf/Form";
import SnackbarUtil from "@/utils/SnackbarUtil";
import DeviceService from "@/services/device/DeviceService";
import EmployeeIDArrayWidget from "@/pages/Device/Device/components/EmployeeIDArrayWidget";


const UploadUserData = (props: Record<any, any>, ref: ForwardedRef<any>) => {
  const {t} = useTranslation("device");
  const {t: common} = useTranslation("common");
  const formRef = useRef<any>()
  const formSchema: RJSFSchema = {
    type: "object",
    properties: {
      mode: {
        type: "integer",
        title: t("device.uploadUserData.mode"),
        oneOf: [
          {const: 1, title: t("device.uploadTransaction.all")},
          {const: 2, title: t("device.uploadTransaction.specific")},
        ],
        default: 1
      },
    },
    required: ["mode"],
    dependencies: {
      mode: {
        oneOf: [
          {
            properties: {
              mode: {
                enum: [2]
              },
              employees: {
                type: "array",
                title: t("device.uploadUserData.employees"),
                items: {
                  type: "string"
                }
              }
            },
            required: ["employees"]
          }
        ]
      }
    }
  }

  const widgets = {
    employees: EmployeeIDArrayWidget
  }

  const uiSchema: UiSchema = {
    employees: {
      "ui:widget": "employees"
    }
  }

  const onConfirm = async () => {
    const isValid = formRef.current?.validateForm()
    if (isValid) {
      const data = formRef.current?.state.formData
      const response = await DeviceService.bulkUploadUserData({...data, devices: props.selectedIds})
      if (response.isSuccess) {
        SnackbarUtil.success(common("common.successful"))
        return true
      } else {
        console.log(response)
        return false
      }
    }
  }

  useImperativeHandle(ref, () => ({
    onConfirm
  }))

  return (
    <LayoutForm
      ref={formRef}
      schema={formSchema}
      widgets={widgets}
      uiSchema={uiSchema}
    />
  )
}
export default React.forwardRef(UploadUserData)

import ListView from "@/components/views/ListView";
import {GridColDef} from "@mui/x-data-grid";
import AppUrls from "@/urls";
import {useTranslation} from "react-i18next";
import {AttEndPoint} from "@/services/att/endpoints";
import {AutoMode, EventNote} from '@mui/icons-material/';


const TimeTableListView = () => {
  const {t} = useTranslation("att")
  const columns: GridColDef[] = [
    {field: "alias", headerName: t("timetable.alias"), flex: 1},
    {
      field: "use_mode", headerName: t("timetable.useMode"), flex: 1,
      type: "singleSelect",
      valueOptions: [
        {value: 0, label: t("timetable.normalTimetable")},
        {value: 1, label: t("timetable.flexibleTimetable")},
      ],
    },
    {field: "in_time", headerName: t("timetable.inTime"), flex: 1},
    {field: "out_time", headerName: t("timetable.outTime"), flex: 1},
    {field: "half_day_work_time_duration", headerName: t("timetable.halfDayWorkTimeDuration"), flex: 1},
    {field: "work_time_duration", headerName: t("timetable.workTimeDuration"), flex: 1},
    {field: "break_time_duration", headerName: t("timetable.breakTimeDuration"), flex: 1},
    {field: "work_day", headerName: t("timetable.workDay"), flex: 1},
    {
      field: "work_type",
      headerName: t("timetable.workType"),
      flex: 1,
      type: "singleSelect",
      valueOptions: [
        {value: 0, label: t("timetable.normalWork")},
        {value: 1, label: t("timetable.dayOff")},
        {value: 2, label: t("timetable.weekend")},
      ],
    },
    {field: "first_half_out_time", headerName: t("timetable.firstHalfOutTime"), flex: 1},
    {field: "second_half_in_time", headerName: t("timetable.secondHalfInTime"), flex: 1},
  ]

  const filters = [
    {key: 'alias', label: t("timetable.alias"), type: 'string'},
  ]

  const action = [
    {
      id: "add-normal",
      title: t("timetable.addNormal"),
      icon: <EventNote/>,
      display: "page",
      url: `${AppUrls.att.timetable}/normal/add`,
      state: {mode: "ADD"},
    },
    {
      id: "add-flexible",
      title: t("timetable.addFlexible"),
      icon: <AutoMode/>,
      display: "page",
      url: `${AppUrls.att.timetable}/flexible/add`,
      state: {mode: "ADD"},
    },
  ]

  return (
    <ListView
      title={t("menu.att.timetable", {ns: "menu"})}
      columns={columns}
      uri={AttEndPoint.TIMETABLE_URI}
      url={AppUrls.att.timetable}
      filters={filters}
      modelActions={action}
      disableAdd={true}
    />
  )
}
export default TimeTableListView

import {Navigate, useRoutes} from "react-router-dom";
import ActivityDashboard from "@/pages/Ralvie/Dashboard";
import ProductivitySummary from "@/pages/Ralvie/ProductivitySummary";
import RalvieConfiguration from "@/pages/Ralvie/Configuration";

const RalvieRouter = () => {
  const routes = useRoutes([
    {index: true, element: <Navigate to={"dashboard"} replace/>},
    {path: "dashboard", element: <ActivityDashboard />},
    {path: "productivity-summary", element: <ProductivitySummary />},
    {path: "configuration", element: <RalvieConfiguration />}
  ])
  return routes
}
export default RalvieRouter

import {useTranslation} from "react-i18next";
import React, {useState} from "react";
import {RJSFSchema, UiSchema} from "@rjsf/utils";
import UsernameWidget from "@/pages/Oauth/components/UsernameWidget";
import CompanyWidget from "@/pages/Oauth/components/CompanyWidget";
import {Box, Button, Typography} from "@mui/material";
import LayoutForm from "@/components/rjsf/Form";
import {useNavigate} from "react-router-dom";
import urls from "@/urls";
import Layout from "@/pages/Oauth/Layout";
import OauthService from "@/services/oauth/OauthService";
import SnackbarUtil from "@/utils/SnackbarUtil";
import qs from 'qs';
import SubmitButton from "@/pages/Oauth/components/SubmitButton";
import Utils from "@/utils";


const VerifyCompanyForm = () => {
  const {t} = useTranslation("oauth");
  const {t: api} = useTranslation("api");
  const [loading, setLoading] = useState<boolean>(false)
  const navigate = useNavigate()
  const formRef = React.useRef<any>()
  const formSchema: RJSFSchema = {
    type: "object",
    properties: {
      username: {
        type: "string",
        title: t("oauth.username"),
      },
      company: {
        type: "string",
        title: t("oauth.companyName")
      }
    },
    required: ["username", "company"]
  }
  const uiSchema: UiSchema = {
    username: {
      "ui:widget": "username",
      "ui:placeholder": t("oauth.username")
    },
    company: {
      "ui:widget": "company",
      "ui:placeholder": t("oauth.companyName"),
      'ui:host': location.host,
    }
  }
  const widgets = {
    username: UsernameWidget,
    company: CompanyWidget
  }

  const onVerifyCompany = async () => {
    setLoading(true)
    const isValid = formRef.current?.validateForm()
    if (isValid) {
      const data = formRef.current?.state.formData
      const response = await OauthService.verifyCompany(data).catch(() => setLoading(false))
      if (response.isSuccess) {
        const curLanguage = localStorage.getItem("i18nextLng");
        const code = Utils.aesEncrypt(JSON.stringify({...data, lng: curLanguage}))
        const params = qs.stringify({code})
        if (window.location.host.startsWith(`${data.company}.`)) {
          navigate(`${urls.signIn}?${params}`)
        } else {
          const newHost = `${data.company}.${window.location.host}`
          const newOrigin = window.location.origin.replace(window.location.host, newHost)
          window.location.href = `${newOrigin}${urls.signIn}?${params}`
        }
      } else {
        console.log(response)
      }
    }
    setLoading(false)
  }

  const onSignUp = async () => {
    navigate(urls.signUp)
  }

  const [formData, setFormData] = useState<Record<string, any>>({username: undefined, company: undefined})

  return (
    <Layout title={t("oauth.signIn.title")}>
      <LayoutForm
        ref={formRef}
        schema={formSchema}
        uiSchema={uiSchema}
        widgets={widgets}
        formData={formData}
        onChange={(e) => setFormData(e.formData)}
        formContext={{
          onSubmit: onVerifyCompany
        }}
      />
      <SubmitButton
        loading={loading}
        onClick={onVerifyCompany}
      >
        {t("oauth.verifyCompany")}
      </SubmitButton>
      <Box
        sx={{
          display: "flex",
          flexWrap: "nowrap",
          alignItems: "center",
          justifyContent: "center",
          margin: "16px 0"
        }}
      >
        <Typography>{t("oauth.signUp.ask")}</Typography>
        <Button onClick={onSignUp} sx={{textTransform: "uppercase"}}>
          {t("oauth.signUp")}
        </Button>
      </Box>
    </Layout>
  )
}
export default VerifyCompanyForm

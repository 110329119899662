import ListView from "@/components/views/ListView";
import { GridColDef } from "@mui/x-data-grid";
import AppUrls from "@/urls";
import { useTranslation } from "react-i18next";
import { AttEndPoint } from "@/services/att/endpoints";
import ApprovalIcon from "@mui/icons-material/Approval";
import ApprovalAction from "@/pages/Attendance/components/ApprovalAction";

const LeaveListView = () => {
  const { t } = useTranslation("att");
  const { t: per } = useTranslation("per");
  const { t: common } = useTranslation("common");
  const displayName = (value: any) => value?.display_name;
  const columns: GridColDef[] = [
    { field: "emp_code", headerName: per("employee.code"), flex: 1 },
    { field: "first_name", headerName: per("employee.firstName"), flex: 1 },
    { field: "last_name", headerName: per("employee.lastName"), flex: 1 },
    { field: "dept_name", headerName: per("employee.department"), flex: 1 },
    { field: "position_name", headerName: per("employee.position"), flex: 1 },
    {
      field: "category",
      valueFormatter: displayName,
      headerName: t("leave.category"),
      flex: 1,
    },
    { field: "start_time", headerName: t("leave.startTime"), flex: 1 },
    { field: "end_time", headerName: t("leave.endTime"), flex: 1 },
    { field: "days", headerName: t("leave.days"), flex: 1 },
    {
      field: "leave_payment_type",
      headerName: t("leave.paymentType"),
      flex: 1,
      type: "singleSelect",
      valueOptions: [
        { value: 0, label: t("paymentType.paid") },
        { value: 1, label: t("paymentType.unpaid") },
      ],
    },
    {
      field: "apply_reason",
      headerName: common("approval.applyReason"),
      flex: 1,
    },
    { field: "apply_time", headerName: common("approval.applyTime"), flex: 1 },
    {
      field: "audit_status",
      headerName: common("approval.status"),
      flex: 1,
      type: "singleSelect",
      valueOptions: [
        { value: 1, label: common("approval.status.pending") },
        { value: 2, label: common("approval.status.approved") },
        { value: 3, label: common("approval.status.rejected") },
        { value: 6, label: common("approval.status.revoked") },
        { value: 7, label: common("approval.status.withdraw") },
      ],
    },
    {
      field: "get_audit_reason",
      headerName: common("approval.remark"),
      flex: 1,
    },
    {
      field: "audit_time",
      headerName: common("approval.time"),
      flex: 1,
    },
    {
      field: "last_approver",
      headerName: common("approval.approver"),
      flex: 1,
    },
  ];

  const modelActions = [
    {
      id: "leaveApprovals",
      title: t("manualLog.transfer"),
      icon: <ApprovalIcon />,
      subs: [
        {
          id: "leaveApprove",
          title: common("approval.action.approve"),
          validateSelected: true,
          display: "dialog",
          Form: ApprovalAction,
          state: {
            uri: AttEndPoint.LEAVE_URI,
            action: "approve",
          },
        },
        {
          id: "leaveReject",
          title: common("approval.action.reject"),
          validateSelected: true,
          display: "dialog",
          Form: ApprovalAction,
          state: {
            uri: AttEndPoint.LEAVE_URI,
            action: "reject",
          },
        },
        {
          id: "leaveRevoke",
          title: common("approval.action.revoke"),
          validateSelected: true,
          display: "dialog",
          Form: ApprovalAction,
          state: {
            uri: AttEndPoint.LEAVE_URI,
            action: "revoke",
          },
        },
      ],
    },
  ];

  const filters = [
    {
      key: "employee__emp_code",
      label: per("employee.code"),
      type: "string",
    },
    {
      key: "employee__first_name",
      label: per("employee.firstName"),
      type: "string",
    },
    {
      key: "employee__last_name",
      label: per("employee.lastName"),
      type: "string",
    },
  ];

  return (
    <ListView
      title={t("menu.att.leave", { ns: "menu" })}
      columns={columns}
      uri={AttEndPoint.LEAVE_URI}
      url={AppUrls.att.leave}
      filters={filters}
      modelActions={modelActions}
      disableEdit
    />
  );
};
export default LeaveListView;

import React from 'react';
import './App.css';
import './asserts/app.sass';
import {BrowserRouter} from "react-router-dom";
import {SnackbarProvider} from "notistack";
import {Provider} from "react-redux";
import store from "@/stores";
import {SnackbarRef} from "@/utils/SnackbarUtil";
import {LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import MainRoutes from "@/router/main";
import { I18nRef } from './utils/i18nt';

function App() {
  return (
    <BrowserRouter basename={"/"}>
      <SnackbarProvider>
        <SnackbarRef/>
        <I18nRef/>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <Provider store={store}>
            <MainRoutes/>
          </Provider>
        </LocalizationProvider>
      </SnackbarProvider>
    </BrowserRouter>
  );
}

export default App;

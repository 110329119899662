import {useRoutes} from "react-router-dom";
import ManualLogListView from "@/pages/Personal/request/manual_log/ListView";
import ManualLogRecordView from "@/pages/Personal/request/manual_log/RecordView";
import AddManualLogRecordView from "@/pages/Personal/request/manual_log/AddRecordView";

const ManualLogRouter = () => {
  const routes = useRoutes([
    {index: true, element: <ManualLogListView />},
    {path: "add", element: <AddManualLogRecordView />},
    {path: "edit", element: <ManualLogRecordView />}
  ])
  return routes
}
export default ManualLogRouter;

import {RJSFSchema, UiSchema} from "@rjsf/utils";
import {useTranslation} from "react-i18next";
import RecordView from "@/components/views/RecordView";
import urls from "@/urls";
import {AttEndPoint} from "@/services/att/endpoints";
import {PsnlEP} from "@/services/psnl/endpoints";
import React from "react";
import {useLocation} from "react-router-dom";
import RecordViewLayout from "@/pages/Attendance/Overtime/components/RecordViewLayout";

const OvertimeRecordView = () => {
  const {t} = useTranslation("att");
  const params = useLocation()
  const mode = params.state?.mode || "ADD"
  const formSchema: RJSFSchema = {
    type: "object",
    required: [
      "employee",
      "start_date",
      "start_time",
      "end_date",
      "end_time",
      "overtime_type",
    ],
    properties: {
      employee: {
        type: "string",
        title: t("overtime.employee"),
        readOnly: mode === "EDIT"
      },
      overtime_type: {
        type: "number",
        title: t("overtime.overtimeType"),
        oneOf: [
          {const: 1, title: t("overtime.option.normalOT")},
          {const: 2, title: t("overtime.option.weekendOT")},
          {const: 3, title: t("overtime.option.holidayOT")},
        ],
      },
      start_date: {
        type: "string",
        format: "date",
        title: t("overtime.startDate"),
      },
      start_time: {
        type: "string",
        title: t("overtime.startTime"),
        default: ""
      },
      end_date: {
        type: "string",
        format: "date",
        title: t("overtime.endDate"),
      },
      end_time: {
        type: "string",
        title: t("overtime.endTime"),
        default: ""
      },
      apply_reason: {
        type: ["string", 'null'],
        title: t("approval.applyReason", {ns: "common"}),
        maxLength: 200
      },
    },
  };

  const uiSchema: UiSchema = {
    "ui:ObjectFieldTemplate": RecordViewLayout,
    employee: {
      "ui:widget": "m2o",
      "ui:options": {
        uri: PsnlEP.EMPLOYEE_URI,
      },
    },
    start_time: {
      'ui:widget': 'time',
      "ui:wrap": true
    },
    end_time: {
      'ui:widget': 'time',
      "ui:wrap": true
    },
    apply_reason: {
      "ui:widget": "textarea",
      "ui:emptyValue": ""
    },
  };

  return (
    <RecordView
      breadcrumbs={[
        {title: t("menu.attendance"), href: urls.att.root},
        {title: t("menu.att.overtime"), href: urls.att.overtime},
      ]}
      formSchema={formSchema}
      uiSchema={uiSchema}
      uri={AttEndPoint.OVERTIME_URI}
    />
  );
};
export default OvertimeRecordView;

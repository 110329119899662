import OnboardPanel from "@/pages/onboard/components/OnboardPanel";
import React, {useState} from "react";
import {RegistryWidgetsType, RJSFSchema, UiSchema} from "@rjsf/utils";
import {useTranslation} from "react-i18next";
import LayoutForm from "@/components/rjsf/Form";
import SubmitButton from "@/pages/Oauth/components/SubmitButton";
import {useNavigate} from "react-router-dom";
import {Box, Button, Checkbox, FormControlLabel, Typography} from "@mui/material";
import OauthService from "@/services/oauth/OauthService";
import PasswordWidget from "@/pages/onboard/components/PasswordWidget";
import EmailWidget from "@/pages/onboard/components/EmailWidget";
import GenericService from "@/services/core/GenericService";
import {OauthEP} from "@/services/oauth/endpoints";


const SignIn = () => {
  const {t} = useTranslation("oauth");
  const {t: api} = useTranslation("api");
  const navigate = useNavigate()
  const [loading, setLoading] = useState<boolean>(false)
  const [rememberPassword, setRememberPassword] = useState<boolean>(false)
  const formRef = React.useRef<any>()
  const formSchema: RJSFSchema = {
    type: "object",
    properties: {
      username: {
        type: "string",
        title: t("oauth.email"),
      },
      password: {
        type: "string",
        title: t("oauth.password")
      }
    },
    required: ["username", "password"]
  }
  const uiSchema: UiSchema = {
    username: {
      "ui:widget": "email"
    },
    password: {
      "ui:widget": "password"
    }
  }
  const widgets: RegistryWidgetsType = {
    email: EmailWidget,
    password: PasswordWidget
  }
  const onSignIn = async () => {
    console.log("onSignIn")
    setLoading(true)
    const isValid = formRef.current?.validateForm()
    if (isValid) {
      const data = formRef.current?.state.formData
      OauthService.signIn(data).then((response) => {
        if (response.isSuccess) {
          localStorage.setItem('userAccessToken', response.data.token)
        }
      })
    }
    setLoading(false)
  }

  const onSignUp = async () => {
    console.log("onSignUp")
  }

  const onForgotPassword = async () => {
    console.log("onForgotPassword")
    // const response = await OauthService.forgotPassword({username: payload.username, company: payload.company})
    // if (response.isSuccess) {
    //   SnackbarUtil.success(t("oauth.emailActivation.successful"))
    //   navigate(urls.forgotPassword, {
    //     state: {
    //       email: response.data?.email,
    //       company: payload.company,
    //       action: "FORGOT_PASSWORD"
    //     }
    //   })
    // }
  }

  return (
    <OnboardPanel>
      <LayoutForm
        ref={formRef}
        schema={formSchema}
        uiSchema={uiSchema}
        widgets={widgets}
        disabled={loading}
        formContext={{
          onSubmit: onSignIn
        }}
      />
      <Box
        sx={{
          display: "flex",
          width: "100%"
        }}
      >
        <FormControlLabel
          control={<Checkbox
            checked={rememberPassword}
            onChange={(event) => setRememberPassword(event.target.checked)}
          />}
          label={t("oauth.rememberPassword")}
          sx={{
            flexGrow: 1
          }}
        />
        <Button sx={{textTransform: "uppercase"}} onClick={onForgotPassword}>
          {t("oauth.forgotPassword")}
        </Button>
      </Box>
      <SubmitButton
        loading={loading}
        onClick={onSignIn}
      >
        {t("oauth.signIn")}
      </SubmitButton>
      <Box
        sx={{
          display: "flex",
          flexWrap: "nowrap",
          alignItems: "center",
          justifyContent: "center",
          margin: "16px 0"
        }}
      >
        <Typography>{t("oauth.signUp.ask")}</Typography>
        <Button onClick={onSignUp} sx={{textTransform: "uppercase"}}>
          {t("oauth.signUp")}
        </Button>
      </Box>
    </OnboardPanel>
  )
}
export default SignIn

import {useTranslation} from "react-i18next";
import {GridColDef} from "@mui/x-data-grid";
import ListView from "@/components/views/ListView";
import AppUrls from "@/urls";
import {GPSEndPoint} from "@/services/gps/endpoints";
import {Message, PhonelinkLock} from "@mui/icons-material";
import PushPublicNotice from "@/pages/GPS/Announcement/components/PushPublicNotice";
import PushPrivateNotice from "@/pages/GPS/Announcement/components/PushPrivateNotice";



const AnnouncementListView = () => {
  const { t: t } = useTranslation("gps");
  const { t: tMenu } = useTranslation("menu");


  const columns: GridColDef[] = [
    {field: "subject", headerName: t("announcement.subject"), flex: 1},
    {field: "category", headerName: t("announcement.category"), flex: 1},
    {field: "receiver", headerName: t("announcement.receiverEmployeeId"), flex: 1},
    {field: "content", headerName: t("announcement.content"), flex: 1},
    {field: "sender", headerName: t("announcement.sender"), flex: 1},
    {field: "system_sender", headerName: t("announcement.systemSender"), flex: 1},
    {field: "create_time", headerName: t("announcement.noticeTime"), flex: 1},
  ]

  const filters = [
    {key: 'subject', label: t("announcement.subject"), type: 'string'},
    {key: 'category', label: t("announcement.category"), type: 'string'},
    {key: 'receiver', label: t("announcement.receiverEmployeeId"), type: 'string'},
  ]

  const modelActions = [
      {id: "pushPublicNotice",
        icon: <Message/>,
        title: t("announcement.pushPublicNotice"),
        display: "dialog",
        Form: PushPublicNotice
      },
      {id: "pushPrivateNotice",
        icon: <PhonelinkLock/>,
        title: t("announcement.pushPrivateNotice"),
        display: "dialog",
        Form: PushPrivateNotice,
      },
    ]

  return (
    <ListView
      title={tMenu("menu.gps.announcement")}
      breadcrumbs={[
        {title: tMenu("menu.gps"), href: AppUrls.gps.root},
      ]}
      columns={columns}
      uri={GPSEndPoint.ANNOUNCEMENT_URI}
      url={AppUrls.gps.announcement}
      filters={filters}
      disableAdd={true}
      disableEdit={true}
      modelActions={modelActions}
    />
  )
}
export default AnnouncementListView

import { WidgetProps } from "@rjsf/utils";
import { Avatar, Badge, Box, Button, Dialog, DialogActions, Stack } from "@mui/material";
import React, { useRef, forwardRef} from "react";
import { bindDialog } from 'material-ui-popup-state';
import { usePopupState } from "material-ui-popup-state/hooks";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import "./cropper.css";
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { asUploadButton } from "@rpldy/upload-button";
import Uploady, { useBatchAddListener, useFileInput } from "@rpldy/uploady";


const UploadyBox = asUploadButton(forwardRef((props: any, ref: any) => <Box {...props} />));

const UploadyInputField = ({ onChange }: any) => {
  const inputRef = useRef() as any;
  useFileInput(inputRef);
  useBatchAddListener((batch) => {
    if (onChange) {
      onChange(batch.items[0].file)
    }
  });
  return (
    <>
      <input ref={inputRef} type="file"
        accept={"image/png,image/jpeg"}
        style={{ display: "none" }}
      />
    </>
  )
}


const ProfilePhotoWidget = (props: WidgetProps) => {
  const { name, label, value, options, uiSchema, readonly } = props
  const [image, setImage] = React.useState('');
  const [cropData, setCropData] = React.useState(value);
  const popupState = usePopupState({ variant: 'popover', popupId: 'popupProfilePhoto' })

  const cropperRef = useRef<any>(null);

  return (
    <div>
      <Stack direction={"row"} spacing={2} sx={{ alignItems: "center" }}>
        <Uploady customInput>
          <UploadyInputField onChange={(fileData: any) => {
            const reader = new FileReader()
            reader.readAsDataURL(fileData)
            reader.onload = (event) => {
              popupState.open()
              setImage(event.target?.result as any)
            }
          }} />
          <UploadyBox>
            <Badge
              sx={{cursor: "pointer"}}
              overlap="circular"
              anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
              badgeContent={
                // <Button size="small" variant="outlined" > Edit </Button>
                <Box sx={{
                  backgroundColor: '#fff',
                  border: "1px solid #ddd",
                  borderRadius: "6px",
                  padding: '3px',
                }}><EditOutlinedIcon /></Box>
              }
            >
              <Avatar
                sx={{ width: "200px", height: "200px", bgcolor: "#4caf50" }}
                src={cropData}
              >No Photo</Avatar>
            </Badge>
          </UploadyBox>
        </Uploady>

      </Stack>

      <Dialog
        {...bindDialog(popupState)}
        maxWidth={'lg'}
      >
        <Box sx={{ height: "400px", width: '400px' }}>
          <Cropper
            src={image}
            style={{ margin: '10px' }}
            aspectRatio={100 / 100}
            guides={false}
            ref={cropperRef}
          />
        </Box>

        <DialogActions>
          <Button onClick={(event) => {
            const cropper = cropperRef.current?.cropper;
            cropper.rotate(90)
          }} >Rotate</Button>
          <Button onClick={(event) => { popupState.close() }} >Cancel</Button>
          <Button variant="contained" onClick={() => {
            setCropData(cropperRef.current?.cropper.getCroppedCanvas().toDataURL());
            props.onChange(cropperRef.current?.cropper.getCroppedCanvas().toDataURL())
            popupState.close()
          }}>Confirm</Button>
        </DialogActions>
      </Dialog>
    </div >
  )
}
export default ProfilePhotoWidget

import {RJSFSchema, UiSchema} from "@rjsf/utils";
import {useTranslation} from "react-i18next";
import RecordView from "@/components/views/RecordView";
import {ReportEndPoint} from "@/services/report/endpoints";
import React from "react";

const PunchStateSettingRecordView = () => {
  const {t: t} = useTranslation("report");
  const {t: tMenu} = useTranslation("menu");
  const formSchema: RJSFSchema = {
    type: "object",
    required: ["key"],
    properties: {
      key: {
        type: "string",
        title: t("punchStateSetting.key"),
      },
      value: {
        type: "string",
        title: t("punchStateSetting.value"),
      },
      name: {
        type: "string",
        title: t("punchStateSetting.name"),
      },
    },
  };

  const uiSchema: UiSchema = {
    "ui:defaultXsWidth": 6,
  };
  const [formData, setFormData] = React.useState<Record<string, any>>({});
  const onChange = (e: any) => {
    const cleanedData = Object.keys(e.formData).reduce(
      (acc: { [key: string]: any }, key: string) => {
        if (
          typeof e.formData[key] !== "string" &&
          e.schema.required.indexOf(key) === -1
        ) {
          acc[key] = "";
        } else {
          acc[key] = e.formData[key];
        }
        return acc;
      },
      {} as { [key: string]: any }
    );
    setFormData({
      ...cleanedData,
    });
  };
  return (
    <RecordView
      formSchema={formSchema}
      uiSchema={uiSchema}
      uri={ReportEndPoint.PUNCH_STATE_SETTING_URI}
      onChange={onChange}
      formData={formData}
    />
  );
};
export default PunchStateSettingRecordView;

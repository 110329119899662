import { useTranslation } from "react-i18next";
import { GridColDef } from "@mui/x-data-grid";
import AppUrls from "@/urls";
import { AuthEP } from "@/services/auth/endpoints";
import ListView from "@/components/views/ListView";
import LayoutTemplate from "./components/LayoutTemplate";
import SyncAltIcon from "@mui/icons-material/SyncAlt";
import ManualExportAction from "./components/ManualExportAction";

const AutoExportListView = () => {
  const { t } = useTranslation("sys");
  const columns: GridColDef[] = [
    { field: "task_code", headerName: t("autoExport.taskCode"), flex: 1 },
    { field: "task_name", headerName: t("autoExport.taskName"), flex: 1 },
    {
      field: "query_field",
      headerName: t("autoExport.queryField"),
      flex: 1,
    },
    {
      field: "export_path",
      headerName: t("autoExport.exportPath"),
      flex: 1,
    },
    {
      field: "export_time",
      headerName: t("autoExport.exportTime"),
      flex: 1,
    },
    {
      field: "execute_time",
      headerName: t("report.punchTime", { ns: "report" }),
      flex: 1,
    },
  ];
  const templates = {
    ObjectFieldTemplate: LayoutTemplate,
  };
  const filters = [
    { key: "task_code", label: t("autoExport.taskCode"), type: "string" },
    { key: "task_name", label: t("autoExport.taskname"), type: "string" },
  ];
  const modelActions = [
    {
      id: "autoExportManualExport",
      title: t("autoExport.manualExport"),
      icon: <SyncAltIcon />,
      validateSelected: true,
      display: "dialog",
      Form: ManualExportAction,
    },
  ];
  return (
    <ListView
      title={t("menu.systemSetting.autoExport", { ns: "menu" })}
      columns={columns}
      uri={AuthEP.AUTO_EXPORT_URI}
      url={AppUrls.system.autoExport}
      filters={filters}
      templates={templates}
      modelActions={modelActions}
    />
  );
};
export default AutoExportListView;

import {Navigate} from "react-router-dom";
import {useAppContext} from "@/provider/AppProvider";
import urls from "@/urls";
import React from "react";

export type PrivateRouteProps = {
  requireRoles?: string[],
  children?: React.ReactElement
}

const PrivateRoute = (props: PrivateRouteProps) => {
  const {currentRole} = useAppContext()
  const requireRoles: string[] = props?.requireRoles || ["tenement"]
  if(!requireRoles.find(role => role === currentRole)){
    if(currentRole === "agent"){
      return <Navigate to={urls.vendor.root} replace/>
    }if(currentRole === "vendor"){
      return <Navigate to={urls.vendor.root} replace/>
    }else if(currentRole === "tenement"){
      return <Navigate to={urls.dashboard} replace/>
    }else if(currentRole === "employee"){
      return <Navigate to={urls.personal.root} replace/>
    }
  }
  return (
    <>
      {props.children}
    </>
  )
}
export default PrivateRoute

import {RJSFSchema, UiSchema} from "@rjsf/utils";
import {useTranslation} from "react-i18next";
import RecordView from "@/components/views/RecordView";
import urls from "@/urls";
import {ComplementEndpoint} from "@/services/complement/endpoints";
import React from "react";
import {PersonalEndPoint} from "@/services/personal/endpoints";

const TrainingRecordView = () => {
  const {t: t} = useTranslation("att");
  const {t: tMenu} = useTranslation("menu");
  const formSchema: RJSFSchema = {
    type: "object",
    required: ["start_date", "start_time", "end_date", "end_time", "category"],
    properties: {
      start_date: {
        type: "string",
        format: "date",
        title: t("report.startDate", {ns: "report"}),
      },
      start_time: {
        type: "string",
        title: t("training.startTime"),
        default: "",
      },
      end_date: {
        type: "string",
        format: "date",
        title: t("report.endDate", {ns: "report"}),
      },
      end_time: {
        type: "string",
        title: t("training.endTime"),
        default: "",
      },
      category: {
        type: "number",
        title: t("training.trainingType"),
      },
      apply_reason: {
        type: ["string", "null"],
        title: t("approval.applyReason", {ns: "common"}),
        maxLength: 200
      },
    },
  };

  const uiSchema: UiSchema = {
    "ui:defaultXsWidth": 6,
    "ui:defaultRowWrap": false,
    "ui:submitButtonOptions": {norender: true},
    category: {
      "ui:widget": "m2o",
      "ui:options": {
        uri: ComplementEndpoint.TRAINING_TYPE_URI,
      },
    },
    start_time: {
      "ui:widget": "time",
      "ui:wrap": true,
    },
    end_time: {
      "ui:widget": "time",
      "ui:wrap": true,
    },
    apply_reason: {
      "ui:widget": "textarea",
    },
  };

  const [formData, setFormData] = React.useState<Record<string, any>>({});
  const onChange = (e: any) => {
    const cleanedData = Object.keys(e.formData).reduce(
      (acc: { [key: string]: any }, key: string) => {
        if (
          typeof e.formData[key] !== "string" &&
          e.schema.required.indexOf(key) === -1
        ) {
          acc[key] = "";
        } else {
          acc[key] = e.formData[key];
        }
        return acc;
      },
      {} as { [key: string]: any }
    );
    setFormData({
      ...cleanedData,
    });
  };

  return (
    <RecordView
      breadcrumbs={[
        {title: tMenu("menu.personal.request"), href: urls.personal.root},
        {
          title: tMenu("menu.personal.training"),
          href: urls.personal.request.training,
        },
      ]}
      formSchema={formSchema}
      uiSchema={uiSchema}
      uri={PersonalEndPoint.TRAINING_URI}
      onChange={onChange}
      formData={formData}
    />
  );
};
export default TrainingRecordView;

import ModelActionPage from "@/components/views/ListView/components/model-action/ModelActionPage";
import urls from "@/urls";
import {useTranslation} from "react-i18next";
import {useEffect, useState} from "react";
import {RJSFSchema} from "@rjsf/utils";
import SystemSettingService from "@/services/base/SystemSettingService";
import SnackbarUtil from "@/utils/SnackbarUtil";


const WhatsAppSetting = () => {
  const {t} = useTranslation("sys")
  const [formData, setFormData] = useState<Record<string, any>>({})
  const formSchema: RJSFSchema = {
    type: "object",
    properties: {
      whatsapp_apikey: {
        type: "string",
        title: t("whatsappSetting.apiKey")
      }
    }
  }
  const uiSchema = {
    "ui:defaultXsWidth": 4,
    whatsapp_apikey: {
      "ui:widget": "password"
    }
  }
  const templates = {}
  const getWhatsAppSetting = async () => {
    const response = await SystemSettingService.getWhatsAppSetting()
    if(response.isSuccess){
      setFormData(response.data)
    }
  }

  const onSave = async (data: Record<string, any>) => {
    const response = await SystemSettingService.updateWhatsAppSetting(data)
    if (response.isSuccess) {
      SnackbarUtil.success("Success")
    } else {
      SnackbarUtil.error(response?.code)
    }
  }

  useEffect(() => {
    getWhatsAppSetting().then()
  }, [])

  return (
    <ModelActionPage
      title={t("menu.systemSetting.whatsAppSetting", {ns: "menu"})}
      breadcrumbs={[
        {title: t("menu.systemSetting", {ns: "menu"}), href: urls.system.root},
      ]}
      formSchema={formSchema}
      uiSchema={uiSchema}
      templates={templates}
      formData={formData}
      disableDiscard={true}
      onSave={onSave}
    />
  )
}
export default WhatsAppSetting

import ListView from "@/components/views/ListView";
import {GridColDef} from "@mui/x-data-grid";
import AppUrls from "@/urls";
import urls from "@/urls";
import {useTranslation} from "react-i18next";
import {ComplementEndpoint} from "@/services/complement/endpoints";
import {Box} from "@mui/material";


const IncidentTypeListView = () => {
  const {t} = useTranslation("complement")
  const columns: GridColDef[] = [
    {field: "category_name", headerName: t("incidentType.incidentName"), flex: 1},
    {field: "report_symbol", headerName: t("incidentType.reportSymbol"), flex: 1},
    {
      field: "report_symbol_color", headerName: t("incidentType.reportSymbolColor"), flex: 1,
      renderCell: (params: any) => {
        return <Box sx={{background: params.value, width: "3rem", height: "3rem"}}/>
      }
    },
  ]

  const filters = [
    {key: 'category_name', label: t("incidentType.incidentName"), type: 'string'},
    {key: 'report_symbol', label: t("incidentType.reportSymbol"), type: 'string'},
  ]

  return (
    <ListView
      title={t("menu.incidentType", {ns: "menu"})}
      columns={columns}
      uri={ComplementEndpoint.INCIDENT_TYPE_URI}
      url={AppUrls.complement.incidentType}
      filters={filters}
    />
  )
}
export default IncidentTypeListView

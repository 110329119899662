import {useTranslation} from "react-i18next";
import {GridColDef} from "@mui/x-data-grid";
import ListView from "@/components/views/ListView";
import AppUrls from "@/urls";
import {AttEndPoint} from "@/services/att/endpoints";
import {CalendarMonth} from "@mui/icons-material";


const TemporaryScheduleListView = () => {
  const {t} = useTranslation("att")
  const columns: GridColDef[] = [
    {field: "first_name", headerName: t("temporarySchedule.employee"), flex: 1},
    {field: "timetable_name", headerName: t("temporarySchedule.timetable"), flex: 1},
    {field: "start_time", headerName: t("temporarySchedule.startTime"), flex: 1},
    {field: "end_time", headerName: t("temporarySchedule.endTime"), flex: 1},
    {
      field: "work_type", headerName: t("temporarySchedule.workType"), flex: 1,
      type: "singleSelect",
      valueOptions: [
        {value: 0, label: t("workType.normalWorkTime")},
        {value: 1, label: t("workType.weekdayOvertime")},
        {value: 2, label: t("workType.weekOffOvertime")},
        {value: 3, label: t("workType.holidayOvertime")},
      ],
    },
    {
      field: "rule_flag", headerName: t("temporarySchedule.scheduleType"), flex: 1,
      type: "singleSelect",
      valueOptions: [
        {value: 1, label: t("scheduleType.only")},
        {value: 2, label: t("scheduleType.additional")},
      ],
    },
  ]

  const modelActions = [
    {
      id: "bulkAdd",
      title: t("temporarySchedule.bulkAdd"),
      icon: <CalendarMonth/>,
      display: "page",
      url: `${AppUrls.att.temporarySchedule}/schedule-by-calendar`
    }
  ]

  return (
    <ListView
      title={t("menu.att.temporarySchedule", {ns: "menu"})}
      columns={columns}
      uri={AttEndPoint.TEMPORARY_SCHEDULE_URI}
      url={AppUrls.att.temporarySchedule}
      modelActions={modelActions}
      disableAdd={true}
      disableEdit={true}
    />
  )
}
export default TemporaryScheduleListView

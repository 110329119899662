import {useTranslation} from "react-i18next";
import {GridColDef, GridRenderCellParams} from "@mui/x-data-grid";
import urls from "@/urls";
import ReportView from "../ReportView";
import {PersonalEndPoint} from "@/services/personal/endpoints";
import { useReportEmployee } from "@/hooks/employee";


const TimeCardReport = () => {
  const {t} = useTranslation("report")
  const employeeFields = useReportEmployee()
  const columns: GridColDef[] = [
    ...employeeFields,
    {
      field: "att_date",
      headerName: t("transaction.punchDate"),
      flex: 1
    },
    {
      field: "punch_times",
      headerName: t("report.punchTimes"),
      flex: 1
    },
    {
      field: "punch_set",
      headerName: t("report.punchTime"),
      flex: 1
    },
  ]

  return (
    <ReportView
      title={t("report.timeCard")}
      breadcrumbs={[]}
      columns={columns}
      uri={PersonalEndPoint.TIME_CARD_URI}
      url={urls.personal.report.timeCard}
      getRowId={(row: any) => Math.random().toString(16).slice(2)}
      hideColumns={['first_name', 'last_name', 'department_code', 'position_code', 'position_name']}
    />
  )
}
export default TimeCardReport

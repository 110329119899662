import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration'
import validator from "@rjsf/validator-ajv8";

dayjs.extend(duration)

export const datetime2string = (
  datetime: Date | dayjs.Dayjs | null | undefined,
  stringFormat = 'YYYY-MM-DDTHH:mm:ss.SSSZ',
) => {
  if (!datetime) {
    return '';
  }
  return dayjs(datetime).format(stringFormat);
};

export const hhmm2duration = (str: string) => {
  const duration = dayjs.duration({
    hours: parseInt(str.split(':')[0], 10),
    minutes: parseInt(str.split(':')[1], 10),
  });
  return duration
};

export const parseTime = (value: string) => {
  const time24Stamp = /^(([0-2][0-3])|([0-1]?[0-9]))(:?)([0-5][0-9])?$/
  const time24Val = value.match(time24Stamp)
  if (time24Val) {
    const time24StampHours = (time24Val[1] || "0").padStart(2, "0")
    const time24StampMinutes = (time24Val[5] || "0").padStart(2, "0")
    return `${time24StampHours}:${time24StampMinutes}`
  }
  const time12Stamp = /^([0]?[0-9]|[1][0-2])([0-5][0-9])?(a|p|am|pm)$/i
  const time12Val = value.match(time12Stamp)
  if (time12Val) {
    const flag = time12Val[3].toLowerCase()
    let hours = parseInt(time12Val[1]) || 0
    if (hours === 12){
      if(flag === "am" || flag === "a"){
        hours = 0
      }
    }else{
      if(flag === "pm" || flag === "p"){
        hours += 12
      }
    }
    const time12StampHours = hours.toString().padStart(2, "0")
    const time12StampMinutes = (time12Val[2] || "0").padStart(2, "0")
    return `${time12StampHours}:${time12StampMinutes}`
  }
  return ""
}

export const parseDuration = (value: string) => {
  const durationPattern = /^([0-9]{1,2})(:?)(([0-5][0-9])?)$/
  const durationVal = value.match(durationPattern)
  if (durationVal) {
    const durationHours = (durationVal[1] || "0").padStart(2, "0")
    const durationMinutes = (durationVal[3] || "0").padStart(2, "0")
    return `${durationHours}:${durationMinutes}`
  }
  const numberPattern = /^([0-9]{1,2})(([0-5][0-9])?)$/
  const numberVal = value.match(numberPattern)
  if (numberVal) {
    const numberHours = (numberVal[1] || "0").padStart(2, "0")
    const numberMinutes = (numberVal[3] || "0").padStart(2, "0")
    return `${numberHours}:${numberMinutes}`
  }
  return ""
}



import {PsnlEP} from "@/services/psnl/endpoints";
import {CustomValidator, RJSFSchema, UiSchema} from "@rjsf/utils";
import {useTranslation} from "react-i18next";
import RecordView from "@/components/views/RecordView";
import urls from "@/urls";
import {useLocation} from "react-router-dom";
import RegExps from "@/utils/RegExps";

const PositionRecordView = () => {
  const {t} = useTranslation("company");
  const {t: common} = useTranslation("common");
  const params = useLocation();
  const mode = params.state?.mode || "ADD";
  const formSchema: RJSFSchema = {
    type: "object",
    required: ["position_code", "position_name"],
    properties: {
      position_code: {
        type: "string",
        title: t("position.code"),
        readOnly: mode === "EDIT",
        "maxLength": 50
      },
      position_name: {
        type: "string",
        title: t("position.name"),
        "maxLength": 100
      },
      parent_position: {
        type: ["number", "null"],
        title: t("position.superior"),
      },
    },
  };

  const uiSchema: UiSchema = {
    "ui:defaultXsWidth": 4,
    "ui:submitButtonOptions": {norender: true},
    parent_position: {
      "ui:widget": "m2o",
      "ui:options": {
        uri: PsnlEP.POSITION_URI,
      },
    },
  };

  const customValidate: CustomValidator = (formData, errors, uiSchema) => {
    if (formData.position_code && RegExps.xssClean.test(formData.position_code)) {
      errors.position_code?.addError(common("form.validateError.illegalInput"))
    }
    if (formData.position_name && RegExps.xssClean.test(formData.position_name)) {
      errors.position_name?.addError(common("form.validateError.illegalInput"))
    }

    return errors
  }

  return (
    <RecordView
      breadcrumbs={[
        {title: t("menu.company"), href: urls.company.root},
        {title: t("menu.company.position"), href: urls.company.position},
      ]}
      formSchema={formSchema}
      uiSchema={uiSchema}
      uri={PsnlEP.POSITION_URI}
      customValidate={customValidate}
    />
  );
};
export default PositionRecordView;

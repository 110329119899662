import React from "react";
import {Box, Typography} from "@mui/material";
import {Stack} from "@mui/system";


const SummaryCard = (props: Record<string, any>) => {
  return (
    <Box sx={{
      display: "flex",
      height: "64px",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: "#fff",
      border: "1px solid rgba(224, 224, 224, 1)",
      borderBottom: "none"
    }}>
      <Stack spacing={0.5} sx={{alignItems: "center"}}>
        <Typography variant={"h4"}>{props.title}</Typography>
        <Typography>{props.value}</Typography>
      </Stack>
    </Box>
  )
}
export default SummaryCard

import {useTranslation} from "react-i18next";
import {GridColDef, GridRenderCellParams} from "@mui/x-data-grid";
import AppUrls from "@/urls";
import ListView from "@/components/views/ListView";
import {VendorEndPoint} from "@/services/vendor/endpoints";
import {Switch} from "@mui/material";


const DiscountListView = () => {
  const {t} = useTranslation("vendor")
  const {t: menu} = useTranslation("menu")
  const renderStatus = (params: GridRenderCellParams) => {
    return <Switch checked={params.value}/>
  }
  const columns: GridColDef[] = [
    {field: "discount_ratio", headerName: t("discount.ratio"), flex: 1},
    {field: "effective_status", headerName: t("discount.effective"), flex: 1, renderCell: renderStatus},
  ]
  return (
    <ListView
      title={menu("menu.subscription.discount")}
      breadcrumbs={[
        {title: menu("menu.subscription"), href: AppUrls.subscription.root},
      ]}
      columns={columns}
      uri={VendorEndPoint.DISCOUNT_URI}
      url={AppUrls.vendor.discount}
    />
  )
}
export default DiscountListView

import { useLocation } from "react-router-dom";
import { Box, Button, Dialog, DialogActions, DialogContent, LinearProgress, Stack, Typography } from "@mui/material";
import ViewBreadcrumbs from "@/components/views/components/ViewBreadcrumbs";
import React, { ForwardedRef, useEffect, useRef, useState } from "react";
import RecordActions from "@/components/views/components/RecordActions";
import LayoutForm from "@/components/rjsf/Form";
import validator from "@rjsf/validator-ajv8";
import { useTranslation } from "react-i18next";
import SnackbarUtil from "@/utils/SnackbarUtil";
import { createBrowserHistory } from "history";
import GenericService from "@/services/core/GenericService";
import Grid from '@mui/material/Grid2';
import DownloadIcon from '@mui/icons-material/Download';
import StarIcon from '@mui/icons-material/Star';
import { downloadFile } from "@/utils";
import CustomTable from "./components/CustomTable";
import ModelActionLayout from "../components/ModelActionLayout";


const ProgressDialog = (props: Record<any, any>, ref: ForwardedRef<any>) => {
  const { open, message, progress, onClose } = props
  const { t } = useTranslation("common")

  return (
    <Dialog sx={{ minWidth: 500, minHeight: 300 }} open={open}>
      <DialogContent>
        <Box sx={{ display: 'flex', alignItems: 'center', minWidth: 400 }}>
          <Box sx={{ width: '100%', mr: 1 }}>
            <LinearProgress variant="determinate" value={progress} />
          </Box>
        </Box>
        <Box sx={{ minWidth: 100 }}>
          <Typography variant="body2" color="text.secondary">
            {progress}% {message}
          </Typography>
        </Box>
      </DialogContent>
      <DialogActions>
        <Stack direction={"row"} spacing={1}>
          <Button onClick={onClose}>{progress === 100 ? t('common.button.close') : t('common.button.cancel')}</Button>
        </Stack>
      </DialogActions>
    </Dialog>
  )
}


type ImportViewProps = Record<string, any>

const ImportView = (props: ImportViewProps, ref: ForwardedRef<any>) => {
  const { t } = useTranslation("common")
  const history = createBrowserHistory()
  const formRef = useRef<any>()
  const [formData, setFormData] = useState<Record<string, any>>(props.formData || {})

  const [message, setMessage] = useState<string>(t("common.importing"))
  const [progress, setProgress] = useState<number>(0)
  const [open, setOpen] = useState<boolean>(false)

  const onSave = async () => {
    const isValid = formRef.current?.validateForm()
    if (isValid) {
      setOpen(true)
      setMessage(t("common.importing"))
      setProgress(0)
      const fields: any = {}
      props.templateDemoHeaders.forEach((each: any) => {
        fields[each['title']] = each['field']
      })
      const data = {
        ...formRef.current?.state.formData,
        fields,
      }
      GenericService.post(`${props.uri}import/`, data).then((response) => {
        if (response?.code === "00000") {
          setProgress(100)
          setMessage(t("common.successful"))
          SnackbarUtil.success(t("common.successful"))
        } else {
          setMessage(t("common.failed"))
          // SnackbarUtil.error(response?.code)
        }
      }).catch((err) => {
        setMessage(t("common.failed"))
        // SnackbarUtil.error(err.response.code)
      })
    } else {
      SnackbarUtil.error(t("common.invalidInput"))
    }
  }

  const onDiscard = async () => {
    history.back()
  }

  const defaultRecordActions: any[] = [
    { title: t("common.button.save"), callback: onSave },
    { title: t("common.button.discard"), variant: "outlined", callback: onDiscard }
  ]


  const ExtraInfo = () => {
    return (
      <div style={{ width: '100%', marginTop: '20px' }}>
        {`${props.uri}import_template/` && <div
          style={{
            paddingBottom: '10px',
          }}
        >
          {t("commond.templateDemo")}:
          <Button variant="text" startIcon={<DownloadIcon />}
            onClick={() => {
              downloadFile(`${props.uri}import_template/`, true, {
                filename: `${props.modelTitle}_${t("common.importTemplate")}.xlsx`,
                fields: props.templateDemoHeaders.map((each: any) => each.title)
              })
            }}
          >{t("commond.downloadTemplate")}</Button>
        </div>}

        {props.templateDemoHeaders &&
          <div>
            <CustomTable
              headers={props.templateDemoHeaders}
              rows={props.templateDemoRows}
            />
            <div
              style={{
                marginTop: '10px',
              }}
            >
              {t("common.description")}:
              <div>1. {t("common.templateHeaderRequired")}</div>
              <div>2. {t("common.templateColumnsWith")} "<span style={{ color: "red" }}> * </span>" {t("common.templateColumnsRequired")}</div>
              <div>3. {t("common.templateColumnsWith")} "<StarIcon sx={{ fontSize: "16px", color: "red" }} />" {t("common.templateColumnsUnique")}</div>
            </div>
          </div>
        }
      </div>
    )
  }

  return (
    <ModelActionLayout
      title={props.title}
      breadcrumbs={props.breadcrumbs}
      actions={defaultRecordActions}
    >
      <LayoutForm
        ref={formRef}
        schema={props.formSchema}
        uiSchema={props.uiSchema}
        fields={props.fields}
        widgets={props.widgets}
        templates={props.templates}
        validator={validator}
        formData={formData}
      />
      {!props.hideExtraInfo && <ExtraInfo />}
      <Box>
        {props.notice?.length && <div style={{ width: '100%', marginTop: '20px' }}>
          <div
            style={{
              marginTop: '10px',
            }}
          >
            <span style={{ color: "red" }}> * </span>Notice:
            {props.notice.map((each: any) => {
              return <div>{each}</div>
            })}
          </div>
        </div>}
      </Box>
      <ProgressDialog open={open} message={message} progress={progress} onClose={() => {
        setOpen(false)
        if (progress === 100) {
          history.back()
        }
      }} />
    </ModelActionLayout>
  )
}
export default React.forwardRef(ImportView)


import {WidgetProps} from "@rjsf/utils";
import React, {useEffect, useMemo, useState} from "react";
import {Autocomplete, InputLabel, TextField} from "@mui/material";
import CustomTreeView from "@/components/rjsf/widgets/AutocompleteTreeWidget/components/CustomTreeView";
import GenericService from "@/services/core/GenericService";


type TreeWidgetProps = WidgetProps & Record<string, any>


const AutocompleteTreeWidget = (props: TreeWidgetProps) => {
  const {name, label, value, options, uiSchema, multiple, readonly, formContext} = props
  const [searchValue, setSearchValue] = useState<string>('')
  const [selections, setSelections] = useState<Record<string, any>[]>([])
  const selected = useMemo(() => {
    if (multiple) {
      return selections.filter((selection) => value?.indexOf(selection.id) > -1)
    }
    return selections.filter((selection) => selection.id === value)
  }, [selections, value])
  const onSelected = async (value: string) => {
    const data = selections.filter((selection) => value.indexOf(selection.id.toString()) > -1)
    console.log("onSelected>>>>>>>>>", multiple, data)
    if (multiple) {
      await props.onChange(data.map(item => item.id))
    } else {
      await props.onChange(data[0].id)
    }
  }
  useEffect(() => {
    if (formContext.preload) {
      const data: Record<string, any>[] = formContext[name]
      setSelections(data || [])
    }else if (options.uri) {
      GenericService.list(options.uri?.toString(), {page: 1, page_size: 100}).then(
        res => {
          // setSelections(res.data.data)
          setSelections(res.data)
        }
      )
    }
  }, [])

  return (
    <Autocomplete
      multiple
      readOnly={readonly}
      options={selections.sort((a, b) => -(a.root < b.root))}
      value={selected}
      isOptionEqualToValue={(option: Record<string, any>, value) => option.id === value?.id}
      getOptionLabel={(option: Record<string, any>) => option?.display_name || ""}
      renderInput={(params: any) => {
        return (
          <TextField
            {...params}
            name={name}
            label={label}
            InputLabelProps={{required: props.required}}
          />
        )
      }}
      filterOptions={(options, {inputValue}) => {
        setSearchValue(inputValue)
        return options.filter((s: any) => s.display_name.indexOf(searchValue) > -1)
      }}
      onChange={async (event, value, reason, details) => {
        if (reason === "removeOption") {
          if (multiple && value.length > 0) {
            props.onChange(value.map(item => item.id))
          } else {
            props.onChange(undefined)
          }
        } else if (reason === "clear") {
          props.onChange(undefined)
        }
      }}
      groupBy={(option) => option.root}
      renderGroup={({key, group, children}) => {
        const groupSelections = selections.filter((s: any) => s.root === group)
        return (
          <CustomTreeView
            key={group}
            sx={{padding: "0 16px"}}
            multiple={multiple}
            selections={(
              searchValue === ""
                ? groupSelections
                : groupSelections.filter((s: any) => s.display_name.indexOf(searchValue) > -1)
            )}
            searchValue={searchValue}
            disabled={options.disabled?.toString()}
            selected={selected}
            onSelected={onSelected}
          />
        )
      }}
    />
  )
};
export default AutocompleteTreeWidget

import {Navigate, useRoutes} from "react-router-dom";
import SubscriptionRouter from "@/pages/vendor/subscription/router";
import TenementRouter from "@/pages/vendor/tenement/router";
import Dashboard from "src/pages/vendor/Dashboard";


const VendorRouter = () => {
  const routes = useRoutes([
    {index: true, element: <Navigate to={"dashboard"} replace/>},
    {path: "dashboard", element: <Dashboard/>},
    {path: "subscription/*", element: <SubscriptionRouter/>},
    {path: "tenement/*", element: <TenementRouter/>},
  ])
  return routes
}
export default VendorRouter

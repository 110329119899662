import {RJSFSchema} from "@rjsf/utils";
import {useTranslation} from "react-i18next";
import RecordView from "@/components/views/RecordView";
import React from "react";
import {PersonalEndPoint} from "@/services/personal/endpoints";


const OvertimeRecordView = () => {
  const {t} = useTranslation("att");
  const {t: menu} = useTranslation("menu");
  const formSchema: RJSFSchema = {
    type: "object",
    properties: {
      start_time: {
        type: "string",
        format: "datetime",
        title: t("overtime.startTime"),
      },
      end_time: {
        type: "string",
        format: "datetime",
        title: t("overtime.endTime"),
      },
      overtime_type: {
        type: "number",
        title: t("overtime.overtimeType"),
        oneOf: [
          { const: 1, title: t("overtime.option.normalOT") },
          { const: 2, title: t("overtime.option.weekendOT") },
          { const: 3, title: t("overtime.option.holidayOT") },
        ],
      },
      apply_reason: {
        type: ["string", 'null'],
        title: t("approval.applyReason", {ns: "common"}),
        maxLength: 200
      },
    },
    required: ["start_time", "end_time", "overtime_type", "apply_reason"],
  };

  const uiSchema = {
    "ui:defaultXsWidth": 6,
    apply_reason: {
      "ui:widget": "textarea",
    },
  }

  return (
    <RecordView
      formSchema={formSchema}
      uiSchema={uiSchema}
      uri={PersonalEndPoint.OVERTIME_URI}
    />
  )
}
export default OvertimeRecordView

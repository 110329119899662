import {Autocomplete, InputAdornment, TextField} from "@mui/material";
import {Search} from "@mui/icons-material";
import React, {useCallback, useEffect, useState} from "react";
import {useMap, useMapsLibrary} from "@vis.gl/react-google-maps";
import {pl} from "date-fns/locale";

const CustomAutoComplete = (props: Record<string, any>) => {
  const {onPlaceSelect} = props
  const map = useMap()
  const places = useMapsLibrary("places")
  // https://developers.google.com/maps/documentation/javascript/reference/places-autocomplete-service#AutocompleteSessionToken
  const [sessionToken, setSessionToken] =
    useState<google.maps.places.AutocompleteSessionToken>();

  // https://developers.google.com/maps/documentation/javascript/reference/places-autocomplete-service
  const [autocompleteService, setAutocompleteService] =
    useState<google.maps.places.AutocompleteService | null>(null);

  // https://developers.google.com/maps/documentation/javascript/reference/places-service
  const [placesService, setPlacesService] =
    useState<google.maps.places.PlacesService | null>(null);

  const [predictionResults, setPredictionResults] = useState<
    Array<google.maps.places.AutocompletePrediction>
  >([])
  const [inputValue, setInputValue] = useState<string>('')

  const fetchPredictions = useCallback(
    async (inputValue: string) => {
      if (!autocompleteService || !inputValue) {
        setPredictionResults([]);
        return;
      }

      const request = {input: inputValue, sessionToken};
      const response = await autocompleteService.getPlacePredictions(request);
      console.log(response.predictions)
      setPredictionResults(response.predictions);
    },
    [autocompleteService, sessionToken]
  );

  const onInputValueChange = useCallback(async (event: any) => {
    await fetchPredictions(event.target.value)
  }, [fetchPredictions])

  const onPlaceChange = useCallback(async (placeId: string|undefined) => {
    if (!places || !placeId) {
      return
    }
    const detailRequestOptions = {
      placeId,
      fields: ['geometry.location', 'name', 'formatted_address', "address_components"],
      sessionToken
    };

    const detailsRequestCallback = (
      placeDetails: google.maps.places.PlaceResult | null
    ) => {
      const address: Record<string, any> = {}
      placeDetails?.address_components?.map((address_component) => {
        switch (address_component.types[0]){
          case "country":
            address.country = address_component.long_name
            break
          case "administrative_area_level_1":
            address.state = address_component.long_name
            break
          case "locality":
            address.city = address_component.long_name
            break
          default:
            break
        }
      })
      onPlaceSelect({...placeDetails, address});
      setInputValue(placeDetails?.formatted_address ?? '');
      setSessionToken(new places.AutocompleteSessionToken());
    };

    placesService?.getDetails(detailRequestOptions, detailsRequestCallback);
  }, [places, placesService, sessionToken])

  useEffect(() => {
    if (!places || !map) return;

    setAutocompleteService(new places.AutocompleteService());
    setPlacesService(new places.PlacesService(map));
    setSessionToken(new places.AutocompleteSessionToken());

    return () => setAutocompleteService(null);
  }, [map, places]);
  return (
    <Autocomplete
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            fullWidth
            slotProps={{
              input: {
                ...params.InputProps,
                startAdornment: <InputAdornment position={"start"}><Search/></InputAdornment>,
              },
              htmlInput: {
                ...params.inputProps,
              }
            }}
            onInput={onInputValueChange}
          />
        )
      }}
      options={predictionResults}
      getOptionKey={(option) => option.place_id}
      getOptionLabel={(option) => option.description}
      onChange={async (event, value, reason, details) => {
        switch (reason){
          case "selectOption":
            await onPlaceChange(value?.place_id)
            break
          case "clear":
            setInputValue("")
            break
          default:
            return
        }
      }}
    />
  )
}
export default CustomAutoComplete

import { Box, InputAdornment, TextField, Tooltip } from "@mui/material";
import { green } from "@mui/material/colors";
import Grid from "@mui/material/Grid2";
import { WidgetProps } from "@rjsf/utils";
import { useRef } from "react";
import { useTranslation } from "react-i18next";

const DataTemplateWidget = (props: WidgetProps) => {
  const { t } = useTranslation("sys");
  const {
    id,
    name,
    value,
    label,
    placeholder,
    onChange,
    formContext,
    readonly,
    disabled,
    required,
    uiSchema,
  } = props;
  const inputRef = useRef<HTMLInputElement>(null);
  const handleDragStart = (event: any, text: any) => {
    event.dataTransfer.setData("text", text);
  };

  const handleDrop = (event: any) => {
    event.preventDefault();
    const droppedText = event.dataTransfer.getData("text");
    typeof value === "undefined"
      ? onChange(droppedText)
      : onChange(value + droppedText);
  };

  const handleDragOver = (event: any) => {
    event.preventDefault();
  };

  const DraggableText = () => {
    const all_text = [
      { show_text: "{emp_code}", tip_text: t("employee.code", { ns: "per" }) },
      {
        show_text: "{first_name}",
        tip_text: t("employee.firstName", { ns: "per" }),
      },
      {
        show_text: "{last_name}",
        tip_text: t("employee.lastName", { ns: "per" }),
      },
      {
        show_text: "{dept_code}",
        tip_text: t("department.code", { ns: "default" }),
      },
      {
        show_text: "{dept_name}",
        tip_text: t("department.name", { ns: "default" }),
      },
      {
        show_text: "{date}",
        tip_text: t("transaction.punchDate", { ns: "default" }),
      },
      {
        show_text: "{time}",
        tip_text: t("report.punchTime", { ns: "report" }),
      },
      {
        show_text: "{verify_type}",
        tip_text: t("autoExport.verifyType"),
      },
      {
        show_text: "{punch_state}",
        tip_text: t("manualLog.punchState", { ns: "att" }),
      },
      {
        show_text: "{work_code}",
        tip_text: t("report.work_code", { ns: "report" }),
      },
      {
        show_text: "{card_number}",
        tip_text: t("autoExport.cardNumber"),
      },
      { show_text: "{area_name}", tip_text: t("area.name", { ns: "default" }) },
      {
        show_text: "{terminal_alias}",
        tip_text: t("transaction.terminalAlias", { ns: "default" }),
      },
      {
        show_text: "{terminal_sn}",
        tip_text: t("transaction.terminalSn", { ns: "default" }),
      },
      {
        show_text: "{temperature}",
        tip_text: t("autoExport.temperature"),
      },
      {
        show_text: "{mask_flag}",
        tip_text: t("autoExport.maskFlag"),
      },
      {
        show_text: "{gps_location}",
        tip_text: t("autoExport.gpsLocation"),
      },
    ];

    return (
      <Grid container>
        {all_text.map((item: any, index: number) => {
          return (
            <Grid key={`ref${index}`} size={3}>
              <Box
                draggable
                onDragStart={(e) => handleDragStart(e, item["show_text"])}
                sx={{ marginTop: 2 }}
              >
                <Tooltip title={item["tip_text"]}>
                  <div style={{ color: "#2e94e0" }}>{item["show_text"]}</div>
                </Tooltip>
              </Box>
            </Grid>
          );
        })}
      </Grid>
    );
  };

  return (
    <div>
      <Box>{t("autoExport.dataTemplateSettingsTip")}</Box>
      <Box>
        <DraggableText />
      </Box>
      <TextField
        ref={inputRef}
        multiline
        rows={4}
        sx={{ marginTop: 2 }}
        fullWidth
        required={required}
        id={id}
        name={name}
        value={value}
        label={""}
        onChange={async (event) => {
          onChange(event.target.value);
        }}
        onDragOver={handleDragOver}
        onDrop={handleDrop}
      />
    </div>
  );
};
export default DataTemplateWidget;

import {Route, Routes} from "react-router-dom";
import OutdoorScheduleListView from "./ListView";
import OutdoorScheduleRecordView from "@/pages/GPS/outdoor-schedule/RecordView";

const OutdoorScheduleRouter = () => {
  return (
    <Routes>
      <Route index element={<OutdoorScheduleListView />}/>
      <Route path={"add"} element={<OutdoorScheduleRecordView />}/>
      <Route path={"edit"} element={<OutdoorScheduleRecordView />}/>
    </Routes>
  )
};
export default OutdoorScheduleRouter

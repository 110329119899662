import {ObjectFieldTemplateProps} from "@rjsf/utils";
import {Stack} from "@mui/material";
import {useTranslation} from "react-i18next";
import Grid from '@mui/material/Grid2';
import {ScrollTab, ScrollTabs, useScrollTabsHook} from "@/components/ScrollTabs";
import React from "react";


const LayoutTemplate = (props: ObjectFieldTemplateProps) => {
  const {t} = useTranslation('att')
  const fields: Record<string, any> = {}
  props.properties?.forEach((element: any) => {
    fields[element.name] = element.content
  })

  const [excludeTabs, setExcludeTabs] = React.useState<Array<string>>([])

  React.useEffect(() => {
    if (fields.calc_type.props.formData === 0) {
      setExcludeTabs(['requirePunchSetting'])
    } else {
      setExcludeTabs([])
    }
  }, [props])

  const [tabs, getTabProps] = useScrollTabsHook([
    {key: 'basicSettings', title: t("breakTime.basicSettings")},
    {key: 'ruleSettings', title: t("breakTime.ruleSettings")},
    {key: 'requirePunchSetting', title: t("breakTime.requirePunchSetting")},
  ])

  return (
    <ScrollTabs tabs={tabs} excludeTabs={excludeTabs}>
      <Stack spacing={3} sx={{width: '80%'}}>
        <ScrollTab {...getTabProps('basicSettings')} >
          <Grid container spacing={2}>
            <Grid size={4}>{fields.alias}</Grid>
            <Grid size={4}>{fields.calc_type}</Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid size={4}>{fields.start_time}</Grid>
            <Grid size={4}>{fields.end_time}</Grid>
            <Grid size={4}>{fields.duration}</Grid>
          </Grid>
        </ScrollTab>

        <ScrollTab {...getTabProps("ruleSettings")}>
          <Grid container spacing={2}>
            <Grid size={4}>{fields.available_interval_type}</Grid>
            <Grid size={4}>{fields.available_interval}</Grid>
            <Grid size={4}>{fields.func_key}</Grid>
          </Grid>
        </ScrollTab>

        {fields.calc_type.props.formData === 1 && <ScrollTab {...getTabProps("requirePunchSetting")}>
          <Grid container spacing={2}>
            <Grid size={4}>{fields.multiple_punch}</Grid>
            <Grid size={4}>{fields.minimum_duration}</Grid>
            <Grid size={4} />
            <Grid size={4}>{fields.early_in}</Grid>
            <Grid size={4}>{fields.min_early_in}</Grid>
            <Grid size={4} />
            <Grid size={4}>{fields.late_in}</Grid>
            <Grid size={4}>{fields.min_late_in}</Grid>
          </Grid>
        </ScrollTab>}

      </Stack>
    </ScrollTabs>
  )
}
export default LayoutTemplate

import {useTranslation} from "react-i18next";
import {useNavigate, useSearchParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {CustomValidator, RJSFSchema, UiSchema} from "@rjsf/utils";
import PasswordWidget from "@/pages/Oauth/components/PasswordWidget";
import LayoutForm from "@/components/rjsf/Form";
import {Box, Link} from "@mui/material";
import Layout from "@/pages/Oauth/Layout";
import urls from "@/urls";
import SnackbarUtil from "@/utils/SnackbarUtil";
import OauthService from "@/services/oauth/OauthService";
import SubmitButton from "@/pages/Oauth/components/SubmitButton";
import RegExps from "@/utils/RegExps";


const ResetPassword = () => {
  const {t} = useTranslation("oauth")
  const navigate = useNavigate()
  const [search, setSearch] = useSearchParams()
  const verifyCode = search.get("code")
  const [loading, setLoading] = useState<boolean>(false)
  const formRef = React.useRef<any>()
  const formSchema: RJSFSchema = {
    type: "object",
    properties: {
      password: {
        type: "string",
        title: t("oauth.password")
      },
      confirm_password: {
        type: "string",
        title: t("oauth.confirmPassword")
      }
    },
    required: ["password", "confirm_password"]
  }
  const uiSchema: UiSchema = {
    password: {
      "ui:widget": "password",
      "ui:placeholder": t("oauth.password"),
    },
    confirm_password: {
      "ui:widget": "password",
      "ui:placeholder": t("oauth.confirmPassword"),
    }
  }
  const widgets = {
    password: PasswordWidget,
  }
  const customValidate: CustomValidator = (formData, errors, uiSchema) => {
    if (formData.password && !RegExps.password.test(formData.password)) {
      errors.password?.addError(t("oauth.validateError.unsafePassword"))
    }
    if (formData.password !== formData.confirm_password) {
      errors.confirm_password?.addError(t("oauth.validateError.mismatchPassword"))
    }
    return errors
  }

  const onResetPassword = async () => {
    setLoading(true)
    const isValid = formRef.current?.validateForm()
    if (isValid) {
      const data = formRef.current?.state.formData
      OauthService.resetPassword({code: verifyCode, ...data}).then((response) => {
        if (response.isSuccess) {
          SnackbarUtil.success(t("common.message.successful", {ns: "common"}))
          navigate(urls.companyVerification)
        }
      }).finally(() => {
        setLoading(false)
      })
    } else {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (!verifyCode) {
      navigate(urls.companyVerification)
    } else {
      setLoading(true)
      OauthService.resetPasswordCheck({code: verifyCode}).then((response) => {
        if (!response.isSuccess) {
          SnackbarUtil.error(t(response.code))
          navigate(urls.companyVerification)
        }
      }).catch(() => {
        SnackbarUtil.error(t("ZBOA0001"))
      }).finally(() => {
        setLoading(false)
      })
    }
  }, [])

  return (
    <Layout title={t("oauth.resetPassword.title")}>
      <LayoutForm
        ref={formRef}
        schema={formSchema}
        uiSchema={uiSchema}
        widgets={widgets}
        customValidate={customValidate}
        formContext={{
          onSubmit: onResetPassword
        }}
      />
      <SubmitButton
        loading={loading}
        onClick={onResetPassword}
      >
        {t("oauth.restPassword")}
      </SubmitButton>
      <Box sx={{p: 2}}>
        <Link href={urls.companyVerification}>
          {t("oauth.backToSignIn")}
        </Link>
      </Box>
    </Layout>
  )
}
export default ResetPassword

import {useTranslation} from "react-i18next";
import {GridColDef} from "@mui/x-data-grid";
import {ReportEndPoint} from "@/services/report/endpoints";
import urls from "@/urls";
import ReportView from "../ReportView";
import {PersonalEndPoint} from "@/services/personal/endpoints";
import { useReportEmployee } from "@/hooks/employee";


const TotalTimecardReport = () => {
  const {t} = useTranslation("report")
  const employeeFields = useReportEmployee()
  const columns: GridColDef[] = [
    ...employeeFields,
    {
      field: "att_date",
      headerName: t("report.scheduleDate"),
      flex: 1
    }, {
      field: "timetable_name",
      headerName: t("report.timetable"),
      flex: 1
    }, {
      field: "duration",
      headerName: t("report.duration"),
      flex: 1
    }, {
      field: "check_in",
      headerName: t("report.checkIn"),
      flex: 1
    }, {
      field: "check_out",
      headerName: t("report.checkOut"),
      flex: 1
    }, {
      field: "duty_duration",
      headerName: t("report.dutyDuration"),
      flex: 1
    }, {
      field: "work_day",
      headerName: t("report.workDay"),
      flex: 1
    }, {
      field: "clock_in",
      headerName: t("report.clockIn"),
      flex: 1
    }, {
      field: "clock_out",
      headerName: t("report.clockOut"),
      flex: 1
    }, {
      field: "total_time",
      headerName: t("report.totalTime"),
      flex: 1
    }, {
      field: "duty_wt",
      headerName: t("report.dutyWorkTime"),
      flex: 1
    }, {
      field: "actual_wt",
      headerName: t("report.actualWorkTime"),
      flex: 1
    }, {
      field: "unscheduled",
      headerName: t("report.unschedule"),
      flex: 1
    }, {
      field: "remaining",
      headerName: t("report.remaining"),
      flex: 1
    }, {
      field: "late",
      headerName: t("report.late"),
      flex: 1
    }, {
      field: "break_late",
      headerName: t("report.breakTimeLate"),
      flex: 1
    }, {
      field: "early_leave",
      headerName: t("report.earlyLeave"),
      flex: 1
    }, {
      field: "absent",
      headerName: t("report.absent"),
      flex: 1
    }, {
      field: "break_absent",
      headerName: t("report.breakTimeAbsent"),
      flex: 1
    }, {
      field: "total_worked",
      headerName: t("report.totalWorkTime"),
      flex: 1
    }, {
      field: "leave",
      headerName: t("report.leave"),
      flex: 1
    }, {
      field: "normal_wt",
      headerName: t("report.normalWorkTime"),
      flex: 1
    }, {
      field: "break_duration",
      headerName: t("report.breakTimeDuration"),
      flex: 1
    }, {
      field: "normal_ot",
      headerName: t("report.normalOT"),
      flex: 1
    }, {
      field: "weekend_ot",
      headerName: t("report.weekOffOT"),
      flex: 1
    }, {
      field: "holiday_ot",
      headerName: t("report.holidayOT"),
      flex: 1
    }, {
      field: "ot_lv1",
      headerName: t("report.overtimeLV1"),
      flex: 1
    }, {
      field: "ot_lv2",
      headerName: t("report.overtimeLV2"),
      flex: 1
    }, {
      field: "ot_lv3",
      headerName: t("report.overtimeLV3"),
      flex: 1
    },
  ]
  return (
    <ReportView
      title={t("reportLibrary.totalTimecard")}
      breadcrumbs={[]}
      columns={columns}
      uri={PersonalEndPoint.TOTAL_TIME_CARD_URI}
      url={urls.personal.report.totalTimeCard}
      getRowId={(row: any) => row.uuid}
      hideColumns={['first_name', 'last_name', 'department_code', 'position_code', 'position_name']}
    />
  )
}
export default TotalTimecardReport

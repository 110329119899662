import {Stack} from "@mui/system";
import {Button, Typography} from "@mui/material";
import React from "react";
import {useNavigate} from "react-router-dom";


const ReportButton = (props: Record<string, any>) => {
  const navigate = useNavigate()
  const {icon, title} = props
  return (
    <Button
      variant={"contained"}
      sx={{
        width: "120px",
        height: "112px",
        margin: "4px",
        padding: "4px",
        borderRadius: "4px",
      }}
      onClick={() => navigate(props.url)}
    >
      <Stack
        direction={"column"}
        spacing={1}
        sx={{
          alignItems: "center",
          "& .MuiSvgIcon-root": {
            width: "40px",
            height: "40px"
          }
        }}
      >
        {icon}
        <Typography
          color={"#fff"}
          sx={{
            wordBreak: "break-word",
            fontSize: 12
          }}
        >
          {title}
        </Typography>
      </Stack>
    </Button>
  )
}
export default ReportButton

import {ObjectFieldTemplateProps} from "@rjsf/utils";
import Grid from "@mui/material/Grid2";


const RecordViewLayout = (props: ObjectFieldTemplateProps) => {
  const fields: Record<string, any> = {}
  props.properties?.forEach((element: any) => {
    fields[element.name] = element.content
  })
  return (
    <Grid container>
      <Grid size={4} container spacing={2}>
        <Grid size={12}>{fields.employee}</Grid>
        <Grid size={12}>{fields.overtime_type}</Grid>
        <Grid size={6}>{fields.start_date}</Grid>
        <Grid size={6}>{fields.start_time}</Grid>
        <Grid size={6}>{fields.end_date}</Grid>
        <Grid size={6}>{fields.end_time}</Grid>
        <Grid size={12}>{fields.apply_reason}</Grid>
      </Grid>
    </Grid>
  )
}
export default RecordViewLayout

import {useTranslation} from "react-i18next";
import {CustomValidator, RJSFSchema, UiSchema} from "@rjsf/utils";
import RecordView from "@/components/views/RecordView";
import urls from "@/urls";
import {AccessControlEndPoint} from "@/services/acc/endpoints";
import React from "react";
import {FormProps} from "@rjsf/core";
import TimePeriodRecordViewLayout from "@/pages/AccessControl/time_period/components/RecordViewLayout";
import {PsnlEP} from "@/services/psnl/endpoints";
import {useLocation} from "react-router-dom";


const TimePeriodRecordView = () => {
  const {t} = useTranslation("acc");
  const params = useLocation()
  const mode = params.state?.mode || "ADD"
  const area = params.state?.record?.area || params.state?.area
  const formSchema: RJSFSchema = {
    definitions: {
      start_time: {
        type: "string",
        title: t("timePeriod.startTime"),
        default: "00:00"
      },
      end_time: {
        type: "string",
        title: t("timePeriod.endTime"),
        default: "23:59"
      },
      weekday_on: {
        type: "boolean",
        default: true
      }
    },
    type: "object",
    properties: {
      area: {
        type: "integer",
        title: t("timePeriod.area"),
        // readOnly: mode === "EDIT",
        readOnly: true,
        default: parseInt(area),
      },
      timezone_no: {
        type: "integer",
        title: t("timePeriod.no"),
        minimum: mode === "ADD"? 2: 1,
        maximum: 50,
        readOnly: mode === "EDIT",
        oneOf: Array(50).fill(0).map((v, i) => ({const: i +1, title: (i + 1).toString()}))
      },
      timezone_name: {
        type: "string",
        title: t("timePeriod.name"),
        maxLength: 100
      },
      sun_start: {$ref: "#/definitions/start_time"},
      sun_end: {$ref: "#/definitions/end_time"},
      sun_on: {$ref: "#/definitions/weekday_on", title: t("weekday.sunday", {ns: "common"})},
      mon_start: {$ref: "#/definitions/start_time"},
      mon_end: {$ref: "#/definitions/end_time"},
      mon_on: {$ref: "#/definitions/weekday_on", title: t("weekday.monday", {ns: "common"})},
      tue_start: {$ref: "#/definitions/start_time"},
      tue_end: {$ref: "#/definitions/end_time"},
      tue_on: {$ref: "#/definitions/weekday_on", title: t("weekday.tuesday", {ns: "common"})},
      wed_start: {$ref: "#/definitions/start_time"},
      wed_end: {$ref: "#/definitions/end_time"},
      wed_on: {$ref: "#/definitions/weekday_on", title: t("weekday.wednesday", {ns: "common"})},
      thu_start: {$ref: "#/definitions/start_time"},
      thu_end: {$ref: "#/definitions/end_time"},
      thu_on: {$ref: "#/definitions/weekday_on", title: t("weekday.thursday", {ns: "common"})},
      fri_start: {$ref: "#/definitions/start_time"},
      fri_end: {$ref: "#/definitions/end_time"},
      fri_on: {$ref: "#/definitions/weekday_on", title: t("weekday.friday", {ns: "common"})},
      sat_start: {$ref: "#/definitions/start_time"},
      sat_end: {$ref: "#/definitions/end_time"},
      sat_on: {$ref: "#/definitions/weekday_on", title: t("weekday.saturday", {ns: "common"})},
    },
    required: [
      "area", "timezone_no", "timezone_name",
      "sun_on", "sun_start", "sun_end",
      "mon_on", "mon_start", "mon_end",
      "tue_on", "tue_start", "tue_end",
      "wed_on", "wed_start", "wed_end",
      "thu_on", "thu_start", "thu_end",
      "fri_on", "fri_start", "fri_end",
      "sat_on", "sat_start", "sat_end",
    ]
  }

  const uiSchema: UiSchema = {
    area: {
      "ui:widget": "m2o",
      "ui:options": {
        uri: PsnlEP.ACCESS_AREA_URI
      }
    },
    sun_start: {
      "ui:widget": "time"
    },
    sun_end: {
      "ui:widget": "time"
    },
    sun_on: {
      "ui:widget": "switch"
    },
    mon_start: {
      "ui:widget": "time"
    },
    mon_end: {
      "ui:widget": "time"
    },
    mon_on: {
      "ui:widget": "switch"
    },
    tue_start: {
      "ui:widget": "time"
    },
    tue_end: {
      "ui:widget": "time"
    },
    tue_on: {
      "ui:widget": "switch"
    },
    wed_start: {
      "ui:widget": "time"
    },
    wed_end: {
      "ui:widget": "time"
    },
    wed_on: {
      "ui:widget": "switch"
    },
    thu_start: {
      "ui:widget": "time"
    },
    thu_end: {
      "ui:widget": "time"
    },
    thu_on: {
      "ui:widget": "switch"
    },
    fri_start: {
      "ui:widget": "time"
    },
    fri_end: {
      "ui:widget": "time"
    },
    fri_on: {
      "ui:widget": "switch"
    },
    sat_start: {
      "ui:widget": "time"
    },
    sat_end: {
      "ui:widget": "time"
    },
    sat_on: {
      "ui:widget": "switch"
    }
  }
  const customValidate: CustomValidator = (formData, errors, uiSchema) => {
    return errors
  }
  const widgets = {}
  const templates:Partial<FormProps["templates"]> = {
    ObjectFieldTemplate: TimePeriodRecordViewLayout
  }

  return (
    <RecordView
      breadcrumbs={[
        { title: t("menu.accessControl", {ns: "menu"}), href: urls.acc.root},
        { title: t("menu.acc.timePeriod", {ns: "menu"}), href: urls.acc.timePeriod}
      ]}
      formSchema={formSchema}
      uiSchema={uiSchema}
      customValidate={customValidate}
      widgets={widgets}
      templates={templates}
      uri={AccessControlEndPoint.TIME_PERIOD_URI}
    />
  )
}
export default TimePeriodRecordView

import {useTranslation} from "react-i18next";
import {useLocation, useNavigate} from "react-router-dom";
import React, {useEffect, useRef, useState} from "react";
import {RJSFSchema, UiSchema} from "@rjsf/utils";
import EmailWidget from "@/pages/Oauth/components/EmailWidget";
import OauthService from "@/services/oauth/OauthService";
import SnackbarUtil from "@/utils/SnackbarUtil";
import urls from "@/urls";
import Layout from "@/pages/Oauth/Layout";
import LayoutForm from "@/components/rjsf/Form";
import {Box, Typography} from "@mui/material";
import SubmitButton from "@/pages/Oauth/components/SubmitButton";


const ForgotPassword = () => {
  const {t} = useTranslation("oauth");
  const {t: api} = useTranslation("api");
  const navigate = useNavigate()
  const timerRef = useRef<any>()
  const [loading, setLoading] = useState<boolean>(false)
  const [time, setTime] = useState(30)
  const params = useLocation()
  const email = params.state?.email
  const company = params.state?.company
  const formRef = React.useRef<any>()
  const formSchema: RJSFSchema = {
    type: "object",
    properties: {
      email: {
        type: "string",
        title: t("oauth.username"),
        default: email
      }
    },
    required: ["email"]
  }
  useEffect(() => {
    if (time && time > 0) {
      timerRef.current = setTimeout(() => {
        setTime(time => time - 1)
      }, 1000)
    }
    return () => {
      clearTimeout(timerRef.current)
    }
  }, [time])

  const uiSchema: UiSchema = {
    email: {
      "ui:widget": "email",
      "ui:placeholder": t("oauth.username"),
      "ui:disabled": true
    }
  }

  const widgets = {
    email: EmailWidget,
  }

  const onSendEmail = async () => {
    setLoading(true)
    OauthService.forgotPassword({username: email, company}).then((response) => {
      if (response.isSuccess) {
        SnackbarUtil.success(t("oauth.sendEmail.successful"))
        setTime(30)
      }
    }).finally(() => {
      setLoading(false)
    })
  }

  useEffect(() => {
    if (!email) {
      navigate(urls.companyVerification)
    }
  }, [])

  return (
    <Layout title={t("oauth.resetPassword.title")}>
      <LayoutForm
        ref={formRef}
        schema={formSchema}
        uiSchema={uiSchema}
        widgets={widgets}
      />
      <SubmitButton
        loading={loading}
        onClick={onSendEmail}
        disabled={Boolean(time && time > 0)}
        endIcon={time === 0 ? "" : time}
      >
        {t("oauth.sendEmailAgain")}
      </SubmitButton>
      <Box
        sx={{
          display: "flex",
          flexWrap: "nowrap",
          alignItems: "center",
          justifyContent: "center",
          margin: "16px 0"
        }}
      >
        <Typography>
          {t("oauth.resetPassword.notice")}
        </Typography>
      </Box>
    </Layout>
  )
}
export default ForgotPassword

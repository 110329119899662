import { useTranslation } from "react-i18next";
import { GridColDef } from "@mui/x-data-grid";
import { ReportEndPoint } from "@/services/report/endpoints";
import urls from "@/urls";
import ReportView from "@/pages/Report/ReportLibrary/components/ReportView";
import { useEffect, useState } from "react";
import GenericService from "@/services/core/GenericService";
import { AttEndPoint } from "@/services/att/endpoints";
import dayjs from 'dayjs';


const AttSheetSummaryReport = () => {
  const { t } = useTranslation("report")
  const dangerouslySetInnerHTML = (params: any) => {
    return <div dangerouslySetInnerHTML={{ __html: params.value }} />
  }
  const fixedColumns: GridColDef[] = [
    // { field: "position_code", headerName: t("position.code", {ns: "defaut"}), minWidth: 120,},
    { field: "No.", headerName: t("report.column.number"), minWidth: 80},
    { field: "total_emp", headerName: t("report.column.totalEmployee"), minWidth: 120},
    // { field: "position_name", headerName: t("report.position"), minWidth: 120, },
  ]

  const [dayColumns, setDayColumns] = useState<GridColDef[]>([])
  const [columns, setColumns] = useState<GridColDef[]>(fixedColumns)

  let startDate = new Date()
  let endDate = new Date()
  const onUpdateFilterModel = (value: any) => {
    if (value.startDate !== startDate || value.endDate !== endDate) {
      startDate = value.startDate
      endDate = value.endDate
      if (startDate && endDate) {
        const dayFileds: any = []
        const days = dayjs(endDate).diff(startDate, "day")
        for (let day = 0; day <= days; day++) {
          const curDate = dayjs(startDate).add(day, 'day')
          dayFileds.push({field: curDate.format('MM/DD'), headerName: `${day + 1}`, renderCell: dangerouslySetInnerHTML})
        }
        setDayColumns(dayFileds)
      }
    }
  }

  useEffect(() => {
    setColumns((old) => {
      return [
        ...fixedColumns,
        ...dayColumns,
      ]
    })
  }, [dayColumns])

  return (
    <ReportView
      title={t("reportLibrary.shiftSummary")}
      breadcrumbs={[]}
      columns={columns}
      uri={ReportEndPoint.ATTSHEET_SUMMARY_URI}
      url={urls.report.monthlyAttSheetSummary}
      getRowId={(row: any) => Math.random().toString(16).slice(2)}
      onUpdateFilterModel={onUpdateFilterModel}
      filterType={'month'}
      wiseFields={[]}
    />
  )
}
export default AttSheetSummaryReport

import {Box, Card, CardContent, CardHeader, MenuItem, Select, Typography} from "@mui/material";
import React, {useEffect, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import * as echarts from "echarts";
import GenericService from "@/services/core/GenericService";
import {BaseEP} from "@/services/base/endpoints";
import dayjs from "dayjs";


const WorkHoursLineChart = () => {
  const {t} = useTranslation("common")
  const containerRef = useRef<any>()
  const chart = useRef<any>()
  const [timePeriod, setTimePeriod] = useState("TODAY")
  const [data, setData] = useState<any>({
    labels: [],
    series: []
  })
  const timeOptions = [
    {value: "TODAY", name: t("timePeriod.today")},
    {value: "THIS_WEEK", name: t("timePeriod.thisWeek")},
    {value: "THIS_MONTH", name: t("timePeriod.thisMonth")},
    {value: "THIS_YEAR", name: t("timePeriod.thisYear")},
  ]

  useEffect(() => {
    const container = containerRef.current
    if (!chart.current) {
      chart.current = echarts.init(container)
    }
    chart.current.showLoading()
    GenericService.action(BaseEP.DASHBOARD_URI, "work_hours", {time_period: timePeriod}).then((response) => {
      const labels: string[] = []
      const work_codes: string[] = []
      const series: Record<string, any>[] = []
      const startDate = dayjs(response.data.start_date)
      const endDate = dayjs(response.data.end_date)
      const durationDays = dayjs.duration(endDate.diff(startDate)).as("days") + 1
      const durationMonths = dayjs.duration(endDate.diff(startDate)).as("months") + 1
      switch (timePeriod) {
        case "TODAY":
          for (let i = 0; i < 24; i++) {
            labels.push(startDate.add(i, "hours").format("HH:mm"))
          }
          break
        case "THIS_WEEK":
          for (let i = 0; i < durationDays; i++) {
            labels.push(startDate.add(i, "day").format("YYYY-MM-DD"))
          }
          break
        case "THIS_MONTH":
          for (let i = 0; i < durationDays; i++) {
            labels.push(startDate.add(i, "day").format("YYYY-MM-DD"))
          }
          break
        case "THIS_YEAR":
          for (let i = 0; i < durationMonths; i++) {
            labels.push(startDate.add(i, "month").format("YYYY-MM"))
          }
          break
        default:
          break
      }
      response.data.data.map((item: any) => {
        if (!work_codes.includes(item.work_code)) {
          work_codes.push(item.work_code)
          series.push({
            name: item.work_code,
            type: 'bar',
            stack: 'workCode',
            emphasis: {
              focus: 'series'
            },
            data: labels.map(() => 0)
          })
        }
        const seriesIndex = work_codes.indexOf(item.work_code)
        const dataIndex = labels.indexOf(item.key)
        series[seriesIndex].data[dataIndex] = item.num
      })
      setData({labels, series})
    }).finally(() => {
      chart.current.hideLoading()
    })
  }, [timePeriod])

  useEffect(() => {
    const container = containerRef.current
    if (!chart.current) {
      chart.current = echarts.init(container)
    }
    const option = {
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow'
        }
      },
      legend: {},
      grid: {
        left: '3%',
        right: '4%',
        bottom: '3%',
        containLabel: true
      },
      xAxis: [
        {
          type: 'category',
          data: data.labels
        }
      ],
      yAxis: [
        {
          type: 'value'
        }
      ],
      series: data.series
    }
    chart.current.setOption(option)
  }, [data])


  return (
    <Card sx={{height: "100%", overflow: "auto"}}>
      <CardHeader
        title={<Box sx={{
          display: "flex"
        }}>
          <Typography variant={"h4"} flex={1}>{t("dashboard.workHours", {ns: "company"})}</Typography>
          <Box>
            <Select
              size={"small"}
              sx={{
                minWidth: "152px"
              }}
              value={timePeriod}
              onChange={(event) => setTimePeriod(event.target.value)}
            >
              {timeOptions.map((timeOption) =>
                <MenuItem key={timeOption.value} value={timeOption.value}>{timeOption.name}</MenuItem>
              )}
            </Select>
          </Box>
        </Box>}
      />
      <CardContent sx={{width: "calc(100% - 32px)", height: "calc(100% - 88px - 40px)"}}>
        <Box
          component={"div"}
          ref={containerRef}
          sx={{
            width: "100%",
            height: "100%"
          }}
        />
      </CardContent>
    </Card>
  )
}
export default WorkHoursLineChart

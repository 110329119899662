import {useRoutes} from "react-router-dom";
import LeaveApprovalListView from "@/pages/Personal/my-approval/leave/ListView";

const LeaveApprovalRouter = () => {
  const routes = useRoutes([
    {index: true, element: <LeaveApprovalListView />},
  ])
  return routes
}
export default LeaveApprovalRouter;

import Grid from "@mui/material/Grid2";
import {Box, List, ListItem, ListItemIcon, ListItemText, Typography} from "@mui/material";
import PhoneInTalkIcon from "@mui/icons-material/PhoneInTalk";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import GenericService from "@/services/core/GenericService";
import {VendorEndPoint} from "@/services/vendor/endpoints";
import {Email} from "@mui/icons-material";

const ContactUs = () => {
  const {t} = useTranslation("sub")
  const [vendor, setVendor] = useState<Record<string, any>>({})
  useEffect(() => {
    GenericService.action(VendorEndPoint.SUBSCRIPTION_URI, 'contact_us').then((response) => {
      setVendor(response.data)
    })
  }, [])

  return (
    <Grid sx={{width: "448px", minHeight: "256px"}}>
      <List>
        <ListItem>
          <ListItemIcon>
            <PhoneInTalkIcon color={"primary"} fontSize={"large"}/>
          </ListItemIcon>
          <ListItemText sx={{marginTop: "-30px"}}>
            <Typography sx={{fontSize: "20px", fontWeight: 400}}>
              {t('onlineSubscription.sales')}
            </Typography>
            <Box sx={{padding: "8px 0"}}>
            {
              vendor.sales_contact?.map((contact: string) => {
                return (
                  <Typography variant={"h4"}>{contact}</Typography>
                )
              })
            }
            </Box>
          </ListItemText>
        </ListItem>
        <ListItem sx={{mt: 4}}>
          <ListItemIcon>
            <PhoneInTalkIcon color={"primary"} fontSize={"large"}/>
          </ListItemIcon>
          <ListItemText sx={{marginTop: "-30px"}}>
            <Typography sx={{fontSize: "20px", fontWeight: 400}}>
              {t('onlineSubscription.technicalSupport')}
            </Typography>
            <Box sx={{padding: "8px 0"}}>
            {
              vendor.ts_contact?.map((contact: string) => {
                return (
                  <Typography variant={"h4"}>{contact}</Typography>
                )
              })
            }
            </Box>
          </ListItemText>
        </ListItem>
        <ListItem>
          <ListItemIcon>
            <Email color={"primary"} fontSize={"large"}/>
          </ListItemIcon>
          <ListItemText>
            <Typography variant={"h4"} sx={{p: 1}}>{vendor.email}</Typography>
          </ListItemText>
        </ListItem>
      </List>
    </Grid>
  )
}
export default ContactUs

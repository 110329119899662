import ListView from "@/components/views/ListView";
import {GridColDef} from "@mui/x-data-grid";
import AppUrls from "@/urls";
import { PsnlEP } from "@/services/psnl/endpoints";
import {useTranslation} from "react-i18next";
import urls from "@/urls";
import TransferAction from "./components/TransferAction";
import SyncAltIcon from '@mui/icons-material/SyncAlt';


const LocationListView = () => {
  const {t} = useTranslation("company")
  const {t: menu} = useTranslation("menu")
  const displayName = (value: any) => value?.display_name
  const columns: GridColDef[] = [
    {field: "location_code", headerName: t("location.code"), flex: 1},
    {field: "location_name", headerName: t("location.name"), flex: 1},
    {field: "parent_location", valueFormatter: displayName, headerName: t("location.superior"), flex: 1},
    {field: "employee_count", headerName: t("location.employeeCount"), flex: 1},
    {field: "resign_employee_count", headerName: t("location.resignCount"), flex: 1},
  ]

  const filters = [
    {key: 'location_code', label: t("location.code"), type: 'string'},
    {key: 'location_name', label: t("location.name"), type: 'string'},
  ]

  const actions = [
    {
      key: "transfer", title: t("location.personnelTransfer"), validateSelected: true, uniqueObjectRequired: true, icon: <SyncAltIcon />,
      display: "dialog", Form: TransferAction
    }
  ]

  return (
    <ListView
      title={menu("menu.company.location")}
      columns={columns}
      uri={PsnlEP.LOCATION_URI}
      url={AppUrls.company.location}
      filters={filters}
      modelActions={actions}
    />
  )
}
export default LocationListView

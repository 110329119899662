import {Navigate, Route, Routes} from "react-router-dom";
import GlobalRule from "@/pages/Attendance/GlobalRule";
import TimeTable from "@/pages/Attendance/TimeTable/router";
import Shift from "@/pages/Attendance/Shift/router";
import EmployeeSchedule from "@/pages/Attendance/EmployeeSchedule/router";
import DepartmentSchedule from "@/pages/Attendance/DepartmentSchedule/router";
import TemporaryScheduleRouter from "@/pages/Attendance/temporary_schedule/router";
import BreakTime from "@/pages/Attendance/BreakTime/router";
import DepartmentRule from "@/pages/Attendance/DepartmentRule/router";
import ManualLogRouter from "./ManualLog/router";
import OvertimeRouter from "@/pages/Attendance/Overtime/router";
import LeaveSettingsRouter from "./LeaveSettings/router";
import LeaveScheduleRouter from "./LeaveSchedule/router";
import TrainingRouter from "@/pages/Attendance/Training/router";
import LeaveRouter from "@/pages/Attendance/Leave/router";
import EmpScheduleReport from "../Report/ReportLibrary/components/EmpScheduleReport";
import LeaveDetailReport from "../Report/ReportLibrary/components/LeaveDetailReport";
import SwapScheduleRouter from "@/pages/Attendance/swap_schedule/router";
import EmployeeCalendar from "@/pages/Report/ReportLibrary/components/EmployeeCalendar";

const AttendanceRouter = () => {
  return (
    <Routes>
      <Route index element={<Navigate to={"global-rule"} replace />} />
      <Route path={"global-rule/*"} element={<GlobalRule />} />
      <Route path={"department-rule/*"} element={<DepartmentRule />} />
      <Route path={"break-time/*"} element={<BreakTime />} />
      <Route path={"timetable/*"} element={<TimeTable />} />
      <Route path={"shift/*"} element={<Shift />} />
      <Route path={"employee-schedule/*"} element={<EmployeeSchedule />} />
      <Route path={"department-schedule/*"} element={<DepartmentSchedule />} />
      <Route path={"temporary-schedule/*"} element={<TemporaryScheduleRouter />} />
      <Route path={"overtime/*"} element={<OvertimeRouter />} />
      <Route path={"manual-log/*"} element={<ManualLogRouter />} />
      <Route path={"leave-setting/*"} element={<LeaveSettingsRouter />} />
      <Route path={"leave-schedule/*"} element={<LeaveScheduleRouter />} />
      <Route path={"training/*"} element={<TrainingRouter />} />
      <Route path={"leave/*"} element={<LeaveRouter />} />
      <Route path={"swap-schedule/*"} element={<SwapScheduleRouter />} />
      <Route path={"schedule-view"} element={<EmpScheduleReport />} />
      <Route path={"leave-detail-report"} element={<LeaveDetailReport />} />
      <Route path={"employee-calendar"} element={<EmployeeCalendar />} />
    </Routes>
  );
};
export default AttendanceRouter;

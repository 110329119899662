import React from "react";
import { Box, CircularProgress, Grid, LinearProgress } from "@mui/material";
import { Outlet } from "react-router-dom";
import Header from "@/layout/Header";
import SideBar from "@/layout/SideBar";
import AppProvider from "@/provider/AppProvider";
import { useTranslation } from "react-i18next";
import DialogProvider from "@/provider/DialogProvider";
import ProgressProvider from "@/provider/ProgressProvider";

const MainLayout: React.FC = () => {
  // 必须在此调用useTranslation，测试发现bug，如果未在此调用可能有多次渲染bug
  const { t } = useTranslation()

  return (
    <React.Suspense
      fallback={
        <Box sx={{ width: '100%' }}>
          <LinearProgress />
        </Box>
      }>
      <AppProvider>
        <ProgressProvider>
          <DialogProvider>
            <Grid sx={{
              width: "100%",
              height: "100%",
              backgroundColor: "rgb(231, 235, 240)"
            }}>
              <Header />
              <Grid style={{
                display: 'flex',
                flexDirection: 'row',
                height: `calc(100% - 64px)`,
                overflowY: "hidden"
              }}>
                <SideBar />
                <Grid
                  sx={{
                    height: 'calc(100% - 16px)',
                    flex: 1,
                    m: 1,
                    overflowY: "auto"
                  }}
                >
                  <Outlet />
                </Grid>
              </Grid>
            </Grid>
          </DialogProvider>
        </ProgressProvider>
      </AppProvider>
    </React.Suspense>
  )
}
export default MainLayout

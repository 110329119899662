import {Box, Card, CardContent, CardHeader, Typography, useTheme} from "@mui/material";
import {EventNote} from "@mui/icons-material";
import React, {useEffect, useRef, useState} from "react";
import * as echarts from "echarts";
import GenericService from "@/services/core/GenericService";
import {BaseEP} from "@/services/base/endpoints";
import {useTranslation} from "react-i18next";


const AbsentPieChart = () => {
  const {t} = useTranslation("company")
  const containerRef = useRef<any>()
  const chart = useRef<any>()
  const theme = useTheme()
  const [data, setData] = useState({
    approved_leave: 0,
    unapproved_leave: 0,
    absent: 0,
  })

  useEffect(() => {
    const container = containerRef.current
    if (!chart.current) {
      chart.current = echarts.init(container)
    }
    chart.current.showLoading()
    GenericService.action(BaseEP.DASHBOARD_URI, "absent").then((response) => {
      setData(response.data)
    }).finally(() => {
      chart.current.hideLoading()
    })
  }, [])

  useEffect(() => {
    if(!data){
      return
    }
    const option = {
      tooltip: {
        trigger: 'item',
        formatter: "{b} : {c} ({d}%)"
      },
      legend: {
        orient: 'vertical',
        left: 'left',
        textStyle: {
          color: theme.palette.primary.main
        },
      },
      grid: {
        top: 0,
      },
      series: [
        {
          type: 'pie',
          avoidLabelOverlap: false,
          label: {
            formatter: '{d}%',
            position: 'inside',
            textStyle: {
              color: '#fff'
            }
          },
          itemStyle: {
            borderColor: '#fff',
            borderWidth: 7
          },
          labelLine: {
            show: false
          },
          data: [
            {value: data.approved_leave, name: t("dashboard.approvedLeave"), itemStyle: {color: 'rgb(103,184,255)'}},
            {value: data.unapproved_leave, name: t("dashboard.unapprovedLeave"), itemStyle: {color: 'rgb(252, 252, 48)'}},
            {value: data.absent, name: t("dashboard.absent"), itemStyle: {color: 'rgb(252,48,48)'}},
          ]
        }
      ]
    }
    chart.current.setOption(option)
  }, [data])
  return (
    <Card sx={{height: "100%", overflow: "auto"}}>
      <CardHeader
        title={<Typography variant={"h4"}>{t("dashboard.absentStaff")}</Typography>}
      />
      <CardContent sx={{width: "calc(100% - 32px)", height: "calc(100% - 88px - 40px)"}}>
        <Box
          component={"div"}
          ref={containerRef}
          sx={{
            width: "100%",
            height: "100%"
          }}
        />
      </CardContent>
    </Card>
  )
}
export default AbsentPieChart

import {useTranslation} from "react-i18next";
import {RJSFSchema, UiSchema} from "@rjsf/utils";
import {PsnlEP} from "@/services/psnl/endpoints";
import RecordView from "@/components/views/RecordView";
import urls from "@/urls";
import {AccessControlEndPoint} from "@/services/acc/endpoints";
import {useLocation} from "react-router-dom";


const CombinationRecordView = () => {
  const {t} = useTranslation("acc")
  const params = useLocation()
  const formSchema: RJSFSchema = {
    type: "object",
    properties: {
      area: {
        type: "integer",
        title: t("area"),
        readOnly: true
      },
      combination_no: {
        type: "integer",
        title: t("combination.code"),
        readOnly: true
      },
      combination_name: {
        type: "string",
        title: t("combination.name"),
        maxLength: 100
      },
      group1: {
        type: "integer",
        title: t("combination.group1"),
      },
      group2: {
        type: ["integer", "null"],
        title: t("combination.group2"),
      },
      group3: {
        type: ["integer", "null"],
        title: t("combination.group3"),
      },
      group4: {
        type: ["integer", "null"],
        title: t("combination.group4"),
      },
      group5: {
        type: ["integer", "null"],
        title: t("combination.group5"),
      },
    },
    required: ["area", "combination_no", "combination_name", "group1"]
  };
  const groupOptions = {
    uri: AccessControlEndPoint.GROUP_URI,
    area: parseInt(params.state?.record?.area)
  }
  const uiSchema: UiSchema = {
    'ui:defaultXsWidth': 4,
    area: {
      "ui:widget": "m2o",
      "ui:options": {
        uri: PsnlEP.ACCESS_AREA_URI
      }
    },
    group1: {
      "ui:widget": "m2o",
      "ui:options": groupOptions
    },
    group2: {
      "ui:widget": "m2o",
      "ui:options": groupOptions
    },
    group3: {
      "ui:widget": "m2o",
      "ui:options": groupOptions
    },
    group4: {
      "ui:widget": "m2o",
      "ui:options": groupOptions
    },
    group5: {
      "ui:widget": "m2o",
      "ui:options": groupOptions
    }
  };
  return (
    <RecordView
      breadcrumbs={[
        {title: t("menu.accessControl"), href: urls.acc.root},
        {title: t("menu.acc.combination"), href: urls.acc.combination},
      ]}
      formSchema={formSchema}
      uiSchema={uiSchema}
      uri={AccessControlEndPoint.COMBINATION_URI}
    />
  )
}
export default CombinationRecordView

import { Navigate, useRoutes } from "react-router-dom";
import DocumentRouter from "@/pages/complement/document/router";
import WorkCodeRouter from "@/pages/complement/work_code/router";
import HolidayRouter from "@/pages/complement/holiday/router";
import TrainingTypeRouter from "./TrainingType/router";
import IncidentTypeRouter from "./IncidentType/router";


const ComplementRouter = () => {
  return useRoutes([
    {index: true, element: <Navigate to={"holiday"} replace/>},
    {path: "holiday/*", element: <HolidayRouter />},
    {path: "document-type/*", element: <DocumentRouter />},
    {path: "work-code/*", element: <WorkCodeRouter />},
    {path: "incident-type/*", element: <IncidentTypeRouter />},
    { path: "training-type/*", element: <TrainingTypeRouter /> },
  ])
};
export default ComplementRouter;

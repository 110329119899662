import ListView from "@/components/views/ListView";
import {GridColDef} from "@mui/x-data-grid";
import AppUrls from "@/urls";
import urls from "@/urls";
import {TeamEP} from "@/services/team/endpoints";
import {useTranslation} from "react-i18next";


const FlowListView = () => {
  const {t} = useTranslation("per")
  const displayName = (value: any) => value?.display_name
  const columns: GridColDef[] = [
    {field: "workflow_code", headerName: t("workflow.code"), flex: 1},
    {field: "workflow_name", headerName: t("workflow.name"), flex: 1},
    {field: "start_date", headerName: t("workflow.startDate"), flex: 1},
    {field: "end_date", headerName: t("workflow.endDate"), flex: 1},
    {field: "content_type", valueFormatter: displayName, headerName: t("workflow.contentType"), flex: 1},
    {
      field: "employee", valueFormatter: (value: any) => {
        return value.map((each: any) => each.display_name)
      }, headerName: t("workflow.requester"), flex: 1
    },
    {field: "applicant_position", valueFormatter: displayName, headerName: t("workflow.position"), flex: 1},
    {field: "departments", valueFormatter: displayName, headerName: t("workflow.department"), flex: 1},
  ]

  const filters = [
    {key: 'workflow_code', label: t("workflow.code"), type: 'string'},
    {key: 'workflow_name', label: t("workflow.name"), type: 'string'},
  ]

  return (
    <ListView
      title={t("menu.workflow", {ns: "menu"})}
      breadcrumbs={[
        {title: t("menu.team"), href: urls.team.root},
      ]}
      columns={columns}
      uri={TeamEP.WORKFLOW_FLOW_URI}
      url={AppUrls.team.workflow}
      filters={filters}
    />
  )
}
export default FlowListView

import request from "@/utils/Request";
import {DeviceEndPoint} from "./endpoints";

const Approve = async (data: Record<string, any>) => {
  const response = await request.authorizedAxios().post(`${DeviceEndPoint.BIO_PHOTO_URI}approve/`, data)
  return response.data;
}

const BioPhotoService = {
  Approve
}
export default BioPhotoService

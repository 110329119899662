import {Route, Routes} from "react-router-dom";
import OperationLogListView from "./ListView";

const OperationLogRouter = () => {
  return (
    <Routes>
      <Route index element={<OperationLogListView />}/>
    </Routes>
  )
};
export default OperationLogRouter

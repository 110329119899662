export default {
  root: "/",
  companyVerification: "/onboard/company-verification",
  signIn: "/onboard/sign-in",
  signUp: "/onboard/sign-up",
  emailActivation: "/onboard/email-activation",
  forgotPassword: "/onboard/forgot-password",
  accountActivation: "/onboard/activation",
  invitedSignUp: "/onboard/invited-sign-up",
  companyRegister: "/onboard/company-register",
  companyCreation: "/onboard/company-creation",
  companySelection: "/onboard/company-selection",
  oauth: {
    signIn: "/oauth/sign-in"
  },
  dashboard: "/dashboard",
  company: {
    root: "/company",
    setting: "/company/settings",
    department: "/company/department",
    position: "/company/position",
    area: "/company/area",
    location: "/company/location",
    organizationChart: "/company/organization-chart",
  },
  complement: {
    root: "/complement",
    holiday: "/complement/holiday",
    documentType: "/complement/document-type",
    incidentType: "/complement/incident-type",
    trainingType: "/complement/training-type",
    workCode: "/complement/work-code",
  },
  personal: {
    root: "/personal",
    myCalendar: {
      holiday: "/personal/my-calendar/holiday",
      calendar: "/personal/my-calendar/calendar",
    },
    request: {
      manualLog: "/personal/request/manual-log",
      leave: "/personal/request/leave",
      overtime: "/personal/request/overtime",
      training: "/personal/request/training",
    },
    approval: {
      manualLog: "/personal/approval/manual-log",
      leave: "/personal/approval/leave",
      overtime: "/personal/approval/overtime",
      training: "/personal/approval/training",
      swapSchedule: "/personal/approval/swap-schedule",
    },
    outdoorManagement: {
      mySchedule: "/personal/outdoor-management/my-schedule",
      scheduleReport: "/personal/outdoor-management/schedule-report",
      unscheduleReport: "/personal/outdoor-management/unschedule-report",
    },
    report: {
      incidentDetail: "/personal/report/incident-detail",
      transaction: "/personal/report/transaction",
      scheduledLog: "/personal/report/scheduled-log",
      totalTimeCard: "/personal/report/total-time-card",
      staffSummary: "/personal/report/staff-summary",
      breakTime: "/personal/report/break-time",
      multipleTransaction: "/personal/report/multiple-transaction",
      timeCard: "/personal/report/time-card"
    }
  },
  team: {
    root: "/team",
    employee: "/team/employee",
    resign: "/team/resign",
    workflowRole: "/team/workflow-role",
    workflow: "/team/workflow",
    workflowNode: "/team/workflow-node",
    document: "/team/document",
  },
  device: {
    root: "/device",
    terminal: "/device/terminal",
    command: "/device/command",
    transaction: "/device/transaction",
    unregisteredTransaction: "/device/unregistered-transaction",
    employee: "/device/employee",
    publicMessage: "/device/public-message",
    privateMessage: "/device/private-message",
    workCode: "/device/work-code",
    bioTemplate: "/device/bio-template",
    bioPhoto: "/device/bio-photo",
    operationLog: "/device/operation-log",
    errorLog: "/device/error-log",
    uploadLog: "/device/upload-log",
    configuration: "/device/configuration"
  },
  gps: {
    root: "/accounts",
    accounts: "/gps/accounts",
    announcement: "/gps/announcement",
    notice: "/gps/notice",
    operationLog: "/gps/operation-log",
    gpsOfEmployee: "/gps/gps-of-employee",
    gpsOfDepartment: "/gps/gps-of-department",
    clientDetails: "/gps/client-details",
    schedulePlanner: "/gps/schedule-planner",
    outdoorSchedule: "/gps/outdoor-schedule",
    scheduleReport: "/gps/schedule-report",
    unscheduleReport: "/gps/unschedule-report"
  },
  att: {
    root: "/att",
    globalRule: "/att/global-rule",
    departmentRule: "/att/department-rule",
    breakTime: "/att/break-time",
    timetable: "/att/timetable",
    shift: "/att/shift",
    departmentSchedule: "/att/department-schedule",
    employeeSchedule: "/att/employee-schedule",
    temporarySchedule: "/att/temporary-schedule",
    scheduleView: "/att/schedule-view",
    employeeCalendar: "/att/employee-calendar",
    manualLog: "/att/manual-log",
    leave: "/att/leave",
    overtime: "/att/overtime",
    training: "/att/training",
    scheduleAdjustment: "/att/swap-schedule",
    leaveSetting: "/att/leave-setting",
    leaveSchedule: "/att/leave-schedule",
    leaveDetailReport: "/att/leave-detail-report",
    leaveType: "/att/leave-type",
    trainingType: "/att/training-type",
    holiday: "/att/holiday",
  },
  acc: {
    root: "/acc",
    terminal: "/acc/terminal",
    command: "/acc/command",
    timePeriod: "/acc/time-period",
    holiday: "/acc/holiday",
    group: "/acc/group",
    combination: "/acc/combination",
    privilege: "/acc/privilege",
  },
  report: {
    root: "/report",
    calculation: "/report/calculation",
    reportLibrary: "/report/report-library",
    transaction: "/report/report-library/transaction",
    totalTimecard: "/report/report-library/total-timecard",
    dailyAttendance: "/report/report-library/daily-attendance",
    dailyDetails: "/report/report-library/daily-details",
    dailySummary: "/report/report-library/daily-summary",
    dailyStatus: "/report/report-library/daily-status",
    late: "/report/report-library/late",
    absent: "/report/report-library/absent",
    earlyLeave: "/report/report-library/early-leave",
    firstLast: "/report/report-library/first-last",
    firstInLastOut: "/report/report-library/first-in-last-out",
    overtime: "/report/report-library/overtime",
    mobile_transaction: "/report/report-library/mobile-transaction",
    total_punches: "/report/report-library/total-punches",
    scheduleLog: "/report/report-library/scheduled-log",
    missedInOut: "/report/report-library/missed-in-out",
    employeeBirthday: "/report/report-library/employee-birthday",
    halfDay: "/report/report-library/half-day",
    breakTime: "/report/report-library/break-time",
    training: "/report/report-library/training",
    abnormal: "/report/report-library/abnormal",
    reportSetting: "/report/report-setting",
    multipleTransaction: "/report/report-library/multiple-transaction",
    punchStateSetting: "/report/punch-state-setting",
    reportItemsSetting: "/report/report-Items-setting",
    employeeSummary: "/report/report-library/employee-summary",
    monthlyBasicStatus: "/report/report-library/monthly-basic-status",
    monthlyStatusSummary: "/report/report-library/monthly_status_summary",
    monthlyDetailedSummary: "/report/report-library/monthly_detailed_summary",
    departmentSummary: "/report/report-library/department-summary",
    leaveDetail: "/report/report-library/leave-detail",
    leaveSummary: "/report/report-library/leave-summary",
    payrollSummary: "/report/report-library/payroll-summary",
    yearlySummary: "/report/report-library/yearly-summary",
    overtimeSummary: "/report/report-library/overtime-summary",
    monthlyDurationSummary: "/report/report-library/monthly-duration-summary",
    monthlyAttSheetSummary: "/report/report-library/monthly-attsheet-summary",
  },
  system: {
    root: "/system",
    group: "/system/group",
    user: "/system/user",
    autoExport: "/system/auto-export",
    userHistory: "/system/user/history",
    pdfSetting: "/system/pdf-setting",
    emailSetting: "/system/email-setting",
    alertSetting: "/system/alert-setting",
    whatsAppSetting: "/system/whatsapp-setting",
    operationLog: "/system/operation-Log",
  },
  vendor: {
    root: "/vendor",
    dashboard: "/vendor/dashboard",
    company: "/vendor/tenement/company",
    user: "/vendor/tenement/user",
    employee: "/vendor/tenement/employee",
    device: "/vendor/tenement/device",
    mobileApp: "/vendor/tenement/mobile-app",
    feature: "/vendor/subscription/feature",
    discount: "/vendor/subscription/discount",
    priceStrategy: "/vendor/subscription/price-strategy",
    order: "/vendor/subscription/order",
  },
  tenement: {
    root: "/tenement/company",
    company: "/tenement/company",
    user: "/tenement/user",
    employee: "/tenement/employee",
    device: "/tenement/device",
    mobileApp: "/tenement/mobile-app",
  },
  subscription: {
    root: "/subscription",
    feature: "/subscription/feature",
    discount: "/subscription/discount",
    priceStrategy: "/subscription/price-strategy",
    order: "/subscription/order",
  },
  activity: {
    root: "/ralvie"
  },
  ralvie: {
    root: "/ralvie",
    dashboard: "/ralvie/dashboard",
    productivitySummary: "/ralvie/productivity-summary",
    configuration: "/ralvie/configuration"
  },
  onlineSubscription: {
    root: "/onlineSubscription",
    billing: "/onlineSubscription/billing"
  },
  Login: "/onboard/login",
  // Dashboard: "/user/dashboard",
  Company: "/personnel/company",
  Department: "/personnel/department",
  Area: "/personnel/area",
  Position: "/personnel/position",
  Employee: "/personnel/person",
  Resign: "/personnel/resign",
  Terminal: "/iclock/terminal",
  Transaction: "/iclock/transaction",
  Command: "/iclock/terminalcommand"
}

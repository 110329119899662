import {ObjectFieldTemplateProps} from "@rjsf/utils";
import Grid from "@mui/material/Grid2";
import {useTranslation} from "react-i18next";


const OutdoorScheduleItemLayout = (props: ObjectFieldTemplateProps) => {
  const {t: common} = useTranslation("common")
  const fields: Record<string, any> = {}
  props.properties?.forEach((element: any) => {
    fields[element.name] = element.content
  })
  return (
    <Grid container spacing={2} sx={{p: 2}}>
      <Grid size={6}>
        {fields.client_id}
      </Grid>
      <Grid size={6}>
        {fields.dates}
      </Grid>
      <Grid size={6}>
        {fields.inclock}
      </Grid>
      <Grid size={6}>
        {fields.outclock}
      </Grid>
    </Grid>
  )
}
export default OutdoorScheduleItemLayout

import DashboardService from "@/services/ralvie/DashboardService";
import React, {useEffect, useMemo, useRef, useState} from "react";
import * as echarts from "echarts";
import {Box} from "@mui/material";
import ChartCard from "@/pages/Ralvie/Dashboard/components/ChartCard";
import {useTranslation} from "react-i18next";


const AppCategoryPie = (props: Record<string, any>) => {
  const {t} = useTranslation("ralvie")
  const containerRef = useRef<any>()
  const chart = useRef<any>()
  const [categories, setCategories] = useState<any[]>([])
  const getAppCategories = async () => {
    const response = await DashboardService.getAppCategories(props.filters)
    return response.data
  }

  const total = useMemo(() => {
    if(categories){
      const durations: number[] = [0]
      categories?.forEach((project: any) => {
        const minutes = Math.floor(project.totalDuration / 60)
        durations.push(minutes)
      })
      const totalDuration = durations.reduce((total, duration) => {
        return total + duration
      })
      const hour = Math.floor(totalDuration / 60)
      const minute = totalDuration % 60
      return {hour, minute}
    }else{
      return {hour: 0, minute: 0}
    }
  }, [categories])

  useEffect(() => {
    getAppCategories().then((data) => {
      setCategories(data?.categories)
    })
  }, [props.filters]);

  useEffect(() => {
    const container = containerRef.current
    if (!chart.current) {
      chart.current = echarts.init(container)
    }
    const option = {
      tooltip: {
        trigger: 'item',
        formatter: (params: any) => {
          console.log(params)
          const minutes = Math.floor(params.value / 60)
          const hour = Math.floor(minutes / 60)
          const minute = minutes % 60
          return `${params.name} <br/> ${hour}h${minute}m`
        }
      },
      legend: {
        x: 'left',
        orient: 'vertical',
      },
      grid: {
        top: 0,
      },
      series: [
        {
          type: 'pie',
          radius: ['40%', '70%'],
          avoidLabelOverlap: false,
          label: {
            show: false,
            position: 'center'
          },
          labelLine: {
            show: false
          },
          data: categories?.map((category) => ({
            value: category.totalDuration,
            name: category.categoryName,
            itemStyle: {
              color: category.color
            }
          }))
        }
      ]
    }
    chart.current.setOption(option)
  }, [categories])

  return (
    <ChartCard
      title={t("ralvie.appCategories")}
      total={total}
    >
      <Box
        component={"div"}
        ref={containerRef}
        sx={{
          width: "100%",
          height: "100%"
        }}
      />
    </ChartCard>
  )
}
export default AppCategoryPie

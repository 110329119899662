import {Box, Card, CardContent, CardHeader, Typography, useTheme} from "@mui/material";
import React, {useEffect, useRef, useState} from "react";
import * as echarts from "echarts";
import GenericService from "@/services/core/GenericService";
import {BaseEP} from "@/services/base/endpoints";
import {useTranslation} from "react-i18next";


const PresentPieChart = () => {
  const {t} = useTranslation("company")
  const containerRef = useRef<any>()
  const chart = useRef<any>()
  const theme = useTheme()
  const [data, setData] = useState<any>()

  useEffect(() => {
    const container = containerRef.current
    if (!chart.current) {
      chart.current = echarts.init(container)
    }
    chart.current.showLoading()
    GenericService.action(BaseEP.DASHBOARD_URI, "present").then((response) => {
      setData(response.data)
    }).finally(() => {
      chart.current.hideLoading()
    })
  }, [])

  useEffect(() => {
    if(!data){
      return
    }
    const option = {
      tooltip: {
        trigger: 'item',
        formatter: "{b} : {c} ({d}%)"
      },
      legend: {
        orient: 'vertical',
        left: 'left',
        textStyle: {
          color: theme.palette.primary.main
        },
      },
      grid: {
        top: 0,
      },
      series: [
        {
          type: 'pie',
          avoidLabelOverlap: false,
          label: {
            formatter: '{d}%',
            position: 'inside',
            textStyle: {
              color: '#fff'
            }
          },
          itemStyle: {
            borderColor: '#fff',
            borderWidth: 7
          },
          labelLine: {
            show: false
          },
          data: [
            {
              name: t("dashboard.onTime"),
              value: data?.on_time,
              itemStyle: {
                color: 'rgb(118,183,69)'
              }

            },
            {
              name: t("dashboard.lateArrival"),
              value: data?.late,
              itemStyle: {
                color: 'rgb(255,153,0)'
              }
            },
          ]
        }
      ]
    }
    chart.current.setOption(option)
  }, [data])

  return (
    <Card sx={{height: "100%", overflow: "auto"}}>
      <CardHeader
        title={<Typography variant={"h4"}>{t("dashboard.presentStaff")}</Typography>}
      />
      <CardContent sx={{width: "calc(100% - 32px)", height: "calc(100% - 88px - 40px)"}}>
        <Box
          component={"div"}
          ref={containerRef}
          sx={{
            width: "100%",
            height: "100%"
          }}
        />
      </CardContent>
    </Card>
  )
}
export default PresentPieChart

import { useTranslation } from "react-i18next";
import { GridColDef } from "@mui/x-data-grid";
import { ReportEndPoint } from "@/services/report/endpoints";
import urls from "@/urls";
import ReportView from "@/pages/Report/ReportLibrary/components/ReportView";
import { useEffect, useState } from "react";
import GenericService from "@/services/core/GenericService";
import { AttEndPoint } from "@/services/att/endpoints";
import dayjs from 'dayjs';
import { useReportEmployee } from "@/hooks/employee";


const DailyStatusReport = () => {
  const { t } = useTranslation("report")
  const dangerouslySetInnerHTML = (params: any) => {
    return <div dangerouslySetInnerHTML={{ __html: params.value }} />
  }
  const employeeFields = useReportEmployee()
  const fixedColumns: GridColDef[] = [
    ...employeeFields,
  ]

  const [leaveColumns, setLeaveColumns] = useState<GridColDef[]>([])
  const [dayColumns, setDayColumns] = useState<GridColDef[]>([])
  const [columns, setColumns] = useState<GridColDef[]>(fixedColumns)

  useEffect(() => {
    GenericService.list(AttEndPoint.LEAVE_TYPE_URI, {
      page: 1,
      page_size: 50,
    })
      .then((response) => {
        const leaves: any = []
        response.data.data.forEach((each: any) => {
          leaves.push({field: `leave_${each.id}`, headerName: `${each.display_name}`})
        })
        setLeaveColumns(leaves)
      })
  }, [])

  let startDate = new Date()
  let endDate = new Date()
  const onUpdateFilterModel = (value: any) => {
    if (value.startDate !== startDate || value.endDate !== endDate) {
      startDate = value.startDate
      endDate = value.endDate
      if (startDate && endDate) {
        const dayFileds: any = []
        const days = dayjs(endDate).diff(startDate, "day")
        for (let day = 0; day <= days; day++) {
          const curDate = dayjs(startDate).add(day, 'day')
          dayFileds.push({field: curDate.format('MM/DD'), headerName: `${day + 1}`, renderCell: dangerouslySetInnerHTML})
        }
        setDayColumns(dayFileds)
      }
    }
  }

  useEffect(() => {
    setColumns((old) => {
      return [
        ...fixedColumns,
        ...dayColumns,
        ...leaveColumns
      ]
    })
  }, [leaveColumns, dayColumns])

  return (
    <ReportView
      title={t("reportLibrary.dailyStatus")}
      breadcrumbs={[]}
      columns={columns}
      uri={ReportEndPoint.DAILY_STATUS_URI}
      url={urls.report.dailyStatus}
      getRowId={(row: any) => row.employee_code}
      onUpdateFilterModel={onUpdateFilterModel}
      hideColumns={['first_name', 'last_name', 'department_code', 'position_code', 'position_name']}
    />
  )
}
export default DailyStatusReport

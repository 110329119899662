import { useRoutes } from "react-router-dom";
import ListView from "@/pages/complement/TrainingType/ListView";
import RecordView from "@/pages/complement/TrainingType/RecordView";

const TrainingTypeRouter = () => {
  return useRoutes([
    { index: true, element: <ListView /> },
    { path: "add", element: <RecordView /> },
    { path: "edit", element: <RecordView /> },
  ]);
};
export default TrainingTypeRouter;

import LayoutForm from "@/components/rjsf/Form";
import React, {ForwardedRef, useImperativeHandle} from "react";
import {useTranslation} from "react-i18next";
import {CustomValidator, RJSFSchema, UiSchema} from "@rjsf/utils";
import UserService from "@/services/auth/UserService";
import {useAppContext} from "@/provider/AppProvider";
import RegExps from "@/utils/RegExps";
import {Box} from "@mui/material";


const CreateCompany = (props: Record<string, any>, ref: ForwardedRef<any>) => {
  const {t} = useTranslation("oauth")
  const {currentUser} = useAppContext()
  const formRef = React.useRef<any>()
  const formSchema: RJSFSchema = {
    type: "object",
    properties: {
      company_name: {
        type: "string",
        title: t("oauth.companyName"),
        maxLength: 50
      }
    },
    required: ["company_name"]
  }
  const uiSchema: UiSchema = {}
  const widgets = {}
  const customValidate: CustomValidator = (formData, errors, uiSchema) => {
    if (formData.company_name && !RegExps.code.test(formData.company_name)) {
      errors.company_name?.addError(t("oauth.validateError.invalidCompanyName"))
    }
    return errors
  }
  const onCreateCompany = async () => {
    const isValid = formRef.current?.validateForm()
    if (isValid) {
      const data = formRef.current?.state.formData
      const response = await UserService.createCompany(currentUser?.id, data)
      return response.isSuccess
    }
  }

  useImperativeHandle(ref, () => ({
    onConfirm: onCreateCompany
  }))

  return (
    <Box sx={{width: "352px"}}>
      <LayoutForm
        ref={formRef}
        schema={formSchema}
        uiSchema={uiSchema}
        widgets={widgets}
        customValidate={customValidate}
      />
    </Box>
  )
}
export default React.forwardRef(CreateCompany)

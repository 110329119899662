import ModelActionPage from "@/components/views/ListView/components/model-action/ModelActionPage";
import {useTranslation} from "react-i18next";
import {RJSFSchema, UiSchema} from "@rjsf/utils";
import {FormProps} from "@rjsf/core";
import DeviceRecordViewLayout from "@/pages/AccessControl/Device/components/RecordViewLayout";
import urls from "@/urls";
import {AccessControlEndPoint} from "@/services/acc/endpoints";


const BulkSetup = () => {
  const {t} = useTranslation("acc");
  const formSchema: RJSFSchema = {
    type: "object",
    properties: {
      door_lock_delay: {
        type: "integer",
        title: t("device.doorLockDelay"),
        default: 10
      },
      verify_mode_485: {
        type: "integer",
        title: t("device.verifyMode"),
        default: 6,
        oneOf: [
          {const: 1, title: t("verifyMode.fingerprintOnly")},
          {const: 4, title: t("verifyMode.badgeOnly")},
          {const: 6, title: t("verifyMode.fingerprintOrBadge")},
          {const: 9, title: t("verifyMode.fingerprintAndPassword")},
          {const: 10, title: t("verifyMode.fingerprintAndBadge")},
          {const: 11, title: t("verifyMode.passwordAndBadge")},
          {const: 12, title: t("verifyMode.fingerprintAndPasswordAndBadge")}
        ]
      },
      door_sensor_delay: {
        type: "integer",
        title: t("device.doorSensorDelay"),
        default: 10,
        minimum: 1,
        maximum: 10
      },
      door_sensor_type: {
        type: "integer",
        title: t("device.doorSensorType"),
        default: 2,
        oneOf: [
          {const: 0, title: t("doorSensorType.normalOpen")},
          {const: 1, title: t("doorSensorType.normalClose")},
          {const: 2, title: t("doorSensorType.none")},
        ]
      },
      retry_times: {
        type: "integer",
        title: t("device.retryTimes"),
        default: 3
      },
      door_alarm_delay: {
        type: "integer",
        title: t("device.doorAlarmDelay"),
        default: 30
      },
      no_time_period: {
        type: "integer",
        title: t("device.normalOpenPeriod"),
        default: 0
      },
      nc_time_period: {
        type: "integer",
        title: t("device.normalClosePeriod"),
        default: 0
      },
      valid_holiday: {
        type: "boolean",
        title: t("device.validHoliday"),
        default: false
      },
      speaker_alarm: {
        type: "boolean",
        title: t("device.speakerAlarm"),
        default: false
      },
      duress_alarm_delay: {
        type: "integer",
        title: t("device.duressAlarmDelay"),
        default: 10
      },
      alarm_1_1: {
        type: "boolean",
        title: t("device.alarmOnOne2One"),
        default: true
      },
      alarm_1_n: {
        type: "boolean",
        title: t("device.alarmOnOne2N"),
        default: true
      },
      alarm_password: {
        type: "boolean",
        title: t("device.alarmOnPassword"),
        default: true
      },
      duress_fun_on: {
        type: "boolean",
        title: t("device.enableDuressAlarm"),
        default: true
      },
      anti_passback_mode: {
        type: "integer",
        title: t("device.antiPassBackMode"),
        default: 0,
        oneOf: [
          {const: 0, title: t("antiPassBackMode.no")},
          {const: 1, title: t("antiPassBackMode.out")},
          {const: 2, title: t("antiPassBackMode.in")},
          {const: 3, title: t("antiPassBackMode.inOut")},
          {const: 4, title: t("antiPassBackMode.noButSaveDoorStatus")},
        ]
      },
      anti_door_direction: {
        type: "integer",
        title: t("device.antiDoorDirection"),
        default: 0,
        oneOf: [
          {const: -1, title: t("antiDoorDirection.none")},
          {const: 0, title: t("antiDoorDirection.out")},
          {const: 1, title: t("antiDoorDirection.in")},
        ]
      }
    },
    required: [
      "door_lock_delay", "verify_mode_485", "door_sensor_delay", "door_sensor_type",
      "retry_times", "door_alarm_delay", "no_time_period", "nc_time_period",
      "valid_holiday", "speaker_alarm", "duress_fun_on", "alarm_password",
      "duress_alarm_delay", "alarm_1_1", "alarm_1_n",
      "anti_passback_mode", "anti_door_direction"
    ]
  }
  const uiSchema: UiSchema = {
    door_lock_delay: {
      "ui:widget": "second"
    },
    door_sensor_delay: {
      "ui:widget": "second"
    },
    valid_holiday: {
      "ui:widget": "switch"
    },
    speaker_alarm: {
      "ui:widget": "switch"
    },
    alarm_password: {
      "ui:widget": "switch"
    },
    duress_fun_on: {
      "ui:widget": "switch"
    },
    duress_alarm_delay: {
      "ui:widget": "second"
    },
    alarm_1_1: {
      "ui:widget": "switch"
    },
    alarm_1_n: {
      "ui:widget": "switch"
    }
  }
  const templates: Partial<FormProps["templates"]> = {
    ObjectFieldTemplate: DeviceRecordViewLayout
  }
  return (
    <ModelActionPage
      breadcrumbs={[
        {title: t("menu.accessControl", {ns: "menu"}), href: urls.acc.root},
        {title: t("menu.device", {ns: "menu"}), href: urls.acc.terminal}
      ]}
      formSchema={formSchema}
      uiSchema={uiSchema}
      templates={templates}
      uri={AccessControlEndPoint.TERMINAL_URI}
      action={"bulk_setup"}
    />
  )
}
export default BulkSetup

import { useTranslation } from "react-i18next";
import { RJSFSchema, UiSchema } from "@rjsf/utils";
import React, { useEffect, useRef, useState } from "react";

import SnackbarUtil from "@/utils/SnackbarUtil";
import ConfigurationService from "@/services/report/ConfigurationService";
import ModelActionPage from "@/components/views/ListView/components/model-action/ModelActionPage";

const ReportSetting = () => {
  const { t: t } = useTranslation("report");
  const [formData, setFormData] = useState<Record<string, any>>();
  const formSchema: RJSFSchema = {
    type: "object",
    properties: {
      short_date: {
        type: "integer",
        title: t("reportSetting.shortDate"),
        oneOf: [
          { const: 1, title: "YYYY-MM-DD" },
          { const: 2, title: "YYYY/MM/DD" },
          { const: 3, title: "YYYY.MM.DD" },
          { const: 4, title: "DD/MM/YYYY" },
          { const: 5, title: "DD-MM-YYYY" },
          { const: 6, title: "MM/DD/YYYY" },
          { const: 7, title: "MM-DD-YYYY" },
          { const: 8, title: "YY-MM-DD" },
          { const: 9, title: "YY/MM/DD" },
          { const: 10, title: "DD/MM/YY" },
          { const: 11, title: "YY.MM.DD" },
          { const: 12, title: "YYYYMMDD" },
          { const: 13, title: "YYYYDDMM" },
          { const: 14, title: "MMDDYYYY" },
          { const: 15, title: "DDMMYYYY" },
          { const: 16, title: "YYMMDD" },
          { const: 17, title: "YYDDMM" },
          { const: 18, title: "MMDDYY" },
          { const: 19, title: "DDMMYY" },
        ],
        default: 1,
      },
      short_time: {
        type: "integer",
        title: t("reportSetting.shortTime"),
        oneOf: [
          { const: 1, title: "HH:MM" },
          { const: 2, title: "H:MM" },
          { const: 3, title: "HH:MM TT" },
          { const: 4, title: "H:MM TT" },
          { const: 5, title: "HH:MM:SS" },
          { const: 6, title: "H:MM:SS" },
          { const: 7, title: "HH:MM:SS TT" },
          { const: 8, title: "H:MM:SS TT" },
          { const: 9, title: "HHMM" },
          { const: 10, title: "HMM" },
          { const: 11, title: "HHMMSS" },
          { const: 12, title: "HMMSS" },
        ],
        default: 1,
      },
      resign_emp: {
        type: "integer",
        title: t("reportSetting.resignEmp"),
        oneOf: [
          { const: 0, title: t("reportSetting.resignEmp.no") },
          { const: 1, title: t("reportSetting.resignEmp.yes") },
        ],
        default: 1,
      },
      work_day_related_display: {
        type: "integer",
        title: t("reportSetting.workDayRelatedDisplay"),
        oneOf: [
          {
            const: 0,
            title: t(
              "reportSetting.workDayRelatedDisplay.calculateByTotalRecord"
            ),
          },
          {
            const: 1,
            title: t(
              "reportSetting.workDayRelatedDisplay.calculateByEveryRecord"
            ),
          },
          {
            const: 2,
            title: t("reportSetting.workDayRelatedDisplay.autoCalculate"),
          },
        ],
        default: 0,
      },
    },
    required: [
      "short_date",
      "short_time",
      "resign_emp",
      "work_day_related_display",
    ],
  };
  const uiSchema: UiSchema = {
    "ui:defaultXsWidth": 6,
    "ui:submitButtonOptions": {
      norender: true,
    },
  };

  const fetchDevConfig = async () => {
    const data = await ConfigurationService.getReportSettings();
    setFormData(data);
  };

  const onSaveDevConfig = async (data: Record<string, any>) => {
    const response = await ConfigurationService.updateReportSetting(data);
    if (response.isSuccess) {
      SnackbarUtil.success(
        t("common.message.saveSuccessfully", { ns: "common" })
      );
    } else {
      console.log(response);
    }
  };

  useEffect(() => {
    fetchDevConfig().then();
  }, []);

  return (
    <ModelActionPage
      title={t("menu.report.reportSetting", { ns: "menu" })}
      breadcrumbs={[]}
      formSchema={formSchema}
      uiSchema={uiSchema}
      formData={formData}
      disableDiscard={true}
      onSave={onSaveDevConfig}
    />
  );
};
export default ReportSetting;

const API_VER = 'v2'
export const OauthEP = {
  USER_LOGIN: `/api/jwt-api-token-auth/`,
  COMPANY_LIST: `/oauth/api/${API_VER}/company-list/`,
  COMPANY_SELECTION: `/oauth/api/${API_VER}/company-token/`,
  TOKEN_REFRESH: `/oauth/api/${API_VER}/token-refresh/`,
  CURRENT_USER: `/oauth/api/${API_VER}/current-user/`,
  CURRENT_COMPANY: `/oauth/api/${API_VER}/current-company/`,
  REGION_IDENTIFY: `/oauth/api/${API_VER}/region_identify/`,
  SIGN_UP: `/oauth/api/${API_VER}/sign_up/`,
  COMPANY_VERIFICATION: `/oauth/api/${API_VER}/company_verification/`,
  COMPANY_CREATION: `/oauth/api/${API_VER}/company_creation/`,
  ACCOUNT_ACTIVATION: `/oauth/api/${API_VER}/activation/`,
  ACCOUNT_INVITED: `/oauth/api/${API_VER}/invited/`,
  INVITED_SIGN_UP: `/oauth/api/${API_VER}/invited_sign_up/`,
  EMAIL_ACTIVATION: `/oauth/api/${API_VER}/email_activation/`,
  SIGN_IN: `/oauth/api/${API_VER}/sign_in/`,
  FORGOT_PASSWORD: `/oauth/api/${API_VER}/forgot_password/`,
  RESET_PASSWORD_CHECK: `/oauth/api/${API_VER}/reset_password_check/`,
  RESET_PASSWORD: `/oauth/api/${API_VER}/reset_password/`,
  JOIN_APPROVAL: `/oauth/api/${API_VER}/join_approval/`,
  OAUTH_URI: `/oauth/api/${API_VER}/oauth/`,
}

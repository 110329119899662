import {GridColDef} from "@mui/x-data-grid";
import {useTranslation} from "react-i18next";
import AppUrls from "@/urls";
import ListView from "@/components/views/ListView";
import {PersonalEndPoint} from "@/services/personal/endpoints";
import FilterPanel from "@/pages/Personal/request/components/FilterPanel";
import ApprovalView from "../components/ApprovalView";
import {Approval} from "@mui/icons-material";

const SwapScheduleApprovalListView = () => {
  const {t} = useTranslation("att");
  const {t: menu} = useTranslation("menu");
  const {t: per} = useTranslation("per");
  const {t: common} = useTranslation("common");
  const columns: GridColDef[] = [
    {field: "emp_code", headerName: per("employee.code"), width: 120},
    {field: "first_name", headerName: per("employee.firstName"), width: 160},
    {field: "last_name", headerName: per("employee.lastName"), width: 100},
    {field: "apply_time", headerName: common("approval.applyTime"), width: 160},
    {field: "previous_timeinterval", headerName: t("swapSchedule.previousTimetable"), width: 160},
    {field: "current_time_interval", headerName: t("swapSchedule.currentTimetable"), width: 160},
    {field: "last_approver", headerName: common("approval.lastApprover"), width: 140},
    {
      field: "audit_status",
      headerName: common("approval.status"),
      type: "singleSelect",
      valueOptions: [
        {value: 1, label: common("approval.status.pending")},
        {value: 2, label: common("approval.status.approved")},
        {value: 3, label: common("approval.status.rejected")},
        {value: 6, label: common("approval.status.revoked")},
        {value: 7, label: common("approval.status.withdraw")},
      ],
      width: 140
    },
    {
      field: "audit_reason",
      headerName: common("approval.remark"),
      flex: 1
    },
  ]

  const recordActions = [
    {
      id: "approval",
      title: common("approval.workflowView"),
      icon: <Approval/>,
      display: "dialog",
      Form: ApprovalView,
      readOnly: true,
      state: {
        uri: PersonalEndPoint.SWAP_SCHEDULE_URI,
        event: menu("menu.personal.swapSchedule"),
      },
    },
  ];

  return (
    <ListView
      title={menu("menu.personal.swapSchedule")}
      columns={columns}
      uri={PersonalEndPoint.SWAP_SCHEDULE_URI}
      resource={"my_approval/"}
      url={AppUrls.personal.approval.swapSchedule}
      disableAdd={true}
      disableHistory={true}
      disableEdit={true}
      disableDelete={true}
      disableExport
      FilterPanel={FilterPanel}
      approvalFilter={true}
      auditStatus={true}
      recordActions={recordActions}
    />
  )
}
export default SwapScheduleApprovalListView

import ListView from "@/components/views/ListView";
import {GridColDef} from "@mui/x-data-grid";
import AppUrls from "@/urls";
import urls from "@/urls";
import {useTranslation} from "react-i18next";
import {PersonalEndPoint} from "@/services/personal/endpoints";
import {VisibilityOutlined} from "@mui/icons-material";
import WorkflowView from "@/pages/Personal/request/components/WorkflowView";
import FilterPanel from "@/pages/Personal/request/components/FilterPanel";
import {useEffect, useState} from "react";
import GenericService from "@/services/core/GenericService";
import {AttEndPoint} from "@/services/att/endpoints";
import {ComplementEndpoint} from "@/services/complement/endpoints";

const TrainingListView = () => {
  const {t: t} = useTranslation("att");
  const {t: tMenu} = useTranslation("menu");
  const {t: common} = useTranslation("common");
  const columns: GridColDef[] = [
    {field: "training_type", headerName: t("training.trainingType"), flex: 1},
    {field: "start_time", headerName: t("training.startTime"), flex: 1},
    {field: "end_time", headerName: t("training.endTime"), flex: 1},
    {field: "apply_reason", headerName: common("approval.applyReason"), flex: 1},
    {
      field: "audit_reason",
      headerName: t("approval.remark"),
      flex: 1,
    },
    {
      field: "audit_status",
      headerName: common("approval.status"),
      flex: 1,
      type: "singleSelect",
      valueOptions: [
        {value: 1, label: common("approval.status.pending")},
        {value: 2, label: common("approval.status.approved")},
        {value: 3, label: common("approval.status.rejected")},
        {value: 6, label: common("approval.status.revoked")},
        {value: 7, label: common("approval.status.withdraw")},
      ],
    },
  ];

  const recordActions = [
    {
      id: "workflowView",
      title: common("approval.workflowView"),
      icon: <VisibilityOutlined/>,
      display: "dialog",
      Form: WorkflowView,
      readOnly: true,
      state: {
        uri: PersonalEndPoint.TRAINING_URI,
        event: tMenu("menu.att.training"),
      },
    },
  ];

  const [trainingCategories, setTrainingCategories] = useState<GridColDef[]>([])
  useEffect(() => {
    GenericService.list(ComplementEndpoint.TRAINING_TYPE_URI, {
      page: 1,
      page_size: 50,
    })
      .then((response) => {
        const leaves: any = []
        response.data.data.forEach((each: any) => {
          leaves.push({value: `${each.id}`, label: `${each.display_name}`})
        })
        setTrainingCategories(leaves)
      })
  }, [])

  return (
    <ListView
      title={tMenu("menu.att.training")}
      breadcrumbs={[
        {title: tMenu("menu.personal.request"), href: urls.personal.root},
      ]}
      columns={columns}
      recordActions={recordActions}
      uri={PersonalEndPoint.TRAINING_URI}
      resource={"my_request/"}
      url={AppUrls.personal.request.training}
      disableExport
      FilterPanel={FilterPanel}
      options={trainingCategories}
      categoryLabel={t("training.trainingType")}
      auditStatus={true}
    />
  );
};
export default TrainingListView;

import Grid from "@mui/material/Grid2";
import AppCategoryPie from "@/pages/Ralvie/Dashboard/components/AppCategoryPie";
import MostUsedApp from "@/pages/Ralvie/Dashboard/components/MostUsedApp";
import ProjectHour from "@/pages/Ralvie/Dashboard/components/ProjectHour";
import WeekTime from "@/pages/Ralvie/Dashboard/components/WeekTime";
import {Box, styled, Typography} from "@mui/material";
import DashboardService from "@/services/ralvie/DashboardService";
import React, {useEffect, useState} from "react";
import CustomDateCalendar from "@/pages/Ralvie/Dashboard/components/CustomDateCalendar";
import dayjs, {Dayjs} from "dayjs";
import {useTranslation} from "react-i18next";

const ChartContainer = styled(Grid)(() => ({
  backgroundColor: "#fff",
  borderRadius: "6px",
  height: "calc(50% - 8px)"
}))

const ActivityDashboard = () => {
  const {t} = useTranslation("ralvie")
  const [filters, setFilters] = useState<any>({
    start_date: dayjs().startOf("week").format("YYYY-MM-DD"),
    end_date: dayjs().endOf("week").format("YYYY-MM-DD")
  })
  const onDateRangeChange = async (startDate: Dayjs, endDate: Dayjs) => {
    setFilters({
      ...filters,
      start_date: startDate,
      end_date: endDate,
    })
  }
  const [weeklyHours, setWeeklyHours] = useState<any>({
      activeTimeSummary: {
        MONDAY: 0.0,
        TUESDAY: 0.0,
        WEDNESDAY: 0.0,
        THURSDAY: 0.0,
        FRIDAY: 0.0,
        SATURDAY: 0.0,
        SUNDAY: 0.0,
      },
      idleTimeSummary: {
        MONDAY: 0.0,
        TUESDAY: 0.0,
        WEDNESDAY: 0.0,
        THURSDAY: 0.0,
        FRIDAY: 0.0,
        SATURDAY: 0.0
      }
    }
  )
  const getWeeklyHours = async () => {
    const response = await DashboardService.getWeeklyHours(filters)
    return response.data
  }

  useEffect(() => {
    getWeeklyHours().then((data) => {
      setWeeklyHours({
        activeTimeSummary: data.activeTime_duration,
        idleTimeSummary: data.idleTime_duration
      })
    })
  }, [filters])

  return (
    <Grid container sx={{height: "100%", overflow: "hidden"}}>
      <Grid size={12} sx={{
        width: "100%",
        display: "flex",
        height: "32px",
        alignItems: "center",
        margin: "8px 0"
      }}>
        <Typography variant={"h3"}>{t("menu.ralvie.dashboard", {ns: "menu"})}</Typography>
        <Box flex={1}/>
        <CustomDateCalendar onDateRangeChange={onDateRangeChange}/>
      </Grid>
      <Grid size={12} container spacing={2} sx={{height: "calc(100% - 48px)"}}>
        <Grid size={8} container spacing={2} sx={{height: "100%"}}>
          <ChartContainer size={6}>
            <AppCategoryPie filters={filters}/>
          </ChartContainer>
          <ChartContainer size={6}>
            <ProjectHour filters={filters}/>
          </ChartContainer>
          <ChartContainer size={6}>
            <WeekTime title={t("ralvie.weekActiveTime")} data={weeklyHours?.activeTimeSummary}/>
          </ChartContainer>
          <ChartContainer size={6}>
            <WeekTime title={t("ralvie.weekIdleTime")} data={weeklyHours?.idleTimeSummary}/>
          </ChartContainer>
        </Grid>
        <ChartContainer size={4} sx={{height: "100%", overflow: "auto"}}>
          <MostUsedApp filters={filters}/>
        </ChartContainer>
      </Grid>
    </Grid>
  )
}
export default ActivityDashboard

import {useSnackbar, ProviderContext, OptionsObject} from "notistack";
import React from "react";
import {useTranslation} from "react-i18next";


let useSnackbarRef: ProviderContext
export const SnackbarRef: React.FC = () => {
  useSnackbarRef = useSnackbar()
  return null
}

const toast = (msg: React.ReactNode, options?: OptionsObject) => {
  useSnackbarRef.closeSnackbar()
  useSnackbarRef.enqueueSnackbar(msg, {
    'variant': 'success',
    'anchorOrigin': {vertical: 'top', horizontal: 'center'},
    ...options
  })
}

const success = (msg: string, options?: OptionsObject) => {
  toast(msg, {
    variant: "success",
    ...options
  })
}
const warning = (msg: string, options?: OptionsObject) => {
  toast(msg, {
    variant: "warning",
    ...options
  })
}

const info = (msg: string, options?: OptionsObject) => {
  toast(msg, {
    variant: "info",
    ...options
  })
}

const error = (msg: string, options?: OptionsObject) => {
  toast(msg, {
    variant: "error",
    ...options
  })
}

export default {
  success,
  warning,
  info,
  error
}

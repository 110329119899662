import {useRoutes} from "react-router-dom";
import OrderListView from "@/pages/vendor/subscription/Order/ListView";
import PrivateRoute from "@/router/PrivateRoute";
import OrderRecordView from "@/pages/vendor/subscription/Order/RecordView";


const OrderRouter = () => {
  const routes = useRoutes([
    {index: true, element: <PrivateRoute requireRoles={["agent", "vendor"]}><OrderListView /></PrivateRoute>},
    {path: "add", element: <PrivateRoute requireRoles={["agent", "vendor"]}><OrderRecordView /></PrivateRoute>},
    {path: "edit", element: <PrivateRoute requireRoles={["agent", "vendor"]}><OrderRecordView /></PrivateRoute>},
  ])
  return routes
}
export default OrderRouter

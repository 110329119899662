import {useTranslation} from "react-i18next";
import {GridColDef} from "@mui/x-data-grid";
import ListView from "@/components/views/ListView";
import AppUrls from "@/urls";
import {AccessControlEndPoint} from "@/services/acc/endpoints";
import SearchPanel from "@/pages/AccessControl/components/SearchPanel";
import {Clear, ClearAll, ClearAllOutlined, ClearOutlined} from "@mui/icons-material";
import DeviceService from "@/services/acc/DeviceService";
import SnackbarUtil from "@/utils/SnackbarUtil";
import CommandService from "@/services/acc/CommandService";


const CommandListView = () => {
  const {t} = useTranslation("acc")

  const columns: GridColDef[] = [
    {field: "sn", headerName: t("device.sn"), flex: 1},
    {field: "alias", headerName: t("device.alias"), flex: 1},
    {field: "content", headerName: t("command.content"), flex: 1},
    {field: "commit_time", headerName: t("command.commitTime"), flex: 1},
    {field: "transfer_time", headerName: t("command.transferTime"), flex: 1},
    {field: "return_time", headerName: t("command.returnTime"), flex: 1},
    {field: "return_value", headerName: t("command.returnValue"), flex: 1},
  ]

  const filters = [
    {key: 'holiday_name', label: t("holiday.name"), type: 'string'},
  ]

  const onClearCommand = async () => {
    const response = await CommandService.clear({});
    if (response.isSuccess) {
      SnackbarUtil.success("Success");
      return true;
    } else {
      console.log(response);
      return false;
    }
  }

  const modelActions = [
    {
      id: "clear",
      title: t("command.clear"),
      icon: <ClearAllOutlined />,
      display: "dialog",
      confirmation: t("command.clear.confirmation"),
      callback: onClearCommand
    }
  ]

  return (
    <ListView
      title={t("menu.device.command", {ns: "menu"})}
      breadcrumbs={[
        {title: t("menu.accessControl", {ns: "menu"}), href: AppUrls.acc.root},
      ]}
      columns={columns}
      uri={AccessControlEndPoint.COMMAND_URI}
      url={AppUrls.acc.command}
      filters={filters}
      disableAdd={true}
      disableEdit={true}
      modelActions={modelActions}
    />
  )
}
export default CommandListView

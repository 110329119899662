import {Typography} from "@mui/material";
import {ObjectFieldTemplateProps} from "@rjsf/utils";
import Grid from "@mui/material/Grid2";


const RecordLayoutTemplate = (props: ObjectFieldTemplateProps) => {
  const fields: Record<string, any> = {}
  props.properties?.forEach((element: any) => {fields[element.name] = element.content})
  return (
    <Grid container spacing={2}>
      <Grid size={4}>{fields.username}</Grid>
      <Grid size={4}>{fields.email}</Grid>
      <Grid size={12}>
        <Typography>{"Base Information"}</Typography>
      </Grid>
      <Grid size={4}>{fields.first_name}</Grid>
      <Grid size={4}>{fields.last_name}</Grid>
      <Grid size={12}>
        <Typography>{"Permissions"}</Typography>
      </Grid>
      <Grid size={4}>{fields.is_staff}</Grid>
      <Grid size={4}>{fields.is_superuser}</Grid>
      <Grid size={"grow"}/>
      <Grid size={4}>{fields.auth_departments}</Grid>
      <Grid size={4}>{fields.auth_areas}</Grid>
      <Grid size={"grow"}/>
      <Grid size={4}>{fields.auth_positions}</Grid>
    </Grid>
  )
}
export default RecordLayoutTemplate

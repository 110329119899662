import {RJSFSchema, UiSchema} from "@rjsf/utils";
import {useTranslation} from "react-i18next";
import validator from "@rjsf/validator-ajv8";
import LayoutForm from "@/components/rjsf/Form";
import React, {ForwardedRef, useImperativeHandle, useRef} from "react";
import SnackbarUtil from "@/utils/SnackbarUtil";
import DeviceService from "@/services/device/DeviceService";


const DaylightSavingTimeAction = (props: Record<string, any>, ref: ForwardedRef<any>) => {
  const {t} = useTranslation("device")
  const {t: common} = useTranslation("common")
  const formRef = useRef<any>()
  const ALL_MONTH = [
    {const: 1, title: common("month.january")},
    {const: 2, title: common("month.february")},
    {const: 3, title: common("month.march")},
    {const: 4, title: common("month.april")},
    {const: 5, title: common("month.may")},
    {const: 6, title: common("month.june")},
    {const: 7, title: common("month.july")},
    {const: 8, title: common("month.august")},
    {const: 9, title: common("month.september")},
    {const: 10, title: common("month.october")},
    {const: 11, title: common("month.november")},
    {const: 12, title: common("month.december")},
  ]
  const ALL_WEEKDAY = [
    {const: 0, title: common('weekday.sunday')},
    {const: 1, title: common('weekday.monday')},
    {const: 2, title: common('weekday.tuesday')},
    {const: 3, title: common('weekday.wednesday')},
    {const: 4, title: common('weekday.thursday')},
    {const: 5, title: common('weekday.friday')},
    {const: 6, title: common('weekday.saturday')},
  ]
  const WEEKS = [
    {const: 1, title: '1'},
    {const: 2, title: '2'},
    {const: 3, title: '3'},
    {const: 4, title: '4'},
    {const: 5, title: '5'},
  ]
  const formSchema: RJSFSchema = {
    "type": "object",
    "properties": {
      "func_on": {
        "type": "number",
        "title": t("device.daylightSavingTime"),
        "default": 0,
        "oneOf": [
          {const: 0, title: t("boolean.no", {ns: "common"}),},
          {const: 1, title: t("boolean.yes", {ns: "common"})},
        ],
      },
      "dlst_mode": {
        "type": "number",
        "title": t("device.daylightSavingTime.mode"),
        "default": 0,
        "oneOf": [
          {const: 0, title: t("device.dlstModel.datetime"),},
          {const: 1, title: t("device.dlstModel.weekday")},
        ],
      }
    },
    "allOf": [
      {
        "if": {
          "properties": {
            "dlst_mode": {
              "const": 0
            }
          }
        },
        "then": {
          "properties": {
            "start_date": {
              "type": "string",
              "format": "date",
              "title": t("device.daylightSavingTime.startDate"),
            },
            "start_time": {
              "type": "string",
              "title": t("device.daylightSavingTime.startTime"),
            },
            "end_date": {
              "type": "string",
              "format": "date",
              "title": t("device.daylightSavingTime.endDate"),
            },
            "end_time": {
              "type": "string",
              "title": t("device.daylightSavingTime.endTime"),
            },
          },
          "required": [
            "start_date",
            "start_time",
            "end_date",
            "end_time"
          ]
        }
      },
      {
        "if": {
          "properties": {
            "dlst_mode": {
              "const": 1
            }
          }
        },
        "then": {
          "properties": {
            "start_month": {
              "type": "number",
              "title": t("device.daylightSavingTime.startMonth"),
              "oneOf": ALL_MONTH,
            },
            "start_week": {
              "type": "number",
              "title": t("device.daylightSavingTime.startWeek"),
              "oneOf": WEEKS,
            },
            "start_weekday": {
              "type": "number",
              "title": t("device.daylightSavingTime.startWeekday"),
              "oneOf": ALL_WEEKDAY
            },
            "start": {
              "type": "string",
              "title": t("device.daylightSavingTime.start"),
            },
            "end_month": {
              "type": "number",
              "title": t("device.daylightSavingTime.endMonth"),
              "oneOf": ALL_MONTH,
            },
            "end_week": {
              "type": "number",
              "title": t("device.daylightSavingTime.endWeek"),
              "oneOf": WEEKS,
            },
            "end_weekday": {
              "type": "number",
              "title": t("device.daylightSavingTime.endWeekday"),
              "oneOf": ALL_WEEKDAY
            },
            "end": {
              "type": "string",
              "title": t("device.daylightSavingTime.end"),
            }
          },
          "required": [
            "start_month",
            "start_week",
            "start_weekday",
            "start",
            "end_month",
            "end_week",
            "end_weekday",
            "end"
          ]
        }
      },
      {
        "required": [
          "dlst_mode"
        ]
      }
    ]
  }

  const uiSchema: UiSchema = {
    'ui:defaultXsWidth': 6,
    "ui:defaultRowWrap": false,
    "start_time": {
      'ui:widget': 'time',
      "ui:wrap": true
    },
    "end_time": {
      'ui:widget': 'time',
      "ui:wrap": true
    },
    "start": {
      'ui:widget': 'time',
      "ui:wrap": true
    },
    "end": {
      'ui:widget': 'time',
      "ui:wrap": true
    },
    "func_on": {
      "ui:widget": "switch2"
    }
  };

  const onConfirm = async () => {
    const isValid = formRef.current?.validateForm()
    if (isValid) {
      const selectedIds = props.selectedIds
      const data = formRef.current?.state.formData
      const response = await DeviceService.daylightSavingTime({ids: selectedIds, ...data})
      if (response.isSuccess) {
        SnackbarUtil.success(common("common.successful"))
        return true
      } else {
        console.log(response)
      }
    }
  }

  useImperativeHandle(ref, () => ({
    onConfirm
  }))

  return (
    <LayoutForm
      ref={formRef}
      schema={formSchema}
      uiSchema={uiSchema}
      validator={validator}
    />
  )
}
export default React.forwardRef(DaylightSavingTimeAction)

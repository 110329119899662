import axios from "axios";
// import WebURL from "../urls";

// import responseCodeAlert from './codes'
import urls from "../urls";
import SnackbarUtil from "@/utils/SnackbarUtil";
import i18nt from "./i18nt";


const onAxiosResponseSuccess = (response: any) => {
  if(response.data?.code?.endsWith("0000")){
    response.data.isSuccess = true
  }
  return response
}
const onAxiosResponseFailed = (error: any) => {
  if(error.response.status === 400){
    SnackbarUtil.error(i18nt.api(error.response.data.code, error.response.data.context))
    return Promise.resolve(error.response);
  }else if(error.response.status === 401){
    if (error.config.url === "/web/oauth/api/v1/token/") {
      SnackbarUtil.error("Incorrect authentication credentials.")
    } else {
      SnackbarUtil.info("Session Timeout")
      const companyHost = localStorage.getItem('company_code') + '.'
      localStorage.removeItem("accessToken")
      if (window.location.host.startsWith(companyHost)) {
        const newHost = window.location.host.substring(companyHost.length, window.location.host.length)
        const newOrigin = window.location.origin.replace(window.location.host, newHost)
        window.location.href = `${newOrigin}${urls.companyVerification}`
      } else {
        window.location.href = urls.companyVerification;
      }
    }

    return Promise.reject(error);
  } else if (400 <= error.response.status) {
    console.log("request error:", error);
    // responseCodeAlert(error.response.data);
    return Promise.reject(error);
  }
}
const onAxiosRequest = (config: any) => {
  config.url = `/web${config.url}`;
  return config;
}

const axiosWrapper = (headers: Record<string, any>, onSuccess?: (error: any) => any, onFailed?: (error: any) => any) => {
  const req = axios.create({
    headers
  })
  req.interceptors.request.use(onAxiosRequest)
  req.interceptors.response.use(onSuccess, onFailed)
  return req;
}

const authorizedAxios = (onFailed = onAxiosResponseFailed, onSuccess = onAxiosResponseSuccess) => {
  return axiosWrapper({
    'Content-type': 'application/json',
    Authorization: `JWT ${localStorage.getItem("accessToken")}`,
    Timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    Accessor: "Web"
  },
  onSuccess,
  onFailed,
  )
}

const unauthorizedAxios = (onFailed = onAxiosResponseFailed, onSuccess = onAxiosResponseSuccess) => {
  return axiosWrapper({
    'Content-type': 'application/json',
    Timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    Accessor: "Web"
  },
  onSuccess,
  onFailed,
  )
}

const getWebSocketUrl = (url: string) => {
  const wsProtocol = (window.location.protocol === "http:"? "ws:": "wss:")
  return `${wsProtocol}//${window.location.host}/websocket${url}`
}

const getAuthorizedWebSocketUrl = (url: string) => {
  const accessToken = `JWT ${localStorage.getItem("accessToken")}`
  const wsProtocol = (window.location.protocol === "http:"? "ws:": "wss:")
  return `${wsProtocol}//${window.location.host}/websocket${url}?token=${accessToken}`
}

const request = {
  axiosWrapper,
  authorizedAxios,
  unauthorizedAxios,
  getWebSocketUrl,
  getAuthorizedWebSocketUrl,
}
export default request

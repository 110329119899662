import {Navigate, Route, Routes} from "react-router-dom";
import Onboard from "@/layout/Onboard";
import SignIn from "@/pages/onboard/SignIn";
import CompanyVerification from "@/pages/onboard/CompanyVerification";


const OnboardRouter = () => {
  return (
    <Routes>
      <Route path={"/"} element={<Onboard/>}>
        <Route index element={<Navigate to={"sign-in"} replace/>}/>
        <Route path={"sign-in"} element={<SignIn/>}/>
        <Route path={"company-verification"} element={<CompanyVerification/>}/>
      </Route>
    </Routes>
  )
}
export default OnboardRouter

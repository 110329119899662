import {IconButton} from "@mui/material";
import {FileDownloadOutlined, ViewColumnOutlined} from "@mui/icons-material";
import {Stack} from "@mui/system";
import ExportAction from "./ExportAction";
import ViewColumnAction from "./ViewColumnAction";
import { useReportViewContext } from "./ReportViewProvider";


const ViewPanel = () => {
  return (
    <Stack
      direction={"row"}
      spacing={1}
      sx={{
        alignItems: "center"
      }}
    >
      <ViewColumnAction />
      <ExportAction/>

    </Stack>
  )
}
export default ViewPanel

import {useAppContext} from "@/provider/AppProvider";
import {Box, LinearProgress} from "@mui/material";
import React from "react";
import {Navigate} from "react-router-dom";
import urls from "@/urls";


const MainView = () => {
  const {currentRole} = useAppContext()
  if (!currentRole) {
    return (
      <Box sx={{width: '100%'}}>
        <LinearProgress/>
      </Box>
    )
  }
  if (currentRole === "agent") {
    return <Navigate to={urls.vendor.root} replace/>
  }else if (currentRole === "vendor") {
    return <Navigate to={urls.vendor.root} replace/>
  } else if (currentRole === "tenement") {
    return <Navigate to={urls.dashboard} replace/>
  } else if (currentRole === "employee") {
    return <Navigate to={urls.personal.root} replace/>
  }
  return (
    <Box sx={{width: '100%'}}>
      <LinearProgress/>
    </Box>
  )
}
export default MainView

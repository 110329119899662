import ReactEcharts from 'echarts-for-react'
import React, {useEffect, useState} from "react";
import {Box, Card, CardContent, CardHeader} from "@mui/material";
import GenericService from "@/services/core/GenericService";
import {VendorEndPoint} from "@/services/vendor/endpoints";
import {useTranslation} from "react-i18next";

const AnalysisChart = () => {
  const {t} = useTranslation("vendor");
  const [data, setData] = useState({
    free_trial: 0,
    expired_license: 0,
    valid_license: 0
  });

  useEffect(() => {
    GenericService.action(VendorEndPoint.DASHBOARD, "subscription_summary").then((response) => {
      setData(response.data)
    })
  }, [])

  const option = {
    tooltip: {
      trigger: 'item'
    },
    legend: {
      left: 'center'
    },
    series: [
      {
        name: '',
        type: 'pie',
        radius: ['40%', '70%'],
        avoidLabelOverlap: false,
        label: {
          show: false,
          position: 'center'
        },
        emphasis: {
          label: {
            show: false,
            fontSize: 12,
            fontWeight: 'bold'
          }
        },
        labelLine: {
          show: false
        },
        data: [
          {value: data.free_trial, name: t("dashboard.freeTrial"), itemStyle: {color: '#4076e3'}},
          {value: data.expired_license, name: t("dashboard.expiredLicense"), itemStyle: {color: "#ee934e"}},
          {value: data.valid_license, name: t("dashboard.validLicense"), itemStyle: {color: "#7ac142"}},
        ]
      }
    ]
  }
  return (
    <Card>
      <CardHeader
        title={t("dashboard.subscriptionAnalysis")}
      />
      <CardContent>
        <ReactEcharts option={option}/>
      </CardContent>
    </Card>
  )
}
export default AnalysisChart

import {useTranslation} from "react-i18next";
import {GridColDef} from "@mui/x-data-grid";
import {ReportEndPoint} from "@/services/report/endpoints";
import urls from "@/urls";
import ReportView from "@/pages/Report/ReportLibrary/components/ReportView";
import { useReportEmployee } from "@/hooks/employee";


const DailyDetailsReport = () => {
  const {t} = useTranslation("report")
  const dangerouslySetInnerHTML = (params: any) => {
    return <div dangerouslySetInnerHTML={{ __html: params.value }} />
  }
  const employeeFields = useReportEmployee()
  const columns: GridColDef[] = [
    ...employeeFields,
    { field: "att_date", headerName: t("report.column.scheduleDate")},
    { field: "timetable_name", headerName: t("report.column.timetable")},
    { field: "duration", headerName: t("report.column.duration")},
    { field: "check_in", headerName: t("report.column.checkIn")},
    { field: "check_out", headerName: t("report.column.checkOut")},
    { field: "clock_in_old", headerName: t("report.column.clockIn")},
    { field: "clock_out_old", headerName: t("report.column.clockOut")},
    { field: "actual_wt", headerName: t("report.column.actualWorkTime")},
    { field: "total_ot", headerName: t("report.column.totalOt")},
    { field: "late", headerName: t("report.column.late")},
    { field: "early_leave", headerName: t("report.column.earlyLeave")},
    { field: "total_worked", headerName: t("report.column.totalWorkTime")},
    { field: "attendance_status", headerName: t("report.column.attendanceStatus"), renderCell: dangerouslySetInnerHTML},
    { field: "punch_set", headerName: t("report.column.punchSet")},
  ]
  return (
    <ReportView
      title={t("reportLibrary.dailyDetails")}
      breadcrumbs={[]}
      columns={columns}
      uri={ReportEndPoint.DAILY_DETAILS_URI}
      url={urls.report.dailyDetails}
      getRowId={(row: any) => row.uuid}
      hideColumns={['first_name', 'last_name', 'department_code', 'position_code', 'position_name']}
    />
  )
}
export default DailyDetailsReport

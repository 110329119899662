import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import GenericService from "@/services/core/GenericService";
import {PsnlEP} from "@/services/psnl/endpoints";
import {OauthEP} from "@/services/oauth/endpoints";

const initialState = {
  companies: [],
  company: {}
}

export const getUserCompanies = createAsyncThunk('company/byUser', async () => {
  const payload = await GenericService.list(`${OauthEP.COMPANY_LIST}`, {page: 1, page_size: 50});
  return payload.data;
})

const companySlice = createSlice({
  name: 'company',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getUserCompanies.fulfilled, (state, action) => {
      return {
        ...state,
        companies: action.payload.data
      }
    })
  }
});
export default companySlice.reducer

import ListView from "@/components/views/ListView";
import {GridColDef} from "@mui/x-data-grid";
import AppUrls from "@/urls";
import urls from "@/urls";
import {DeviceEndPoint} from "@/services/device/endpoints";
import {useTranslation} from "react-i18next";
import RecordPanel from "@/pages/Device/Transaction/components/RecordPanel";

const UnregisteredTransactionListView = () => {
  const {t} = useTranslation("device");
  const {t: per} = useTranslation("per");
  const {t: common} = useTranslation("common");
  const columns: GridColDef[] = [
    {field: "emp_code", headerName: per("employee.code"), flex: 1},
    {
      field: "name",
      headerName: per("employee.firstName"),
      flex: 1,
      renderCell: (params) => {
        const row = params.row
        return `${row.first_name} ${row.last_name || ""}`
      }
    },
    {
      field: "department",
      headerName: per("employee.department"),
      flex: 1,
    },
    {
      field: "punch_date",
      headerName: t("transaction.punchDate"),
      flex: 1,
    },
    {
      field: "punch_time",
      headerName: t("transaction.punchTime"),
      flex: 1,
    },
    {
      field: "punch_state",
      headerName: t("transaction.punchState"),
      flex: 1,
      type: "singleSelect",
      valueOptions: [
        {value: "0", label: common("punchState.checkIn")},
        {value: "1", label: common("punchState.checkOut")},
        {value: "2", label: common("punchState.breakOut")},
        {value: "3", label: common("punchState.breakIn")},
        {value: "4", label: common("punchState.overtimeIn")},
        {value: "5", label: common("punchState.overtimeOut")},
      ]
    },
    {field: "area_alias", headerName: t("transaction.area"), flex: 1},
    {field: "terminal_sn", headerName: t("device.sn"), flex: 1},
    {
      field: "terminal_alias",
      headerName: t("device.alias"),
      flex: 1,
    },
    {field: "upload_time", headerName: t("transaction.uploadTime"), flex: 1},
  ];

  const filters = [
    {
      key: "terminal_sn",
      label: t("device.sn"),
      type: "string",
    },
    {
      key: "terminal_alias",
      label: t("device.alias"),
      type: "string",
    },
  ];

  return (
    <ListView
      disableAdd
      disableEdit
      disableDelete
      title={t("menu.device.unregisteredTransaction", {ns: "menu"})}
      breadcrumbs={[{title: t("menu.device"), href: urls.device.root}]}
      columns={columns}
      uri={DeviceEndPoint.UNREGISTERED_TRANSACTION_URI}
      url={AppUrls.device.unregisteredTransaction}
      filters={filters}
      RecordPanel={RecordPanel}
    />
  );
};
export default UnregisteredTransactionListView;

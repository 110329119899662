import {Route, Routes} from "react-router-dom";
import NoticeListView from "./ListView";

const NoticeRouter = () => {
  return (
    <Routes>
      <Route index element={<NoticeListView />}/>
    </Routes>
  )
};
export default NoticeRouter

import { RegistryWidgetsType, RJSFSchema, UiSchema } from "@rjsf/utils";
import { useTranslation } from "react-i18next";
import urls from "@/urls";
import RecordView from "@/components/views/RecordView";
import { TeamEP } from "@/services/team/endpoints";
import { PsnlEP } from "@/services/psnl/endpoints";
import LayoutTemplate from "./components/RecordViewLayout";
import DocumentWidget from "./components/DocumentWidget";
import PhotoWidget from "@/pages/Team/Employee/components/PhotoWidget";
import { useLocation } from "react-router-dom";

const EmployeeRecordView = () => {
  const { t } = useTranslation("per");
  const { t: common } = useTranslation("common");
  const params = useLocation();
  const mode = params.state?.mode || "ADD";
  const formSchema: RJSFSchema = {
    type: "object",
    required: [
      "emp_code",
      "first_name",
      "email",
      "mobile",
      "department",
      "area",
      "position",
    ],
    properties: {
      photo: {
        type: ["string", "null"],
        title: t("employee.photo"),
      },
      emp_code: {
        maxLength: 20,
        type: "string",
        title: t("employee.code"),
        readOnly: mode === "EDIT",
      },
      first_name: {
        type: "string",
        maxLength: 100,
        title: t("employee.firstName"),
      },
      last_name: {
        maxLength: 25,
        type: ["string", "null"],
        title: t("employee.lastName"),
      },
      email: {
        type: "string",
        title: t("employee.email"),
      },
      mobile: {
        type: ["string", "null"],
        title: t("employee.mobile"),
      },
      hire_date: {
        type: ["string", "null"],
        format: "date",
        title: t("employee.joinedDate"),
      },
      department: {
        type: "number",
        title: t("employee.department"),
      },
      area: {
        type: "array",
        title: t("employee.area"),
        items: {
          type: "number",
        },
      },
      position: {
        type: "number",
        title: t("employee.position"),
      },
      location: {
        type: ["number", "null"],
        title: t("employee.location"),
      },
      enable_outdoor_management: {
        type: "number",
        title: t("employee.outDoor.Mng"),
        oneOf: [
          { const: 0, title: t("employee.disable") },
          { const: 1, title: t("employee.enable") },
        ],
      },
      emp_type: {
        type: ["number", "null"],
        title: t("employee.employmentType"),
        oneOf: [
          { const: null, title: "---------" },
          { const: 1, title: t("employmentType.official") },
          { const: 2, title: t("employmentType.temporary") },
        ],
      },
      is_active: {
        type: "boolean",
        title: t("employee.account.webLogin"),
        default: false,
      },
      app_status: {
        type: "boolean",
        title: t("employee.account.appLogin"),
        default: false,
      },
      birthday: {
        type: ["string", "null"],
        format: "date",
        title: t("employee.birthDate"),
      },
      nickname: {
        type: ["string", "null"],
        title: t("employee.nickname"),
        maxLength: 100,
      },
      ssn: {
        type: ["string", "null"],
        title: t("employee.SSN"),
        maxLength: 20,
      },
      driver_license_motorcycle: {
        type: ["string", "null"],
        title: t("employee.motorcycleLicense"),
        maxLength: 30,
      },
      contact_tel: {
        type: ["string", "null"],
        title: t("employee.contactTel"),
        maxLength: 20,
      },
      office_tel: {
        type: ["string", "null"],
        title: t("employee.officeTel"),
        maxLength: 20,
      },
      driver_license_automobile: {
        type: ["string", "null"],
        title: t("employee.automobileLicense"),
        maxLength: 30,
      },
      card_no: {
        type: ["string", "null"],
        title: t("employee.cardNumber"),
        maxLength: 20,
      },
      religion: {
        type: ["string", "null"],
        title: t("employee.religion"),
      },
      city: {
        type: ["string", "null"],
        title: t("employee.city"),
        maxLength: 20,
      },
      address: {
        type: ["string", "null"],
        title: t("employee.address"),
        maxLength: 200,
      },
      postcode: {
        type: ["string", "null"],
        title: t("employee.postcode"),
        maxLength: 10,
      },
      gender: {
        type: ["string", "null"],
        title: t("employee.gender"),
        oneOf: [
          { const: null, title: "------" },
          { const: "M", title: t("gender.male") },
          { const: "F", title: t("gender.female") },
        ],
      },
      passport: {
        type: ["string", "null"],
        title: t("employee.passport"),
        maxLength: 30,
      },
      national: {
        type: ["string", "null"],
        title: t("employee.nationality"),
      },

      documents: {
        type: "array",
        required: ["certification", "expire_on"],
        items: {
          properties: {
            certification: {
              type: "integer",
              title: t("employee.document"),
            },
            expire_on: {
              type: "string",
              title: t("employee.document.expireOn"),
            },
            email_alert: {
              type: "boolean",
              title: t("employee.document.emailAlert"),
            },
            before: {
              type: "integer",
              title: t("employee.document.alertBefore"),
            },
            file: {
              type: ["string", "null"],
              title: t("employee.document.attachment"),
            },
          },
        },
      },

      verify_mode: {
        type: ["number", "null"],
        title: t("employee.VerificationMode"),
        oneOf: [
          { const: null, title: "------" },
          { const: -1, title: common("verifyMode.groupMode") },
          { const: 0, title: common("verifyMode.any") },
          { const: 1, title: common("verifyMode.fingerprintOnly") },
          { const: 2, title: common("verifyMode.userId") },
          { const: 3, title: common("verifyMode.password") },
          { const: 4, title: common("verifyMode.badgeOnly") },
          { const: 5, title: common("verifyMode.fpOrPwd") },
          { const: 6, title: common("verifyMode.fingerprintOrBadge") },
          { const: 7, title: common("verifyMode.pwdOrBadge") },
          { const: 8, title: common("verifyMode.userIdAndFP") },
          { const: 9, title: common("verifyMode.fingerprintAndPassword") },
          { const: 10, title: common("verifyMode.fingerprintAndBadge") },
          {
            const: 11,
            title: common("verifyMode.fingerprintAndPasswordAndBadge"),
          },
          { const: 12, title: common("verifyMode.passwordAndBadge") },
          { const: 13, title: common("verifyMode.userIdAndFPOrPwd") },
          { const: 14, title: common("verifyMode.fpAndBadgeOrUserId") },
          { const: 15, title: common("verifyMode.face") },
          { const: 16, title: common("verifyMode.faceAndFP") },
          { const: 17, title: common("verifyMode.faceAndPwd") },
          { const: 18, title: common("verifyMode.faceAndCard") },
          { const: 19, title: common("verifyMode.faceAndFPAndCard") },
          { const: 20, title: common("verifyMode.faceAndFPAndPwd") },
          { const: 21, title: common("verifyMode.fv") },
          { const: 22, title: common("verifyMode.fvAndPwd") },
          { const: 23, title: common("verifyMode.fvAndCard") },
          { const: 24, title: common("verifyMode.fvAndPwdAndCard") },
          { const: 25, title: common("verifyMode.palm") },
          { const: 26, title: common("verifyMode.palmAndCard") },
          { const: 27, title: common("verifyMode.palmAndFace") },
          { const: 28, title: common("verifyMode.palmAndFP") },
          { const: 29, title: common("verifyMode.palmAndFPAndFace") },
        ],
      },
      dev_privilege: {
        type: ["number", "null"],
        title: t("employee.devicePrivilege"),
        oneOf: [
          { const: null, title: "------" },
          { const: 0, title: t("devicePrivilege.employee") },
          { const: 2, title: t("devicePrivilege.registrar") },
          { const: 6, title: t("devicePrivilege.systemAdministrator") },
          { const: 14, title: t("devicePrivilege.superAdministrator") },
        ],
      },
      device_password: {
        type: ["string", "null"],
        title: t("employee.devicePassword"),
      },
      biophoto: {
        type: ["string", "null"],
        title: t("employee.bioPhoto"),
      },
      enroll_sn: {
        type: ["string", "null"],
        title: t("employee.enrollDevice"),
        readOnly: true,
      },

      enable_att: {
        type: ["boolean", "null"],
        title: t("employee.enableAttendance"),
        oneOf: [
          { const: false, title: t("employee.disable") },
          { const: true, title: t("employee.enable") },
        ],
      },
      enable_holiday: {
        type: "boolean",
        title: t("employee.enableHoliday"),
        oneOf: [
          { const: false, title: t("employee.disable") },
          { const: true, title: t("employee.enable") },
        ],
      },
      flow_role: {
        type: "array",
        title: t("employee.workflowRole"),
        items: {
          type: "integer",
        },
      },
      app_role: {
        type: "number",
        title: t("employee.appRole"),
        oneOf: [
          { const: 1, title: t("appRole.employee") },
          { const: 2, title: t("appRole.administrator") },
        ],
      },
      app_punch_status: {
        type: "number",
        title: t("employee.mobilePunch"),
      },
      enable_whatsapp: {
        type: "boolean",
        title: t("employee.enableWhatsappAlert"),
      },
      whatsapp_exception: {
        type: "boolean",
        title: t("whatsapp.exceptionAlert"),
      },
      whatsapp_punch: {
        type: "boolean",
        title: t("whatsapp.punchAlert"),
      },
      supervisor_mobile: {
        type: ["string", "null"],
        title: t("employee.supervisorMobile"),
      },
    },
  };

  const uiSchema: UiSchema = {
    "ui:defaultXsWidth": 4,
    "ui:defaultRowWrap": false,
    photo: {
      "ui:widget": "photo",
      "ui:placeholder": t("employee.photoUpload"),
    },
    biophoto: {
      "ui:widget": "photo",
      "ui:placeholder": t("employee.bioPhotoUpload"),
    },
    department: {
      "ui:widget": "m2o",
      "ui:options": {
        uri: PsnlEP.DEPARTMENT_URI,
      },
    },
    area: {
      "ui:widget": "m2m",
      "ui:options": {
        uri: PsnlEP.AREA_URI,
      },
    },
    position: {
      "ui:widget": "m2o",
      "ui:options": {
        uri: PsnlEP.POSITION_URI,
      },
    },
    location: {
      "ui:widget": "m2o",
      "ui:options": {
        uri: PsnlEP.LOCATION_URI,
      },
    },
    is_active: {
      "ui:widget": "switch2",
    },
    app_status: {
      "ui:widget": "switch2",
    },
    enable_att: {
      "ui:widget": "select",
    },
    enable_holiday: {
      "ui:widget": "select",
    },
    flow_role: {
      "ui:widget": "m2m",
      "ui:options": {
        uri: TeamEP.WORKFLOW_ROLE_URI,
      },
    },
    app_punch_status: {
      "ui:widget": "switch",
    },
    enable_whatsapp: {
      "ui:widget": "switch",
    },
    whatsapp_exception: {
      "ui:widget": "switch",
    },
    whatsapp_punch: {
      "ui:widget": "switch",
    },
    documents: {
      "ui:widget": DocumentWidget,
    },
  };

  const templates = {
    ObjectFieldTemplate: LayoutTemplate,
  };

  const widgets: RegistryWidgetsType = {
    documents: DocumentWidget,
    photo: PhotoWidget,
  };

  return (
    <RecordView
      formSchema={formSchema}
      uiSchema={uiSchema}
      uri={TeamEP.EMPLOYEE_URI}
      widgets={widgets}
      templates={templates}
    />
  );
};
export default EmployeeRecordView;

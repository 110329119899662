import {useRoutes} from "react-router-dom";
import IncidentTypeListView from "./ListView";
import IncidentTypeRecordView from "./RecordView";


const IncidentTypeRouter = () => {
  return useRoutes([
    {index: true, element: <IncidentTypeListView />},
    {path: "add", element: <IncidentTypeRecordView />},
    {path: "edit", element: <IncidentTypeRecordView />}
  ])
}
export default IncidentTypeRouter

import {Box, IconButton, ListItemText, MenuItem} from "@mui/material";
import {useDialogContext} from "@/provider/DialogProvider";
import {useListViewContext} from "@/provider/ListViewProvider";
import {useNavigate} from "react-router-dom";


const ListViewRecordAction = (props: Record<string, any>) => {
  const {refreshData} = useListViewContext()
  const dialog = useDialogContext()
  const navigate = useNavigate()
  const recordId = props.record?.id
  const recordParams = {recordId, record: props.record}
  const onRowAction = async () => {
    switch (props.display) {
      case "page":
        navigate(props.url, {state: {...recordParams, ...props.state}})
        break
      case "dialog":
        dialog.showDialog({
          title: props.title,
          confirmation: props.confirmation,
          onConfirm: async () => await props.callback(recordId),
          afterConfirm: props.afterConfirm || refreshData,
          disableAction: props.readOnly,
          Form: props.Form,
          formProps: {
            ...recordParams,
            ...props.state
          }
        })
        break
      default:
        props.callback(recordId)
        break
    }
  }
  return (
    <Box>
      {
        props.menuItem
          ? <MenuItem
            sx={{minWidth: 128}}
            onClick={onRowAction}
          >
            <ListItemText>{props.title}</ListItemText>
          </MenuItem>
          : <IconButton
            onClick={onRowAction}
            title={props.title}
            sx={{
              width: "32px",
              height: "32px"
            }}
          >
            {props.icon}
          </IconButton>
      }
    </Box>
  )
}
export default ListViewRecordAction

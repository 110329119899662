import {useNavigate, useSearchParams} from "react-router-dom";
import {Box, CircularProgress} from "@mui/material";
import OauthService from "@/services/oauth/OauthService";
import {useEffect, useState} from "react";
import SnackbarUtil from "@/utils/SnackbarUtil";
import urls from "@/urls";
import {useTranslation} from "react-i18next";

const AccountActivation = () => {
  const {t} = useTranslation("oauth")
  const {t: api} = useTranslation("api")
  const navigate = useNavigate()
  const [search, setSearch] = useSearchParams()
  const verifyCode = search.get("code")
  const [loading, setLoading] = useState<boolean>(false)
  const onActivation = async () => {
    setLoading(true)
    OauthService.accountActivation({code: verifyCode}).then((response) => {
      if (response.isSuccess) {
        navigate(urls.companyCreation, {state: {verifyCode}})
      } else {
        SnackbarUtil.error(t(response.code))
        navigate(urls.companyVerification)
      }
    }).catch(() => {
      SnackbarUtil.error(api("ZBOA0001"))
    }).finally(() => {
      setLoading(false)
    })
  }
  useEffect(() => {
    if (!verifyCode) {
      SnackbarUtil.error(api("ZBOA0106"))
      navigate(urls.companyVerification)
    } else {
      onActivation().then()
    }
  }, [])
  return (
    <Box sx={{width: "100%", display: "flex", justifyContent: "center"}}>
      {loading && <CircularProgress/>}
    </Box>
  )
}
export default AccountActivation

import {RJSFSchema} from "@rjsf/utils";
import {useTranslation} from "react-i18next";
import RecordView from "@/components/views/RecordView";
import React from "react";
import {PersonalEndPoint} from "@/services/personal/endpoints";
import {ComplementEndpoint} from "@/services/complement/endpoints";


const AddManualLogRecordView = () => {
  const {t} = useTranslation("att");
  const {t: common} = useTranslation("common");
  const formSchema: RJSFSchema = {
    type: "object",
    properties: {
      punch_state: {
        type: "number",
        title: t("manualLog.punchState"),
        oneOf: [
          { const: 0, title: common("punchState.checkIn") },
          { const: 1, title: common("punchState.checkOut") },
          { const: 2, title: common("punchState.breakOut") },
          { const: 3, title: common("punchState.breakIn") },
          { const: 4, title: common("punchState.overtimeIn") },
          { const: 5, title: common("punchState.overtimeOut") },
        ],
      },
      punch_date: {
        type: "string",
        format: "date",
        title: t("manualLog.punchDate"),
      },
      punch_time: {
        type: "string",
        title: t("manualLog.punchTime"),
        default: ""
      },
      work_code: {
        type: "number",
        title: t("manualLog.workCode"),

      },
      apply_reason: {
        type: ["string", 'null'],
        title: common("approval.applyReason"),
        maxLength: 200
      },
    },
    required: ["punch_date", "punch_time", "punch_state"],
  };

  const uiSchema = {
    "ui:defaultXsWidth": 6,
    punch_time: {
      "ui:widget": "time"
    },
    apply_reason: {
      "ui:widget": "textarea",
    },
    work_code: {
      "ui:widget": "m2o",
      "ui:options": {
        uri: ComplementEndpoint.WORK_CODE_URI,
      },
    }
  }

  return (
    <RecordView
      formSchema={formSchema}
      uiSchema={uiSchema}
      uri={PersonalEndPoint.MANUAL_LOG_URI}
    />
  )
}
export default AddManualLogRecordView

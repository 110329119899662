import {useRoutes} from "react-router-dom";
import TemporaryScheduleListView from "@/pages/Attendance/temporary_schedule/ListView";
import AddTemporarySchedule from "@/pages/Attendance/temporary_schedule/components/AddTemporarySchedule";


const TemporaryScheduleRouter = () => {
  return useRoutes([
    {index: true, element: <TemporaryScheduleListView />},
    {path: "schedule-by-calendar", element: <AddTemporarySchedule />},
  ])
}
export default TemporaryScheduleRouter

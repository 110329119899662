import {useRoutes} from "react-router-dom";
import PrivateRoute from "@/router/PrivateRoute";
import EmployeeListView from "@/pages/vendor/tenement/Employee/ListView";


const EmployeeRouter = () => {
  const routes = useRoutes([
    {index: true, element: <PrivateRoute requireRoles={["agent", "vendor"]}><EmployeeListView /></PrivateRoute>},
  ])
  return routes
}
export default EmployeeRouter

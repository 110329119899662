import {InfoOutlined} from "@mui/icons-material";
import {createRoot} from "react-dom/client";
import ConfirmDialog, {ConfirmDialogProps} from "@/components/dialog/components/ConfirmDialog";
import {DialogProps} from "@mui/material";

export type ConfirmDialogFuncProps =Omit<ConfirmDialogProps, 'dialog' | 'onConfirm' | 'onCancel'> & {
  dialog?: DialogProps;
  onConfirm?: () => Promise<boolean>;
}

export const withConfirm = (props: ConfirmDialogFuncProps) => {
  return {
    icon: <InfoOutlined color={"warning"} sx={{p: "8px"}}/>,
    ...props
  }
}

export const withInfo = (props: ConfirmDialogFuncProps) => {
  return {
    icon: <InfoOutlined color={"primary"} sx={{p: "8px"}}/>,
    ...props
  }
}

export const withWarn = (props: ConfirmDialogFuncProps) => {
  return {
    icon: <InfoOutlined color={"warning"} sx={{p: "8px"}}/>,
    ...props
  }
}

const confirm = (config: ConfirmDialogFuncProps) => {
  const container = document.createDocumentFragment();
  const root = createRoot(container);
  const destroy = () => {
    root.unmount();
  }
  const defaultConfirm = async () => {
    destroy()
    return true
  }
  const currentConfig: ConfirmDialogProps = {
    ...config,
    dialog: {
      ...config.dialog || {},
      open: true
    },
    onConfirm: config.onConfirm || defaultConfirm,
    onCancel: destroy
  }
  const render = (props: ConfirmDialogProps) => {
    root.render(<ConfirmDialog {...props} />);
  }
  render(currentConfig);
  return {
    destroy
  }
}
export default confirm

import {GridColDef} from "@mui/x-data-grid";
import AppUrls from "@/urls";
import ListView from "@/components/views/ListView";
import {useTranslation} from "react-i18next";
import {GPSEndPoint} from "@/services/gps/endpoints";
import RecordPanel from "@/pages/GPS/schedule-planner/components/RecordPanel";


const SchedulePlannerListView = () => {
  const {t} = useTranslation("gps")
  const {t: menu} = useTranslation("menu")
  const columns: GridColDef[] = [
    { field: "schedule_name", headerName: t("schedulePlanner.name"), flex: 1 },
  ]
  return (
    <ListView
      title={menu("menu.gps.schedulePlanner")}
      columns={columns}
      uri={GPSEndPoint.SCHEDULE_PLANNER}
      url={AppUrls.gps.schedulePlanner}
      RecordPanel={RecordPanel}
    />
  )
}
export default SchedulePlannerListView

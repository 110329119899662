import dayjs from "dayjs";
import {
  Timeline as TimelineComponent,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator
} from "@mui/lab";
import {Stack} from "@mui/system";
import {Avatar, Card, CardContent, CardHeader, Chip, Typography} from "@mui/material";
import {AccessTime, CameraAlt, LocationOn, Storage, Today} from "@mui/icons-material";
import React, {useEffect, useState} from "react";
import GenericService from "@/services/core/GenericService";
import {BaseEP} from "@/services/base/endpoints";
import {useTranslation} from "react-i18next";


const RealTimeMonitoring = () => {
  const {t: common} = useTranslation("common")
  const [punches, setPunches] = useState<any[]>([])
  const fetchPunches = async () => {
    GenericService.action(BaseEP.DASHBOARD_URI, "real_time").then((response) => {
      setPunches(response.data)
    })
  }
  const formatPunchState = (punchState: string) => {
    switch (punchState) {
      case "0":
        return common("punchState.checkIn")
      case "1":
        return common("punchState.checkOut")
      case "2":
        return common("punchState.breakOut")
      case "3":
        return common("punchState.breakIn")
      case "4":
        return common("punchState.overtimeIn")
      case "5":
        return common("punchState.overtimeOut")
      case "255":
        return common("punchState.none")
      default:
        return punchState
    }
  }
  useEffect(() => {
    fetchPunches().then()
  }, [])
  return (
    <Card sx={{height: "100%", overflow: "auto"}}>
      <CardHeader
        title={"REAL TIME DATA"}
        avatar={<Storage color={"primary"}/>}
      />
      <CardContent>
        <TimelineComponent>
          {punches.map((punch: any) => {
            const punchTime = dayjs(punch.punch_time)
            return (
              <TimelineItem>
                <TimelineOppositeContent sx={{flex: "none"}}>
                  <Stack spacing={0.5}>
                    <Avatar
                      variant={"square"}
                      src={punch.capture_url && `/web/${punch.capture_url}`}
                      sx={{width: "64px", height: "64px", img: {objectFit: "contain"}}}
                    />
                  </Stack>
                </TimelineOppositeContent>
                <TimelineSeparator>
                  <TimelineDot/>
                  <TimelineConnector/>
                </TimelineSeparator>
                <TimelineContent>
                  <Stack spacing={0.5}>
                    <Stack direction={"row"} spacing={0.5} sx={{alignItems: "center"}}>
                      <AccessTime sx={{width: 16, height: 16, p: 0.5}}/>
                      <Typography sx={{fontSize: 14}}>{punchTime.format("HH:mm:ss")}</Typography>
                      <Chip color={"primary"} label={formatPunchState(punch.punch_state)} size={"small"}/>
                    </Stack>
                    <Stack direction={"row"} spacing={0.5} sx={{alignItems: "center"}}>
                      <Today sx={{width: 16, height: 16, p: 0.5}}/>
                      <Typography sx={{fontSize: 14}}>{punchTime.format("dddd, MMMM D, YYYY")}</Typography>
                    </Stack>
                    <Stack direction={"row"} spacing={0.5} sx={{alignItems: "center"}}>
                      <LocationOn sx={{width: 16, height: 16, p: 0.5}}/>
                      <Typography sx={{fontSize: 14}}>{punch.terminal_alias}</Typography>
                    </Stack>
                    <Stack direction={"row"} spacing={0.5} sx={{alignItems: "center"}}>
                      <Avatar sx={{width: 24, height: 24}}/>
                      <Typography sx={{fontSize: 14}}>{`${punch.emp?.first_name}`}</Typography>
                      <Chip label={punch.emp_code} size={"small"}/>
                    </Stack>
                  </Stack>
                </TimelineContent>
              </TimelineItem>
            )
          })}
        </TimelineComponent>
      </CardContent>
    </Card>
  )
}
export default RealTimeMonitoring

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Stack
} from "@mui/material";
import { useTranslation } from "react-i18next";
import Utils from "@/utils";
import { string } from "zod";
import { Close } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import React, { createContext, useContext, ForwardedRef, useCallback, useEffect, useRef, useState } from "react";
import request from "@/utils/Request";
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import { downloadFile } from "@/utils"
import GenericService from "@/services/core/GenericService";
import SnackbarUtil from "@/utils/SnackbarUtil";


type ProgressContextProps = Record<string, any>

const ProgressContext = createContext<ProgressContextProps>({
  open: () => ({ key: string, hide: Promise<void> }),
  close: () => null,
  set: () => null,
})

export const useProgressContext = () => {
  const context = useContext(ProgressContext)
  if (!context) {
    throw new Error('The dialog provider not found!')
  }
  return context
}

type ProgressProviderProps = Record<string, any>
const ProgressProvider = (props: ProgressProviderProps) => {
  const [dialogConfig, setDialogConfig] = useState<Record<string, any>>({ open: false })

  const hide = (key: string) => {
    setDialogConfig({
      open: false,
      id: key
    })
  }

  const show = (options: Record<string, any>) => {
    const key = Utils.uid(8)
    const onDiscard = () => {
      hide(key)
    }

    setDialogConfig({
      ...options,
      onDiscard,
      open: true,
      id: key
    })
    return {
      key
    }
  }

  const { t } = useTranslation()
  const [message, setMessage] = useState<string>("Waiting...")
  const [progress, setProgress] = useState<number>(0)

  const set = (key: string, message: string, progress: number) => {
    setMessage(message)
    setProgress(progress)
  }

  return (
    <ProgressContext.Provider
      value={{
        open: show,
        close: hide,
        set: set,
      }}
    >
      {props.children}
      {dialogConfig.open && <Dialog sx={{ minWidth: 500, minHeight: 300 }} open={dialogConfig.open || false}>
        <DialogContent>
          <Box sx={{ display: 'flex', alignItems: 'center', minWidth: 400 }}>
            <Box sx={{ width: '100%', mr: 1 }}>
              <LinearProgress variant="determinate" value={progress} />
            </Box>
          </Box>
          <Box sx={{ minWidth: 100 }}>
            <Typography variant="body2" color="text.secondary">
              {progress} % {message}
            </Typography>
          </Box>
        </DialogContent>
        <DialogActions>
          <Stack direction={"row"} spacing={1} onClick={dialogConfig.onDiscard}>
            {progress === 100 ?
              <Button >{t('common.button.close', {ns: "common"})}</Button>
              :
              <Button >{t('common.button.cancel',{ns: "common"})}</Button>
            }

          </Stack>
        </DialogActions>
      </Dialog>}
    </ProgressContext.Provider>
  )
}
export default ProgressProvider

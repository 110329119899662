import {ArrayFieldTemplateItemType} from "@rjsf/utils";
import Grid from "@mui/material/Grid2";
import {IconButton} from "@mui/material";
import {Delete} from "@mui/icons-material";

const  OutdoorScheduleItemTemplate = (props: ArrayFieldTemplateItemType) => {
  const {index, onDropIndexClick} = props
  return (
    <Grid sx={{
      display: "flex"
    }}>
      <Grid sx={{flex: 1}}>
        {props.children}
      </Grid>
      <IconButton onClick={onDropIndexClick(index)}><Delete /></IconButton>
    </Grid>
  )
}
export default OutdoorScheduleItemTemplate

import {Route, Routes} from "react-router-dom";
import UserListView from "@/pages/SystemSetting/User/ListView";
import UserRecordView from "@/pages/SystemSetting/User/RecordView";


const UserRouter = () => {
  return (
    <Routes>
      <Route index element={<UserListView />}/>
      <Route path={"edit/"} element={<UserRecordView />}/>
    </Routes>
  )
};
export default UserRouter

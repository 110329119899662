import {RJSFSchema, UiSchema} from "@rjsf/utils";
import {useTranslation} from "react-i18next";
import validator from "@rjsf/validator-ajv8";
import LayoutForm from "@/components/rjsf/Form";
import React, {
  ForwardedRef,
  useImperativeHandle,
  useRef,
} from "react";
import SnackbarUtil from "@/utils/SnackbarUtil";
import {PsnlEP} from "@/services/psnl/endpoints";
import {Box} from "@mui/material";
import DataCloneService from "@/services/acc/DataCloneService";
import {AccessControlEndPoint} from "@/services/acc/endpoints";
import {FormProps} from "@rjsf/core";
import PrivilegeRecordViewLayout from "@/pages/AccessControl/privilege/components/RecordViewLayout";
import { useVerifyModesForm } from "@/pages/AccessControl/options";

const AdjustAction = (props: Record<string, any>, ref: ForwardedRef<any>) => {
  const {t} = useTranslation('acc');
  const formRef = useRef<any>();
  const area = props.searchPanelParams?.area
  const verifyModes = useVerifyModesForm()
  const formSchema: RJSFSchema = {
    type: "object",
    required: [
      "employee",
      "group"
    ],
    properties: {
      area: {
        type: "integer",
        title: t("area"),
        // readOnly: mode === "EDIT",
        readOnly: true,
        default: parseInt(area)
      },
      "employee": {
        "title": t("common.employee", {ns: 'common'}),
        "type": "array",
        "minItems": 1,
        "items": {
          "type": "string",
        },
      },
      group: {
        type: "number",
        title: t("group"),
      },
      is_group_verifycode: {
        type: "number",
        title: t('privilege.verifyModeUseFor'),
        oneOf:[
          {const: 0, title: t("timePeriodMode.person")},
          {const: 1, title: t("timePeriodMode.group")},
        ],
        default: 1,
      },
      is_group_timezone: {
        type: "number",
        title: t('privilege.timePeriodUseFor'),
        oneOf:[
          {const: 0, title: t("timePeriodMode.person")},
          {const: 1, title: t("timePeriodMode.group")},
        ],
        default: 1,
      },
    },
    allOf: [
      {
        "if": {
          "properties": {
            "is_group_verifycode": {
              "const": 0
            }
          }
        },
        "then": {
          "properties": {
            "verify_mode": {
              "type": "number",
              "title": t("privilege.verifyMode"),
              "oneOf": verifyModes,
              "default": 0,
            },
          },
          required: ['verify_mode']
        },
      },
      {
        "if": {
          "properties": {
            "is_group_verifycode": {
              "const": 1
            }
          }
        },
        "then": {
            "properties": {
            "verify_mode": {
              "type": "number",
              "title": t("privilege.verifyMode"),
              "readOnly": true,
              "oneOf": verifyModes,
              "default": -1,
            },
          },
          required: []
        },
      },
      {
        "if": {
          "properties": {
            "is_group_timezone": {
              "const": 0
            }
          }
        },
        "then": {
          "properties": {
            "timezone1": {
              "type": "number",
              "title": t("privilege.timePeriod1"),
            },
            "timezone2": {
              "type": "number",
              "title": t("privilege.timePeriod2"),
            },
            "timezone3": {
              "type": "number",
              "title": t("privilege.timePeriod3"),
            },
          },
          required: ['timezone1']
        },
      },
      {
        "if": {
          "properties": {
            "is_group_timezone": {
              "const": 1
            }
          }
        },
        "then": {
          "properties": {
            "timezone1": {
              "type": "number",
              "title": t("privilege.timePeriod1"),
              "readOnly": true,
            },
            "timezone2": {
              "type": "number",
              "title": t("privilege.timePeriod2"),
              "readOnly": true,
            },
            "timezone3": {
              "type": "number",
              "title": t("privilege.timePeriod3"),
              "readOnly": true,
            },
          },
          required: []
        },
      },
    ]
  };
  const timezoneOptions = {
    uri: AccessControlEndPoint.TIME_PERIOD_URI,
    area: parseInt(area)
  }
  const uiSchema: UiSchema = {
    "ui:defaultXsWidth": 12,
    "ui:submitButtonOptions": {
      norender: true,
    },
    area: {
      "ui:widget": "m2o",
      "ui:options": {
        uri: PsnlEP.ACCESS_AREA_URI,
      }
    },
    "employee": {
      "ui:widget": "m2m",
      "ui:options": {
        uri: PsnlEP.EMPLOYEE_URI,
        area: parseInt(area)
      }
    },
    group: {
      "ui:widget": "m2o",
      "ui:options": {
        uri: AccessControlEndPoint.GROUP_URI,
        area: parseInt(area)
      }
    },
    timezone1: {
      "ui:widget": "m2o",
      "ui:options": timezoneOptions
    },
    timezone2: {
      "ui:widget": "m2o",
      "ui:options": timezoneOptions
    },
    timezone3: {
      "ui:widget": "m2o",
      "ui:options": timezoneOptions
    },
  };

  const onConfirm = async () => {
    const isValid = formRef.current?.validateForm();
    if (isValid) {
      const selectedIds = props.selectedIds;
      const data = formRef.current?.state.formData;
      const response = await DataCloneService.adjust({ids: selectedIds, ...data});
      if (response.isSuccess) {
        SnackbarUtil.success(t("common.successful", {ns:'common'}));
        return true;
      } else {
        console.log(response);
      }
    }
  };

  useImperativeHandle(ref, () => ({
    onConfirm,
  }));

  const templates: Partial<FormProps["templates"]> = {
    ObjectFieldTemplate: PrivilegeRecordViewLayout
  }

  return (
    <Box sx={{width: '800px'}}>
      <LayoutForm
        ref={formRef}
        schema={formSchema}
        uiSchema={uiSchema}
        templates={templates}
        validator={validator}
      />
    </Box>
  );
};
export default React.forwardRef(AdjustAction);

import {Stack} from "@mui/system";
import ListViewModelActionMenu from "@/components/views/ListView/components/model-action/ModelActionMenu";
import ListViewModelAction from "@/components/views/ListView/components/model-action/ModelAction";

type ModelActionRenderProps = Record<string, any>

const ListViewModelActionRender = (props: ModelActionRenderProps) => {
  return (
    <Stack direction={"row"} spacing={1}>
      {props.actions?.map((action: Record<string, any>) => {
        if(action.subs?.length > 0){
          return <ListViewModelActionMenu key={`actionMenu${action.id}`} {...action}/>
        }
        return <ListViewModelAction key={`action${action.id}`} {...action}/>
      })}
    </Stack>
  )
}
export default ListViewModelActionRender

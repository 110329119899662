import {
  FormContextType,
  ObjectFieldTemplateProps as LayoutObjectFieldTemplateProps,
  RJSFSchema,
  StrictRJSFSchema,
  canExpand,
  descriptionId,
  getTemplate,
  getUiOptions,
  titleId,
} from '@rjsf/utils';
import Grid from "@mui/material/Grid2";

/** The `LayoutObjectFieldTemplate` is the template to use to render all the inner properties of an object along with the
 * title and description if available. If the object is expandable, then an `AddButton` is also rendered after all
 * the properties.
 *
 * @param props - The `LayoutObjectFieldTemplateProps` for this component
 */
export default function LayoutObjectFieldTemplate<
  T = any,
  S extends StrictRJSFSchema = RJSFSchema,
  F extends FormContextType = any
>(props: LayoutObjectFieldTemplateProps<T, S, F>) {
  const {
    description,
    title,
    properties,
    required,
    disabled,
    readonly,
    uiSchema,
    idSchema,
    schema,
    formData,
    onAddClick,
    registry,
  } = props;
  const uiOptions = getUiOptions<T, S, F>(uiSchema);
  const TitleFieldTemplate = getTemplate<'TitleFieldTemplate', T, S, F>('TitleFieldTemplate', registry, uiOptions);
  const DescriptionFieldTemplate = getTemplate<'DescriptionFieldTemplate', T, S, F>(
    'DescriptionFieldTemplate',
    registry,
    uiOptions
  );
  // Button templates are not overridden in the uiSchema
  const {
    ButtonTemplates: { AddButton },
  } = registry.templates;
  const LayoutTemplate = props?.uiSchema?.['ui:layoutTemplate']
  return (
    <>
      {(uiOptions.title || title) && (
        <TitleFieldTemplate
          id={titleId<T>(idSchema)}
          title={title}
          required={required}
          schema={schema}
          uiSchema={uiSchema}
          registry={registry}
        />
      )}
      {(uiOptions.description || description) && (
        <DescriptionFieldTemplate
          id={descriptionId<T>(idSchema)}
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          description={uiOptions.description || description!}
          schema={schema}
          uiSchema={uiSchema}
          registry={registry}
        />
      )}
      <Grid container={true} spacing={2}>
        {
          LayoutTemplate
          ? <LayoutTemplate elements={properties} />
          : properties.map((element, index) => {
            // Remove the <Grid> if the inner element is hidden as the <Grid>
            // itself would otherwise still take up space.
            const defaultXsWidth = props?.uiSchema?.['ui:defaultXsWidth']
            const elemXsWidth = element.content.props.uiSchema?.['ui:xsWidth']

            const defaultRowWrap = props?.uiSchema?.['ui:defaultRowWrap']
            const rowWrap = element.content.props.uiSchema?.['ui:rowWrap']
            let wrap = true
            if(rowWrap !== undefined){
              wrap = rowWrap
            }else if(defaultRowWrap !== undefined){
              wrap = defaultRowWrap
            }
            // const wrap = element.content.props.uiSchema?.['ui:wrap']
            const xsWidth = elemXsWidth || defaultXsWidth || 12
            const children = [
              (<Grid
                key={`main${index}`}
                size={xsWidth}
              >
                {element.content}
              </Grid>)
            ]
            if(wrap){
              children.push((<Grid key={`pad${index}`} size={12-xsWidth} />))
            }
            return element.hidden
              ? (element.content)
              : children
          })
        }
        {canExpand<T, S, F>(schema, uiSchema, formData) && (
          <Grid container justifyContent='flex-end'>
            <Grid size={12}>
              <AddButton
                className='object-property-expand'
                onClick={onAddClick(schema)}
                disabled={disabled || readonly}
                uiSchema={uiSchema}
                registry={registry}
              />
            </Grid>
          </Grid>
        )}
      </Grid>
    </>
  );
}

import {useRoutes} from "react-router-dom";
import DocumentListView from "@/pages/complement/document/ListView";
import DocumentRecordView from "@/pages/complement/document/RecordView";


const DocumentRouter = () => {
  return useRoutes([
    {index: true, element: <DocumentListView />},
    {path: "add", element: <DocumentRecordView />},
    {path: "edit", element: <DocumentRecordView />}
  ])
}
export default DocumentRouter

import {Box, CircularProgress} from "@mui/material";
import {ForwardedRef, useEffect, useState} from "react";
import GenericService from "@/services/core/GenericService";
import {PsnlEP} from "@/services/psnl/endpoints";
import {RichTreeView} from "@mui/x-tree-view/RichTreeView";
import {useListViewContext} from "@/provider/ListViewProvider";
import React from "react";


const SearchPanel = (props: Record<string, any>, ref: ForwardedRef<any>) => {
  const {searchPanelParams, setSearchPanelParams, setSearchPanelReady} = useListViewContext()
  const [loading, setLoading] = useState<boolean>(false)
  const [treeData, setTreeData] = useState<any>({tree: []})
  const flatten2Tree = (flatData: any[], parentId = null): any[] => {
    return flatData
      .filter(item => item.parent_area === parentId)
      .map((item: any) => ({
        ...item,
        id: item.id.toString(),
        label: item.display_name,
        children: flatten2Tree(flatData, item.id)
      }))
  }
  const getDataList = async () => {
    setLoading(true)
    GenericService.list(PsnlEP.AREA_URI, {page: 1, page_size: 100, is_default: false}).then((response) => {
      if (response.isSuccess) {
        const areaTree = flatten2Tree(response.data.data)
        const defaultSelection = areaTree?.[0]?.id.toString()
        setTreeData({
          tree: areaTree,
          defaultSelection
        })
        setSearchPanelParams({...searchPanelParams, area: defaultSelection})
        setSearchPanelReady(true)
      }
    }).finally(() => {
      setLoading(false)
    })
  }

  const onItemToggle = (event: any, itemId: string, isSelected: boolean) => {
    if(isSelected){
      setSearchPanelParams({...searchPanelParams, area: itemId})
    }
  }

  useEffect(() => {
    getDataList().then()
  }, []);

  return (
    <Box
      sx={{
        minWidth: "256px",
        padding: "8px",
        backgroundColor: "#fff",
        overflow: "auto"
      }}
    >
      {loading
        ? <Box
          sx={{
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
        }}><CircularProgress/></Box>
        : <RichTreeView
          items={treeData.tree}
          onItemSelectionToggle={onItemToggle}
          defaultSelectedItems={treeData.defaultSelection}
        />
      }
    </Box>
  )
}
export default React.forwardRef(SearchPanel)

import Grid from "@mui/material/Grid2";
import {Box, Typography} from "@mui/material";
import CustomDateCalendar from "@/pages/Ralvie/ProductivitySummary/components/CustomDateCalendar";
import React, {useEffect, useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import dayjs, {Dayjs} from "dayjs";
import {DataGrid, GridColDef, GridRenderCellParams} from "@mui/x-data-grid";
import SummaryCard from "@/pages/Ralvie/ProductivitySummary/components/SummaryCard";
import ReportService from "@/services/ralvie/ReportService";
import {deepClone} from "@mui/x-data-grid/internals";


const GridCell = (props: Record<string, any>) => {
  return (
    <Box sx={{
      display: "flex",
      justifyContent: "center",
      justifyItems: "center",
      backgroundColor: props.color
    }}>
      {props.value}
    </Box>
  )
}


const ProductivitySummary = () => {
  const {t} = useTranslation("ralvie")
  const [loading, setLoading] = useState<boolean>(false)
  const [filters, setFilters] = useState<any>({
    start_date: dayjs().startOf("month"),
    end_date: dayjs().endOf("month")
  })
  const [gridData, setGridData] = useState<Record<string, any>>({
    rows: [],
    total: 0,
    totalHours: "00:00",
    averageDay: "00:00",
  })
  const onDateRangeChange = async (startDate: Dayjs, endDate: Dayjs) => {
    setFilters({
      ...filters,
      start_date: startDate,
      end_date: endDate,
    })
  }

  const formatDuration = (seconds: number) => {
    const minutes = Math.floor(seconds / 60)
    const hour = Math.floor(minutes / 60)
    const minute = minutes % 60
    return `${hour}:${minute}`
  }

  const renderTotal = (params: GridRenderCellParams) => {
    return formatDuration(params.value)
  }

  const renderDuration = (params: GridRenderCellParams) => {
    if (params.row.id === 0) {
      return formatDuration(params.value?.duration)
    } else if (params.value?.duration > 0) {
      return (
        <GridCell
          color={params.value?.type === "weekOff" ? "#E7E7E7" : "#B6E5CA"}
          value={formatDuration(params.value?.duration)}
        />
      )
    } else {
      return (
        <GridCell
          color={params.value?.type === "weekOff" ? "#E7E7E7" : "#FFCACA"}
          value={"-"}
        />
      )
    }
  }

  const columns: GridColDef[] = useMemo(() => {
    const startDate = filters.start_date
    const endDate = filters.end_date
    const fixedColumns: GridColDef[] = [
      {field: "name", headerName: t("ralvie.timesheet.name"), width: 120, disableColumnMenu: true},
      // {
      //   field: "time_off",
      //   headerName: t("ralvie.timesheet.timeOff"),
      //   headerAlign: "center",
      //   align: "center",
      //   width: 80,
      //   disableColumnMenu: true
      // },
      {
        field: "total",
        headerName: t("ralvie.timesheet.total"),
        headerAlign: "center",
        align: "center",
        width: 60,
        disableColumnMenu: true,
        renderCell: renderTotal
      },
    ]
    const days = endDate.diff(startDate, "days") + 1
    for (let day = 0; day < days; day++) {
      const today = startDate.add(day, "days")
      fixedColumns.push({
        field: today.format("YYYY-MM-DD"),
        headerName: today.format("MMM D"),
        headerAlign: "center",
        align: "center",
        width: 40,
        sortable: false,
        disableColumnMenu: true,
        renderCell: renderDuration,
      })
    }
    return fixedColumns
  }, [filters])

  const getTimesheet = async () => {
    const response = await ReportService.getTimesheet({
      start_date: filters.start_date.format("YYYY-MM-DD"),
      end_date: filters.end_date.format("YYYY-MM-DD"),
    })
    if (response.code?.endsWith("0000")) {
      return response.data
    }
    return []
  }

  const updateRows = (data: any) => {
    let totalHours = 0
    let durationDays = 0
    const users: string[] = []
    const newRows: any[] = []
    const startDate = filters.start_date
    const endDate = filters.end_date
    const days = endDate.diff(startDate, "days") + 1
    const daySet: Record<string, any> = {}
    for (let day = 0; day < days; day++) {
      const today = startDate.add(day, "days")
      const dayKey = today.format("YYYY-MM-DD")
      daySet[dayKey] = {
        day: today,
        type: "workDay",
        duration: 0
      }
      if (today.day() === 0 || today.day() === 6) {
        daySet[dayKey].type = "weekOff"
      }
    }
    const totalDaySet = deepClone(daySet)
    data?.map((item: any) => {
      if (users.includes(item.user.id)) {
        return
      }
      const userDurations: number[] = [0]
      const userDaySet = deepClone(daySet)
      item.durations?.map((duration: any) => {
        Object.keys(duration).map((key) => {
          userDurations.push(duration[key])
          userDaySet[key].duration = duration[key]
          totalDaySet[key].duration += duration[key]
          durationDays += 1
        })
      })
      console.log(daySet, userDaySet)
      const userTotal = userDurations.reduce((accumulator, v) => accumulator + v)
      const row = Object.assign({}, {
        id: item.user.id,
        name: item.user.name,
        time_off: 0,
        total: userTotal,
      }, deepClone(userDaySet))
      totalHours += userTotal
      users.push(item.user.id)
      newRows.push(row)
    })
    const averageDay = durationDays > 0 ? Math.floor(totalHours / durationDays) : 0
    setGridData({
      ...gridData,
      total: users.length,
      rows: [
        {id: 0, name: t("ralvie.timesheet.totalHours"), time_off: 0, total: totalHours, ...totalDaySet},
        ...newRows
      ],
      totalHours: formatDuration(totalHours),
      averageDay: formatDuration(averageDay)
    })
  }

  useEffect(() => {
    setLoading(true)
    getTimesheet().then((data) => {
      updateRows(data)
    }).finally(() => {
      setLoading(false)
    })
  }, [filters])

  return (
    <Grid container sx={{height: "100%", overflow: "hidden"}}>
      <Grid size={12} sx={{
        width: "100%",
        display: "flex",
        height: "32px",
        alignItems: "center",
        margin: "8px 0"
      }}>
        <Typography variant={"h3"}>{t("menu.ralvie.productivitySummary", {ns: "menu"})}</Typography>
        <Box flex={1}/>
        <CustomDateCalendar onDateRangeChange={onDateRangeChange}/>
      </Grid>
      <Grid size={12} container>
        <Grid size={4}>
          <SummaryCard title={t("ralvie.timesheet.usersWorked")} value={gridData.total}/>
        </Grid>
        <Grid size={4}>
          <SummaryCard title={t("ralvie.timesheet.totalHours")} value={gridData.totalHours}/>
        </Grid>
        <Grid size={4}>
          <SummaryCard title={t("ralvie.timesheet.averageDay")} value={gridData.averageDay}/>
        </Grid>
      </Grid>
      <Grid size={12} container sx={{height: "calc(100% - 48px - 64px)"}}>
        <DataGrid
          loading={loading}
          sx={{
            backgroundColor: "#fff",
            "& .MuiDataGrid-columnHeaderTitle": {
              textOverflow: "inherit",
              overflow: "auto",
              whiteSpace: "normal"
            },
            ".MuiDataGrid-cell:focus": {outline: "none"},
            ".MuiDataGrid-cell:focus-within": {outline: "none"},
            ".MuiDataGrid-columnHeader:focus": {outline: "none"},
            "& .MuiDataGrid-cell": {
              padding: "0 1px"
            }
          }}
          columns={columns}
          rows={gridData.rows}

          hideFooter={true}
        />
      </Grid>
    </Grid>
  )
}
export default ProductivitySummary

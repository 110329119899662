import {useTranslation} from "react-i18next";
import {GridColDef} from "@mui/x-data-grid";
import ListView from "@/components/views/ListView";
import {VendorEndPoint} from "@/services/vendor/endpoints";
import AppUrls from "@/urls";


const EmployeeListView = () => {
  const {t} = useTranslation("vendor")
  const {t: menu} = useTranslation("menu")

  const columns: GridColDef[] = [
    {field: "emp_code", headerName: t("employee.empCode"), flex: 1},
    {field: "first_name", headerName: t("employee.firstName"), flex: 1},
    {field: "area_name",  headerName: t("employee.area"), flex: 1},
    {field: "position_name", headerName: t("employee.positionName"), flex: 1},
    {field: "dept_name", headerName: t("employee.deptName"), flex: 1},
    {field: "mobile", headerName: t("employee.mobile"), flex: 1},
    {field: "email", headerName: t("employee.email"), flex: 1},
    {field: "company_name", headerName: t("employee.company"), flex: 1},

  ]

  const filters = [
    {key: "first_name", label: t("employee.firstName"), type: 'string'},
    {key: 'mobile', label: t("employee.mobile"), type: 'string'},
    {key: 'email', label: t("employee.email"), type: 'string'},
  ]

  return (
    <ListView
      title={menu("menu.tenement.employee")}
      breadcrumbs={[
        {title: menu("menu.tenement"), href: AppUrls.tenement.root},
      ]}
      columns={columns}
      uri={VendorEndPoint.EMPLOYEE_URI}
      url={AppUrls.vendor.employee}
      filters={filters}
      disableAdd={true}
      disableDelete={true}
      disableEdit={true}
    />
  )
}
export default EmployeeListView

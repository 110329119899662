import {FieldProps} from "@rjsf/utils";
import {useEffect, useRef, useState} from "react";
import {DataGrid, GridColDef, GridRenderCellParams} from "@mui/x-data-grid";
import {FormControl, Grid, InputLabel, MenuItem, Select, SelectChangeEvent, TextField} from "@mui/material";
import {Stack} from "@mui/system";
import PatternDayRender from "./PatternDayRender";
import InsertShiftTemplate from "./InsertShiftTemplate";
import {useTranslation} from "react-i18next";


const PatternDaysField = (props: FieldProps) => {

  const WEEK = 1
  const DAY = 0

  const {schema, idSchema, formData, onChange} = props
  const {t} = useTranslation("att")
  const {t: common} = useTranslation("common")
  const shiftTemplateRef = useRef<any>()
  const [gridData, setGridData] = useState<{
    columns: GridColDef[],
    rows: Record<string, any>[]
  }>({
    columns: [],
    rows: []
  })

  const onInsertShiftTemplate = async (params: any) => {
    const shiftTemplate = shiftTemplateRef.current?.selected
    if (shiftTemplate) {
      const pattern_days = [...formData.pattern_days]
      // const index = params.value?.day - 1
      const index = params.value?.day
      pattern_days[index].shift_tmpl = shiftTemplate
      const newFormData = {...formData, pattern_days, id: Date.now().toString()}
      onChange(newFormData, undefined, idSchema.$id)
    }
  }

  const onDeleteShiftTemplate = async (day: number, shiftId: number) => {
    const pattern_days = [...formData.pattern_days]
    const index = day - 1
    pattern_days[index].shift_tmpl = pattern_days[index].shift_tmpl.filter((each: any) => each.id !== shiftId)
    const newFormData = {...formData, pattern_days, id: Date.now().toString()}
    onChange(newFormData, undefined, idSchema.$id)
  }

  const dayRender = (params: GridRenderCellParams) => {
    return <PatternDayRender
      params={params}
      onInsertShiftTemplate={onInsertShiftTemplate}
      onDeleteShiftTemplate={onDeleteShiftTemplate}
    />
  }

  const generateDataGrid = (pattern: any) => {
    const headers: Record<string, string[]> = {}
    headers[DAY] = [t("cycleUnit.day")]
    headers[WEEK] = [
      common("weekday.Sun"), common("weekday.Mon"), common("weekday.Tue"),
      common("weekday.Wed"), common("weekday.Thu"), common("weekday.Fri"),
      common("weekday.Sat")
    ]
    const columns: GridColDef[] = []
    let periodDays = 1
    switch (pattern.time_period_type) {
      case DAY:
        break;
      case WEEK:
        periodDays = 7
        break;
      default:
        break;
    }
    headers[pattern.time_period_type].forEach((value, index) => {
      columns.push({
        field: `${pattern.time_period_type}_${index}`,
        headerName: value,
        flex: 1,
        renderCell: dayRender
      })
    })
    const pattern_days: any[] = pattern.pattern_days
    const rows: any[] = []
    pattern_days.forEach((value: any, index: number) => {
      if (index % periodDays === 0) {
        const rowPatternDays = pattern_days.slice(index, index + periodDays)
        const row: Record<string, any> = {}
        row.id = parseInt((index / periodDays).toString()) + 1
        columns.forEach((column, columnIndex) => {
          row[column.field] = rowPatternDays[columnIndex]
        })
        rows.push(row)
      }
    })
    columns.splice(0, 0, {
      field: "id",
      headerName: "",
      width: 36
    })
    setGridData({
      columns,
      rows
    })
  }

  const generatePatternDays = (time_period_type: number, time_period_cnt: number) => {
    if (!time_period_cnt) {
      return []
    }
    let pattern_days: any[] = []
    const pre_pattern_days: any[] = formData.pattern_days
    const period_days = (time_period_type === WEEK) ? 7 : 1
    const days = period_days * time_period_cnt
    const pre_days = pre_pattern_days.length
    const diff: number = days - pre_days
    if (diff > 0) {
      pattern_days = [...pre_pattern_days]
      Array(diff).fill(1).forEach((value, index, array) => {
        // const day = pre_days + index + value
        // from 0 start
        const day = pre_days + index
        pattern_days.push({day})
      })
    } else {
      pattern_days = pre_pattern_days.slice(0, days)
    }
    return pattern_days
  }

  const onPeriodTypeChange = async (event: SelectChangeEvent) => {
    const time_period_type = Number(event.target?.value)
    const pattern_days = generatePatternDays(time_period_type, formData.time_period_cnt)
    onChange(
      {...formData, time_period_type, pattern_days, id: Date.now().toString()},
      undefined,
      idSchema && idSchema.$id
    )
  }

  const onPeriodCountChange = async (value: any) => {
    const time_period_cnt = value
    const pattern_days = generatePatternDays(formData.time_period_type, time_period_cnt)
    onChange(
      {...formData, time_period_cnt, pattern_days, id: Date.now().toString()},
      undefined,
      idSchema && idSchema.$id
    )
  }
  useEffect(() => {
    if (formData.pattern_days.length) {
      generateDataGrid(formData)
    } else {
      if (formData.time_period_type && formData.time_period_cnt) {
        const pattern_days = generatePatternDays(formData.time_period_type, formData.time_period_cnt)
        onChange(
          {...formData, pattern_days, id: Date.now().toString()},
          undefined,
          idSchema && idSchema.$id
        )
      }
    }

  }, [formData])

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <InsertShiftTemplate ref={shiftTemplateRef}/>
      </Grid>
      <Grid item xs={3}>
        <Stack direction={"column"} spacing={2}>
          <FormControl
            fullWidth
          >
            <InputLabel required>{t("shift.cycleUnit")}</InputLabel>
            <Select
              fullWidth
              label={t("shift.cycleUnit")}
              value={formData.time_period_type}
              onChange={onPeriodTypeChange}
            >
              <MenuItem value={DAY}>
                {t("cycleUnit.day")}
              </MenuItem>
              <MenuItem value={WEEK}>
                {t("cycleUnit.week")}
              </MenuItem>
            </Select>
          </FormControl>
          <TextField
            required
            fullWidth
            type={"number"}
            label={t("shift.shiftCycle")}
            value={formData.time_period_cnt}
            onChange={(event) => {
              let time_period_cnt = parseInt(event.target?.value)
              console.log(time_period_cnt)
              if (isNaN(time_period_cnt) || time_period_cnt < 1) {
                time_period_cnt = 1
              }
              if (time_period_cnt > 52) {
                time_period_cnt = 52
              }
              onPeriodCountChange(time_period_cnt)
            }}
          />
        </Stack>
      </Grid>
      <Grid item xs={9}>
        <DataGrid
          autoHeight={true}
          columns={gridData.columns}
          rows={gridData.rows}
          hideFooter={true}
          onCellClick={async (params, event, details) => {
            await onInsertShiftTemplate(params)
          }}
        />
      </Grid>
    </Grid>
  )
}
export default PatternDaysField

import * as d3 from "d3-org-chart"
import {Box} from "@mui/material";
import React, {useEffect, useRef} from "react";
import GenericService from "@/services/core/GenericService";
import {PsnlEP} from "@/services/psnl/endpoints";
import {useAppContext} from "@/provider/AppProvider";

const OrganizationChart = () => {
  const containerRef = useRef<any>()
  const {currentCompany} = useAppContext()

  useEffect(() => {
    if(!currentCompany){
      return
    }
    GenericService.list(PsnlEP.DEPARTMENT_URI, {page: 1, page_size: 100}).then((response) => {
      let totalEmployees = 0
      const data: any[] = (response.data?.data || []).map((item: any) => {
        totalEmployees += item.employee_count
        return {
          ...item, parentId: item.parent_dept || currentCompany.id
        }
      })
      data.push({id: currentCompany.id, display_name: currentCompany.name, employee_count: totalEmployees})
      new d3.OrgChart()
        .nodeWidth((d) => 160)
        .nodeHeight((d) => 64)
        .compactMarginBetween((d) => 35)
        .compactMarginPair((d) => 30)
        .neighbourMargin((a, b) => 16)
        .nodeContent((node: any) => {
          return `
            <div style="border: 1px solid #E4E2E9;width:${node.width}px;height:${node.height}px;background-color: #FFFFFF;border-radius: 8px" >
              <div style="padding: 8px;font-size: 16px;color: #08011E">${node.data.display_name}</div>
              <div style="padding: 0 8px;display: flex;align-items: center;">
                 <svg style="width: 24px;height:24px;">
                   <path d="M16 11c1.66 0 2.99-1.34 2.99-3S17.66 5 16 5s-3 1.34-3 3 1.34 3 3 3m-8 0c1.66 0 2.99-1.34 2.99-3S9.66 5 8 5 5 6.34 5 8s1.34 3 3 3m0 2c-2.33 0-7 1.17-7 3.5V19h14v-2.5c0-2.33-4.67-3.5-7-3.5m8 0c-.29 0-.62.02-.97.05 1.16.84 1.97 1.97 1.97 3.45V19h6v-2.5c0-2.33-4.67-3.5-7-3.5"></path>
                 </svg>
                 <span style="padding: 0 8px;">${node.data.employee_count}<span>
              </span>
            </div>`
        })
        .container(containerRef.current)
        .data(data)
        .render();
    })
  }, [currentCompany])

  return (
    <Box
      component={"div"}
      ref={containerRef}
      sx={{
        width: "100%",
        height: "100%",
        overflow: "hidden"
      }}
    />
  )
}
export default OrganizationChart

import {configureStore} from "@reduxjs/toolkit";
import companyReducer from "./slices/Company";

const reducer = {
  company: companyReducer,
}
const store = configureStore({
  reducer,
  devTools: true
})
export default store

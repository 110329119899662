import {WidgetProps} from "@rjsf/utils";
import {useRef, useState} from "react";
import dayGridPlugin from "@fullcalendar/daygrid"
import interactionPlugin from '@fullcalendar/interaction'
import TimetableSelection from "@/pages/Attendance/temporary_schedule/components/TimetableSelection";
import Grid from "@mui/material/Grid2";
import FullCalendar from "@fullcalendar/react";
import {Box, Button, Chip, Tooltip, Typography, useTheme} from "@mui/material";
import {Stack} from "@mui/system";
import {ArrowLeft, ArrowRight} from "@mui/icons-material";
import dayjs from "dayjs";
import SnackbarUtil from "@/utils/SnackbarUtil";
import {useTranslation} from "react-i18next";


const TemporaryScheduleShiftsWidget = (props: WidgetProps) => {
  const {t} = useTranslation()
  const theme = useTheme()
  const {value, onChange} = props
  const timetableRef = useRef<any>()
  const fullCalendarRef = useRef<any>()
  const [day, setDay] = useState(dayjs())
  const onDateClick = async (params: any) => {
    const selectedOptions = timetableRef.current?.selectedOptions
    if (selectedOptions.length === 0) {
      SnackbarUtil.warning(t("temporarySchedule.warning.noTimetableSelected"))
      return
    }
    const events = selectedOptions.map((selectedOption: any) => (
      {
        id: `${params.dateStr}@${selectedOption.id}`,
        start: params.dateStr,
        title: selectedOption?.alias,
        day: params.dateStr,
        timetable: selectedOption.id
      }
    ))
    onChange([...value, ...events])
  }

  const onPreMonth = async () => {
    fullCalendarRef.current?.getApi().prev()
    setDay(day.add(-1, "month"))
  }

  const onNextMonth = async () => {
    fullCalendarRef.current?.getApi().next()
    setDay(day.add(1, "month"))
  }

  return (
    <Grid container spacing={2}>
      <Grid size={12} sx={{display: "flex", alignItems: "center", justifyContent: "center"}}>
        <TimetableSelection ref={timetableRef}/>
        <Box flex={1} sx={{display: "flex", justifyContent: "center"}}>
          <Typography>
            {day.format("MMMM YYYY")}
          </Typography>
        </Box>
        <Stack direction={"row"} spacing={1}>
          <Button variant={"contained"} onClick={onPreMonth}>
            <ArrowLeft/>
          </Button>
          <Button variant={"contained"} onClick={onNextMonth}>
            <ArrowRight/>
          </Button>
        </Stack>
      </Grid>
      <Grid size={12}>
        <FullCalendar
          ref={fullCalendarRef}
          plugins={[dayGridPlugin, interactionPlugin]}
          initialView={"dayGridMonth"}
          headerToolbar={false}
          height={"auto"}
          selectable={true}
          dateClick={onDateClick}
          eventColor={theme.palette.primary.main}
          eventContent={(params) => {
            const event = params.event
            return (
              <Tooltip title={event.title}>
                <Chip
                  label={event.title}
                  sx={{
                    color: "#fff",
                    width: "100%",
                  }}
                  onDelete={() => {
                    onChange(value.filter((item: any) => item.id !== event.id))
                  }}/>
              </Tooltip>
            )
          }}
          events={value}
        />
      </Grid>
    </Grid>
  )
}
export default TemporaryScheduleShiftsWidget

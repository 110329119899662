import {ObjectFieldTemplateProps} from "@rjsf/utils";
import {useTranslation} from "react-i18next";
import Grid from "@mui/material/Grid2";
import {Typography} from "@mui/material";


const CompanySettingLayout = (props: ObjectFieldTemplateProps) => {
  const {t} = useTranslation("company")
  const fields: Record<string, any> = {}
  props.properties?.forEach((element: any) => {
    fields[element.name] = element.content
  })

  return (
    <Grid
      sx={{
        display: "flex"
      }}
    >
      <Grid>
        <Grid>{fields.logo_img}</Grid>
        <Grid>{t("company.logo.hint1")}</Grid>
        <Grid>{t("company.logo.hint2")}</Grid>
      </Grid>

      <Grid sx={{flex: 1, padding: "0 16px"}} container spacing={2}>
        <Grid size={12}>
          <Typography variant={"h4"}>{t("company.information")}</Typography>
        </Grid>
        <Grid size={4}>{fields.company_name}</Grid>
        <Grid size={4}>{fields.company_phone}</Grid>
        <Grid size={4}/>
        <Grid size={12} sx={{marginTop: "24px"}}>
          <Typography variant={"h4"}>{t("company.showInReport")}</Typography>
        </Grid>
        <Grid size={4}>{fields.logo_display}</Grid>
        <Grid size={4}>{fields.company_name_display}</Grid>
        <Grid size={4}/>
        <Grid size={12} sx={{marginTop: "24px"}}>
          <Typography variant={"h4"}>{t("company.address")}</Typography>
        </Grid>
        <Grid size={8}>
          {fields.company_address}
        </Grid>
        <Grid size={4}/>
        <Grid size={4}>{fields.company_country}</Grid>
        <Grid size={4}>{fields.company_timezone}</Grid>
        <Grid size={4}/>
        <Grid size={4}>{fields.company_province}</Grid>
        <Grid size={4}>{fields.company_city}</Grid>
        <Grid size={4}/>
        <Grid size={4}>{fields.company_latitude}</Grid>
        <Grid size={4}>{fields.company_longitude}</Grid>
        <Grid size={4}/>
        <Grid size={12} sx={{marginTop: "24px"}}>
          <Typography variant={"h4"}>{t("company.emailSignature")}</Typography>
        </Grid>
        <Grid size={4}>{fields.email_signature}</Grid>
        <Grid size={8}/>
        <Grid size={4}>{fields.email_signature1}</Grid>
        <Grid size={4}>{fields.email_signature2}</Grid>
      </Grid>
    </Grid>
  )
}
export default CompanySettingLayout

import ListView from "@/components/views/ListView";
import {GridColDef} from "@mui/x-data-grid";
import AppUrls from "@/urls";
import {useTranslation} from "react-i18next";
import {PsnlEP} from "@/services/psnl/endpoints";
import CalculateIcon from '@mui/icons-material/Calculate';
import CalculateAction from "./components/CalculateAction";


const CalculationListView = () => {
  const {t} = useTranslation("report")
  const {t: per} = useTranslation("per")
  const displayName = (value: any) => value.display_name
  const columns: GridColDef[] = [
    {field: "emp_code", headerName: per("employee.code"), flex: 1},
    {field: "first_name", headerName: per("employee.firstName"), flex: 1},
    {field: "department", valueFormatter: displayName, headerName: per("employee.department"), flex: 1},
    {field: "position", valueFormatter: displayName, headerName: per("employee.position"), flex: 1},
    {
      field: "area", valueFormatter: (value: any) => {
        return value.map((each: any) => each.display_name)
      }, headerName: per("employee.area"), flex: 1
    },
  ]

  const filters = [
    {key: 'emp_code', label: per("employee.code"), type: 'string'},
    {key: 'first_name', label: per("employee.firstName"), type: 'string'},
  ]

  const actions = [
    {
      key: "calc",
      title: t("report.calc"),
      validateSelected: true,
      icon: <CalculateIcon/>,
      display: "dialog",
      Form: CalculateAction
    }
  ]

  return (
    <ListView
      title={t("menu.team.employee", {ns: "menu"})}
      columns={columns}
      uri={PsnlEP.CALCULATE_EMPLOYEE_URI}
      url={AppUrls.team.employee}
      filters={filters}
      modelActions={actions}
      disableAdd={true}
      disableEdit={true}
      disableDelete={true}
      disableExport={true}
    />
  )
}
export default CalculationListView

import { RJSFSchema, UiSchema } from "@rjsf/utils";
import ImportView from "@/components/views/ImportView";
import { useTranslation } from "react-i18next";
import AppUrls from "@/urls";
import { PsnlEP } from "@/services/psnl/endpoints";
import urls from "@/urls";
import { boolean } from "zod";


const DepartmentImportView = () => {
  const { t } = useTranslation("menu");

  const formSchema: RJSFSchema = {
    "type": "object",
    "required": [
      "import_file",
      "overwrite",
    ],
    "properties": {
      "import_file": {
        "type": "string",
        "title": t("common.button.upload", {ns: "common"}),
      },

      "overwrite": {
        "title": t("import.overwrite", {ns: "common"}),
        "type": "boolean",
        "default": false,
        "oneOf": [
          {const: false, title: t("boolean.no", {ns: "common"}),},
          {const: true, title: t("boolean.yes", {ns: "common"})},
        ]
      }
    }
  };

  const uiSchema: UiSchema = {
    'ui:defaultXsWidth': 4,
    "import_file": {
      'ui:widget': 'file',
    },
    "overwrite": {
      "ui:widget": "select",
    },
  }

  const templateDemoHeaders = [
    {field: 'dept_code', title: t("department.code", {ns: "company"}), required: true, unique: true},
    {field: 'dept_name', title: t("department.name", {ns: "company"}), required: true, unique: true},
    {field: 'parent_dept', title: t("department.superior", {ns: "company"})},
  ]
  const templateDemoRows = [
    ['0001', 'xxxx', ''],
    ['0002', 'xxxx', ''],
  ]

  return (
    <ImportView
      title={"Import"}
      modelTitle={t("menu.company.department")}
      breadcrumbs={[
        {title: t("menu.company"), href: urls.company.root},
        { title: t("menu.company.department"), href: AppUrls.company.department }
      ]}
      formSchema={formSchema}
      uiSchema={uiSchema}
      uri={PsnlEP.DEPARTMENT_URI}
      templateDemoHeaders={templateDemoHeaders}
      templateDemoRows={templateDemoRows}
      templateUrl={'/personnel/department/import_template/'}
    />
  )
}
export default DepartmentImportView

import GenericService from "@/services/core/GenericService";
import {AttEndPoint} from "@/services/att/endpoints";


const calculate = async (data: Record<string, any>) => {
  return await GenericService.action(AttEndPoint.CALCULATION_URI, "calculate", data)
}

const CalculationdService = {
  calculate,
}
export default CalculationdService

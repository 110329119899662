import {useRoutes} from "react-router-dom";
import CommandListView from "./ListView";
import CommandRecordView from "@/pages/AccessControl/device_command/RecordView";


const CommandRouter = () => {
  return useRoutes([
    {index: true, element: <CommandListView />},
    {path: "add", element: <CommandRecordView />},
    {path: "edit", element: <CommandRecordView />},
  ])
};
export default CommandRouter

import { useTranslation } from "react-i18next";
import { GridColDef } from "@mui/x-data-grid";
import ListView from "@/components/views/ListView";
import AppUrls from "@/urls";
import { ReportEndPoint } from "@/services/report/endpoints";
import { Box } from "@mui/material";

const ReportItemsSettingListView = () => {
  const { t: t } = useTranslation("report");
  const { t: tMenu } = useTranslation("menu");
  const displayName = (value: any) => t(value);
  const columns: GridColDef[] = [
    { field: "name", headerName: t("reportItemsSetting.name"), valueFormatter: displayName, flex: 1 },
    {
      field: "min_unit",
      headerName: t("reportItemsSetting.minUnit"),
      flex: 1,
    },
    { field: "unit", headerName: t("reportItemsSetting.unit"), flex: 1,
      type: "singleSelect",
      valueOptions: [
        { value: 1, label: t("reportItemsSetting.unit.minute")},
        { value: 2, label: t("reportItemsSetting.unit.hour")},
        { value: 3, label: t("reportItemsSetting.unit.workday")},
        { value: 4, label: t("reportItemsSetting.unit.hourMinute")},
      ],
     },
    {
      field: "round_off",
      headerName: t("reportItemsSetting.roundOff"),
      flex: 1,
      type: "singleSelect",
      valueOptions: [
        { value: 1, label: t("reportItemsSetting.round.roundDown") },
        { value: 2, label: t("reportItemsSetting.roundOff.roundOff") },
        { value: 3, label: t("reportItemsSetting.roundOff.roundUp") },
      ],
    },
    { field: "symbol", headerName: t("reportItemsSetting.symbol"), flex: 1 },
    {
      field: "report_color",
      headerName: t("reportItemsSetting.reportColor"),
      flex: 1,
      renderCell: (params: any) => {
        return (
          <Box
            sx={{ background: params.value, width: "3rem", height: "3rem" }}
          />
        );
      },
    },
  ];

  return (
    <ListView
      disableDelete
      disableAdd
      title={tMenu("menu.report.reportItemsSetting")}
      breadcrumbs={[{ title: tMenu("menu.report"), href: AppUrls.report.root }]}
      columns={columns}
      uri={ReportEndPoint.REPORT_ITEMS_SETTING_URI}
      url={AppUrls.report.reportItemsSetting}
    />
  );
};
export default ReportItemsSettingListView;

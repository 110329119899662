import {RJSFSchema, UiSchema} from "@rjsf/utils";
import {useTranslation} from "react-i18next";
import RecordView from "@/components/views/RecordView";
import urls from "@/urls";
import {PsnlEP} from "@/services/psnl/endpoints";
import {GPSEndPoint} from "@/services/gps/endpoints";
import LocationWidget from "@/pages/GPS/ClientDetails/components/LocationWidget";
import {useEffect, useRef, useState} from "react";
import MapProvider, {useMapContext} from "@/provider/MapProvider";
import LogoWidget from "@/pages/GPS/ClientDetails/components/LogoWidget";

const ClientDetailsRecordView = () => {
  const {t} = useTranslation('gps');
  const {t: common} = useTranslation("common");
  const ref = useRef<any>(null);
  const [formData, setFormData] = useState<Record<string, any>>({})
  const {mapInfo} = useMapContext()
  const formSchema: RJSFSchema = {
    type: "object",
    required: ["client_id", "company_name", "contact_person","mobile", "longitude", "latitude", "punch_location_radius",
      "address", "branch"],
    properties: {
      client_id: {
        type: "string",
        title: t("clientDetails.clientId"),
        maxLength: 55
      },
      address: {
        type: "string",
        title: t("clientDetails.address"),
      },
      company_name: {
        type: "string",
        title: t("clientDetails.companyName"),
        maxLength: 55
      },
      branch: {
        type: "string",
        title: t("clientDetails.branch"),
        maxLength: 55
      },
      contact_person: {
        type: "string",
        title: t("clientDetails.contactPerson"),
        maxLength: 55
      },
      mobile: {
        type: "string",
        title: t("clientDetails.contactNumber"),
        maxLength: 15
      },
      longitude: {
        type: "number",
        title: t("clientDetails.longitude"),
        minimum: 0,
        readOnly: true
      },
      latitude: {
        type: "number",
        title: t("clientDetails.latitude"),
        readOnly: true
      },
      punch_location_radius: {
        type: "number",
        title: t("clientDetails.punchRadius"),
        minimum: 1
      },
      // logo:{
      //   type: "string",
      //   title: t("clientDetails.logo"),
      // }
    },
  };


  const uiSchema: UiSchema = {
    'ui:defaultXsWidth': 6,
    'ui:defaultRowWrap': false,
    punch_location_radius: {
      "ui:widget": "quantifier",
      "ui:options": {
        unit: "mtrs"
      }
    },
    address: {
      "ui:widget": "location"
    },
    logo: {
      "ui:widget": "clientLogo",
      "ui:placeholder": t("clientDetails.logoUpload")
    },
  };

  const widgets = {
    location: LocationWidget,
    // clientLogo: LogoWidget,
  }

  useEffect(() => {
    if (mapInfo) {
      setFormData({
        ...ref.current?.formData,
        address: mapInfo.place.formatted_address,
        latitude: mapInfo.place.geometry?.location?.lat(),
        longitude: mapInfo.place.geometry?.location?.lng()
      });
    }
  }, [mapInfo]);

  return (
    <RecordView
      ref={ref}
      breadcrumbs={[
        {title: t("menu.gps.clientDetails"), href: urls.gps.clientDetails},
      ]}
      formSchema={formSchema}
      uiSchema={uiSchema}
      widgets={widgets}
      formData={formData}
      uri={GPSEndPoint.CLIENT_DETAILS_URI}
    />
  );
};
export default ClientDetailsRecordView;

import { Navigate, useRoutes } from "react-router-dom";
import MyRequestRouter from "@/pages/Personal/request/router";
import MyCalendarRouter from "@/pages/Personal/my-calendar/router";
import MyApprovalRouter from "@/pages/Personal/my-approval/router";
import ReportRouter from "@/pages/Personal/report/router";
import OutdoorManagementRouter from "@/pages/Personal/outdoor-mangement/router";

const PersonalRouter = () => {
  const routes = useRoutes([
    { index: true, element: <Navigate to={"my-calendar"} replace /> },
    { path: "my-calendar/*", element: <MyCalendarRouter /> },
    { path: "request/*", element: <MyRequestRouter /> },
    { path: "approval/*", element: <MyApprovalRouter /> },
    { path: "outdoor-management/*", element: <OutdoorManagementRouter /> },
    { path: "report/*", element: <ReportRouter /> },
  ]);
  return routes;
};
export default PersonalRouter;

const API_VER = 'v2'
const RESOURCE_TAG = 'api'
export const AttEndPoint = {
  ATT_RULE_URI: `/att/${RESOURCE_TAG}/${API_VER}/att_rules/`,
  DEPT_ATT_RULE_URI: `/att/${RESOURCE_TAG}/${API_VER}/dept_att_rules/`,

  ATTCODE_URI: `/att/${RESOURCE_TAG}/${API_VER}/attcodes/`,
  LEAVE_TYPE_URI: `/att/${RESOURCE_TAG}/${API_VER}/leave_types/`,
  HOLIDAY_URI: `/att/${RESOURCE_TAG}/${API_VER}/holidays/`,
  ATTGROUP_URI: `/att/${RESOURCE_TAG}/${API_VER}/attgroups/`,
  ATTRULE_URI: `/att/${RESOURCE_TAG}/${API_VER}/attrules/`,
  BREAK_TIME_URI: `/att/${RESOURCE_TAG}/${API_VER}/break_times/`,
  TIMETABLE_URI: `/att/${RESOURCE_TAG}/${API_VER}/timetables/`,
  ATT_SHIFT_URI: `/att/${RESOURCE_TAG}/${API_VER}/att_shifts/`,
  SCHEDULE_URI: `/att/${RESOURCE_TAG}/${API_VER}/schedules/`,
  DEPT_SCHEDULE_URI: `/att/${RESOURCE_TAG}/${API_VER}/dept_schedules/`,
  TEMPORARY_SCHEDULE_URI: `/att/${RESOURCE_TAG}/${API_VER}/temporary_schedules/`,
  LEAVE_URI: `/att/${RESOURCE_TAG}/${API_VER}/leaves/`,
  OVERTIME_URI: `/att/${RESOURCE_TAG}/${API_VER}/overtimes/`,
  MANUAL_LOG_URI: `/att/${RESOURCE_TAG}/${API_VER}/manual_logs/`,
  TRAINING_URI: `/att/${RESOURCE_TAG}/${API_VER}/trainings/`,
  SWAP_SCHEDULE_URI: `/att/${RESOURCE_TAG}/${API_VER}/swap_schedules/`,

  LEAVE_SETTINGS_URI: `/att/${RESOURCE_TAG}/${API_VER}/leave_settings/`,
  LEAVE_SCHEDULE_URI: `/att/${RESOURCE_TAG}/${API_VER}/leave_schedules/`,

  CALCULATION_URI: `/att/${RESOURCE_TAG}/${API_VER}/calculations/`,
  TIMECARD_URI: `/att/${RESOURCE_TAG}/${API_VER}/timecards/`,
  EXCEPTION_TIMECARD_URI: `/att/${RESOURCE_TAG}/${API_VER}/exception_timecards/`,
  LEAVE_TIMECARD_URI: `/att/${RESOURCE_TAG}/${API_VER}/leave_timecards/`,
  OVERTIME_TIMECARD_URI: `/att/${RESOURCE_TAG}/${API_VER}/overtime_timecards/`,
  UNSCHEDULE_TIMECARD_URI: `/att/${RESOURCE_TAG}/${API_VER}/unschedule_timecards/`,

  WEEKLY_TIMECARD_URI: `/att/${RESOURCE_TAG}/${API_VER}/weekly_timecards/`,
  WEEKLY_EXCEPTION_TIMECARD_URI: `/att/${RESOURCE_TAG}/${API_VER}/weekly_exception_timecards/`,
  WEEKLY_LEAVE_TIMECARD_URI: `/att/${RESOURCE_TAG}/${API_VER}/weekly_leave_timecards/`,
  WEEKLY_OVERTIME_TIMECARD_URI: `/att/${RESOURCE_TAG}/${API_VER}/weekly_overtime_timecards/`,
  WEEKLY_UNSCHEDULE_TIMECARD_URI: `/att/${RESOURCE_TAG}/${API_VER}/weekly_unschedule_timecards/`,

  MONTHLY_TIMECARD_URI: `/att/${RESOURCE_TAG}/${API_VER}/monthly_timecards/`,
  MONTHLY_EXCEPTION_TIMECARD_URI: `/att/${RESOURCE_TAG}/${API_VER}/monthly_exception_timecards/`,
  MONTHLY_LEAVE_TIMECARD_URI: `/att/${RESOURCE_TAG}/${API_VER}/monthly_leave_timecards/`,
  MONTHLY_OVERTIME_TIMECARD_URI: `/att/${RESOURCE_TAG}/${API_VER}/monthly_overtime_timecards/`,
  MONTHLY_UNSCHEDULE_TIMECARD_URI: `/att/${RESOURCE_TAG}/${API_VER}/monthly_unschedule_timecards/`,

  EMPLOYEE_SCHEDULE_URI: `/att/${RESOURCE_TAG}/${API_VER}/employee_schedules/`,
}

import GenericService from "@/services/core/GenericService";
import {AccessControlEndPoint} from "@/services/acc/endpoints";

const openDoor = async (data: Record<string, any>) => {
  const response = await GenericService.action(AccessControlEndPoint.TERMINAL_URI, "open_door", data)
  return response
}

const cancelAlarm = async (data: Record<string, any>) => {
  const response = await GenericService.action(AccessControlEndPoint.TERMINAL_URI, "cancel_alarm", data)
  return response
}

const syncSetup = async (data: Record<string, any>) => {
  const response = await GenericService.action(AccessControlEndPoint.TERMINAL_URI, "sync_setup", data)
  return response
}

const DeviceService = {
  openDoor,
  cancelAlarm,
  syncSetup
}
export default DeviceService

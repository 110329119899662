import {useTranslation} from "react-i18next";
import {useEffect, useState} from "react";
import {RJSFSchema} from "@rjsf/utils";
import SnackbarUtil from "@/utils/SnackbarUtil";
import ModelActionPage from "@/components/views/ListView/components/model-action/ModelActionPage";
import GenericService from "@/services/core/GenericService";
import {BaseEP} from "@/services/base/endpoints";


const RalvieConfiguration = () => {
  const {t} = useTranslation("ralvie")
  const [formData, setFormData] = useState<Record<string, any>>({})
  const formSchema: RJSFSchema = {
    type: "object",
    properties: {
      username: {
        type: "string",
        title: t("ralvie.username")
      },
      password: {
        type: "string",
        title: t("ralvie.password")
      },
      company_code: {
        type: "string",
        title: t("ralvie.companyCode")
      }
    },
    required: ["username", "password", "company_code"]
  }
  const uiSchema = {
    "ui:defaultXsWidth": 4,
    password: {
      "ui:widget": "password"
    }
  }
  const templates = {}
  const getRalvieSetting = async () => {
    const response = await GenericService.action(BaseEP.SYSTEM_SETTING_URI, "get_ralvie_setting")
    if(response.isSuccess){
      setFormData(response.data)
    }
  }

  const onSave = async (data: Record<string, any>) => {
    const response = await GenericService.action(BaseEP.SYSTEM_SETTING_URI, "update_ralvie_setting", data)
    if (response.isSuccess) {
      SnackbarUtil.success(t("common.message.successful", {ns: "common"}))
      await getRalvieSetting()
    } else {
      SnackbarUtil.error(response?.code)
    }
  }

  useEffect(() => {
    getRalvieSetting().then()
  }, [])

  return (
    <ModelActionPage
      title={t("menu.ralvie.configuration", {ns: "menu"})}
      formSchema={formSchema}
      uiSchema={uiSchema}
      templates={templates}
      formData={formData}
      disableDiscard={true}
      onSave={onSave}
    />
  )
}
export default RalvieConfiguration

import {useRoutes} from "react-router-dom";
import HolidayListView from "@/pages/complement/holiday/ListView";
import HolidayRecordView from "@/pages/complement/holiday/RecordView";


const HolidayRouter = () => {
  return useRoutes([
    {index: true, element: <HolidayListView />},
    {path: "add", element: <HolidayRecordView />},
    {path: "edit", element: <HolidayRecordView />}
  ])
}
export default HolidayRouter

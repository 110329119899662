import { WidgetProps } from "@rjsf/utils";
import { FormControlLabel, Switch } from "@mui/material";

const EnableWidget = (props: WidgetProps) => {
  const { label, name, value, required, onChange } = props;
  const _onChange = async (event: any, checked: boolean) => {
    onChange(checked);
  };
  return (
    <FormControlLabel
      required={required}
      control={<Switch checked={value} onChange={_onChange} />}
      label={label}
    />
  );
};
export default EnableWidget;

import {useListViewContext} from "@/provider/ListViewProvider";
import {useDialogContext} from "@/provider/DialogProvider";
import SnackbarUtil from "@/utils/SnackbarUtil";
import {IconButton, ListItemText, MenuItem, Tooltip, useTheme} from "@mui/material";
import {useNavigate} from "react-router-dom";
import {useMemo, useState} from "react";
import {useTranslation} from "react-i18next";

type ListViewModelActionProps = Record<string, any>

const ListViewModelAction = (props: ListViewModelActionProps) => {
  const {t} = useTranslation("common");
  const theme = useTheme()
  const {dataGridRef, refreshData, searchPanelParams} = useListViewContext()
  const dialog = useDialogContext()
  const navigate = useNavigate()
  const onAction = async () => {
    const selectedIds = dataGridRef.current.state.rowSelection
    if (props.validateSelected) {
      if (selectedIds.length == 0) {
        SnackbarUtil.warning(t("common.modelAction.selectRecord"))
        return
      }
    }
    if (props.uniqueObjectRequired){
      if(selectedIds.length > 1){
        SnackbarUtil.warning(t("common.modelAction.selectOneRecordOnly"))
        return
      }
    }
    await props.beforeAction?.()
    switch (props.display) {
      case "page":
        navigate(props.url, {state: {selectedIds, ...props.state}})
        break
      case "dialog":
        dialog.showDialog({
          title: props.title,
          confirmation: props.confirmation,
          onConfirm: async () => await props.callback?.(selectedIds),
          afterConfirm: props.afterConfirm || refreshData,
          Form: props.Form,
          formProps: {
            selectedIds,
            searchPanelParams,
            ...props.state
          }
        })
        break
      default:
        await props.callback?.()
    }
  }
  const buttonColor = useMemo(() => {
    switch (props.id) {
      case "add":
        return {backgroundColor: theme.palette.primary.main, color: "#fff"}
      case "bulkDelete":
        return {backgroundColor: theme.palette.error.main, color: "#fff"}
      default:
        return {backgroundColor: "#fff"}
    }
  }, [props.id])

  return (
    <Tooltip title={props.title}>
      {
        props.menuItem
          ? <MenuItem
            sx={{minWidth: 128}}
            onClick={onAction}
          >
            <ListItemText>{props.title}</ListItemText>
          </MenuItem>
          : <IconButton
            onClick={onAction}
            sx={{
              borderRadius: "6px",
              backgroundColor: buttonColor.backgroundColor,
              color: buttonColor.color
            }}
          >
            {props.icon}
          </IconButton>
      }
    </Tooltip>
  )
}
export default ListViewModelAction

import {ObjectFieldTemplateProps} from "@rjsf/utils";
import {useTranslation} from "react-i18next";
import Grid from "@mui/material/Grid2";
import {Stack} from "@mui/system";


const TemporaryScheduleLayout = (props: ObjectFieldTemplateProps) => {
  const { t } = useTranslation()
  const fields: Record<string, any> = {}
  props.properties?.forEach((element: any) => { fields[element.name] = element.content })
  return (
    <Grid container spacing={2}>
      <Grid size={3}>
        <Stack spacing={2}>
          {fields.employees}
          {fields.work_type}
          {fields.rule_flag}
        </Stack>
      </Grid>
      <Grid size={9} sx={{height: "100%"}}>
        {fields.shifts}
      </Grid>
    </Grid>
  )
}
export default TemporaryScheduleLayout

import {useTranslation} from "react-i18next";
import {GridColDef} from "@mui/x-data-grid";
import ListView from "@/components/views/ListView";
import AppUrls from "@/urls";
import {GPSEndPoint} from "@/services/gps/endpoints";


const GPSOfDepartmentListView = () => {
  const { t: t } = useTranslation("gps");
  const { t: tMenu } = useTranslation("menu");


  const columns: GridColDef[] = [
    {field: "department_code", headerName: t("GPSOfDepartment.departmentCode"), flex: 1,},
    {field: "department_name", headerName: t("GPSOfDepartment.departmentName"), flex: 1},
    {field: "location", headerName: t("GPSOfDepartment.location"), flex: 1},
    {field: "longitude", headerName: t("GPSOfDepartment.longitude"), flex: 1},
    {field: "latitude", headerName: t("GPSOfDepartment.latitude"), flex: 1},
    {field: "distance", headerName: t("GPSOfDepartment.distance"), flex: 1},
    {field: "start_date", headerName: t("GPSOfDepartment.startDate"), flex: 1},
    {field: "end_date", headerName: t("GPSOfDepartment.endDate"), flex: 1},
  ]

  const filters = [
    {key: 'department_code', label: t("GPSOfDepartment.employeeId"), type: 'string'},
    {key: 'department_code', label: t("GPSOfDepartment.employeeName"), type: 'string'}
  ]


  return (
    <ListView
      title={tMenu("menu.gps.geofenceOfDepartment")}
      breadcrumbs={[
        {title: tMenu("menu.gps"), href: AppUrls.gps.root},
      ]}
      columns={columns}
      uri={GPSEndPoint.DEPARTMENT_GPS_URI}
      url={AppUrls.gps.gpsOfDepartment}
      filters={filters}
    />
  )
}
export default GPSOfDepartmentListView

import {GridColDef} from "@mui/x-data-grid";
import AppUrls from "@/urls";
import ListView from "@/components/views/ListView";
import {useTranslation} from "react-i18next";
import {GPSEndPoint} from "@/services/gps/endpoints";
import RecordPanel from "@/pages/GPS/outdoor-schedule/components/RecordPanel";


const OutdoorScheduleListView = () => {
  const {t} = useTranslation("gps")
  const {t:per} = useTranslation("per")
  const {t: menu} = useTranslation("menu")
  const columns: GridColDef[] = [
    {field: "emp_code", headerName: per("employee.code"), flex: 1},
    {field: "first_name", headerName: per("employee.firstName"), flex: 1},
    {field: "schedule_name", headerName: t("schedulePlanner.name"), flex: 1},
    {field: "start_date", headerName: t("outdoorSchedule.startDate"), flex: 1},
    {field: "end_date", headerName: t("outdoorSchedule.endDate"), flex: 1},
  ]
  return (
    <ListView
      title={menu("menu.gps.outdoorSchedule")}
      columns={columns}
      uri={GPSEndPoint.OUTDOOR_SCHEDULE}
      url={AppUrls.gps.outdoorSchedule}
      RecordPanel={RecordPanel}
    />
  )
}
export default OutdoorScheduleListView

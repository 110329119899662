import {DateCalendar, LocalizationProvider, PickersDay, PickersDayProps} from "@mui/x-date-pickers";
import dayjs, {Dayjs} from "dayjs";
import isBetweenPlugin from "dayjs/plugin/isBetween";
import React, {useMemo, useState} from "react";
import {Button, IconButton, Menu, Stack, styled, useTheme} from "@mui/material";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {bindMenu, bindTrigger, usePopupState} from "material-ui-popup-state/hooks";
import {ArrowLeft, ArrowRight} from "@mui/icons-material";

dayjs.extend(isBetweenPlugin);

interface CustomPickerDayProps extends PickersDayProps<Dayjs> {
  isSelected: boolean;
  isHovered: boolean;
}

const CustomPickersDay = styled(PickersDay, {
  shouldForwardProp: (prop) => prop !== 'isSelected' && prop !== 'isHovered',
})<CustomPickerDayProps>(({theme, isSelected, isHovered, day}) => ({
  borderRadius: 0,
  ...(isSelected && {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    '&:hover, &:focus': {
      backgroundColor: theme.palette.primary.main,
    },
  }),
  ...(isHovered && {
    backgroundColor: theme.palette.primary.light,
    '&:hover, &:focus': {
      backgroundColor: theme.palette.primary.light,
    },
    ...theme.applyStyles('dark', {
      backgroundColor: theme.palette.primary.dark,
      '&:hover, &:focus': {
        backgroundColor: theme.palette.primary.dark,
      },
    }),
  }),
  ...(day.day() === 0 && {
    borderTopLeftRadius: '50%',
    borderBottomLeftRadius: '50%',
  }),
  ...(day.day() === 6 && {
    borderTopRightRadius: '50%',
    borderBottomRightRadius: '50%',
  }),
})) as React.ComponentType<CustomPickerDayProps>

const isInSameWeek = (dayA: Dayjs, dayB: Dayjs | null | undefined) => {
  if (dayB === null) {
    return false;
  }
  return dayA.isSame(dayB, 'week');
}

const CustomDay = (props: PickersDayProps<Dayjs> & { selectedDay?: Dayjs | null, hoveredDay?: Dayjs | null }) => {
  const {day, selectedDay, hoveredDay, ...pickersProps} = props
  return (
    <CustomPickersDay
      {...pickersProps}
      disableMargin
      day={day}
      selected={false}
      isSelected={isInSameWeek(day, selectedDay)}
      isHovered={isInSameWeek(day, hoveredDay)}
    />
  )
}

const CustomDateCalendar = (props: Record<string, any>) => {
  const theme = useTheme()
  const {onDateRangeChange} = props
  const [selectedDay, setSelectedDay] = useState<Dayjs>(dayjs())
  const [hoveredDay, setHoveredDay] = useState<Dayjs | null>()
  const popupState = usePopupState({variant: "popover", popupId: "dateRangeCalendar"})
  const dateRangeDisplay = useMemo(() => {
    return `${selectedDay.format("MMMM YYYY")}`
  }, [selectedDay])

  const updateFilters = async (newDay: Dayjs) => {
    onDateRangeChange(
      newDay.startOf("month"),
      newDay.endOf("month")
    )
  }
  const onPreMonth = async () => {
    const preStartDay = selectedDay.add(-1, "month")
    setSelectedDay(preStartDay)
    await updateFilters(preStartDay)
  }
  const onNextMonth = async () => {
    const nextStartDay = selectedDay.add(1, "month")
    setSelectedDay(nextStartDay)
    await updateFilters(nextStartDay)
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Stack direction={"row"} sx={{justifyContent: "center", alignItems: "center"}}>
        <IconButton onClick={onPreMonth}><ArrowLeft/></IconButton>
        <Button
          variant={"text"}
          {...bindTrigger(popupState)}
        >
          {dateRangeDisplay}
        </Button>
        <IconButton onClick={onNextMonth}><ArrowRight/></IconButton>
      </Stack>
      <Menu
        autoFocus={false}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: (theme.direction === 'rtl' ? 'left' : 'right')
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: (theme.direction === 'rtl' ? 'left' : 'right')
        }}
        variant={'selectedMenu'}
        {...bindMenu(popupState)}
      >
        <DateCalendar
          value={selectedDay}
          onChange={async (newValue: Dayjs) => {
            setSelectedDay(newValue)
            popupState.close()
            await updateFilters(newValue)
          }}
          views={['month', 'year']}
          openTo="month"
        />
      </Menu>
    </LocalizationProvider>
  )
}
export default CustomDateCalendar

import { ObjectFieldTemplateProps } from "@rjsf/utils";
import { Stack, Tooltip } from "@mui/material";
import { useTranslation } from "react-i18next";
import Grid from "@mui/material/Grid2";
import {
  ScrollTab,
  ScrollTabs,
  useScrollTabsHook,
} from "@/components/ScrollTabs";

const LayoutTemplate = (props: ObjectFieldTemplateProps) => {
  const { t } = useTranslation("sys");
  const fields: Record<string, any> = {};
  props.properties?.forEach((element: any) => {
    fields[element.name] = element.content;
  });

  const [tabs, getTabProps] = useScrollTabsHook([
    {
      key: "basicSettings",
      title: t("workRule.basicSettings"),
    },
    {
      key: "dataTemplateSettings",
      title: t("autoExport.dataTemplateSettings"),
    },
    { key: "formatSettings", title: t("autoExport.formatSettings") },
    {
      key: "dataFilterSettings",
      title: t("autoExport.dataFilterSettings"),
    },
    {
      key: "exportTimeSettings",
      title: t("autoExport.exportTimeSettings"),
    },
    {
      key: "exportPathSettings",
      title: t("autoExport.exportPathSettings"),
    },
  ]);

  return (
    <>
      <ScrollTabs tabs={tabs}>
        <Stack spacing={3} sx={{ width: "70%" }}>
          <ScrollTab {...getTabProps("basicSettings")}>
            <Grid container spacing={2}>
              <Grid size={6}>{fields.task_code}</Grid>
              <Grid size={6}>{fields.task_name}</Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid size={6}>{fields.file_prefix}</Grid>
              <Grid size={6}>{fields.file_date}</Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid size={6}>{fields.file_time}</Grid>
            </Grid>
          </ScrollTab>

          <ScrollTab {...getTabProps("dataTemplateSettings")}>
            <Grid container spacing={2}>
              <Grid size={12}>{fields.data_template}</Grid>
            </Grid>
          </ScrollTab>

          <ScrollTab {...getTabProps("formatSettings")}>
            <Grid container spacing={2}>
              <Grid size={6}>{fields.short_date}</Grid>
              <Grid size={6}>{fields.short_time}</Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid size={6}>{fields.pin_digit}</Grid>
              <Grid size={6}>{fields.export_format}</Grid>
            </Grid>
          </ScrollTab>

          <ScrollTab {...getTabProps("dataFilterSettings")}>
            <Grid container spacing={2}>
              <Grid size={6}>{fields.query_field}</Grid>
              <Grid size={6}>{fields.end_day}</Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid size={6}>{fields.department}</Grid>
              <Grid size={6}>{fields.area}</Grid>
            </Grid>
          </ScrollTab>

          <ScrollTab {...getTabProps("exportTimeSettings")}>
            <Grid container spacing={2}>
              <Grid size={6}>{fields.export_interval}</Grid>
              <Grid size={6}>{fields.export_time}</Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid size={6}>{fields.frequency}</Grid>
              <Grid size={6}>{fields.day}</Grid>
            </Grid>
          </ScrollTab>

          <ScrollTab {...getTabProps("exportPathSettings")}>
            <Grid container spacing={2}>
              <Grid size={6}>{fields.export_path}</Grid>
              <Grid size={6}>{fields.export_email}</Grid>
            </Grid>
          </ScrollTab>
        </Stack>
      </ScrollTabs>
    </>
  );
};
export default LayoutTemplate;

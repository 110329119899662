import {useTranslation} from "react-i18next";
import {GridColDef, GridRenderCellParams} from "@mui/x-data-grid";
import AppUrls from "@/urls";
import ListView from "@/components/views/ListView";
import {VendorEndPoint} from "@/services/vendor/endpoints";
import {Visibility} from "@mui/icons-material";
import RecordReadOnlyView from "@/pages/vendor/subscription/Order/components/RecordReadOnlyView";


const OrderListView = () => {
  const {t} = useTranslation("vendor")
  const {t: menu} = useTranslation("menu")
  const renderPaymentStatus = (params: GridRenderCellParams) => {
    switch (params.value) {
      case 0:
        return t("paymentStatus.manualCancel")
      case 1:
        return t("paymentStatus.timeout")
      case 2:
        return t("paymentStatus.paid")
      case 3:
        return t("paymentStatus.unpaid")
      default:
        return ""
    }
  }
  const columns: GridColDef[] = [
    {field: "number", headerName: t("order.number")},
    {field: "company_name", headerName: t("order.company")},
    {field: "price_strategy", headerName: t("order.priceStrategy")},
    {field: "subscription_duration", headerName: t("order.lengthOfService")},
    {field: "employees_count", headerName: t("order.employeeCapacity")},
    {field: "devices_count", headerName: t("order.deviceCapacity")},
    {field: "mobile_applications_count", headerName: t("order.mobileAppCapacity")},
    {field: "transactions_count", headerName: t("order.transactionCapacity")},
    {field: "whatsapp_push_count", headerName: t("order.whatsappCapacity")},
    {field: "original_price", headerName: t("order.originalPrice")},
    {field: "discount_price", headerName: t("order.discountPrice")},
    {field: "payment_price", headerName: t("order.paymentAmount")},
    {field: "create_user", headerName: t("order.orderingPerson")},
    {field: "create_time", headerName: t("order.orderingTime")},
    {field: "payment_status", headerName: t("order.paymentStatus"), renderCell: renderPaymentStatus},
  ]

  const recordActions = [
    {id: "view", icon: <Visibility />, title: "View", display: "dialog", readOnly: true, Form: RecordReadOnlyView}
  ]

  return (
    <ListView
      title={menu("menu.subscription.order")}
      breadcrumbs={[
        {title: menu("menu.subscription"), href: AppUrls.subscription.root},
      ]}
      columns={columns}
      recordActions={recordActions}
      uri={VendorEndPoint.ORDER_URI}
      url={AppUrls.vendor.order}
      disableDelete={true}
      disableEdit={true}
    />
  )
}
export default OrderListView

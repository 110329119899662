import { WidgetProps } from "@rjsf/utils";
import { Avatar, Box, Button, Dialog, DialogActions, DialogTitle, InputLabel, Stack, Typography } from "@mui/material";
import React, { useRef } from "react";
import { bindDialog } from 'material-ui-popup-state';
import { usePopupState } from "material-ui-popup-state/hooks";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";


const UploadLogoWidget = (props: WidgetProps) => {
  const { name, label, value, options, uiSchema, readonly } = props
  const inputRef = useRef<any>()
  let displaySrc = '#'
  if (value) {
    if (value.startsWith('data:image/')) {
      displaySrc = value
    } else {
      displaySrc = `/web/auth_files/${value}`
    }
  }

  const [image, setImage] = React.useState('');
  const [cropData, setCropData] = React.useState(displaySrc);
  const popupState = usePopupState({ variant: 'popover', popupId: 'popupLogoUpload' })

  const cropperRef = useRef<any>(null);

  return (
    <div>
      <InputLabel shrink sx={{ transform: "translate(0px, -24px) scale(0.75)" }}>
        {label}
      </InputLabel>
      <Stack direction={"row"} spacing={2} sx={{ alignItems: "center" }}>
        <Avatar
          variant={"square"}
          sx={{ width: "300px", height: "100px", bgcolor: "#4caf50" }}
          src={cropData}
        >No Photo</Avatar>
        <Stack spacing={2}>
          <Stack direction={"row"} spacing={2}>
            <Button variant={"contained"} component={"label"} disabled={readonly}>
              {options?.uploadButton ? options?.uploadButton?.toString() : "Upload"}
              <input
                hidden={true}
                type={"file"}
                accept={(options?.accept?.toString() || "image/png,image/jpeg")}
                name={name}
                onChange={async (event: any) => {
                  const curAvatar = event.target.files[0]
                  const reader = new FileReader()
                  reader.readAsDataURL(curAvatar)
                  reader.onload = (event) => {
                    popupState.open()
                    setImage(event.target?.result as any)
                  }
                }}
                ref={inputRef}
              />
            </Button>
            <Button variant={"outlined"} disabled={readonly} onClick={async () => {
              inputRef.current.value = null
              props.onChange(undefined)
              setCropData('#')
            }}>Reset</Button>
          </Stack>
          <Typography>{options?.customHelp?.toString()}</Typography>
        </Stack>
      </Stack>

      <Dialog
        {...bindDialog(popupState)}
        maxWidth={'lg'}
      >
        <DialogTitle>Crop Logo</DialogTitle>
        <Box sx={{ height: "300px", width: '600px' }}>
          <Cropper
            src={image}
            style={{ height: 200, width: 600 }}
            initialAspectRatio={210 / 70}
            aspectRatio={210 / 70}
            guides={false}
            ref={cropperRef}
          />
        </Box>

        <DialogActions>
          <Button onClick={(event) => {
            const cropper = cropperRef.current?.cropper;
            cropper.rotate(90)
          }} >Rotate</Button>
          <Button onClick={(event) => { popupState.close() }} >Cancel</Button>
          <Button variant="contained" onClick={() => {
            setCropData(cropperRef.current?.cropper.getCroppedCanvas().toDataURL());
            props.onChange(cropperRef.current?.cropper.getCroppedCanvas().toDataURL())
            popupState.close()
          }}>Set Logo</Button>
        </DialogActions>
      </Dialog>
    </div >
  )
}
export default UploadLogoWidget

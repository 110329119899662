import ListView from "@/components/views/ListView";
import {GridColDef} from "@mui/x-data-grid";
import AppUrls from "@/urls";
import {PsnlEP} from "@/services/psnl/endpoints";
import {useTranslation} from "react-i18next";
import TransferAction from "./components/TransferAction";
import SyncAltIcon from '@mui/icons-material/SyncAlt';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';


const PositionListView = () => {
  const {t} = useTranslation("company")
  const {t: menu} = useTranslation("menu")
  const {t: common} = useTranslation("common")
  const displayName = (value: any) => value?.display_name
  const columns: GridColDef[] = [
    {field: "position_code", headerName: t("position.code"), flex: 1},
    {field: "position_name", headerName: t("position.name"), flex: 1},
    {field: "parent_position", valueFormatter: displayName, headerName: t("position.superior"), flex: 1},
    {field: "employee_count", headerName: t("position.employeeCount"), flex: 1},
    {field: "resigned_count", headerName: t("position.resignCount"), flex: 1},
  ]

  const filters = [
    {key: 'position_code', label: t("position.code"), type: 'string'},
    {key: 'position_name', label: t("position.name"), type: 'string'},
  ]

  const actions = [
    {
      key: "transfer", title: t("position.personnelTransfer"), validateSelected: true, uniqueObjectRequired: true, icon: <SyncAltIcon />,
      display: "dialog", Form: TransferAction
    },
    {
      id: "import", title: common("common.action.import"), icon: <FileUploadOutlinedIcon />,
      display: "page", url: `${AppUrls.company.position}/import`,
    },
  ]

  return (
    <ListView
      title={menu("menu.company.position")}
      columns={columns}
      uri={PsnlEP.POSITION_URI}
      url={AppUrls.company.position}
      filters={filters}
      modelActions={actions}
    />
  )
}
export default PositionListView

import Grid from "@mui/material/Grid2";
import {useTranslation} from "react-i18next";
import {Chip, FormLabel} from "@mui/material";
import {GridRenderCellParams} from "@mui/x-data-grid";
import {Stack} from "@mui/system";


const RecordReadOnlyView = (props: Record<string, any>) => {
  const {t} = useTranslation('vendor')
  const {recordId, record} = props
  console.log("RecordReadOnlyView", record)
  const renderPaymentStatus = (paymentStatus: number) => {
    switch (paymentStatus) {
      case 0:
        return t("paymentStatus.manualCancel")
      case 1:
        return t("paymentStatus.timeout")
      case 2:
        return t("paymentStatus.paid")
      case 3:
        return t("paymentStatus.unpaid")
      default:
        return ""
    }
  }

  const renderFeature = (features: any[]) => {
    return (
      <Stack spacing={0.5} direction={"row"}>
        {
          features.map((feature, index) => <Chip key={`feature${index}`} color={"primary"} label={feature.name}/>)
        }
      </Stack>
    )
  }

  const items = [
    {label: t("vendor.order.number"), value: record.number},
    {label: t("vendor.order.company"), value: record.company_name},
    {label: t("vendor.order.employeeCapacity"), value: record.employees_count},
    {label: t("vendor.order.deviceCapacity"), value: record.devices_count},
    {label: t("vendor.order.mobileAppCapacity"), value: record.mobile_applications_count},
    {label: t("vendor.order.whatsappCapacity"), value: record.whatsapp_push_count},
    {label: t("vendor.order.originalPrice"), value: record.original_price},
    {label: t("vendor.order.discountPrice"), value: record.discount_price},
    {label: t("vendor.order.paymentAmount"), value: record.payment_price},
    {label: t("vendor.order.paymentStatus"), value: renderPaymentStatus(record.payment_status)},
    {label: t("vendor.order.feature"), value: renderFeature(record.feature)},
  ]

  return (
    <Grid
      container
      spacing={1}
      sx={{
        minWidth: "384px",
        minHeight: "128px",
        marginTop: "-16px",
        marginBottom: "16px"
      }}
    >
      {items.map((item) => (
        <>
          <Grid size={6}>
            <FormLabel>{item.label}</FormLabel>
          </Grid>
          <Grid size={6}>{item.value}</Grid>
        </>
      ))}
    </Grid>
  )
}
export default RecordReadOnlyView

import {PsnlEP} from "@/services/psnl/endpoints";
import {RJSFSchema, UiSchema} from "@rjsf/utils";
import {useTranslation} from "react-i18next";
import RecordView from "@/components/views/RecordView";
import urls from "@/urls";
import {TeamEP} from "@/services/team/endpoints";


const NodeRecordView = () => {
  const {t} = useTranslation("per");
  const formSchema: RJSFSchema = {
    "type": "object",
    "required": [
      "node_code",
      "node_name",
    ],
    "properties": {
      "node_code": {
        "type": "string",
        "title": t("workflowNode.code"),
        "maxLength": 30
      },
      "node_name": {
        "type": "string",
        "title": t("workflowNode.name"),
        "maxLength": 30
      },
      "approver": {
        "type": ["array", "null"],
        "title": t("workflowNode.approver"),
        "items": {
          "type": ["number"]
        },
      },
      "notifier": {
        "type": ["array", "null"],
        "title": t("workflowNode.notifier"),
        "items": {
          "type": ["number"]
        },
      },
      "approve_scope": {
        "type": ["array", "null"],
        "title": t("workflowNode.approverScope"),
        "items": {
          "type": ["number"],
        },
      },
      "notify_scope": {
        "type": ["array", "null"],
        "title": t("workflowNode.notifierScope"),
        "items": {
          "type": ["number"],
        },
      }
    }
  };

  const uiSchema: UiSchema = {
    "ui:defaultXsWidth": 6,
    "ui:defaultRowWrap": false,
    "ui:submitButtonOptions": {"norender": true},
    "approver": {
      "ui:widget": "m2m",
      "ui:options": {
        uri: TeamEP.WORKFLOW_ROLE_URI
      }
    },
    "notifier": {
      "ui:widget": "m2m",
      "ui:options": {
        uri: TeamEP.WORKFLOW_ROLE_URI
      }
    },
    "approve_scope": {
      "ui:widget": "m2m",
      "ui:options": {
        uri: PsnlEP.DEPARTMENT_URI
      }
    },
    "notify_scope": {
      "ui:widget": "m2m",
      "ui:options": {
        uri: PsnlEP.DEPARTMENT_URI
      }
    },
  };
  return (
    <RecordView
      breadcrumbs={[
        {title: t("menu.team", {ns: "menu"}), href: urls.team.root},
        {title: t("menu.workflowNode", {ns: "menu"}), href: urls.team.workflowNode},
      ]}
      formSchema={formSchema}
      uiSchema={uiSchema}
      uri={TeamEP.WORKFLOW_NODE_URI}
    />
  )
}
export default NodeRecordView

import {Navigate, Route, Routes} from "react-router-dom";
import VerifyCompanyForm from "@/pages/Oauth/VerifyCompanyForm";
import SignInForm from "@/pages/Oauth/SignInForm";
import SignUpForm from "@/pages/Oauth/SignUpForm";
import EmailActivationForm from "@/pages/Oauth/EmailActivationForm";
import AccountActivation from "@/pages/Oauth/AccountActivation";
import CreateCompanyForm from "@/pages/Oauth/CreateCompanyForm";
import AccountInvited from "@/pages/Oauth/AccountInvited";
import InvitedSignUp from "@/pages/Oauth/InvitedSignUp";
import ResetPassword from "@/pages/Oauth/ResetPassword";
import ForgotPassword from "@/pages/Oauth/ForgotPassword";
import Onboard from "@/layout/Onboard";
import JoinApproval from "@/pages/Oauth/JoinApproval";

const OauthRouter = () => {
  return (
    <Routes>
      <Route path={"/"} element={<Onboard/>}>
        <Route index element={<Navigate to={"company-verification"} replace/>}/>
        <Route path={"company-verification"} element={<VerifyCompanyForm/>}/>
        <Route path={"sign-in"} element={<SignInForm/>}/>
        <Route path={"sign-up"} element={<SignUpForm/>}/>
        <Route path={"reset-password"} element={<ResetPassword/>}/>
        <Route path={"email-activation"} element={<EmailActivationForm/>}/>
        <Route path={"forgot-password"} element={<ForgotPassword/>}/>
        <Route path={"activation"} element={<AccountActivation/>}/>
        <Route path={"invited"} element={<AccountInvited/>}/>
        <Route path={"invited-sign-up"} element={<InvitedSignUp/>}/>
        <Route path={"company-creation"} element={<CreateCompanyForm/>}/>
        <Route path={"join-approval"} element={<JoinApproval/>}/>
      </Route>
    </Routes>
  )
};
export default OauthRouter

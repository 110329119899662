import {useTranslation} from "react-i18next";
import {GridColDef, GridRenderCellParams} from "@mui/x-data-grid";
import AppUrls from "@/urls";
import ListView from "@/components/views/ListView";
import {VendorEndPoint} from "@/services/vendor/endpoints";
import {Switch} from "@mui/material";


const PriceStrategyListView = () => {
  const {t} = useTranslation("vendor")
  const {t: menu} = useTranslation("menu")
  const renderStatus = (params: GridRenderCellParams) => {
    return <Switch checked={params.value}/>
  }

  const columns: GridColDef[] = [
    {field: "country", headerName: t("priceStrategy.country")},
    {
      field: "type",
      headerName: t("priceStrategy.type"),
      type: "singleSelect",
      valueOptions: [
        {value: 1, label: t("priceStrategyType.basic")},
        {value: 2, label: t("priceStrategyType.standard")},
        {value: 3, label: t("priceStrategyType.premium")},
        {value: 4, label: t("priceStrategyType.enterprise")},
        {value: 5, label: t("priceStrategyType.customization")},
        {value: 6, label: t("priceStrategyType.lite")},
        {value: 7, label: t("priceStrategyType.professional")},
      ]
    },
    {field: "effective_status", headerName: t("priceStrategy.effective"), renderCell: renderStatus},
    {field: "subscription_duration_unit_price_pre_month", headerName: t("priceStrategy.monthlyFee")},
    {
      field: "discount", headerName: t("priceStrategy.discount"), renderCell: (params) => {
        return params.value?.discount_ratio
      }
    },
    {field: "employee_threshold", headerName: t("priceStrategy.employeeThreshold")},
    {field: "employee_unit_price", headerName: t("priceStrategy.employeeUnitPrice")},
    {field: "device_threshold", headerName: t("priceStrategy.deviceThreshold")},
    {field: "device_unit_price", headerName: t("priceStrategy.deviceUnitPrice")},
    {
      field: "whatsapp_push_counts_limit_status",
      headerName: t("priceStrategy.whatsappLimited"),
      renderCell: renderStatus
    },
    {field: "whatsapp_push_threshold", headerName: t("priceStrategy.whatsappThreshold")},
    {field: "whatsapp_push_unit_price", headerName: t("priceStrategy.whatsappUnitPrice")},
    {
      field: "mobile_app_counts_limit_status",
      headerName: t("priceStrategy.mobileAppLimited"),
      renderCell: renderStatus
    },
    {field: "mobile_applications_threshold", headerName: t("priceStrategy.mobileAppThreshold")},
    {field: "mobile_applications_unit_price", headerName: t("priceStrategy.mobileAppUnitPrice")},
    {
      field: "transaction_counts_limit_status",
      headerName: t("priceStrategy.transactionLimited"),
      renderCell: renderStatus
    },
    {field: "transaction_threshold", headerName: t("priceStrategy.transactionThreshold")},
    {field: "transaction_unit_price", headerName: t("priceStrategy.transactionUnitPrice")},
    {
      field: "feature", headerName: t("priceStrategy.feature"), renderCell: (params) => {
        return params.value?.map((feature: any) => feature.name)?.join(",")
      }
    },
  ]
  return (
    <ListView
      title={menu("menu.subscription.priceStrategy")}
      breadcrumbs={[
        {title: menu("menu.subscription"), href: AppUrls.subscription.root},
      ]}
      columns={columns}
      uri={VendorEndPoint.PRICE_STRATEGY_URI}
      url={AppUrls.vendor.priceStrategy}
    />
  )
}
export default PriceStrategyListView

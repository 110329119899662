import { Box, InputAdornment, Popover, TextField } from "@mui/material";
import { colorFromString } from "./utils";
import React, { useEffect } from "react";
import Chrome from '@uiw/react-color-chrome';
import { GithubPlacement } from '@uiw/react-color-github';

interface ColorInputProps {
  label: string;
  color: string;
  onColorChange: (color: string) => void;
}

/**
 * The base TextField input for selecting colors.
 * onClick opens a popover with components to help pick colors
 */
export default function ColorInput({ label, color, onColorChange }: ColorInputProps) {
  const [anchorEl, setAnchorEl] = React.useState<Element | null>(null)

  const handleOpenPopover = (event: React.MouseEvent) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClosePopover = () => {
    setAnchorEl(null)
  }

  const handleColorChange = (value: string) => onColorChange(value)

  const handlePaste = (event: React.ClipboardEvent<HTMLDivElement>) => {
    const pastedText = event.clipboardData.getData("text")
    const color = colorFromString(pastedText)
    if (color) {
      handleColorChange(color)
    }
  }

  const popoverOpen = Boolean(anchorEl)
  return (
    <Box>
      <TextField
        label={label}
        onClick={handleOpenPopover}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Box
                sx={{
                  width: "1em",
                  height: "1em",
                  border: "1px solid grey",
                  bgcolor: color
                }}
              />
            </InputAdornment>
          ),
        }}
        InputLabelProps={{ shrink: true }}
        // size="small"
        fullWidth
        value={color}
        onPaste={handlePaste}
        variant="standard"
      />
      <Popover
        open={popoverOpen}
        anchorEl={anchorEl}
        onClose={handleClosePopover}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        disableAutoFocus
        disableEnforceFocus
      >
        <ColorPicker color={color} onChangeComplete={handleColorChange} />
      </Popover>
    </Box>
  )
}

interface ColorPickerProps {
  color: string;
  onChangeComplete: (a: any, b: any) => void;
}

export function ColorPicker({ color, onChangeComplete }: ColorPickerProps) {
  const [inputValue, setInputValue] = React.useState<string>("#fff")
  useEffect(() => {
    setInputValue(color)
  }, [color])

  return (
    <>
      <Chrome
        color={inputValue}
        style={{ float: 'left' }}
        placement={GithubPlacement.Right}
        onChange={(color) => {
          setInputValue(color.hex)
          onChangeComplete(color.hex, null)
        }}
      />
    </>
  )
}

import {ObjectFieldTemplateProps} from "@rjsf/utils";
import Grid from "@mui/material/Grid2";


const RecordViewLayout = (props: ObjectFieldTemplateProps) => {
  const fields: Record<string, any> = {}
  props.properties?.forEach((element: any) => { fields[element.name] = element.content })
  return (
    <Grid container spacing={2} sx={{ height: "100%", marginTop: 0 }}>
      <Grid size={4}>{fields.subscription_start_date}</Grid>
      <Grid size={4}>{fields.subscription_end_date}</Grid>
      <Grid size={4} />
      <Grid size={4}>{fields.max_employees_limit}</Grid>
      <Grid size={4}>{fields.max_devices_limit}</Grid>
      <Grid size={4} />
      <Grid size={4}>{fields.max_transactions_limit}</Grid>
      <Grid size={4}>{fields.max_mobile_applications_limit}</Grid>
      <Grid size={4} />
      <Grid size={4}>{fields.max_whatsapp_push_limit}</Grid>
      <Grid size={8} />
      <Grid size={4}>{fields.feature}</Grid>
    </Grid>
  )
}
export default RecordViewLayout

import {useTranslation} from "react-i18next";
import {RJSFSchema, UiSchema} from "@rjsf/utils";
import {AuthEP} from "@/services/auth/endpoints";
import {PsnlEP} from "@/services/psnl/endpoints";
import RecordView from "@/components/views/RecordView";
import LayoutTemplate from "./components/LayoutTemplate";
import DataTemplateWidget from "./components/DataTemplateWidget";
import React, {useMemo} from "react";

const AutoExportRecordView = () => {
  const {t} = useTranslation("sys");
  const {t: common} = useTranslation("common");
  const weekDays = [
    {const: 0, title: common("weekday.monday",)},
    {const: 1, title: common("weekday.tuesday",)},
    {const: 2, title: common("weekday.wednesday",)},
    {const: 3, title: common("weekday.thursday",)},
    {const: 4, title: common("weekday.friday",)},
    {const: 5, title: common("weekday.saturday",)},
    {const: 6, title: common("weekday.sunday",)},
  ];

  const monthDays = useMemo(() => {
    const days = [];
    for (let i = 1; i < 32; i++) {
      days.push({const: i, title: `${i}`});
    }
    return days;
  }, []);
  const formSchema: RJSFSchema = {
    type: "object",
    definitions: {
      day: {
        type: "integer",
        title: t("cycleUnit.day"),
        default: 1,
        oneOf: [{const: 1, title: "1"}],
      },
    },
    properties: {
      task_code: {
        type: "string",
        title: t("autoExport.taskCode"),
        maxLength: 50
      },
      task_name: {
        type: "string",
        title: t("autoExport.taskName"),
        maxLength: 50
      },
      file_prefix: {
        type: "string",
        title: t("autoExport.filePrefix"),
      },
      file_date: {
        type: "integer",
        title: t("autoExport.fileDate"),
        oneOf: [
          {const: 1, title: "YYYY-MM-DD"},
          {const: 5, title: "DD-MM-YYYY"},
          {const: 6, title: "MM/DD/YYYY"},
          {const: 7, title: "MM-DD-YYYY"},
          {const: 8, title: "YY-MM-DD"},
          {const: 12, title: "YYYYMMDD"},
          {const: 13, title: "YYYYDDMM"},
          {const: 14, title: "MMDDYYYY"},
          {const: 15, title: "DDMMYYYY"},
          {const: 16, title: "YYMMDD"},
          {const: 17, title: "YYDDMM"},
          {const: 18, title: "MMDDYY"},
          {const: 19, title: "DDMMYY"},
        ],
        default: 1,
      },
      file_time: {
        type: "integer",
        title: t("autoExport.fileTime"),
        oneOf: [
          {const: 0, title: "-----------"},
          {const: 9, title: "HHmm"},
          {const: 10, title: "hmm"},
          {const: 11, title: "HHmmss"},
          {const: 12, title: "hmmss"},
        ],
        default: 0,
      },
      data_template: {
        type: "string",
        title: t("autoExport.dataTemplate"),
        default:
          "{emp_code}\\t{first_name}\\t{last_name}\\t{dept_code}\\t{dept_name}\\t{date}\\t{time}\\t{verify_type}\\t{punch_state}\\t{work_code}\\t{card_number}\\t{area_name}\\t{terminal_alias}\\t{terminal_sn}\\t{temperature}\\t{mask_flag}\\t{gps_location}\\r\\n",
      },
      short_date: {
        type: "string",
        title: t("autoExport.shortDate"),
        oneOf: [
          {const: "1", title: "YYYY-MM-DD"},
          {const: "2", title: "YYYY/MM/DD"},
          {const: "3", title: "YYYY.MM.DD"},
          {const: "4", title: "DD/MM/YYYY"},
          {const: "5", title: "DD-MM-YYYY"},
          {const: "6", title: "MM/DD/YYYY"},
          {const: "7", title: "MM-DD-YYYY"},
          {const: "8", title: "YY-MM-DD"},
          {const: "9", title: "YY/MM/DD"},
          {const: "10", title: "DD/MM/YY"},
          {const: "11", title: "YY.MM.DD"},
          {const: "12", title: "YYYYMMDD"},
          {const: "13", title: "YYYYDDMM"},
          {const: "14", title: "MMDDYYYY"},
          {const: "15", title: "DDMMYYYY"},
          {const: "16", title: "YYMMDD"},
          {const: "17", title: "YYDDMM"},
          {const: "18", title: "MMDDYY"},
          {const: "19", title: "DDMMYY"},
        ],
        default: "1",
      },
      short_time: {
        type: "string",
        title: t("autoExport.shortTime"),
        oneOf: [
          {const: "1", title: "HH:MM"},
          {const: "2", title: "H:MM"},
          {const: "3", title: "HH:MM TT"},
          {const: "4", title: "H:MM TT"},
          {const: "5", title: "HH:MM:SS"},
          {const: "6", title: "H:MM:SS"},
          {const: "7", title: "HH:MM:SS TT"},
          {const: "8", title: "H:MM:SS TT"},
          {const: "9", title: "HHMM"},
          {const: "10", title: "HMM"},
          {const: "11", title: "HHMMSS"},
          {const: "12", title: "HMMSS"},
        ],
        default: "1",
      },
      pin_digit: {
        type: "integer",
        title: t("autoExport.pinDigits"),
        default: 0,
      },
      export_format: {
        type: "string",
        title: t("autoExport.exportFormat"),
        oneOf: [
          {const: "xlsx", title: common("common.fileFormat.xlsx")},
          {const: "csv", title: common("common.fileFormat.csv")},
          {const: "txt", title: common("common.fileFormat.txt")},
        ],
        default: "txt",
      },
      query_field: {
        type: "integer",
        title: t("autoExport.queryField"),
        oneOf: [
          {const: 1, title: t("autoExport.uploadTime")},
          {const: 2, title: t("autoExport.punchTime")},
        ],
        default: 1,
      },
      end_day: {
        type: "integer",
        title: t("autoExport.endDay"),
        oneOf: [
          {const: 0, title: common("boolean.no")},
          {const: 1, title: common("boolean.yes")},
        ],
        default: 1,
      },
      department: {
        type: ["array", "null"],
        items: {
          type: "number",
        },
        title: t("autoExport.department"),
      },
      area: {
        type: ["array", "null"],
        items: {
          type: "number",
        },
        title: t("autoExport.area"),
      },
      export_interval: {
        type: "integer",
        title: t("autoExport.exportInterval"),
        default: 1,
      },
      export_time: {
        type: "string",
        title: t("autoExport.exportTime"),
        default: "00:01",
      },
      frequency: {
        type: "integer",
        title: t("autoExport.frequency"),
        oneOf: [
          {const: 0, title: t("frequency.daily")},
          {const: 1, title: t("frequency.weekly")},
          {const: 2, title: t("frequency.monthly")},
        ],
        default: 0,
      },
      export_path: {
        type: "string",
        title: t("autoExport.exportPath"),
        default: "AutoExport",
      },
      export_email: {
        type: ["string", "null"],
        title: t("autoExport.email"),
      },
    },
    required: [
      "task_code",
      "task_name",
      "file_prefix",
      "data_template",
      "short_date",
      "short_time",
      "pin_digit",
      "export_format",
      "query_field",
      "end_day",
      "export_interval",
      "export_time",
      "frequency",
    ],
    dependencies: {
      frequency: {
        oneOf: [
          {
            properties: {
              frequency: {enum: [0]},
              day: {
                $ref: "#/definitions/day",
                oneOf: [{const: 1, title: "1"}],
              },
            },
          },
          {
            properties: {
              frequency: {enum: [1]},
              day: {
                $ref: "#/definitions/day",
                oneOf: weekDays,
              },
            },
          },
          {
            properties: {
              frequency: {enum: [2]},
              day: {
                $ref: "#/definitions/day",
                oneOf: monthDays,
              },
            },
          },
        ],
        required: ["day"],
      },
    },
  };
  const uiSchema: UiSchema = {
    data_template: {
      "ui:widget": "data_template",
      "ui:options": {
        required: true,
      },
    },
    export_time: {
      "ui:widget": "time",
      "ui:options": {
        required: true,
      },
    },
    department: {
      "ui:widget": "m2m",
      "ui:options": {
        uri: PsnlEP.DEPARTMENT_URI,
      },
    },
    area: {
      "ui:widget": "m2m",
      "ui:options": {
        uri: PsnlEP.AREA_URI,
      },
    },
  };

  const widgets = {
    data_template: DataTemplateWidget,
  };
  const templates = {
    ObjectFieldTemplate: LayoutTemplate,
  };
  return (
    <RecordView
      formSchema={formSchema}
      uiSchema={uiSchema}
      widgets={widgets}
      uri={AuthEP.AUTO_EXPORT_URI}
      templates={templates}
    />
  );
};
export default AutoExportRecordView;

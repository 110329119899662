import {ArrayFieldTemplateProps} from "@rjsf/utils";
import Grid from "@mui/material/Grid2";
import {Button, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import OutdoorScheduleItemTemplate from "@/pages/GPS/schedule-planner/components/OutdoorScheduleItemTemplate";

const  OutdoorScheduleTemplate = (props: ArrayFieldTemplateProps) => {
  const {t: common} = useTranslation("common")
  const {title, items, onAddClick} = props
  return (
    <Grid container>
      <Grid size={12} sx={{display: "flex"}}>
        <Grid sx={{flex: 1}}>
          <Typography variant={"h4"}>{title}</Typography>
        </Grid>
        <Grid>
          <Button variant={"contained"} onClick={onAddClick}>
            {common("common.action.add")}
          </Button>
        </Grid>
      </Grid>
      {items.map((item) =>
        <Grid size={12}>
          <OutdoorScheduleItemTemplate {...item} />
        </Grid>
      )}
    </Grid>
  )
}
export default OutdoorScheduleTemplate

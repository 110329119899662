import {useTranslation} from "react-i18next";
import {GridColDef, GridRenderCellParams} from "@mui/x-data-grid";
import urls from "@/urls";
import {ReportEndPoint} from "@/services/report/endpoints";
import ReportView from "@/pages/Report/ReportLibrary/components/ReportView";
import {useEffect, useState} from "react";
import GenericService from "@/services/core/GenericService";
import {AttEndPoint} from "@/services/att/endpoints";
import { useReportEmployee } from "@/hooks/employee";


const MonthlyStatusSummaryReport = () => {
  const {t} = useTranslation("report")
  const employeeFields = useReportEmployee()
  const fixedColumns: GridColDef[] = [
    ...employeeFields,
    { field: "present", headerName: "P"},
    { field: "total_absent", headerName: "A"},
    { field: "total_holiday_times", headerName: "HL"},
    { field: "total_holiday_presen", headerName: "HP"},
    { field: "total_week_off", headerName: "WO"},
    { field: "total_week_off_present", headerName: "WOP"},
  ]

  const otherColumns: GridColDef[] = [
    { field: "total_leave", headerName: t("report.column.totalLeaveTime")},
    { field: "total_present", headerName: t("report.column.totalPresent")},
  ]

  const [leaveColumns, setLeaveColumns] = useState<GridColDef[]>([])
  const [columns, setColumns] = useState<GridColDef[]>(fixedColumns)

  useEffect(() => {
    GenericService.list(AttEndPoint.LEAVE_TYPE_URI, {
      page: 1,
      page_size: 50,
    })
      .then((response) => {
        const leaves: any = []
        response.data.data.forEach((each: any) => {
          leaves.push({field: `leave_${each.id}`, headerName: `${each.display_name}`})
        })
        setLeaveColumns(leaves)
      })
  }, [])

  useEffect(() => {
    setColumns((old) => {
      return [
        ...fixedColumns,
        ...leaveColumns,
        ...otherColumns,
      ]
    })
  }, [leaveColumns])

  return (
    <ReportView
      title={t("reportLibrary.statusSummary")}
      breadcrumbs={[]}
      columns={columns}
      uri={ReportEndPoint.MONTHLY_STATUS_SUMMARY_URI}
      url={urls.report.monthlyStatusSummary}
      getRowId={(row: any) => row.employee_code}
      filterType={'month'}
      wiseFields={[]}
      hideColumns={['first_name', 'last_name', 'department_code', 'position_code', 'position_name']}
    />
  )
}
export default MonthlyStatusSummaryReport

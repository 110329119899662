import {useTranslation} from "react-i18next";
import {GridColDef, GridRenderCellParams} from "@mui/x-data-grid";
import ListView from "@/components/views/ListView";
import AppUrls from "@/urls";
import {AccessControlEndPoint} from "@/services/acc/endpoints";
import SearchPanel from "@/pages/AccessControl/components/SearchPanel";
import {Switch} from "@mui/material";
import ApprovalIcon from "@mui/icons-material/Approval";
import SyncAltIcon from '@mui/icons-material/SyncAlt';
import {AppSettingsAlt} from "@mui/icons-material";
import GroupCloneAction from "@/pages/AccessControl/group/components/CloneAction";
import TransferAction from "@/pages/AccessControl/group/components/TransferAction";
import { useVerifyModesList } from "../options";


const GroupListView = () => {
  const {t} = useTranslation("acc")

  const renderIncludeHoliday = (params: GridRenderCellParams) => {
    return <Switch checked={params.value}/>
  }

  const columns: GridColDef[] = [
    {field: "group_no", headerName: t("group.code"), flex: 1},
    {field: "group_name", headerName: t("group.name"), flex: 1},
    {field: "verify_mode", headerName: t("group.verifyMode"), flex: 1,
      type: "singleSelect",
      valueOptions: useVerifyModesList()
    },
    {field: "timezone1", headerName: t("group.timePeriod1"), width: 120},
    {field: "timezone2", headerName: t("group.timePeriod2"), width: 120},
    {field: "timezone3", headerName: t("group.timePeriod3"), width: 120},
    {field: "is_include_holiday", headerName: t("group.includeHoliday"), width: 120, renderCell: renderIncludeHoliday},
  ]

  const filters = [
    {key: 'group_name', label: t("group.name"), type: 'string'},
  ]

  const modelActions = [
    {
      id: 'groupMenu', icon: <AppSettingsAlt/>, title: t("group.menu"), subs:[
        {
          id: "groupClone",
          title: t("data.clone"),
          icon: <ApprovalIcon/>,
          validateSelected: true,
          display: "dialog",
          Form: GroupCloneAction,
        },
        {
          id: "transferGroupEmployee",
          title: t("group.personnelTransfer"),
          icon: <SyncAltIcon/>,
          validateSelected: true,
          display: "dialog",
          Form: TransferAction,
        }
      ]
    }
  ];

  return (
    <ListView
      title={t("menu.acc.group", {ns: "menu"})}
      breadcrumbs={[
        {title: t("menu.accessControl", {ns: "menu"}), href: AppUrls.acc.root},
      ]}
      columns={columns}
      uri={AccessControlEndPoint.GROUP_URI}
      url={AppUrls.acc.group}
      filters={filters}
      SearchPanel={SearchPanel}
      modelActions={modelActions}
    />
  )
}
export default GroupListView

import {Box, CircularProgress, Typography} from "@mui/material";
import {useEffect, useState} from "react";
import {useNavigate, useSearchParams} from "react-router-dom";
import urls from "@/urls";
import SnackbarUtil from "@/utils/SnackbarUtil";
import OauthService from "@/services/oauth/OauthService";
import {useTranslation} from "react-i18next";


const JoinApproval = () => {
  const {t} = useTranslation("oauth")
  const {t: api} = useTranslation("api")
  const navigate = useNavigate()
  const [status, setStatus] = useState<string | null>()
  const [search, setSearch] = useSearchParams()
  const verifyCode = search.get("code")
  const onJoinApproval = async () => {
    OauthService.joinApproval({code: verifyCode}).then((response) => {
      if (response.isSuccess) {
        setStatus(t("common.message.successful", {ns: "common"}))
      } else {
        setStatus(api(response.code))
      }
    })
  }
  useEffect(() => {
    if (!verifyCode) {
      SnackbarUtil.error(api("ZBOA0106"))
      navigate(urls.companyVerification)
    }else{
      onJoinApproval().then()
    }
  }, [])
  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        justifyContent: "center"
      }}
    >
      {!status
        ? <CircularProgress/>
        : <Typography>{status}</Typography>
      }
    </Box>
  )
}
export default JoinApproval

import {ObjectFieldTemplateProps} from "@rjsf/utils";
import {useTranslation} from "react-i18next";
import {ScrollTab, ScrollTabs, useScrollTabsHook} from "@/components/ScrollTabs";
import Grid from "@mui/material/Grid2";
import {Typography} from "@mui/material";

const LayoutTemplate = (props: ObjectFieldTemplateProps) => {
  const {t} = useTranslation("device");
  const fields: Record<string, any> = {};
  props.properties?.forEach((element: any) => {
    fields[element.name] = element.content;
  });

  const [tabs, getTabProps] = useScrollTabsHook([
    {key: 'devCommSetting', title: t("devConfiguration.devCommSetting")},
    {key: 'bioPhotoSetting', title: t("devConfiguration.bioPhotoSetting")},
    {key: 'dataClearSetting', title: t("devConfiguration.dataClearSetting")},
  ])

  return (
    <ScrollTabs tabs={tabs}>
      <Grid container spacing={3}>
        <Grid size={8}>
          <ScrollTab {...getTabProps('devCommSetting')} >
            <Grid container spacing={2}>
              <Grid size={6}>{fields.enable_registration}</Grid>
              <Grid size={6} />
              <Grid size={6}>{fields.enable_resigned_filter}</Grid>
              <Grid size={6}>{fields.enable_synchronization_data}</Grid>
              <Grid size={6}>{fields.enable_auto_add}</Grid>
              <Grid size={6}>{fields.enable_employee_upload}</Grid>
              <Grid size={6}>{fields.enable_name_upload}</Grid>
              <Grid size={6}>{fields.enable_card_upload}</Grid>
            </Grid>
          </ScrollTab>
          <ScrollTab {...getTabProps('bioPhotoSetting')} >
            <Grid container spacing={2}>
              <Grid size={6}>{fields.edit_policy}</Grid>
              <Grid size={6}>{fields.import_policy}</Grid>
              <Grid size={6}>{fields.mobile_policy}</Grid>
              <Grid size={6}>{fields.device_policy}</Grid>
            </Grid>
          </ScrollTab>
          <ScrollTab {...getTabProps('dataClearSetting')} >
            <Typography>{t("devConfiguration.dataClearSetting.notice")}</Typography>
            <Grid container spacing={2}>
              <Grid size={6}>{fields.transaction_retention}</Grid>
              <Grid size={6}>{fields.command_retention}</Grid>
              <Grid size={6}>{fields.dev_log_retention}</Grid>
              <Grid size={6}>{fields.upload_log_retention}</Grid>
            </Grid>
          </ScrollTab>
        </Grid>
      </Grid>
    </ScrollTabs>
  );
};
export default LayoutTemplate;

import {useTranslation} from "react-i18next";
import React, {ForwardedRef, useImperativeHandle, useRef} from "react";
import {RJSFSchema, UiSchema} from "@rjsf/utils";
import SnackbarUtil from "@/utils/SnackbarUtil";
import LayoutForm from "@/components/rjsf/Form";
import validator from "@rjsf/validator-ajv8";
import DeviceService from "@/services/acc/DeviceService";


const SyncSetup = (props: Record<string, any>, ref: ForwardedRef<any>) => {
  const {t} = useTranslation("acc")
  const formRef = useRef<any>()
  const formSchema: RJSFSchema = {
    type: "object",
    properties: {
      timezone: {
        type: "boolean",
        title: t("timePeriod"),
        default: false
      },
      holiday: {
        type: "boolean",
        title: t("holiday"),
        default: false
      },
      group: {
        type: "boolean",
        title: t("group"),
        default: false
      },
      combination: {
        type: "boolean",
        title: t("combination"),
        default: false
      },
      privilege: {
        type: "boolean",
        title: t("privilege"),
        default: false
      },
    }
  }

  const uiSchema: UiSchema = {
    "ui:defaultXsWidth": 6,
    "ui:defaultRowWrap": false,
    timezone: {
      "ui:widget": "switch",
    },
    holiday: {
      "ui:widget": "switch",
    },
    group: {
      "ui:widget": "switch",
    },
    combination: {
      "ui:widget": "switch",
    },
    privilege: {
      "ui:widget": "switch",
    },
  }

  const onConfirm = async () => {
    const isValid = formRef.current?.validateForm();
    if (isValid) {
      const selectedIds = props.selectedIds;
      const data = formRef.current?.state.formData;
      const response = await DeviceService.syncSetup({
        objects: selectedIds,
        ...data,
      });
      if (response.isSuccess) {
        SnackbarUtil.success(t("common.successful"));
        return true;
      } else {
        console.log(response);
      }
    }
  }

  useImperativeHandle(ref, () => ({
    onConfirm,
  }))

  return (
    <LayoutForm
      ref={formRef}
      schema={formSchema}
      uiSchema={uiSchema}
      validator={validator}
    />
  )
}
export default React.forwardRef(SyncSetup)

import ListView from "@/components/views/ListView";
import {GridColDef} from "@mui/x-data-grid";
import AppUrls from "@/urls";
import ResignAction from "./components/ResignAction";
import {useTranslation} from "react-i18next";
import {SyncAlt,MoreVertOutlined}from '@mui/icons-material';
import AppsIcon from '@mui/icons-material/Apps'
import {PsnlEP} from "@/services/psnl/endpoints";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import TransferDepartmentAction from "@/pages/Team/Employee/components/TransferDepartmentAction";
import TransferPositionAction from "@/pages/Team/Employee/components/TransferPositionAction";
import TransferHolidayAction from "@/pages/Team/Employee/components/TransferHolidayAction";
import MoveToNewAreaAction from "@/pages/Team/Employee/components/MoveToNewAreaAction";
import CopyToNewAreaAction from "@/pages/Team/Employee/components/CopyToNewAreaAction";
import DeviceService from "@/services/device/DeviceService";
import SnackbarUtil from "@/utils/SnackbarUtil";
import ReloadFromDevice from "@/pages/Team/Employee/components/ReloadFromDevice";
import DeleteBiometric from "@/pages/Team/Employee/components/DeleteBiometric";
import EmployeeService from "@/services/psnl/EmployeeService";
import EnableAppAction from "@/pages/Team/Employee/components/EnableAppAction";
import DisabelAppAction from "@/pages/Team/Employee/components/DisableAppAction";


const EmployeeListView = () => {
  const {t} = useTranslation("per")
  const displayName = (value: any) => value.display_name
  const columns: GridColDef[] = [
    {field: "emp_code", headerName: t("employee.code"), flex: 1},
    {field: "first_name", headerName: t("employee.firstName"), flex: 1},
    {field: "department", valueFormatter: displayName, headerName: t("employee.department"), flex: 1},
    {field: "position", valueFormatter: displayName, headerName: t("employee.position"), flex: 1},
    {
      field: "area", valueFormatter: (value: any) => {
        return value.map((each: any) => each.display_name)
      }, headerName: t("employee.area"), flex: 1
    },
  ]

  const filters = [
    {key: 'emp_code', label: t("employee.code"), type: 'string'},
    {key: 'first_name', label: t("employee.firstName"), type: 'string'},
    // {key: 'department__dept_code', label: t("department.code"), type: 'string'},
    {key: 'department__dept_name', label: t("department.name", {ns: 'company'}), type: 'string'},
    // {key: 'area__area_code', label: t("area.code"), type: 'string'},
    {key: 'area__area_name', label: t("area.name", {ns: 'company'}), type: 'string'},
    // {key: 'position__position_code', label: t("position.code"), type: 'string'},
    {key: 'position__position_name', label: t("position.name", {ns: 'company'}), type: 'string'},
    {key: 'hire_date', label: t("employee.joinedDate"), type: 'date'},
    {key: 'birthday', label: t("employee.birthDate", {ns: 'per'}), type: 'date'},
    {
      key: 'emp_type', label: t("employee.employmentType", {ns: 'per'}), type: 'select',
      select_type: 'number',
      select_options: [
        {const: 1, title: t("employmentType.official", {ns: 'per'})},
        {const: 2, title: t("employmentType.temporary", {ns: 'per'})},
      ],
    },
  ]

  const PassProbationtAction = async (selectedIds: string) => {
    const response = await EmployeeService.PassProbation({ids: selectedIds});
    if (response.isSuccess) {
      SnackbarUtil.success(t("common.successful", {ns: "common"}))
      return true
    } else {
      console.log(response)
    }
  }

  const RsyncToDevice = async (selectedIds: string) => {
    const response = await EmployeeService.RsyncToDevice({ids: selectedIds});
    if (response.isSuccess) {
      SnackbarUtil.success("Success");
      return true;
    }
    SnackbarUtil.success(response.code);
    return false;
  };

  const actions = [
    {
      key: "transfer", title: t("employee.transfer"), icon: <SyncAlt/>, subs: [
        {
          key: "transferDepartment", title: t("employee.transferDepartment"), validateSelected: true,
          display: "dialog", Form: TransferDepartmentAction
        },
        {
          key: "transferPosition", title: t("employee.transferPosition"), validateSelected: true,
          display: "dialog", Form: TransferPositionAction
        },
        {
          key: "moveToNewArea", title: t("employee.moveToNewArea"), validateSelected: true,
          display: "dialog", Form: MoveToNewAreaAction
        },
        {
          key: "copyToNewArea", title: t("employee.copyToNewArea"), validateSelected: true,
          display: "dialog", Form: CopyToNewAreaAction
        },
        {
          key: "transferHolidayLocation", title: t("employee.transferHoliday"), validateSelected: true,
          display: "dialog", Form: TransferHolidayAction
        },
        {
          id: "passProbation", title: t("employee.passProbation"),
          display: "dialog",
          validateSelected: true,
          confirmation: t("employee.passProbation.confirm"),
          callback: PassProbationtAction
        },
        {
          key: "resign", title: t("employee.resignation"), validateSelected: true,
          display: "dialog", Form: ResignAction
        },
      ]
    },
    {
      key: "app", title: t("employee.app"), icon: <AppsIcon/>, subs: [
        {
          key: "enableApp", title: t("employee.app.enable"), validateSelected: true,
          display: "dialog", Form: EnableAppAction
        },
        {
          key: "disableApp", title: t("employee.app.disable"), validateSelected: true,
          display: "dialog", Form: DisabelAppAction
        },
      ]
    },
    {
      id: "import", title: t("common.action.import", {ns: "common"}), icon: <FileUploadOutlinedIcon/>,
      display: "page", url: `${AppUrls.team.employee}/import`,
    },
    {
      key: "moreActions", title: t("employee.moreActions"), icon: <MoreVertOutlined/>, subs: [
        {
          key: "rsyncToDevice", title: t("employee.rsyncToDevice"), validateSelected: true,
          display: "dialog",
          confirmation: t("employee.rsyncToDevice.confirm"),
          callback: RsyncToDevice
        },
        {
          key: "reloadFromDevice", title: t("employee.reloadFromDevice"), validateSelected: true,
          display: "dialog",
          Form: ReloadFromDevice
        },
        {
          key: "deleteBiometric", title: t("employee.deleteBiometric"), validateSelected: true,
          display: "dialog",
          Form: DeleteBiometric
        },
      ]
    },
  ]

  return (
    <ListView
      title={t("menu.team.employee", {ns: "menu"})}
      columns={columns}
      uri={PsnlEP.EMPLOYEE_URI}
      url={AppUrls.team.employee}
      filters={filters}
      modelActions={actions}
    />
  )
}
export default EmployeeListView

import {RJSFSchema, WidgetProps} from "@rjsf/utils";
import React, {useEffect, useRef, useState} from "react";
import validator from "@rjsf/validator-ajv8";
import LayoutForm from "@/components/rjsf/Form";
import EnableWidget from "@/pages/Attendance/GlobalRule/components/EnableWidget";
import {useTranslation} from "react-i18next";


const WeekOffWidget = (props: WidgetProps) => {
  const {t} = useTranslation("att")
  const {label, value, onChange} = props
  const formData = value?.length > 0 ? value[0] : {
    enable: false,
    policy: 0,
    lv1: 0,
    lv2: 0,
    lv3: 0,
  }
  const formRef = useRef()
  const formSchema: RJSFSchema = {
    type: "object",
    properties: {
      enable: {
        type: "boolean",
        title: t("workRule.weekOffSettings.enable")
      },
      policy: {
        type: "integer",
        title: t("workRule.weekOffSettings.policy"),
        oneOf: [
          {const: 0, title: t("workRule.weekOffSettings.ignore")},
          {const: 1, title: t("workRule.weekOffSettings.calcAsNormalWork")},
          {const: 2, title: t("workRule.weekOffSettings.calcAsNormalOT")},
          {const: 3, title: t("workRule.weekOffSettings.calcAsWeekOffOT")},
          {const: 4, title: t("workRule.weekOffSettings.calcAsHolidayOT")},
        ],
        default: 0
      },
      lv1: {
        type: "integer",
        title: t("workRule.weekOffSettings.lv1"),
        default: 0
      },
      lv2: {
        type: "integer",
        title: t("workRule.weekOffSettings.lv2"),
        default: 0
      },
      lv3: {
        type: "integer",
        title: t("workRule.weekOffSettings.lv3"),
        default: 0
      }
    },
    required: ["enable"]
  }
  const uiSchema = {
    "ui:defaultXsWidth": 3,
    "ui:defaultRowWrap": false,
    enable: {
      "ui:xsWidth": 12,
      "ui:widget": "enable"
    },
    lv1: {
      "ui:widget": "hour"
    },
    lv2: {
      "ui:widget": "hour"
    },
    lv3: {
      "ui:widget": "hour"
    }
  }
  const widgets = {
    enable: EnableWidget
  }
  const templates = {}
  return (
    <LayoutForm
      ref={formRef}
      schema={formSchema}
      uiSchema={uiSchema}
      widgets={widgets}
      templates={templates}
      formData={formData}
      validator={validator}
      onChange={(event, id) => {
        onChange([event.formData])
      }}
    />
  )
}
export default WeekOffWidget

import {useTranslation} from "react-i18next";
import {GridColDef, GridRenderCellParams} from "@mui/x-data-grid";
import ListView from "@/components/views/ListView";
import AppUrls from "@/urls";
import {DeviceEndPoint} from "@/services/device/endpoints";
import AddArea from "@/pages/Device/Device/components/AddArea";
import {
  AddLocationAltOutlined,
  AppSettingsAlt, CancelOutlined,
  CheckCircleOutlined,
  CloudSync,
  DoNotDisturbAltOutlined, InfoOutlined, SyncOutlined, UploadOutlined
} from "@mui/icons-material";
import UploadUserData from "@/pages/Device/Device/components/UploadUserData";
import UploadTransaction from "@/pages/Device/Device/components/UploadTransaction";
import SyncData2Device from "@/pages/Device/Device/components/SyncData2Device";
import EnrollRemotelyAction from "@/pages/Device/Device/components/EnrollRemotelyAction";
import UpgradeFirmwareAction from "@/pages/Device/Device/components/UpgradeFirmwareAction";
import CaptureAction from "@/pages/Device/Device/components/CaptureAction";
import DuplicatePunchPeriodAction from "@/pages/Device/Device/components/DuplicatePunchPeriodAction";
import DeviceService from "@/services/device/DeviceService";
import SnackbarUtil from "@/utils/SnackbarUtil";
import DaylightSavingTimeAction from "@/pages/Device/Device/components/DaylightSavingTimeAction";
import ClearAllIcon from "@mui/icons-material/ClearAll";
import ClearIcon from "@mui/icons-material/Clear";
import ClearPunchesAction from "@/pages/Device/Device/components/ClearPunchesAction";
import {Link} from "@mui/material";
import {useDialogContext} from "@/provider/DialogProvider";
import AreaEmployeeView from "@/pages/Device/Device/components/AreaEmployeeView";


const DeviceListView = () => {
  const {t: t} = useTranslation("device");
  const {t: common} = useTranslation("common");
  const {t: menu} = useTranslation("menu");
  const dialog = useDialogContext()

  const deviceStateRender = (params: GridRenderCellParams) => {
      switch (params.value) {
        case 0:
        return <DoNotDisturbAltOutlined color={"error"}/>
      case 1:
        return <CheckCircleOutlined sx={{color: "green", height: "48px"}}/>
      case 2:
        return <SyncOutlined sx={{color: "green", height: "48px"}}/>
      case 3:
        return <CancelOutlined color={"error"}/>
      case 4:
        return <UploadOutlined sx={{color: "green", height: "48px"}}/>
      default:
        return <InfoOutlined color={"warning"}/>
      }
  }

  const renderArea = (params: GridRenderCellParams) => {
    const onView = async ()=> {
      dialog.showDialog({
        title: "",
        disableAction: true,
        content: <AreaEmployeeView area={params.row.area}/>
      })
    }
    return (
      <Link component={"button"} onClick={onView}>{params.value}</Link>
    )
  }

  const columns: GridColDef[] = [
    {field: "sn", headerName: t("device.sn"), flex: 1},
    {field: "alias", headerName: t("device.alias"), flex: 1},
    {field: "area_name", headerName: t("device.area"), flex: 1, renderCell: renderArea},
    {field: "ip_address", headerName: t("device.deviceIp"), flex: 1},
    {field: "real_status", headerName: t("device.state"), flex: 1, renderCell: deviceStateRender},
    {field: "last_activity", headerName: t("device.lastActivity"), flex: 1},
    {field: "user_count", headerName: t("device.userCount"), flex: 1},
    {field: "fp_count", headerName: t("device.fingerprintCount"), flex: 1},
    {field: "face_count", headerName: t("device.faceCount"), flex: 1},
    {field: "palm_count", headerName: t("device.palmCount"), flex: 1},
    {field: "transaction_count", headerName: t("device.transactionCount"), flex: 1},
    {field: "command_count", headerName: t("device.commandCount"), flex: 1}
  ]

  const filters = [
    {key: 'sn', label: t("device.sn"), type: 'string'},
    {key: 'alias', label: t("device.alias"), type: 'string'},
    {key: 'area', label: t("device.area"), type: 'string'},
    // {key: 'user_count', label: t("device.userCount"), type: 'number'},
    // {key: 'fp_count', label: t("device.fingerprintCount"), type: 'number'},
    // {key: 'face_count', label: t("device.faceCount"), type: 'number'},
    // {key: 'palm_count', label: t("device.palmCount"), type: 'number'},
    // {key: 'transaction_count', label: t("device.transactionCount"), type: 'number'},
    // {key: 'real_status', label: t("device.state"), type: 'number'},
  ]

  const uiSchema = {
    real_status: {
      "ui:widget": "html"
    }
  }

  const RebootAction = async (selectedIds: string) => {
    const response = await DeviceService.Reboot({ids: selectedIds});
    if (response.isSuccess) {
      SnackbarUtil.success(common("common.message.successful"))
      return true
    }
  }

  const InfoAction = async (selectedIds: string) => {
    const response = await DeviceService.Info({ids: selectedIds});
    if (response.isSuccess) {
      SnackbarUtil.success(common("common.message.successful"))
      return true
    }
  }

  const BulkClearCommand = async (selectedIds: string) => {
    const response = await DeviceService.bulkClearCommand({ids: selectedIds});
    if (response.isSuccess) {
      SnackbarUtil.success(common("common.message.successful"))
      return true;
    }
    return false;
  };

  const BulkClearData = async (selectedIds: string) => {
    const response = await DeviceService.bulkClearData({ids: selectedIds});
    if (response.isSuccess) {
      SnackbarUtil.success(common("common.message.successful"))
      return true;
    }
    return false;
  };

  const BulkClearCapture = async (selectedIds: string) => {
    const response = await DeviceService.bulkClearCapture({ids: selectedIds});
    if (response.isSuccess) {
      SnackbarUtil.success(common("common.message.successful"))
      return true;
    }
    return false;
  };

  const modelActions = [
    {
      id: "addArea",
      icon: <AddLocationAltOutlined/>,
      title: t("device.addArea"),
      display: "dialog",
      Form: AddArea
    },
    {
      id: "clearDeviceCommand",
      icon: <ClearAllIcon/>,
      title: t("device.clearCommand"),
      display: "dialog",
      confirmation: t("deviceCommand.bulkDeleteAction.confirm"),
      validateSelected: true,
      callback: BulkClearCommand
    },
    {
      id: "sync", icon: <CloudSync/>, title: t("device.dataSync"), subs: [
        {
          id: "uploadUserData",
          title: t("device.uploadUserData"),
          display: "dialog",
          Form: UploadUserData,
          validateSelected: true
        },
        {
          id: "uploadTransaction",
          title: t("device.uploadTransaction"),
          display: "dialog",
          Form: UploadTransaction,
          validateSelected: true
        },
        {
          id: "syncData2Device",
          title: t("device.syncData2Device"),
          display: "dialog",
          Form: SyncData2Device,
          validateSelected: true
        },
      ]
    },
    {
      id: "clearData", icon: <ClearIcon/>, title: t("device.clear"), subs: [
        {
          id: "clearPunches",
          title: t("device.clearPunches"),
          validateSelected: true,
          display: "dialog",
          Form: ClearPunchesAction
        },
        {
          id: "clearCapture", title: t("device.clearCapture"),
          display: "dialog",
          validateSelected: true,
          confirmation: t("device.clearCapture.confirm"),
          callback: BulkClearCapture
        },
        {
          id: "clearAll", title: t("device.clearAll"),
          display: "dialog",
          validateSelected: true,
          confirmation: t("device.clearAll.confirm"),
          callback: BulkClearData
        },
      ]
    },
    {
      id: "settings", icon: <AppSettingsAlt/>, title: t("device.deviceSetting"), subs: [
        {
          id: "reboot",
          title: t("device.reboot"),
          display: "dialog",
          confirmation: t("device.reboot.confirmation"),
          validateSelected: true,
          callback: RebootAction,
        },
        {
          id: "info",
          title: t("device.info"),
          validateSelected: true,
          confirmation: t("device.info.confirmation"),
          callback: InfoAction,
          display: "dialog"
        },
        {
          id: "enrollRemotely", title: t("device.enrollRemotely"),
          validateSelected: true,
          uniqueObjectRequired: true,
          display: "dialog", Form: EnrollRemotelyAction
        },
        {
          id: "duplicatePunchPeriod",
          title: t("device.duplicatePunchPeriod"),
          validateSelected: true,
          display: "dialog",
          Form: DuplicatePunchPeriodAction
        },
        {
          id: "captureSetting",
          title: t("device.capture"),
          validateSelected: true,
          display: "dialog",
          Form: CaptureAction
        },
        {
          id: "upgradeFirmware",
          title: t("device.upgradeFirmware"),
          validateSelected: true,
          display: "dialog",
          Form: UpgradeFirmwareAction
        },
        {
          id: "daylightSavingTime",
          title: t("device.daylightSavingTime"),
          validateSelected: true,
          display: "dialog",
          Form: DaylightSavingTimeAction
        },
      ]
    },
  ]

  return (
    <ListView
      title={menu("menu.device")}
      breadcrumbs={[
        {title: menu("menu.device"), href: AppUrls.device.root},
      ]}
      columns={columns}
      uri={DeviceEndPoint.TERMINAL_URI}
      url={AppUrls.device.terminal}
      filters={filters}
      modelActions={modelActions}
    />
  )
}
export default DeviceListView

import {useTranslation} from "react-i18next";
import {CustomValidator, RJSFSchema} from "@rjsf/utils";
import {AttEndPoint} from "@/services/att/endpoints";
import {PsnlEP} from "@/services/psnl/endpoints";
import TemporaryScheduleLayout from "@/pages/Attendance/temporary_schedule/components/TemporaryScheduleLayout";
import ShiftsWidget from "@/pages/Attendance/temporary_schedule/components/ShiftsWidget";
import SnackbarUtil from "@/utils/SnackbarUtil";
import ModelActionPage from "@/components/views/ListView/components/model-action/ModelActionPage";


const AddTemporarySchedule = () => {
  const {t} = useTranslation("att");
  const formSchema: RJSFSchema = {
    type: "object",
    properties: {
      employees: {
        type: "array",
        title: t("temporarySchedule.employee"),
        items: {
          type: "string"
        }
      },
      work_type: {
        type: "integer",
        title: t("temporarySchedule.workType"),
        oneOf: [
          {const: 0, title: t("workType.normalWorkTime")},
          {const: 1, title: t("workType.weekdayOvertime")},
          {const: 2, title: t("workType.weekOffOvertime")},
          {const: 3, title: t("workType.holidayOvertime")},
        ],
        default: 0
      },
      rule_flag: {
        type: "integer",
        title: t("temporarySchedule.scheduleType"),
        oneOf: [
          {const: 1, title: t("scheduleType.only")},
          {const: 2, title: t("scheduleType.additional")},
        ],
        default: 1
      },
      shifts: {
        type: "array",
        items: {
          type: "object",
          properties: {
            day: {
              type: "string"
            },
            timetable: {
              type: "integer"
            },
          }
        }
      },
    },
    required: ["employees", "work_type", "rule_flag"]
  }

  const uiSchema = {
    employees: {
      "ui:xsWidth": 8,
      "ui:widget": "m2m",
      "ui:options": {
        uri: PsnlEP.EMPLOYEE_URI
      }
    },
    shifts: {
      "ui:widget": "shifts"
    }
  }

  const customValidate: CustomValidator = (formData, errors, uiSchema) => {
    if(formData.shifts.length === 0){
      SnackbarUtil.error(t("temporarySchedule.error.noTimetableAssigment"))
      errors.shifts?.addError("")
    }
    return errors
  }

  const widgets = {
    shifts: ShiftsWidget
  }

  const templates = {
    ObjectFieldTemplate: TemporaryScheduleLayout
  }

  return (
    <ModelActionPage
      title={t("temporarySchedule.bulkAdd")}
      formSchema={formSchema}
      uiSchema={uiSchema}
      customValidate={customValidate}
      widgets={widgets}
      templates={templates}
      uri={AttEndPoint.TEMPORARY_SCHEDULE_URI}
      action={"bulk_add_temporary_schedule"}
    />
  )
}
export default AddTemporarySchedule

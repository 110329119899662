import {TreeItem} from "@mui/lab";
import React from "react";
import {Button, Typography, Checkbox, FormControlLabel, Box} from "@mui/material";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";


const CustomTreeItem = (props: Record<string, any>) => {
  const {nodeId, searchValue, treeData, multiple, disabled, selected, onSelected} = props
  const node = treeData[nodeId]
  const label = multiple
    ? <Box sx={{display: "flex", marginLeft: '-11px'}}>
      <Checkbox
          icon={<CheckBoxOutlineBlankIcon/>}
          checkedIcon={<CheckBoxIcon/>}
          checked={selected.some((nId: string) => nId === nodeId)}
          onClick={async (e) => {
            e.stopPropagation()
          }}
          onChange={async (e, value) => {
            if (value) {
              onSelected([nodeId, ...selected])
            } else {
              onSelected([...selected].filter(nId => nId !== nodeId))
            }
            // await props.onSelectedChange?.(nodes, value)
          }}
        />
      <Typography
      sx={{alignSelf: "center"}}
    >{node.display_name}</Typography>
    </Box>
    : <Typography
      sx={{padding: "8px 0px"}}
    >{node.display_name}</Typography>
  return (
    <TreeItem
      sx={{"& .MuiTreeItem-content.Mui-focused": {
        backgroundColor: "#fff"
      }}}
      nodeId={nodeId}
      label={label}
      disabled={nodeId === disabled}
    >
      {node.children?.map((item: any) => {
        return <CustomTreeItem
          key={`treeItem${item}`}
          nodeId={item}
          searchValue={searchValue}
          treeData={treeData}
          multiple={multiple}
          disabled={disabled}
          selected={selected}
          onSelected={onSelected}
        />
      })}
    </TreeItem>
  )
}
export default CustomTreeItem

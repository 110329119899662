import { useTranslation } from "react-i18next";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import urls from "@/urls";
import { ReportEndPoint } from "@/services/report/endpoints";
import ReportView from "../ReportView";
import GenericService from "@/services/core/GenericService";
import { useEffect, useState } from "react";
import {PersonalEndPoint} from "@/services/personal/endpoints";
import { useReportEmployee } from "@/hooks/employee";

const MultipleTransactionReport = () => {
  const { t } = useTranslation("report");
  const employeeFields = useReportEmployee()
  const columns: GridColDef[] = [
    ...employeeFields,
    {
      field: "att_date",
      headerName: t("transaction.punchDate"),
      flex: 1,
    },
    {
      field: "timetable",
      headerName: t("report.timetable"),
      flex: 1,
    },
    {
      field: "total_tb_duration",
      headerName: t("report.summaryTime"),
      flex: 1,
    },
  ];

  const total_all_columns: GridColDef[] = [
    {
      field: "total_all",
      headerName: t("report.totalWorkTime"),
      flex: 1,
    },
  ];
  const [clockColumns, setClockColumns] = useState<GridColDef[]>([
    {
      field: "clock_in",
      headerName: t("report.clockIn"),
      flex: 1,
    },
    {
      field: "clock_out",
      headerName: t("report.clockOut"),
      flex: 1,
    },
    {
      field: "total_time",
      headerName: t("report.totalTime"),
      flex: 1,
    },
  ]);
  const [allColumns, setAllColumns] = useState<GridColDef[]>([
    ...columns,
    ...clockColumns,
    ...total_all_columns,
  ]);
  const refreshColumns = async (props: any) => {
    const { startDate, endDate, selected, category, setLoading } = props;
    setLoading(true);
    GenericService.action(
      PersonalEndPoint.MULTIPLE_TRANSACTION_URI,
      "refresh_columns",
      {
        start_date: startDate?.format("YYYY-MM-DD"),
        end_date: endDate?.format("YYYY-MM-DD"),
        [`${category}`]: selected,
      }
    )
      .then((response) => {
        const clock_columns: any = [];
        for (const i of JSON.parse(response.data)) {
          const i_name = i.startsWith("clock_in")
            ? t("report.clockIn")
            : i.startsWith("clock_out")
            ? t("report.clockOut")
            : t("report.summaryTime");
          clock_columns.push({ field: i, headerName: i_name, flex: 1 });
        }
        setClockColumns(clock_columns);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  useEffect(() => {
    setAllColumns([...columns, ...clockColumns, ...total_all_columns]);
  }, [clockColumns]);
  return (
    <ReportView
      title={t("reportLibrary.multiplePairPunch")}
      refreshColumns={refreshColumns}
      breadcrumbs={[]}
      columns={allColumns}
      uri={PersonalEndPoint.MULTIPLE_TRANSACTION_URI}
      url={urls.personal.report.multipleTransaction}
      getRowId={(row: any) => Math.random().toString(16).slice(2)}
      hideColumns={['first_name', 'last_name', 'department_code', 'position_code', 'position_name']}
    />
  );
};
export default MultipleTransactionReport;

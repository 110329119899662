import {useRoutes} from "react-router-dom";
import WorkCodeListView from "@/pages/complement/work_code/ListView";
import WorkCodeRecordView from "@/pages/complement/work_code/RecordView";


const WorkCodeRouter = () => {
  return useRoutes([
    {index: true, element: <WorkCodeListView />},
    {path: "add", element: <WorkCodeRecordView />},
    {path: "edit", element: <WorkCodeRecordView />},
  ])
};
export default WorkCodeRouter

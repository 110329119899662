import Grid from "@mui/material/Grid2";
import {useTranslation} from "react-i18next";
import {Button, Chip, FormLabel} from "@mui/material";
import {Stack} from "@mui/system";
import {Payment} from "@mui/icons-material";
import React from "react";
import GenericService from "@/services/core/GenericService";
import {VendorEndPoint} from "@/services/vendor/endpoints";
import SnackbarUtil from "@/utils/SnackbarUtil";


const OrderView = (props: Record<string, any>) => {
  const {t} = useTranslation('vendor')
  const {t: api} = useTranslation('api')
  const {recordId, record} = props
  const onPayment = async () => {
    GenericService.action(VendorEndPoint.SUBSCRIPTION_URI, "payment", {provider: "TELR"}, recordId).then((response) => {
      if (response.isSuccess) {
        const paymentUrl = response.data?.payment_url
        window.open(paymentUrl, '_blank', 'noopener,noreferrer');
      } else {
        return console.log(response)
      }
    })
  }

  const renderPaymentStatus = (paymentStatus: number) => {
    switch (paymentStatus) {
      case 0:
        return t("paymentStatus.manualCancel")
      case 1:
        return t("paymentStatus.timeout")
      case 2:
        return t("paymentStatus.paid")
      case 3:
        return t("paymentStatus.unpaid")
      default:
        return ""
    }
  }

  const renderFeature = (features: any[]) => {
    return (
      <Stack spacing={0.5} direction={"row"}>
        {
          features.map((feature, index) => <Chip key={`feature${index}`} color={"primary"} label={feature.name}/>)
        }
      </Stack>
    )
  }

  const showPayNowButton = record.payment_status === 3

  const items = [
    {label: t("vendor.order.number"), value: record.number},
    {label: t("vendor.order.employeeCapacity"), value: record.employees_count},
    {label: t("vendor.order.deviceCapacity"), value: record.devices_count},
    {label: t("vendor.order.mobileAppCapacity"), value: record.mobile_applications_count},
    {label: t("vendor.order.whatsappCapacity"), value: record.whatsapp_push_count},
    {label: t("vendor.order.originalPrice"), value: `$ ${record.original_price}`},
    {label: t("vendor.order.discountPrice"), value: record.discount_price},
    {label: t("vendor.order.paymentAmount"), value: `$ ${record.payment_price}`},
    {label: t("vendor.order.paymentStatus"), value: renderPaymentStatus(record.payment_status)},
    {label: t("vendor.order.feature"), value: renderFeature(record.feature)},
  ]
  return (
    <Grid
      container
      spacing={1}
      sx={{
        width: "400px",
        minHeight: "400px",
        marginTop: "-16px",
        marginBottom: "16px"
      }}
    >
      <Grid container>
        {items.map((item) => (
          <>
            <Grid size={6}>
              <FormLabel>{item.label}</FormLabel>
            </Grid>
            <Grid size={6}>{item.value}</Grid>
          </>
        ))}
      </Grid>
      {showPayNowButton && <Grid container sx={{justifyContent: "center", alignItems: "center", width: "100%"}}>
        <Button
          variant={"contained"}
          sx={{justifyContent: "center", width: "100%"}}
          startIcon={<Payment/>}
          onClick={onPayment}
        >
          {t("onlineSubscription.continueToPay", {ns: "sub"})}
        </Button>
      </Grid>}
    </Grid>
  )
}
export default OrderView

import request from "@/utils/Request";
import {PsnlEP} from "./endpoints";
import GenericService from "@/services/core/GenericService";


const Resign = async (data: Record<string, any>) => {
  return await GenericService.action(PsnlEP.EMPLOYEE_URI, 'resign/', data)
}

const TransferDepartment = async (data: Record<string, any>) => {
  return await GenericService.action(PsnlEP.EMPLOYEE_URI, 'transfer_department/', data)
}

const TransferPosition = async (data: Record<string, any>) => {
  return await GenericService.action(PsnlEP.EMPLOYEE_URI, 'transfer_position/', data)
}

const MoveToNewArea = async (data: Record<string, any>) => {
  return await GenericService.action(PsnlEP.EMPLOYEE_URI, 'move_to_new_area/', data)
}

const CopyToNewArea = async (data: Record<string, any>) => {
  return await GenericService.action(PsnlEP.EMPLOYEE_URI, 'copy_to_new_area/', data)
}

const TransferHolidayLocation = async (data: Record<string, any>) => {
  return await GenericService.action(PsnlEP.EMPLOYEE_URI, 'transfer_location/', data)
}

const PassProbation = async (data: Record<string, any>) => {
  return await GenericService.action(PsnlEP.EMPLOYEE_URI, 'pass_probation/', data)
}
const RsyncToDevice = async (data: Record<string, any>) => {
  return await GenericService.action(PsnlEP.EMPLOYEE_URI, "rsync_to_device", data)
}

const DeleteBiometric = async (data: Record<string, any>) => {
  return await GenericService.action(PsnlEP.EMPLOYEE_URI, "delete_biometric", data)
}

const ReloadFromDevice = async (data: Record<string, any>) => {
  return await GenericService.action(PsnlEP.EMPLOYEE_URI, "reload_from_device", data)
}

const EnableApp = async (data: Record<string, any>) => {
  return await GenericService.action(PsnlEP.EMPLOYEE_URI, 'enable_app/', data)
}

const DisableApp = async (data: Record<string, any>) => {
  return await GenericService.action(PsnlEP.EMPLOYEE_URI, 'disable_app/', data)
}

const EmployeeService = {
  Resign,
  TransferDepartment,
  TransferPosition,
  MoveToNewArea,
  CopyToNewArea,
  TransferHolidayLocation,
  PassProbation,
  RsyncToDevice,
  DeleteBiometric,
  ReloadFromDevice,
  EnableApp,
  DisableApp
}
export default EmployeeService

import {Badge, Box, Divider, IconButton, useTheme} from "@mui/material";
import {DatePicker, LocalizationProvider} from "@mui/x-date-pickers";
import {useTranslation} from "react-i18next";
import {Stack} from "@mui/system";
import dayjs from "dayjs";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {Search} from "@mui/icons-material";
import {useEffect, useMemo, useState} from "react";
import GenericService from "@/services/core/GenericService";
import {AttEndPoint} from "@/services/att/endpoints";
import Grid from "@mui/material/Grid2";


const RecordPanel = (props: Record<string, any>) => {
  const {record} = props
  const {t} = useTranslation("att")
  const theme = useTheme()
  const [timePeriod, setTimePeriod] = useState({
    startDate: dayjs().startOf("month"),
    endDate: dayjs().endOf("month"),
  })
  const [shifts, setShifts] = useState<any[]>([])

  const scheduleView = useMemo(() => {
    if(!record) return []
    const days = timePeriod.endDate.diff(timePeriod.startDate, "days") + 1
    const dayShifts = []
    const shiftDays = shifts.length
    const scheduleStartDate = dayjs(record.start_date)
    const scheduleEndDate = dayjs(record.end_date)
    for(let i=0; i < days; i++){
      const day = timePeriod.startDate.add(i, "days")
      if(!(day.isBefore(scheduleStartDate) || day.isAfter(scheduleEndDate))){
        const diff = day.diff(scheduleStartDate, "days")
        const index = diff % shiftDays
        dayShifts.push({
          day: day.format("MM.DD"),
          weekday: day.format("ddd"),
          shift: shifts[index]
        })
        console.log(day, diff, shiftDays, index, shifts[index])
      }
    }
    return dayShifts
  }, [timePeriod, shifts])

  useEffect(() => {
    if(!record) return
    GenericService.action(AttEndPoint.EMPLOYEE_SCHEDULE_URI, "shifts", {}, record.id).then((response) => {
      setShifts(response.data.shifts)
    })
  }, [record])
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Box sx={{
        height: "100%",
        minWidth: "256px",
        backgroundColor: "#fff"
      }}>
        <Stack
          direction={"row"}
          spacing={1}
          sx={{
            display: "flex",
            justifyContent: "center",
            p: 2
          }}
        >
          <DatePicker
            label={t("schedule.startDate")}
            value={timePeriod.startDate}
            onChange={(value) => {
              if(value){
                setTimePeriod({...timePeriod, startDate: value})
              }
            }}
            sx={{
              width: "160px",
            }}
          />
          <span>-</span>
          <DatePicker
            label={t("schedule.endDate")}
            value={timePeriod.endDate}
            onChange={(value) => {
              if(value){
                setTimePeriod({...timePeriod, endDate: value})
              }
            }}
            sx={{
              width: "160px",
            }}
          />
        </Stack>
        <Divider/>
        <Stack
          sx={{
            height: "calc(100vh - 64px - 72px - 88px)",
            overflowY: "auto"
          }}
        >
          {scheduleView?.map((shift: any) => {
            return (
              <Grid container sx={{border: "1px solid #fff"}}>
                <Grid
                  size={5}
                  sx={{
                    backgroundColor: theme.palette.primary.main,
                    color: "#fff",
                    p: 0.5,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Badge
                    badgeContent={shift.weekday}
                    sx={{
                      "& .MuiBadge-badge": {
                        top: 8,
                        right: -16
                      }
                    }}
                  >
                    {shift.day}
                  </Badge>
                </Grid>
                <Grid
                  size={7}
                  sx={{
                    p: 0.5,
                    backgroundColor: "rgba(255, 249, 217, 0.458824)",
                    color: theme.palette.secondary.main,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <span>{shift.shift.in_time}</span>
                  <span>-</span>
                  <span>{shift.shift.out_time}</span>
                </Grid>
              </Grid>
            )
          })}
        </Stack>
      </Box>
    </LocalizationProvider>
  )
}
export default RecordPanel

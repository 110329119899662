import request from "@/utils/Request";
import {DeviceEndPoint} from "@/services/device/endpoints";

const clear = async (data: Record<string, any>) => {
  const response = await request.authorizedAxios().post(`${DeviceEndPoint.COMMAND_URI}query_clear/`, {})
  return response.data;
}

const CommandService = {
  clear
}
export default CommandService

import {useTranslation} from "react-i18next";
import {GridColDef} from "@mui/x-data-grid";
import ListView from "@/components/views/ListView";
import AppUrls from "@/urls";
import {DeviceEndPoint} from "@/services/device/endpoints";

const BioTemplateListView = () => {
  const {t} = useTranslation("device");
  const {t: per} = useTranslation("per");
  const columns: GridColDef[] = [
    {field: "emp_code", headerName: per("employee.code"), flex: 1},
    {field: "first_name", headerName: per("employee.firstName"), flex: 1},
    {field: "last_name", headerName: per("employee.lastName"), flex: 1},
    {
      field: "bio_type",
      headerName: t("bioTemplate.bioType"),
      flex: 1,
    },
    {field: "bio_no", headerName: t("bioTemplate.bioNo"), flex: 1},
    {
      field: "major_ver",
      headerName: t("bioTemplate.majorVer"),
      flex: 1,
    },
    {field: "sn", headerName: t("device.sn"), flex: 1},
    {
      field: "update_time",
      headerName: t("bioTemplate.updateTime"),
      flex: 1,
    },
  ];

  const filters = [
    {
      key: "employee",
      label: t("bioTemplate.employee"),
      type: "string",
    },
  ];

  return (
    <ListView
      disableAdd
      disableEdit
      disableDelete
      title={t("menu.device.bioTemplate", {ns: "menu"})}
      columns={columns}
      uri={DeviceEndPoint.BIO_DATA_URI}
      url={AppUrls.device.bioTemplate}
      filters={filters}
    />
  );
};
export default BioTemplateListView;

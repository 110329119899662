import {useTranslation} from "react-i18next";
import {GridColDef} from "@mui/x-data-grid";
import ListView from "@/components/views/ListView";
import AppUrls from "@/urls";
import {AccessControlEndPoint} from "@/services/acc/endpoints";
import SearchPanel from "@/pages/AccessControl/components/SearchPanel";
import ApprovalIcon from "@mui/icons-material/Approval";
import TimePeriodCloneAction from "@/pages/AccessControl/time_period/components/CloneAction";


const TimePeriodListView = () => {
  const {t} = useTranslation("acc")

  const columns: GridColDef[] = [
    {field: "timezone_no", headerName: t("timePeriod.no"), flex: 1},
    {field: "timezone_name", headerName: t("timePeriod.name"), flex: 1},
  ]

  const filters = [
    {key: 'timezone_no', label: t("timePeriod.sn"), type: 'string'},
    {key: 'timezone_name', label: t("timePeriod.name"), type: 'string'},
  ]

  const modelActions = [
    {
      id: "timePeriodClone",
      title: t("data.clone"),
      icon: <ApprovalIcon />,
      validateSelected: true,
      display: "dialog",
      Form: TimePeriodCloneAction,
    },
  ];

  return (
    <ListView
      title={t("menu.acc.timePeriod", {ns: "menu"})}
      breadcrumbs={[
        {title: t("menu.accessControl", {ns: "menu"}), href: AppUrls.acc.timePeriod},
      ]}
      columns={columns}
      uri={AccessControlEndPoint.TIME_PERIOD_URI}
      url={AppUrls.acc.timePeriod}
      filters={filters}
      SearchPanel={SearchPanel}
      modelActions={modelActions}
    />
  )
}
export default TimePeriodListView

import {useRoutes} from "react-router-dom"
import Dashboard from "@/pages/Dashboard";
import MainLayout from "@/layout/MainLayout";
import TeamRouter from "@/pages/Team/router";
import DeviceManagementRouter from "@/pages/Device/router";
import AttendanceRouter from "@/pages/Attendance/router";
import AccessControlRouter from "@/pages/AccessControl/router";
import SystemSettingRouter from "@/pages/SystemSetting/router";
import ReportRouter from "@/pages/Report/router";
import HistoryView from "@/components/views/HistoryView";
import OauthRouter from "@/pages/Oauth/router";
import CompanyRouter from "@/pages/Company/router";
import PrivateRoute from "@/router/PrivateRoute";
import ComplementRouter from "@/pages/complement/router";
import OnlineSubscription from "@/pages/OnlineSubscription";
import RalvieRouter from "@/pages/Ralvie/router";
import GPSManagementRouter from "@/pages/GPS/router";
import PersonalRouter from "@/pages/Personal/router";
import MainView from "@/router/MainView";
import VendorRouter from "@/pages/vendor/router";
import OnboardRouter from "@/pages/onboard/router";

const MainRoutes = () => {
  const routes = useRoutes([
    {path: "/onboard/*", element: <OauthRouter/>},
    {path: "/oauth/*", element: <OnboardRouter/>},
    {
      path: "/", element: <MainLayout/>, children: [
        {index: true, element: <MainView />},
        {path: "dashboard", element: <PrivateRoute><Dashboard /></PrivateRoute>},
        {path: "company/*", element: <CompanyRouter />},
        {path: "complement/*", element: <ComplementRouter />},
        {path: "personal/*", element: <PersonalRouter />},
        {path: "team/*", element: <TeamRouter />},
        {path: "device/*", element: <DeviceManagementRouter />},
        {path: "gps/*", element: <GPSManagementRouter />},
        {path: "att/*", element: <AttendanceRouter />},
        {path: "acc/*", element: <AccessControlRouter />},
        {path: "report/*", element: <ReportRouter />},
        {path: "system/*", element: <SystemSettingRouter />},
        {path: ":appLabel/:modelName/history", element: <HistoryView />},
        {path: "vendor/*", element: <VendorRouter/>},
        // {path: "tenement/*", element: <TenementRouter/>},
        // {path: "subscription/*", element: <SubscriptionRouter/>},
        {path: "ralvie/*", element: <RalvieRouter/>},
        {path: "onlineSubscription/*", element: <OnlineSubscription/>}
      ]
    },
  ])
  return routes
}
export default MainRoutes

import { TabPanel } from "@mui/lab";
import MultiSelectTreeView from "./MultiSelectTreeView";
import { useEffect, useState } from "react";


const PermissionView = (props: any) => {
  const { itemKey, onStatusChange, initSelecteds } = props

  const nodes: any[] = [
    {
      "id": "iclock.terminal",
      "label": "Device",
      "children": [
        {
          "codename": "terminal_capture_terminal",
          "label": "terminal_capture_terminal"
        },
        {
          "codename": "terminal_duplicate_punch_period_terminal",
          "label": "terminal_duplicate_punch_period_terminal"
        },
        {
          "codename": "terminal_enroll_remotely_terminal",
          "label": "terminal_enroll_remotely_terminal"
        },
        {
          "codename": "terminal_read_information_terminal",
          "label": "terminal_read_information_terminal"
        },
        {
          "codename": "terminal_reboot_terminal",
          "label": "terminal_reboot_terminal"
        },
        {
          "codename": "terminal_reload_data_terminal",
          "label": "terminal_reload_data_terminal"
        },
        {
          "codename": "terminal_re_upload_transaction_terminal",
          "label": "terminal_re_upload_transaction_terminal"
        },
        {
          "codename": "terminal_re_upload_data_terminal",
          "label": "terminal_re_upload_data_terminal"
        },
        {
          "codename": "terminal_clear_all_terminal",
          "label": "terminal_clear_all_terminal"
        },
        {
          "codename": "terminal_delete_capture_terminal",
          "label": "terminal_delete_capture_terminal"
        },
        {
          "codename": "terminal_clear_attendance_data_terminal",
          "label": "terminal_clear_attendance_data_terminal"
        },
        {
          "codename": "terminal_clear_command_terminal",
          "label": "terminal_clear_command_terminal"
        },
        {
          "codename": "terminal_new_area_terminal",
          "label": "terminal_new_area_terminal"
        },
        {
          "codename": "view_terminal",
          "label": "view_terminal"
        },
        {
          "codename": "terminal_upgrade_firmware_terminal",
          "label": "terminal_upgrade_firmware_terminal"
        },
        {
          "codename": "terminal_daylight_saving_time_terminal",
          "label": "terminal_daylight_saving_time_terminal"
        },
        {
          "codename": "add_terminal",
          "label": "add_terminal"
        },
        {
          "codename": "change_terminal",
          "label": "change_terminal"
        },
        {
          "codename": "delete_terminal",
          "label": "delete_terminal"
        }
      ]
    },
    {
      "id": "iclock.terminallog",
      "label": "Operation Log",
      "children": [
        {
          "codename": "add_terminallog",
          "label": "add_terminallog"
        },
        {
          "codename": "delete_terminallog",
          "label": "delete_terminallog"
        },
        {
          "codename": "change_terminallog",
          "label": "change_terminallog"
        },
        {
          "codename": "view_terminallog",
          "label": "view_terminallog"
        }
      ]
    },
    {
      "id": "iclock.terminalcommand",
      "label": "Command",
      "children": [
        {
          "codename": "view_terminalcommand",
          "label": "view_terminalcommand"
        },
        {
          "codename": "terminal_cmd_clear_command_terminalcommand",
          "label": "terminal_cmd_clear_command_terminalcommand"
        },
        {
          "codename": "add_terminalcommand",
          "label": "add_terminalcommand"
        },
        {
          "codename": "change_terminalcommand",
          "label": "change_terminalcommand"
        },
        {
          "codename": "delete_terminalcommand",
          "label": "delete_terminalcommand"
        }
      ]
    },
    {
      "id": "iclock.terminaluploadlog",
      "label": "Device Upload Log",
      "children": [
        {
          "codename": "view_terminaluploadlog",
          "label": "view_terminaluploadlog"
        },
        {
          "codename": "delete_terminaluploadlog",
          "label": "delete_terminaluploadlog"
        },
        {
          "codename": "change_terminaluploadlog",
          "label": "change_terminaluploadlog"
        },
        {
          "codename": "add_terminaluploadlog",
          "label": "add_terminaluploadlog"
        }
      ]
    },
    {
      "id": "iclock.biodata",
      "label": "Bio-Data",
      "children": [
        {
          "codename": "delete_biodata",
          "label": "delete_biodata"
        },
        {
          "codename": "change_biodata",
          "label": "change_biodata"
        },
        {
          "codename": "add_biodata",
          "label": "add_biodata"
        },
        {
          "codename": "view_biodata",
          "label": "view_biodata"
        }
      ]
    },
    {
      "id": "iclock.biophoto",
      "label": "Bio-Photo",
      "children": [
        {
          "codename": "bio_photo_approve_biophoto",
          "label": "bio_photo_approve_biophoto"
        },
        {
          "codename": "import_bio_photo_biophoto",
          "label": "import_bio_photo_biophoto"
        },
        {
          "codename": "delete_biophoto",
          "label": "delete_biophoto"
        },
        {
          "codename": "view_biophoto",
          "label": "view_biophoto"
        },
        {
          "codename": "bio_photo_q_r_code_biophoto",
          "label": "bio_photo_q_r_code_biophoto"
        }
      ]
    },
    {
      "id": "iclock.publicmessage",
      "label": "Public Message",
      "children": [
        {
          "codename": "add_public_message_publicmessage",
          "label": "add_public_message_publicmessage"
        },
        {
          "codename": "view_publicmessage",
          "label": "view_publicmessage"
        },
        {
          "codename": "change_publicmessage",
          "label": "change_publicmessage"
        },
        {
          "codename": "delete_publicmessage",
          "label": "delete_publicmessage"
        },
        {
          "codename": "send_public_message_publicmessage",
          "label": "send_public_message_publicmessage"
        }
      ]
    },
    {
      "id": "iclock.privatemessage",
      "label": "Private Message",
      "children": [
        {
          "codename": "delete_privatemessage",
          "label": "delete_privatemessage"
        },
        {
          "codename": "send_private_message_privatemessage",
          "label": "send_private_message_privatemessage"
        },
        {
          "codename": "add_private_message_privatemessage",
          "label": "add_private_message_privatemessage"
        },
        {
          "codename": "view_privatemessage",
          "label": "view_privatemessage"
        },
        {
          "codename": "change_privatemessage",
          "label": "change_privatemessage"
        }
      ]
    },
    {
      "id": "iclock.transaction",
      "label": "Transaction",
      "children": [
        {
          "codename": "u_s_b_data_upload_transaction",
          "label": "u_s_b_data_upload_transaction"
        },
        {
          "codename": "view_transaction",
          "label": "view_transaction"
        }
      ]
    },
    {
      "id": "iclock.unregister",
      "label": "Unregistered Transactions",
      "children": [
        {
          "codename": "view_unregister",
          "label": "view_unregister"
        }
      ]
    },
    {
      "id": "iclock.errorcommandlog",
      "label": "Error Log",
      "children": [
        {
          "codename": "delete_errorcommandlog",
          "label": "delete_errorcommandlog"
        },
        {
          "codename": "change_errorcommandlog",
          "label": "change_errorcommandlog"
        },
        {
          "codename": "add_errorcommandlog",
          "label": "add_errorcommandlog"
        },
        {
          "codename": "view_errorcommandlog",
          "label": "view_errorcommandlog"
        }
      ]
    },
    {
      "id": "iclock.deviceconfig",
      "label": "Device Configuration",
      "children": [
        {
          "codename": "change_deviceconfig",
          "label": "change_deviceconfig"
        },
        {
          "codename": "delete_deviceconfig",
          "label": "delete_deviceconfig"
        },
        {
          "codename": "view_deviceconfig",
          "label": "view_deviceconfig"
        },
        {
          "codename": "add_deviceconfig",
          "label": "add_deviceconfig"
        }
      ]
    }
  ]

  const [treeNodes, setTreeNodes] = useState<any[]>([]);
  const [allIds, setaAllIds] = useState<any[]>([]);
  useEffect(() => {
    const newNodes: any = []
    const newAllIds: any = []
    if (Object.keys(itemKey).length > 0) {
      nodes.forEach((node) => {
        const newChildren: any = []
        if (itemKey[node.id]) {
          node.children.forEach((child: any) => {
            if (itemKey[node.id][child.codename]) {
              newChildren.push({ ...child, id: itemKey[node.id][child.codename].toString() })
              newAllIds.push(itemKey[node.id][child.codename].toString())
            } else {
              console.log('error key', node.id, child.codename)
            }
          })
          node.children = newChildren
        } else {
          console.log('error key:', node.id)
        }
        newNodes.push(node)
      })
    }
    setTreeNodes(newNodes)
    setaAllIds(newAllIds)
  }, [itemKey])

  return (
    <TabPanel value={"device"}>
      <MultiSelectTreeView nodes={treeNodes}
        selected={initSelecteds}
        onSelectedChange={(ids: any) => {
          const unSelected = allIds.filter(each => ids.indexOf(each) === -1)
          onStatusChange && onStatusChange(ids, unSelected)
        }}
      />
    </TabPanel>
  )
}
export default PermissionView

import GenericService from "@/services/core/GenericService";
import {ReportEndPoint} from "@/services/report/endpoints";


const getReportSettings = async () => {
  const response = await GenericService.action(ReportEndPoint.REPORT_CONFIGURATION_URI, "report_settings", {})
  return response.data
}

const updateReportSetting = async (data: Record<string, any>) => {
  return await GenericService.action(ReportEndPoint.REPORT_CONFIGURATION_URI, "report_settings", data)
}

const ConfigurationService = {
  getReportSettings,
  updateReportSetting,
}
export default ConfigurationService

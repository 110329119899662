import {Menu, MenuProps, useTheme} from "@mui/material";


const ActionMenu = (props: MenuProps) => {
  const theme = useTheme()
  return (
    <Menu
      autoFocus={false}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: (theme.direction === 'rtl' ? 'left' : 'right'),
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: (theme.direction === 'rtl' ? 'left' : 'right')
      }}
      variant={'selectedMenu'}
      sx={{
        mt: 1
      }}
      {...props}
    >
      {props.children}
    </Menu>
  )
}
export default ActionMenu

import { TabPanel } from "@mui/lab";
import MultiSelectTreeView from "./MultiSelectTreeView";
import { useEffect, useState } from "react";


const PermissionView = (props: any) => {
  const { itemKey, onStatusChange, initSelecteds } = props

  const nodes: any[] = [
    {
      "id": "personnel.company",
      "label": "Company",
      "children": [
        {
          "codename": "add_company",
          "label": "add_company"
        },
        {
          "codename": "delete_company",
          "label": "delete_company"
        },
        {
          "codename": "change_company",
          "label": "change_company"
        },
        {
          "codename": "company_delete_company",
          "label": "company_delete_company"
        },
        {
          "codename": "view_company",
          "label": "view_company"
        }
      ]
    },
    {
      "id": "personnel.employee",
      "label": "Employee",
      "children": [
        {
          "codename": "re_upload_from_device_employee",
          "label": "re_upload_from_device_employee"
        },
        {
          "codename": "add_employee",
          "label": "add_employee"
        },
        {
          "codename": "change_employee",
          "label": "change_employee"
        },
        {
          "codename": "delete_employee",
          "label": "delete_employee"
        },
        {
          "codename": "view_employee",
          "label": "view_employee"
        },
        {
          "codename": "import_employee_employee",
          "label": "import_employee_employee"
        },
        {
          "codename": "import_document_employee",
          "label": "import_document_employee"
        },
        {
          "codename": "import_photo_employee",
          "label": "import_photo_employee"
        },
        {
          "codename": "adjust_department_employee",
          "label": "adjust_department_employee"
        },
        {
          "codename": "position_transfer_employee",
          "label": "position_transfer_employee"
        },
        {
          "codename": "adjust_area_employee",
          "label": "adjust_area_employee"
        },
        {
          "codename": "copy_area_employee",
          "label": "copy_area_employee"
        },
        {
          "codename": "location_transfer_employee",
          "label": "location_transfer_employee"
        },
        {
          "codename": "pass_probation_employee",
          "label": "pass_probation_employee"
        },
        {
          "codename": "resignation_employee",
          "label": "resignation_employee"
        },
        {
          "codename": "resynchronize_device_employee",
          "label": "resynchronize_device_employee"
        },
        {
          "codename": "delete_biometric_templates_employee",
          "label": "delete_biometric_templates_employee"
        },
        {
          "codename": "enable_app_employee",
          "label": "enable_app_employee"
        },
        {
          "codename": "disable_app_employee",
          "label": "disable_app_employee"
        }
      ]
    },
    {
      "id": "personnel.resign",
      "label": "Resignation",
      "children": [
        {
          "codename": "reinstatement_resign",
          "label": "reinstatement_resign"
        },
        {
          "codename": "disable_attendance_function_resign",
          "label": "disable_attendance_function_resign"
        },
        {
          "codename": "add_employee_resign_resign",
          "label": "add_employee_resign_resign"
        },
        {
          "codename": "view_resign",
          "label": "view_resign"
        },
        {
          "codename": "delete_resign",
          "label": "delete_resign"
        },
        {
          "codename": "change_resign",
          "label": "change_resign"
        },
        {
          "codename": "import_resign",
          "label": "import_resign"
        }
      ]
    },
    {
      "id": "workflow.workflownode",
      "label": "Node",
      "children": [
        {
          "codename": "add_workflownode",
          "label": "add_workflownode"
        },
        {
          "codename": "change_workflownode",
          "label": "change_workflownode"
        },
        {
          "codename": "delete_workflownode",
          "label": "delete_workflownode"
        },
        {
          "codename": "view_workflownode",
          "label": "view_workflownode"
        }
      ]
    },
    {
      "id": "workflow.workflowengine",
      "label": "Flow",
      "children": [
        {
          "codename": "delete_workflowengine",
          "label": "delete_workflowengine"
        },
        {
          "codename": "view_workflowengine",
          "label": "view_workflowengine"
        },
        {
          "codename": "change_workflowengine",
          "label": "change_workflowengine"
        },
        {
          "codename": "add_workflowengine",
          "label": "add_workflowengine"
        }
      ]
    },
    {
      "id": "workflow.workflowrole",
      "label": "Role",
      "children": [
        {
          "codename": "view_workflowrole",
          "label": "view_workflowrole"
        },
        {
          "codename": "add_workflowrole",
          "label": "add_workflowrole"
        },
        {
          "codename": "change_workflowrole",
          "label": "change_workflowrole"
        },
        {
          "codename": "delete_workflowrole",
          "label": "delete_workflowrole"
        },
        {
          "codename": "work_flow_role_assign_employee_workflowrole",
          "label": "work_flow_role_assign_employee_workflowrole"
        }
      ]
    }
  ]

  const [treeNodes, setTreeNodes] = useState<any[]>([]);
  const [allIds, setaAllIds] = useState<any[]>([]);
  useEffect(() => {
    const newNodes: any = []
    const newAllIds: any = []
    if ( Object.keys(itemKey).length > 0) {
      nodes.forEach((node) => {
        const newChildren: any = []
        if (itemKey[node.id]) {
          node.children.forEach((child: any) => {
            if (itemKey[node.id][child.codename]) {
              newChildren.push({ ...child, id: itemKey[node.id][child.codename].toString() })
              newAllIds.push(itemKey[node.id][child.codename].toString())
            } else {
              console.log('error key', node.id, child.codename)
            }
          })
          node.children = newChildren
        } else {
          console.log('error key:', node.id)
        }
        newNodes.push(node)
      })
    }
    setTreeNodes(newNodes)
    setaAllIds(newAllIds)
  }, [itemKey])

  return (
    <TabPanel value={"psnl"}>
      <MultiSelectTreeView nodes={treeNodes}
      selected={initSelecteds}
      onSelectedChange={ (ids: any) => {
        const unSelected = allIds.filter(each => ids.indexOf(each) === -1)
        onStatusChange && onStatusChange(ids, unSelected)
      }}
      />
    </TabPanel>
  )
}
export default PermissionView

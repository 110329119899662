import React, {ForwardedRef, useImperativeHandle, useRef} from "react";
import {useTranslation} from "react-i18next";
import { RJSFSchema, UiSchema } from "@rjsf/utils";
import LayoutForm from "@/components/rjsf/Form";
import SnackbarUtil from "@/utils/SnackbarUtil";
import GenericService from "@/services/core/GenericService";
import GPSService from "@/services/gps/GPSService";


const PushNotification = (props: Record<any, any>, ref: ForwardedRef<any>) => {
  const { t } = useTranslation("gps");
  const formRef = useRef<any>()
  const formSchema: RJSFSchema = {
    type: "object",

    properties: {
      notification: {
        type: "string",
        format: "text",
        title: t("accounts.notification"),
      },

    },
    required: ["notification"]
  }

  const uiSchema: UiSchema = {
    "ui:defaultXsWidth": 12,
    notification: {
      "ui:widget": "textarea",
      "ui:options": {
        rows: 4
      }
    }
  }

  const onPushNotification = async () => {
   const isValid = formRef.current?.validateForm()
    if (isValid) {
      const selectedIds = props.selectedIds
      const data = formRef.current?.state.formData
      const response = await GPSService.pushNotification({ids: selectedIds, ...data})
      if (response.isSuccess) {
        SnackbarUtil.success(t("common.successful"))
        return true
      } else {
        console.log(response)
      }
    }
  }

  useImperativeHandle(ref, () => ({
    onConfirm: onPushNotification
  }))

  return (
    <LayoutForm
      ref={formRef}
      schema={formSchema}
      uiSchema={uiSchema}
    />
  )
}
export default React.forwardRef(PushNotification)

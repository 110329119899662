import React, {createContext, useContext, useEffect, useState} from "react";
import {Navigate, useLocation, useNavigate} from "react-router-dom";
import urls from "@/urls";
import UserService from "@/services/auth/UserService";
import {Box, LinearProgress} from "@mui/material";


type AppContextProps = {
  currentUser?: any;
  menuExpand: boolean;
  expandMenu?: () => void;
  shrinkMenu?: () => void;
} & Record<string, any>
const AppContext = createContext<AppContextProps>({
  menuExpand: true
})

export const useAppContext = () => {
  const context = useContext(AppContext)
  if (!context) {
    throw new Error('The app provider not found!')
  }
  return context
}

type AppProviderProps = {
  children?: React.ReactNode
}
const AppProvider = (props: AppProviderProps) => {
  const navigate = useNavigate()
  const {children} = props
  const [loading, setLoading] = useState<boolean>(false)
  const [currentUser, setCurrentUser] = useState<any>()
  const [currentCompany, setCurrentCompany] = useState<any>()
  const [currentRole, setCurrentRole] = useState<any>()
  const [menuExpand, setMenuExpand] = useState<boolean>(true)
  const jwtToken = localStorage.getItem("accessToken");
  if (!jwtToken) {
    const companyHost = localStorage.getItem('company_code') + '.'
    localStorage.removeItem("accessToken")
    if (window.location.host.startsWith(companyHost)) {
      const newHost = window.location.host.substring(companyHost.length, window.location.host.length)
      const newOrigin = window.location.origin.replace(window.location.host, newHost)
      window.location.href = `${newOrigin}${urls.companyVerification}`
      // window.location.href = `${newOrigin}${urls.oauth.signIn}`
    } else {
      return <Navigate to={urls.companyVerification} replace/>
      // return <Navigate to={urls.oauth.signIn} replace/>
    }
  }
  const location = useLocation()
  const currentApp = location.pathname.split("/")[1] || "dashboard"
  const [viewApp, setViewApp] = useState<string>()
  const switchViewApp = async (view: string) => {
    setViewApp(view)
    navigate(`/${view}/`, {state: {app: view}})
  }
  const updateMenuStatus = (expand: boolean) => {
    setMenuExpand(expand)
  }
  const expandMenu = () => {
    updateMenuStatus(true)
  }
  const shrinkMenu = () => {
    updateMenuStatus(false)
  }

  const getRoleName = (role: number) => {
    switch (role) {
      case 0:
        return {roleName: "employee", mainView: "personal"}
      case 1:
        return {roleName: "tenement", mainView: "dashboard"}
      case 2:
        return {roleName: "agent", mainView: "vendor"}
      case 3:
        return {roleName: "vendor", mainView: "vendor"}
      default:
        return {roleName: "anonymous", mainView: ""}
    }
  }

  useEffect(() => {
    setLoading(true)
    UserService.getCurrentMembership().then((response) => {
      const membership = response.data
      setCurrentUser(membership.user)
      setCurrentCompany(membership.company)
      const role = getRoleName(membership.role)
      setCurrentRole(role.roleName)
      switchViewApp(role.mainView).then()
    }).finally(() =>
      setLoading(false)
    )
  }, [])

  return (
    <AppContext.Provider value={{
      currentUser,
      currentCompany,
      currentRole,
      menuExpand,
      expandMenu,
      shrinkMenu,
      viewApp,
      switchViewApp
    }}>
      {loading
        ? <Box sx={{width: '100%'}}>
          <LinearProgress/>
        </Box>
        : children
      }
    </AppContext.Provider>
  )
}
export default AppProvider


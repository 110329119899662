import Grid from "@mui/material/Grid2";
import ViewBreadcrumbs from "@/components/views/components/ViewBreadcrumbs";
import {Box} from "@mui/material";
import RecordActions from "@/components/views/components/RecordActions";
import React from "react";


const ModelActionLayout = (props: Record<string, any>) => {
  return (
    <Grid
      container
      sx={{
        height: "100%",
        overflowY: "hidden"
      }}
    >
      <Grid
        size={12}
        sx={{
          display: "flex",
          borderRadius: "4px",
          alignItems: "center",
          padding: "8px 0"
        }}>
        <ViewBreadcrumbs
          title={props.title}
          breadcrumbs={props.breadcrumbs}
        />
        <Box sx={{flexGrow: 1}}/>
        <RecordActions
          actions={props.actions}
        />
      </Grid>
      <Grid
        size={12}
        sx={{
          backgroundColor: "#fff",
          height: "calc(100% - 48px)",
          borderRadius: "4px",
          p: 2,
          overflowY: "auto",
        }}
      >
        {props.children}
      </Grid>
    </Grid>
  )
}
export default ModelActionLayout

import {useTranslation} from "react-i18next";
import {GridColDef} from "@mui/x-data-grid";
import ListView from "@/components/views/ListView";
import {VendorEndPoint} from "@/services/vendor/endpoints";
import AppUrls from "@/urls";


const UserListView = () => {
  const {t} = useTranslation("vendor")
  const {t: menu} = useTranslation("menu")

  const columns: GridColDef[] = [
    {field: "username", headerName: t("user.username"), flex: 1},
    {field: "email", headerName: t("user.email"), flex: 1},
    {field: "mobile",  headerName: t("user.mobile"), flex: 1},
    {field: "active_status", headerName: t("user.activeStatus"), flex: 1},
    {field: "last_login", headerName: t("user.lastLogin"), flex: 1},
    {field: "date_joined", headerName: t("user.dateJoined"), flex: 1},
    {field: "belongs_company", headerName: t("user.belongsCompany"), flex: 1},

  ]

  const filters = [
    {key: 'username', label: t("user.username"), type: 'string'},
    {key: 'mobile', label: t("user.mobile"), type: 'string'},
    {key: 'email', label: t("user.email"), type: 'string'},
  ]

  return (
    <ListView
      title={menu("menu.tenement.user")}
      breadcrumbs={[
        {title: menu("menu.tenement"), href: AppUrls.tenement.root},
      ]}
      columns={columns}
      uri={VendorEndPoint.USER_URI}
      url={AppUrls.vendor.user}
      filters={filters}
      disableAdd={true}
    />
  )
}
export default UserListView


import { Navigate, Route, Routes } from "react-router-dom";
import ReportsRouter from "@/pages/Report/ReportLibrary/router";
import CalculationRouter from "@/pages/Report/Calculation/router";
import ReportSetting from "@/pages/Report/Configuration/ReportSetting";
import PunchStateSettingRouter from "@/pages/Report/Configuration/PunchStateSetting/router";
import ReportItemsSettingRouter from "@/pages/Report/Configuration/ReportItemsSetting/router";

const ReportRouter = () => {
  return (
    <Routes>

      <Route index element={<Navigate to={"report-library"} replace />} />
      <Route path={"report-library/*"} element={<ReportsRouter />} />
      <Route path={"calculation/"} element={<CalculationRouter />} />
      <Route path={"report-setting/"} element={<ReportSetting />} />
      <Route
        path={"punch-state-setting/*"}
        element={<PunchStateSettingRouter />}
      />
      <Route
        path={"report-Items-setting/*"}
        element={<ReportItemsSettingRouter />}
      />
    </Routes>

  );
};

export default ReportRouter;

import {useRoutes} from "react-router-dom";
import TrainingApprovalListView from "@/pages/Personal/my-approval/training/ListView";

const TrainingApprovalRouter = () => {
  const routes = useRoutes([
    {index: true, element: <TrainingApprovalListView />},
  ])
  return routes
}
export default TrainingApprovalRouter;

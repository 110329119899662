import {WidgetProps} from "@rjsf/utils";
import {Autocomplete, TextField} from "@mui/material";
import React, {useEffect, useMemo, useState} from "react";
import GenericService from "@/services/core/GenericService";


const Many2OneWidget = (props: WidgetProps) => {
  const {name, label, value, options, uiSchema, readonly, formContext} = props
  const [loading, setLoading] = useState<boolean>(false)
  const [selections, setSelections] = useState<Record<string, any>[]>([])
  const selected = useMemo(() => {
    const s = selections.filter((selection) => selection.id === value)
    if(s.length === 0){
      return null
    }
    return s[0]
  }, [selections, value])

  function fetchList(url: string, filter: Record<any, any>) {
    setLoading(true)
    GenericService.list(url, {page: 1, page_size: 100, ...filter}).then(
      res => {
        setSelections(res.data.data)
      }
    ).finally(() => {
      setLoading(false)
    })
  }
  const {uri, ...filter} = options
  useEffect(() => {
    if (formContext.preload){
      const data: Record<string, any>[] = formContext[name]
      setSelections(data || [])
    }else if (options.uri) {
      fetchList(options.uri?.toString(), filter)
    }
  }, [])

  const [oldFormContext, setOldFormContext] = React.useState<Record<string, any>>({test: 1})

  useEffect(() => {
    let change = false
    if (options?.filter) {
      const filterData: Record<string, any> = {}
      for (const field of options.filter as Array<string>) {
        filterData[field] = formContext[field]
        if (oldFormContext[field] !== formContext[field]) {
          change = true
        }
      }
      if (change) {
        fetchList(options.uri?.toString() as string, filterData)
      }
    }
    setOldFormContext(formContext)
  }, [formContext])

  return (
    <Autocomplete
      loading={loading}
      readOnly={readonly}
      options={selections}
      getOptionLabel={(option: Record<string, any>) => option?.display_name || ""}
      getOptionKey={(option: Record<string, any>) => option.id}
      value={selected}
      renderInput={(params: any) => {
        return (
          <TextField
            {...params}
            name={name}
            label={label}
            required={props.required}
            disabled={props.readonly}
          />
        )
      }}
      onChange={async (event, value, reason, details) => {
        if (props.required) {
          props.onChange(value?.id)
        } else {
          props.onChange(value?.id ? value.id : null)
        }
      }}
      isOptionEqualToValue={(option, value) => {
        if(typeof (value) === "object"){
          return option.id === value?.id
        }else{
          return option.id === parseInt(value)
        }
      }}
    />
  )
}
export default Many2OneWidget

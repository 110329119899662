import {Box, Card, CardContent, CardHeader, Chip, CircularProgress, Divider, Typography} from "@mui/material";
import {useEffect, useState} from "react";
import GenericService from "@/services/core/GenericService";
import {PersonalEndPoint} from "@/services/personal/endpoints";
import {AccessTime, ArrowRight, BadgeOutlined, Event, PersonOutlined} from "@mui/icons-material";
import {Stack} from "@mui/system";
import {useTranslation} from "react-i18next";


const WorkflowView = (props: Record<string, any>) => {
  const {t} = useTranslation("per")
  const {record} = props
  const [nodes, setNodes] = useState<any[]>([])
  const [loading, setLoading] = useState<boolean>(true)
  useEffect(() => {
    GenericService.action(props.uri, "workflow", {}, props.recordId).then((response) => {
      setNodes(response.data)
    }).finally(() => {
      setLoading(false)
    })
  }, [])
  return (
    <Box sx={{marginTop: "-36px"}}>
      <Stack
        spacing={1}
        sx={{p: 1}}
      >
        <Stack spacing={1} direction={"row"}>
          <PersonOutlined/>
          <Typography>{`${record.first_name} ${record.last_name}`}</Typography>
        </Stack>
        <Stack spacing={1} direction={"row"}>
          <BadgeOutlined/>
          <Typography>{record.emp_code}</Typography>
        </Stack>
        <Stack spacing={1} direction={"row"}>
          <Event/>
          <Typography>{props.event}</Typography>
        </Stack>
        <Stack spacing={1} direction={"row"}>
          <AccessTime/>
          <Typography>{record.apply_time}</Typography>
        </Stack>
      </Stack>
      <Divider/>
      <Box sx={{width: "640px", height: "412px", padding: "16px 0", overflowY: "auto"}}>
        {
          nodes.length > 0
            ? <Box sx={{display: "flex", alignItems: "center"}}>
              {nodes.map((node: any, index) => {
                return (
                  <>
                    {index > 0 && <ArrowRight/>}
                    <Card
                      variant={"outlined"}
                      sx={{
                        width: "192px",
                        height: "192px",
                      }}
                    >
                      <CardHeader
                        title={<Typography variant={"h3"}>{node.name}</Typography>}
                        subheader={node.state}
                      />
                      <CardContent sx={{width: "100%"}}>
                        <Stack spacing={2}>
                          <Typography variant={"h4"}>{t("workflowNode.approver")}</Typography>
                          <Stack spacing={1} direction={"row"}>
                            {node.approver.map((role: string) => {
                              return (
                                <Chip label={role}/>
                              )
                            })}
                          </Stack>
                        </Stack>

                      </CardContent>
                    </Card>
                  </>
                )
              })}
            </Box>
            : <Box sx={{
                width: "100%",
                height: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center"
              }}>
                {loading
                  ? <CircularProgress/>
                  : <Typography>{t("common.message.noDataFound", {ns: "common"})}</Typography>
                }
              </Box>
        }
      </Box>
    </Box>
  )
}
export default WorkflowView

import {RJSFSchema, UiSchema} from "@rjsf/utils";
import {useTranslation} from "react-i18next";
import urls from "@/urls";
import RecordView from "@/components/views/RecordView";
import React from "react";
import {DeviceEndPoint} from "@/services/device/endpoints";
import {PsnlEP} from "@/services/psnl/endpoints";
import RecordViewLayout from "@/pages/Device/Device/components/RecordViewLayout";
import {useLocation} from "react-router-dom";


const DeviceRecordView = () => {
  const {t} = useTranslation("device");
  const {t: common} = useTranslation("common");
  const params = useLocation();
  const mode = params.state?.mode || "ADD";
  const formSchema: RJSFSchema = {
    "type": "object",
    "required": [
      "alias",
      "sn",
      "area",
      "transfer_mode",
      "terminal_tz",
      "is_registration",
      "is_attendance",
      "heartbeat",
      "transfer_interval",
      "transfer_time",
      "is_access"
    ],
    "properties": {
      "alias": {
        "type": "string",
        "maxLength": 50,
        "title": t("device.alias"),
      },
      "sn": {
        "maxLength": 50,
        "type": "string",
        "title": t("device.sn"),
        readOnly: mode === "EDIT",
      },
      "area": {
        "type": "number",
        "title": t("device.area")
      },
      "ip_address": {
        "type": "string",
        "title": t("device.deviceIp"),
      },
      "transfer_mode": {
        "type": ["number"],
        "title": t("device.transferMode"),
        oneOf: [
          {const: 1, title: t("device.transferMode.choices.REAL_TIME")},
          {const: 0, title: t("device.transferMode.choices.TIMING")},
        ],
        default: 1
      },
      "terminal_tz": {
        "type": ["number"],
        "title": t("device.terminalTz"),
        oneOf: [
          {const: -750, title: "Etc/GMT-12:30"},
          {const: -12, title: "Etc/GMT-12"},
          {const: -690, title: "Etc/GMT-11:30"},
          {const: -11, title: "Etc/GMT-11"},
          {const: -630, title: "Etc/GMT-10:30"},
          {const: -10, title: "Etc/GMT-10"},
          {const: -570, title: "Etc/GMT-9:30"},
          {const: -9, title: "Etc/GMT-9"},
          {const: -510, title: "Etc/GMT-8:30"},
          {const: -8, title: "Etc/GMT-8"},
          {const: -450, title: "Etc/GMT-7:30"},
          {const: -7, title: "Etc/GMT-7"},
          {const: -390, title: "Etc/GMT-6:30"},
          {const: -6, title: "Etc/GMT-6"},
          {const: -330, title: "Etc/GMT-5:30"},
          {const: -5, title: "Etc/GMT-5"},
          {const: -270, title: "Etc/GMT-4:30"},
          {const: -4, title: "Etc/GMT-4"},
          {const: -210, title: "Etc/GMT-3:30"},
          {const: -3, title: "Etc/GMT-3"},
          {const: -150, title: "Etc/GMT-2:30"},
          {const: -2, title: "Etc/GMT-2"},
          {const: -90, title: "Etc/GMT-1:30"},
          {const: -1, title: "Etc/GMT-1"},
          {const: -30, title: "Etc/GMT-0:30"},
          {const: 0, title: "Etc/GMT"},
          {const: 1, title: "Etc/GMT+1"},
          {const: 90, title: "Etc/GMT+1:30"},
          {const: 2, title: "Etc/GMT+2"},
          {const: 150, title: "Etc/GMT+2:30"},
          {const: 3, title: "Etc/GMT+3"},
          {const: 210, title: "Etc/GMT+3:30"},
          {const: 4, title: "Etc/GMT+4"},
          {const: 270, title: "Etc/GMT+4:30"},
          {const: 5, title: "Etc/GMT+5"},
          {const: 330, title: "Etc/GMT+5:30"},
          {const: 6, title: "Etc/GMT+6"},
          {const: 390, title: "Etc/GMT+6:30"},
          {const: 7, title: "Etc/GMT+7"},
          {const: 450, title: "Etc/GMT+7:30"},
          {const: 8, title: "Etc/GMT+8"},
          {const: 510, title: "Etc/GMT+8:30"},
          {const: 9, title: "Etc/GMT+9"},
          {const: 570, title: "Etc/GMT+9:30"},
          {const: 10, title: "Etc/GMT+10"},
          {const: 630, title: "Etc/GMT+10:30"},
          {const: 11, title: "Etc/GMT+11"},
          {const: 690, title: "Etc/GMT+11:30"},
          {const: 12, title: "Etc/GMT+12"},
          {const: 750, title: "Etc/GMT+12:30"},
          {const: 13, title: "Etc/GMT+13"},
          {const: 810, title: "Etc/GMT+13:30"},
        ]
      },
      "is_registration": {
        "type": "number",
        "title": t("device.isRegistration"),
        "oneOf": [
          {const: 1, title: 'Yes'},
          {const: 0, title: 'No'},
        ],
        "default": 0
      },
      "is_attendance": {
        "type": "number",
        "title": t("device.isAttendance"),
        "oneOf": [
          {const: 1, title: 'Yes'},
          {const: 0, title: 'No'},
        ],
        "default": 1
      },
      "heartbeat": {
        "type": ["number"],
        "title": t("device.heartbeat"),
        "default": 10
      },
      "is_access": {
        "type": "number",
        "title": t("device.isAccess"),
        "oneOf": [
          {const: 1, title: 'Yes'},
          {const: 0, title: 'No'},
        ],
        "default": 0
      },
      "transfer_interval": {
        "type": ["number"],
        "title": t("device.transferInterval"),
        "default": 10
      },
      "transfer_time": {
        "type": ['string'],
        "title": t("device.transferTime"),
        "default": "00:00;14:05"
      },
    }
  };

  const uiSchema: UiSchema = {
    "ui:ObjectFieldTemplate": RecordViewLayout,
    'ui:defaultXsWidth': 4,
    'ui:defaultRowWrap': false,
    heartbeat: {
      "ui:widget": "quantifier",
      "ui:options": {
        unit: common("common.unit.second")
      }
    },
    transfer_interval: {
      "ui:widget": "quantifier",
      "ui:options": {
        unit: common("common.unit.second")
      }
    },
    "area": {
      "ui:widget": "m2o",
      "ui:options": {
        uri: PsnlEP.AREA_URI
      }
    },

  };
  return (
    <RecordView
      breadcrumbs={[
        {title: t("menu.device"), href: urls.device.terminal}
      ]}
      formSchema={formSchema}
      uiSchema={uiSchema}
      uri={DeviceEndPoint.TERMINAL_URI}
    />
  )
}
export default DeviceRecordView

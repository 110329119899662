import { useTranslation } from "react-i18next";
import { RJSFSchema, UiSchema } from "@rjsf/utils";
import React, { useEffect, useRef, useState } from "react";

import SnackbarUtil from "@/utils/SnackbarUtil";
import urls from "@/urls";
import LayoutTemplate from "./LayoutTemplate";
import EnableWidget from "./components/EnableWidget";
import ConfigurationService from "@/services/device/ConfigurationService";
import ModelActionPage from "@/components/views/ListView/components/model-action/ModelActionPage";

const DevConfiguration = () => {
  const { t } = useTranslation("device");
  const [formData, setFormData] = useState<Record<string, any>>();
  const formRef = useRef<any>();
  const approval_choice = [
    { const: 0, title: t("devConfiguration.pending") },
    { const: 3, title: t("devConfiguration.autoApproved") },
  ];
  const formSchema: RJSFSchema = {
    type: "object",
    properties: {
      enable_registration: {
        type: "boolean",
        title: t("devConfiguration.enableRegistration"),
        default: false,
      },
      enable_resigned_filter: {
        type: "boolean",
        title: t("devConfiguration.enableResignedFilter"),
        default: false,
      },
      enable_auto_add: {
        type: "boolean",
        title: t("devConfiguration.autoAdd"),
        default: false,
      },
      enable_employee_upload: {
        type: "boolean",
        title: t("devConfiguration.enableEmployeeUpload"),
        default: false,
      },
      enable_name_upload: {
        type: "boolean",
        title: t("devConfiguration.enableNameUpload"),
        default: false,
      },
      enable_card_upload: {
        type: "boolean",
        title: t("devConfiguration.enableCardUpload"),
        default: false,
      },
      transaction_retention: {
        type: "number",
        title: t("devConfiguration.transactionRetention"),
        default: 9999,
        minimum: 0,
        maximum: 9999,
      },
      command_retention: {
        type: "number",
        title: t("devConfiguration.commandRetention"),
        default: 9999,
        minimum: 0,
        maximum: 9999,
      },
      dev_log_retention: {
        type: "number",
        title: t("devConfiguration.devLogRetention"),
        default: 9999,
        minimum: 0,
        maximum: 9999,
      },
      upload_log_retention: {
        type: "number",
        title: t("devConfiguration.uploadLogRetention"),
        default: 9999,
        minimum: 0,
        maximum: 9999,
      },
      enable_synchronization_data: {
        type: "boolean",
        title: t("devConfiguration.enableSynchronizeData"),
        default: false,
      },
      edit_policy: {
        type: "integer",
        title: t("devConfiguration.editBioPhotoPolicy"),
        oneOf: approval_choice,
      },
      import_policy: {
        type: "integer",
        title: t("devConfiguration.importBioPhotoPolicy"),
        oneOf: approval_choice,
      },
      mobile_policy: {
        type: "integer",
        title: t("devConfiguration.mobileBioPhotoPolicy"),
        oneOf: approval_choice,
      },
      device_policy: {
        type: "integer",
        title: t("devConfiguration.deviceBioPhotoPolicy"),
        oneOf: approval_choice,
      },
    },
    required: [
      "edit_policy",
      "import_policy",
      "mobile_policy",
      "device_policy",
      "transaction_retention",
      "command_retention",
      "dev_log_retention",
      "upload_log_retention",
    ],
  };
  const uiSchema: UiSchema = {
    enable_registration: {
      "ui:widget": "switch",
    },
    enable_resigned_filter: {
      "ui:widget": "switch",
    },
    enable_auto_add: {
      "ui:widget": "switch",
    },
    enable_employee_upload: {
      "ui:widget": "switch",
    },
    enable_name_upload: {
      "ui:widget": "switch",
    },
    enable_card_upload: {
      "ui:widget": "switch",
    },
    enable_synchronization_data: {
      "ui:widget": "switch",
    },
  };

  const widgets = {
    enable: EnableWidget,
  };

  const templates = {
    ObjectFieldTemplate: LayoutTemplate,
  };
  const breadcrumbs = [
    { title: t("menu.configuration"), href: urls.device.root },
  ];
  const fetchDevConfig = async () => {
    const data = await ConfigurationService.getConfiguration();
    setFormData(data);
  };

  const onSaveDevConfig = async (data: Record<string, any>) => {
    const response = await ConfigurationService.updateConfiguration(
      data,
      data["uuid"]
    );
    if (response.isSuccess) {
      SnackbarUtil.success(
        t("common.message.saveSuccessfully", { ns: "common" })
      );
    } else {
      console.log(response);
    }
  };

  useEffect(() => {
    fetchDevConfig().then();
  }, []);

  return (
    <ModelActionPage
      title={t("menu.device.configuration", { ns: "menu" })}
      formSchema={formSchema}
      uiSchema={uiSchema}
      widgets={widgets}
      templates={templates}
      formData={formData}
      disableDiscard={true}
      onSave={onSaveDevConfig}
    />
  );
};
export default DevConfiguration;

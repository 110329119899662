import { Navigate, Route, Routes } from "react-router-dom";
import DeviceRouter from "@/pages/Device/Device/router";
import PublicMessageRouter from "./PublicMessage/router";
import DeviceCommandRouter from "./DeviceCommand/router";
import OperationLogRouter from "./OperationLog/router";
import ErrorLogRouter from "./ErrorLog/router";
import UploadLogRouter from "./UploadLog/router";
import DevConfiguration from "./Configuration";
import BioPhoto from "./BioPhoto/router";
import WorkCodeRouter from "@/pages/Device/WorkCode/router";
import PrivateMessageRouter from "@/pages/Device/PrivateMessge/router";
import BioTemplateRouter from "@/pages/Device/BioTemplate/router";
import TransactionRouter from "@/pages/Device/Transaction/router";
import UnregisteredTransactionRouter from "@/pages/Device/UnregisteredTransaction/router";

const DeviceManagementRouter = () => {
  return (
    <Routes>
      <Route index element={<Navigate to={"terminal"} replace />} />
      <Route path={"terminal/*"} element={<DeviceRouter />} />
      <Route path={"public-message/*"} element={<PublicMessageRouter />} />
      <Route path={"command/*"} element={<DeviceCommandRouter />} />
      <Route path={"operation-log/*"} element={<OperationLogRouter />} />
      <Route path={"error-log/*"} element={<ErrorLogRouter />} />
      <Route path={"upload-log/*"} element={<UploadLogRouter />} />
      <Route path={"bio-photo/*"} element={<BioPhoto />} />
      <Route path={"configuration/*"} element={<DevConfiguration />} />
      <Route path={"terminal/*"} element={<DeviceRouter />} />
      <Route path={"work-code/*"} element={<WorkCodeRouter />} />
      <Route path={"private-message/*"} element={<PrivateMessageRouter />} />
      <Route path={"bio-template/*"} element={<BioTemplateRouter />} />
      <Route path={"transaction/*"} element={<TransactionRouter />} />
      <Route
        path={"unregistered-transaction/*"}
        element={<UnregisteredTransactionRouter />}
      />
    </Routes>
  );
};
export default DeviceManagementRouter;

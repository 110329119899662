import {Box, IconButton} from "@mui/material";
import {ShoppingCart} from "@mui/icons-material";
import {bindTrigger, usePopupState} from "material-ui-popup-state/hooks";
import React from "react";
import {useNavigate} from 'react-router-dom';
import {useAppContext} from "@/provider/AppProvider";

const Subscription = () => {
  const navigate = useNavigate();
  const {viewApp, switchViewApp } = useAppContext()
  const subscriptionClick = () => {
    switchViewApp("onlineSubscription")
    // navigate(AppUrls.onlineSubscription.root, {state: {app: "onlineSubscription"}})
  }
  const popupState = usePopupState({variant: "popover", popupId: "notificationMenu"})
  return (
    <>
      <Box sx={{display: "flex", alignItems: "center", height: "100%"}} {...bindTrigger(popupState)}>
        <IconButton color={"inherit"}
            onClick={subscriptionClick}>
          <ShoppingCart/>
        </IconButton>
      </Box>
    </>
  )
}
export default Subscription

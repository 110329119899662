import { Route, Routes } from "react-router-dom";
import ListView from "./ListView";

const UnregisteredTransactionRouter = () => {
  return (
    <Routes>
      <Route index element={<ListView />} />
    </Routes>
  );
};
export default UnregisteredTransactionRouter;

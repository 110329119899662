import {Navigate, Route, Routes} from "react-router-dom";
import DepartmentRouter from "./Department/router"
import AreaRouter from "./Area/router"
import PositionRouter from "./Position/router"
import LocationRouter from "./Location/router"
import CompanySettings from "@/pages/Company/CompanySettings";
import OrganizationChart from "@/pages/Company/OrganizationChart";
import React from "react";
import MapProvider from "@/provider/MapProvider";


const CompanyRouter = () => {
  return (
    <Routes>
      <Route index element={<Navigate to={"settings"} replace/>}/>
      <Route path={"settings"} element={<MapProvider><CompanySettings /></MapProvider>}/>
      <Route path={"department/*"} element={<DepartmentRouter />}/>
      <Route path={"area/*"} element={<AreaRouter />}/>
      <Route path={"position/*"} element={<PositionRouter />}/>
      <Route path={"location/*"} element={<LocationRouter />}/>
      <Route path={"organization-chart"} element={<OrganizationChart />}/>
    </Routes>
  )
};
export default CompanyRouter

import {Box, ListItemText, MenuItem, MenuList, Stack, styled, Typography} from "@mui/material";
import {useRef} from "react";
import Grid from '@mui/material/Grid2';

const StyledTypography = styled(Typography)(({theme}) => ({
  paddingBottom: "16px"
}))


export const ScrollTab = (props: any) => {
  return (
    <Box ref={props.refTag}>
      <StyledTypography variant={"h4"}>
        {props.title}
      </StyledTypography>
      <Stack spacing={2} sx={{paddingBottom: "16px"}}>
        {props.children}
      </Stack>
    </Box>
  )
}

export const useScrollTabsHook = (tabs: Array<any>): any => {
  const newTab = tabs.map((each) => {
    return {key: each.key, title: each.title, refTag: useRef<any>()}
  })
  const getTabProps = (key: string) => newTab.find((each) => each.key === key)
  return [newTab, getTabProps]
}

export const ScrollTabs = (props: any) => {

  const scrollIntoView = (ref: any) => {
    ref.current?.scrollIntoView({block: "start", behavior: "smooth"})
  }
  const displayTabs = props.tabs.filter((each: any) => {
    if (!props.excludeTabs) {
      return true
    }
    if (props.excludeTabs.indexOf(each.key) === -1) {
      return true
    } else {
      return false
    }
  })

  return (
    <Grid
      className={"scroll-tabs"}
      sx={{
        display: "flex",
        height: "calc(100vh - 64px - 48px - 48px)"
      }}
    >
      <Grid>
        <MenuList sx={{
          minWidth: "208px",
          maxWidth: "324px"
        }}>
          {displayTabs.map((item: any, index: number) => {
              return (
                <MenuItem key={`ref${index}`} onClick={() => scrollIntoView(item.refTag)}>
                  <ListItemText>
                    <Typography variant={"h5"}>{item.title}</Typography>
                  </ListItemText>
                </MenuItem>
              )
            }
          )}
        </MenuList>
      </Grid>
      <Grid
        sx={{
          flex: 1,
          height: "100%",
          p: 1,
          overflowY: "auto"
        }}
      >
        {props.children}
      </Grid>
    </Grid>
  )
}

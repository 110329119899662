import {Navigate, useRoutes} from "react-router-dom";
import MyRequestRouter from "@/pages/Personal/request/router";
import HolidayListView from "@/pages/Personal/my-calendar/holiday/ListView";
import HolidayRouter from "@/pages/Personal/my-calendar/holiday/router";
import CalendarView from "@/pages/Personal/my-calendar/calendar/CalendarView";


const MyCalendarRouter = () => {
  const routes = useRoutes([
    {index: true, element: <Navigate to={"calendar"} replace/>},
    {path: "holiday/*", element: <HolidayRouter />},
    {path: "calendar/*", element: <CalendarView />},
  ])
  return routes
};
export default MyCalendarRouter

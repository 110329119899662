import { RJSFSchema, UiSchema } from "@rjsf/utils";
import { useTranslation } from "react-i18next";
import validator from "@rjsf/validator-ajv8";
import LayoutForm from "@/components/rjsf/Form";
import { ForwardedRef, useImperativeHandle, useRef } from "react";

import SnackbarUtil from "@/utils/SnackbarUtil";
import ApprovalService from "@/services/att/ApprovalService";
import React from "react";
import { AutoExportService } from "@/services/auth/AutoExportService";

const ManualExportAction = (
  props: Record<string, any>,
  ref: ForwardedRef<any>
) => {
  const { t } = useTranslation("sys");
  const formRef = useRef<any>();
  const formSchema: RJSFSchema = {
    type: "object",
    required: ["manual_start", "manual_end"],
    properties: {
      manual_start: {
        type: "string",
        format: "date",
        title: t("report.startDate", { ns: "report" }),
      },
      manual_end: {
        type: "string",
        format: "date",
        title: t("report.endDate", { ns: "report" }),
      },
      query_field: {
        type: "integer",
        title: t("autoExport.queryField"),
        oneOf: [
          { const: 1, title: t("operationLog.uploadTime", { ns: "default" }) },
          { const: 2, title: t("manualLog.punchTime", { ns: "att" }) },
        ],
        default: 1,
      },
    },
  };

  const uiSchema: UiSchema = {
    "ui:defaultXsWidth": 12,
    "ui:submitButtonOptions": { norender: true },
  };

  const onConfirm = async () => {
    console.log("1111111111111111111111111111111");
    const isValid = formRef.current?.validateForm();
    if (isValid) {
      const selectedIds = props.selectedIds;
      const data = formRef.current?.state.formData;
      const response = await AutoExportService.manualExport({
        autoExportTask: selectedIds,
        ...data,
      });
      if (response.isSuccess) {
        SnackbarUtil.success(t("common.successful"));
        return true;
      } else {
        console.log(response);
      }
    }
  };
  useImperativeHandle(ref, () => ({
    onConfirm,
  }));

  return (
    <LayoutForm
      ref={formRef}
      schema={formSchema}
      uiSchema={uiSchema}
      validator={validator}
    />
  );
};
export default React.forwardRef(ManualExportAction);

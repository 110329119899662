import {useTranslation} from "react-i18next";
import {GridColDef, GridRenderCellParams} from "@mui/x-data-grid";
import urls from "@/urls";
import {ReportEndPoint} from "@/services/report/endpoints";
import ReportView from "../ReportView";
import {useEffect, useState} from "react";
import GenericService from "@/services/core/GenericService";
import {AttEndPoint} from "@/services/att/endpoints";
import {PersonalEndPoint} from "@/services/personal/endpoints";
import { useReportEmployee } from "@/hooks/employee";


const StaffSummaryReport = () => {
  const {t} = useTranslation("report")
  const employeeFields = useReportEmployee()
  const fixedColumns: GridColDef[] = [
    ...employeeFields,
    {
      field: "total_late",
      headerName: t("report.late"),
      flex: 1,
    },
    {
      field: "total_early_leave",
      headerName: t("report.earlyLeave"),
      flex: 1,
    },
    {
      field: "total_absent",
      headerName: t("report.absent"),
      flex: 1,
    },
    {
      field: "total_work",
      headerName: t("report.actualWork"),
      flex: 1,
    },
    {
      field: "total_not",
      headerName: t("report.normalOT"),
      flex: 1,
    },
    {
      field: "total_wot",
      headerName: t("report.weekOffOT"),
      flex: 1,
    },
    {
      field: "total_hot",
      headerName: t("report.holidayOT"),
      flex: 1,
    },
  ]
  const [leaveColumns, setLeaveColumns] = useState<GridColDef[]>([])
  const [columns, setColumns] = useState<GridColDef[]>(fixedColumns)

  useEffect(() => {
    GenericService.list(AttEndPoint.LEAVE_TYPE_URI, {
      page: 1,
      page_size: 50,
    })
      .then((response) => {
        const leaves: any = []
        response.data.data.forEach((each: any) => {
          leaves.push({field: `leave_${each.id}`, headerName: `${each.display_name}`})
        })
        setLeaveColumns(leaves)
      })
  }, [])

  useEffect(() => {
    setColumns((old) => {
      return [
        ...fixedColumns,
        ...leaveColumns
      ]
    })
  }, [leaveColumns])

  return (
    <ReportView
      title={t("reportLibrary.employeeSummary")}
      breadcrumbs={[]}
      columns={columns}
      uri={PersonalEndPoint.STAFF_SUMMARY_URI}
      url={urls.personal.report.staffSummary}
      getRowId={(row: any) => row.emp_id}
      hideColumns={['first_name', 'last_name', 'department_code', 'position_code', 'position_name']}
    />
  )
}
export default StaffSummaryReport

import { MainIconButton } from "@/components/styled"
import { useListViewContext } from "@/provider/ListViewProvider";
import ViewColumnOutlinedIcon from '@mui/icons-material/ViewColumnOutlined';
import { Box, Checkbox, FormControlLabel, Popover, Tooltip } from "@mui/material";
import { gridColumnDefinitionsSelector, gridColumnVisibilityModelSelector, useGridSelector } from "@mui/x-data-grid";
import { bindPopover, bindTrigger, usePopupState } from "material-ui-popup-state/hooks";
import { useReportViewContext } from "./ReportViewProvider";


const ColumnsPopover = (props: Record<any, any>) => {
  const { apiRef } = props
  const columns = useGridSelector(apiRef, gridColumnDefinitionsSelector);
  const columnVisibilityModel = useGridSelector(apiRef, gridColumnVisibilityModelSelector);
  const toggleColumn = (event: any) => {
    const {
      name: field
    } = event.target;
    apiRef.current.setColumnVisibility(field, columnVisibilityModel[field] === false);
  };

  return <Box
    sx={{
      padding: '12px 24px 12px 24px',
      display: 'flex',
      flexDirection: 'column',
      overflow: 'auto',
      flex: '1 1 0%',
      maxHeight: '400px',
      alignItems: 'flex-start',
    }}
  >
    {
      columns.filter((each: any) => ['__check__', 'actions'].indexOf(each.field) === -1).map((column: any) => {
        return <Box key={column.field}>
          <FormControlLabel control={<Checkbox
            disabled={column.hideable === false}
            checked={columnVisibilityModel[column.field] !== false}
            onClick={toggleColumn}
            name={column.field}
            sx={{ p: 0.5 }}
          />} label={column.headerName || column.field} />
        </Box>
      })
    }
  </Box>
}


const ViewColumnAction = (props: Record<any, any>) => {
  const { dataGridRef: apiRef } = useReportViewContext()
  const popupState = usePopupState({ variant: "popover", popupId: "ViewColumnAction" })
  return <>
    <>
      <Tooltip title={"Columns"}>
        <MainIconButton {...bindTrigger(popupState)}>
          <ViewColumnOutlinedIcon />
        </MainIconButton>
      </Tooltip>
      {apiRef.current && <Popover
        {...bindPopover(popupState)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <ColumnsPopover apiRef={apiRef} />

      </Popover>
      }
    </>
  </>
}

export default ViewColumnAction

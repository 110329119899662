import {Box, Button, Stack, Typography} from "@mui/material";
import {useAppContext} from "@/provider/AppProvider";
import React from "react";
import urls from "@/urls";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import CompanyLogo from "@/layout/Header/components/CompanyLogo";
import Plugins from "@/layout/Header/components/Plugins";

const Apps = () => {
  const {t} = useTranslation("menu")
  const {currentRole} = useAppContext()
  const navigate = useNavigate()
  const apps = [
    {name: 'dashboard', string: t("menu.dashboard"), url: urls.dashboard, requireRoles: ["tenement"]},
    {name: 'company', string: t("menu.company"), requireRoles: ["tenement"]},
    {name: 'complement', string: t("menu.complement"), requireRoles: ["tenement"]},
    {name: 'personal', string: t("menu.personal"), requireRoles: ["employee"]},
    {name: 'team', string: t("menu.team"), requireRoles: ["tenement"]},
    // {name: 'ralvie', string: t("menu.ralvie"), url: urls.ralvie.root, requireRoles: ["tenement"]},
    {name: 'device', string: t("menu.device"), requireRoles: ["tenement"]},
    {name: 'gps', string: t("menu.gps"), requireRoles: ["tenement"]},
    {name: 'att', string: t("menu.attendance"), requireRoles: ["tenement"]},
    {name: 'acc', string: t("menu.accessControl"), requireRoles: ["tenement"]},
    {name: 'report', string: t("menu.report"), requireRoles: ["tenement"]},
    {name: 'system', string: t("menu.systemSetting"), requireRoles: ["tenement"]},
    {
      name: 'vendor',
      string: t("menu.vendor"),
      url: urls.vendor.root,
      requireRoles: ["vendor", "agent"]
    },
    // {name: 'tenement', string: t("menu.tenement"), requireRoles: ["vendor", "agent"]},
    // {name: 'subscription', string: t("menu.subscription"), requireRoles: ["vendor", "agent"]},
    {
      name: 'onlineSubscription', string: t("menu.subscription"), requireRoles: ["tenement"],
      url: urls.onlineSubscription.root, visible: false
    },
  ]
  const {viewApp, switchViewApp} = useAppContext()
  const isVisible = (app: Record<any, any>) => {
    if ('visible' in app) {
      return app.visible
    } else {
      return true
    }
  }
  const visibleApps = apps.filter((app) => app.requireRoles.find(role => role === currentRole) && isVisible(app))
  return (
    <Stack direction={"row"} sx={{flex: 1, overflowX: "hidden"}}>
      <CompanyLogo/>
      <Stack direction={"row"} spacing={1}>
        {visibleApps.length > 1 && visibleApps.map((app: Record<any, any>) => {
          return (
            <Box
              key={app.name}
               className={viewApp === app.name ? "Mui-selected" : ""}
               sx={{
                 "&.Mui-selected": {
                    borderBottom: "3px solid #2E85CF",
                    color: "#2E85CF"
                  },
                 "&.Mui-selected .MuiTypography-root": {
                   color: "#2E85CF"
                 }
               }}
            >
              <Button
                sx={{
                  padding: "8px",
                  height: "61px",
                  borderRadius: 0,
                }}
                onClick={() => switchViewApp(app.name)}
              >
                <Typography variant={"h4"} sx={{textTransform: "none"}}>{app.string}</Typography>
              </Button>
            </Box>
          )
        })}
        {currentRole === "tenement" && <Plugins />}
      </Stack>
    </Stack>
  )
}
export default Apps

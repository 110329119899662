import {useTranslation} from "react-i18next";
import {RJSFSchema, UiSchema} from "@rjsf/utils";
import {Box, Button, Grid, Typography} from "@mui/material";
import LayoutForm from "@/components/rjsf/Form";
import validator from "@rjsf/validator-ajv8";
import {Stack} from "@mui/system";
import {useRef} from "react";

const NodeRecordView = (props: any) => {
  const {onDiscard, formData} = props
  const {t} = useTranslation("per");
  const formRef = useRef<any>()
  const formSchema: RJSFSchema = {
    "type": "object",
    "required": ["node_code", "node_name"],
    "properties": {
      "node_code": {
        "type": "string",
        "title": t("workflowNode.code")
      },
      "node_name": {
        "type": "string",
        "title": t("workflowNode.name"),
      }
    }
  }

  const uiSchema: UiSchema = {}


  const onConfirm = async () => {
    const isValid = formRef.current?.validateForm()
    if (isValid) {
      const formData = formRef.current?.state.formData
      props.onConfirm(formData)
    }
  }

  return (
    <Grid container sx={{width: "352px", p: 2, height: "100%"}}>
      <Grid item xs={12} sx={{height: "calc(100% - 38px)", overflowY: "auto"}}>
        <Box sx={{pb: 2}}>
          <Typography>
            Grant
          </Typography>
        </Box>
        <LayoutForm
          ref={formRef}
          schema={formSchema}
          uiSchema={uiSchema}
          widgets={{}}
          validator={validator}
          formData={formData}
          formContext={{}}
        />
      </Grid>
      <Grid item xs={12}>
        <Stack direction={"row"} spacing={1}>
          <Button title={t("common.button.confirm", {ns: "common"})} variant={"contained"} onClick={onConfirm}>
            {t("common.button.confirm", {ns: "common"})}
          </Button>
          <Button title={t("common.button.discard", {ns: "common"})} onClick={onDiscard}>
            {t("common.button.discard", {ns: "common"})}
          </Button>
        </Stack>
      </Grid>
    </Grid>
  )
}
export default NodeRecordView

import AvatarWidget from "@/components/rjsf/widgets/AvatarWidget";
import UploadLogoWidget from "@/components/rjsf/widgets/UploadLogoWidget";
import Many2OneWidget from "@/components/rjsf/widgets/Many2OneWidget";
import Many2ManyWidget from "@/components/rjsf/widgets/Many2ManyWidget";
import Many2OneTreeWidget from "@/components/rjsf/widgets/Many2OneTreeWidget";
import Many2ManyTreeWidget from "@/components/rjsf/widgets/Many2ManyTreeWidget";
import SwitchWidget from "@/components/rjsf/widgets/SwitchWidget";
import CheckboxWidget from "@/components/rjsf/widgets/CheckboxWidget";
import MultiPhotoWidget from "@/components/rjsf/widgets/MultiPhotoWidget";
import { RegistryWidgetsType } from "@rjsf/utils";
import ProfilePhoto from "@/components/rjsf/widgets/ProfilePhotoWidget";
import MinuteWidget from "@/components/rjsf/widgets/MinuteWidget";
import HourWidget from "@/components/rjsf/widgets/HourWidget";
import Switch2Widget from "@/components/rjsf/widgets/Switch2Widget";
import DateWidget from "@/components/rjsf/widgets/DateWidget";
import TimeWidget from "@/components/rjsf/widgets/TimeWidget";
import SecondWidget from "@/components/rjsf/widgets/SecondWidget";
import ColorInputWidget from "./ColorInputWidget";
import QuantifierWidget from "@/components/rjsf/widgets/QuantifierWidget";
import DateTimeNoTZWidget from "./DateTimeNoTZWidget";

export const customWidgets: RegistryWidgetsType = {
  avatar: AvatarWidget,
  logo: UploadLogoWidget,
  profilePhoto: ProfilePhoto,
  m2o: Many2OneWidget,
  "m2o:tree": Many2OneTreeWidget,
  m2m: Many2ManyWidget,
  "m2m:tree": Many2ManyTreeWidget,
  switch: SwitchWidget,
  switch2: Switch2Widget,
  checkbox: CheckboxWidget,
  "multi-photo": MultiPhotoWidget,
  second: SecondWidget,
  minute: MinuteWidget,
  hour: HourWidget,
  DateWidget,
  time: TimeWidget,
  datetime: DateTimeNoTZWidget,
  'color': ColorInputWidget,
  'quantifier': QuantifierWidget,
};

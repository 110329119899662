import {useTranslation} from "react-i18next";
import {GridColDef} from "@mui/x-data-grid";
import {ReportEndPoint} from "@/services/report/endpoints";
import urls from "@/urls";
import ReportView from "@/pages/Report/ReportLibrary/components/ReportView";
import { useReportEmployee } from "@/hooks/employee";


const TotalTimecardReport = () => {
  const {t} = useTranslation("report")
  const {t: per} = useTranslation("per")
  const employeeFields = useReportEmployee()
  const dangerouslySetInnerHTML = (params: any) => {
    return <div dangerouslySetInnerHTML={{ __html: params.value }} />
  }
  const columns: GridColDef[] = [
    ...employeeFields,
    {
      field: "att_date",
      headerName: t("report.column.scheduleDate"),
    }, {
      field: "timetable_name",
      headerName: t("report.column.timetable"),
    }, {
      field: "duration",
      headerName: t("report.column.duration"),
    }, {
      field: "check_in",
      headerName: t("report.column.checkIn"),
    }, {
      field: "check_out",
      headerName: t("report.column.checkOut"),
    }, {
      field: "duty_duration",
      headerName: t("report.dutyDuration"),
    }, {
      field: "work_day",
      headerName: t("report.column.workDay"),
    }, {
      field: "clock_in",
      headerName: t("report.column.clockIn"),
    }, {
      field: "clock_out",
      headerName: t("report.column.clockOut"),
    }, {
      field: "total_time",
      headerName: t("report.column.totalTime"),
    }, {
      field: "duty_wt",
      headerName: t("report.column.dutyWorkTime"),
    }, {
      field: "actual_wt",
      headerName: t("report.column.actualWorkTime"),
    }, {
      field: "unscheduled",
      headerName: t("report.column.unschedule"),
    }, {
      field: "remaining",
      headerName: t("report.column.remaining"),
    }, {
      field: "late",
      headerName: t("report.column.late"),
    }, {
      field: "break_late",
      headerName: t("report.column.breakTimeLate"),
    }, {
      field: "early_leave",
      headerName: t("report.column.earlyLeave"),
    }, {
      field: "absent",
      headerName: t("report.column.absent"),
    }, {
      field: "break_absent",
      headerName: t("report.column.breakTimeAbsent"),
    }, {
      field: "total_worked",
      headerName: t("report.column.totalWorkTime"),
    }, {
      field: "leave",
      headerName: t("report.column.leave"),
    }, {
      field: "normal_wt",
      headerName: t("report.column.normalWorkTime"),
    }, {
      field: "break_duration",
      headerName: t("report.column.breakTimeDuration"),
    }, {
      field: "normal_ot",
      headerName: t("report.column.normalOT"),
    }, {
      field: "weekend_ot",
      headerName: t("report.column.weekOffOT"),
    }, {
      field: "holiday_ot",
      headerName: t("report.column.holidayOT"),
    }, {
      field: "ot_lv1",
      headerName: t("report.column.overtimeLV1"),
    }, {
      field: "ot_lv2",
      headerName: t("report.column.overtimeLV2"),
    }, {
      field: "ot_lv3",
      headerName: t("report.column.overtimeLV3"),
    },
    { field: "attendance_status",
      headerName: t("report.column.attendanceStatus"),
      renderCell: dangerouslySetInnerHTML},
  ]
  return (
    <ReportView
      title={t("reportLibrary.totalTimecard")}
      breadcrumbs={[]}
      columns={columns}
      uri={ReportEndPoint.TOTAL_TIMECARD_URI}
      url={urls.report.totalTimecard}
      getRowId={(row: any) => row.uuid}
      hideColumns={['first_name', 'last_name', 'department_code', 'position_code', 'position_name']}
    />
  )
}
export default TotalTimecardReport

import {useRoutes} from "react-router-dom";
import SwapScheduleApprovalListView from "@/pages/Personal/my-approval/swap_schedule/ListView";

const SwapScheduleApprovalRouter = () => {
  const routes = useRoutes([
    {index: true, element: <SwapScheduleApprovalListView />},
  ])
  return routes
}
export default SwapScheduleApprovalRouter;

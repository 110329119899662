import {RJSFSchema, UiSchema} from "@rjsf/utils";
import {useTranslation} from "react-i18next";
import RecordView from "@/components/views/RecordView";
import urls from "@/urls";
import {PsnlEP} from "@/services/psnl/endpoints";
import {GPSEndPoint} from "@/services/gps/endpoints";
import LocationWidget from "@/pages/GPS/GPSOfEmployee/components/LocationWidget";
import {useEffect, useRef, useState} from "react";
import MapProvider, {useMapContext} from "@/provider/MapProvider";

const GpsOfEmployeeRecordView = () => {
  const {t} = useTranslation('gps');
  const {t: common} = useTranslation("common");
  const ref = useRef<any>(null);
  const [formData, setFormData] = useState<Record<string, any>>({})
  const {mapInfo} = useMapContext()
  const formSchema: RJSFSchema = {
    type: "object",
    required: ["employee", "location", "longitude", "latitude", "distance", "start_date", "end_date"],
    properties: {
      employee: {
        type: "string",
        title: t("GPSOfEmployee.employee"),
      },
      location: {
        type: "string",
        title: t("GPSOfEmployee.location"),
      },
      distance: {
        type: "number",
        title: t("GPSOfEmployee.distance"),
        default: 10
      },
      longitude: {
        type: "number",
        title: t("GPSOfEmployee.longitude"),
        minimum: 0,
        readOnly: true
      },
      latitude: {
        type: "number",
        title: t("GPSOfEmployee.latitude"),
        readOnly: true
      },
      start_date: {
        type: "string",
        format: "date",
        title: t("GPSOfEmployee.startDate"),
      },
      end_date: {
        type: "string",
        format: "date",
        title: t("GPSOfEmployee.endDate"),
      }
    },
  };


  function dateValidate(formData: any, errors: any, uiSchema: any) {
    if (formData.start_date > formData.end_date) {
      errors.end_date.addError(common("common.message.startDateInvalid"));
    }
    return errors;
  }

  const uiSchema: UiSchema = {
    'ui:defaultXsWidth': 6,
    'ui:defaultRowWrap': false,
    employee: {
      "ui:rowWrap": true,
      "ui:widget": "m2o",
      "ui:options": {
        uri: PsnlEP.EMPLOYEE_URI,
      },
    },
    distance: {
      "ui:widget": "quantifier",
      "ui:options": {
        unit: "m"
      }
    },
    location: {
      "ui:widget": "location"
    }
  };

  const widgets = {
    location: LocationWidget
  }

  useEffect(() => {
    if (mapInfo) {
      setFormData({
        ...ref.current?.formData,
        location: mapInfo.place.formatted_address,
        latitude: mapInfo.place.geometry?.location?.lat(),
        longitude: mapInfo.place.geometry?.location?.lng()
      });
    }
  }, [mapInfo]);

  return (
    <RecordView
      ref={ref}
      breadcrumbs={[
        {title: t("menu.gps.geofenceOfEmployee"), href: urls.gps.gpsOfEmployee},
      ]}
      formSchema={formSchema}
      uiSchema={uiSchema}
      widgets={widgets}
      formData={formData}
      uri={GPSEndPoint.EMPLOYEE_GPS_URI}
      customValidate={dateValidate}
    />
  );
};
export default GpsOfEmployeeRecordView;

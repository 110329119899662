import request from "@/utils/Request";
import {PsnlEP} from "./endpoints";
import GenericService from "@/services/core/GenericService";

const Rehire = async (data: Record<string, any>) => {
  return await GenericService.action(PsnlEP.RESIGN_URI, 'reinstate/', data)
}

const DisableAttendance = async (data: Record<string, any>) => {
  return await GenericService.action(PsnlEP.RESIGN_URI, 'disable_attendance/', data)
}

const ResignService = {
  Rehire,
  DisableAttendance
}
export default ResignService

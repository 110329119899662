import {Box, Button, Input, Typography} from "@mui/material";
import {CloudUpload} from "@mui/icons-material";
import {WidgetProps} from "@rjsf/utils";
import {Stack} from "@mui/system";
import SnackbarUtil from "@/utils/SnackbarUtil";


const LogoWidget = (props: WidgetProps) => {
  const {label, value, placeholder} = props
  const onLogoChange = async (event: any) => {
    const files = event.target.files
    if (files.length > 0) {
      const avatar = files[0];
      if (avatar.type !== 'image/jpeg' && avatar.type !== 'image/png') {
        SnackbarUtil.warning('Logo only support .jpg, .jpeg, and .png')
        return;
      }
      const img = new Image();
      img.crossOrigin = 'Anonymous';
      img.src = URL.createObjectURL(avatar);
      img.onload = () => {
        if (img.width !== 200 && img.height !== 75) {
          SnackbarUtil.warning('Dimensions 200 x 75.')
          return
        }
        else{
          const reader = new FileReader()
          reader.readAsDataURL(avatar)
          reader.onload = (event) => {
            props.onChange(event.target?.result)
          }
        }
      }
    }
  }

  return (
    <Box sx={{width: "320px"}}>
      <Typography variant={"h4"} sx={{marginBottom: "16px"}}>{label}</Typography>
      <Button
        component={"label"}
        sx={{
          display: "flex",
          justifyContent: "center",
          border: value ? "1px solid #aaa" : "2px dashed #aaa",
          background: "#fafafa",
          height: "190px"
        }}
      >
        <input
          accept={".jpg, .jpeg, .png"}
          type={"file"}
          hidden
          onChange={onLogoChange}
        />
        {value
          ? <img style={{width: "200px", height: "75px"}} src={value}/>
          : <Stack
            sx={{
              display: "flex",
              alignItems: "center"
            }}
          >
            <CloudUpload sx={{fontSize: 60}}/>
            <Typography variant={"body1"}>{placeholder}</Typography>
          </Stack>
        }
      </Button>
    </Box>
  )
}
export default LogoWidget

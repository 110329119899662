import { useTranslation } from "react-i18next";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import urls from "@/urls";
import { PersonalEndPoint } from "@/services/personal/endpoints";
import { useEffect, useState } from "react";
import { usePunchStateHook } from "@/hooks/optionsHooks";
import ReportView from "../ReportView";
import { useReportEmployee } from "@/hooks/employee";

const TransactionReport = () => {
  const employeeFields = useReportEmployee()
  const { t } = useTranslation("report");
  const [columns, setColumns] = useState<GridColDef[]>([
    ...employeeFields,
    {
      field: "punch_date",
      headerName: t("report.column.punchDate"),
      flex: 1,
    },
    {
      field: "punch_time",
      headerName: t("report.column.punchTime"),
      flex: 1,
    },
    {
      field: "punch_state",
      headerName: t("report.column.punchState"),
      flex: 1,
    },
    {
      field: "source",
      headerName: t("report.column.source"),
      flex: 1,
      type: "singleSelect",
      valueOptions: [
        { value: 1, label: t("report.dataSource.device") },
        { value: 2, label: t("report.dataSource.manualLog") },
        { value: 3, label: t("report.dataSource.mobile") },
        { value: 4, label: t("report.dataSource.penDriver") },
      ],
    },
  ]);

  const punchStateOptions = usePunchStateHook();
  useEffect(() => {
    setColumns((old: any) => {
      return old.map((each: any) => {
        if (each.field === "punch_state") {
          return {
            ...each,
            type: "singleSelect",
            valueOptions: punchStateOptions,
          };
        } else {
          return each;
        }
      });
    });
  }, [punchStateOptions]);

  return (
    <ReportView
      title={t("reportLibrary.transactionReports")}
      breadcrumbs={[]}
      columns={columns}
      uri={PersonalEndPoint.TRANSACTION_URI}
      url={urls.personal.report.transaction}
      hideColumns={['first_name', 'last_name', 'department_code', 'position_code', 'position_name']}
    />
  );
};
export default TransactionReport;

import jwtDecode from "jwt-decode";
import request from "./Request";
import CryptoJS from "crypto-js"

const key = CryptoJS.enc.Utf8.parse("BioTimeCloud@Global@2024")

const aesEncrypt = (value: string) => {
  const src = CryptoJS.enc.Utf8.parse(value)
  console.log(src)
  const result = CryptoJS.AES.encrypt(src, key, {
    iv: CryptoJS.enc.Utf8.parse("Global@2024@BioTimeCloud"),
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7
  })
  return result.toString()
}

const aesDecrypt = (src: string) => {
  const result =CryptoJS.AES.decrypt(src, key, {
    iv: CryptoJS.enc.Utf8.parse("Global@2024@BioTimeCloud"),
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7
  })
  return result.toString(CryptoJS.enc.Utf8).toString()
}

const uid = (len = 8) => {
  const buf: string[] = []
  const chars = 'ABCDEFGHIGKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  for (let i=0; i < len; i ++){
    buf[i] = chars.charAt(Math.floor(Math.random() * chars.length))
  }
  return buf.join("")
}

const formatString = (format: string, ...args: any[]) => {
  return format.replace(/{(\d+)}/g, (match, index) => {
    return typeof args[index] !== 'undefined'? args[index]: match
  })
}

const getUserId = () => {
  const jwtToken = localStorage.getItem("accessToken") || "";
  const decodedToken = jwtDecode<any>(jwtToken);
  return decodedToken.user_id;
}

const Utils = {
  uid,
  aesEncrypt,
  aesDecrypt,
  formatString,
  getUserId
}
export default Utils


export async function downloadFile(url: string, post=false, data={}) {
  let res: any
  if (post) {
    res = await request.authorizedAxios().post(url, data, {responseType: 'blob'})
  } else {
    res = await request.authorizedAxios().get(url, {responseType: 'blob'})
  }
  const downloadElement = document.createElement('a')
  const binaryData = [];
  binaryData.push(res.data);
  const href = window.URL.createObjectURL(new Blob(binaryData));
  const contentDisposition = res.headers['content-disposition']
  const patt = new RegExp('filename=([^;]+\\.[^\\.;]+);*')
  const result = patt.exec(contentDisposition)
  let filename = 'download_file'
  if (result) {
    filename = result[1].replaceAll('"', '')
  } else {
    const tempList = url.split('/')
    filename = tempList[tempList.length - 1]
  }
  downloadElement.style.display = 'none'
  downloadElement.href = href
  downloadElement.download = filename
  document.body.appendChild(downloadElement)
  downloadElement.click() // 点击下载
  document.body.removeChild(downloadElement) // 下载完成移除元素
  window.URL.revokeObjectURL(href) // 释放掉blob对象
}

import {PsnlEP} from "@/services/psnl/endpoints";
import {CustomValidator, RJSFSchema, UiSchema} from "@rjsf/utils";
import {useTranslation} from "react-i18next";
import {useLocation} from "react-router-dom";
import RecordView from "@/components/views/RecordView";
import RegExps from "@/utils/RegExps";

const DepartmentRecordView = () => {
  const {t} = useTranslation("company");
  const {t: common} = useTranslation("common");
  const params = useLocation();
  const mode = params.state?.mode || "ADD";
  const formSchema: RJSFSchema = {
    type: "object",
    required: ["dept_code", "dept_name"],
    properties: {
      dept_code: {
        type: "string",
        title: t("department.code"),
        readOnly: mode === "EDIT",
        "maxLength": 50,
      },
      dept_name: {
        type: "string",
        title: t("department.name"),
        "maxLength": 100
      },
      parent_dept: {
        type: ["number", "null"],
        title: t("department.superior"),
      },
    },
  };

  const customValidate: CustomValidator = (formData, errors, uiSchema) => {
    if (formData.dept_code && RegExps.xssClean.test(formData.dept_code)) {
      errors.dept_code?.addError(common("form.validateError.illegalInput"))
    }
    if (formData.dept_name && RegExps.xssClean.test(formData.dept_name)) {
      errors.dept_name?.addError(common("form.validateError.illegalInput"))
    }

    return errors
  }

  const uiSchema: UiSchema = {
    "ui:defaultXsWidth": 4,
    "ui:submitButtonOptions": {norender: true},
    parent_dept: {
      "ui:widget": "m2o",
      "ui:options": {
        uri: PsnlEP.DEPARTMENT_URI,
      },
    },
  };
  return (
    <RecordView
      formSchema={formSchema}
      uiSchema={uiSchema}
      uri={PsnlEP.DEPARTMENT_URI}
      customValidate={customValidate}
    />
  );
};
export default DepartmentRecordView;

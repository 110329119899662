import {CustomValidator, RJSFSchema, UiSchema} from "@rjsf/utils";
import {useTranslation} from "react-i18next";
import RecordView from "@/components/views/RecordView";
import {PsnlEP} from "@/services/psnl/endpoints";
import {useLocation} from "react-router-dom";
import RegExps from "@/utils/RegExps";


const AreaRecordView = () => {
  const {t} = useTranslation("company");
  const {t: common} = useTranslation("common");
  const params = useLocation();
  const mode = params.state?.mode || "ADD";
  const formSchema: RJSFSchema = {
    "type": "object",
    "required": [
      "area_code",
      "area_name",
    ],
    "properties": {
      "area_code": {
        "type": "string",
        "title": t("area.code"),
        readOnly: mode === "EDIT",
        "maxLength": 100,
      },
      "area_name": {
        "type": "string",
        "title": t("area.name"),
        "maxLength": 120,
      },
      "parent_area": {
        "type": ["number", "null"],
        "title": t("area.superior"),
      }
    }
  };

  const uiSchema: UiSchema = {
    'ui:defaultXsWidth': 4,
    "ui:submitButtonOptions": {"norender": true},
    "parent_area": {
      "ui:widget": "m2o",
      "ui:options": {
        uri: PsnlEP.AREA_URI
      }
    }
  };

  const customValidate: CustomValidator = (formData, errors, uiSchema) => {
    if (formData.area_code && RegExps.xssClean.test(formData.area_code)) {
      errors.area_code?.addError(common("form.validateError.illegalInput"))
    }
    if (formData.area_name && RegExps.xssClean.test(formData.area_name)) {
      errors.area_name?.addError(common("form.validateError.illegalInput"))
    }

    return errors
  }

  return (
    <RecordView
      formSchema={formSchema}
      uiSchema={uiSchema}
      uri={PsnlEP.AREA_URI}
      customValidate={customValidate}
    />
  )
}
export default AreaRecordView

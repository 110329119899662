import {useTranslation} from "react-i18next";
import {RJSFSchema, UiSchema} from "@rjsf/utils";
import RecordView from "@/components/views/RecordView";
import {GPSEndPoint} from "@/services/gps/endpoints";
import OutdoorScheduleItemLayout from "@/pages/GPS/schedule-planner/components/OutdoorScheduleItemLayout";
import OutdoorScheduleTemplate from "@/pages/GPS/schedule-planner/components/OutdoorScheduleTemplate";
import dayjs from "dayjs";


const SchedulePlannerRecordView = () => {
  const {t} = useTranslation("gps");
  const formSchema: RJSFSchema = {
    type: "object",
    properties: {
      schedule_name: {
        type: "string",
        title: t("schedulePlanner.name"),
        maxLength: 55
      },
      schedule_details: {
        type: "array",
        title: "Schedule",
        items: {
          type: "object",
          properties: {
            client_id: {
              type: "integer",
              title: t("schedulePlanner.company")
            },
            dates: {
              type: "string",
              title: t("schedulePlanner.date")
            },
            inclock: {
              type: "string",
              title: t("schedulePlanner.startTime"),
            },
            outclock: {
              type: "string",
              title: t("schedulePlanner.endTime"),
            }
          },
          required: ["client_id", "dates", "inclock", "outclock"]
        },
        default: [{
          dates: dayjs().format("YYYY-MM-DD"),
          inclock: "09:00",
          outclock: "18:00"
        }]
      }
    },
    required: ['schedule_name']
  }
  const widgets = {
    schedule: OutdoorScheduleItemLayout
  }
  const uiSchema: UiSchema = {
    "ui:defaultXsWidth": 4,
    schedule_details: {
      "ui:ArrayFieldTemplate": OutdoorScheduleTemplate,
      "ui:xsWidth": 8,
      items: {
        "ui:ObjectFieldTemplate": OutdoorScheduleItemLayout,
        client_id: {
          "ui:widget": "m2o",
          "ui:options": {
            uri: GPSEndPoint.CLIENT_DETAILS_URI
          }
        },
        dates: {
          "ui:widget": "date"
        },
        inclock: {
          "ui:widget": "time"
        },
        outclock: {
          "ui:widget": "time"
        }
      }
    }
  }
  return (
    <RecordView
      formSchema={formSchema}
      widgets={widgets}
      uiSchema={uiSchema}
      uri={GPSEndPoint.SCHEDULE_PLANNER}
    />
  )
}
export default SchedulePlannerRecordView

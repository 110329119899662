import DashboardService from "@/services/ralvie/DashboardService";
import {useEffect, useState} from "react";
import {
  Box,
  Card, CardContent, CardHeader,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Typography
} from "@mui/material";
import {AppSettingsAlt, LaptopMacOutlined} from "@mui/icons-material";
import ChartCard from "@/pages/Ralvie/Dashboard/components/ChartCard";
import {useTranslation} from "react-i18next";


const MostUsedApp = (props: Record<string, any>) => {
  const {t} = useTranslation("ralvie")
  const [mostUsedApps, setMostUsedApps] = useState<Record<string, any>[]>([])
  const getMostUsedApps = async () => {
    const response = await DashboardService.getMostUsedApps(props.filters)
    setMostUsedApps(response.data?.applications)
  }

  useEffect(() => {
    getMostUsedApps().then()
  }, [props.filters])
  return (
    <ChartCard title={t("ralvie.mostUsedApplications")}>
      <List sx={{height: "calc(100% - 48px)", overflow: "auto"}}>
        {mostUsedApps?.map((app, index) => (
          <ListItem
            key={`app${index}`}
            secondaryAction={`${app.hours} h ${app.minutes} m`}
          >
            <ListItemAvatar>
              <LaptopMacOutlined/>
            </ListItemAvatar>
            <ListItemText sx={{marginRight: 6}}>{app.app}</ListItemText>
          </ListItem>
        ))}
      </List>
    </ChartCard>
  )
}
export default MostUsedApp

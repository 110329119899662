import { useLocation } from "react-router-dom";
import NormalTimeTable from "./NormalTimeTable";
import FlexibleTimeTable from "./FlexibleTimeTable";


const TimeTableRecordView = () => {
  const params = useLocation()
  const record = params.state?.record
  if (record.use_mode === 0) {
    return <NormalTimeTable />
  } else {
    return <FlexibleTimeTable />
  }
}
export default TimeTableRecordView

import {Box, Button, List, ListItem, ListItemButton, ListItemText, useTheme} from "@mui/material";
import {useTranslation} from "react-i18next";
import React, {ForwardedRef, useImperativeHandle, useState} from "react";
import Const from "@/utils/Const";


const ChangeLanguage = (props: Record<string, any>, ref: ForwardedRef<any>) => {
  const {t} = useTranslation()
  const theme = useTheme()
  const [selected, setSelected] = useState<string>(props.defaultLanguage)

  useImperativeHandle(ref, () => ({
    selected
  }))

  return (
    <Box sx={{width: "248px", height: "136px"}}>
      <List>
        {Const.languages.map((language) => {
          const isSelected = selected === language.code
          return (
            <ListItemButton
              key={language.code}
              dense
              selected={isSelected}
              onClick={async () => setSelected(language.code)}
              sx={{
                "&.Mui-selected, &.Mui-selected:hover": {
                  backgroundColor: theme.palette.primary.main,
                  color: "white"
                }
              }}
            >
              <ListItemText>{language.title}</ListItemText>
            </ListItemButton>
          )
        })}
      </List>
    </Box>
  )
}
export default React.forwardRef(ChangeLanguage)

import Grid from "@mui/material/Grid2";
import FilterPanel from "./FilterPanel";
import ViewPanel from "./ViewPanel";
import { Stack } from "@mui/material";
import RefreshAction from "@/components/views/components/RefreshAction";
import { useReportViewContext } from "./ReportViewProvider";

const HeaderToolBar = () => {
  const { refreshGridData } = useReportViewContext();

  return (
    <Stack direction={"row"} gap={1}>
      <FilterPanel />
      <Grid flex={1} />
      <ViewPanel />
      <RefreshAction onRefresh={refreshGridData} />
    </Stack>
  )
}
export default HeaderToolBar

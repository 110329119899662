import {Route, Routes} from "react-router-dom";
import EmployeeRecordView from "@/pages/Team/Employee/RecordView";
import EmployeeListView from "./ListView";
import EmployeeImportView from "@/pages/Team/Employee/ImportView";


const EmployeeRouter = () => {
  return (
    <Routes>
      <Route index element={<EmployeeListView />}/>
      <Route path={"add/"} element={<EmployeeRecordView />}/>
      <Route path={"edit/"} element={<EmployeeRecordView />}/>
      <Route path={"import"} element={<EmployeeImportView />}/>
    </Routes>
  )
};
export default EmployeeRouter

import ListView from "@/components/views/ListView";
import {GridColDef} from "@mui/x-data-grid";
import AppUrls from "@/urls";
import {DeviceEndPoint} from "@/services/device/endpoints";
import {useTranslation} from "react-i18next";

const UploadLogListView = () => {
  const { t } = useTranslation("device");
  const columns: GridColDef[] = [
    {field: "sn", headerName: t("device.sn"), flex: 1},
    {field: "alias", headerName: t("device.alias"), flex: 1},
    { field: "event", headerName: t("uploadLog.event"), flex: 1 },
    { field: "content", headerName: t("uploadLog.content"), flex: 1 },
    { field: "upload_count", headerName: t("uploadLog.uploadCount"), flex: 1 },
    { field: "error_count", headerName: t("uploadLog.errorCount"), flex: 1 },
    {
      field: "upload_time",
      headerName: t("uploadLog.uploadTime"),
      flex: 1,
    },
  ];

  const filters = [
    {
      key: "terminal__sn",
      label: t("device.sn"),
      type: "string",
    },
    {
      key: "terminal__alias",
      label: t("device.alias"),
      type: "string",
    },
    {
      key: "event",
      label: t("uploadLog.event"),
      type: "string",
    },
  ];

  return (
    <ListView
      disableAdd
      disableEdit
      title={t("menu.device.uploadLog", {ns: "menu"})}
      columns={columns}
      uri={DeviceEndPoint.UPLOAD_LOG_URI}
      url={AppUrls.device.uploadLog}
      filters={filters}
      excludeActions={["delete"]}
    />
  );
};
export default UploadLogListView;

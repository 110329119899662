const API_VER = 'v2'
export const AuthEP = {
  COMPANY_URI: `/personnel/api/${API_VER}/companies/`,
  USER_URI: `/accounts/api/${API_VER}/users/`,
  USER_GROUP_URI: `/accounts/api/${API_VER}/groups/`,
  USER_PERMISSION_URI: `/accounts/api/${API_VER}/permissions/`,
  CURRENT_USER: `/accounts/api/${API_VER}/users/current-user/`,
  CURRENT_COMPANY: `/accounts/api/${API_VER}/users/current-company/`,
  PERMISSION_URI: `/accounts/api/${API_VER}/permissions/`,
  GROUP_URI: `/accounts/api/${API_VER}/groups/`,
  ACCOUNT_URI: `/accounts/api/${API_VER}/users/`,
  ADMIN_LOG_URI: `/base/api/${API_VER}/admin_logs/`,
  AUTO_EXPORT_URI: `/base/api/${API_VER}/auto_export/`,
}

import {useTranslation} from "react-i18next";
import {RJSFSchema, UiSchema} from "@rjsf/utils";
import {AuthEP} from "@/services/auth/endpoints";
import RecordView from "@/components/views/RecordView";
import PermissionWidget from "@/pages/SystemSetting/Group/components/PermissionWidget";


const GroupRecordView = () => {
  const {t} = useTranslation('sys');
  const formSchema: RJSFSchema = {
    "type": "object",
    properties: {
      name: {
        type: "string",
        title: t("group.name"),
        maxLength: 50
      },
      permissions: {
        type: "array",
        items: {
          type: "string"
        }
      }
    },
    required: ['name']
  }
  const uiSchema: UiSchema = {
    name: {
      "ui:xsWidth": 4
    },
    permissions: {
      "ui:widget": "permissions"
    }
  }
  const widgets = {
    permissions: PermissionWidget
  }
  return (
    <RecordView
      formSchema={formSchema}
      uiSchema={uiSchema}
      widgets={widgets}
      uri={AuthEP.GROUP_URI}
    />
  )
}
export default GroupRecordView

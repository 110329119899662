import {useTranslation} from "react-i18next";
import {GridColDef} from "@mui/x-data-grid";
import AppUrls from "@/urls";
import {AuthEP} from "@/services/auth/endpoints";
import ListView from "@/components/views/ListView";


const AdminLogListView = () => {
  const {t} = useTranslation("sys")
  const {t: common} = useTranslation("common")
  const columns: GridColDef[] = [
    {field: "action", headerName: t("actionLog.action"), flex: 1},
    {field: "content_type_name", headerName: t("actionLog.contentType"), flex: 1},
    {field: "targets_repr", headerName: t("actionLog.object"), flex: 1},
    {field: "action_status", headerName: t("actionLog.status"), flex: 1,
      type: "singleSelect",
      valueOptions: [
        { value: 0, label: common("common.success")},
        { value: 0, label: common("common.failed")},
      ],
    },
    {field: "ip_address", headerName: t("actionLog.ipAddress"), flex: 1},
    {field: "description", headerName: t("actionLog.description"), flex: 1},
    {field: "op_time", headerName: t("actionLog.actionTime"), flex: 1},
  ]

  const filters = [
    {key: 'action', label: t("actionLog.action"), type: 'string'},
  ]

  return (
    <ListView
      title={t("menu.systemSetting.operationLog", {ns: "menu"})}
      columns={columns}
      uri={AuthEP.ADMIN_LOG_URI}
      url={AppUrls.system.operationLog}
      filters={filters}
      disableAdd={true}
      disableDelete={true}
    />
  )
}
export default AdminLogListView

import { TabPanel } from "@mui/lab";
import MultiSelectTreeView from "./MultiSelectTreeView";
import { useEffect, useState } from "react";


const PermissionView = (props: any) => {
  const { itemKey, onStatusChange, initSelecteds } = props

  const nodes: any[] = [
    {
      "id": "acc.accterminal",
      "label": "Device",
      "children": [
        {
          "codename": "change_accterminal",
          "label": "change_accterminal"
        },
        {
          "codename": "cancel_alarm_accterminal",
          "label": "cancel_alarm_accterminal"
        },
        {
          "codename": "set_parameter_accterminal",
          "label": "set_parameter_accterminal"
        },
        {
          "codename": "terminal_reload_data_accterminal",
          "label": "terminal_reload_data_accterminal"
        },
        {
          "codename": "open_door_accterminal",
          "label": "open_door_accterminal"
        },
        {
          "codename": "view_accterminal",
          "label": "view_accterminal"
        }
      ]
    },
    {
      "id": "acc.acctimezone",
      "label": "Time Period",
      "children": [
        {
          "codename": "view_acctimezone",
          "label": "view_acctimezone"
        },
        {
          "codename": "delete_acctimezone",
          "label": "delete_acctimezone"
        },
        {
          "codename": "add_acctimezone",
          "label": "add_acctimezone"
        },
        {
          "codename": "clone_to_area_acctimezone",
          "label": "clone_to_area_acctimezone"
        },
        {
          "codename": "change_acctimezone",
          "label": "change_acctimezone"
        }
      ]
    },
    {
      "id": "acc.accholiday",
      "label": "Holiday",
      "children": [
        {
          "codename": "change_accholiday",
          "label": "change_accholiday"
        },
        {
          "codename": "add_accholiday",
          "label": "add_accholiday"
        },
        {
          "codename": "delete_accholiday",
          "label": "delete_accholiday"
        },
        {
          "codename": "view_accholiday",
          "label": "view_accholiday"
        },
        {
          "codename": "clone_to_area_accholiday",
          "label": "clone_to_area_accholiday"
        }
      ]
    },
    {
      "id": "acc.accgroups",
      "label": "Group",
      "children": [
        {
          "codename": "set_employee_group_accgroups",
          "label": "set_employee_group_accgroups"
        },
        {
          "codename": "add_accgroups",
          "label": "add_accgroups"
        },
        {
          "codename": "change_accgroups",
          "label": "change_accgroups"
        },
        {
          "codename": "delete_accgroups",
          "label": "delete_accgroups"
        },
        {
          "codename": "clone_to_area_accgroups",
          "label": "clone_to_area_accgroups"
        },
        {
          "codename": "view_accgroups",
          "label": "view_accgroups"
        }
      ]
    },
    {
      "id": "acc.acccombination",
      "label": "Combination",
      "children": [
        {
          "codename": "view_acccombination",
          "label": "view_acccombination"
        },
        {
          "codename": "add_acccombination",
          "label": "add_acccombination"
        },
        {
          "codename": "change_acccombination",
          "label": "change_acccombination"
        },
        {
          "codename": "delete_acccombination",
          "label": "delete_acccombination"
        },
        {
          "codename": "clone_to_area_acccombination",
          "label": "clone_to_area_acccombination"
        }
      ]
    },
    {
      "id": "acc.accprivilege",
      "label": "Privilege",
      "children": [
        {
          "codename": "change_accprivilege",
          "label": "change_accprivilege"
        },
        {
          "codename": "delete_accprivilege",
          "label": "delete_accprivilege"
        },
        {
          "codename": "adjust_employee_privilege_accprivilege",
          "label": "adjust_employee_privilege_accprivilege"
        },
        {
          "codename": "view_accprivilege",
          "label": "view_accprivilege"
        },
        {
          "codename": "add_accprivilege",
          "label": "add_accprivilege"
        }
      ]
    }
  ]

  const [treeNodes, setTreeNodes] = useState<any[]>([]);
  const [allIds, setaAllIds] = useState<any[]>([]);
  useEffect(() => {
    const newNodes: any = []
    const newAllIds: any = []
    if (Object.keys(itemKey).length > 0) {
      nodes.forEach((node) => {
        const newChildren: any = []
        if (itemKey[node.id]) {
          node.children.forEach((child: any) => {
            if (itemKey[node.id][child.codename]) {
              newChildren.push({ ...child, id: itemKey[node.id][child.codename].toString() })
              newAllIds.push(itemKey[node.id][child.codename].toString())
            } else {
              console.log('error key', node.id, child.codename)
            }
          })
          node.children = newChildren
        } else {
          console.log('error key:', node.id)
        }
        newNodes.push(node)
      })
    }
    setTreeNodes(newNodes)
    setaAllIds(newAllIds)
  }, [itemKey])

  return (
    <TabPanel value={"acc"}>
      <MultiSelectTreeView nodes={treeNodes}
        selected={initSelecteds}
        onSelectedChange={(ids: any) => {
          const unSelected = allIds.filter(each => ids.indexOf(each) === -1)
          onStatusChange && onStatusChange(ids, unSelected)
        }}
      />
    </TabPanel>
  )
}
export default PermissionView

import {Box, ImageList, ImageListItem, ImageListItemBar, Typography} from "@mui/material";
import {useAppContext} from "@/provider/AppProvider";
import {Stack} from "@mui/system";
import {useRegionContext} from "@/provider/RegionProvider";


const About = () => {
  const {currentCompany} = useAppContext()
  const {region} = useRegionContext()
  return (
    <Box
      sx={{
        minWidth: "320px",
        minHeight: "128px",
        p: 2
      }}
    >
      <Stack spacing={1}>
        <Box>
          <Typography sx={{fontWeight: 600}}>{`BioTime Cloud 2.0.1`}</Typography>
          <Typography>{`Build 2024-09-18 10:00:00`}</Typography>
        </Box>
        <Box>
          <Typography sx={{fontWeight: 600}}>{`License to ${currentCompany.name}`}</Typography>
          <Typography>{`Expiration date: 2025/01/01`}</Typography>
        </Box>
        <Box>
          <Typography sx={{fontWeight: 600}}>{`App Download`}</Typography>
          <ImageList sx={{width: "160px", height: "auto"}}>
            <ImageListItem>
              <img src={`/images/${region.identify_code}/ios.png`}/>
              <ImageListItemBar position={"below"} title={"App Store"}/>
            </ImageListItem>
            <ImageListItem>
              <img src={`/images/${region.identify_code}/android.png`}/>
              <ImageListItemBar position={"below"} title={"Play Store"}/>
            </ImageListItem>
          </ImageList>
        </Box>
      </Stack>
    </Box>
  )
}
export default About

import LayoutForm from "@/components/rjsf/Form"
import validator from "@rjsf/validator-ajv8"
import React, {ForwardedRef, useImperativeHandle, useRef} from "react"
import GenericService from "@/services/core/GenericService"
import SnackbarUtil from "@/utils/SnackbarUtil"
import {createBrowserHistory} from "history"
import {useTranslation} from "react-i18next"
import {useLocation} from "react-router-dom";
import ModelActionLayout from "@/components/views/components/ModelActionLayout";


const ModelActionPage = (props: Record<string, any>, ref: ForwardedRef<any>) => {
  const {t} = useTranslation("common")
  const formRef = useRef<any>()
  const history = createBrowserHistory()
  const params = useLocation()
  const _onSave = async (data: Record<string, any>) => {
    const response = await GenericService.action(props.uri, props.action, data)
    if (response.isSuccess) {
      SnackbarUtil.success(t("common.message.successful"))
      history.back()
    }
    // else {
    //   SnackbarUtil.error(response?.code)
    // }
  }
  const onSave = async () => {
    const isValid = formRef.current?.validateForm()
    const selectedKey = props.selectedKey || "objects"
    if (isValid) {
      const data = formRef.current?.state.formData
      const formData = {
        ...data,
        [selectedKey]: params.state?.selectedIds
      }
      if (props.onSave) {
        await props.onSave(formData)
      } else {
        await _onSave(formData)
      }
    }
  }

  const onDiscard = async () => {
    history.back()
  }

  const defaultRecordActions: any[] = [
    {title: t("common.button.save"), callback: onSave, disable: props.disableSave},
    {title: t("common.button.discard"), variant: "outlined", callback: onDiscard, disable: props.disableDiscard}
  ]

  useImperativeHandle(ref, () => ({
    formData: formRef.current?.state.formData
  }))

  return (
    <ModelActionLayout
      title={props.title}
      breadcrumbs={props.breadcrumbs}
      actions={defaultRecordActions.filter((action: any) => !action.disable)}
    >
      <LayoutForm
        ref={formRef}
        schema={props.formSchema}
        uiSchema={props.uiSchema}
        fields={props.fields}
        widgets={props.widgets}
        templates={props.templates}
        onChange={props.onChange}
        customValidate={props.customValidate}
        validator={validator}
        formData={props.formData}
      />
    </ModelActionLayout>
  )
}
export default React.forwardRef(ModelActionPage)

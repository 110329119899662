import {useTranslation} from "react-i18next";
import {GridColDef} from "@mui/x-data-grid";
import ListView from "@/components/views/ListView";
import AppUrls from "@/urls";
import {GPSEndPoint} from "@/services/gps/endpoints";
import {ChatBubble, SensorsOff, Block, CheckCircleOutline} from "@mui/icons-material";
import GPSService from "@/services/gps/GPSService";
import SnackbarUtil from "@/utils/SnackbarUtil";
import PushNotification from "@/pages/GPS/Accounts/components/PushNotification";


const AccountsListView = () => {
  const {t: t} = useTranslation("gps");
  const {t: tMenu} = useTranslation("menu");


  const columns: GridColDef[] = [
    {field: "username", headerName: t("accounts.username"), flex: 1},
    {field: "login_time", headerName: t("accounts.loginTime"), flex: 1},
    {field: "last_active", headerName: t("accounts.lastActive"), flex: 1},
    {field: "client_id", headerName: t("accounts.clientId"), flex: 1},
    {field: "device_token", headerName: t("accounts.mobileToken"), flex: 1},
    {field: "client_category", headerName: t("accounts.clientCategory"), flex: 1},
    {field: "active", headerName: t("accounts.runningStatus"), flex: 1},
    {field: "enable", headerName: t("accounts.mobileAppStatus"), flex: 1},
  ]

  const filters = [
    {key: 'username', label: t("accounts.username"), type: 'string'},
    {key: 'active', label: t("accounts.runningStatus"), type: 'string'},
    {key: 'enable', label: t("accounts.mobileAppStatus"), type: 'string'},
    {key: 'login_time', label: t("accounts.loginTime"), type: 'string'},
  ]


  const forceOffline = async (selectedIds: string) => {
    const response = await GPSService.forceOffline({ids: selectedIds});
    if (response.isSuccess) {
      SnackbarUtil.success("Success");
      return true;
    }
    SnackbarUtil.success(response.code);
    return false;
  };

  const DisableAccount = async (selectedIds: string) => {
    const response = await GPSService.Disable({ids: selectedIds});
    if (response.isSuccess) {
      SnackbarUtil.success("Success");
      return true;
    }
    SnackbarUtil.success(response.code);
    return false;
  };

  const EnableAccount = async (selectedIds: string) => {
    const response = await GPSService.Enable({ids: selectedIds});
    if (response.isSuccess) {
      SnackbarUtil.success("Success");
      return true;
    }
    SnackbarUtil.success(response.code);
    return false;
  };

  const modelActions = [
    {
      id: "pushNotification",
      icon: <ChatBubble/>,
      title: t("accounts.pushNotification"),
      display: "dialog",
      Form: PushNotification
    },
    {
      id: "forceOffline",
      icon: <SensorsOff/>,
      title: t("accounts.forceOffline"),
      display: "dialog",
      confirmation: t("accounts.forceOffline.confirm"),
      validateSelected: true,
      callback: forceOffline
    },
    {
      id: "disable",
      icon: <Block/>,
      title: t("accounts.disable"),
      display: "dialog",
      confirmation: t("accounts.disable.confirm"),
      callback: DisableAccount
    },
    {
      id: "enable",
      icon: <CheckCircleOutline/>,
      title: t("accounts.enable"),
      display: "dialog",
      confirmation: t("accounts.enable.confirm"),
      callback: EnableAccount
    },
  ]

  return (
    <ListView
      title={tMenu("menu.gps.accounts")}
      breadcrumbs={[
        {title: tMenu("menu.gps"), href: AppUrls.gps.root},
      ]}
      columns={columns}
      uri={GPSEndPoint.ACCOUNTS_URI}
      url={AppUrls.gps.accounts}
      filters={filters}
      modelActions={modelActions}
      disableAdd={true}
      disableEdit={true}
    />
  )
}
export default AccountsListView

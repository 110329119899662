import AppUrls from "@/urls";
import { DeviceEndPoint } from "@/services/device/endpoints";
import { RJSFSchema, UiSchema } from "@rjsf/utils";
import { useTranslation } from "react-i18next";
import RecordView from "@/components/views/RecordView";
import urls from "@/urls";

const WorkCodeRecordView = () => {
  const { t } = useTranslation();
  const formSchema: RJSFSchema = {
    type: "object",
    required: ["code", "alias"],
    properties: {
      code: {
        type: "string",
        title: t("workCode.code"),
      },
      alias: {
        type: "string",
        title: t("workCode.alias"),
      },
    },
  };

  const uiSchema: UiSchema = {
    "ui:defaultXsWidth": 4,
    "ui:submitButtonOptions": { norender: true },
  };
  return (
    <RecordView
      breadcrumbs={[
        { title: t("menu.device"), href: urls.device.root },
        { title: t("menu.device.workCode"), href: urls.device.workCode },
      ]}
      formSchema={formSchema}
      uiSchema={uiSchema}
      uri={DeviceEndPoint.WORK_CODE_URI}
      url={AppUrls.device.workCode}
    />
  );
};
export default WorkCodeRecordView;

import {useTranslation} from "react-i18next";
import {GridColDef} from "@mui/x-data-grid";
import AppUrls from "@/urls";
import {AttEndPoint} from "@/services/att/endpoints";
import ListView from "@/components/views/ListView";
import ApprovalIcon from "@mui/icons-material/Approval";
import ApprovalAction from "@/pages/Attendance/components/ApprovalAction";


const SwapScheduleListView = () => {
  const {t} = useTranslation("att");
  const {t: per} = useTranslation("per");
  const {t: common} = useTranslation("common");
  const columns: GridColDef[] = [
    {
      field: "emp_code",
      headerName: per("employee.code"),
      flex: 1,
    },
    {
      field: "first_name",
      headerName: per("employee.firstName"),
      flex: 1,
    },
    {
      field: "last_name",
      headerName: per("employee.lastName"),
      flex: 1,
    },
    {
      field: "att_date",
      headerName: t("swapSchedule.date"),
      flex: 1,
    },
    {
      field: "previous_timeinterval",
      headerName: t("swapSchedule.previousTimetable"),
      flex: 1,
    },
    {
      field: "current_time_interval",
      headerName: t("swapSchedule.currentTimetable"),
      flex: 1,
    },
    {
      field: "apply_reason",
      headerName: common("approval.applyReason"),
      flex: 1,
    },
    {
      field: "apply_time",
      headerName: common("approval.applyTime"),
      flex: 1,
    },
    {
      field: "audit_status",
      headerName: common("approval.status"),
      flex: 1,
      type: "singleSelect",
      valueOptions: [
        {value: 1, label: common("approval.status.pending")},
        {value: 2, label: common("approval.status.approved")},
        {value: 3, label: common("approval.status.rejected")},
        {value: 6, label: common("approval.status.revoked")},
        {value: 7, label: common("approval.status.withdraw")},
      ],
    },
    {
      field: "last_approver",
      headerName: common("approval.lastApprover"),
      flex: 1,
    },
    {
      field: "audit_reason",
      headerName: common("approval.remark"),
      flex: 1,
    },
  ]

  const modelActions = [
    {
      id: "swapScheduleApproval",
      title: t("manualLog.transfer"),
      icon: <ApprovalIcon/>,
      subs: [
        {
          id: "swapScheduleApprove",
          title: common("approval.action.approve"),
          validateSelected: true,
          display: "dialog",
          Form: ApprovalAction,
          state: {
            uri: AttEndPoint.SWAP_SCHEDULE_URI,
            action: "approve"
          }
        },{
          id: "swapScheduleReject",
          title: common("approval.action.reject"),
          validateSelected: true,
          display: "dialog",
          Form: ApprovalAction,
          state: {
            uri: AttEndPoint.SWAP_SCHEDULE_URI,
            action: "reject"
          }
        },{
          id: "swapScheduleRevoke",
          title: common("approval.action.revoke"),
          validateSelected: true,
          display: "dialog",
          Form: ApprovalAction,
          state: {
            uri: AttEndPoint.SWAP_SCHEDULE_URI,
            action: "revoke"
          }
        },
      ],
    },
  ];

  return (
    <ListView
      title={t("menu.att.scheduleAdjustment", {ns: "menu"})}
      columns={columns}
      uri={AttEndPoint.SWAP_SCHEDULE_URI}
      url={AppUrls.att.scheduleAdjustment}
      modelActions={modelActions}
      disableAdd={true}
    />
  )
}
export default SwapScheduleListView

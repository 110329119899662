import {GridColDef} from "@mui/x-data-grid";
import {useTranslation} from "react-i18next";
import ListView from "@/components/views/ListView";
import AppUrls from "@/urls";
import {AuthEP} from "@/services/auth/endpoints";
import {LockReset, PersonAdd} from "@mui/icons-material";
import InviteUser from "@/pages/SystemSetting/User/components/InviteUser";
import ChangePassword from "@/pages/SystemSetting/User/components/ChangePassword";


const UserListView = () => {
  const {t} = useTranslation("sys")
  const {t: menu} = useTranslation("menu")
  const columns: GridColDef[] = [
    {field: "username", headerName: t("user.username"), flex: 1},
    {field: "first_name", headerName: t("user.firstName"), flex: 1},
    {field: "last_name", headerName: t("user.lastName"), flex: 1},
    {field: "email", headerName: t("user.email"), flex: 1}
  ]

  const filters = [
    {key: 'username', label: t("user.username"), type: 'string'},
  ]

  const modelActions = [
    {
      id: "inviteUser",
      title: t("user.inviteUser"),
      icon: <PersonAdd/>,
      display: "dialog",
      Form: InviteUser
    },
  ]

  const recordActions = [
    {
      id: "changePassword",
      title: t("user.changePassword"),
      icon:<LockReset />,
      display: "dialog",
      Form: ChangePassword
    },
  ]

  return (
    <ListView
      title={menu("menu.systemSetting.user")}
      breadcrumbs={[
        {title: menu("menu.systemSetting"), href: AppUrls.system.root},
      ]}
      columns={columns}
      uri={AuthEP.ACCOUNT_URI}
      url={AppUrls.system.user}
      filters={filters}
      disableAdd={true}
      modelActions={modelActions}
      recordActions={recordActions}
    >
    </ListView>
  )
}
export default UserListView

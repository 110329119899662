import {Box, Stack} from "@mui/material";
import {Add, Delete, Edit} from "@mui/icons-material";
import {DataGrid, GridColDef, GridColumnVisibilityModel, useGridApiRef} from "@mui/x-data-grid";
import React, {ForwardedRef, useEffect, useImperativeHandle, useMemo, useRef, useState,} from "react";
import GenericService from "@/services/core/GenericService";
import ViewBreadcrumbs from "@/components/views/components/ViewBreadcrumbs";
import SnackbarUtil from "@/utils/SnackbarUtil";
import {ListViewContext} from "@/provider/ListViewProvider";
import HistoryAction from "../components/HistoryAction";
import ExportAction from "../components/ExportAction";
import RefreshAction from "../components/RefreshAction";
import ViewColumnAction from "../components/ViewColumnAction";
import FiltersAction from "../components/FiltersAction";
import RecordActionRender from "@/components/views/ListView/components/record-action/RecordActionRender";
import ListViewModelActionRender from "@/components/views/ListView/components/model-action/ModelActionRender";
import {useTranslation} from "react-i18next";
import Grid from "@mui/material/Grid2";
import CustomPagination from "../components/GridPagination";

type BreadcrumbProps = {
  title: string;
  href?: string;
  callback?: any;
};

type ListViewProps = {
  uri: string;
  /**
   * backend API interface
   */
  url: string;
  title: string;
  excludeActions?: Array<string>;
  extraActions?: Array<any>;
  extraRowActions?: Array<any>;
  filters?: Array<any>;
  defaultParams?: Record<any, any>;
  breadcrumbs?: BreadcrumbProps[];
  modelActions?: Record<string, any>[];
  recordActions?: Record<string, any>[];
  disableRowAction?: boolean;
  dataGrid?: Record<any, any>;
  disableAdd: false;
  disableEdit: false;
  disableDelete: false;
} & Omit<Record<any, any>, "ref">;

const ListView = (
  props: Omit<ListViewProps, "ref">,
  ref: ForwardedRef<any>
) => {
  const {t} = useTranslation("common");
  const {t: api} = useTranslation("api");
  const {FilterPanel, SearchPanel, RecordPanel, options, categoryLabel, approvalFilter, auditStatus} = props
  const columns: GridColDef[] = [...props.columns];
  const dataGridRef = useGridApiRef();
  const searchPanelRef = useRef<any>();
  const filterPanelRef = useRef<any>();
  const [searchPanelReady, setSearchPanelReady] = useState<boolean>(!props.SearchPanel)
  const [isLoading, setLoading] = useState<boolean>(true);
  const [gridData, setGridData] = useState<any>({data: [], total: 0});
  const [selectedRow, setSelectedRow] = useState<Record<string, any>>()
  const [paginationModel, setPaginationModel] = useState<{
    page: number;
    pageSize: number;
  }>({page: 0, pageSize: 10});
  const [filterParams, setFilterParams] = useState<Record<string, any>>({});
  const [searchPanelParams, setSearchPanelParams] = useState({})

  const updateFilterModel = (params: Record<string, any>) => {
    setFilterParams({ ...filterParams, ...params });
    setSearchPanelReady(true);
  };

  const refreshData = () => {
    setLoading(true);
    GenericService.list(`${props.uri || ""}${props.resource || ""}`, {
      page: paginationModel.page + 1,
      page_size: paginationModel.pageSize,
      toggle_relation: "object",
      ...filterParams,
      ...searchPanelParams
    })
      .then((response) => {
        setGridData(response.data);
      })
      .catch((error) => {
        if (error.response.status < 500) {
          // do something when status is 4xx.
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onDelete = async (recordId: string) => {
    const response = await GenericService.destroy(props.uri, recordId);
    if (response.isSuccess) {
      SnackbarUtil.success(t("common.message.successful"));
      return true;
    }
    return false;
  };

  const onBulkDelete = async (selectedIds: number[]) => {
    const response = await GenericService.multiDestroy(props.uri, selectedIds);
    if (response.isSuccess) {
      SnackbarUtil.success(t("common.message.successful"));
      return true;
    }
    return false
  };

  const modelActions = useMemo(() => {
    const defaultModelActions = [
      {
        id: "add",
        title: t("common.action.add",{ns: "common"}),
        icon: <Add/>,
        disable: props.disableAdd,
        display: "page",
        url: `${props.url}/add`,
        state: {mode: "ADD", ...searchPanelParams},
      },
      {
        id: "bulkDelete",
        title: t("common.action.bulkDelete",{ns: "common"}),
        icon: <Delete/>,
        disable: props.disableDelete,
        callback: onBulkDelete,
        display: "dialog",
        confirmation: t("common.action.bulkDeleteConfirmation", {ns: "common"}),
        validateSelected: true,
      },
    ];
    const _modelActions = [
      ...defaultModelActions,
      ...(props.modelActions || []),
    ];
    return _modelActions.filter((action) => !action.disable);
  }, [props.url, props.disableAdd, props.disableDelete, props.modelActions, searchPanelParams]);

  const recordActions = useMemo(() => {
    const defaultRecordActions = [
      {
        id: "edit",
        title: t("common.action.edit", {ns: "common"}),
        icon: <Edit/>,
        disable: props.disableEdit,
        display: "page",
        url: `${props.url}/edit`,
        state: {mode: "EDIT"},
      },
      {
        id: "delete",
        title: t("common.action.delete", {ns: "common"}),
        icon: <Delete/>,
        disable: props.disableDelete,
        callback: onDelete,
        display: "dialog",
        confirmation: t("common.action.deleteConfirmation" ,{ns: "common"}),
      },
    ];
    const _recordActions = [
      ...defaultRecordActions,
      ...(props.recordActions || []),
    ];
    return _recordActions.filter((action) => !action.disable);
  }, [props.url, props.disableEdit, props.disableDelete, props.recordActions]);

  if (recordActions.length > 0) {
    const actionColumn: GridColDef = {
      field: "actions",
      headerName: t("common.column.action"),
      type: "actions",
      renderCell: (params) => (
        <RecordActionRender actions={recordActions} params={params}/>
      ),
    };
    columns.push(actionColumn);
  }

  useImperativeHandle(ref, () => ({
    refresh: async () => {
      await refreshData();
    },
  }));

  useEffect(() => {
    if (props.uri && searchPanelReady) {
      refreshData();
    }
  }, [paginationModel, props.uri, filterParams, searchPanelParams, searchPanelReady]);

  const defaultHideColumns: GridColumnVisibilityModel = {}
  if (props.hideColumns) {
    props.hideColumns.forEach((each: string) => {
      defaultHideColumns[each] = false
    })
  }
  const [columnVisibilityModel, setColumnVisibilityModel] =
    React.useState<GridColumnVisibilityModel>(defaultHideColumns);

  return (
    <ListViewContext.Provider
      value={{
        title: props.title,
        uri: props.uri,
        paginationModel,
        filterParams,
        updateFilterModel,
        setFilterParams,
        searchPanelParams,
        setSearchPanelParams,
        setSearchPanelReady,
        refreshData,
        dataGridRef,
        options,
        categoryLabel,
        approvalFilter,
        auditStatus
      }}
    >
      <Grid container spacing={1} sx={{height: "100%", overflowY: "hidden"}}>
        {!props.disableBreadcrumbs && <Grid
          size={12}
          sx={{
            height: "48px",
            display: "flex",
            alignItems: "center"
          }}
        >
          <ViewBreadcrumbs
            title={props.title}
            breadcrumbs={props.breadcrumbs}
          />
        </Grid>}
        <Grid size={12} sx={{display: "flex"}}>
          <Stack spacing={1} direction={"row"} sx={{width: "100%"}}>
            {SearchPanel && <SearchPanel ref={searchPanelRef}/>}
            <Grid sx={{flex: 1}}>
              <Grid className={"controlPanel"}>
                <Stack direction={"row"} gap={1}>
                  {FilterPanel && <FilterPanel ref={filterPanelRef} />}
                  {props.filters && (
                    <FiltersAction
                      filters={props.filters}
                      filterModel={filterParams}
                      onFilterModelChange={(model: any) => {
                        setFilterParams(model);
                      }}
                    />
                  )}
                  <Box sx={{flexGrow: 1}}/>
                  <ListViewModelActionRender actions={modelActions}/>
                  {!props.disableExport && <ExportAction/>}
                  {!props.disableViewColumn && <ViewColumnAction/>}
                  {!props.disableHistory && <HistoryAction
                    breadcrumbs={[
                      ...(props.breadcrumbs || []),
                      {title: props.title, href: props.url},
                    ]}
                    uri={props.uri}
                  />}
                  {!props.disableRefresh && <RefreshAction onRefresh={refreshData}/>}
                </Stack>
              </Grid>
              <Grid className={"listRender"} sx={{mt: 1}}>
                <DataGrid
                  loading={isLoading}
                  apiRef={dataGridRef}
                  sx={{
                    height: "calc(100vh - 64px - 48px - 64px)",
                    backgroundColor: "#fff",
                    ".MuiDataGrid-cell:focus": {outline: "none"},
                    ".MuiDataGrid-cell:focus-within": {outline: "none"},
                    ".MuiDataGrid-columnHeader:focus": {outline: "none"},
                  }}
                  columns={columns}
                  columnVisibilityModel={columnVisibilityModel}
                  onColumnVisibilityModelChange={(newModel) =>
                    setColumnVisibilityModel(newModel)
                  }
                  checkboxSelection
                  pagination={true}
                  paginationMode={"server"}
                  pageSizeOptions={[5, 10, 20, 50]}
                  disableRowSelectionOnClick
                  disableColumnSorting
                  disableColumnMenu
                  paginationModel={paginationModel}
                  onPaginationModelChange={(model: any, details: any) => {
                    setPaginationModel(model);
                  }}
                  rows={gridData.data}
                  rowCount={gridData.total}
                  onRowClick={(params, event, details) => {
                    console.log(params, details)
                    setSelectedRow(params.row)
                  }}
                  slots={{
                    pagination: CustomPagination,
                  }}
                  {...props.dataGrid}
                />
              </Grid>
            </Grid>
            {RecordPanel && <RecordPanel record={selectedRow}/>}
          </Stack>
        </Grid>
        {props.children}
      </Grid>
    </ListViewContext.Provider>
  );
};
export default React.forwardRef(ListView);

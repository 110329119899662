const APP = 'ralvie'
const API_VER = 'v2'
const RESOURCE_TAG = 'api'
export const RalvieEndPoint = {
  DASHBOARD_APP_CATEGORY_URI: `/${APP}/${RESOURCE_TAG}/${API_VER}/dashboard/application_categories`,
  DASHBOARD_PROJECT_HOURS_URI: `/${APP}/${RESOURCE_TAG}/${API_VER}/dashboard/project_hours`,
  DASHBOARD_WEEKLY_HOURS_URI: `/${APP}/${RESOURCE_TAG}/${API_VER}/dashboard/weekly_hours`,
  DASHBOARD_MOST_USED_APP_URI: `/${APP}/${RESOURCE_TAG}/${API_VER}/dashboard/most_used_applications`,
  REPORT_TIMESHEET_URI: `/${APP}/${RESOURCE_TAG}/${API_VER}/report/timesheet`
}

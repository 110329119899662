import {Stack} from "@mui/system";
import {MoreVert} from "@mui/icons-material";
import {useMemo} from "react";
import ListViewRowRecordAction from "@/components/views/ListView/components/record-action/RecordAction";
import ListViewRowRecordMenu from "@/components/views/ListView/components/record-action/RecordActionMenu";
import {useTranslation} from "react-i18next";


const RecordActionRender = (props: Record<string, any>) => {
  const {t} = useTranslation("common")
  const {actions, params} = props
  const rowActions = useMemo(() => {
    if (actions.length > 1) {
      const _rowActions: any[] = []
      const actionMore: Record<string, any> = {
        id: "more", title: t("common.action.more"), icon: <MoreVert />, subs: []
      }
      actions.forEach((action: Record<string, any>, index: number) => {
        if (actions.length > 3 && index > 1) {
          actionMore.subs.push(action)
        } else {
          _rowActions.push(action)
        }
      })
      if (actionMore.subs.length > 0){
        _rowActions.push(actionMore)
      }
      return _rowActions
    } else {
      return actions
    }
  }, [actions])
  return (
    <Stack direction={"row"}>
      {rowActions?.map((action: any) => {
        if (action.subs?.length > 0) {
          return <ListViewRowRecordMenu key={action.id} record={params.row} {...action}/>
        }
        return (
          <ListViewRowRecordAction key={action.id} record={params.row} {...action}/>
        )
      })}
    </Stack>
  )
}
export default RecordActionRender

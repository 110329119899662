import {FormControl, FormControlLabel, FormLabel, Switch} from "@mui/material";
import {WidgetProps} from "@rjsf/utils";


const Switch2Widget = (props: WidgetProps) => {
  const {label, value, name, required} = props
  return (
    <FormControl required={required}>
      <FormLabel>{label}</FormLabel>
      <FormControlLabel
        control={<Switch checked={!!value} name={name} onChange={(event, checked) => props.onChange(checked)}/>}
        label={""}
      />
    </FormControl>
  )
}
export default Switch2Widget


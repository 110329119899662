import {GridRenderCellParams} from "@mui/x-data-grid";
import {Box, Chip, Typography} from "@mui/material";


const PatternDayRender = (props: {
  params: GridRenderCellParams
} & Record<string, any>) => {
  const {params} = props
  return (
    <Box
      sx={{
        height: "100%",
        width: "100%",
        alignContent: "center"
      }}
    >
      {params.value.shift_tmpl?.map((shift: any) => {
        return <Chip label={shift.display_name} onDelete={async () => {
          await props.onDeleteShiftTemplate(params.value.day, shift.id)
        }}/>
      })}
    </Box>
  )
}
export default PatternDayRender

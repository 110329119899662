import GenericService from "@/services/core/GenericService";
import {ComplementEndpoint} from "@/services/complement/endpoints";

const sendWorkCode2Device = async (data: Record<string, any>) => {
  const response = await GenericService.action(ComplementEndpoint.WORK_CODE_URI, 'send_work_code', data)
  return response;
}

const removeWorkCodeFromDevice = async (data: Record<string, any>) => {
  const response = await GenericService.action(ComplementEndpoint.WORK_CODE_URI, 'remove_work_code', data)
  return response;
}

const WorkCodeService = {
  sendWorkCode2Device,
  removeWorkCodeFromDevice,
}
export default WorkCodeService

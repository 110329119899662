import {useTranslation} from "react-i18next";
import {GridColDef} from "@mui/x-data-grid";
import AppUrls from "@/urls";
import ListView from "@/components/views/ListView";
import {VendorEndPoint} from "@/services/vendor/endpoints";
import {Visibility} from "@mui/icons-material";
import OrderView from "@/pages/OnlineSubscription/MESubscription/Billing/components/OrderView";
import RemoveShoppingCartIcon from '@mui/icons-material/RemoveShoppingCart';
import React, {useRef} from "react";
import GenericService from "@/services/core/GenericService";
import SnackbarUtil from "@/utils/SnackbarUtil";


const SubscriptionListView = () => {
  const {t} = useTranslation('vendor')
  const {t: sub} = useTranslation('sub')
  const {t: api} = useTranslation('api')
  const ref = useRef<any>()
  const columns: GridColDef[] = [
    {field: "number", headerName: t("vendor.order.number"), width: 200},
    {field: "create_time", headerName: t("vendor.order.orderingTime"), width: 160},
    {field: "subscription_duration", headerName: t("vendor.order.lengthOfService"), flex: 1},
    {field: "employees_count", headerName: t("vendor.order.employeeCapacity"), flex: 1},
    {field: "devices_count", headerName: t("vendor.order.deviceCapacity"), flex: 1},
    {field: "mobile_applications_count", headerName: t("vendor.order.mobileAppCapacity"), flex: 1},
    {field: "transactions_count", headerName: t("vendor.order.transactionCapacity"), flex: 1},
    {field: "whatsapp_push_count", headerName: t("vendor.order.whatsappCapacity"), flex: 1},
    {field: "price_strategy", headerName: t("vendor.order.priceStrategy"), flex: 1},
    {field: "payment_price", headerName: t("vendor.order.paymentAmount"), flex: 1},
    {
      field: "payment_status",
      headerName: t("vendor.order.paymentStatus"),
      flex: 1,
      type: "singleSelect",
      valueOptions: [
        {value: 0, label: t("paymentStatus.manualCancel")},
        {value: 1, label: t("paymentStatus.timeout")},
        {value: 2, label: t("paymentStatus.paid")},
        {value: 3, label: t("paymentStatus.unpaid")},
      ]
    },
  ]

  const onCancelOrder = async (recordId: number) => {
    const response = await GenericService.action(VendorEndPoint.SUBSCRIPTION_URI, "cancel", {}, recordId.toString())
    if (response.isSuccess) {
      ref.current?.refresh()
      return true
    } else {
      console.log(response)
      return false
    }
  }

  const recordActions = [
    {
      id: "view",
      icon: <Visibility/>,
      title: sub("onlineSubscription.viewOrder"),
      display: "dialog",
      readOnly: true,
      Form: OrderView
    },
    {
      id: "cancel",
      icon: <RemoveShoppingCartIcon/>,
      title: sub("onlineSubscription.cancelOrder"),
      display: "dialog",
      confirmation: sub("onlineSubscription.cancelOrder.confirmation"),
      callback: onCancelOrder
    },
  ]

  return (
    <ListView
      ref={ref}
      columns={columns}
      recordActions={recordActions}
      uri={VendorEndPoint.SUBSCRIPTION_URI}
      resource={"orders/"}
      url={AppUrls.subscription.order}
      disableBreadcrumbs={true}
      disableDelete={true}
      disableEdit={true}
      disableAdd={true}
      disableExport={true}
      disableViewColumn={true}
      disableHistory={true}
      disableRefresh={true}
    />
  )
}
export default SubscriptionListView

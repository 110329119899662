import {Route, Routes} from "react-router-dom";
import AnnouncementListView from "./ListView";

const AnnouncementRouter = () => {
  return (
    <Routes>
      <Route index element={<AnnouncementListView/>}/>
    </Routes>
  )
};
export default AnnouncementRouter

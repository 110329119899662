import ListView from "@/components/views/ListView";
import {GridColDef} from "@mui/x-data-grid";
import AppUrls from "@/urls";
import {useTranslation} from "react-i18next";
import {AttEndPoint} from "@/services/att/endpoints";


const LeaveScheduleListView = () => {
  const {t} = useTranslation("att")
  const {t: per} = useTranslation("per")
  const columns: GridColDef[] = [
    {field: "emp_code", headerName: per("employee.code"), flex: 1},
    {field: "first_name", headerName: per("employee.firstName"), flex: 1},
    {field: "last_name", headerName: per("employee.lastName"), flex: 1},
  ]

  const filters = [
    {key: 'employee__emp_code', label: per("employee.code"), type: 'string'},
    {key: 'employee__first_name', label: per("employee.firstName"), type: 'string'},
  ]

  return (
    <ListView
      title={t("menu.att.leaveSchedule", {ns: "menu"})}
      columns={columns}
      uri={AttEndPoint.LEAVE_SCHEDULE_URI}
      url={AppUrls.att.leaveSchedule}
      filters={filters}
    />
  )
}
export default LeaveScheduleListView

import {useTranslation} from "react-i18next";
import {GridColDef, GridRenderCellParams} from "@mui/x-data-grid";
import AppUrls from "@/urls";
import ListView from "@/components/views/ListView";
import {VendorEndPoint} from "@/services/vendor/endpoints";
import {Switch} from "@mui/material";


const FeatureListView = () => {
  const {t} = useTranslation("vendor")
  const {t: menu} = useTranslation("menu")
  const renderStatus = (params: GridRenderCellParams) => {
    return <Switch checked={params.value}/>
  }
  const columns: GridColDef[] = [
    {field: "name", headerName: t("feature.name"), flex: 1},
    {field: "display_status", headerName: t("feature.display"), flex: 1, renderCell: renderStatus},
    {field: "effective_status", headerName: t("feature.effective"), flex: 1, renderCell: renderStatus},
    {
      field: "type", headerName: t("feature.type"), flex: 1, renderCell: (params) => {
        switch (params.value) {
          case 0:
            return t("featureType.server")
          case 1:
            return t("featureType.customization")
          case 2:
            return t("featureType.prototype")
          default:
            return params.value
        }
      }
    },
    {field: "country", headerName: t("feature.country"), flex: 1},
    {field: "price", headerName: t("feature.price"), flex: 1},
    {field: "currency", headerName: t("feature.currency"), flex: 1},
    {field: "description", headerName: t("feature.description"), flex: 1},
  ]
  return (
    <ListView
      title={menu("menu.subscription.feature")}
      breadcrumbs={[
        {title: menu("menu.subscription"), href: AppUrls.subscription.root},
      ]}
      columns={columns}
      uri={VendorEndPoint.FEATURE_URI}
      url={AppUrls.vendor.feature}
    />
  )
}
export default FeatureListView

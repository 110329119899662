import {LoadingButton, LoadingButtonProps} from "@mui/lab";


const SubmitButton = (props: LoadingButtonProps) => {
  return (
    <LoadingButton
      fullWidth
      variant={"contained"}
      size={"large"}
      sx={{mt: 2, textTransform: "uppercase"}}
      {...props}
    >
      {props.children}
    </LoadingButton>
  )
}
export default SubmitButton

import {Card, CardContent, CardHeader, Chip} from "@mui/material";
import React from "react";


const ChartCard = (props: Record<string, any>) => {
  const {total} = props
  return (
    <Card sx={{height: "100%"}}>
      <CardHeader
        title={props.title}
        action={props.total && <Chip
          color={"primary"}
          label={`${total.hour} h ${total.minute} m`}
          variant={"filled"}
        />}
      />
      <CardContent sx={{width: "100%", height: "calc(100% - 64px)"}}>
        {props.children}
      </CardContent>
    </Card>
  )
}
export default ChartCard

import {useTranslation} from "react-i18next";
import {RJSFSchema, UiSchema} from "@rjsf/utils";
import RecordView from "@/components/views/RecordView";
import urls from "@/urls";
import {VendorEndPoint} from "@/services/vendor/endpoints";
import RecordViewLayout from "@/pages/vendor/subscription/Order/components/RecordViewLayout";


const OrderRecordView = () => {
  const {t} = useTranslation("vendor");
  const formSchema: RJSFSchema = {
    "type": "object",
    "properties": {
      company: {
        "type": "string",
        "title": t("order.company"),
      },
      price_strategy: {
        "type": "integer",
        "title": t("order.priceStrategy"),
      },
      subscription_duration: {
        type: "integer",
        title: t("order.lengthOfService"),
      },
      employees_count: {
        type: "integer",
        title: t("order.employeeCapacity"),
      },
      devices_count: {
        type: "integer",
        title: t("order.deviceCapacity"),
      },
      mobile_applications_count: {
        type: "integer",
        title: t("order.mobileAppCapacity"),
        minimum: 1
      },
      whatsapp_push_count: {
        type: "integer",
        title: t("order.whatsappCapacity"),
      },
      transactions_count: {
        type: "integer",
        title: t("order.transactionCapacity"),
      },
      description: {
        type: "string",
        title: t("order.description"),
      }
    },
    required: ["company", "price_strategy", "subscription_duration", "employees_count", "devices_count"]
  }

  const uiSchema: UiSchema = {
    'ui:defaultXsWidth': 4,
    "ui:submitButtonOptions": {"norender": true},
    company: {
      "ui:widget": "m2o",
      "ui:options": {
        uri: VendorEndPoint.COMPANY_URI
      }
    },
    price_strategy: {
      "ui:widget": "m2o",
      "ui:options": {
        uri: VendorEndPoint.PRICE_STRATEGY_URI
      }
    },
    description: {
      "ui:widget": "textarea",
    },
  }

  const templates = {
    ObjectFieldTemplate: RecordViewLayout
  }

  return (
    <RecordView
      breadcrumbs={[
        {title: t("menu.subscription"), href: urls.subscription.root},
        {title: t("menu.subscription.order"), href: urls.subscription.order},
      ]}
      formSchema={formSchema}
      uiSchema={uiSchema}
      templates={templates}
      uri={VendorEndPoint.ORDER_URI}
    />
  )
}
export default OrderRecordView

import {RJSFSchema, UiSchema} from "@rjsf/utils";
import {useTranslation} from "react-i18next";
import RecordView from "@/components/views/RecordView";
import {AttEndPoint} from "@/services/att/endpoints";
import {ComplementEndpoint} from "@/services/complement/endpoints";
import {PsnlEP} from "@/services/psnl/endpoints";
import React from "react";


const LeaveRecordView = () => {
  const {t} = useTranslation("att");
  const formSchema: RJSFSchema = {
    type: "object",
    properties: {
      employee: {
        type: "string",
        title: t("leave.employee"),
      },
      category: {
        type: "number",
        title: t("leave.category"),
      },
      start_date: {
        type: "string",
        title: t("leave.startDate"),
      },
      end_date: {
        type: "string",
        title: t("leave.endDate"),
      },
      day_type: {
        type: "number",
        title: t("leave.dayType"),
        oneOf: [
          {const: 1, title: t("dayType.option.fullDay")},
          {const: 2, title: t("dayType.option.firstHalf")},
          {const: 3, title: t("dayType.option.secondHalf")}
        ],
        default: 1
      },
      leave_payment_type: {
        type: "number",
        title: t("leave.paymentType"),
        oneOf: [
          {const: 0, title: t("paymentType.paid")},
          {const: 1, title: t("paymentType.unpaid")},
        ],
      },
      apply_reason: {
        type: "string",
        title: t("approval.applyReason", {ns: "common"}),
        maxLength: 200
      },
    },
    required: [
      "employee",
      "start_date",
      "end_date",
      "leave_payment_type",
      "day_type",
      "category"
    ],
  };

  const uiSchema: UiSchema = {
    "ui:defaultXsWidth": 4,
    "ui:defaultRowWrap": true,
    employee: {
      "ui:widget": "m2o",
      "ui:options": {
        uri: PsnlEP.EMPLOYEE_URI,
      },
    },
    category: {
      "ui:widget": "m2o",
      "ui:options": {
        uri: ComplementEndpoint.INCIDENT_TYPE_URI,
      },
    },
    start_date: {
      "ui:widget": "date",
    },
    end_date: {
      "ui:widget": "date",
    },
    apply_reason: {
      "ui:widget": "textarea",
      "ui:emptyValue": ""
    },
  };

  return (
    <RecordView
      formSchema={formSchema}
      uiSchema={uiSchema}
      uri={AttEndPoint.LEAVE_URI}
    />
  );
};
export default LeaveRecordView;

import { useTranslation } from "react-i18next";
import { useEffect, useRef, useState } from "react";
import GenericService from "@/services/core/GenericService";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Chip,
  CircularProgress,
  Divider,
  TextField,
  Typography,
} from "@mui/material";
import { Stack } from "@mui/system";
import {
  AccessTime,
  ArrowRight,
  BadgeOutlined,
  Event,
  PersonOutlined,
} from "@mui/icons-material";
import SnackbarUtil from "@/utils/SnackbarUtil";

const ApprovalView = (props: Record<string, any>) => {
  const { t } = useTranslation("per");
  const { t: att } = useTranslation("att");
  const { record } = props;
  const remarksRef = useRef<any>();
  const [nodes, setNodes] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [buttonsVisible, setButtonsVisible] = useState<boolean>(true);

  const employeeInfo = [
    {
      name: "name",
      icon: <PersonOutlined />,
      label: `${record.first_name} ${record.last_name}`,
    },
    { name: "empCode", icon: <BadgeOutlined />, label: `${record.emp_code}` },
    { name: "event", icon: <Event />, label: `${props.event}` },
    { name: "applyTime", icon: <AccessTime />, label: `${record.apply_time}` },
  ];

  const onApprove = async () => {
    const remarks = remarksRef.current?.value;
    GenericService.action(
      props.uri,
      "approve",
      { remarks },
      props.recordId
    ).then((response) => {
      // console.log(response);
      if (response.isSuccess) {
        SnackbarUtil.success("Success");
        setButtonsVisible(false);
        return true;
      } else {
        console.log(response);
        return false;
      }
    });
  };

  const onReject = async () => {
    const remarks = remarksRef.current?.value;
    GenericService.action(
      props.uri,
      "reject",
      { remarks },
      props.recordId
    ).then((response) => {
      // console.log(response);
      if (response.isSuccess) {
        SnackbarUtil.success("Success");
        setButtonsVisible(false);
        return true;
      } else {
        console.log(response);
        return false;
      }
    });
  };

  useEffect(() => {
    GenericService.action(props.uri, "workflow", {}, props.recordId)
      .then((response) => {
        setNodes(response.data);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);
  let pending_approve = true;
  return (
    <Box sx={{ marginTop: "-36px" }}>
      <Stack spacing={1} sx={{ p: 1 }}>
        {employeeInfo.map((item) => {
          return (
            <Stack key={item.name} spacing={1} direction={"row"}>
              {item.icon}
              <Typography>{`${item.label}`}</Typography>
            </Stack>
          );
        })}
      </Stack>
      <Divider />
      <Box
        sx={{
          width: "640px",
          height: "348px",
          padding: "16px 0",
          overflowY: "auto",
        }}
      >
        {nodes.length > 0 ? (
          <Box sx={{ display: "flex", alignItems: "center" }}>
            {nodes.map((node: any, index) => {
              node.pending_approve ? "" : (pending_approve = false);
              return (
                <>
                  {index > 0 && <ArrowRight />}
                  <Card
                    variant={"outlined"}
                    sx={{
                      width: "192px",
                      height: "192px",
                    }}
                  >
                    <CardHeader
                      title={
                        <Typography variant={"h3"}>{node.name}</Typography>
                      }
                      subheader={node.state}
                    />
                    <CardContent sx={{ width: "100%" }}>
                      <Stack spacing={2}>
                        <Typography variant={"h4"}>
                          {t("workflowNode.approver")}
                        </Typography>
                        <Stack spacing={1} direction={"row"}>
                          {node.approver.map((role: string) => {
                            return <Chip label={role} />;
                          })}
                        </Stack>
                      </Stack>
                    </CardContent>
                  </Card>
                </>
              );
            })}
          </Box>
        ) : (
          <Box
            sx={{
              width: "100%",
              height: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {loading ? (
              <CircularProgress />
            ) : (
              <Typography>
                {t("common.message.noDataFound", { ns: "common" })}
              </Typography>
            )}
          </Box>
        )}
      </Box>
      <Divider />
      {buttonsVisible && pending_approve && (
        <Stack spacing={2} sx={{ pt: 2 }}>
          <TextField
            inputRef={remarksRef}
            fullWidth
            label={att("approval.remark", { ns: "common" })}
          />
          <Stack direction={"row"} spacing={2}>
            <Box sx={{ flex: 1 }} />
            <Button color={"primary"} variant={"contained"} onClick={onApprove}>
              {att("approval.action.approve", { ns: "common" })}
            </Button>
            <Button color={"error"} variant={"contained"} onClick={onReject}>
              {att("approval.action.reject", { ns: "common" })}
            </Button>
          </Stack>
        </Stack>
      )}
    </Box>
  );
};
export default ApprovalView;

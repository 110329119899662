import ModelActionPage from "@/components/views/ListView/components/model-action/ModelActionPage";
import urls from "@/urls";
import {useTranslation} from "react-i18next";
import {useEffect, useMemo, useState} from "react";
import {RJSFSchema} from "@rjsf/utils";
import SystemSettingService from "@/services/base/SystemSettingService";
import SnackbarUtil from "@/utils/SnackbarUtil";
import SettingLayout from "@/pages/SystemSetting/AlertSetting/components/SettingLayout";


const AlertSetting = () => {
  const {t} = useTranslation("sys")
  const [formData, setFormData] = useState<Record<string, any>>({})
  const weekDays = [
    {const: 0, title: t("weekday.monday", {ns: "common"})},
    {const: 1, title: t("weekday.tuesday", {ns: "common"})},
    {const: 2, title: t("weekday.wednesday", {ns: "common"})},
    {const: 3, title: t("weekday.thursday", {ns: "common"})},
    {const: 4, title: t("weekday.friday", {ns: "common"})},
    {const: 5, title: t("weekday.saturday", {ns: "common"})},
    {const: 6, title: t("weekday.sunday", {ns: "common"})}
  ]

  const monthDays = useMemo(() => {
    const days = []
    for (let i = 1; i < 32; i++) {
      days.push({const: i, title: `${i}`})
    }
    return days
  }, [])

  const formSchema: RJSFSchema = {
    type: "object",
    definitions: {
      email_day: {
        type: "integer",
        title: t("alertSetting.endDayOfPeriod"),
        oneOf: [
          {const: 1, title: "1"}
        ],
        default: 1,
      }
    },
    properties: {
      late_exceed: {
        type: "integer",
        title: t("alertSetting.late"),
        default: 0
      },
      early_leave_exceed: {
        type: "integer",
        title: t("alertSetting.earlyLeave"),
        default: 0
      },
      absent_exceed: {
        type: "integer",
        title: t("alertSetting.absent"),
        default: 0
      },
      email_frequency: {
        type: "integer",
        title: t("alertSetting.frequency"),
        oneOf: [
          {const: 0, title: t("frequency.none")},
          {const: 1, title: t("frequency.daily")},
          {const: 2, title: t("frequency.weekly")},
          {const: 3, title: t("frequency.monthly")}
        ],
        default: 0,
      },
      // email_day: {
      //   type: "integer",
      //   title: t("alertSetting.sendingDay"),
      //   default: 1
      // },
      email_time: {
        type: "string",
        title: t("alertSetting.endTimeOfPeriod"),
        default: "00:00"
      },
      sending_day: {
        type: "integer",
        title: t("alertSetting.sendingDay"),
        oneOf: [
          {const: 0, title: t("sendingDays.currentDay")},
          {const: -1, title: t("sendingDays.nextDay")}
        ],
        default: 0
      },
      adminpasschange_alert: {
        type: "boolean",
        title: t("alertSetting.pwdChange"),
        default: false
      },
      deviceoffline_alert: {
        type: "boolean",
        title: t("alertSetting.deviceOffline"),
        default: false
      },
      email_alert: {
        type: "boolean",
        title: t("alertSetting.emailAlert"),
        default: false
      },
      whatsapp_alert: {
        type: "boolean",
        title: t("alertSetting.whatsappAlert"),
        default: false
      },
      popup_alert: {
        type: "boolean",
        title: t("alertSetting.popupAlert"),
        default: false
      },
      autocalculation_alert: {
        type: "boolean",
        title: t("alertSetting.autoCalculation"),
        default: false
      },
      autocalculation_alert_time: {
        type: "string",
        title: t("alertSetting.autoCalculationTime"),
        default: "23:00"
      }
    },
    required: [
      "late_exceed", "early_leave_exceed", "absent_exceed",
      "email_frequency", "email_time", "sending_day"
    ],
    dependencies: {
      email_frequency: {
        oneOf: [
          {
            properties: {
              email_frequency: {enum: [0]},
              email_day: {
                $ref: "#/definitions/email_day",
                oneOf: [
                  {const: 1, title: "1"}
                ],
                readOnly: true
              }
            }
          },
          {
            properties: {
              email_frequency: {enum: [1]},
              email_day: {
                $ref: "#/definitions/email_day",
                oneOf: [
                  {const: 1, title: "1"}
                ],
                readOnly: true
              }
            }
          },
          {
            properties: {
              email_frequency: {enum: [2]},
              email_day: {
                $ref: "#/definitions/email_day",
                oneOf: weekDays,
              }
            }
          },
          {
            properties: {
              email_frequency: {enum: [3]},
              email_day: {
                $ref: "#/definitions/email_day",
                oneOf: monthDays,
              }
            }
          }
        ],
        required: ["email_day"]
      }
    }
  }
  const uiSchema = {
    late_exceed: {
      "ui:widget": "quantifier",
      "ui:options": {
        unit: t("common.unit.time", {ns: "common"})
      }
    },
    early_leave_exceed: {
      "ui:widget": "quantifier",
      "ui:options": {
        unit: t("common.unit.time", {ns: "common"})
      }
    },
    absent_exceed: {
      "ui:widget": "quantifier",
      "ui:options": {
        unit: t("common.unit.time", {ns: "common"})
      }
    },
    email_time: {
      "ui:widget": "time"
    },
    adminpasschange_alert: {
      "ui:widget": "switch"
    },
    deviceoffline_alert: {
      "ui:widget": "switch"
    },
    email_alert: {
      "ui:widget": "switch"
    },
    whatsapp_alert: {
      "ui:widget": "switch"
    },
    popup_alert: {
      "ui:widget": "switch"
    },
    autocalculation_alert: {
      "ui:widget": "switch"
    },
    autocalculation_alert_time: {
      "ui:widget": "time"
    }
  }
  const templates = {
    ObjectFieldTemplate: SettingLayout
  }
  const getAlertSetting = async () => {
    const response = await SystemSettingService.getAlertSetting()
    if (response.isSuccess) {
      setFormData(response.data)
    }
  }

  const onSave = async (data: Record<string, any>) => {
    const response = await SystemSettingService.updateAlertSetting(data)
    if (response.isSuccess) {
      SnackbarUtil.success("Success")
    } else {
      SnackbarUtil.error(response?.code)
    }
  }

  useEffect(() => {
    getAlertSetting().then()
  }, [])

  return (
    <ModelActionPage
      title={t("menu.systemSetting.alertSetting", {ns: "menu"})}
      breadcrumbs={[
        {title: t("menu.systemSetting", {ns: "menu"}), href: urls.system.root},
      ]}
      formSchema={formSchema}
      uiSchema={uiSchema}
      templates={templates}
      formData={formData}
      disableDiscard={true}
      onSave={onSave}
    />
  )
}
export default AlertSetting

import {useTranslation} from "react-i18next";
import {GridColDef, GridRenderCellParams} from "@mui/x-data-grid";
import ListView from "@/components/views/ListView";
import AppUrls from "@/urls";
import {AccessControlEndPoint} from "@/services/acc/endpoints";
import SearchPanel from "@/pages/AccessControl/components/SearchPanel";
import SyncAltIcon from '@mui/icons-material/SyncAlt';
import AdjustAction from "@/pages/AccessControl/privilege/components/AdjustAction";
import { useVerifyModesList } from "@/pages/AccessControl/options";

const PrivilegeListView = () => {
  const {t} = useTranslation("acc")
  const columns: GridColDef[] = [
    {field: "employee_code", headerName: t("employee.code", {ns: "per"}), width: 100},
    {field: "employee_name", headerName: t("employee.firstName", {ns: "per"}), flex: 1},
    {field: "group_name", headerName: t("group"), width: 80},
    {field: "is_group_verifycode", headerName: t("privilege.verifyModeUseFor"), flex: 1,
      type: "singleSelect",
      valueOptions: [
        {value: 0, label: t("timePeriodMode.person")},
        {value: 1, label: t("timePeriodMode.group")},
      ],
    },
    {field: "verify_mode", headerName: t("privilege.verifyMode"), flex: 1,
      type: "singleSelect",
      valueOptions: useVerifyModesList()
    },
    {field: "is_group_timezone", headerName: t("privilege.timePeriodUseFor"), flex: 1,
      type: "singleSelect",
      valueOptions: [
        {value: 0, label: t("timePeriodMode.person")},
        {value: 1, label: t("timePeriodMode.group")},
      ],
    },
    {field: "timezone1", headerName: t("privilege.timePeriod1"), flex: 1},
    {field: "timezone2", headerName: t("privilege.timePeriod2"), flex: 1},
    {field: "timezone3", headerName: t("privilege.timePeriod3"), flex: 1},
  ]

  const filters = [
    {key: 'holiday_name', label: t("holiday.name"), type: 'string'},
  ]

  const modelActions = [
    {
      id: "privilegeAdjust",
      title: t("privilege.adjust"),
      icon: <SyncAltIcon />,
      display: "dialog",
      Form: AdjustAction,
    },
  ];

  return (
    <ListView
      title={t("menu.acc.privilege", {ns: "menu"})}
      breadcrumbs={[
        {title: t("menu.accessControl", {ns: "menu"}), href: AppUrls.acc.root},
      ]}
      columns={columns}
      uri={AccessControlEndPoint.PRIVILEGE_URI}
      url={AppUrls.acc.privilege}
      filters={filters}
      SearchPanel={SearchPanel}
      disableAdd={true}
      disableDelete={true}
      modelActions={modelActions}
    />
  )
}
export default PrivilegeListView

import Grid from "@mui/material/Grid2";
import React from "react";
import MXSubscriptionPlan from "@/pages/OnlineSubscription/MXSubscription/Pricing/components/Plan";
import {Button, Typography} from "@mui/material";
import RequestSubscription from "@/pages/OnlineSubscription/MXSubscription/Pricing/components/Request";
import {useTranslation} from "react-i18next";
import ContactUs from "@/pages/OnlineSubscription/MXSubscription/Pricing/components/ContactUs";
import {useDialogContext} from "@/provider/DialogProvider";


const MXSubscriptionPricing = () => {
  const {t} = useTranslation('sub')
  const dialog = useDialogContext()
  const onContactUs = async () => {
    dialog.showDialog({
      title: t('onlineSubscription.contactUs'),
      content: <ContactUs/>,
      disableAction: true
    })
  }
  return (
    <Grid container sx={{display: "flex", flexDirection: "column", padding: "0 16px"}}>
      <Grid size={12} sx={{height: "48px"}}>
        <RequestSubscription/>
      </Grid>
      <Grid
        size={12}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          p: 4,
          flex: 1,
          overflowY: "hidden"
        }}
      >
        <MXSubscriptionPlan/>
      </Grid>
      <Grid size={12} sx={{display: "flex", justifyContent: "center", alignItems: "center"}}>
        <Typography variant={"h5"}>{t("onlineSubscription.mx.helpText")}</Typography>
        <Button variant={"text"} onClick={onContactUs}>
          {t("onlineSubscription.contactUs")}
        </Button>
      </Grid>
    </Grid>
  )
}
export default MXSubscriptionPricing

import {RJSFSchema, UiSchema} from "@rjsf/utils";
import {useTranslation} from "react-i18next";
import RecordView from "@/components/views/RecordView";
import {PsnlEP} from "@/services/psnl/endpoints";
import {AttEndPoint} from "@/services/att/endpoints";
import LayoutTemplate from "./components/FromTemplate";
import TimesWidget from "../GlobalRule/components/TimesWidget";

const DeptRuleRecordView = () => {
  const {t} = useTranslation("att");
  const formSchema: RJSFSchema = {
    type: "object",
    required: [
      "alias",
      "department",
      "mins_late_absents",
      // "timecheckinmin",
      // "timecheckinminchoices",
      "mins_late_absent",
      // "timecheckoutmin",
      // "timecheckoutminchoices",
      "mins_early_absents",
      "mins_early_absent",
      "half_day_work",
      "miss_in",
      "miss_out",
      "use_ot",
    ],
    properties: {
      alias: {
        type: "string",
        title: t("departmentWorkRule.alias"),
        maxLength: 50
      },
      department: {
        title: t("departmentWorkRule.department"),
        type: "number",
      },
      mins_late_absent: {
        type: "integer",
        title: t("workRule.exception.considerAs"),
        default: 0,
        oneOf: [
          {const: 0, title: t("workRule.exception.absent")},
          {const: 1, title: t("workRule.exception.late")},
          {const: 2, title: t("workRule.exception.halfDay")},
        ],
      },
      mins_late_absents: {
        type: "integer",
        title: t("workRule.exception.lateDuration"),
        default: 60,
        minimum: 0,
        maximum: 24 * 60 * 60,
      },
      // timecheckinmin: {
      //   type: "integer",
      //   title: t("workRule.exception.lateTime"),
      //   default: 0,
      //   minimum: 0,
      // },
      // timecheckinminchoices: {
      //   type: "integer",
      //   title: t("workRule.exception.considerAs"),
      //   oneOf: [
      //     { const: 0, title: t("workRule.exception.absent") },
      //     { const: 1, title: t("workRule.exception.late") },
      //     { const: 2, title: t("workRule.exception.halfDay") },
      //   ],
      //   default: 0,
      // },
      mins_early_absents: {
        type: "integer",
        title: t("workRule.exception.earlyLeaveDuration"),
        default: 60,
        minimum: 0,
        maximum: 24 * 60 * 60,
      },
      mins_early_absent: {
        type: "integer",
        title: t("workRule.exception.considerAs"),
        default: 2,
        oneOf: [
          {const: 0, title: t("workRule.exception.absent")},
          {const: 1, title: t("workRule.exception.earlyLeave")},
          {const: 2, title: t("workRule.exception.halfDay")},
        ],
      },
      // timecheckoutmin: {
      //   type: "integer",
      //   title: t("workRule.exception.earlyLeaveTime"),
      //   default: 0,
      //   minimum: 0,
      // },
      // timecheckoutminchoices: {
      //   type: "integer",
      //   title: t("workRule.exception.considerAs"),
      //   oneOf: [
      //     { const: 0, title: t("workRule.exception.absent") },
      //     { const: 1, title: t("workRule.exception.earlyLeave") },
      //     { const: 2, title: t("workRule.exception.halfDay") },
      //   ],
      //   default: 0,
      // },
      half_day_work: {
        type: "integer",
        title: t("workRule.exception.halfDayWork"),
        default: 270,
        minimum: 1,
        maximum: 24 * 60 * 60,
      },
      miss_in: {
        type: "integer",
        title: t("workRule.exception.missedIn"),
        default: 1,
        oneOf: [
          {const: 1, title: t("workRule.exception.present")},
          {const: 2, title: t("workRule.exception.absent")},
          {const: 0, title: t("workRule.exception.incomplete")},
          {const: 5, title: t("workRule.exception.halfDay")},
        ],
      },
      miss_out: {
        type: "integer",
        title: t("workRule.exception.missedOut"),
        default: 1,
        oneOf: [
          {const: 1, title: t("workRule.exception.present")},
          {const: 2, title: t("workRule.exception.absent")},
          {const: 0, title: t("workRule.exception.incomplete")},
          {const: 5, title: t("workRule.exception.halfDay")},
        ],
      },
      use_ot: {
        type: "integer",
        title: t("workRule.overtimeRule"),
        default: 1,
        oneOf: [
          {const: 0, title: t("workRule.disable.overtime")},
          {const: 1, title: t("workRule.calculateOT")},
          {const: 2, title: t("workRule.approvedOT")},
          {const: 3, title: t("workRule.approvedOTPriority")},
        ],
      },
    },
  };

  const uiSchema: UiSchema = {
    "ui:defaultXsWidth": 6,
    department: {
      "ui:widget": "m2o",
      "ui:options": {
        uri: PsnlEP.DEPARTMENT_URI,
      },
    },
    // timecheckinmin: {
    //   "ui:widget": "times",
    // },
    // timecheckoutmin: {
    //   "ui:widget": "times",
    // },
  };

  const widgets = {
    times: TimesWidget,
  };

  const templates = {
    ObjectFieldTemplate: LayoutTemplate,
  };

  return (
    <RecordView
      widgets={widgets}
      formSchema={formSchema}
      uiSchema={uiSchema}
      uri={AttEndPoint.DEPT_ATT_RULE_URI}
      templates={templates}
    />
  );
};
export default DeptRuleRecordView;

import {useRoutes} from "react-router-dom";
import HolidayListView from "./ListView";
import TimePeriodRecordView from "@/pages/AccessControl/time_period/RecordView";
import HolidayRecordView from "@/pages/AccessControl/holiday/RecordView";


const HolidayRouter = () => {
  return useRoutes([
    {index: true, element: <HolidayListView />},
    {path: "add", element: <HolidayRecordView />},
    {path: "edit", element: <HolidayRecordView />},
  ])
};
export default HolidayRouter

import {Box} from "@mui/material";
import React, {useEffect, useMemo, useRef, useState} from "react";
import * as echarts from "echarts";
import DashboardService from "@/services/ralvie/DashboardService";
import ChartCard from "@/pages/Ralvie/Dashboard/components/ChartCard";
import {useTranslation} from "react-i18next";


const ProjectHour = (props: Record<string, any>) => {
  const {t} = useTranslation("ralvie")
  const containerRef = useRef<any>()
  const chart = useRef<any>()
  const [projects, setProjects] = useState<any[]>([])
  const formatDuration = (value: number) => {
    const minutes = Math.floor(value / 60)
    const hour = Math.floor(minutes / 60)
    const minute = minutes % 60
    return `${hour}h${minute}m`
  }

  const getProjects = async () => {
    const response = await DashboardService.getProjectHours(props.filters)
    return response.data
  }

  const total = useMemo(() => {
    if(projects){
      const durations: number[] = [0]
      projects?.forEach((project: any) => {
        const minutes = Math.floor(project.totalLoggedDuration / 60)
        durations.push(minutes)
      })
      const totalDuration = durations.reduce((total, duration) => {
        return total + duration
      })
      const hour = Math.floor(totalDuration / 60)
      const minute = totalDuration % 60
      return {hour, minute}
    }else{
      return {hour: 0, minute: 0}
    }
  }, [projects])

  useEffect(() => {
    getProjects().then((data) => {
      setProjects(data?.projects)
    })
  }, [props.filters])

  useEffect(() => {
    const container = containerRef.current
    if (!chart.current) {
      chart.current = echarts.init(container)
    }
    const option = {
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow'
        },
        formatter: (params: any) => {
          console.log(params)
          const param = params[0]
          const minutes = Math.floor(param.value / 60)
          const hour = Math.floor(minutes / 60)
          const minute = minutes % 60
          return `${param.name} <br/> ${hour}h${minute}m`
        }
      },
      legend: {
        orient: 'vertical',
        x: 'right',
      },
      grid: {
        top: 0,
        containLabel: true
      },
      xAxis: {
        type: 'value',
        boundaryGap: [0, 0.01],
        axisLabel: {show: false},
        // splitLine: {show: false},
      },
      yAxis: {
        type: 'category',
        axisLabel: {show: false},
        axisLine: {show: false},
        axisTick: {show: false},
        splitLine: {show: false},
        data: projects?.map((project) => ({
          value: project.name,
        }))
      },
      series: [
        {
          type: 'bar',
          label: {
            show: true,
            formatter: (params: any) => {
              console.log(params)
              return `${params.data.label} (${formatDuration(params.data.value)})`
            }
          },
          data: projects?.map((project) => ({
            value: project.totalLoggedDuration,
            label: `${project.name}`,
            itemStyle: {
              color: project.color
            }
          }))
        }
      ]
    }
    chart.current.setOption(option)
  }, [projects])

  return (
    <ChartCard
      title={t("ralvie.projectHours")}
      total={total}
    >
      <Box
        ref={containerRef}
        component={"div"}
        sx={{
          width: "100%",
          height: "100%"
        }}
      />
    </ChartCard>
  )
}
export default ProjectHour

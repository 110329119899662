import {Route, Routes} from "react-router-dom";
import FlowRecordView from "./RecordView";
import FlowListView from "./ListView";
import React from "react";


const FlowRouter = () => {
  return (
    <Routes>
      <Route index element={<FlowListView/>}/>
      <Route path={"add/"} element={<FlowRecordView/>}/>
      <Route path={"edit/"} element={<FlowRecordView/>}/>
    </Routes>
  )
};
export default FlowRouter

import React, {createContext, useContext, useEffect, useState} from "react";
import {Box, LinearProgress} from "@mui/material";
import {useLocation} from "react-router-dom";
import OauthService from "@/services/oauth/OauthService";
import SnackbarUtil from "@/utils/SnackbarUtil";


type RegionContextProps = Record<string, any>

const RegionContext = createContext<RegionContextProps>({})

export const useRegionContext = () => {
  const context = useContext(RegionContext)
  if (!context) {
    throw new Error('The app provider not found!')
  }
  return context
}

const RegionProvider = (props: Record<string, any>) => {
  const [loading, setLoading] = useState<boolean>(false)
  const [region, setRegion] = useState<Record<string, any>>({})
  useEffect(() => {
    setLoading(true)
    OauthService.regionIdentify({}).then((response) => {
      if(response.isSuccess){
        setRegion(response.data)
      }else{
        SnackbarUtil.error("Invalid Access")
      }
    }).catch(() => {
      setLoading(false)
      SnackbarUtil.error("Invalid Access")
    }).finally(() => setLoading(false))
  }, [])
  return (
    <RegionContext.Provider
      value={{
        region
      }}
    >
      {loading
        ? <Box sx={{width: '100%'}}>
          <LinearProgress/>
        </Box>
        : props.children
      }
    </RegionContext.Provider>
  )
}
export default RegionProvider

import { FormProps } from '@rjsf/core';
import { Form as RJSFForm } from '@rjsf/mui';
import LayoutObjectFieldTemplate from "@/components/rjsf/templates/LayoutObjectFieldTemplate"
import React, { ForwardedRef, useState } from 'react';
import {RegistryFieldsType, RegistryWidgetsType, UiSchema} from '@rjsf/utils';
import { customWidgets } from "@/components/rjsf/widgets";
import validator from "@rjsf/validator-ajv8";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";


function transformErrors(errors: any[], uiSchema: any) {
  return errors.map((error) => {
    if (error.name === 'required') {
      error.message = 'This filed is required';
    } else if (error.name === 'maxLength') {
      error.message = `must not have more than ${error.params.limit} characters`;
    } else if (error.name === 'minLength') {
      error.message = `must not have less than ${error.params.limit} characters`;
    }
    console.log(error, error.name)
    return error;
  });
}

type IsOptional<Base, Key extends keyof Base> = Omit<Base, Key> & Partial<Pick<Base, Key>>;
type CusFormProps = IsOptional<FormProps, 'validator'>;


const LayoutForm = (props: CusFormProps, ref: ForwardedRef<any>) => {
  const [liveValidate, setLiveValidate] = useState(false)

  const onError = (errors: any) => {
    setLiveValidate(true)
    props.onError && props.onError(errors)
  }

  const templates = {
    ObjectFieldTemplate: LayoutObjectFieldTemplate,
    ...props.templates,
  }

  const widgets: RegistryWidgetsType = {
    ...customWidgets,
    ...props.widgets
  }
  const fields: RegistryFieldsType = {
    ...props.fields
  }
  const uiSchema: UiSchema = {
    "ui:submitButtonOptions": {
      "norender": true,
    },
    ...props.uiSchema
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <RJSFForm
        ref={ref}
        liveValidate={liveValidate}
        showErrorList={false}
        transformErrors={transformErrors}
        noHtml5Validate
        validator={validator}
        {...props}
        onError={onError}
        fields={fields}
        widgets={widgets}
        templates={templates}
        uiSchema={uiSchema}
        disabled={props.disabled}
      />
    </LocalizationProvider>
  )
}

export default React.forwardRef(LayoutForm)

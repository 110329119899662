import {Box, Card, CardContent, CardHeader, MenuItem, Select, Typography} from "@mui/material";
import React, {useEffect, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import * as echarts from "echarts";
import GenericService from "@/services/core/GenericService";
import {BaseEP} from "@/services/base/endpoints";


const LeaveLineChart = () => {
  const {t} = useTranslation("common")
  const containerRef = useRef<any>()
  const chart = useRef<any>()
  const [timePeriod, setTimePeriod] = useState("TODAY")
  const [data, setData] = useState<any>({
    total_employee: 0,
    leaves: []
  })
  const timeOptions = [
    {value: "TODAY", name: t("timePeriod.today")},
    {value: "YESTERDAY", name: t("timePeriod.yesterday")},
    {value: "THIS_WEEK", name: t("timePeriod.thisWeek")},
    {value: "THIS_MONTH", name: t("timePeriod.thisMonth")},
    {value: "THIS_YEAR", name: t("timePeriod.thisYear")},
    {value: "LAST_WEEK", name: t("timePeriod.lastWeek")},
    {value: "LAST_MONTH", name: t("timePeriod.lastMonth")},
    {value: "LAST_THREE_MONTH", name: t("timePeriod.lastThreeMonths")},
    {value: "LAST_SIX_MONTH", name: t("timePeriod.lastSixMonths")},
    {value: "LAST_YEAR", name: t("timePeriod.lastYear")},
  ]

  useEffect(() => {
    const container = containerRef.current
    if (!chart.current) {
      chart.current = echarts.init(container)
    }
    chart.current.showLoading()
    GenericService.action(BaseEP.DASHBOARD_URI, "leave_summary", {time_period: timePeriod}).then((response) => {
      setData(response.data)
    }).finally(() => {
      chart.current.hideLoading()
    })
  }, [timePeriod])

  useEffect(() => {
    const container = containerRef.current
    if (!chart.current) {
      chart.current = echarts.init(container)
    }
    const option = {
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow'
        },
        formatter: (params: any) => {
          const param = params[0]
          return `${param.name}  ${data.leaves?.[param.dataIndex]?.employee_count}`
        }
      },
      legend: {},
      grid: {
        left: '3%',
        right: '4%',
        bottom: '3%',
        containLabel: true
      },
      xAxis: {
        type: 'value',
        boundaryGap: [0, 0.01],
        splitNumber: 10,
        max: 100,
        axisLabel: {
          formatter: '{value}%'
        }
      },
      yAxis: [
        {
          type: 'category',
          data: data.leaves?.map((item: any) => item.category__category_name) || []
        }
      ],
      series: [
        {
          name: '',
          type: 'bar',
          data: data.leaves?.map((item: any) => (item.employee_count / data.total_employee) * 100) || [],
          itemStyle: {
            color: (params: any) => {
              return data.leaves?.[params.dataIndex]?.category__report_symbol_color
            }
          }
        }
      ]
    }
    chart.current.setOption(option)
  }, [data])

  return (
    <Card sx={{height: "100%", overflow: "auto"}}>
      <CardHeader
        title={<Box sx={{
          display: "flex"
        }}>
          <Typography variant={"h4"} flex={1}>{t("dashboard.leaveSummary", {ns: "company"})}</Typography>
          <Box>
            <Select
              size={"small"}
              sx={{
                minWidth: "152px"
              }}
              value={timePeriod}
              onChange={(event) => setTimePeriod(event.target.value)}
            >
              {timeOptions.map((timeOption) =>
                <MenuItem key={timeOption.value} value={timeOption.value}>{timeOption.name}</MenuItem>
              )}
            </Select>
          </Box>
        </Box>}
      />
      <CardContent sx={{width: "calc(100% - 32px)", height: "calc(100% - 88px - 40px)"}}>
        <Box
          component={"div"}
          ref={containerRef}
          sx={{
            width: "100%",
            height: "100%"
          }}
        />
      </CardContent>
    </Card>
  )
}
export default LeaveLineChart

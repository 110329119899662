import { useTranslation } from "react-i18next";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import urls from "@/urls";
import { ReportEndPoint } from "@/services/report/endpoints";
import ReportView from "../ReportView";
import { useEffect, useState } from "react";
import GenericService from "@/services/core/GenericService";
import {PersonalEndPoint} from "@/services/personal/endpoints";
import { useReportEmployee } from "@/hooks/employee";

const BreakTimeReport = () => {
  const { t } = useTranslation("report");
  const employeeFields = useReportEmployee()
  const columns: GridColDef[] = [
    ...employeeFields,
    {
      field: "att_date",
      minWidth: 120,
      headerName: t("report.scheduleDate"),
      flex: 1,
    },
    {
      field: "timetable",
      minWidth: 120,
      headerName: t("report.timetable"),
      flex: 1,
    },
    {
      field: "total_break_time",
      minWidth: 120,
      headerName: t("report.summaryTime"),
      flex: 1,
    },
  ];

  const [clockColumns, setClockColumns] = useState<GridColDef[]>([
    {
      field: "break_out",
      minWidth: 120,
      headerName: t("report.breakOut"),
      flex: 1,
    },
    {
      field: "break_in",
      minWidth: 120,
      headerName: t("report.breakIn"),
      flex: 1,
    },
    {
      field: "total_time",
      minWidth: 120,
      headerName: t("report.totalTime"),
      flex: 1,
    },
  ]);
  const [allColumns, setAllColumns] = useState<GridColDef[]>([
    ...columns,
    ...clockColumns,
  ]);
  const refreshColumns = async (props: any) => {
    const { startDate, endDate, selected, category, setLoading } = props;
    setLoading(true);
    GenericService.action(PersonalEndPoint.BREAK_TIME_URI, "refresh_columns", {
      start_date: startDate?.format("YYYY-MM-DD"),
      end_date: endDate?.format("YYYY-MM-DD"),
      [`${category}`]: selected,
    })
      .then((response) => {
        const clock_columns: any = [];
        for (const i of JSON.parse(response.data)) {
          const i_name = i.startsWith("break_out")
            ? t("report.breakOut")
            : i.startsWith("break_in")
            ? t("report.breakIn")
            : t("report.totalTime");
          clock_columns.push({
            field: i,
            minWidth: 120,
            headerName: i_name,
            flex: 1,
          });
        }
        setClockColumns(clock_columns);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  useEffect(() => {
    setAllColumns([...columns, ...clockColumns]);
  }, [clockColumns]);

  return (
    <ReportView
      title={t("report.breakTime")}
      breadcrumbs={[]}
      columns={allColumns}
      uri={PersonalEndPoint.BREAK_TIME_URI}
      url={urls.personal.report.breakTime}
      refreshColumns={refreshColumns}
      getRowId={(row: any) => Math.random().toString(16).slice(2)}
      hideColumns={['first_name', 'last_name', 'department_code', 'position_code', 'position_name']}
    />
  );
};
export default BreakTimeReport;

import { RJSFSchema, UiSchema } from "@rjsf/utils";
import ImportView from "@/components/views/ImportView";
import { useTranslation } from "react-i18next";
import AppUrls from "@/urls";
import { PsnlEP } from "@/services/psnl/endpoints";
import urls from "@/urls";


const EmployeeImportView = () => {
  const { t } = useTranslation("menu");

  const formSchema: RJSFSchema = {
    "type": "object",
    "required": [
      "import_file",
      "overwrite",
    ],
    "properties": {
      "import_file": {
        "type": "string",
        "title": t("common.button.upload", {ns: "common"}),
      },

      "overwrite": {
        "title": t("import.overwrite", {ns: "common"}),
        "type": "boolean",
        "default": false,
        "oneOf": [
          {const: false, title: t("boolean.no", {ns: "common"}),},
          {const: true, title: t("boolean.yes", {ns: "common"})},
        ]
      }
    }
  };

  const uiSchema: UiSchema = {
    'ui:defaultXsWidth': 4,
    "import_file": {
      'ui:widget': 'file',
    },
    "overwrite": {
      "ui:widget": "select",
    },
  }

  const templateDemoHeaders = [
    {field: 'emp_code', title: t("employee.code", {ns: "per"}), required: true, unique: true},
    {field: 'first_name',title: t("employee.firstName", {ns: "per"}), required: true, },
    {field: 'last_name',title: t("employee.lastName", {ns: "per"})},
    {field: 'department',title: t("department.code", {ns: "company"}), required: true},
    {field: 'dept_name',title: t("department.name", {ns: "company"})},
    {field: 'position',title: t("position.code", {ns: "company"}), required: true},
    {field: 'position_name',title: t("position.name", {ns: "company"})},
    {field: 'hire_date',title: t("employee.joinedDate", {ns: "per"})},
    {field: 'card_no',title: t("employee.cardNumber", {ns: "per"}), unique: true},
    {field: 'area',title: t("area.code", {ns: "company"}), required: true},
    {field: 'gender',title: t("employee.gender", {ns: "per"})},
    {field: 'mobile',title: t("employee.mobile", {ns: "per"}), required: true, unique: true},
    {field: 'birthday',title: t("employee.birthDate", {ns: "per"})},
    {field: 'email',title: t("employee.email", {ns: "per"}), required: true, unique: true},
    {field: 'ssn',title: t("employee.SSN", {ns: "per"}), unique: true},
  ]
  const templateDemoRows = [
    ['0001', 'xxxx', '', '1', '', '1', '', '2024-01-01', '12345', '1', 'M', '18911111111', '', '1@1.com', '12345'],
    ['0002', 'xxxx', '', '1', '', '1', '', '2024-01-01', '12346', '1', 'M', '18911111112', '', '2@1.com', '23456'],
  ]

  return (
    <ImportView
      modelTitle={t("menu.team.employee")}
      title={t("common.action.import", {ns: "common"})}
      breadcrumbs={[
        {title: t("menu.company"), href: urls.team.root},
        { title: t("menu.team.employee"), href: AppUrls.team.employee }
      ]}
      formSchema={formSchema}
      uiSchema={uiSchema}
      uri={PsnlEP.EMPLOYEE_URI}
      templateDemoHeaders={templateDemoHeaders}
      templateDemoRows={templateDemoRows}
      templateUrl={'/personnel/employee/import_template/'}
    />
  )
}
export default EmployeeImportView

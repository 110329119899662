import {
  Autocomplete,
  Badge,
  Button,
  Input,
  InputAdornment,
  ListItem,
  ListItemIcon,
  ListItemText,
  Popper,
  styled
} from "@mui/material";
import {ForwardedRef, useEffect, useImperativeHandle, useState} from "react";
import GenericService from "@/services/core/GenericService";
import {Close, Done, Search} from "@mui/icons-material";
import React from "react";
import { AttEndPoint } from "@/services/att/endpoints";
import {useTranslation} from "react-i18next";


const StyledInput = styled(Input)(({theme}) => ({
  padding: 10,
  width: '100%',
  borderBottom: `1px solid ${
    theme.palette?.mode === 'light' ? '#eaecef' : '#30363d'
  }`
}))

const StyledPopper = styled(Popper)(({theme}) => ({
  border: `1px solid ${theme.palette.mode === 'light' ? '#e1e4e8' : '#30363d'}`,
  boxShadow: `0 8px 24px ${
    theme.palette.mode === 'light' ? 'rgba(149, 157, 165, 0.2)' : 'rgb(1, 4, 9)'
  }`,
  borderRadius: 6,
  width: 300,
  zIndex: theme.zIndex.modal,
  fontSize: 13,
  color: theme.palette.mode === 'light' ? '#24292e' : '#c9d1d9',
  backgroundColor: theme.palette.mode === 'light' ? '#fff' : '#1c2128',
}));

const InsertShiftTemplate = (props: Record<string, any>, ref: ForwardedRef<any>) => {
  const {t} = useTranslation("att");
  const [anchorEl, setAnchorEl] = useState<any>()
  const [options, setOptions] = useState<any[]>([])
  const [value, setValue] = useState<any>(null)
  const getShiftTemplates = async () => {
    const response = await GenericService.list(
      AttEndPoint.TIMETABLE_URI,
      {page: 1, page_size: 100})
    return response.data.data
  }

  useImperativeHandle(ref, () => ({
    selected: value
  }))

  useEffect(() => {
    getShiftTemplates().then(data => {
      setOptions(data)
    })
  }, [])

  return (
    <>
      <Badge badgeContent={value?.length} color={"primary"}>
        <Button
          variant={"contained"}
          onClick={(event) => {
            if (anchorEl) {
              setAnchorEl(null)
            } else {
              setAnchorEl(event.currentTarget)
            }
          }}
        >{t("insertShift.selectTimetable")}</Button>
      </Badge>
      <StyledPopper
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        placement="bottom-start"
      >
        <Autocomplete
          open={Boolean(anchorEl)}
          multiple
          value={value ? [value] : []}
          options={options}
          getOptionLabel={(option) => {
            return option.display_name
          }}
          isOptionEqualToValue={(option, value) => value.indexOf(option) > -1}
          onBlur={() => {
            setAnchorEl(null)
          }}
          renderTags={() => null}
          renderInput={(params) => {
            const {value, ...otherInputProps} = params.inputProps
            return (
              <StyledInput
                ref={params.InputProps.ref}
                inputProps={otherInputProps}
                autoFocus
                startAdornment={<InputAdornment position={"start"}><Search/></InputAdornment>}
              />
            )
          }}
          renderOption={(optionProps, option, {selected}) => {
            return (
              <ListItem {...optionProps}>
                <ListItemIcon sx={{minWidth: "32px"}}>
                  {selected && <Done/>}
                </ListItemIcon>
                <ListItemText>{option.display_name}</ListItemText>
                <ListItemIcon
                  sx={{minWidth: "32px"}}
                >
                  {selected && <Close/>}
                </ListItemIcon>
              </ListItem>
            )
          }}
          onChange={(event, newValue, reason, details) => {
            if(reason === "selectOption"){
              setValue((old: any) => {
                if (old) {
                  if (details) {
                    return [...old, details.option]
                  } else {
                    return old
                  }
                } else {
                  return newValue
                }
              })
            } else if (reason === "removeOption") {
              setValue((old: any) => {
                if (details) {
                  return old.filter((item: any) => item.id !== details.option.id)
                }
                return old
              })
            }
            setAnchorEl(null)
          }}
        />
      </StyledPopper>
    </>
  )
}
export default React.forwardRef(InsertShiftTemplate)

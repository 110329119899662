import { RJSFSchema, UiSchema } from "@rjsf/utils";
import { useTranslation } from "react-i18next";
import RecordView from "@/components/views/RecordView";
import urls from "@/urls";
import { ReportEndPoint } from "@/services/report/endpoints";
import React from "react";

const ReportItemsSettingRecordView = () => {
  const { t: t } = useTranslation("report");
  const { t: tMenu } = useTranslation("menu");
  const { t: common } = useTranslation("common");
  const formSchema: RJSFSchema = {
    type: "object",
    required: ["name", "min_unit", "unit", "round_off", "report_color"],
    properties: {
      name: {
        type: "string",
        title: t("reportItemsSetting.name"),
      },
      min_unit: {
        type: "number",
        title: t("reportItemsSetting.minUnit"),
        // TODO: 此校验暂时调整到后端, 因为无法自定义错误提示
        // pattern: "^(?:\\d+(?:\\.\\d)?|\\.\\d+)$",
      },
      unit: {
        type: "number",
        title: t("reportItemsSetting.unit"),
        oneOf: [
          { const: 1, title: common("duration.minute") },
          { const: 2, title: common("duration.hour") },
          { const: 3, title: common("duration.workday") },
          { const: 4, title: common("duration.HHmm") },
        ],
      },
      round_off: {
        type: "number",
        title: t("reportItemsSetting.roundOff"),
        oneOf: [
          { const: 1, title: t("reportItemsSetting.roundOff.roundDown") },
          { const: 2, title: t("reportItemsSetting.roundOff.roundOff") },
          { const: 3, title: t("reportItemsSetting.roundOff.roundUp") },
        ],
      },
      symbol: {
        type: "string",
        title: t("reportItemsSetting.symbol"),
      },
      report_color: {
        type: "string",
        title: t("reportItemsSetting.reportColor"),
        default: "#000000",
      },
    },
  };

  const uiSchema: UiSchema = {
    "ui:defaultXsWidth": 6,
    "ui:submitButtonOptions": { norender: true },
    report_color: {
      "ui:widget": "color",
    },
  };
  const [formData, setFormData] = React.useState<Record<string, any>>({});
  const onChange = (e: any) => {
    const cleanedData = Object.keys(e.formData).reduce(
      (acc: { [key: string]: any }, key: string) => {
        if (
          typeof e.formData[key] !== "string" &&
          e.schema.required.indexOf(key) === -1
        ) {
          acc[key] = "";
        } else {
          acc[key] = e.formData[key];
        }
        return acc;
      },
      {} as { [key: string]: any }
    );
    setFormData({
      ...cleanedData,
    });
  };
  return (
    <RecordView
      breadcrumbs={[
        { title: tMenu("menu.report"), href: urls.report.root },
        {
          title: tMenu("menu.report.reportItemsSetting"),
          href: urls.report.reportItemsSetting,
        },
      ]}
      formSchema={formSchema}
      uiSchema={uiSchema}
      uri={ReportEndPoint.REPORT_ITEMS_SETTING_URI}
      onChange={onChange}
      formData={formData}
    />
  );
};
export default ReportItemsSettingRecordView;

const API_VER = 'v2'
const RESOURCE_TAG = 'api'
export const GPSEndPoint = {
  ACCOUNTS_URI: `/gps/${RESOURCE_TAG}/${API_VER}/accounts/`,
  ANNOUNCEMENT_URI: `/gps/${RESOURCE_TAG}/${API_VER}/announcements/`,
  NOTICE_URI: `/gps/${RESOURCE_TAG}/${API_VER}/notifications/`,
  OPERATION_LOG_URI: `/gps/${RESOURCE_TAG}/${API_VER}/operation-logs/`,
  EMPLOYEE_GPS_URI: `/gps/${RESOURCE_TAG}/${API_VER}/gps-of-employee/`,
  DEPARTMENT_GPS_URI: `/gps/${RESOURCE_TAG}/${API_VER}/gps-of-department/`,
  CLIENT_DETAILS_URI: `/gps/${RESOURCE_TAG}/${API_VER}/client-details/`,
  SCHEDULE_PLANNER: `/gps/${RESOURCE_TAG}/${API_VER}/schedule_planners/`,
  OUTDOOR_SCHEDULE: `/gps/${RESOURCE_TAG}/${API_VER}/outdoor_schedules/`,
}

import {useTranslation} from "react-i18next";
import {RJSFSchema, UiSchema} from "@rjsf/utils";
import {PsnlEP} from "@/services/psnl/endpoints";
import RecordView from "@/components/views/RecordView";
import urls from "@/urls";
import {ComplementEndpoint} from "@/services/complement/endpoints";
import {AccessControlEndPoint} from "@/services/acc/endpoints";
import {useLocation} from "react-router-dom";
import {FormProps} from "@rjsf/core";
import GroupRecordViewLayout from "@/pages/AccessControl/group/components/RecordViewLayout";
import PrivilegeRecordViewLayout from "@/pages/AccessControl/privilege/components/RecordViewLayout";
import { useVerifyModesForm } from "@/pages/AccessControl/options";


const PrivilegeRecordView = () => {
  const {t} = useTranslation("acc");
  const params = useLocation()
  const area = params.state?.record?.area || params.state?.area
  const mode = params.state?.mode || "ADD"
  const verifyModes = useVerifyModesForm()
  const formSchema: RJSFSchema = {
    type: "object",
    properties: {
      area: {
        type: "integer",
        title: t("area"),
        // readOnly: mode === "EDIT",
        readOnly: true,
        default: parseInt(area)
      },
      employee: {
        type: "string",
        title: t("privilege.employee"),
        readOnly: mode === "EDIT"
      },
      group: {
        type: "number",
        title: t("group"),
      },
      is_group_verifycode: {
        type: "number",
        title: t('privilege.verifyModeUseFor'),
        oneOf:[
          {const: 0, title: t("timePeriodMode.person")},
          {const: 1, title: t("timePeriodMode.group")},
        ]
      },
      is_group_timezone: {
        type: "number",
        title: t('privilege.timePeriodUseFor'),
        oneOf:[
          {const: 0, title: t("timePeriodMode.person")},
          {const: 1, title: t("timePeriodMode.group")},
        ]
      },
    },
    required: ["group"],
    allOf: [
      {
        "if": {
          "properties": {
            "is_group_verifycode": {
              "const": 0
            }
          }
        },
        "then": {
          "properties": {
            "verify_mode": {
              "type": "number",
              "title": t("privilege.verifyMode"),
              "oneOf": verifyModes,
            },
          },
          required: ['verify_mode']
        }
      },
      {
        "if": {
          "properties": {
            "is_group_verifycode": {
              "const": 1
            }
          }
        },
        "then": {
            "properties": {
            "verify_mode": {
              "type": "number",
              "title": t("privilege.verifyMode"),
              "readOnly": true,
              "oneOf": verifyModes,
            },
          },
          required: []
        }
      },
      {
        "if": {
          "properties": {
            "is_group_timezone": {
              "const": 0
            }
          }
        },
        "then": {
          "properties": {
            "timezone1": {
              "type": "number",
              "title": t("privilege.timePeriod1"),
            },
            "timezone2": {
              "type": "number",
              "title": t("privilege.timePeriod2"),
            },
            "timezone3": {
              "type": "number",
              "title": t("privilege.timePeriod3"),
            },
          },
          required: ['timezone1']
        }
      },
      {
        "if": {
          "properties": {
            "is_group_timezone": {
              "const": 1
            }
          }
        },
        "then": {
          "properties": {
            "timezone1": {
              "type": "number",
              "title": t("privilege.timePeriod1"),
              "readOnly": true,
            },
            "timezone2": {
              "type": "number",
              "title": t("privilege.timePeriod2"),
              "readOnly": true,
            },
            "timezone3": {
              "type": "number",
              "title": t("privilege.timePeriod3"),
              "readOnly": true,
            },
          },
          required: []
        }
      },
    ]
  };
  const timezoneOptions = {
    uri: AccessControlEndPoint.TIME_PERIOD_URI,
    area: parseInt(area)
  }
  const uiSchema: UiSchema = {
    'ui:defaultXsWidth': 4,
    area: {
      "ui:widget": "m2o",
      "ui:options": {
        uri: PsnlEP.ACCESS_AREA_URI,
      }
    },
    "employee": {
      "ui:widget": "m2o",
      "ui:options": {
        uri: PsnlEP.EMPLOYEE_URI,
        area: parseInt(area)
      }
    },
    group: {
      "ui:widget": "m2o",
      "ui:options": {
        uri: AccessControlEndPoint.GROUP_URI,
        area: parseInt(area)
      }
    },
    timezone1: {
      "ui:widget": "m2o",
      "ui:options": timezoneOptions
    },
    timezone2: {
      "ui:widget": "m2o",
      "ui:options": timezoneOptions
    },
    timezone3: {
      "ui:widget": "m2o",
      "ui:options": timezoneOptions
    },
  };
  const templates: Partial<FormProps["templates"]> = {
    ObjectFieldTemplate: PrivilegeRecordViewLayout
  }
  return (
    <RecordView
      breadcrumbs={[
        {title: t("menu.accessControl"), href: urls.acc.root},
        {title: t("menu.acc.privilege"), href: urls.acc.privilege},
      ]}
      formSchema={formSchema}
      uiSchema={uiSchema}
      templates={templates}
      uri={AccessControlEndPoint.PRIVILEGE_URI}
    />
  )
}
export default PrivilegeRecordView
